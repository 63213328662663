import React, { useEffect, useState } from "react";
import { Input, Modal, Button, Badge, message } from "antd";
import { Form,  } from "@ant-design/compatible";

// Redux
import { connect } from "react-redux";

import "./index.scss";
import strings from "../../../../strings";


const VariantsModals = ( {product, toggleModal, showVariantModal, onClick, currency, form, cartList}) => {

    const [ loading, setLoading ] = useState(false);
    const [totalQuantity, setTotalQuantity ] = useState(0);
    const [variantsToDisplay, setVariantToDisplay ] = useState([]);
    const { getFieldDecorator } = form;
    const FormItem = Form.Item;
    // form.setFieldsValue({ quantity: totalQuantity });
    // console.log(cartList);
    useEffect(()=>{
        const newProduct = product?.variants.map((prod)=>{
            const selectQty = 0;
            return {...prod, selectQty}
        })
        // console.log(newProduct);
        setVariantToDisplay(newProduct);
    }, [product]);

    const handleOnClick = () => {
        let variants = variantsToDisplay.filter((variant)=>{
            return variant.selectQty > 0
        });
        // console.log(variants);
        let productCopy = { ...product };
        delete productCopy.variants;
        // const item = { ...productCopy, variants };
        const  item = variants.map((itme)=>{
          return {...productCopy, variants: [itme]}
        })
        // console.log(item);
        // item.variants = variants;
    
        // item.quantity = totalQuantity;
        if (variants.length === 0) {
          Modal.warning({
            title: strings.pleaseSelectAVariant,
          });
        } else {
          onClick(item, "bulk");
          form.setFieldsValue({ quantity: totalQuantity });
          setTotalQuantity(0)
        }
        
      };

      const getSelectedVariants = () => {
        const { selectedVariants } = this.state;
        let variants = [];
        Object.keys(selectedVariants).forEach(variant => {
          variants = [...variants, ...selectedVariants[variant]];
        });
        return variants;
      };
      const handleAddQuantity =(variant) =>{
        if (Number(variant.quantity) < 1) return message.warning("Product is out of stock");

        let variants = variantsToDisplay.filter((variant)=>{
          return variant.selectQty > 0
        });

        // if(variants.length > 0 && variants[0]?.id !== variant.id) {
        //   return message.info("Can Select more than one Variant");
        // }
        // if(variants.length > 1) return handleOnClick();
        variant.selectQty = variant.selectQty + 1
        variant.quantity = (Number(variant.quantity) - 1).toFixed(1);
        const newArray = variantsToDisplay.map((values)=>{
            if(values.id === variant.id){
                return values = {...variant}
            }else{
                return values
            }
        })
        setVariantToDisplay(newArray);
        setTotalQuantity(()=>{
            if (newArray.length === 0){
                 return 0
            }else{
                return newArray?.reduce((prev, curr)=>{
                    return prev + ((curr.selectQty));
                }, 0)
            }
        });
        // console.log(variant, totalQuantity);
      } 

      const handleReduceQuantity =(variant) =>{
        if (Number(variant.selectQty) < 1 ) return;
        variant.selectQty = variant.selectQty - 1
        variant.quantity = (Number(variant.quantity) + 1).toFixed(1);
        const newArray = variantsToDisplay.map((values)=>{
            if(values.id === variant.id){
                return values = {...variant}
            }else{
                return values
            }
        })
        setVariantToDisplay(newArray);
        setTotalQuantity(()=>{
            if (newArray.length === 0){
                 return 0
            }else{
                return newArray?.reduce((prev, curr)=>{
                    return prev + ((curr.selectQty));
                }, 0)
            }
        });
        // console.log(variant, totalQuantity);
      } 
    
    return (
        <Modal
          title={`${product.name} ${product.price}`}
          okText={strings.add}
          cancelText={strings.cancel}
          onOk={handleOnClick}
          onCancel={()=>{
            toggleModal()
          }}
          destoryOnClose
          open={showVariantModal}
        // open={true}
          okButtonProps={{ loading: loading }}
        >
          <div className="variants">
          <p>Please Select Quantity of Variant</p>
            <span>Variants:</span>
            {/* <Button
              htmlType="button"
              className="clear-button"
            //   disabled={!(getSelectedVariants().length > 0)}
            //   onClick={this.clearSelectedVariants}
            >
              {strings.clearVariants}
            </Button> */}
            <div>{variantsToDisplay.map((variant, index) => {
                return (
                <div key={index} style={{position: "relative"}}>
                    <span className="type">{`${variant.type}:`}</span>
                    <div className="variant-wrapper">
                        <Badge
                            key={variant.id}
                            count={variant.quantity}
                            className={
                                variant.isSelected === true
                                ? strings.variantSelected
                                : strings.variant
                            }
                            // onClick={() => {
                            //     handleOnClick()
                            // }}
                        >
                            <span style={{ paddingRight: "10px" }}>{variant.value}</span>
                            <span style={{ paddingRight: "10px" }}>-</span>
                            <span>
                            {currency}
                            {product.tax_rate
                                ? (
                                    (product.tax_rate / 100) * variant.price +
                                    parseFloat(variant.price)
                                ).toFixed(2)
                                : variant.price}
                            </span>
                        </Badge>
                    </div>
                    <div style={{position: "absolute", right: "1rem", bottom: "2.2rem", display: "flex", justifyContent: "space-around"}}>
                        <p style={{cursor: "pointer", marginLeft: "-5rem"}}
                            onClick={()=>{
                                handleReduceQuantity(variant)
                            }}
                        >-</p>
                        <p>{variant.selectQty}</p>
                        {/* <input type="number" value={variant.selectQty} 
                          onChange={(e)=>{
                            // setSelectQuantity(e.target.value);

                          }}
                          style={{
                            width: "10%",
                            height: "10%"
                          }}
                        /> */}
                        <p style={{cursor: "pointer"}} onClick={()=>{
                            handleAddQuantity(variant);
                        }}>+</p>
                    </div>
              </div>
            );
          })}</div>
          </div>
  
          <Form layout="vertical" >
            <span>{strings.quantity}:</span>
            <div className="variant_quantity_container">
              <div>
                {/* <Button
                  htmlType="button"
                  onClick={() => {
                    this.changeQuantity("minus");
                  }}
                >
                  -
                </Button> */}
              </div>
              {/* <FormItem label="">
                {getFieldDecorator("quantity", {
                  rules: [
                    {
                      required: true,
                      message: strings.pleaseEnterQuantity,
                    },
                  ],
                })(
                  <Input
                    size="large"
                    onChange={() => {
                      form.validateFields((err, values) => {
                        changeQuantity(values.quantity);
                      });
                    }}
                    className="variant_input center"
                    type="number"
                    value={totalQuantity}
                  />
                )}
              </FormItem> */}
              {totalQuantity}
              <div>
                {/* <Button
                  htmlType="button"
                  onClick={() => {
                    this.changeQuantity("plus");
                  }}
                >
                  +
                </Button> */}
              </div>
            </div>
          </Form>
        </Modal>
      );
    }


const VariantsModalForm = Form.create()(VariantsModals);
export default connect(null, {})(VariantsModalForm);