import { SET_CURRENT_USER } from "../constants";

const initialState = {
  isAuthenticated: false,
  user: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: payload?.id ? true : false,
        user: payload?.id ? payload : {},
      };
    default:
      return state;
  }
};
export default reducer;