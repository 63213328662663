import React from "react";
import { Modal, Input, Select } from "antd";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";

//Redux
import { connect } from "react-redux";
import { getAllBusinessBranches } from "../../../../../../redux/actions/branchActions";
import {
  updateInventory,
  getAllProducts,
} from "../../../../../../redux/actions/productActions";
import strings from "../../../../../../strings";

const Option = Select.Option;

class UpdateInventoryModal extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.props.getAllBusinessBranches();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ loading: false });
    }
  }

  handleUpdateInventory = () => {
    const {
      form,
      product,
      updateInventory,
      updateProducts,
      closeModal,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      this.setState({ loading: true });

      const requestPayload = { ...values, product_id: product.id };
      requestPayload.quantity = Number(requestPayload.quantity);

      // validate product quantity to add
      if (values.quantity < 0) {
        Modal.warning({
          title: strings.enterQuantityToUpdate,
        });

        this.setState({ loading: false });
      } else {
        updateInventory(requestPayload).then(res => {
          if (res && res.status === 200) {
            this.setState({ loading: false });
            form.resetFields();

            Modal.success({
              title: strings.inventorySentSuccessfully,
            });

            updateProducts();
            closeModal();
          }
        });
      }
    });
  };

  render() {
    const {
      showModal,
      closeModal,
      form: { getFieldDecorator },
      product,
    } = this.props;

    const branches = this.props.branches.filter(
      branch => branch.name.toLowerCase() !== "ecommerce"
    );

    return (
      <Modal
        visible={showModal}
        onCancel={closeModal}
        okText={strings.updateInventory}
        cancelText={strings.cancel}
        title={`${strings.update} ${product.name}`}
        destoryOnClose
        okButtonProps={{ loading: this.state.loading }}
        onOk={this.handleUpdateInventory}
      >
        <Form layout="vertical" >
          <span>{strings.currentQuantity}: </span>
          <span>{product.quantity}</span>
          <Form.Item label={strings.quantity}>
            {getFieldDecorator("quantity", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterQuantityToAdd,
                },
              ],
            })(<Input type="number" size="large" style={{ width: "100%" }} />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("business_branch_id", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseSelectABranch,
                },
              ],
            })(
              <Select
                showSearch
                placeholder={strings.searchForBranch}
                optionFilterProp="children"
                size="large"
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {branches &&
                  branches.map(branch => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const UpdateInventoryForm = Form.create()(UpdateInventoryModal);

UpdateInventoryForm.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string.isRequired,
  getAllBusinessBranches: PropTypes.func.isRequired,
  sendInventory: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  branches: state.branch.allBranches,
  error: state.error,
});

export default connect(mapStateToProps, {
  getAllBusinessBranches,
  updateInventory,
  getAllProducts,
})(UpdateInventoryForm);
