import React from "react";
import { Modal, Input } from "antd";
import { Form } from "@ant-design/compatible";
import api from "../../../../api";
import strings from "../../../../strings";

class ReceiptToEmailModal extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    const { customerList, customerValue, form } = this.props;

    const customer =
      customerList.length &&
      customerList.find(user => user.user_id === customerValue);

    if (customer) {
      form.setFieldsValue({
        email: customer.email,
      });
    }
  }

  handleSendReceiptToEmail = () => {
    const { form, saleId, closeModal, clearSaleId } = this.props;

    form.validateFields(async (err, values) => {
      this.setState({ loading: true });
      try {
        if (err) return;

        const payload = {
          email: values.email,
          sale_id: saleId,
        };

        const res = await api.sales.sendReceiptToEmail(payload);

        this.setState({ loading: false });

        if (res.status === 200) {
          Modal.success({
            title: res.data.message,
            onOk: () => {
              closeModal();
              clearSaleId();
            },
          });
        }
      } catch (error) {
        // console.log(error);
        this.setState({ loading: true });
      }
    });
  };

  render() {
    const {
      isReceiptModalVisible,
      closeModal,
      form: { getFieldDecorator },
    } = this.props;

    return (
      <Modal
        title={strings.sendReceiptToEmail}
        style={{ top: 20 }}
        visible={isReceiptModalVisible}
        onCancel={closeModal}
        okText={strings.send}
        cancelText={strings.cancel}
        onOk={this.handleSendReceiptToEmail}
        okButtonProps={{ loading: this.state.loading }}
        destroyOnClose
      >
        <Form layout="vertical" >
          <Form.Item label={strings.pleaseEnterCustomerEmail}>
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterCustomerEmail,
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const ReceiptToEmailModalForm = Form.create()(ReceiptToEmailModal);

export default ReceiptToEmailModalForm;
