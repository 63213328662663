import React, { Component } from "react";
import { Button, Spin } from "antd";
import { Form, Icon } from "@ant-design/compatible";
import { connect } from "react-redux";
import { checkPhoneAvailability } from "../../../redux/actions/authActions";
import PhoneInput from "react-phone-input-2";
import strings from "../../../strings";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class Phone extends Component {
  state = {
    isLoading: false,
    isPhoneLoading: false,
  };

  handleSubmit = () => {
    const { next, form, setPhone } = this.props;

    form.validateFields(async (err, values) => {
      if (err) return;

      this.setState({ isLoading: true });

      const { phone_number } = values;

      const phone = phone_number.split(" ").join("");

      setPhone(phone);

      try {
        next();

        // const res = await api.auth.generatePhoneVerirficationToken(phone);

        // if (res.status === 200) {
        //   setTextLabel(res.data.message);
        //   setPhone(phone);
        //   next();
        // }
        this.setState({ isLoading: false });
      } catch (error) {
        console.log(error.message);
        this.setState({ isLoading: false });
      }
    });
  };

  handlePhoneValidation = async (rule, value, callback) => {
    const { checkPhoneAvailability } = this.props;

    if (!value) {
      callback("");
    }

    let phone = value.split(" ").join("");

    try {
      if (value) {
        this.setState({ isPhoneLoading: true });

        const res = await checkPhoneAvailability(phone);

        this.setState({ isPhoneLoading: false });

        const { phone_available } = res.data;

        if (!phone_available) {
          callback(strings.phoneNumberInUse);
        }
      } else {
        callback();
      }
    } catch (error) {
      callback();
      this.setState({ isPhoneLoading: false });
    }
  };

  render() {
    const { form, setCountryData } = this.props;
    const { isPhoneLoading } = this.state;

    const { getFieldDecorator } = form;

    return (
      <Form >
        <Form.Item name="phone" label={strings.enterPhoneNumber}>
          {/* {getFieldDecorator("phone_number", {
          rules: [
            { required: true, message: "Please input your phone number!" },
          ],
        })(<Input size="large" placeholder="Enter Phone Number" />)} */}

          {getFieldDecorator("phone_number", {
            rules: [
              {
                message: strings.pleaseEnterPhoneNumber,
                required: true,
              },
              {
                validator: this.handlePhoneValidation,
              },
            ],
          })(
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PhoneInput
                inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                inputStyle={{
                  border: "1px solid #d9d9d9",
                  width: `${isPhoneLoading ? "90%" : "100%"}`,
                  height: "40px",
                }}
                country="us"
                onChange={(value, country) => {
                  setCountryData(country);
                }}
              />

              {isPhoneLoading && <Spin indicator={antIcon} />}
            </div>
          )}
        </Form.Item>

        <div className="control-buttons">
          <Button
            loading={this.state.isLoading}
            size="large"
            type="primary"
            onClick={() => this.handleSubmit()}
          >
            {strings.submit}
          </Button>
        </div>
      </Form>
    );
  }
}

const PhoneForm = Form.create()(Phone);

export default connect(null, { checkPhoneAvailability })(PhoneForm);
