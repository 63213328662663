import React from "react";
import { Col } from "antd";

import { connect } from "react-redux";

class ServicesListItem extends React.Component {
  confirmProductPrice = () => {
    const { item, onClick, handleShowSetPrice, checkoutData } = this.props;
    // console.log("hj",item);
    if(item.amount_charged === 0){
      handleShowSetPrice(item)
    }else{
      onClick(item);
    }
  };

  render() {
    const { item, currency } = this.props;

    return (
      <Col xs={12} sm={12} md={8} lg={6}>
        <div className="item">
          <a href onClick={this.confirmProductPrice}>
            <div className="item-image-container">
              {/* <img src={item.picture} alt={item.name} /> */}
              {item.image_url ? (
                <img src={item.image_url} alt={item.name} />
              ) : (
                <span>{item && item.service_name.substr(0, 2)}</span>
              )}
            </div>
            <div className="item-details">
              <h5>{item.service_name}</h5>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <h4 className="price">
                  {currency}
                  {item.amount_charged}
                </h4>
              </div>
            </div>
          </a>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  sales: state.sale,
});

export default connect(mapStateToProps, {})(ServicesListItem);
