import React, { useEffect, useState } from "react";
import { Menu, Button, Tag, Modal, message } from "antd";
import { Icon } from "@ant-design/compatible";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { DollarOutlined, DeliveredProcedureOutlined, UserAddOutlined, BarChartOutlined } from "@ant-design/icons";
// Redux
import { connect } from "react-redux";
import { signoutUser } from "../../../../redux/actions/authActions";
import { fetchActiveApps } from "../../../../redux/actions/appActions";
import { getAllBusinessBranches } from "../../../../redux/actions/branchActions";
import { getAllProducts, getAllBranchProducts } from "../../../../redux/actions/productActions";
import { changeMultiLevelStatus } from "../../../../redux/actions/multiLevelActions";
import "./index.scss";
import strings from "../../../../strings";
import {
  LIST_FORMS,
  UPDATE_MERCHANT_UREWARD_ID,
} from "../../Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";
import { disabledInvoiceFieldEmails } from "../../../Sales/_partials/RecordSalePaymentModal/emails";

const { SubMenu } = Menu;

const SidebarMenuItems = ({
  user,
  allBranches,
  signoutUser,
  fetchActiveApps,
  changeMultiLevelStatus,
  subscribedApps,
  getAllProducts,
  getAllBusinessBranches,
  isBeepBeepActive,
  isWhatsAppReferralActive,
  isLedgerActive,
  isPurchaseOrderActive
}) => {
  const [ blockAccess, setBlockAccess ] = useState(false);
  const isAdminIsManager =
    user.role_id === 1 || (user.role && user.role.id === 2);
  
  const business = user.employer ? { ...user.employer } : { ...user };

  const milisecondsToDaysConst = 86400000;

  useEffect(()=>{
    const daysLeft = Math.ceil(
      (new Date(business.subscription_expires_on) - Date.now()) /
        milisecondsToDaysConst
    );

    if(daysLeft <= 0){
      setBlockAccess(true);
    }else{
      setBlockAccess(false);
    }
  }, [business]);
  
  const isAdminIsManagerIsCashier =
    user.role_id === 1 ||
    (user.role && user.role.id === 2) ||
    (user.role && user.role.id === 3);

  const isUserBlackListed = disabledInvoiceFieldEmails.includes(user.email);

  const isAdmin = user.role_id === 1;

  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [integrationDetails] = useState({});
  const [iframeIsLoaded, setIframeIsLoaded] = useState(false);
  const [activeForms, setActiveForms] = useState([]);

  const client = useApolloClient();
  const history = useHistory();

  const homeBranch = allBranches.find(
    (branch) => branch.name.toLowerCase() === "home branch"
  );

  const isNitro = subscribedApps.find(
    (app) => app.app.app_name === "Loystar Nitro 🚀 "
  )
    ? true
    : false;

  useEffect(() => {
    getAllBusinessBranches();
    const isProduct = localStorage.getItem("products");
    if(!isProduct) handleFetchProducts();
  }, []);

  const handleFetchProducts = () => {
    if(user.employer){
      isNitro && getAllBranchProducts(user.id, user.business_branch.id)
    }else{
      isNitro && getAllProducts();
    }
  }

  const isSubscribedToMultiLevel = subscribedApps.find(
    (app) => app.app.app_name === "Multi-Level Loyalty"
  )
    ? true
    : false;

  const isSubscribedToFormBuilder = subscribedApps.find(
    (app) => app.app.app_name === "Form Builder"
  )
    ? true
    : false;

  const isSubscribedToBookings = subscribedApps.find(
    (app) => app.app.app_name === "Services, Bookings & Appointments"
  )
    ? true
    : false;

  const { data } = useQuery(LIST_FORMS, {
    skip: !isSubscribedToFormBuilder,
  });

  const { data: updateMerchantUrewardId } = useQuery(
    UPDATE_MERCHANT_UREWARD_ID
  );

  // const isProPlusPlan = user.subscription_plan === "Pro Plus";

  useEffect(() => {
    if (data && data.listForms) {
      const findActiveForms = data.listForms.filter(
        (form) => form.active === true
      );
      setActiveForms(findActiveForms);
    }
  }, [data]);

  useEffect(() => {
    const multi_level_token = localStorage.getItem("multi-level-token");
    if (!multi_level_token && isSubscribedToMultiLevel) {
      message.error("Could not login properly, please try again.");
      signoutUser(client);
      history.push("/signin");
    }
  }, [history, isSubscribedToMultiLevel, signoutUser, client]);

  useEffect(() => {
    fetchActiveApps().then((res) => {
      changeMultiLevelStatus(isSubscribedToMultiLevel);
    });
  }, [fetchActiveApps, isSubscribedToMultiLevel, changeMultiLevelStatus]);

  const isSubscribedToUpay = subscribedApps.find(
    (app) => app.app.app_name === "Instant USSD Transfer"
  )
    ? true
    : false;
    const isSubscribedToVpay = subscribedApps.find(
      (app) => app.app.app_name === "VPay Bank Transfer"
    )
      ? true
      : false;

      const isSubscribedToPaystack = subscribedApps.find(
        (app) => app.app.app_name === "Paystack Terminal"
      )
        ? true
        : false;

  const isShowDisbursement = isSubscribedToUpay || isSubscribedToVpay || isSubscribedToPaystack
  return (
    <Menu
      id="sidebar-menu"
      theme="light"
      mode="inline"
      style={{ minHeight: "100vh" }}
      defaultSelectedKeys={["4"]}
      className="side-nav"
    >
      <SubMenu
        key="sub2"
        icon={<DollarOutlined />}
        title={strings.transactions}
      >
        <Menu.Item key="daily-report">
          <NavLink
            to= {blockAccess ? "/d/account#subscribe" : "/d/transactions/today/report"}
            activeClassName="active"
            style={{ marginLeft: "30px" }}
          >
            <Icon type="calendar" />
            <span>{strings.todaySales}</span>
          </NavLink>
        </Menu.Item>

        {isAdminIsManager && (
          <Menu.Item key="report">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/transactions/reports"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="history" />
              <span>{strings.salesHistory}</span>
            </NavLink>
          </Menu.Item>
        )}
        {isAdmin && <Menu.Item key="redemption">
          <NavLink
            to={blockAccess ? "/d/account#subscribe" : "/d/transactions/redemption"}
            activeClassName="active"
            style={{ marginLeft: "30px" }}
          >
            <Icon type="history" />
            <span>{strings.redemption}</span>
          </NavLink>
        </Menu.Item>}

        {isAdminIsManager && (
          <Menu.Item key="supply-history">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/supply-history"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="history" />
              <span>{strings.supplyHistory}</span>{" "}
            </NavLink>
          </Menu.Item>
        )}

        {isAdminIsManager && isPurchaseOrderActive && (
          <Menu.Item key="purchase-order-history">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/purchase-order-history"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="history" />
              <span>{strings.purchaseHistory}</span>{" "}
            </NavLink>
          </Menu.Item>
        )}

     

        {isAdminIsManagerIsCashier && !isUserBlackListed && (
          <Menu.Item key="invoices">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/transactions/invoices"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="book" />
              <span>{strings.invoices}</span>
            </NavLink>
          </Menu.Item>
        )}

        {isShowDisbursement && isAdmin && (
          <Menu.Item key="disbursements">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/upay-disbursements"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="book" />
              <span>{strings.disbursements}</span>
            </NavLink>
          </Menu.Item>
        )}

        {/* <Menu.Item key="draft">
          <NavLink
            to="/d/transactions/draft-sales"
            activeClassName="active"
            style={{ marginLeft: "30px" }}
          >
            <Icon type="save" />
            <span>Draft Sales</span>
          </NavLink>
        </Menu.Item> */}

        <Menu.Item key="offline-sales">
          <NavLink
            to={blockAccess ? "/d/account#subscribe" : "/d/transactions/offline-sales"}
            activeClassName="active"
            style={{ marginLeft: "30px" }}
          >
            <Icon type="disconnect" />
            <span>{strings.offlineSales}</span>
          </NavLink>
        </Menu.Item>
        {/* isBeepBeepActive &&  */}
        {<Menu.Item key="delivery-requests">
          <NavLink
            to={blockAccess ? "/d/account#subscribe" : "/d/delivery-requests"}
            activeClassName="active"
            style={{ marginLeft: "30px" }}
          >
            <DeliveredProcedureOutlined />
            <span>Delivery Requests</span>
          </NavLink>
        </Menu.Item>}
      </SubMenu>

      {isSubscribedToBookings && (
        <SubMenu
          key="appointments"
          title={
            <span>
              <Icon type="shop" />
              <span>Appointments</span>
            </span>
          }
        >
          <Menu.Item key="services">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/appointments/services"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="team" />
              <span>Services</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="bookings">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/appointments/bookings"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="team" />
              <span>Bookings</span>
            </NavLink>
          </Menu.Item>
          {isAdmin && (
            <Menu.Item key="settings">
              <NavLink
                to={blockAccess ? "/d/account#subscribe" : "/d/appointments/settings"}
                activeClassName="active"
                style={{ marginLeft: "30px" }}
              >
                <Icon type="tool" />
                <span>Settings</span>
              </NavLink>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {user.role_id === 1 ? (
        allBranches && allBranches.length > 1 ? (
          <SubMenu
            key="products"
            title={
              <span>
                <Icon type="shopping" />
                <span>{strings.products}</span>
              </span>
            }
          >
            {homeBranch && (
              <Menu.Item key={homeBranch.name}>
                <NavLink
                  to={blockAccess ? "/d/account#subscribe" : `/d/products`}
                  activeClassName="active"
                  style={{ marginLeft: "30px" }}
                >
                  <Icon type="shop" />
                  <span>{homeBranch.name}</span>
                </NavLink>
              </Menu.Item>
            )}
            {allBranches.map((branch) => {
              if (
                branch.name !== "Ecommerce" &&
                branch.name.toLowerCase() !== "home branch"
              ) {
                return (
                  <Menu.Item key={branch.name}>
                    <NavLink
                      to={blockAccess ? "/d/account#subscribe" : `/d/branch-products/${branch.id}`}
                      activeClassName="active"
                      style={{ marginLeft: "30px" }}
                    >
                      <Icon type="shop" />
                      <span>{branch.name}</span>
                    </NavLink>
                  </Menu.Item>
                );
              }
              return null;
            })}
          </SubMenu>
        ) : allBranches && allBranches.length === 1 ? (
          <Menu.Item key="products">
            <NavLink to={blockAccess ? "/d/account#subscribe" : `/d/products`} activeClassName="active">
              <Icon type="shop" />
              <span>{strings.products}</span>
            </NavLink>
          </Menu.Item>
        ) : (
          <Menu.Item key="products">
            <NavLink to={blockAccess ? "/d/account#subscribe" : "/d/products"} activeClassName="active">
              <Icon type="shop" />
              <span>{strings.products}</span>
            </NavLink>
          </Menu.Item>
        )
      ) : user.role && user.role.id === 2 ? (
        <Menu.Item key={user.business_branch.name}>
          <NavLink
            to={blockAccess ? "/d/account#subscribe" : `/d/branch-products/${user.business_branch.id}`}
            activeClassName="active"
          >
            <Icon type="shop" />
            <span>{strings.products}</span>
          </NavLink>
        </Menu.Item>
      ) : null}

      <Menu.Item key="customers">
        <NavLink to={blockAccess ? "/d/account#subscribe" : "/d/customers"} activeClassName="active">
          <Icon type="team" />
          <span>{strings.customers}</span>
        </NavLink>
      </Menu.Item>

      {/* {isAdminIsManager && <Menu.Item key="birthday-offers">
        <NavLink to={blockAccess ? "/d/account#subscribe" : "/d/birthdayandoffers"} activeClassName="active">
          <Icon type="team" />
          <span>{strings.birthdayOfferAndMessage}</span>
        </NavLink>
      </Menu.Item>} */}

      {isSubscribedToFormBuilder &&
        activeForms.length > 0 &&
        activeForms.map((form) => (
          <Menu.Item key={form.form_name}>
            <NavLink to={blockAccess ? "/d/account#subscribe" : `/d/forms?${form.id}`} activeClassName="">
              <Icon type="book" />
              <span>{form.form_name}</span>
            </NavLink>
          </Menu.Item>
        ))}

      {isAdminIsManager && isSubscribedToMultiLevel && (
        <SubMenu
          key="sub4"
          title={
            <span>
              <Icon type="gift" />
              <span>{strings.multiLevelLoyalty}</span>
            </span>
          }
        >
          <Menu.Item key="multi_level_programs">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/apps/multi-level/programs"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="gateway" />
              <span>{strings.loyaltyPrograms}</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="members">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/apps/multi-level/members"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="gateway" />
              <span>{strings.members}</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      )}

      <Menu.Item key="order-menu">
        <NavLink to={blockAccess ? "/d/account#subscribe" : "/d/orders"} activeClassName="active">
          <Icon type="bars" />
          <span>{strings.orders}</span>
        </NavLink>
      </Menu.Item>

      {isLedgerActive && isAdminIsManager && <Menu.Item key="lehyah">
        <NavLink to={blockAccess ? "/d/account#subscribe" : "/d/accounting"} activeClassName="active">
          <BarChartOutlined />
          <span>{strings.lehjahService}</span>
        </NavLink>
      </Menu.Item>}

      {isAdmin && (
        <Menu.Item key="adds_on">
          <NavLink to={blockAccess ? "/d/account#subscribe" : "/d/adds_on"} activeClassName="active">
            <Icon type="appstore" />
            <span>{strings.addOns}</span>
          </NavLink>
        </Menu.Item>
      )}

      

      {user.role_id === 1 && (
        <SubMenu
          key="sub3"
          title={
            <span>
              <Icon type="setting" />
              <span>{strings.settings}</span>
            </span>
          }
        >
          <Menu.Item key="loyalty_programs">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/loyalty_programs"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="gift" />
              <span>{strings.loyaltyPrograms}</span>
            </NavLink>
          </Menu.Item>

          {isAdmin && isWhatsAppReferralActive && (
            <Menu.Item key="referral_program">
              <NavLink
                to={"/d/referral_programs"}
                activeClassName="active"
                style={{ marginLeft: "30px" }}
              >
                <UserAddOutlined />
                <span>{strings.referralProgram}</span>
                <Tag className="ml-1" color="green">
                  {strings.new}
                </Tag>
              </NavLink>
            </Menu.Item>
          )}

          {user.role_id === 1 && (
            <Menu.Item key="cashiers">
              <NavLink
                to={blockAccess ? "/d/account#subscribe" : "/d/branches"}
                activeClassName="active"
                style={{ marginLeft: "30px" }}
              >
                <Icon type="usergroup-add" />
                <span>{strings.staffAndBranches}</span>
              </NavLink>
            </Menu.Item>
          )}

          <Menu.Item key="account">
            <NavLink
              to="/d/account"
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="user" />
              <span>{strings.myAccount}</span>
            </NavLink>
          </Menu.Item>

          {/* { <Menu.Item key="lehyah">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/accounting"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="gift" />
              <span>{strings.lehjahService}</span>
            </NavLink>
          </Menu.Item>} */}

          <Menu.Item key="addCustomFields">
            <NavLink
              to={blockAccess ? "/d/account#subscribe" : "/d/apps/custom-fields"}
              activeClassName="active"
              style={{ marginLeft: "30px" }}
            >
              <Icon type="plus" />
              <span>Add Custom Fields</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      )}

      <Menu.Item key="sell">
        <NavLink to="/sales" activeClassName="active">
          <Icon type="shopping-cart" />
          <span>{strings.switchToSellMode}</span>
        </NavLink>
      </Menu.Item>

      <div style={{ width: "100%", textAlign: "center", marginTop: "30px" }}>
        <Button onClick={() => signoutUser(client)}>{strings.signOut}</Button>
      </div>

      <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
        <a
          href="https://loystar.co/partner"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon type="gift" />
          <span style={{ marginLeft: ".5rem" }}>
            {strings.getFreeSubscription}
          </span>
        </a>
      </div>

      <Modal
        title={`Add New ${integrationDetails.title} Integration`}
        onCancel={() => {
          setShowIntegrationModal(false);
        }}
        destoryOnClose
        open={showIntegrationModal}
        okText={null}
        width="70%"
        bodyStyle={{
          height: "70vh",
        }}
        footer={null}
      >
        {!iframeIsLoaded && (
          <div
            style={{
              width: "90%",
              height: "80%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon type="loading" />
          </div>
        )}
        <iframe
          title={integrationDetails.title}
          src={integrationDetails.src}
          frameBorder="0"
          style={{
            border: "0",
            width: "100%",
            height: "100%",
          }}
          onLoad={() => {
            setIframeIsLoaded(true);
          }}
        />
      </Modal>
    </Menu>
  );
};

SidebarMenuItems.propTypes = {
  signoutUser: PropTypes.func.isRequired,
  allBranches: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  subscribedApps: state.appStore.subscribedApps,
  isBeepBeepActive: state.appStore.isBeepBeepActive,
  isWhatsAppReferralActive: state.appStore.isWhatsAppReferralActive,
  isLedgerActive: state.appStore.isLedgerActive,
  // isSubscribedToMultiLevel: state.multiLevel.isSubscribed,
  subscriptionStatus: state.renewal.subscriptionStatus,
  isPurchaseOrderActive: state.appStore.isPurchaseOrderActive,
});

export default connect(
  mapStateToProps,
  {
    signoutUser,
    fetchActiveApps,
    changeMultiLevelStatus,
    getAllBusinessBranches,
    getAllProducts,
    getAllBranchProducts
  },
  null
)(withRouter(SidebarMenuItems));
