import React from "react";
import { Input, Modal, Button, Badge } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";
import { clearShowVariantModalData } from "../../../../redux/actions/saleActions"
import { connect } from "react-redux";

import "./index.scss";
import strings from "../../../../strings";

const FormItem = Form.Item;

class VariantsModal extends React.Component {
  state = {
    loading: false,
    selectedVariants: {},
    groupedVariants: {},
    quantity: 1.0,
    modalIsUp: false,
    tryUpdate: false
  };

  componentDidMount() {
    const { form } = this.props;
    form.setFieldsValue({ quantity: this.state.quantity });
  }

  componentWillReceiveProps(nextProps) {
    const { showVariantModal } = nextProps;
    const { modalIsUp } = this.state;
    if (showVariantModal && !modalIsUp) {
      const { product } = this.props;
      const groupedVariants = {};
      const selectVariantsObj = {};
      product.variants.forEach(variant => {
        if (groupedVariants[variant.type]) {
          groupedVariants[variant.type].push(variant);
        } else {
          selectVariantsObj[variant.type] = [];
          groupedVariants[variant.type] = [variant];
        }
      });
      this.setState({
        selectedVariants: selectVariantsObj,
        groupedVariants,
        modalIsUp: true,
        quantity: 1.0,
      });
    }
  }

  componentDidUpdate(nextProps){
    const { showVariantModal } = nextProps;
    const { modalIsUp, tryUpdate } = this.state;
    if(!showVariantModal && modalIsUp){
      const { product } = this.props;
      const groupedVariants = {};
      const selectVariantsObj = {};
      product.variants.forEach(variant => {
        if (groupedVariants[variant.type]) {
          groupedVariants[variant.type].push(variant);
        } else {
          selectVariantsObj[variant.type] = [];
          groupedVariants[variant.type] = [variant];
        }
      });
      this.setState({
        groupedVariants,
        modalIsUp: false
      });
    }
  }

  handleAddCategory = () => {
    const { form, addNewCategory, getAllCategories, toggleModal } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      this.setState({ loading: true });
      addNewCategory(values).then(res => {
        if (res && res.status === 200) {
          form.resetFields();
          this.setState({ loading: false });
          getAllCategories();

          Modal.success({
            title: strings.youveSuccessfullyAddedANewCategory,
          });

          toggleModal();
        }
      });
    });
  };

  displayVariants = () => {
    const variants = this.state.groupedVariants;
    const { selectedVariants } = this.state;
    const { currency, product } = this.props;
    const keys = Object.keys(variants);
    return keys.map(key => {
      return (
        <div key={key}>
          <span className="type">{`${key}:`}</span>
          <div className="variant-wrapper">
            {variants[key].map(variant => {
              return (
                <Badge
                  key={variant.id}
                  count={variant.quantity}
                  className={
                    selectedVariants[key].indexOf(variant) >= 0
                      ? strings.variantSelected
                      : strings.variant
                  }
                  onClick={() => {
                    this.selectVariant(variant, key);
                  }}
                >
                  <span style={{ paddingRight: "10px" }}>{variant.value}</span>
                  <span style={{ paddingRight: "10px" }}>-</span>
                  <span>
                    {currency}
                    {this.props.product.tax_rate
                      ? (
                          (product.tax_rate / 100) * variant.price +
                          parseFloat(variant.price)
                        ).toFixed(2)
                      : variant.price}
                  </span>
                </Badge>
              );
            })}
          </div>
        </div>
      );
    });
  };

  selectVariant = (variant, key) => {
    const { selectedVariants } = this.state;
    const keys = Object.keys(selectedVariants);
    let check = false;
    keys.forEach(selectedKey => {
      if (selectedVariants[selectedKey].length > 0 && key !== selectedKey) {
        if (selectedVariants[selectedKey][0].price !== variant.price) {
          check = true;
        }
      }
    });
    if (check) {
      Modal.warning({
        title: strings.thePreviousVariantSelectionNotAvailable,
      });
      return;
    }
    selectedVariants[key] = [variant];
    this.setState({
      selectedVariants,
    });
  };

  inStock = quantity => {
    let variants = this.getSelectedVariants();
    let result = true;
    let outOfStockVariant = {};
    try {
      variants.forEach(variant => {
        if (variant.quantityLeft) {
          if (quantity > variant.quantity) {
            result = false;
            outOfStockVariant = variant;
            throw new Error(strings.lowStock);
          }
        } else {
          if (quantity > variant.quantity) {
            result = false;
            outOfStockVariant = variant;
            throw new Error(strings.lowStock);
          }
        }
      });
    } catch (error) {
      return [result, outOfStockVariant];
    }
    return [result, outOfStockVariant];
  };

  changeQuantity = type => {
    let { quantity } = this.state;
    const { form } = this.props;
    const variants = this.getSelectedVariants();
    if (variants.length > 0) {
      if (type === "plus") {
        ++quantity;
      } else if (type === "minus") {
        --quantity;
        if (quantity < 0) {
          quantity = 0;
        }
      } else {
        if (!isNaN(type) && Number(type) >= 0) {
          quantity = Number(type);
        } else {
          Modal.warning({
            title: strings.pleaseSupplyPositiveQuantityNumber,
          });
        }
      }
      const result = this.inStock(quantity);
      if (result[0]) {
        form.setFieldsValue({ quantity });
        this.setState({
          quantity,
        });
      } else {
        Modal.warning({
          title: `${strings.lowStockFor} ${result[1].type}: ${result[1].value}`,
        });
      }
    } else {
      Modal.warning({
        title: strings.pleaseSelectAVariant,
      });
    }
  };
  

  handleOnClick = () => {
    const { onClick, product, form } = this.props;
    let variants = this.getSelectedVariants();

    let productCopy = { ...product };

    delete productCopy.variants;
    const item = { ...productCopy, variants };

    //  console.log("Item ", item);

    item.variants = variants;
    // item.selectedVariants = variants;

    item.quantity = this.state.quantity;
    if (variants.length === 0) {
      Modal.warning({
        title: strings.pleaseSelectAVariant,
      });
    } else {
      onClick(item, "bulk");
      form.setFieldsValue({ quantity: this.state.quantity });
      this.setState({
        modalIsUp: true,
        quantity: 1.0,
      });
      this.props.clearShowVariantModalData();
    }
  };

  getSelectedVariants = () => {
    const { selectedVariants } = this.state;
    let variants = [];
    Object.keys(selectedVariants).forEach(variant => {
      variants = [...variants, ...selectedVariants[variant]];
    });
    return variants;
  };

  clearSelectedVariants = () => {
    const { form } = this.props;
    const { selectedVariants } = this.state;
    Object.keys(selectedVariants).forEach(key => {
      selectedVariants[key] = [];
    });
    this.setState({
      selectedVariants,
    });
    form.setFieldsValue({ quantity: this.state.quantity });
  };

  render() {
    const { form, showVariantModal, toggleModal, product } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        title={`${product.name} ${product.price}`}
        okText={strings.add}
        cancelText={strings.cancel}
        onOk={this.handleOnClick}
        onCancel={toggleModal}
        destoryOnClose
        visible={showVariantModal}
        okButtonProps={{ loading: this.state.loading }}
      >
        <div className="variants">
          <span>Variants:</span>
          <Button
            htmlType="button"
            className="clear-button"
            disabled={!(this.getSelectedVariants().length > 0)}
            onClick={this.clearSelectedVariants}
          >
            {strings.clearVariants}
          </Button>
          <div>{this.displayVariants()}</div>
        </div>

        <Form layout="vertical" >
          <span>{strings.quantity}:</span>
          <div className="variant_quantity_container">
            <div>
              <Button
                htmlType="button"
                onClick={() => {
                  this.changeQuantity("minus");
                }}
              >
                -
              </Button>
            </div>
            <FormItem label="">
              {getFieldDecorator("quantity", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseEnterQuantity,
                  },
                ],
              })(
                <Input
                  size="large"
                  onChange={() => {
                    this.props.form.validateFields((err, values) => {
                      this.changeQuantity(values.quantity);
                    });
                  }}
                  className="variant_input center"
                  type="number"
                />
              )}
            </FormItem>
            <div>
              <Button
                htmlType="button"
                onClick={() => {
                  this.changeQuantity("plus");
                }}
              >
                +
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }
}

VariantsModal.propTypes = {
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  sales: PropTypes.object.isRequired,
}; 
const VariantsModalForm = Form.create()(VariantsModal);

const mapStateToProps = (state) => ({
  products: state.product.allProducts,
  branchProducts: state.product.branchProducts,
});

export default connect(mapStateToProps, {clearShowVariantModalData})(VariantsModalForm);
