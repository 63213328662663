import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Modal, Select } from "antd";
import { Form } from "@ant-design/compatible";
import {
  editMerchantAccount,
  setCurrentUser,
} from "../../../../../../../../redux/actions/authActions";
import { getAllBusinessBranches } from "../../../../../../../../redux/actions/branchActions";
import store from "../../../../../../../../redux/store";
import api from "../../../../../../../../api";
import strings from "../../../../../../../../strings";
import { loginToVpay, addCluster, addTill, getCluster } from "../../../../functions";

const { Option } = Select;

// const minimum_sms_units = process.env.REACT_APP_MINIMUM_SMS_UNITS;

/**
 * @typedef IBank
 * @type {object}
 * @property {number} id - ID.
 * @property {string} name - bank name.
 * @property {string} code - bank code.
 * @property {string} slug - bank slug
 * @property {string} type - bank type
 * @property {boolean} active - is bank active
 * @property {string} country - country which bank is located
 * @property {string} currency - bank currency
 * @property {string} gateway - bank gateway
 * @property {string} longcode
 * @property {boolean} pay_with_bank
 * @property {boolean} is_deleted
 * @property {string} updated_at
 * @property {string} created_at
 *
 */
class AccountDetails extends Component {
  /**
   * @typedef IState
   * @type {object}
   * @property {boolean} isLoading
   * @property {IBank[]} banks
   */

  /** @type {IState} */
  state = {
    isLoading: false,
    banks: [],
  };

  componentDidMount() {
    this.props.getAllBusinessBranches();
    const { updateState } = this.props;

    updateState({
      isDisabled: true,
      handlerFn: this.handleSubmission,
    });

    localStorage.removeItem("merchant-phone");

    this.fetchNigerianBanks();
  }

  fetchNigerianBanks = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await api.merchants.fetchNigerianBanks();

      this.setState({
        isLoading: false,
        banks: res.data,
      });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  };
  handleSubmission = async () => {
    const {
      form,
      closeModal,
      updateState,
      updatePayout,
      user,
      editMerchantAccount,
      allBranches,
    } = this.props;

    const homeBranch = allBranches.find(branch => branch.name?.toLowerCase()?.match("home"));

    updateState({
      isLoading: true,
    });

    try {
      const values = await form.validateFields();

      // console.log({
      //   account_number: values.account_number.toString(),
      //   bank_code: values.bank_code,
      // });
      const selectdBank = this.state.banks.find(
        bank => bank.code === values.bank_code
      );

      const res = await api.merchants.updateAccountDetails({
        ...values,
        bank_name: selectdBank.name,
      });

      updateState({
        isLoading: false,
      });

      console.log(res.data);

      if (res.status === 200) {
        this.generateVpayAccount(homeBranch.id);
        Modal.success({
          title: res.data.message,
          onOk: () => {
            closeModal()},
        });

        const resp = await editMerchantAccount({
          ...user,
          commision: 1,
        });

        updatePayout(resp.data.data);

        localStorage.setItem("userDetails", JSON.stringify(resp.data.data));
        store.dispatch(setCurrentUser(resp.data.data));
      }
    } catch (error) {
      console.log(error);
      updateState({
        isLoading: false,
      });
    }
  };

  updateSMSPoints = async numOfSMSUnits => {
    try {
      if (numOfSMSUnits) {
        const res = await api.merchants.updateSMSPoints(numOfSMSUnits);

        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  validateAccountNo = (rule, value, callback) => {
    if (isNaN(value)) {
      callback(strings.onlyNumbersAllowed);
    }
    // console.log(value);
    if (!isNaN(value) && value.length === 10) {
      this.props.updateState({
        isDisabled: false,
      });
      callback();
    }

    if (!isNaN(value) && value.length > 10) {
      this.props.updateState({
        isDisabled: true,
      });
      callback(strings.yourAccountMustBe10Digits);
    }

    if (!isNaN(value) && value.length < 10) {
      this.props.updateState({
        isDisabled: true,
      });
      callback(strings.yourAccountMustBe10Digits);
    }
  };

  validateBVN = (rule, value, callback) => {
    if (isNaN(value)) {
      callback(strings.onlyNumbersAllowed);
    }

    if (!isNaN(value) && value.length === 11) {
      this.props.updateState({
        isDisabled: false,
      });
      callback();
    }

    if (!isNaN(value) && value.length > 11) {
      this.props.updateState({
        isDisabled: true,
      });
      callback(strings.yourBvnMustBe11);
    }

    if (!isNaN(value) && value.length < 11) {
      this.props.updateState({
        isDisabled: true,
      });
      callback(strings.yourBvnMustBe11);
    }
  };

  generateVpayAccount = (id) => {
    this.setState({ vpayLoading: true });
    loginToVpay()
      .then(res => {
        addCluster()
          .then(data => {
            getCluster()
              .then(res => {
                this.setState({ vpayLoading: false });
                if (res.status === 200) {
                  this.handleAddTill(id)
                  this.setState({ branchesModalOpen: true });
                }
              })
              .catch(err => console.log(err));
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log({ err }));
  };

  handleAddTill = id => {
    this.setState({ vpayLoading: true });
    addTill(id)
      .then(res => {
        this.setState({ vpayLoading: false });
        if (res.status === 200 && res.data.status === "success") {
          Modal.success({
            title: "Bank account created successfully",
            onOk: this.getAllBranchesNuban,
          });
        } else
          return Modal.error({
            title: "Branch registration failed",
            content: res.data.message,
          });
      })
      .catch(err => {
        this.setState({ vpayLoading: false });
        Modal.error({
          title: "Branch registration failed",
          content:
            "Add a new branch manager with phone number or update the existing branch manager account to include a phone number",
        });
      });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    const { banks } = this.state;

    return (
      <Form layout="vertical">
        <Form.Item label={strings.selectYourBank}>
          {getFieldDecorator("bank_code", {
            rules: [
              {
                required: true,
                message: strings.pleaseSelectBank,
              },
            ],
          })(
            <Select
              loading={this.state.isLoading}
              showSearch
              placeholder={strings.selectYourBank}
              size="large"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {banks.map(bank => (
                <Option key={bank.id} value={bank.code}>
                  {bank.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={strings.enterBankAccountNumber} className="">
          {getFieldDecorator("account_number", {
            rules: [
              {
                validator: this.validateAccountNo,
                type: "number",
              },
            ],
          })(<Input style={{ width: "100%" }} size="large" />)}
        </Form.Item>

        <Form.Item label={strings.enterBvnNumber} className="">
          {getFieldDecorator("bvn", {
            rules: [
              {
                validator: this.validateBVN,
              },
            ],
          })(
            <Input
              onChange={e => this.setState({ bvnValue: e.target.value })}
              style={{ width: "100%" }}
              size="large"
            />
          )}
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  allBranches: state.branch.allBranches,
});

const AccountDetailsForm = Form.create()(AccountDetails);

export default connect(mapStateToProps, { editMerchantAccount, getAllBusinessBranches })(
  AccountDetailsForm
);
