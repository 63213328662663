import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button } from "antd";
import { getAllCustomers } from "../../../../../../redux/actions/customerActions";
import ReactToPrint from "react-to-print";
import CartList from "../../../../../Sales/_partials/CartList";
import SalesReceipt from "../../../../../Sales/_partials/SalesReceipt";
import strings from "../../../../../../strings";
import moment from "moment";

class ViewOfflineDetails extends Component {
  handleDraftPayment = () => {
    const {
      transaction,
      selectedCustomer,
      customer,
      id,
    } = this.props.currentBill;

    this.props.history.push({
      pathname: "/sales",
      state: {
        fromDraftSales: true,
        transaction,
        selectedCustomer: selectedCustomer ? selectedCustomer : "",
        customerValue: customer && customer.user_id ? customer.user_id : null,
        bill_id: id,
      },
    });
  };

  render() {
    const { isViewDraftModalOpen, closeModal, currentBill, user } = this.props;

    const {
      total,
      transaction,
      selectedCustomer,
      formattedCustomerName,
      customer,
      status,
      staff,
      allLoyaltyPrograms,
      created_at,
    } = currentBill;


    // const isAdminIsManager =
    //   user.role_id === 1 || (user.role && user.role.id === 2);

    return (
      <Modal
        title={strings.billDetails}
        cancelText={strings.cancel}
        open={isViewDraftModalOpen}
        destroyOnClose
        onCancel={closeModal}
        footer={[
          <div>
            <ReactToPrint
              trigger={() => <Button type="primary">Print Receipt</Button>}
              content={() => this.componentRef}
            />
            <div style={{ display: "none" }}>
              <SalesReceipt
                currency={user.currency || user.employer.currency}
                saleTotal={total}
                transaction={transaction}
                purchaseTime={moment(created_at || Date.now()).format("DD-MM-YYYY hh:mm a")}
                user={user}
                selectedCustomer={selectedCustomer}
                customer={customer}
                allLoyaltyPrograms={allLoyaltyPrograms}
                ref={el => (this.componentRef = el)}
              />
            </div>
          </div>,
        ]}
      >
        <div>
          <p>
            <strong>{strings.customerName}</strong>: {formattedCustomerName}
          </p>

          <p>
            <strong>{strings.servedBy}</strong>: {staff ? staff : strings.admin}
          </p>
          <p>
            <strong>{strings.products}</strong>
          </p>
          <div className="cart details-cart">
            {transaction && transaction.length && (
              <CartList
                data={transaction}
                increaseItemQuantity={null}
                removeItemFromCart={null}
                reduceItemFromCart={null}
                handleShowBulkModal={null}
              />
            )}
          </div>
          <p>
            <strong>{strings.status}</strong>: {status || ""}
          </p>
          <p>
            <strong>{strings.total}</strong>: {total || "-"}
          </p>
        </div>
      </Modal>
    );
  }
}

const mapStateToprops = state => ({
  customers: state.customer.allCustomers,
  user: state.auth.user,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
});

export default connect(mapStateToprops, { getAllCustomers })(
  ViewOfflineDetails
);
