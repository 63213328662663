import { Checkbox, Input, InputNumber } from "antd";

export const PaymentLabelContent = ({
  splitPaymentActive,
  allPaymentLabels,
  totalAmount,
  amountRemaining,
  setAmountRemaining,
  setSelectedPaymentLabel,
  selectedPaymentLabel,
  strings,
  setTotalAmount,
  valueToRemain,
  totalAmountToBePaid,
  splitPaymentMethods,
  message
}) => {
  return (
    <div>
    <div>{strings.hasPaymentBeenMade}</div>
    <div className="mt-2">
      {splitPaymentActive && (
        <div style={{ marginTop: 10 }}>{strings.amountPaidSplit}</div>
      )}
      {splitPaymentActive && (
        <InputNumber
          type="text"
          id="transfer-reference-input"
          // className="mt-2"
          placeholder={strings.amountPaidSplit}
          required
          value={
            splitPaymentMethods.method1.method !== null
              ? Number(totalAmount).toFixed(2)
              : Number(amountRemaining).toFixed(2)
          }
          onChange={(value) => {
            if (
              splitPaymentMethods.method1.method === null &&
              value >= Number(totalAmountToBePaid)
            )
              return message.error(
                "Cannot use split payment on complete amount"
              );
            setAmountRemaining(value);
            valueToRemain = value;
            setTotalAmount(totalAmountToBePaid - value);
          }}
          disabled={splitPaymentMethods.method1.method !== null}
          style={{ width: "100%" }}
        />
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        {allPaymentLabels.map((labels, index) => {
          return (
            <p
              key={index}
              style={{
                padding: "5px 10px",
                border: "1px solid black",
                cursor: "pointer",
              }}
            >
              {labels.label_name}
              <Checkbox
                checked={selectedPaymentLabel === labels.id} // Fixed spelling of selectedPaymentLabel
                value={labels.id}
                onChange={(e) => {
                  const isChecked = e.target.checked; // Get the current checked state
                  if (isChecked) {
                    setSelectedPaymentLabel(labels.id); // Set this label as selected
                  } else {
                    setSelectedPaymentLabel(null); // Clear selection if unchecked
                  }
                }}
              />
            </p>
          );
        })}
      </div>
      <Input
        type="text"
        id="transfer-reference-input"
        className="mt-2"
        placeholder={strings.transactionReference}
        required
      />
    </div>
  </div>
  );
};
