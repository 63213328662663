import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Select, Input, Button, InputNumber, message, Upload, Image } from "antd";
import { Icon, Form,  } from "@ant-design/compatible";
import api from '../../../../../../api';
import strings from '../../../../../../strings';
import { getBase64 } from '../../../../../../utils/covertBase64';

const EditBundle = ({ form, open, onCancel, editGroup, user, getProductBundle  }) => {

    const FormItem = Form.Item, 
    Option = Select.Option
    const { TabPane } = Tabs;
    const { getFieldDecorator, setFieldsValue } = form;
    const { name, price, barcode, quantity, products, description, images, id } = editGroup;
    const [ bundleProducts, setBundleProducts ] = useState([]);
    const [ productsName, setProductsName ] = useState([]);
    const [ textProdId, setTextProdId ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ quantit, setQuantit ] = useState([]);
    const [ openQuantity, setOpenQuantity ] = useState(false);
    const [ singleQty, setSingleQty ] = useState("");
    const [ fileList, setFileList ] = useState([]);
    const [ addloading, setAddloading ] = useState(false);
    const maxFileSize = 4000000; 
    const hint = {
        color: "orange",
        fontSize: "13px",
    };

    useEffect(()=>{
        setBundleProducts(products);
        setQuantit(quantity);
        setFileList(images)
        let prodId = [];
        let prodName = [];
        products && products.map((prod)=>{
            prodId.push(prod.id);
            prodName.push(prod.name)
        });

        setFieldsValue({ product_ids: [...prodId] })
        setTextProdId(prodId);
        setProductsName(prodName);
        console.log("eidt", editGroup);
        
    }, [editGroup]);

    const handleQuantityChoice = (value) =>{
        setQuantit((prev)=>{
            return [
                ...prev,
                value.toString()
            ]
        })
        setOpenQuantity(false);
        setSingleQty("");
   }
    const handleRemoveProductPicture = file => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.filter((file, i) => i !== index)
        setFileList(newFileList);
    };

  const handleProductPicture = async file => {
    if (file.size > maxFileSize) {
      message.warning(strings.fileTooLargeLessThan4Mb);
      return;
    }

    if (!file.url && !file.preview) {
        file.preview = await getBase64(file);
    }

    setFileList((prev)=>{
        return [
            ...prev,
            file
        ]
    })
  };

    const handleChoice = (e) => {
        const { code, target } = e;
        const { value } = target;
    
        if(value === "") return;
        setLoading(true);
        api.HttpClient.get(`/search_product/${value}`)
        .then((res)=>{
            if(res.status === 200){
                setLoading(false);
                if(res.data.length > 0){
                    setBundleProducts((prev)=>{
                        return [
                            ...prev,
                            ...res.data
                        ]
                    });
                }
            }
        })
        .catch((err)=>{
            setLoading(false);
            console.log(err);
        })
    }

    const handleEdit = () =>{
        form.validateFields(async (err, values)=>{
            if(err === null){
                const { name, price, description, barcode, product_ids } = values;
                if(product_ids.length === 0){
                    return Modal.error({
                        title: "Please select at least 2 products!"
                    })
                }
                
                const formData = new FormData();
                formData.append("data[name]", name);
                // formData.append("data[price]", quantity);
                formData.append("data[price]", price);
                barcode && formData.append("data[barcode]", barcode);
                description && formData.append("data[description]", description);
                fileList.map((file) => {
                    formData.append("data[images][]", file)
                });
                product_ids.map((productId) => {
                    formData.append("data[product_ids][]", productId);
                });
                quantity.map((qty) => {
                    formData.append("data[quantity][]", qty);
                });

                setAddloading(true);
                const response = await api.product.editBundleProducts(id, formData);

                if(response.status === 200){
                    setAddloading(false);
                    Modal.success({
                        title: `Bundle - ${name} edited successfully`,
                        onOk: () =>{
                            getProductBundle();
                            onCancel();
                        }
                    });
                }else{
                    setAddloading(false);
                    Modal.error({
                        title: `${response.err.message}`,
                        onOk: () =>{
                            onCancel();
                        }
                    });
                }
                

            }
        })
   }


  return (
    <>
        <Modal
            open={open}
            onCancel={onCancel}
            title={`Edit - ${name}`}
            destroyOnClose
            okButtonProps={{loading: addloading}}
            onOk={()=>handleEdit()}
        >
            <Form
                layout='vertical'
            >
            <FormItem label="Select Products">
                    {getFieldDecorator("product_ids", {
                        rules: [
                        {
                            required: true,
                            message: "Please select at least 2 products!",
                        },
                        ],
                    })(
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            placeholder="Select Products"
                            optionFilterProp="children"
                            size="large"
                            value={productsName}
                            onKeyDown={(e)=>handleChoice(e)}
                            onDeselect={(e)=>{
                                const index = textProdId.indexOf(e)
                                const newText = textProdId.filter((id)=>{
                                    return id !== e
                                });
                                if( index > -1){
                                    const qty = [ ...quantit ]
                                    qty.splice(index, 1);
                                    setQuantit(qty);
                                }
                                setTextProdId(newText);
                            }}
                            onSelect={(e)=>{
                                setOpenQuantity(true)
                                setTextProdId((prev)=>{
                                    return [
                                        ...prev,
                                        e
                                    ]
                                });
                            }}
                            style={{ width: "100%" }}
                            loading={loading}
                            filterOption={(input, option) => {
                                return (
                                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            disabled={false}
                        >
                        {bundleProducts &&
                            bundleProducts.map(bundleProduct => (
                            <Option
                                key={bundleProduct.name}
                                value={bundleProduct.id}
                                id={bundleProduct.id}
                                name={bundleProduct.name}
                            >
                                {bundleProduct.name ? bundleProduct.name : "N/A"} 
                            </Option>
                            ))}
                        </Select>
                    )}
                </FormItem>
                <FormItem label="Name">
                    {getFieldDecorator("name", {
                        initialValue: name,
                        rules: [{ required: true, message: "Please enter name" }], 
                    })(
                            <Input 
                            size="large" 
                            placeholder="Name"
                        />
                        )}
                </FormItem>

                <FormItem label="Price">
                    {getFieldDecorator("price", {
                        initialValue: price,
                        rules: [{ required: true, message: "Please enter price" }], 
                    })(
                            <Input 
                            size="large" 
                            placeholder="Name"
                        />
                        )}
                </FormItem>

                <FormItem label="Description">
                    {getFieldDecorator("description", {
                        initialValue: description,
                        // rules: [{ required: true, message: "Please enter decsription" }], 
                    })(
                            <Input 
                            size="large" 
                            placeholder="Name"
                        />
                        )}
                </FormItem>

                <FormItem label="Product Sku">
                    {getFieldDecorator("barcode", {
                        initialValue: barcode,
                        // rules: [{ required: true, message: "Please enter price" }], 
                    })(
                            <Input 
                            size="large" 
                            placeholder="Name"
                        />
                        )}
                </FormItem>

                <FormItem label={strings.productImage}>
                    {getFieldDecorator("images")(
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Upload
                          // {...this.uploadProps}
                          fileList={fileList}
                          beforeUpload={handleProductPicture}
                          onRemove={handleRemoveProductPicture}
                        >
                          <Button>
                            <Icon type="upload" /> {strings.selectProductImage}
                          </Button>
                          <p style={hint}>
                            {user.currency === "NGN"
                              ? strings.productsWithPicturesArePublished
                              : ""}
                          </p>
                        </Upload>
                      </div>
                    )}
                  </FormItem>
                    {fileList && fileList.length > 0 && (
                      <div style={{display: "flex", alignItems: "center", gap:15, marginBottom: 20}}>
                        {fileList.map((file, index) => {
                          return (
                            <div style={{position: "relative"}}>
                              <Image
                                    src={file.preview || file}
                                    alt="images"
                                    key={index}
                                    height={60}
                                    width={60}
                              />
                               <Icon 
                                    type="close" 
                                    style={{
                                    position: "absolute", 
                                    right: 5, 
                                    cursor: "pointer"
                                    }}  
                                    onClick={() => handleRemoveProductPicture(file)}
                              />
                            </div>
                          )
                        })}
                      </div>
                    )}
            </Form>
            
        </Modal>
        <Modal
            open={openQuantity}
            onCancel={()=>setOpenQuantity(false)}
            title={`Choose quantity to add`}
            footer={null}
            destroyOnClose
        >
            <p>Select Quantity</p>
            <InputNumber
                value={singleQty}
                onChange={(value)=>setSingleQty(value)}
                onKeyDown={(e)=>{
                    if(e.code === "Enter"){
                        handleQuantityChoice(e.target.value)
                    }
                }}
            />
            <Button
                type='primary'
                onClick={()=>handleQuantityChoice(singleQty)}
            >
                Add
            </Button>
        </Modal>
    </>
  )
}

const EditBundleForm = Form.create()(EditBundle)

export default EditBundleForm;