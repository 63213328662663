import React, { useRef } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Select,
  message,
  Upload,
  Spin,
} from "antd";
import { Form, Icon } from "@ant-design/compatible";
import moment from "moment";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { UpdateSenderid } from "./queryfunction";
// import { useQuery, useLazyQuery } from "@apollo/client";
// import { UPDATE_MERCHANT_SENDERID } from "../Apps/MultiLevelLoyalty/Loyalties/constants";

import {
  editMerchantAccount,
  setCurrentUser,
  checkEmailAvailability,
  checkPhoneAvailability,
} from "../../../../redux/actions/authActions";
import ChangeLanguage from "../../../../components/ChangeLanguage";
import { UploadOutlined } from "@ant-design/icons";
import SMSTopUpModal from "./_partials/SMSTopUpModal";
import api from "../../../../api";
import { toggleRenewalModal } from "../../../../redux/actions/renewalActions";
import strings from "../../../../strings";
import { QRCode } from "react-qrcode-logo";

const Option = Select.Option;
const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class AccountScreen extends React.Component {
  state = {
    loading: false,
    state: "",
    country: "",
    sms_balance: null,
    countries: [],
    isSMSToggleModalOpen: false,
    countryCodes: null,
    isEmailLoading: false,
    isPhoneLoading: false,
    businessTypes: [
      { id: 1, type: strings.foodAndDrinks },
      { id: 2, type: strings.salonAndBeauty },
      { id: 3, type: strings.fashionAndAccessories },
      { id: 4, type: strings.gymAndFitness },
      { id: 5, type: strings.travelAndHotel },
      { id: 6, type: strings.homeAndGifts },
      { id: 7, type: strings.washingAndCleaning },
      { id: 8, type: strings.gadgetsAndElectronics },
      { id: 9, type: strings.groceries },
      { id: 11, type: strings.photography },
      { id: 12, type: strings.bookstore },
      { id: 13, type: strings.healthcare },
      { id: 10, type: strings.others },
    ],
    isEditShopLink: false,
    senderID: null
  };

  componentDidMount() {
    const {
      form: { setFieldsValue },
      user,
    } = this.props;

    this.getMerchantSMSBalance();

    // fetch all countries with their currencies and calling codes
    fetch(`https://restcountries.com/v2/all`)
      .then((res) => res.json())
      .then((res) => {
        let countries = res.map((country) =>
          country.currencies ? country.currencies[0].code : null
        );
        let countryCodes = res
          .map(({ callingCodes, alpha3Code }) => ({
            callingCode: callingCodes[0],
            country: alpha3Code,
          }))
          .sort((a, b) => {
            // remove spaces from  calling code for efficent sorting
            const _a = Number(a.callingCode.replace(/ +/g, ""));
            const _b = Number(b.callingCode.replace(/ +/g, ""));

            return _a - _b;
          });

        // remove duplicated values
        countries = [...new Set(countries)];
        countryCodes = [...new Set(countryCodes)];

        this.setState({ countries, countryCodes });
      });

    this.setState({ contact_number: user.contact_number, email: user.email });

    setFieldsValue({
      ...user,
      business_type_id: user.business_type,
    });
    // Set Country and State Values
    this.setState({ country: user.country, state: user.state });
  }

  getMerchantSMSBalance = async () => {
    try {
      const res = await api.merchants.getMerchantSMSBalance();

      if (res.status === 200) {
        this.setState({ sms_balance: res.data.balance });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleShowChangePassword = () => {
    this.setState({ showChangePassword: !this.state.showChangePassword });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, editMerchantAccount } = this.props;
    const { country, state } = this.state;

    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.setState({ loading: true });

      // // filter out business type with business typd id
      // let business_type = businessTypes.filter(
      //   type => Number(values.business_type_id) === type.id
      // );
      // business_type = business_type[0].type;

      let contact_number =
        // eslint-disable-next-line eqeqeq
        values.contact_number[0] == "+"
          ? values.contact_number
          : `+${values.contact_number}`;

      contact_number = contact_number.split(" ").join("");

      const payload = {
        ...values,
        contact_number,
        postcode: values.postcode ? values.postcode : "",
      };

      if (this.state.imageUrl) {
        payload.image = this.state.imageUrl;
      }

      editMerchantAccount({
        ...payload,
        state,
        country,
      })
        .then((res) => {
          this.setState({ loading: false });
          const { status, data } = res;

          // check if merchant registration was successful
          if (status && status === 200) {
            this.props.setCurrentUser({ ...data });
            this.props.form.setFieldsValue({
              ...data,
            });

            Modal.success({
              title: strings.accountEditedSuccessfully,
            });
          } else {
            Modal.error({
              title: strings.somethingWentWrong,
              content: res.data.errors.full_messages.join(", "),
            });
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  };

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ state: val });
  }

  // [ updateSenderId, { loading, data, error} ] = useLazyQuery(UPDATE_MERCHANT_SENDERID)

  handleUpdateSenderId = async ( uRewardsId, senderID) => {
    if(senderID === "" || senderID === null ){
      return Modal.info({
        title: "Please input value"
      });

    }
    console.log(uRewardsId, senderID);
    await UpdateSenderid(uRewardsId, senderID)
    this.setState({ senderID: senderID, isEditShopLink: false})
    
  }

  toggleSmsTopUpModal = () =>
    this.setState({ isSMSToggleModalOpen: !this.state.isSMSToggleModalOpen });

  handleEmailValidation = async (rule, value, callback) => {
    const { checkEmailAvailability } = this.props;

    if (value) {
      if (!this.isValidEmail(value)) {
        callback();
      } else {
        this.setState({
          isEmailLoading: true,
        });

        try {
          const res = await checkEmailAvailability(value);

          const { email_available } = res.data;
          this.setState({ isEmailLoading: false });

          if (!email_available) {
            callback(strings.emailInUse);
          }
        } catch (err) {
          callback();
          this.setState({ isEmailLoading: false });
        }
      }
    } else {
      callback();
    }
  };

  isValidEmail = (email) => {
    const expression = /\S+@\S+\.\S+/;
    return expression.test(String(email).toLowerCase());
  };

  handlePhoneValidation = async (rule, value, callback) => {
    const { checkPhoneAvailability } = this.props;
    let phone = value.split(" ").join("");

    try {
      if (value) {
        this.setState({ isPhoneLoading: true });

        const res = await checkPhoneAvailability(phone);

        this.setState({ isPhoneLoading: false });

        const { phone_available } = res.data;

        if (!phone_available) {
          callback(strings.phoneNumberInUse);
        }
      } else {
        callback();
      }
    } catch (error) {
      callback();
      this.setState({ isPhoneLoading: false });
    }
  };

  render() {
    const {
      isEmailLoading,
      isPhoneLoading,
      countries,
      businessTypes,
      state,
      country,
      loading,
      contact_number,
      email,
      // isEditShopLink,
      // senderID
    } = this.state;

    const { user, toggleRenewalModal } = this.props;

    const { getFieldDecorator } = this.props.form;

    const setImageUrl = (url) =>
      this.setState((prev) => ({ ...prev.state, imageUrl: url }));

    const uploadProps = {
      name: "image",
      action: `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/upload`,
      onChange(info) {
        if (info.file.status === "done") {
          message.success(`${info.file.name} ${strings.fileUploadSuccessful}`);
          setImageUrl(info.file.response.imageCloudData.url);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} ${strings.fileUploadFailed}`);
        }
      },
    };

    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const currencyOptions =
      countries &&
      countries.map((country) => {
        if (country) {
          return <Option key={country}>{`${country}`}</Option>;
        }
        return null;
      });

    const businessTypeOptions = businessTypes.map((type) => (
      <Option key={type.id} value={type.type}>
        {type.type}
      </Option>
    ));

    const MyQrCode = () => {
      const qrDownloadRef = useRef(null);

      const exportImage = () => {
        exportComponentAsJPEG(qrDownloadRef, {
          fileName: user.business_name,
        });
      };

      let string = "";
      for (let letter of user.contact_number) {
        string = isNaN(Number(letter)) ? string : (string += letter);
      }

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <QRCode
            crossorigin="anonymous"
            size={200}
            quietZone={50}
            value={`https://loystar.shop/${string}`}
            logoImage={user.image}
            logoWidth={100}
            logoHeight={100}
            enableCORS={true}
            logoOpacity={0.6}
            qrStyle="dots"
            ecLevel="H"
            eyeRadius={[
              {
                outer: [10, 10, 0, 10],
                inner: [0, 10, 10, 10],
              },
              [10, 10, 10, 0],
              [10, 0, 10, 10],
            ]}
          />
          <div style={{ position: "absolute", left: "-100000vw" }}>
            <QRCode
              ref={qrDownloadRef}
              crossorigin="anonymous"
              size={1000}
              quietZone={100}
              value={`https://loystar.shop/${contact_number}`}
              logoImage={user.image}
              logoWidth={400}
              logoOpacity={0.6}
              logoHeight={400}
              enableCORS={true}
              qrStyle="dots"
              ecLevel="H"
              eyeRadius={[
                {
                  outer: [10, 10, 0, 10],
                  inner: [0, 10, 10, 10],
                },
                [10, 10, 10, 0],
                [10, 0, 10, 10],
              ]}
            />
          </div>
          <Button onClick={exportImage} className="mt-20">
            Download
          </Button>

          {/* <div
            style={{
              marginTop: "1rem"
            }}
          >
            <h4>Shop link:</h4>
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem"
            }}>
              {isEditShopLink ? 
              <p style={{margin: 0}}> 
                {`https://urewards.me/`}
                <Input 
                  placeholder={contact_number}
                  value={senderID}
                  onChange={(e)=>this.setState({ senderID: e.target.value })}
                />
              </p> : 
              <p style={{margin: "0"}}>{`https://urewards.me/${contact_number}`}</p>}
              
              {isEditShopLink ?  
                <Button 
                  onClick={()=>this.handleUpdateSenderId(contact_number, senderID)}
                  type="primary"
                >Update </Button> 
                 :  
                  <span>
                    <Icon 
                      type="edit" 
                      onClick={()=>this.setState({ isEditShopLink: true })}
                      />
                  </span>
                }
            </div>
          </div> */}
        </div>
      );
    };

    return (
      <div className="page-container account-screen">
        {/* Main Content */}
        <Card
          bordered={false}
          // title="My Account"
          // extra={
          //   <Button type="primary" onClick={this.handleShowChangePassword}>
          //     Change Password
          //   </Button>
          // }
        >
          <div style={{ display: "flex" }}>
            <span>
              {strings.youAeCurrentlyOn}{" "}
              <strong>{user.subscription_plan}</strong> {strings.plan}.{" "}
              {strings.yourSubscriptionExpiresIn}{" "}
              <strong>
                {this.props.isCurrentPlanDate !== null
                  ? this.props.isCurrentPlanDate
                  : moment(user.subscription_expires_on).format("DD/MM/YYYY")}
              </strong>
              .{" "}
              <Button type="primary" onClick={() => toggleRenewalModal()}>
                Subscribe
              </Button>
            </span>
          </div>
          <ChangeLanguage />

          <section className="mt-20">
            <Form layout="vertical" onSubmit={this.handleSubmit}>
              <Row>
                <Col span={24} md={18}>
                  <div style={{ width: "100%" }}>
                    <h4>{strings.userData}</h4>

                    <Row gutter={12}>
                      <Col lg={10} md={12} sm={24}>
                        {/* Firstname Input */}
                        <FormItem>
                          {getFieldDecorator("first_name", {
                            rules: [
                              {
                                message: strings.pleaseEnterFirstName,
                                required: true,
                              },
                            ],
                          })(
                            <Input
                              size="large"
                              prefix={
                                <Icon
                                  type="user"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              placeholder={strings.firstName}
                            />
                          )}
                        </FormItem>
                        {/* ======Firstname */}
                      </Col>

                      <Col lg={10} md={12} sm={24}>
                        {/* Lastname */}
                        <FormItem>
                          {getFieldDecorator("last_name", {
                            rules: [
                              {
                                message: strings.pleaseEnterLastName,
                                required: true,
                              },
                            ],
                          })(
                            <Input
                              size="large"
                              prefix={
                                <Icon
                                  type="user"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              placeholder={strings.lastName}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      {/* Email Input */}
                      <Col lg={10} md={12} sm={24}>
                        <FormItem>
                          {getFieldDecorator("email", {
                            rules: [
                              {
                                message: strings.pleaseEnterEmail,
                                required: true,
                              },
                              {
                                type: "email",
                                message: strings.pleaseEnterValidEmail,
                              },
                              {
                                /* {
                          validator: this.handleEmailValidation,
                        }, */
                              },
                            ],
                          })(
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Input
                                size="large"
                                type="email"
                                value={email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                                prefix={
                                  <Icon
                                    type="mail"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                  />
                                }
                                placeholder="Email"
                                style={{
                                  width: isEmailLoading ? "90%" : "100%",
                                }}
                              />

                              {isEmailLoading && <Spin indicator={antIcon} />}
                            </div>
                          )}
                        </FormItem>
                      </Col>
                      {/* ======Email */}

                      {/* Phone number Input */}
                      <Col lg={10} md={12} sm={24}>
                        <FormItem>
                          {getFieldDecorator("contact_number", {
                            rules: [
                              {
                                message: strings.pleaseEnterPhoneNumber,
                                required: true,
                              },
                              {
                                /* {
                          validator: this.handlePhoneValidation,
                        }, */
                              },
                            ],
                          })(
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <PhoneInput
                                inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                                inputStyle={{
                                  border: "1px solid #d9d9d9",
                                  width: `${isPhoneLoading ? "90%" : "100%"}`,
                                  height: "40px",
                                }}
                                country="ng"
                                value={contact_number}
                                onChange={(data, country) => {
                                  this.setState({
                                    country,
                                  });
                                }}
                              />
                              {isPhoneLoading && <Spin indicator={antIcon} />}
                            </div>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    {/* ======Phone number */}

                    <h4 style={{ marginBottom: "10px" }}>
                      {strings.businessData}
                    </h4>
                    {/* Business name Input */}
                    <Row gutter={12}>
                      <Col lg={10} md={12} sm={24}>
                        <FormItem>
                          {getFieldDecorator("business_name", {
                            rules: [
                              {
                                message: strings.pleaseEnterBusinessName,
                                required: true,
                              },
                            ],
                          })(
                            <Input
                              size="large"
                              prefix={
                                <Icon
                                  type="bank"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              placeholder={strings.businessName}
                            />
                          )}
                        </FormItem>
                      </Col>
                      {/* ======Business name */}

                      <Col lg={10} md={12} sm={24}>
                        {/* Business category Input */}
                        <FormItem>
                          {getFieldDecorator("business_type", {
                            rules: [
                              {
                                required: true,
                                message: strings.pleaseSelectBusinessCategory,
                              },
                            ],
                          })(
                            <Select
                              placeholder={strings.businessCategory}
                              size="large"
                            >
                              {businessTypeOptions}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                      {/* =========Business category */}
                    </Row>

                    <Row gutter={12}>
                      <Col lg={20} md={20} sm={24}>
                        {/* Business description*/}
                        <FormItem>
                          {getFieldDecorator("description", {
                            rules: [
                              {
                                required: false,
                              },
                            ],
                          })(
                            <Input
                              size="large"
                              placeholder="Business description"
                            />
                          )}
                        </FormItem>
                        {/* =========Business description */}
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col lg={10} md={12} sm={24}>
                        {/* Address Line 1*/}
                        <FormItem>
                          {getFieldDecorator("address_line1", {
                            rules: [
                              {
                                required: true,
                                message: strings.pleaseEnterAddress,
                              },
                            ],
                          })(
                            <Input
                              size="large"
                              prefix={
                                <Icon
                                  type="environment"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              placeholder={strings.addressLine1}
                            />
                          )}
                        </FormItem>
                        {/* =========Address Line 1 */}
                      </Col>

                      <Col lg={10} md={12} sm={24}>
                        {/* Address Line 2*/}
                        <FormItem>
                          {getFieldDecorator("address_line2")(
                            <Input
                              size="large"
                              prefix={
                                <Icon
                                  type="environment"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              placeholder={strings.addressLine2}
                            />
                          )}
                        </FormItem>
                        {/* =========Address Line 2 */}
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col lg={10} md={12} sm={24}>
                        {/* Currency */}
                        <FormItem>
                          {getFieldDecorator("currency", {
                            rules: [
                              {
                                message: strings.pleaseSelectCurrency,
                                required: true,
                              },
                            ],
                          })(
                            <Select
                              showSearch
                              placeholder={strings.selectYourCurrency}
                              size="large"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {[
                                <Option key="CFA" value="CFA">
                                  CFA
                                </Option>,
                                ...currencyOptions,
                              ]}
                            </Select>
                          )}
                        </FormItem>
                        {/* ==== Currency */}
                      </Col>

                      <Col lg={10} md={12} sm={24}>
                        <div className="custom-input">
                          <CountryDropdown
                            label={strings.country}
                            defaultOptionLabel={strings.selectCountry}
                            value={country}
                            onChange={(val) => this.selectCountry(val)}
                            className="ant-input-lg ant-input"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col lg={10} md={12} sm={24}>
                        <div className="custom-input" style={{ width: "100%" }}>
                          <RegionDropdown
                            label={strings.state}
                            country={country}
                            value={state}
                            onChange={(val) => this.selectRegion(val)}
                            className="ant-input-lg ant-input"
                            defaultOptionLabel={strings.selectRegion}
                          />
                        </div>
                      </Col>

                      <Col lg={10} md={12} sm={24}>
                        <FormItem
                          className="custom-input"
                          style={{ width: "100%" }}
                        >
                          {getFieldDecorator("postcode", {
                            rules: [
                              {
                                required: false,
                                message: strings.pleaseEnterPostCode,
                              },
                            ],
                          })(
                            <Input
                              placeholder={strings.postCode}
                              size="large"
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <Button type="primary" htmlType="submit" loading={loading}>
                      {strings.save}
                    </Button>
                  </div>
                </Col>
                <Col span={24} md={6}>
                  <MyQrCode />
                </Col>
              </Row>
            </Form>

            <div style={{ marginTop: 30 }}>
              <p>
                <b>Business Logo</b>
              </p>
              {user.image ? (
                <Avatar src={user.image} shape="square" size="large" />
              ) : (
                <em>No business logo uploaded.</em>
              )}
              <Form.Item name="picture" className="wrap">
                {getFieldDecorator("picture", {
                  rules: [{ required: false }],
                  valuePropName: "fileList",
                  getValueFromEvent: normFile,
                })(
                  <Upload listType="picture" multiple={false} {...uploadProps}>
                    <Button icon={<UploadOutlined />}>
                      {strings.clickToUpload}
                    </Button>
                  </Upload>
                )}
              </Form.Item>
              {this.state.imageUrl && (
                <Button type="primary" onClick={this.handleSubmit}>
                  Save
                </Button>
              )}
            </div>

            <section className="sms-info" style={{ marginTop: "2rem" }}>
              <h4 style={{ marginBottom: "10px" }}>SMS TOP-UP</h4>

              <p>
                {strings.youHave} {this.state.sms_balance} {strings.smsUnits}.{" "}
                {strings.purchaseMoreSmsUnits}:
              </p>

              <SMSTopUpModal
                user={user}
                updateSMSBalance={this.getMerchantSMSBalance}
              />
            </section>

            {/* {showChangePassword ? (
              <ChangeStaffPasswordModal
                handleShowChangePassword={this.handleShowChangePassword}
                showChangePassword={showChangePassword}
              />
            ) : null} */}
          </section>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isRenewalModalOpen: state.renewal.isRenewalModalOpen,
  isCurrentPlanDate: state.merchant.isCurrentPlanDate,
});

const AccountForm = Form.create()(AccountScreen);

export default connect(mapStateToProps, {
  editMerchantAccount,
  checkEmailAvailability,
  checkPhoneAvailability,
  toggleRenewalModal,
  setCurrentUser,
})(AccountForm);
