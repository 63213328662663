import { Button } from "antd";
import React, { useEffect, useState } from "react";

const CountdownTimer = ({ countDown, onClick, loading, btnText }) => {
  const [timeLeft, setTimeLeft] = useState(countDown);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  if (timeLeft < 1)
    return (
      <Button type="primary" loading={loading} onClick={onClick}>
        {btnText}
      </Button>
    );
  return (
    <div>
      {Math.floor(timeLeft / 60) > 0 ? Math.floor(timeLeft / 60) + ":" : null}
      {timeLeft - Math.floor(timeLeft / 60) * 60}
    </div>
  );
};

export default CountdownTimer;
