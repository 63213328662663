import React from "react";
import strings from "../../../../../../strings";
import numberFormatter from "../../../../../../utils/numberFormatter";

const PriceTotal = ({ plan, subTotal, total, duration }) => {
  const durationDiscount =
    duration === 10
      ? strings.twoMonthsFree
      : duration === 15
      ? strings.threeMonthsFree
      : duration === 19
      ? strings.fiveMonthsFree
      : null;

  return (
    <div className="total-wrapper">
      <div className="subtotal">
        <p>
          <strong>{strings.subTotal}: </strong>
          <span>
            {plan.currency}
            {numberFormatter(subTotal)}
          </span>
        </p>

        <p>
          <span>
            <strong>{strings.durationMonths}: </strong>
            {durationDiscount}
          </span>

          <span>
            x <span className="multiply">{duration}</span>
          </span>
        </p>
      </div>

      <p className="total">
        <strong>{strings.total}: </strong>
        <span>
          {plan.currency}
          {numberFormatter(total)}
        </span>
      </p>
    </div>
  );
};

export default PriceTotal;
