import React, { useState } from "react";
import { Form, Input, Button, Card, Modal, message } from "antd";
import {
  GET_CATEGORIES,
  DELETE_CATEGORY,
  ADD_CATEGORY,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";
import { useQuery, useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";

const AddServicesCategory = ({ visible, onCancel }) => {
  const [form] = Form.useForm();

  const [addCategory, { loading: isAddCategoryLoading }] = useMutation(
    ADD_CATEGORY,
    {
      onCompleted: data => {
        form.resetFields();
        data.addCategory &&
          Modal.success({
            title: "Category added successfully",
            onOk: onCancel,
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: GET_CATEGORIES }],
    }
  );

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        addCategory({ variables: values });
      })
      .catch(err => console.log(err));
  };

  return (
    <Modal
      open={visible}
      footer={[
        <Button onClick={onCancel} type="ghost">
          Cancel
        </Button>,
        <Button
          onClick={handleSubmit}
          type="primary"
          loading={isAddCategoryLoading}
        >
          Create
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          name="category_name"
          label="Category Name"
          rules={[
            {
              required: true,
              message: "Please enter category name!",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ServicesCategory = () => {
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const { loading, data } = useQuery(GET_CATEGORIES);

  const [deleteCategory] = useMutation(
    DELETE_CATEGORY,
    {
      onCompleted: data => {
        data.deleteCategory &&
          Modal.success({
            title: "Category deleted successfully",
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: GET_CATEGORIES }],
    }
  );

  const handleDeleteCategory = id =>
    Modal.confirm({
      title: "Delete Category",
      content: "Are you sure you want to delete this category?",
      onOk: () => deleteCategory({ variables: { id } }),
    });

  return (
    <Card
      title="Service Category"
      bordered={false}
      loading={loading}
      extra={[
        <Button onClick={() => setShowAddCategoryModal(true)} type="primary">
          Add New Category
        </Button>,
      ]}
    >
      <Card title="Saved Categories">
        <ul>
          <li>Body</li>
          <li>Hair</li>
          <li>Face</li>
          <li>Nails</li>
          <li>Dental</li>
          {data?.getCategories &&
            data.getCategories.map(category => (
              <li>
                <span>{category.category_name}</span>
                <DeleteOutlined
                style={{marginLeft: "5rem"}}
                  onClick={() => handleDeleteCategory(category.id)}
                />
              </li>
            ))}
        </ul>
      </Card>
      <AddServicesCategory
        visible={showAddCategoryModal}
        onCancel={() => setShowAddCategoryModal(false)}
      />
    </Card>
  );
};

export default ServicesCategory;
