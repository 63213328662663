import { Modal, Form, Input, Button } from 'antd'
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'
import strings from '../../../../../../../strings';
import PhoneInput from "react-phone-input-2";
import { editSupplies } from '../../../../../../../redux/actions/productActions';
import { connect } from 'react-redux';


const FormItem = Form.Item;
const EditSupplierModal = ({
    open, 
    onCancel,
    supplyDetails,
    editSupplies,
    fetchSuppliers,
    user
}) => {
    const [form] = useForm();
    const { setFieldsValue, validateFields } = form;
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        setFieldsValue({
            ...supplyDetails
        })
    }, [supplyDetails]);

    const handleEditSupplier = async () => {
        const values = await validateFields();

        setLoading(true);
        editSupplies(supplyDetails.id, { ...values })
        .then(res => {
            if(res.status === 200 || res.status === 201 || res.status === 204){
                Modal.success({
                    title: strings.youveSuccessfullyEditedThisSupplier,
                    onOk: () => {
                        setLoading(false);
                        fetchSuppliers();
                        onCancel();
                    }
                })
            }
        })
        .catch(err => {
            Modal.error({
                title: err?.response?.data?.message || "Unable to edit this supplier, please try again",
                onOk: () => {
                    setLoading(false);
                }
            })
        })
    }
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        footer={[
            <Button key="back" onClick={onCancel}>
              {strings.close}
            </Button>,
            <Button
              key="submit"
              loading={loading}
              type="primary"
              onClick={handleEditSupplier}
            >
              {strings.edit}
            </Button>,
          ]}
        destroyOnClose
        
    >
        <Form form={form} layout="vertical" onFinish={handleEditSupplier}>
            <FormItem label={strings.firstName} rules={[
                {required: true, message: strings.pleaseEnterFirstName}
            ]} name="first_name">
                <Input size="large" />
            </FormItem>

            <FormItem label={strings.lastName} name="last_name">
                <Input size="large" />
            </FormItem>

            <FormItem 
                label={strings.email} 
                name="email"
                rules={[
                    {required: false, message: strings.pleaseEnterSupplierEmail}
                ]}
            >
                <Input size="large" />
            </FormItem>

            <FormItem 
                label={strings.businessName} 
                name="business_name"
                rules={[
                    {required: true, message: strings.pleaseEnterBusinessName}
                ]}
            >
                <Input size="large" />
            </FormItem>

            <FormItem 
                label={strings.phoneNumber} 
                name="phone_number"
                rules={[
                    {required: false, message: strings.pleaseEnterCustomerPhoneNumber}
                ]}
            >
                <PhoneInput
                    inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                    inputStyle={{
                        border: "1px solid #d9d9d9",
                        width: "100%",
                        height: "40px",
                    }}
                    placeholder={strings.phoneNumber}
                    country="ng"
                    />
            </FormItem>

            <FormItem label={strings.address} name="address_line1">
                <Input size="large" />
            </FormItem>

            <FormItem 
                label={strings.description} 
                name='description'
                rules={[
                    {required: false, message: strings.pleaseAddADescription}
                ]}
            >
                <Input.TextArea size="large" />
            </FormItem>
        </Form>
    </Modal>
  )
}

const mapStateToProps = state => ({
    user: state.auth.user,
    supplies: state.product.supplies,
});

export default connect(mapStateToProps, { editSupplies })(EditSupplierModal);