import SimpleCrypto from "simple-crypto-js";

const _secretKey = "get-from-environment-variable";

const simpleCrypto = new SimpleCrypto(_secretKey);

export const encryptString = plainString => {
  let newString = simpleCrypto.encrypt(plainString);

  return encodeURIComponent(newString);
};

export const decryptString = encryptedString => {
  const newString = simpleCrypto.decrypt(encryptedString);

  return newString;
};
