import React from "react";
import ServicesListItem from "../ServicesListItem";
import strings from "../../../../strings";

const ServicesList = ({
  data,
  addServiceToCart,
  user,
  handleShowSetPrice,
  checkoutData
}) => (
  <>
    {data && data.length
      ? data.map((service) => {
            return (
              <ServicesListItem
                key={service.id}
                item={service}
                onClick={addServiceToCart}
                currency={user.currency}
                handleShowSetPrice={handleShowSetPrice}
                checkoutData={checkoutData}
              />
            );
        })
      : strings.noProductItem}
  </>
);

export default ServicesList;
