import { Button, Card, Form, Input, InputNumber, message, Modal, Select, Table } from 'antd'
import React, { useMemo, useState, useEffect } from 'react'
import strings from '../../../../../../strings'
import "./index.scss"
import { getSuppliers } from '../../../../../../redux/actions/productActions';
import { connect } from 'react-redux';
import api from '../../../../../../api';
import { createPurchase, getSupplierProducts } from '../../../../../../redux/actions/purchaseOrder';


const FormItem = Form.Item;
const CreatePurchaseOrder = ({
    open,
    onCancel,
    suppliers,
    getSuppliers,
    createPurchase,
    user,
    getSupplierProducts
}) => {
    const [form] = Form.useForm()
    const [ step, setStep ] = useState(1);
    const [supplier, setSupplier] = useState(null);
    const [products, setProducts] = useState([]);
    const [ searchedProduct, setSearchedProducts ] = useState(null);
    const [currentProduct, setCurrentProduct] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        fetchSuppliers();
    }, []);

    const productsMemo = useMemo(() => products, [products]);
    const fetchSuppliers = async () => {
        try {
          setIsLoading(true);
          const res = await getSuppliers();
    
          if (res.status === 200) {
            setIsLoading(false);
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
    };

    const handleCreateOrder = () => {
        setLoading(true);
        
        let purchase_order = {
            supplier_id: supplier,
            merchant_id: user.employer ? user.employer.id : user.id,
            purchase_order_items_attributes: products.map(product => {
                delete product.key
                return product;
            })
        }
        if(user.business_branch) {
            purchase_order.business_branch_id = user.business_branch.id
        }
        createPurchase(purchase_order)
        .then(res => {
            if(res.status === 200 || res.status === 201){
                Modal.success({
                    title: strings.purchaseOrderHasBeenCreated,
                    onOk: () => {
                        setLoading(false);
                        onCancel();
                    }
                })
            }
        })
        .catch(err=> {
            setLoading(false);
            console.log("err", err);
            
        })
    }
    
    const handleSubmitForm = () => {
        const { validateFields, setFieldsValue } = form;
        validateFields()
        .then(values => {
            const { product_name, quantity, price } = values;
            
            const selectedProduct = currentProduct.find(prod => prod.id === product_name);
            
            setProducts((prev) => {
                return [
                    ...prev,
                    {
                        key: products.length +1, 
                        name: selectedProduct.name,
                        product_id: product_name, 
                        ordered_quantity: quantity, 
                        price
                    }
                ]
            });

            setFieldsValue({
                product_name: "",
                quantity: null,
                price: null
            });
        })
    }

    const handleProductSearch = (name) => {
        if(name.length < 2) return;
        setLoading(true);
        api.HttpClient.get(`/search_product/${name}`)
        .then(res => {
            if(res.status === 200){
                setLoading(false);
                setCurrentProduct(() => res.data.filter(prod => prod.track_inventory));
            }
        })
        .catch(err => {
            setLoading(false);
            console.log("err", err);
            message.error("Unable to fetch products")
        })
    }

    const handleSelectProduct = (value) => {
        const { setFieldsValue } = form;
        const foundProd = currentProduct && currentProduct.find(prod => prod.id == value);

        setFieldsValue({ price: Number(foundProd.price)})
        
    }

    const handleGetSupplierproduct = (id) => {
        setLoading(true);
        getSupplierProducts(id) 
        .then(res => {
            if(res.status === 200){
                setLoading(false);
                setCurrentProduct(() => res.data.filter(prod => prod.track_inventory));
            }
        })
        .catch(err => {
            setLoading(false);
            console.log("err", err);
            message.error("Unable to fetch products")
        })

    }

    const removeItem = (id) => {
        setProducts((prev) => {
            return [
                ...prev.filter(prod => prod.key !== id)
            ]
        })
    }
    const addColumn = [
        {
            key: "1",
            title: strings.productName,
            dataIndex: "name",
        },
        {
            key: "2",
            title: strings.quantity,
            dataIndex: "ordered_quantity",
        },
        {
            key: "3",
            title: strings.price,
            dataIndex: "price",
        },
        {
            key: "4",
            title: strings.actions,
            dataIndex: "name",
            render(value, record){
                return ( <Button type="primary" onClick={()=> removeItem(record.key)} >{strings.remove}</Button>)
            }
        }
    ]

    const submitColumn = [
        {
            key: "1",
            title: strings.productName,
            dataIndex: "name",
        },
        {
            key: "2",
            title: strings.quantity,
            dataIndex: "ordered_quantity",
        },
        {
            key: "3",
            title: strings.price,
            dataIndex: "price",
        },
        {
            key: "4",
            title: strings.total,
            dataIndex: "total",
            render(value, record){
                return (<span>{user.currency} {Number(record.ordered_quantity) * Number(record.price)}</span>)
            }
        }
    ]

    const calculateTotal = () => {
        const total = products.reduce((prev, curr) => {
            return prev + (Number(curr.price) * Number(curr.ordered_quantity) )
        }, 0);

        return Number(total).toFixed(2);
    }

    const renderStep = () => {
        switch (step) {
        case 1:
        return (
            <Card
                title={strings.createPurchaseStep1}
                bordered
            >
                <div className='ordersbody'>
                    <div className='fullwidth'>
                        <Select 
                            onChange={(value) => {
                                setSupplier(value)
                                handleGetSupplierproduct(value)
                            }}
                            placeholder={strings.selectSuppliers}
                            size='large'
                            style={{width: "100%"}}
                            value={supplier}
                        >
                            {suppliers && suppliers.map((supplier, index) => {
                                return ( <Select.Option key={index} value={supplier.id}>{supplier.user && supplier.user.first_name} {supplier.user && supplier.user.last_name}</Select.Option>)
                            })}
                        </Select>

                    </div>
                        
                    <div className='fullwidth'>
                        <Button 
                            onClick={() => setStep(2)} 
                            disabled={!supplier}
                            type='primary'
                        >
                                {strings.next}
                        </Button>
                    </div>
                </div>
            </Card>
        )
        case 2:
        return (
        <Card
            title={strings.createPurchaseStep2}
            bordered
        >
            <div className='ordersbody'>
                <Form layout="vertical" form={form} onFinish={handleSubmitForm}>
                    <FormItem rules={[{required: true}]} label={strings.productName} name="product_name">
                        <Select
                            placeholder={strings.searchProduct}
                            showSearch
                            loading={loading}
                            optionFilterProp="children"
                            // value={searchedProduct}
                            // onSearch={(value) => handleProductSearch(value)}
                            onChange={(value) => handleSelectProduct(value)}
                            size="large"
                        >
                            {currentProduct && currentProduct.map((products, index) => {
                                return (<Select.Option key={index} value={products.id}>{products.name}</Select.Option>)
                            })}
                        </Select>
                        {/* <Input placeholder={strings.productName} size="large" /> */}
                    </FormItem>

                    <FormItem rules={[{required: true}]} label={strings.quantity} name="quantity">
                        <InputNumber style={{width: "100%"}} placeholder={strings.quantity} size="large" />
                    </FormItem>

                    <FormItem rules={[{required: true}]} label={strings.price} name="price">
                        <InputNumber style={{width: "100%"}} placeholder={strings.price} size="large" />
                    </FormItem>

                    <FormItem >
                        <Button htmlType="submit" type="primary" style={{width: "100%"}}>{strings.addProduct}</Button>
                    </FormItem>
                </Form>
            
                <div>
                    <Table 
                        columns={addColumn}
                        dataSource={productsMemo}
                        pagination={false}
                    />
                </div>
                <div className='fullwidth flexJustify'>
                    <Button 
                        onClick={() => {
                            setStep(1);
                            setCurrentProduct([]);
                        }} 
                         type='primary'
                    >
                            {strings.back}
                    </Button>
                    <Button 
                        onClick={() => setStep(3)} 
                         type='primary'
                    >
                            {strings.next}
                    </Button>
                </div>
            </div>
        </Card>
        )
        case 3:
        return (
            <Card
                title={strings.createPurchaseStep3}
                bordered
            >
           <div className='ordersbody'>
                <div>
                    <Table 
                        columns={submitColumn}
                        dataSource={productsMemo}
                        // summary={(data) => {
                        //     return (
                        //         <div style={{width: "100%"}}>
                        //         <span>{strings.totalAmount}: </span>
                        //         <span>{user.employer ? user.employer.currency : user.currency} {calculateTotal()}</span>
                        //     </div>
                        //     )
                        // }}
                        footer={(data) => {
                            return (
                                <div style={{width: "100%"}}>
                                <span>{strings.totalAmount}: </span>
                                <span>{user.employer ? user.employer.currency : user.currency} {calculateTotal()}</span>
                            </div>
                            )
                        }}
                        pagination={false}
                    />
                   
                </div>
                <div className='fullwidth flexJustify'>
                    <Button 
                        onClick={() => setStep(2)} 
                         type='primary'
                    >
                            {strings.back}
                    </Button>
                    <Button 
                        onClick={handleCreateOrder} 
                        type='primary'
                        loading={loading}
                    >
                        {strings.submitOrder}
                    </Button>
                </div>
            </div>
            </Card>
        )
    }
    }

  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={strings.CreatePurchaseOrder}
        footer={null}
    >
        <div>
            {/* <h1>{strings.CreatePurchaseOrder}</h1> */}
            {renderStep()}
        </div>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
    suppliers: state.product.suppliers,
});
  
export default connect(mapStateToProps, { getSuppliers, createPurchase, getSupplierProducts })(CreatePurchaseOrder);