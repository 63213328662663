import React, { Component } from "react";
import { Button, message, Modal } from "antd";
import { encryptString, decryptString } from "../../../../../../utils/crypt";
import api from "../../../../../../api";
import strings from "../../../../../../strings";

class AccounteerModal extends Component {
  state = {
    logged_in: false,
    alertMessage: "",
    errorMessage: "",
    headers: {},
    isLoading: false,
    success: false,
  };

  componentDidMount() {
    const headers = {
      uid: api.HttpClient.defaults.headers.common["uid"],
      client: api.HttpClient.defaults.headers.common["client"],
      expiry: api.HttpClient.defaults.headers.common["expiry"],
      "token-type": api.HttpClient.defaults.headers.common["token-type"],
      "access-token": api.HttpClient.defaults.headers.common["access-token"],
    };

    this.setState({
      headers,
      logged_in: true,
      isLoading: false,
      alertMessage: strings.loginSuccessful,
      errorMessage: "",
    });

    const { state, authCode } = this.getUrlParams();

    console.log({ state, authCode });

    if (state !== null && authCode !== null) {
      const formattedState = state.split(" ").join("+");

      const reqHeaders = this.updateState(formattedState);

      this.getAuthToken(authCode, reqHeaders, formattedState);
    } else if (state !== null && authCode === null) {
      this.updateState(state);
    }
  }

  updateState(state) {
    let currentState = decryptString(state);

    currentState = JSON.parse(currentState);

    this.setState({ ...currentState });

    return currentState.headers;
  }

  getUrlParams() {
    const url = window.location.search.substring(1);

    const params = new URLSearchParams(url);

    const state = params.get("state");
    const authCode = params.get("code");

    return { state, authCode };
  }

  getAuthToken(authCode, headers, redirect_uri) {
    const clientUrl = process.env.REACT_APP_CLIENT_URL;

    this.setState({
      isLoading: true,
      alertMessage: strings.pleaseWaitWhileWeConnectAccount,
    });

    fetch(
      `https://api.loystar.co/api/v2/merchants/accounteer/connect_account?code=${authCode}&redirect_uri=${redirect_uri}`,
      {
        headers,
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          this.setState({
            logged_in: false,
            headers: {},
            success: false,
            isLoading: false,
            alertMessage: "",
            errorMessage: strings.connectionFailedTryAgain,
          });
          window.location = `${clientUrl}?state=${encryptString(this.state)}`;
          return;
        }

        this.setState({
          logged_in: false,
          headers: {},
          success: true,
          isLoading: false,
          alertMessage: "",
          errorMessage: "",
        });

        message.success(strings.connectionSuccessful, 5);
      })
      .catch(() => {
        this.setState({
          logged_in: false,
          headers: {},
          success: false,
          isLoading: false,
          alertMessage: "",
          errorMessage: strings.connectionFailedTryAgain,
        });
        window.location = `${clientUrl}?state=${encryptString(this.state)}`;
      });
  }

  render() {
    const { visible, toggleAccounteerModal, currentIntegration } = this.props;

    const {
      id,
      website_link,
      base_url,
      icon,
      app_name,
      company_name,
      description,
      price,
    } = currentIntegration;

    const base_uri = `${process.env.REACT_APP_ACCOUNTEER_BASE_URL}/oauth/v2/auth`;
    const redirect_uri = `${
      process.env.REACT_APP_ACCOUNTEER_REDIRECT_URI
    }?state=${encryptString(this.state)}`;
    const client_id = process.env.REACT_APP_ACCOUNTEER_CLIENT_ID;

    return (
      <Modal
        title={strings.viewDetails}
        visible={visible}
        onCancel={toggleAccounteerModal}
        footer={[
          <Button key="cancel" onClick={toggleAccounteerModal}>
            {strings.cancel}
          </Button>,
          <Button type="primary" key="enable">
            <a
              href={`${base_uri}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {strings.enable}
            </a>
          </Button>,
        ]}
      >
        <div className="integration-details">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1.5rem",
            }}
          >
            <div className="img-wrapper" style={{ width: "100px" }}>
              <img src={icon} alt={app_name} />
            </div>

            <div className="name" style={{ marginLeft: "2rem" }}>
              <p style={{ marginBottom: 0, fontWeight: 700 }}>{app_name}</p>
              <p>{company_name}</p>
            </div>
          </div>

          <p>
            <strong>{strings.description}: </strong>
            <span
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></span>
          </p>

          <p>
            <strong>{strings.cost}:</strong> {price > 0 ? price : strings.free}
          </p>

          {website_link && (
            <Button
              type="link"
              href={website_link}
              target="_blank"
              rel="noreferrer noopener"
              style={{ paddingLeft: 0 }}
            >
              {strings.visitWebsite}
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}

export default AccounteerModal;
