import React from 'react';
import Lehjah from "lehjah-react";
import { connect } from 'react-redux';


//page={"'overview' | 'transactions' | 'bills' | 'inventory' | 'financial_statements' | 'manual_journal' | 'general_ledger' | 'chart_of_accounts'"}
const Leyjahs = ({
    user
}) => {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Lehjah
                business_id={user.id}
                public_key={process.env.REACT_APP_LEHJAH_PUBLIC_KEY}
                token={process.env.REACT_APP_LEHJAH_TEST_TOKEN_ID}
                environment={process.env.REACT_APP_LEHJAH_ENVIRONMENT} // or 'production'
                mode="full"
                // page={"overview, transactions, bills, inventory, financial_statements, manual_journal, general_ledger,chart_of_accounts"}
                width="100%"
                height="100%"
                variable1={user["access_token"] || user["access-token"]}
                variable2={user.client}
                variable3={user.email}
                variable5={user.id}
            />
        </div>
    );
}
const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default connect(mapStateToProps, null)(Leyjahs);