import React from "react";
import { Checkbox } from "antd";
import strings from "../../../../strings";

const ProAddons = ({
  addons,
  setAddons,
  setNumOfStaffs,
  setNumOfBranches,
  setNumOfEcommerceBranch,
  toggleInputBranchModal,
  toggleInputStaffModal,
  // toggleEcommerceBranchModal,
}) => {
  return (
    <div className="addons-pro">
      <Checkbox
        checked={addons.hasStaffs}
        onChange={e => {
          if (e.target.checked) {
            toggleInputStaffModal();
          } else {
            setNumOfStaffs(0);
          }

          setAddons({ ...addons, hasStaffs: e.target.checked });
        }}
      >
        {strings.staffAccounts}
      </Checkbox>
      <Checkbox
        checked={addons.hasBranches}
        onChange={e => {
          if (e.target.checked) {
            toggleInputBranchModal();
          } else {
            setNumOfBranches(0);
          }

          setAddons({ ...addons, hasBranches: e.target.checked });
        }}
      >
        {strings.branches}
      </Checkbox>

      {/* <Checkbox
        checked={addons.hasAccounteer}
        onChange={e =>
          setAddons({ ...addons, hasAccounteer: e.target.checked })
        }
      >
        Accounteer
      </Checkbox> */}

      <Checkbox
        checked={addons.hasEcommerce}
        onChange={e => {
          if (e.target.checked) {
            setNumOfEcommerceBranch(1);
          } else {
            setNumOfEcommerceBranch(0);
          }

          setAddons({ ...addons, hasEcommerce: e.target.checked });
        }}
      >
        {strings.ecommerce}
      </Checkbox>
    </div>
  );
};

export default ProAddons;
