import React from "react";
import { Select } from "antd";
import strings from "../../../../strings";

const Duration = ({ handleDurationChange, isLoading, user }) => {
  return (
    <div
      className="duration-options"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Select
        onChange={handleDurationChange}
        placeholder={strings.selectADuration}
        style={{ width: "50%" }}
        disabled={isLoading}
      >
        <Select.Option value="1">{strings.oneMonth}</Select.Option>
        <Select.Option value="2">{strings.twoMonths}</Select.Option>
        <Select.Option value="3">{strings.threeMonths}</Select.Option>
        <Select.Option value="6">{strings.sixMonths}</Select.Option>
        <Select.Option value="12">
          {strings.twelveMonths}{" "}
          {user.subscription_plan === "Pro" ? strings.twoMonthsFree : null}
        </Select.Option>
        <Select.Option value="18">
          {strings.eighteenMonths}{" "}
          {user.subscription_plan === "Pro" ? strings.threeMonthsFree : null}
        </Select.Option>
        <Select.Option value="24">
          {strings.twentyFourMonths}{" "}
          {user.subscription_plan === "Pro" ? strings.fiveMonthsFree : null}
        </Select.Option>
      </Select>
    </div>
  );
};

export default Duration;
