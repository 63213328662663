import React from "react";
import { Modal, Input, notification, message } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";
import strings from "../../../../../../strings";
import { connect } from "react-redux";


class BulkQuantityModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialProductItemData: {},
      theVarian: {}
    };
  }

  componentDidMount() {
    const { form, item, isNitroActive, isProductBundleActive, handleBlurFactor } = this.props;
    form.setFieldsValue({ quantity: item.quantity });

    handleBlurFactor(false);
    // console.log(isNitroActive);
    if(isNitroActive){
      // console.log(item);
    }else if(item.variants){
      const initialProductsData = JSON.parse(localStorage.getItem("products"));
      const initialProductItemData = initialProductsData.filter(
        product => product.id === item.id
      )[0];
      const theVariant = initialProductItemData.variants.filter((variant)=>{
        return variant.id === item.variants[0].id
      })[0];

      this.setState({ initialProductItemData: initialProductItemData, theVarian: theVariant});
    }else if(isProductBundleActive){

    }
    
  }

  onAddBulkQuantity = item => {
    this.handleAddBulk();
    this.props.handleBlurFactor(true)
  };

  onEnter = e => {
    if (e.keyCode === 13) {
      this.handleAddBulk();
      this.props.handleBlurFactor(true)
    }
  };

  // static getDerivedStateFromProps(props, state) {
  //   const { products, item } = props;

  //   const productItem = products.filter(product => product.id === item.id)[0];

  //   if (
  //     productItem &&
  //     productItem.quantity !== state.initialProductItemData.quantity
  //   ) {
  //     return {
  //       initialProductItemData: {
  //         ...state.initialProductItemData,
  //         quantity: productItem.quantity,
  //         variants: productItem.variants,
  //       },
  //     };
  //   }

  //   return null;
  // }

  handleAddBulk = () => {
    const { 
      form, 
      handleAdd, 
      item, 
      isNitroActive,
       handleAddBundle, 
       closeModal, 
       checkoutData 
    } = this.props;
    const { initialProductItemData } = this.state;
    form.validateFields((err, values) => {
      if (err) return;

      if (
        initialProductItemData.track_inventory &&
        (parseInt(values.quantity) >
          parseInt(initialProductItemData.quantity) ||
          parseInt(values.quantity) < 0)
      ) {
        notification.error({
          message: strings.invalidProductQuantity,
          description: strings.quantityAddedIsOverStock,
        });
        return;
      }

      let newItem = { ...item };

      if(isNitroActive){
        
        if (newItem.variants.length > 0) {
          if(Number(newItem.variants[0].quantity) <= Number(values.quantity)) return message.warning("Not Enough Quantity");
          // newItem.variants[0].quantity = Number(newItem.variants[0].quantity) - Number(values.quantity);
          // newItem.variants[0].selectQty = Number(values.quantity);
          newItem.quantity = values.quantity;
        }else{
          if(Number(newItem.quantityCheck) <= Number(values.quantity)) return message.warning("Not Enough Quantity");
          newItem.quantity = values.quantity;
        }
      }else if(newItem.products && newItem.products.length > 0){
        newItem.quantity = values.quantity;
        handleAddBundle(newItem, "bulk");
        return closeModal()
      }else{
        newItem.quantity = values.quantity;
  
        if (newItem.variants.length > 0) {
          const theVariant = initialProductItemData.variants.filter((variant)=>{
            return variant.id === newItem.variants[0].id
          })[0];
          
          if(Number(theVariant.quantity) < Number(values.quantity)) return message.warning("Not Enough Quantity")
          newItem.variants[0].quantity = Number(theVariant.quantity) - Number(values.quantity);
          newItem.variants[0].selectQty = Number(values.quantity);
          newItem.quantity = values.quantity;
        }
      }
      
      handleAdd(newItem);
    });
  };

  render() {
    const {
      closeModal,
      form: { getFieldDecorator },
      visible,
      item,
      isNitroActive,
      isProductBundleActive,
      checkoutData
    } = this.props;
    const { initialProductItemData, theVarian } = this.state;

    return (
      <div>
        <Modal
          title={checkoutData === "services" ? `Add Bulk Qty. to ${item.name}` : item.products && item.products.length > 0 ? `Add Bulk Qty. to ${item.name}`:
            isNitroActive ? (item?.variants?.length > 0 ? `Add Bulk Qty. to  ${item && item.name + " " + item.variants[0].value} -  ${
            item.track_inventory
              ? `Qty. in stock: ${parseInt(item.variants[0].quantity)}`
              : strings.itemInventoryNotTracked
          }` : `Add Bulk Qty. to  ${item && item.name} -  ${
            item.track_inventory
              ? `Qty. in stock: ${parseInt(item.quantityCheck)}`
              : strings.itemInventoryNotTracked
          }`) : (item.variants.length > 0 ? `Add Bulk Qty. to  ${item && item.name + " " + item.variants[0].value} -  ${
            initialProductItemData.track_inventory
              ? `Qty. in stock: ${parseInt(theVarian.quantity)}`
              : strings.itemInventoryNotTracked
          }` : `Add Bulk Qty. to  ${item && item.name} -  ${
            initialProductItemData.track_inventory
              ? `Qty. in stock: ${parseInt(initialProductItemData.quantity)}`
              : strings.itemInventoryNotTracked
          }`)}
          okText={strings.addBulkQuantity}
          cancelText={strings.cancel}
          open={visible}
          destroyOnClose
          onCancel={()=>{
            this.props.handleBlurFactor(true)
            closeModal()
          }}
          onOk={() => {
            this.onAddBulkQuantity(item);
          }}
        >
          <Form layout="vertical" >
            <Form.Item label={strings.enterBulkQuantity}>
              {getFieldDecorator("quantity", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseEnterBulkQuantity,
                  },
                ],
              })(<Input 
                size="large" 
                type="number" 
                onKeyUp={this.onEnter} 
                autoFocus
                // ref={this.componentRef}
                />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const BulkQuantityModalForm = Form.create()(BulkQuantityModal);

BulkQuantityModal.propTypes = {
  item: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  handleAdd: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isNitroActive: state.appStore.isNitroActive,
  isProductBundleActive: state.appStore.isProductBundleActive
});

export default connect(mapStateToProps)(BulkQuantityModalForm);
