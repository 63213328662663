import api from "../../api";
import { GET_MERCHANT_PLAN_DATE } from "../constants";
import { SET_CURRENT_USER } from "../constants";
import moment from "moment";
export const getPricingPlanData = requestPayload => async dispatch => {
  const res = await api.merchants.getPricingPlanData(requestPayload);

  return Promise.resolve(res);
};

export const getMerchantSubPlan = () => async dispatch => {
  const res = await api.merchants.getMerchantSubscriptionPlan()

  return Promise.resolve(res);
}

export const addMerchantCurrentPlan = (
  merchantEmail,
  subscriptionData
) => async dispatch => {
  const newSubscriptionData = {
    plan: subscriptionData.plan,
    currency_code: subscriptionData.currency_code,
    no_of_days: subscriptionData.no_of_days,
    transaction_reference: subscriptionData.transaction_reference,
    payee_email_address: subscriptionData.payee_email_address
  }
  const res = await api.merchants.addMerchantCurrentPlan(
    merchantEmail,
    newSubscriptionData
  );
  if (res && res.status === 200) {
    const milisecondsToDaysConst = 86400000;
    const daysToMill = Number(subscriptionData.no_of_days) * milisecondsToDaysConst;
    
    let retrievedObj = JSON.parse(localStorage.getItem("userDetails"));

    let splitD = subscriptionData.expires_on.split("/");
    const [mm, dd, yyyy] = splitD;

    const orderedDate = `${yyyy}-${mm}-${dd}`;

    // retrievedObj.subscription_expires_on = moment(orderedDate).toISOString();
    localStorage.setItem("userDetails", JSON.stringify(retrievedObj));
    // console.log(
    //   subscriptionData.expires_on,
    //   retrievedObj,
    //   "merchant action"
    // );

    await dispatch({
      type: GET_MERCHANT_PLAN_DATE,
      payload: subscriptionData.expires_on,
    });
    await dispatch(setCurrentUser({ ...retrievedObj }));
  }

  return Promise.resolve(res);
};

// Set Current User
export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const getMerchantsSMSBalance = () => dispatch => {
  return api.merchants.getMerchantSMSBalance();
};

export const fetchTransferFeeCredits = () => dispatch => {
  return api.merchants.fetchTransferFeeCredits();
};

export const topUpTransferFeeCredits = data => dispatch => {
  return api.merchants.topUpTransferFeeCredits(data);
};

export const fetchMerchantDisbursementsByDuration = (
  pageNumber,
  from,
  to
) => dispatch => {
  return api.merchants.fetchMerchantDisbursementsByDuration(
    pageNumber,
    from,
    to
  );
};

export const fetchMerchantDisbursements = pageNumber => dispatch => {
  return api.merchants.fetchMerchantDisbursements(pageNumber);
};

export const fetchMerchantDisbursementsByStatus = payload => dispatch => {
  return api.merchants.fetchMerchantDisbursementsByStatus(payload);
};
