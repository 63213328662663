import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, Tag, message, Checkbox } from 'antd';
import { PrinterOutlined } from "@ant-design/icons";
import NewWindow from "react-new-window";
import strings from '../../strings';
import { signoutUser } from '../../redux/actions/authActions';
import { useApolloClient } from '@apollo/client';
import { openOrderScreen as activateKitchenScreen } from '../../redux/actions/orderActions';
import { 
  GET_INSTORE_ORDERS,
  GET_ONE_ORDERITEM, 
  DELETE_ONE_ORDER, 
  DELETE_ORDERITEMS,
  GET_ONE_ORDER,
  READY_ONE_ORDER,
  PAY_ONE_ORDER, 
  SUBSCRIBE_INSTORE_ORDERS 
} from '../../constants';
import { 
  useQuery, 
  useLazyQuery, 
  useSubscription 
} from '@apollo/client';
import  { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import ReactToPrint from "react-to-print";
import ComponentToPrint from '../../containers/Dashboard/Layouts/OrderingMenu/partials/componentToPrint';
import "./index.scss";



const KitchScreen = ({ 
  order, 
  user, 
  openOrderScreen,
  open,
  activateKitchenScreen,
  auth,
  signoutUser
}) => {
  const history = useHistory();
  const client = useApolloClient();
  const homeBranch = localStorage.getItem("homebranch") 
                      ? JSON.parse(localStorage.getItem("homebranch")) 
                        : {id: null};
  const [ pendingOrders, setPendingOrders ] = useState(null);
  const [ processingOrders, setProcessingOrders ] = useState(null);
  const [ readyOrders, setReadyOrders ] = useState(null);
  const [ pendingOrderItem, setPendingOrderItems ] = useState(null);
  const [ loadProcessOrder, setLoadProcessingOrder ] = useState(false);
  const [ loadReadyOrder, setLoadReadyOrder ] = useState(false);
  const [ orderToLoad, setOrderToLoad ] = useState(null);
  const [ ordersWithItems, setOrdersWithItems ] = useState([]);
  const [ checkBoxValue, setCheckBoxValue ] = useState([]);
  const [ itemsCount, setItemsCount ] = useState({});
  const [ isKitchenScreenActive, setIsKitchenScreenActive ] = useState(false);
  const componentRef = useRef(); 

  //Graphl functions
  const [ getOneOrder ] = useLazyQuery(GET_ONE_ORDER);
  const [ readyOneOrder ] = useLazyQuery(READY_ONE_ORDER);

  useEffect(()=>{
    const { user } = auth;

    if (!auth.isAuthenticated) {
      history.push("/signin-kds")
    }

  }, [auth]);

  useEffect(()=>{
    const isKitchenScr = localStorage.getItem("isKitchenActive");
    if(isKitchenScr){
      const kitchenState = JSON.parse(isKitchenScr);
      if(kitchenState === true){
        setIsKitchenScreenActive(true);
      }else{
        setIsKitchenScreenActive(false); 
      }
    }else{
      setIsKitchenScreenActive(false);
    }
  }, []);

  const { data: subData } = useSubscription(SUBSCRIBE_INSTORE_ORDERS, {
    onData: () => {
      refetch();
      refetchItem();
      message.info("New order created")
    },
    variables: { merchant_id: user?.employer ? user?.employer?.id  : user.id, branch_id: user?.business_branch ? user?.business_branch.id : homeBranch.id  }
  })

  const { 
    data: inStoreData, 
    loading: inStoreLoading,
    refetch, 
  } = useQuery(
    GET_INSTORE_ORDERS, 
    {variables: { 
      merchant_id: user?.business_branch?.home === false
        ? user?.employer?.id  
          || user.id : user?.employer?.id   
            || user.id,
      branch_id: user?.business_branch 
                  ? user?.business_branch.id  
                    : homeBranch.id, homebranch_id: !user?.business_branch 
                      ? homeBranch.id 
                        : null 
  }});

  const { 
    data: itemData, 
    loading: itemLoading,
    refetch: refetchItem 
  } = useQuery(
    GET_ONE_ORDERITEM, 
    {variables: { 
      merchant_id: user?.business_branch?.home === false 
        ? user?.employer?.id 
          || user.id : user?.employer?.id 
            || user.id,
      branch_id: user?.business_branch 
        ? user?.business_branch.id 
          : homeBranch.id, 
      homebranch_id: !user?.business_branch 
        ? homeBranch.id 
          : null 
      }});

  const setProperProductITems = (items) => {
    
  }

  const createNewOrderFlow = () => {
    // inStoreData && inStoreData
    const orderAndItems = inStoreData && inStoreData.getInstoreOrders.map((data)=>{
      const newData = {...data}
      const ordItem = [] 
      itemData && itemData.getOrderItems.map((item)=>{
        if(item.order_id === data.id){
          let newt = {...item}
          newt.product_name = newt.name;
          newt.product_quantity = newt.quantity;
          newt.original_price = newt.price;
          newt.product_variants = newt.variants;
          newt.product = newt;
          ordItem.push(newt);
        }
      });
      newData.order_items = ordItem;
      return newData;
    });

    setOrdersWithItems(orderAndItems);
    const pendingItems = [];
    const pendi = orderAndItems && orderAndItems.filter((data)=>{
      return data.status === "pending";
    })
    pendi && pendi.map((pen)=>{
      const { order_items } = pen;
      pendingItems.push(...order_items)
    })
    setPendingOrders(pendi);

    const arr = ['one', 'one', 'one', 'two', 'two', 'three'];

    const count = {};

    pendingItems && pendingItems.forEach(element => {
      if(element.variants.length > 0){
        count[element.name + "(" + element.variants[0].value + ")"] = (count[element.variants[0].value] || 0) + 1;
      }else if(element.custom_quantities.length > 0){
        count[element.name + "(" + element.custom_quantities[0].name + ")"] = (count[element.custom_quantities[0].name] || 0) + 1;
      }else{
        count[element.name] = (count[element.name] || 0) + 1;
      }
    });

    setItemsCount(count);

    // console.log("count",count, pendingItems);

    setPendingOrderItems(pendingItems);
    setProcessingOrders(()=>{
      return orderAndItems && orderAndItems.filter((data)=>{
        return data.status === "processing";
      })
    });

    setReadyOrders(()=>{
      return orderAndItems && orderAndItems.filter((data)=>{
        return data.status === "ready";
      })
    })
  }

  useEffect(()=>{
    createNewOrderFlow();
  }, [inStoreLoading, itemLoading, inStoreData, itemData]);

  const processOrder = (orderID)=>{
    setLoadProcessingOrder(true);
    const currentOrder = inStoreData && inStoreData.getInstoreOrders.find((order)=>{
      return order.id === orderID;
    });

    const timing = new Date(currentOrder.created_at);
    const username = user && user?.first_name + user?.last_name;
    const checkUser = user && user?.role_id === 1 ? user?.id : user?.username
    
    const reference_code = timing.getTime() + "_" + checkUser;
    
    getOneOrder({variables: {id: orderID, processed_by: user && user?.role_id === 1 ? "Admin" : user?.username, reference_code: reference_code}});

    setTimeout(()=>{

      refetch();
      message.success(strings.orderProcessedSuccessfully);
      // createNewOrderFlow();
      // Modal.success({
      //   title: strings.orderProcessedSuccessfully
      // });
      setLoadProcessingOrder(false);
    
    }, 1000);
  }

  const readyOrder = (orderID, index)=>{
    setLoadReadyOrder(true);
    
    readyOneOrder({variables: {id: orderID}});

    setTimeout(()=>{

      refetch();
      // Modal.success({
      //   title: strings.orderConfirmedSuccessfully
      // });
      message.success(strings.orderConfirmedSuccessfully);
      // createNewOrderFlow();
      setLoadReadyOrder(false);
      setCheckBoxValue((prev)=>{
        const newP = prev.filter(pre=>pre !== index )
        return newP;
      })
    
    }, 1000);
  }

  useEffect(()=>{
    setCheckBoxValue(checkBoxValue)
  }, [checkBoxValue]);
  

  return (
    <>
      {!isKitchenScreenActive && 
        <Modal
          open={!isKitchenScreenActive}
          title="Kindly Enable Loystar KDS to use this feature"
          footer={null}
        >
          <p>Click below to activate Loystar Kitchen Display System</p>
          <Button 
            onClick={()=>{
              history.push(`/d/app_store?communication&kds`);
            }}
            type="primary"
          >
            Enable
          </Button>
        </Modal>
      }
      {isKitchenScreenActive && <div
        className="ordercomponent"
      >
        <div className="ordersummary">
            <h1>Orders Summary</h1>
            <div className="pendingorderitems">
              {itemsCount && Object.keys(itemsCount).length > 0 
                ?
                Object.keys(itemsCount).sort((a, b)=>{return b.name - a.name}).map((item, index)=>{
                  return (
                  <div 
                    key={index} 
                    style={{ 
                      display: "flex", 
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 10px"
                    }}
                  >
                      <p>{item}</p>
                      <p><Tag color='warning'>{Object.values(itemsCount)[index]}</Tag></p>
                    </div>
                )}) : <h2>No Pending Item</h2>}
            </div>
        </div>
        <div className="orderdetails">
          <div className="orderheading">
            <h1 style={{ textTransform: "uppercase"}}>Orders in Queue</h1>
            <div className="orderrefresh">
              <a onClick={() => signoutUser(client)}>{strings.signOut}</a>
              <Button
                type="primary"
                onClick={()=>{
                  window.location.reload();
                }}
                size="large"
              >Refresh</Button>
            </div>
          </div>
          <div className="orderbody">
            <div className="orderitem">
              <h3>PENDING ORDERS {pendingOrders && `(${pendingOrders.length})`}</h3>
              <div className='cover'>
              {pendingOrders && pendingOrders.length > 0
                ? 
                  pendingOrders.sort((a, b)=> {return b.order_id - a.order_id}).map((orders, index)=>{
                    const { order_items } = orders;
                    return (
                    <div className="orderstructure" key={index}>
                      <div className="orderitemhead">
                        <h2>#{orders.order_id}</h2>
                        <p>
                        <ReactToPrint
                          trigger={() => (
                            <PrinterOutlined style={{fontSize: "24px"}}/>
                          )}
                          content={() => componentRef.current}
                        />
                        <div style={{ display: "none" }}>
                          <ComponentToPrint ref={componentRef} order={orders} transaction={order_items} user={user}/>
                        </div>
                        </p>
                        
                        {/* <p><PrinterOutlined /></p> */}
                      </div>
                      <div className="orderitembody">
                        <div className='orderitemothers'>
                          {order_items && order_items.map((item, index)=>{
                            return (
                              <div className="itemlist" key={index}>
                                <p>{item.name} {item.variants.length > 0 && `(${item.variants[0].value})`} {item.custom_quantities.length > 0 && `(${item.custom_quantities[0].name})`}</p>
                                {/* <p><Tag color='warning'>{item.quantity}</Tag></p> */}
                              </div>
                            )
                          })}
                        </div>
                        <div
                          style={{
                            display: "flex", 
                            flexDirection: "column", 
                            gap: "10px"
                          }} 
                        > 
                          {order_items && order_items.map((ite)=> {
                            return <Tag color='warning'>{ite.quantity}</Tag>
                          })}
                          </div>
                      </div>
                      <div className="orderitemfooter">
                        <Button
                          type="primary"
                          size='large'
                          loading={orderToLoad === orders.id && loadProcessOrder}
                          onClick={()=>{
                            setOrderToLoad(orders.id);
                            processOrder(orders.id);
                          }}
                        >
                          Process Order
                        </Button>
                      </div>
                    </div>)
                  })
                  : <h2>No Pending Orders</h2>}
              </div>
            </div>
            <div className="orderitem">
              <h3>PROCESSING ORDERS {processingOrders && `(${processingOrders.length})`}</h3>
              <div className="cover">
              {processingOrders && processingOrders.length > 0
                ? 
                processingOrders.sort((a, b)=> {return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()}).map((orders, index)=>{
                    const { order_items } = orders;
                    return (
                    <div className="orderstructure" key={index}>
                      <div className="orderitemhead">
                        <h2>#{orders.order_id}</h2>
                        <p>
                        <ReactToPrint
                          trigger={() => (
                            <PrinterOutlined   style={{fontSize: "24px"}}/>
                          )}
                          content={() => componentRef.current}
                        />
                        <div style={{ display: "none" }}>
                          <ComponentToPrint ref={componentRef} order={orders} transaction={order_items} user={user}/>
                        </div>
                        </p>
                      </div>
                      <div className="orderitembody">
                        <div className="orderitemothers">
                        {order_items && order_items.map((item, index)=>{
                          return (
                            <div className="itemlist" key={index}>
                              <p>{item.name} {item.variants.length > 0 && `(${item.variants[0].value})`} {item.custom_quantities.length > 0 && `(${item.custom_quantities[0].name})`}- {item.quantity}</p>
                              
                            </div>
                          )
                        })}
                        </div>
                        <Checkbox.Group 
                          options={order_items && order_items.map((item)=>{return { value: item.id}})}
                          style={{
                            display: "flex", 
                            flexDirection: "column", 
                            gap: "10px"
                          }}
                          // value={}
                          onChange={(value)=>{
                            if(value.length === order_items.length){
                              setCheckBoxValue((prev)=>{
                                return [...prev, index]
                              })
                            }else{
                              setCheckBoxValue((prev)=>{
                                const newP = prev.filter(pre=>pre !== index )
                                return newP;
                              })
                            }
                          }}
                          />
                      </div>
                      <div className="orderitemfooter">
                        <Button
                          type="primary"
                          size='large'
                          loading={orderToLoad === orders.id && loadReadyOrder}
                          onClick={()=>{
                            setOrderToLoad(orders.id)
                            readyOrder(orders.id, index)
                          }}
                          disabled={checkBoxValue.includes(index) ? false : true }
                        >
                          Ready Order
                        </Button>
                      </div>
                    </div>)
                  })
                  : <h2>No Order in this category</h2>}
              </div>
            </div>
            <div className="orderitem">
              <h3>READY ORDERS {readyOrders && `(${readyOrders.length})`}</h3>
              <div className="cover">
              {readyOrders && readyOrders.length > 0
                ? 
                readyOrders.sort((a, b)=> {return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()}).map((orders, index)=>{
                    const { order_items } = orders;
                    return (
                    <div className="orderstructure" key={index}>
                      <div className="orderitemhead">
                        <h2>#{orders.order_id}</h2>
                        <p>
                        <ReactToPrint
                          trigger={() => (
                            <PrinterOutlined  style={{fontSize: "24px"}}/>
                          )}
                          content={() => componentRef.current}
                        />
                        <div style={{ display: "none" }}>
                          <ComponentToPrint ref={componentRef} order={orders} transaction={order_items} user={user}/>
                        </div>
                        </p>
                      </div>
                      <div className="orderitembody">
                        <div className='orderitemothers'>
                          {order_items && order_items.map((item, index)=>{
                            return (
                              <div className="itemlist" key={index}>
                                <p>{item.name} {item.variants.length > 0 && `(${item.variants[0].value})`} {item.custom_quantities.length > 0 && `(${item.custom_quantities[0].name})`}</p>
                                {/* <p><Tag color='success'>{item.quantity}</Tag></p> */}
                              </div>
                            )
                          })}
                        </div>  
                        <div
                          style={{
                            display: "flex", 
                            flexDirection: "column", 
                            gap: "10px"
                          }} 
                        > 
                          {order_items && order_items.map((ite)=> {
                            return <Tag color='success'>{ite.quantity}</Tag>
                          })}
                          </div>               
                      </div>
                      <div className="orderitemfooter">
                        {/* <Button
                          type="primary"
                          size='large'
                          disabled
                        > */}
                          <h4 style={{ fontWeight: "300"}}>Awaiting Payment...</h4>
                        {/* </Button> */}
                      </div>
                    </div>)
                  })
                  : <h2>No Order in this category</h2>}
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
    
  )
}

const mapStateToProps = ( state ) => ({
  user: state.auth.user || state.merchant.user,
  auth: state.auth,
  openOrderScreen: state.allOrders.openOrderScreen,
});

export default connect(mapStateToProps, {
  activateKitchenScreen, 
  signoutUser
})(KitchScreen);