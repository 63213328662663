import {
  GET_ALL_CUSTOMERS,
  UPDATE_CUSTOMERS,
  ADD_LOYALTY_CUSTOMERS,
  SET_NEW_CUSTOMER,
} from "../constants";
import api from "../../api";

export const getAllCustomers = (pageNumber, pageSize) => async (dispatch) => {
  const res = await api.customer.getAllCustomers(pageNumber, pageSize);

  if (res && res.status === 200) {
    const filterCustomer =
      res && res.data.filter((customer) => customer.deleted === false);

    dispatch({
      type: GET_ALL_CUSTOMERS,
      payload: filterCustomer,
    });
  }

  return Promise.resolve(res);
};

export const searchForCustomer = (params, value) => async (dispatch) => {
  const res = await api.customer.searchCustomer(params, value);

  if (res && res.status === 200) {
    const filterCustomer =
      res && res.data.filter((customer) => customer.deleted === false);

    dispatch({
      type: GET_ALL_CUSTOMERS,
      payload: filterCustomer,
    });
  }

  return Promise.resolve(res);
};

export const setNewCustomer = (newCustomer) => async (dispatch) => {
  dispatch({
    type: SET_NEW_CUSTOMER,
    payload: newCustomer,
  });
};

export const updateCustomers = (customer) => async (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMERS,
    payload: customer,
  });
};

export const addLoyaltyCustomers = (customers) => async (dispatch) => {
  dispatch({
    type: ADD_LOYALTY_CUSTOMERS,
    payload: customers,
  });
};

export const addNewCustomer =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.addNewCustomer(requestPayload);
  };

export const editCustomer =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.editCustomer(requestPayload);
  };

export const deleteCustomer = (id) => (dispatch) => {
  return api.customer.deleteCustomer(id);
};

export const sendSmsToCustomer =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.sendSmsToCustomer(requestPayload);
  };

export const sendSmsToAllCustomer =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.sendSmsToAllCustomer(requestPayload);
  };

export const redeemReward =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.redeemReward(requestPayload);
  };

export const assignLoyaltyIdToCustomer =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.assignLoyaltyIdToCustomer(requestPayload);
  };

export const createWallet =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.createWallet(requestPayload);
  };


  export const fundWallet =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.fundWallet(requestPayload);
  };

  export const fundWalletOverdraftLimit =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.fundWalletOverdraftLimit(requestPayload);
  };

  export const withdrawWallet =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.withdrawWallet(requestPayload);
  };


  export const deleteWallet =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.deleteWallet(requestPayload);
  };


  export const importCustomers =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.customer.importCustomers(requestPayload);
  };
