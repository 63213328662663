import { GET_ALL_SALES } from "../constants";

const initialState = {
  allSales: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_SALES:
      return {
        allSales: payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
