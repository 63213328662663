import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, InputNumber, Spin, Form } from 'antd';
import { InputOTP } from 'antd-input-otp';
import "./index.scss";
import api from '../../../../../api';
import PhoneInput from "react-phone-input-2";
import Pusher from "pusher-js";
import strings from '../../../../../strings';
import moment from 'moment';
import { getAllCustomers } from '../../../../../redux/actions/customerActions';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import SalesReceipt from '../../SalesReceipt';
import { toggleRenewalModal } from '../../../../../redux/actions/renewalActions';



const FormItem = Form.Item;
const milisecondsToDaysConst = 86400000;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
const authUrl = `${BASE_URL}/pusher/auth`;

const CredpalProcessModal = ({ 
    open, 
    onCancel, 
    customer, 
    cartList, 
    totalAmount,
    user, 
    location,
    getAllCustomers,
    selectedCustomer,
    clearSelectedCustomer,
    customerValue,
    clearCart,
    setPaymentType,
    isRecordSalePaymentVisible,
    toggleTransactionModal,
    toggleRenewalModal,
}) => {
    const [ credPalLoading, setCredPalLoading ] = useState(false);
    const [ openModalOne, setOpenModalOne ] = useState(true);
    const [ openModalTwo, setOpenModalTwo ] = useState(false);
    const [ openModalThree, setOpenModalThree ] = useState(false);
    const [ openModalFour, setOpenModalFour ] = useState(false);
    const [ phoneNumber, setPhoneNumber ] = useState(null);
    const [ OTP, setOTP ] = useState([]);
    const [ isError, setIsError ] = useState(false);
    const [ paymentSession, setPaymentSession ] = useState({
        available_credit: null,
        sessionId: null,
        token: null
    });
    const otpRef = useRef();
    const componentRef = useRef();
    const { client } = api.HttpClient.defaults.headers.common;
    const business = user.employer ? { ...user.employer } : { ...user };

    const typesObj = {
        ngBasic: "basic-pay",
        ngPro: "pro-pay",
        ghBasic: "basic-pay-gh",
        ghPro: "pro-pay-gh",
        intBasic: "basic-pay-int",
        intPro: "pro-pay-int",
    };

    useEffect(()=>{
        if (user.payout_active || user.employer.payout_active) {
            // Pusher.log = (e) => console.log(e);
      
            const pusher = new Pusher(PUSHER_KEY, {
              cluster: PUSHER_CLUSTER,
              authEndpoint: authUrl,
              auth: {
                params: {
                  uid: user.employer ? user.employer.uid : user.uid,
                  access_token:
                    api.HttpClient.defaults.headers.common["access-token"],
                  client,
                },
              },
            });
      
            const channel = pusher.subscribe(
              `private-ussd_payment_${user.employer ? user.employer.id : user.id}`
            );
      
            channel.bind("new_payment_made", function (data) {
              // console.log("Pusher Data", data);
      
              const {
                status,
                ref_code,
                invoice: { status: invoiceStatus, paid_at, invoice_id, amount },
                customer: { first_name, last_name, phone_number, email },
              } = data;

              console.log("payemtn made", data);
      
              if (status === "success" && invoiceStatus === "paid") {
                // const { location } = self.state;
      
                // console.log(location);
      
                // Delete Bill if Bill is in Draft Sales
                if (location && location.state && location.state.fromDraftSales) {
                  const draftSalesList = JSON.parse(
                    localStorage.getItem("draftSales")
                  );
      
                  const filteredDraftSales =
                    draftSalesList.length &&
                    draftSalesList.filter(
                      (bill) => bill.id !== location.state.bill_id
                    );
      
                  localStorage.setItem(
                    "draftSales",
                    JSON.stringify(filteredDraftSales)
                  );
      
                  // console.log("Draft Sales Has been deleted");
      
                //   this.setState({ location: null });
                }
      
                const transactionDate = new Date();
      
                getAllCustomers().then((res) => {
                  const customerList =
                    res && res.data.filter((customer) => customer.deleted === false);
      
                  const customer =
                    selectedCustomer &&
                    customerList.find(
                      (item) => item.user_id === customerValue
                    );
      
                  // Show Success Message only When BankTransferMOdal is Open
                  open &&
                    Modal.success({
                      title: `Payment ${
                        amount ? `of ${user.currency}${amount}` : ""
                      } Received Successfully from ${first_name ? first_name : ""} ${
                        last_name ? last_name : ""
                      }`,
                      onOk: () => {
                        clearSelectedCustomer();
                        clearCart();
                        setPaymentType("");
                      },
                      content: (
                        <div style={{ display: "flex" }}>
                          <ReactToPrint
                            trigger={() => (
                              <Button type="primary">Print Receipt</Button>
                            )}
                            content={() => componentRef.current}
                          />
                          <div style={{ display: "none" }}>
                            <SalesReceipt
                              currency={user.currency || user.employer.currency}
                              saleTotal={totalAmount}
                              paidWith={"bank transfer"}
                              transaction={cartList}
                              saleId={ref_code}
                              purchaseTime={moment(transactionDate || Date.now()).format("DD-MM-YYYY hh:mm a")}
                              customer={customer}
                              user={user}
                              ref={componentRef}
                            //   channel={self.state.selectedBank.code}
                              payment_reference={""}
                            />
                          </div>
                        </div>
                      ),
                    });
      
                //   self.setState((prevState) => ({
                //     ...prevState,
                //     isLoading: false,
                //     selectedBank: {
                //       name: "",
                //       code: "",
                //       isLoading: false,
                //     },
                //   }));
      
                  // Close Current Modal only when its open
                  onCancel();
      
                  // Close Record Sale Modal only when its open
                  isRecordSalePaymentVisible && toggleTransactionModal();
      
                  let secondsToGo = 60;
      
                  if (business.subscription_plan === "Free") {
                    const country =
                      business.currency === "NGN"
                        ? "ng"
                        : business.currency === "GHS"
                        ? "gh"
                        : "int";
      
                    let subscription_plan = "Basic";
      
                    const subPaymentType = typesObj[country + subscription_plan];
      
                    const daysLeft = Math.ceil(
                      (new Date(business.subscription_expires_on) - Date.now()) /
                        milisecondsToDaysConst
                    );
      
                    const modal =
                      daysLeft <= 0 &&
                      Modal.success({
                        title: `${strings.holdOn} ${secondsToGo}`,
                        content: <span>{strings.yourSubscriptionHasExpired}</span>,
                        okText: strings.renew,
                        onOk: () => toggleRenewalModal(),
                      });
      
                    const timer =
                      daysLeft <= 0 &&
                      setInterval(() => {
                        secondsToGo--;
                        modal.update({
                          title: `${strings.holdOn} ${secondsToGo}`,
                        });
                      }, 1000);
      
                    daysLeft <= 0 &&
                      setTimeout(() => {
                        clearInterval(timer);
                        modal.destroy();
                      }, secondsToGo * 1000);
                  } else {
                    const country =
                      business.currency === "NGN"
                        ? "ng"
                        : business.currency === "GHS"
                        ? "gh"
                        : "int";
      
                    const subPaymentType =
                      typesObj[country + business.subscription_plan];
      
                    const daysLeft = Math.ceil(
                      (new Date(business.subscription_expires_on) - Date.now()) /
                        milisecondsToDaysConst
                    );
      
                    const modal =
                      daysLeft <= 0 &&
                      Modal.success({
                        title: `${strings.holdOn} ${secondsToGo}`,
                        content: <span>{strings.yourSubscriptionHasExpired}</span>,
                        okText: strings.renew,
                        onOk: () => toggleRenewalModal(),
                      });
      
                    const timer =
                      daysLeft <= 0 &&
                      setInterval(() => {
                        secondsToGo--;
                        modal.update({
                          title: `${strings.holdOn} ${secondsToGo}`,
                        });
                      }, 1000);
      
                    daysLeft <= 0 &&
                      setTimeout(() => {
                        clearInterval(timer);
                        modal.destroy();
                      }, secondsToGo * 1000);
                  }
                });
              }
            });
          }
    }, []);

    useEffect(()=>{
        customer && setPhoneNumber(customer.phone_number);
    }, [customer]);

    useEffect(()=>{
        if(openModalTwo){
            otpRef.current.focus();
        }
    }, [openModalTwo]);


    const verifyPhoneNumber = () => {
        const phone_number = phoneNumber;
        
        setCredPalLoading(true);
        api.credley.initiateTransaction(phone_number)
        .then((res)=>{
            if(res.data.status===false){
                return Modal.error({
                    title: res.data?.errors?.bvn[0] || res.data?.message || "An unexpected error occured",
                    onOk: () => setCredPalLoading(false)
                });
            }else{
                setPaymentSession({
                    available_credit: res.data.data.available_credit,
                    sessionId: res.data.data.sessionId,
                    token: res.data.data.token
                });
                if(res.data.data.available_credit < totalAmount) setIsError(true);
    
                setOpenModalOne(false);
                setOpenModalTwo(true);
                setCredPalLoading(false);
            }
        })
        .catch((err)=>{
            Modal.error({
                title: err?.response?.data || "An unexpected error occured",
                onOk: () => setCredPalLoading(false)
            });
        })
        
    };

    const handleOTPInput = (value) =>{
        setOTP((prev)=>{
            return [...prev, ...value.filter(val=>val !== undefined)]
        });
    };

    const verifyOTP = () => {
        const value = OTP.toString().split(",").join("");
        if(value.length !== 6){
            Modal.error({
                title: "OTP must be six letter",
                onOk: setCredPalLoading(false)
            })
        }else if(value !== paymentSession.token){
            Modal.error({
                title: "Wrong otp input",
                onOk: setCredPalLoading(false)
            });
        }else{
            setOpenModalTwo(false);
            setOpenModalThree(true);
        }
    };
 
    const verifyPayment = () =>{
        const date = new Date().getTime();

        const data = {
            token: paymentSession.token,
            session_id: paymentSession.sessionId,
            amount: totalAmount,
            description: JSON.stringify(cartList),
            reference: `credley_${date}`
        };

        setCredPalLoading(true);
        api.credley.checkoutTransaction(data)
        .then((res)=>{
            if(res.data.message === "Processing transaction"){
                setCredPalLoading(false);
                setOpenModalThree(false);
                setOpenModalFour(true);
            }else{
                Modal.error({
                    title: res?.data?.message || "An unexpected error occured, pls try again",
                    onOk: () => setCredPalLoading(false)
                })
            }
        })
        .catch((err)=>{
            console.log(err);
            Modal.error({
                title: err?.response?.data || "An unexpected error occured",
                onOk: () => setCredPalLoading(false)
            });
        })
        
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            confirmLoading={credPalLoading}
            footer={null}
            title={
                openModalOne 
                ? "Pay with CredPal" 
                : openModalTwo 
                ? "Enter verification code" 
                : openModalThree 
                ? "Payment Confirmation" 
                : openModalFour 
                ? "Transaction In Progress" : null}
            destroyOnClose
        >
            {openModalOne && <div className="modal_1">
                <div className="md_1">
                    <div>
                        <Form
                            onFinish={verifyPhoneNumber}
                            autoComplete="off"
                        >
                            {!customer && <FormItem
                               label="Phone Number"
                               name="phone_number"
                               rules={[{ required: true, message: 'Please input your phone number!' }]}
                            >
                                <PhoneInput
                                    inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                                    inputStyle={{
                                        border: "1px solid #d9d9d9",
                                        width: "100%",
                                        height: "40px",
                                    }}
                                    value={phoneNumber}
                                    onChange={(value)=>setPhoneNumber(value)}
                                    country="ng"
                                    placeholder={phoneNumber}
                                    defaultMask={phoneNumber}
                                />
                            </FormItem>}
                            {customer && <FormItem
                               label="Phone Number"
                               name="phone_number"
                               rules={[{ required: false, message: 'Please input your phone number!' }]}
                            >
                                <InputNumber 
                                    value={phoneNumber}
                                    onChange={(value)=>setPhoneNumber(value)}
                                    defaultValue={phoneNumber}
                                    disabled
                                    placeholder={phoneNumber}
                                />
                            </FormItem>}
                            <FormItem className='btn-div'>
                                <Button 
                                    loading={credPalLoading}
                                    type="primary"
                                    // onClick={verifyPhoneNumber}
                                    htmlType='submit'
                                >
                                    Verify Identity
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </div>}
            {openModalTwo && <div className="modal_2">
                <div className="md_1">
                    <div>
                        <p>A 4-digit code was sent to your number ending with {phoneNumber.substr(phoneNumber.length - 4, phoneNumber.length)}</p>
                        <InputOTP 
                            inputType='numeric'
                            length={6}
                            autoSubmit={verifyOTP}
                            autoFocus={true}
                            onChange={handleOTPInput}
                            ref={otpRef}
                        />
                    </div>

                    <div className="btn-div">
                        <Button 
                            loading={credPalLoading}
                            type="primary"
                            onClick={verifyOTP}
                        >
                            Verify
                        </Button>
                        <p>Didn’t get the code? <span onClick={verifyPhoneNumber}>Resend code</span></p>
                    </div>
                </div>
            </div>}
            {openModalThree && <div className="modal_3">
                <div className="md_1">
                    <div>
                        <p style={{color: isError ? "red" : "#000"}}>*Credit is good, transaction can proceed</p>
                        <p>Do you want to proceed with this transaction and pay {user && user.currency}{totalAmount} to {user && user.business_name}?</p>
                    </div>

                    <div className="btn-div">
                        <Button 
                            loading={credPalLoading}
                            type="primary"
                            onClick={verifyPayment}
                        >
                            Yes
                        </Button>
                        <Button 
                            onClick={onCancel}
                        >
                            No
                        </Button>
                    </div>
                </div>
            </div>}
            {openModalFour && <div className="modal_4">
            <div className="md_1">
                    <div>
                        <p>Do not close this page.</p>

                        <div><Spin size='large'/></div>

                        <p>Your payment is processing and you will be redirected once it is approved.</p>
                    </div>
                </div>
            </div>}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    products: state.product.allProducts,
});
  
export default connect(mapStateToProps, {
    getAllCustomers,
    toggleRenewalModal,
})(CredpalProcessModal);


