// import { Tabs } from "antd";
import React from "react";
// import Requests from "./Requests";
// import Orders from "./Orders";
// import AdminOrders from "./AdminOrders";
import { connect } from "react-redux";
import Delivery from "./Delivery";
// import strings from "../../../../strings";

// const { TabPane } = Tabs;

const Index = ({ user }) => {
  // const [activeTab, setActiveTab] = useState("1");
  return (
    <section>
      {/* <Tabs
        activeKey={activeTab}
        onChange={(activeKey) => setActiveTab(activeKey)}
      > */}
        {/* <TabPane tab="Requests" key="3">
          <Requests />
        </TabPane>
        <TabPane tab="Orders" key="2">
          <Orders />
        </TabPane> */}
        {/* <TabPane tab={strings.deliveryRequests} key="1"> */}
          <Delivery />
        {/* </TabPane> */}
        {/* {user && user.email === "hello@loystar.co" && (
          <TabPane tab="Admin" key="3">
            <AdminOrders goBack={() => setActiveTab("1")} />
          </TabPane>
        )} */}
      {/* </Tabs> */}
    </section>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Index);
