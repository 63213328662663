import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, InputNumber } from 'antd';
import api from '../../../../../../api';

const FormItem = Form.Item;

const BVNModal = ({ open, onCancel, phone_number }) => {
    const [ phoneNumber, setPhoneNumber ] = useState(phone_number);
    const [ BVN, setBVN ] = useState(null);
    const [ credPalLoading, setCredPalLoading ] = useState(false);

    useEffect(()=>{
        setPhoneNumber(phone_number);
    }, [phone_number]);

    const verifyPhoneNumber = () => {
        const data = {
            bvn: BVN,
            phone_number: phoneNumber
        }
        setCredPalLoading(true);
        api.credley.updateCustomerBVN(data)
        .then((res)=>{
            Modal.success({
                title: "BVN successfully updated!",
                onOk: () => {
                    setCredPalLoading(false);
                    onCancel();
                }
            })
            setCredPalLoading(false);
        })
        .catch((err)=>{
            Modal.error({
                title: err.response.data,
                onOk: () => setCredPalLoading(false)
            })
        })
        
    }
  return (
    <Modal
        title="Update BVN"
        open={open}
        onCancel={onCancel}
        footer={null}
    >
        <div className="md_1">
            <div>
                <Form
                    onFinish={verifyPhoneNumber}
                    autoComplete="off"
                >
                    <FormItem
                        label="Phone Number"
                        name="phone_number"
                        rules={[{ required: false, message: 'Please input your phone number!' }]}
                    >
                        <InputNumber
                            inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                            style={{
                                border: "1px solid #d9d9d9",
                                width: "100%",
                                height: "40px",
                            }}
                            placeholder={phoneNumber}
                            value={phoneNumber}
                            onChange={(value)=>setPhoneNumber(value)}
                            disabled={true}
                        />
                    </FormItem>
                    <FormItem  
                        label="BVN"
                        name="BVN"
                        rules={[{required: true, message: "Please input your BVN"}]}
                    >
                        <InputNumber 
                            value={BVN}
                            onChange={(value)=>setBVN(value)}
                            style={{
                                width: "100%",
                            }}
                        />
                    </FormItem>
                    <FormItem className='btn-div'>
                        <Button 
                            loading={credPalLoading}
                            type="primary"
                            // onClick={verifyPhoneNumber}
                            htmlType='submit'
                        >
                           Update BVN
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    </Modal>
  )
}

export default BVNModal;