import React from "react";
import { Modal, Button, InputNumber } from "antd";
import { Form } from "@ant-design/compatible";
import strings from "../../../../../../strings";

const InputBranchModal = ({
  addons,
  setAddons,
  isInputBranchModalOpen,
  toggleInputBranchModal,
  setNumOfBranches,
  form,
}) => {
  const { getFieldDecorator, validateFields } = form;

  const handleBranchInput = () => {
    validateFields((errors, values) => {
      if (errors) return;

      setNumOfBranches(values.numOfBranches);
      toggleInputBranchModal();
    });
  };

  return (
    <Modal
      title={strings.enterNumberOfBranches}
      destroyOnClose
      visible={isInputBranchModalOpen}
      onCancel={() => {
        setAddons({ ...addons, hasBranches: false });
        toggleInputBranchModal();
      }}
      footer={[
        <Button
          key="1"
          onClick={() => {
            setAddons({ ...addons, hasBranches: false });
            toggleInputBranchModal();
          }}
        >
          {strings.cancel}
        </Button>,
        <Button key="2" type="primary" onClick={() => handleBranchInput()}>
          {strings.save}
        </Button>,
      ]}
    >
      <Form layout="vertical" >
        <Form.Item label={strings.enterNumberOfAdditionalBranchesToPay}>
          {getFieldDecorator("numOfBranches", {
            rules: [
              {
                required: true,
                type: "number",
                message: strings.pleaseEnterANumericValue,
              },
            ],
          })(<InputNumber size="large" style={{ width: "100%" }} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const InputBranchModalForm = Form.create()(InputBranchModal);

export default InputBranchModalForm;
