import api from "../api";

const setAuthToken = (token) => {
  if (token) {
    api.HttpClient.defaults.headers.common.uid = token.uid;
    api.HttpClient.defaults.headers.common.client = token.client;
    api.HttpClient.defaults.headers.common["access-token"] =
      token["access-token"];
    api.HttpClient.defaults.headers.common["expiry"] = token["expiry"];
    api.HttpClient.defaults.headers.common["token-type"] = token["token-type"];
    api.HttpClient.defaults.headers.common["user-auth"] = `Bearer ${token.mtier}`;
    api.HttpClient.defaults.headers.common["Content-Type"] = "application/json";
  } else {
    api.HttpClient.defaults.headers.common = {};
  }
};

export default setAuthToken;
