import {
  CHANGE_MULTILEVEL_STATUS,
  CHANGE_BOOKING_SUBSCRIPTION,
} from "../constants";

const initialState = {
  isSubscribed: false,
  isSubscribedToBookings: false,
};

const multiLevelReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_MULTILEVEL_STATUS:
      return {
        ...state,
        isSubscribed: payload,
      };

    case CHANGE_BOOKING_SUBSCRIPTION:
      return {
        ...state,
        isSubscribedToBookings: payload,
      };

    default:
      return state;
  }
};

export default multiLevelReducer;
