import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Icon, Input, Button, Tooltip, message } from "antd";
import { Form } from "@ant-design/compatible";
import { connect } from "react-redux";
import ChangeLanguage from '../../ChangeLanguage';
import strings from '../../../strings';
import { multiLevelSignin } from '../../../redux/actions/authActions';
import { changeLanguage } from '../../../redux/actions/languageActions';



const FormItem = Form.Item;

const KDSSignin = ({ 
    auth, 
    form, 
    multiLevelSignin 
}) => {
    const history = useHistory();
    const { getFieldDecorator } = form;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (auth.isAuthenticated) {
            history.push("/loystar-kds");
        }
    }, [auth, history]);

      const handleSubmit = async e => {
        e.preventDefault();
        const { email, password } = await form.getFieldsValue();
    
        if(navigator.onLine === false) return message.info("Please check your connectivity and try again");
        
        try {
          const values = await form.validateFields();
          setIsLoading(true);
          await multiLevelSignin(values, history)
          .then((res)=>{
            console.log("this re", res);
          })
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          if( email === ""){
            message.error("Please enter an admin email or staff username");
          }else if( password === ""){
            message.error(strings.pleaseEnterPassword);
          }else{
            message.error(error.message);
          }
        }
      };
    

      return (
        <div>
          <ChangeLanguage />
          <div className="signin auth">
            <h1 className="title">{strings.signInKDS}</h1>
            <div className="auth-form__container">
              <Form layout="vertical" onSubmit={handleSubmit}>
                {/* Username Input */}
                <FormItem>
                  <Tooltip
                    trigger={["focus"]}
                    title={"Admin signs in with email while Staff signs in with username (case sensitive)"}
                    placement="topLeft"
                  >
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          message: 'Please enter an admin email or staff username',
                          required: true,
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        prefix={
                          <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder={"Email or username"}
                      />
                    )}
                  </Tooltip>
                </FormItem>
                {/* ======Username */}
    
                {/* Password Input */}
                <FormItem>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        message: strings.pleaseEnterPassword,
                        required: true,
                      },
                    ],
                  })(
                    <Input.Password
                      size="large"
                      prefix={
                        <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder={strings.password}
                    />
                  )}
                </FormItem>
                {/* ======Password */}
    
                <Button
                  type="primary"
                  size="large"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {strings.signIn}
                </Button>
    
                <div className="form-utils__container">
                  {/* <a href="https://play.google.com/store/apps/details?id=co.loystar.loystarbusiness">Get the Android App</a> */}
                  <Link to="/signup">{strings.createAnAccount}</Link>
                  <Link to="/reset-password">{strings.forgotPassword}</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      );
}

const KDSSigninForm = Form.create()(KDSSignin);

const mapStateToProps = state => ({
    auth: state.auth,
    error: state.error,
});

const mapDispatchToProps = dispatch => {
    return {
      multiLevelSignin: userData => dispatch(multiLevelSignin(userData)),
      changeLanguage: language => dispatch(changeLanguage(language)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KDSSigninForm);