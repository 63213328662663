import React from "react";
import { Modal, Input, notification, message } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";
import strings from "../../../../../../strings";
import { connect } from "react-redux";


class SetServicePriceModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialProductItemData: {},
      theVarian: {}
    };
  }

  componentDidMount() {
    const { form, item, isNitroActive, isProductBundleActive } = this.props;
    form.setFieldsValue({ price: item.price });

    // console.log(isNitroActive);
    if(isNitroActive){
      // console.log(item);
    }else if(item.variants){
      const initialProductsData = JSON.parse(localStorage.getItem("products"));
      const initialProductItemData = initialProductsData.filter(
        product => product.id === item.id
      )[0];
      const theVariant = initialProductItemData.variants.filter((variant)=>{
        return variant.id === item.variants[0].id
      })[0];

      this.setState({ initialProductItemData: initialProductItemData, theVarian: theVariant});
    }else if(isProductBundleActive){

    }
    
  }

  onSetPrice = item => {
    this.handleSetPrice();
  };

  onEnter = e => {
    if (e.keyCode === 13) {
      this.handleSetPrice();
    }
  };

  // static getDerivedStateFromProps(props, state) {
  //   const { products, item } = props;

  //   const productItem = products.filter(product => product.id === item.id)[0];

  //   if (
  //     productItem &&
  //     productItem.quantity !== state.initialProductItemData.quantity
  //   ) {
  //     return {
  //       initialProductItemData: {
  //         ...state.initialProductItemData,
  //         quantity: productItem.quantity,
  //         variants: productItem.variants,
  //       },
  //     };
  //   }

  //   return null;
  // }

  handleSetPrice = () => {
    const { 
      form, 
      handleAdd, 
      item, 
      isNitroActive,
       handleAddBundle, 
       closeModal, 
       checkoutData,
       addServiceToCart 
    } = this.props;
    const { initialProductItemData } = this.state;

    form.validateFields((err, values) => {
      if (err) return;

      let newItem = { ...item };

      newItem.amount_charged = values.price;
      
        addServiceToCart(newItem);
        closeModal();
    });
  };

  render() {
    const {
      closeModal,
      form: { getFieldDecorator },
      visible,
      item,
      isNitroActive,
      isProductBundleActive,
      checkoutData
    } = this.props;
    const { initialProductItemData, theVarian } = this.state;

    return (
      <div>
        <Modal
          title={`Set ${item.name} price`}
          okText={"set price"}
          cancelText={strings.cancel}
          open={visible}
          destroyOnClose
          onCancel={closeModal}
          onOk={() => {
            this.onSetPrice(item);
          }}
        >
          <Form layout="vertical" >
            <Form.Item label={"Enter custom price"}>
              {getFieldDecorator("price", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseEnterServicePrice,
                  },
                ],
              })(<Input size="large" type="number" onKeyUp={this.onEnter} />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const SetServicePriceModalForm = Form.create()(SetServicePriceModal);

SetServicePriceModal.propTypes = {
  item: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  handleAdd: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isNitroActive: state.appStore.isNitroActive,
  isProductBundleActive: state.appStore.isProductBundleActive
});

export default connect(mapStateToProps)(SetServicePriceModalForm);
