import React, { useState, useEffect } from "react";
import api from "../../../../api";
import Pusher from "pusher-js";
import QRCode from "react-qr-code";
import { Badge, Button, message, Modal, notification, Spin } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import CountdownTimer from "./CountdownTimer";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;

function PaystackTransfer({
  user,
  terminalData,
  visible,
  closeModal,
  totalAmount,
  hasDiscount,
  discountAmount,
  handlePayment,
  transactionDate,
  clearDiscount,
  hasInstantRewardDiscount,
  location,
  paystackInvoiceData,
  instantRewardDiscount,
  onCancel,
}) {
  const [resendInvoiceLoading, setResendInvoiceLoading] = useState(false);

  const resendPaystackInvoice = () => {
    const { paystack_invoice_id, offline_reference } = paystackInvoiceData;
    const { terminal_id } = terminalData;
    const payload = {
      data: {
        paystack_invoice_id,
        offline_reference,
        terminal_id,
      },
    };
    setResendInvoiceLoading(true);
    api.HttpClient.post("/merchants/resend_paystack_invoice", payload)
      .then((res) => {
        setResendInvoiceLoading(false);
        if (res.status === 200) {
          message.success("Bill sent to Terminal");
        }
      })
      .catch((err) => {
        setResendInvoiceLoading(false);
        message.error(err.response?.data.message || err.message);
      });
  };

  const authUrl = `${BASE_URL}/pusher/auth`;
  const { client } = api.HttpClient.defaults.headers.common;
  const pusher = new Pusher(PUSHER_KEY, {
    cluster: PUSHER_CLUSTER,
    authEndpoint: authUrl,
    auth: {
      params: {
        uid: user.employer ? user.employer.uid : user.uid,
        access_token: api.HttpClient.defaults.headers.common["access-token"],
        client,
      },
    },
  });

  useEffect(() => {
    const channel = pusher.subscribe(
      `private-ussd_payment_${user.employer ? user.employer.id : user.id}`
    );

    channel.bind("new_terminal_payment_made", function (data) {
      console.log("Pusher Data", data);

      const {
        status,
        transaction: { status: invoiceStatus, amount, reference },
      } = data;

      if (
        status === "success" &&
        invoiceStatus === "success" &&
        amount / 100 >= totalAmount
      ) {
        notification.success({
          message: `Payment of ${user.currency || user.employer?.currency}${
            amount / 100
          }
            received successully`,
          duration: 0,
          placement: "topRight",
        });

        if (hasDiscount || hasInstantRewardDiscount()) {
          handlePayment(
            "card",
            location,
            true,
            discountAmount || instantRewardDiscount,
            reference,
            clearDiscount,
            transactionDate || Date.now()
          );
        } else {
          handlePayment(
            "card",
            location,
            false,
            null,
            reference,
            clearDiscount,
            transactionDate || Date.now()
          );
        }
        onCancel();
        unsuscribeEvent();
        closeModal();
      }
    });
    return () => {
      unsuscribeEvent();
    };
  }, []);

  console.log({ paystackInvoiceData });

  const handleMakePayment = () => {
    if (hasDiscount || hasInstantRewardDiscount()) {
      handlePayment(
        "card",
        location,
        true,
        discountAmount || instantRewardDiscount,
        `${paystackInvoiceData?.terminal_id || ""}-${
          paystackInvoiceData?.paystack_invoice_id || ""
        }-${paystackInvoiceData?.offline_reference || ""}`,
        clearDiscount,
        transactionDate || Date.now()
      );
    } else {
      handlePayment(
        "card",
        location,
        false,
        null,
        `${paystackInvoiceData?.terminal_id || ""}-${
          paystackInvoiceData?.paystack_invoice_id || ""
        }-${paystackInvoiceData?.offline_reference || ""}`,
        clearDiscount,
        transactionDate || Date.now()
      );
    }
    onCancel();
    unsuscribeEvent();
    closeModal();
  };

  const unsuscribeEvent = () => {
    pusher.unsubscribe(`private-ussd_payment_${user.id}`);
    pusher.disconnect();
  };

  return (
    <Modal
      title={
        <p style={{ textTransform: "capitalize" }}>
          Pay With {terminalData?.terminal_provider}
        </p>
      }
      open={visible}
      onCancel={() => {
        Modal.confirm({
          title: "Do you want to cancel this transaction?",
          onOk: () => {
            unsuscribeEvent();
            closeModal();
          },
        });
      }}
      footer={null}
      destroyOnClose
    >
      <div id="pay_with_bank_transfer">
        <div style={{ textAlign: "center" }}>
          <p>
            Waiting for {terminalData?.terminal_provider} terminal payment at{" "}
            {terminalData?.terminal_label}
          </p>
          <p>
            Amount: {user.currency || user.employer?.currency}
            {totalAmount}
          </p>
          <Spin />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <QRCode
              size={150}
              value={paystackInvoiceData?.offline_reference || ""}
            />
            <p style={{ marginTop: 20 }}>
              Payment reference: {paystackInvoiceData?.offline_reference}{" "}
            </p>
            {terminalData?.terminal_provider === "paystack" && (
              <>
                <span style={{ marginBottom: 10 }}>
                  Didn't get bill on terminal? Click to resend or scan barcode
                </span>
                <Button
                  onClick={resendPaystackInvoice}
                  disabled={!paystackInvoiceData}
                  loading={resendInvoiceLoading}
                  style={{
                    width: "20%",
                  }}
                  title="Resend invoice when available"
                >
                  <RedoOutlined />
                  <Badge
                    style={{ marginLeft: 10 }}
                    status={
                      terminalData?.terminal_provider === "payforce" ||
                      !!terminalData?.presence?.online
                        ? "success"
                        : "error"
                    }
                  />
                </Button>
              </>
            )}

            <div style={{ marginTop: 20 }}>
              <CountdownTimer
                countDown={120}
                btnText="Transaction Successful"
                onClick={handleMakePayment}
                loading={false}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PaystackTransfer;
