import React from 'react'


export const getShock = (quantity) => {
  if (quantity === 0) {
    return <span>no stock ({quantity})</span>;
  } else if (quantity > 0 && quantity < 5) {
    return <span>low stock ({quantity})</span>;
  } else {
    return quantity;
  }
}