import React from "react";
import { connect } from "react-redux";
import ChangeLanguage from "../../../components/ChangeLanguage";
import { Link, withRouter } from "react-router-dom";
import {
  sendEmailToken,
  resetPassword,
} from "../../../redux/actions/authActions.js";

import { Modal } from "antd";

import "./index.scss";

// Form container style
import "../index.scss";
import EnterPasswordForm from "./_partials/password.jsx";
import strings from "../../../strings.js";

class ResetCode extends React.Component {
  state = {
    isEmailLoading: false,
    isPasswordLoading: false,
  };

  onResetPassword = payload => {
    const { resetPassword, history } = this.props;

    this.setState({ isPasswordLoading: true });

    resetPassword(payload).then(res => {
      this.setState({ isPasswordLoading: false });

      if (res && res.status === 200) {
        Modal.success({
          title: strings.passwordResetSuccessful,
          onOk: () => {
            history.push("/signin");
          },
        });

        return;
      } else if (res) {
        Modal.error({
          title: strings.somethingWentWrong,
          content: res.data,
        });
      }
    });
  };

  render() {
    const { isPasswordLoading } = this.state;
    return (
      <div>
        <ChangeLanguage />
        <div className="reset-password auth">
          <h1 className="title">{strings.resetPassword}</h1>
          <div className="auth-form__container">
            <EnterPasswordForm
              loading={isPasswordLoading}
              resetPassword={this.onResetPassword}
            />

            <div className="form-utils__container">
              <Link to="/signup">{strings.signUp}</Link>
              <Link to="/signin">{strings.signIn}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { sendEmailToken, resetPassword })(
  withRouter(ResetCode)
);
