import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import SubscriptionNotification from "../SubscriptionNotification";

const PrivateRoute = ({ component: Component, auth, subscriptionStatus, ...rest }) => {
  // if (
  //   auth.isAuthenticated &&
  //   !localStorage.getItem("isUserDataSentToSegement")
  // ) {
  //   window.analytics.identify(auth.user.id, auth.user);
  //   localStorage.setItem("isUserDataSentToSegement", "true");
  // }

  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated) {
          return (
            <div style={{ position: "relative" }}>
              {subscriptionStatus === "not_active" || subscriptionStatus === "incomplete" ? <SubscriptionNotification {...auth} user={auth.user} /> : null}
              <Component {...props} />
            </div>
          );
        } else {
          return <Redirect to="/signin" />;
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateProps = state => ({
  auth: state.auth,
  subscriptionStatus: state.renewal.subscriptionStatus
});

export default connect(mapStateProps)(PrivateRoute);
