import React, { useEffect } from "react";
import { Button, Checkbox, Modal } from "antd";
import numberFormatter from "../../../../utils/numberFormatter";
import SelectCustomer from "./SelectCustomer";
import PropTypes from "prop-types";
import strings from "../../../../strings";

const CartFooter = ({
  user,
  subPaymentType,
  denyAccess,
  customers,
  customerValue,
  onCustomerSearch,
  toggleTransactionModal,
  getCartTotalAmount,
  addLoyaltyProgramToSale,
  cleanupSelectCustomerDropdown,
  toggleAddLoyaltyProgramToTransaction,
  toggleRenewalModal,
  isRedeemRewardModalOpen,
  toggleRedeemRewardModal,
  location,
  customerName,
  handleBlurFactor
}) => {
  useEffect(() => {
    return () => {
      cleanupSelectCustomerDropdown();
    };
  }, [cleanupSelectCustomerDropdown]);

  useEffect(() => {
    handleBlurFactor(false);
  }, []);

  // console.log("deny", denyAccess);
  

  return (
    <div className="cart-footer">
      <SelectCustomer
        customerValue={customerValue}
        onCustomerSearch={onCustomerSearch}
        customers={customers}
        cleanupSelectCustomerDropdown={cleanupSelectCustomerDropdown}
        isDisabled={location.state?.fromInstoreOrder}
        instoreOrders={location.state?.fromInstoreOrder}
        customerName={customerName}
        handleBlurFactor={handleBlurFactor}
        user={user}
      />
      <div className="sub-container">
        <div className="total-container">
          <div>
            <Checkbox
              checked={addLoyaltyProgramToSale}
              onChange={toggleAddLoyaltyProgramToTransaction}
            >
              {strings.addLoyalty}
            </Checkbox>
          </div>

          <div>
            <Checkbox
              checked={isRedeemRewardModalOpen}
              onChange={toggleRedeemRewardModal}
            >
              {strings.redeemReward}
            </Checkbox>
          </div>
        </div>
      </div>

      <div className="checkout-btn">
        <Button
          type="primary"
          size="large"
          style={{ width: "97%", height: "45px" }}
          onClick={() => {
            denyAccess
              ? Modal.warning({
                  title: strings.yourSubscriptionHasExpired,
                  okText: strings.renew,
                  onOk: () => toggleRenewalModal(),
                })
              : toggleTransactionModal();
          }}
          disabled={denyAccess}
        >
          {strings.checkout}{" "}
          {user.currency || (user.employer && user.employer.currency)}
          {numberFormatter(getCartTotalAmount())}
        </Button>
      </div>
    </div>
  );
};

CartFooter.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerValue: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  onCustomerSearch: PropTypes.func.isRequired,
  toggleTransactionModal: PropTypes.func.isRequired,
  getCartTotalAmount: PropTypes.func.isRequired,
};

export default CartFooter;
