import {
  GET_OFFERS,
  DELETE_OFFERS,
  SAVE_OFFERS,
  GET_BIRTHDAY_MESSAGE,
  EDIT_BIRTHDAY_MESSAGE
} from "../constants";
import api from "../../api";

export const getAllOffers = (id) => async dispatch => {
  const res = await api.birthdayOffers.getAllOffers(id);

  if (res && res.status === 200) {
    dispatch({
      type: GET_OFFERS,
      payload: res.data
    });
  }

  return Promise.resolve(res);
};

export const saveOffer = (requestPayload = {}) => dispatch => {
  return api.birthdayOffers.saveOffer(requestPayload);
};

export const deleteOffer = (requestPayload = {}) => dispatch => {
  return api.birthdayOffers.deleteOffer(requestPayload)
};

export const getBirthdayMessage = () => async dispatch => {
  const res = await api.birthdayOffers.getBirthdayMessage();

  if (res && res.status === 200) {
    dispatch({
      type: GET_BIRTHDAY_MESSAGE,
      payload: res.data
    });
  }

  return Promise.resolve(res);
};

export const editBirthdayMessage = (merchantId, message) => async dispatch => {
  const res = await api.birthdayOffers.editBirthdayMessage(merchantId, message);

  if (res && res.status === 200) {
    dispatch({
      type: EDIT_BIRTHDAY_MESSAGE,
      payload: res.data
    });
  }

  return Promise.resolve(res);
};

export const createBirthdayOffer = (data) => async dispatch => {
  const res = await api.birthdayOffers.createBirthdayOffer(data);

  return Promise.resolve(res);
};
