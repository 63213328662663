import React from "react";
import Barcode from "react-barcode";
import "./index.scss";

const Tag = ({ sku, price, name, currency }) => (
  <div
    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
  >
    <h5 style={{ marginBottom: -3, fontSize: 11, fontWeight: 900 }}>{name}</h5>
    <h4 style={{ marginBottom: -3, fontSize: 11, fontWeight: 900 }}>
      {currency}
      {price}
    </h4>
    <Barcode
      value={sku}
      height={38}
      fontSize={10}
      width={0.9}
      marginTop={-7}
      marginLeft={26}
      format="CODE39"
    />
  </div>
);

class ProductsBarCode extends React.Component {
  render() {
    const { products, user } = this.props;
    const currency = user
      ? user.currency
        ? user.currency
        : user.employer.currency
        ? user.employer.currency
        : ""
      : "";

    const barCodes = Array.isArray(products) ? (
      products.map((product, key) =>
        product.sku ? <Tag key={key} {...product} currency={currency} /> : null
      )
    ) : products.sku ? (
      <Tag {...products} currency={currency} />
    ) : null;

    return <div id="products-barcode">{barCodes}</div>;
  }
}

ProductsBarCode.defaultProps = {
  products: [],
};

export default ProductsBarCode;
