import { GET_ALL_BRANCHES, SET_NEW_STAFF, SET_NEW_BRANCH, LOADING_ALL_BRANCHES } from "../constants";

const initialState = {
  allBranches: [],
  newStaff: null,
  newBranch: null,
  loadingAllBranches: false
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_BRANCHES:
      return {
        ...state,
        allBranches: payload,
        loadingAllBranches: false
    };
    case LOADING_ALL_BRANCHES:
      return {
        ...state,
        loadingAllBranches: payload
    };
    case SET_NEW_STAFF:
      return {
        ...state,
        newStaff: payload
      };
    case SET_NEW_BRANCH:
      return {
        ...state,
        newBranch: payload
      };
    default:
      return state;
  }
};
export default reducer;
