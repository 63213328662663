import {
  SET_SALE_TRANSACTION_INFO,
  SHOW_VARIANT_MODAL,
  CLEAR_SHOW_VARIANT_MODAL_DATA,
  HAS_RECORDED_SALE,
  GET_ALL_SALES,
  GET_SALES_DETAILS,
  SHOW_CUSTOMQTY_MODAL,
  CLEAR_SHOW_CUSTOMQTY_MODAL_DATA,
  SPLIT_PAYMENT_ACTIVE
} from "../constants";

const initialState = {
  salesDetails: {},
  hasRecordedSale: false,
  sale: null,
  splitPaymentActive: false
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SALE_TRANSACTION_INFO:
      return {
        ...state,
        ...payload,
      };

    case SHOW_CUSTOMQTY_MODAL:
      return {
        ...state,
        sale: {
          ...payload,
          showCustomQtyModal: true,
          type: SHOW_CUSTOMQTY_MODAL,
        },
      };
      case SHOW_VARIANT_MODAL:
      return {
        ...state,
        sale: {
          ...payload,
          showVariantModal: true,
          type: SHOW_VARIANT_MODAL,
        },
      };
    case GET_SALES_DETAILS:
      return {
        ...state,
        salesDetails: payload,
      };
    case CLEAR_SHOW_VARIANT_MODAL_DATA:
      return {
        ...state,
        sale: {},
      };
      case CLEAR_SHOW_CUSTOMQTY_MODAL_DATA:
        return {
          ...state,
          sale: {},
        };
    case HAS_RECORDED_SALE:
      return {
        ...state,
        hasRecordedSale: payload,
      };
    case SPLIT_PAYMENT_ACTIVE:
      return {
        ...state,
        splitPaymentActive: payload
      }

    default:
      return state;
  }
};
export default reducer;
