import React, { useState } from "react";
import { Link } from "react-router-dom";
import {  Input, Button } from "antd";
import { Icon, Form } from "@ant-design/compatible";
import "./index.scss";

// Form container style
import "../index.scss";
import strings from "../../../strings";

const FormItem = Form.Item;

const OTP = props => {
  // Passed from the Form.create HOC
  const { getFieldDecorator } = props.form;

  // State
  const [loading, setLoading] = useState(false);

  // TODO: Submit form
  const handleSubmit = e => {
    e.preventDefault();

    props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        setLoading(!loading);
      }
    });
  };

  return (
    <div className="otp auth">
      <h1 className="title">{strings.enterOtp}</h1>
      <div className="auth-form__container">
        <Form layout="vertical" onSubmit={handleSubmit}>
          {/* OTP Input */}
          <FormItem>
            {getFieldDecorator("otp", {
              rules: [
                {
                  message: strings.enterValidOtp,
                  required: true,
                },
              ],
            })(
              <Input
                size="large"
                type="number"
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={strings.pin}
              />
            )}
          </FormItem>
          {/* ======OTP */}

          <Button
            type="primary"
            size="large"
            block
            htmlType="submit"
            loading={loading}
          >
            Continue
          </Button>

          <div className="form-utils__container">
            <a href="https://loystar.co/signup/">{strings.createAcount}</a>
            <Link to="/signin">{strings.signIn}</Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

const OTPForm = Form.create()(OTP);

export default OTPForm;
