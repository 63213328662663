import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ChangeLanguage from "../../../components/ChangeLanguage";
import EnterEmailForm from "./_partials/email.jsx";
import {
  sendEmailToken,
  resetPassword,
} from "../../../redux/actions/authActions.js";

import { Modal } from "antd";

import "./index.scss";

// Form container style
import "../index.scss";
import EnterPasswordForm from "./_partials/password.jsx";
import strings from "../../../strings.js";

class ResetPassword extends React.Component {
  state = {
    showEmailForm: true,
    isEmailLoading: false,
    isPasswordLoading: false,
  };

  onEmailSubmit = (email) => {
    const { sendEmailToken } = this.props;

    this.setState({ isEmailLoading: true });

    sendEmailToken(email)
      .then((res) => {
        this.setState({ isEmailLoading: false });
        if (res && res.status === 200) {
          Modal.success({
            title: strings.tokenSentToMail,
            content: res.data.message,
            onOk: () => {
              this.setState({ showEmailForm: false });
            },
          });
        }
      })
      .catch((err) => this.setState({ isEmailLoading: false }));
  };

  onResetPassword = (payload) => {
    const { resetPassword, history } = this.props;

    this.setState({ isPasswordLoading: true });

    resetPassword(payload).then((res) => {
      this.setState({ isPasswordLoading: false });

      if (res && res.status === 200) {
        Modal.success({
          title: strings.passwordResetSuccessful,
          onOk: () => {
            history.push("/signin");
          },
        }).catch((err) => this.setState({ isPasswordLoading: false }));

        return;
      } else if (res) {
        Modal.error({
          title: strings.somethingWentWrong,
          content: res.data,
        });
      }
    });
  };

  render() {
    const { showEmailForm, isEmailLoading, isPasswordLoading } = this.state;
    return (
      <div>
        <ChangeLanguage />
        <div className="reset-password auth">
          <h1 className="title">{strings.resetPassword}</h1>
          <div className="auth-form__container">
            {showEmailForm ? (
              <EnterEmailForm
                sendEmailToken={this.onEmailSubmit}
                loading={isEmailLoading}
              />
            ) : (
              <EnterPasswordForm
                loading={isPasswordLoading}
                resetPassword={this.onResetPassword}
              />
            )}
            <div className="form-utils__container">
              <Link to="/reset-code">{strings.iHaveResetCode}</Link>
              <Link to="/signin">{strings.signIn}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { sendEmailToken, resetPassword })(
  withRouter(ResetPassword)
);
