import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Select,
  Modal,
  Checkbox,
  Input,
  Radio,
  Switch,
} from "antd";
import { Form } from "@ant-design/compatible";
import RadioGroup from "antd/lib/radio/group";
const { Option } = Select;

const ViewFormDetails = ({ currentForm, visible, onCancel, form }) => {
  const FormItem = Form.Item;
  const { getFieldDecorator } = form;

  const renderInputType = field => {
    const options = field.field_options ? field.field_options.split(", ") : [];
    switch (field.field_input_type) {
      case "select":
        return (
          <Select size="large" placeholder={`Select ${field.field_name}`}>
            {options.map(option => (
              <Option value={option}>{option}</Option>
            ))}
          </Select>
        );

      case "radio":
        return (
          <RadioGroup size="large">
            {options.map(option => (
              <Radio value={option}>{option}</Radio>
            ))}
          </RadioGroup>
        );

      case "checklist":
        return (
          <Checkbox.Group>
            {options.map(option => (
              <Checkbox value={option}>{option}</Checkbox>
            ))}
          </Checkbox.Group>
        );

      case "switch":
        return <Switch defaultChecked={false} size="large" />;

      default:
        return (
          <Input
            type={field.field_input_type}
            size="large"
            placeholder={`Enter ${field.field_name}`}
          />
        );
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        closable={false}
        title="Form Preview"
        footer={[
          <Button type="ghost" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={onCancel}
          >
            Ok
          </Button>,
        ]}
      >
        <h3>{currentForm?.form_name}</h3>

        {currentForm?.form_fields.map(field => (
          <FormItem label={field.field_name} key={field.field_name}>
            {getFieldDecorator(field.field_name, {
              rules: [
                {
                  required: field.required_field,
                },
              ],
            })(renderInputType(field))}
            <p>{field.notes}</p>
          </FormItem>
        ))}
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Form.create()(ViewFormDetails));
