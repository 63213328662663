import { notification as AppNotification } from "antd";
import api from "../api";

function notifyMerchant(msg, actionType) {
  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");

    if (actionType === "error") {
      AppNotification.error({
        message:
          "An error occurred while syncing your offline sales. Some sales may not be synced!",
        placement: "bottomRight",
      });
    } else {
      AppNotification.success({
        message:
          "You are back online! All your offline sales have been synced!",
        placement: "bottomRight",
      });
    }
  }

  // Let's check whether notification permissions have alredy been granted
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    var notification = new Notification("Offline Sales Synced", {
      icon: "/loystar-favicon-png.png",
      body: msg,
    });
  }

  // Otherwise, we need to ask the user for permission
  else if (
    Notification.permission !== "denied" ||
    Notification.permission === "default"
  ) {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        var notification = new Notification("Offline Sales Synced", {
          icon: "/loystar-favicon-png.png",
          body: msg,
        });
      } else {
        if (actionType === "error") {
          AppNotification.error({
            message:
              "An error occurred while syncing your offline sales. Some sales may not be synced!",
            placement: "bottomRight",
          });
        } else {
          AppNotification.success({
            message:
              "You are back online! All your offline sales have been synced!",
            placement: "bottomRight",
          });
        }
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them any more.
  else {
    if (actionType === "error") {
      AppNotification.error({
        message:
          "An error occurred while syncing your offline sales. Some sales may not be synced!",
        placement: "bottomRight",
      });
    } else {
      AppNotification.success({
        message:
          "You are back online! All your offline sales have been synced!",
        placement: "bottomRight",
      });
    }
  }
}

const recordOfflineSale = async (
  transactionInfo,
  cartItems,
  addLoyaltyProgram,
  shared_loyalty_txn,
  payment_reference,
  created_at,
) => {
  const {
    isPayPaidWithCash,
    isPayPaidWithCard,
    isPayPaidWithMobile,
    isPayPaidWithMTransfer,
    isPayPaidWithPoint,
    businessBranchId,
    card_payment_ref,
    userId,
    has_discount,
    discount_amount,
    mtier_amount,
    loyalty_id,
  } = transactionInfo;

  //access the loyalty programs
  const loyaltyPrograms = JSON.parse(localStorage.getItem("loyaltyPrograms"));

  const activeInstantRewardLoyalty = JSON.parse(
    localStorage.getItem("activeInstantRewardLoyalty")
  );

  //check if a usr is attached to the transaction
  const addLoyaltyProgramToTransaction =
    userId && addLoyaltyProgram ? true : false;

  //filter out the loyalty program with the merchant_loyalty_program_id
  if (addLoyaltyProgramToTransaction) {
    cartItems.forEach(item => {
      let loyaltyInfo = loyaltyPrograms.filter(
        loyaltyProgram => item.merchant_loyalty_program_id === loyaltyProgram.id
      );
      item.program_type =
        loyaltyInfo.length > 0 ? loyaltyInfo[0].program_type : null;

      if (activeInstantRewardLoyalty) {
        item.program_type = activeInstantRewardLoyalty.program_type;
        item.merchant_loyalty_program_id = activeInstantRewardLoyalty.id;
      }
    });
  }
 const reference_code = new Date(Date.now()).getTime()
  const payload = {
    sale: {
      is_paid_with_cash: isPayPaidWithCash,
      is_paid_with_card: isPayPaidWithCard,
      is_paid_with_mobile: isPayPaidWithMobile,
      is_paid_with_mtransfer: isPayPaidWithMTransfer,
      is_paid_with_point: isPayPaidWithPoint,
      user_id: userId,
      business_branch_id: businessBranchId,
      transactions: cartItems,
      payment_reference,
      card_payment_ref,
      has_discount,
      shared_loyalty_txn,
      discount_amount,
      mtier_amount,
      loyalty_id,
      created_at,
      reference_code
    },
  };

  return await api.sales.recordSale(payload);
};

const deleteOfflineSale = (sale_id) => {
  const offlineSales = JSON.parse(localStorage.getItem("offlineSales"));

  const result =
    offlineSales &&
    offlineSales.filter(({ formattedData }) => formattedData.id !== sale_id);

  localStorage.setItem("offlineSales", JSON.stringify(result));
};

export { notifyMerchant, recordOfflineSale, deleteOfflineSale };
