import React from "react";
import { Icon, Input, Button } from "antd";
import { Form } from "@ant-design/compatible";
import strings from "../../../../strings";

const FormItem = Form.Item;

const Email = ({ form, sendEmailToken, loading }) => {
  const { getFieldDecorator } = form;

  // TODO: Submit form
  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        sendEmailToken(values.email);
      }
    });
  };

  return (
    <div>
      <Form layout="vertical" onSubmit={handleSubmit}>
        {/* Email Input */}
        <div>
          <p>{strings.aTokenWillBeSentToEmail}</p>
          <h4>{strings.enterEmail}: </h4>
        </div>
        <FormItem>
          {getFieldDecorator("email", {
            rules: [
              {
                message: strings.pleaseEnterEmail,
                required: true,
              },
            ],
          })(
            <Input
              size="large"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={strings.email}
            />
          )}
        </FormItem>
        {/* ======Email */}

        <Button
          type="primary"
          size="large"
          block
          htmlType="submit"
          loading={loading}
        >
          {!loading ? strings.continue : strings.sendinYourToken}
        </Button>
      </Form>
    </div>
  );
};
const EnterEmail = Form.create()(Email);

export default EnterEmail;
