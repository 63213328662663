import React, { Fragment } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import strings from "../../../../../../strings";

const ViewDisburseMentDetail = ({
  currentDisbursement,
  isViewDetialsModal,
  closeModal,
  user,
}) => {
  const {
    amount,
    sale,
    status,
    merchant_details: { bank_name, bank_account_name, bank_account_number },
    transfer_completed_on,
    transfer_initiated_on,
    transfer_id,
  } = currentDisbursement;

  return (
    <Modal
      title={strings.disbursementDetails}
      visible={isViewDetialsModal}
      destroyOnCbank_namelose
      onCancel={closeModal}
      footer={[
        <Button key="ok" type="primary" onClick={() => closeModal()}>
          Ok
        </Button>,
      ]}
    >
      <div>
        <p>
          <strong>{strings.amount}</strong>: {user.currency}
          {amount}
        </p>
        <p>
          <strong>{strings.paidBy}</strong>: {sale.customer.name}
        </p>
        <p>
          <strong>{strings.disbursed}</strong>: {status ? "Yes" : "No"}
        </p>

        {status ? (
          <Fragment>
            <p>
              <strong>{strings.bankName}</strong>: {bank_name || "-"}
            </p>
            <p>
              <strong>{strings.bankAccountName}</strong>:{" "}
              {bank_account_name || "-"}
            </p>
            <p>
              <strong>{strings.bankAccountNumber}</strong>:{" "}
              {bank_account_number || "-"}
            </p>
            <p>
              <strong>{strings.transferInitiated}</strong>:{" "}
              {new Date(transfer_initiated_on).toLocaleString() || "-"}
            </p>
            <p>
              <strong>{strings.transferCompleted}</strong>:{" "}
              {new Date(transfer_completed_on).toLocaleString() || "-"}
            </p>
          </Fragment>
        ) : null}
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(ViewDisburseMentDetail);
