import api from "../../../../api";

export const loginToVpay = async () =>
  await api.HttpClient.post("/merchants/vpay_login");

export const addCluster = async () =>
  await api.HttpClient.post("/merchants/add_cluster");

export const addTill = async id =>
  await api.HttpClient.post(`/merchants/add_till/${id}`);

export const addCashierTill = async id =>
await api.HttpClient.put(`/merchants/add_till_cashier/${id}`);  

export const getCashierTill = async (id) =>
await api.HttpClient.get(`/merchants/${id}/cashier_till`);  

export const getCluster = async () =>
  await api.HttpClient.get("/merchants/get_cluster");

export const getTill = async id =>
  await api.HttpClient.get(`/merchants/get_till/${id}`);
