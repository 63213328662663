import React, { useEffect, useState } from 'react';
import { Card, Modal, Input, Button, Spin, message } from 'antd'
import {  deleteReferralProgramParticipant, getReferralProgramParticipant } from '../../../../../redux/actions/referralProgramAction';
import CustomDataTable from '../../../../../components/CustomDataTable';
import strings from '../../../../../strings';
import {
    DeleteOutlined 
  } from "@ant-design/icons";

const ReferralProgramParticipant = ({ open, onCancel, referralProgram, user }) => {
    const [ loading, setLoading ] = useState(false);
    const [ selectCust, setSelectedCust ] = useState(null);
    const [ programParticipant, setProgramParticipant ] = useState([]);

    useEffect(() => {
        getAllParticipant(referralProgram.event_code, user);
    }, [referralProgram]);

    const getAllParticipant = (event_code) => {
        setLoading(true);
        getReferralProgramParticipant(event_code, user)
        .then((res) => {
            setProgramParticipant(res.data);
            setLoading(false);
        })
        .catch((err) => {
            if(err?.response?.data?.message === "No participants found!") {
              message.info(err?.response?.data?.message || "No participants found!");
              return setLoading(false);
            }
            Modal.error({
                title: `${err?.response?.data?.message || "Unable to get participants"}, try again`,
                onOk: () => setLoading(false)
            })
        })
    }

    const deleteParticipant = (payload) => {
        setSelectedCust(payload.phone_no)
        deleteReferralProgramParticipant(payload, referralProgram.event_code, user)
        .then((res) => {
            console.log("res",res);
            Modal.success({
                title: "Participant deleted successfully",
                onOk: () => {
                    setSelectedCust(null);
                    getAllParticipant(referralProgram.event_code);
                }
            })
        })
        .catch((err) => {
            Modal.error({
                title: `${err?.response?.data?.message || "Unable to delete participant"}, try again`,
            })
        })
    }

    const column = [
        {
          title: strings.name,
          dataIndex: "name",
          render: record => (record ? <span>{record}</span> : <span>N/A</span>),
          onFilter: (value, record) => {
            return String(record.paymentType).toLowerCase().includes(value.toLowerCase());
          },
          filterDropdown: (...props) => {
            const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
              props[0];
            return (
              <section className="filter-container">
                <Input 
                  onChange={(e) => setSelectedKeys([e.target.value])}
                  value={selectedKeys[0]}
                  // style={{ margin: "0 10px" }}
                  placeholder="Search referral program name"
                  onPressEnter={() =>
                    confirm()
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <footer
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTop: "solid 1px #E6E6E6",
                  }}
                >
                  <Button
                    type="link"
                    onClick={() => {
                      confirm();
                    }}
                  >
                    Ok
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      clearFilters();
                    }}
                  >
                    {strings.reset}
                  </Button>
                </footer>
              </section>
            );
          },
          onFilter: (value, record) => {
            if (!record.name) return;
            return record.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          },
          filterMultiple: false,
        },
        {
          title: "Phone number",
          dataIndex: "phone_no",
          render: record => (record ? <span>{record}</span> : <span>N/A</span>)
        },
        {
            title: "Number of Referrals",
            dataIndex: "no_of_referrals",
            // render: record => (record ? <span>{record}</span> : <span>N/A</span>)
        },
        {
            title: "Actions",
            // dataIndex: "no_of_referrals",
            render: (record, value) => {
                console.log("df", record, value);
                return (selectCust === value.phone_no ? <Spin /> : <DeleteOutlined  onClick={() => deleteParticipant(value)}/>)
            }
        },
      ]

  return (
    <Modal
        title={`${referralProgram && referralProgram.name} participants (${programParticipant.length})`}
        open={open}
        onCancel={onCancel}
        cancelText={null}
    >
        <CustomDataTable 
            columns={column}
            dataSource={programParticipant}
            loading={loading}
        />
        
    </Modal>
  )
}

export default ReferralProgramParticipant