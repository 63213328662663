import React from "react";
import { Modal, Button, Input, Spin, message } from "antd";
import { Form } from "@ant-design/compatible";
import PhoneInput from "react-phone-input-2";
import QRCode from "react-qr-code";
import Pusher from "pusher-js";
import api from "../../../../api";

// Redux
import { connect } from "react-redux";
import {
  addNewCustomer,
  getAllCustomers,
} from "../../../../redux/actions/customerActions";
import {
  saveInvoice,
  getPaymentMessage,
} from "../../../../redux/actions/invoiceActions";
import { sendNotification } from "../../../../redux/actions/notificationAction";
import { toggleRenewalModal } from "../../../../redux/actions/renewalActions";
import {
  computeLoyaltyPoints,
  computeLoyaltyStamps,
} from "../../../../containers/Sales/functions";

import bankcodes from "../../../../banks.json";
import cloneArray from "../../../../utils/cloneArray";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import SalesReceipt from "../SalesReceipt";
import strings from "../../../../strings";
import moment from "moment";

const FormItem = Form.Item;

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;

const milisecondsToDaysConst = 86400000;

class BankTransferModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeft: null,
      invoiceId: "",
      isLoading: false,
      isCreateInvoiceLoading: false,
      country: "",
      selectedBank: {
        name: "",
        code: "",
        image_url: "",
        isLoading: false,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const {
      customer,
      selectedCustomer,
      customerValue,
      cartList,
      allLoyaltyPrograms,
      paymentType,
      isTransferModalOpen,
      hasDiscount,
      discountAmount,
      clearDiscount,
    } = this.props;

    const totalAmountToBePaid =
      hasDiscount && discountAmount
        ? this.cartTotalAmount() - discountAmount
        : this.cartTotalAmount();

    if (
      isTransferModalOpen !== prevProps.isTransferModalOpen &&
      selectedCustomer &&
      paymentType === "bank_transfer"
    ) {
      const parsedCartList = cloneArray(cartList);

      const loyaltyPrograms = JSON.parse(
        localStorage.getItem("loyaltyPrograms")
      );

      parsedCartList.forEach((cartItem) => {
        let loyaltyInfo = loyaltyPrograms.filter(
          (loyaltyProgram) =>
            cartItem.merchant_loyalty_program_id === loyaltyProgram.id
        );
        cartItem.program_type = loyaltyInfo.length
          ? loyaltyInfo[0].program_type
          : null;
        cartItem.points = computeLoyaltyPoints.apply(this, [
          cartItem,
          allLoyaltyPrograms,
        ]);
        cartItem.stamps = computeLoyaltyStamps.apply(this, [
          cartItem,
          allLoyaltyPrograms,
        ]);
        cartItem.user_id = customerValue;
      });

      if (customer.bank_ussd_code) {
        const bank = bankcodes.find(
          (bank) => bank.short === customer.bank_ussd_code
        );

        // console.log("BANK", bank);

        this.setState({
          selectedBank: {
            name: bank.name,
            code: bank.code,
            image_url: bank.image_url,
            isLoading: true,
          },
        });

        this.props
          .saveInvoice({
            user_id: customerValue,
            customer_id: customer.id,
            due_amount: this.cartTotalAmount(),
            // business_branch_id: "",
            transactions: parsedCartList,
            payment_method: "ussd",
            status: "unpaid",
            payment_message: `${strings.dialTheUSSDCode}: *${bank.code}*000*380*`,
            due_date: new Date(),
            send_notification: false,
            has_discount: hasDiscount,
            discount_amount: discountAmount,
          })
          .then(async ({ data }) => {
            // console.log(data);

            // Clears Discount Data
            hasDiscount && clearDiscount();

            await this.props.sendNotification({
              data: {
                customer_id: this.props.customer.id,
                invoice_id: data.id,
              },
            });

            const { invoice_id } = data;

            this.setState({
              invoiceId: invoice_id,
              isLoading: true,
              selectedBank: {
                ...this.state.selectedBank,
                isLoading: false,
              },
            });
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    }
  }

  componentDidMount() {
    const { location } = this.props;

    if (location && location.state && location.state.fromDraftSales) {
      this.setState({ location: location });
    }

    const {
      user,
      toggleBankTransferModal,
      toggleTransactionModal,
      clearCart,
      clearSelectedCustomer,
      setPaymentType,
      toggleRenewalModal,
    } = this.props;

    // If Merchant Account's Expired, Show Renewal Modal
    const typesObj = {
      ngBasic: "basic-pay",
      ngPro: "pro-pay",
      ghBasic: "basic-pay-gh",
      ghPro: "pro-pay-gh",
      intBasic: "basic-pay-int",
      intPro: "pro-pay-int",
    };

    const business = user.employer ? { ...user.employer } : { ...user };

    const authUrl = `${BASE_URL}/pusher/auth`;

    const { client } = api.HttpClient.defaults.headers.common;

    // Initialize Pusher Instance Only When Payout is Active
    if (user.payout_active || user.employer.payout_active) {
      // Pusher.log = (e) => console.log(e);

      const pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
        authEndpoint: authUrl,
        auth: {
          params: {
            uid: user.employer ? user.employer.uid : user.uid,
            access_token:
              api.HttpClient.defaults.headers.common["access-token"],
            client,
          },
        },
      });

      const channel = pusher.subscribe(
        `private-ussd_payment_${user.employer ? user.employer.id : user.id}`
      );

      let self = this;

      channel.bind("new_payment_made", function (data) {
        // console.log("Pusher Data", data);

        const {
          status,
          ref_code,
          invoice: { status: invoiceStatus, paid_at, invoice_id, amount },
          customer: { first_name, last_name, phone_number, email },
        } = data;

        if (status === "success" && invoiceStatus === "paid") {
          const { location } = self.state;

          // console.log(location);

          // Delete Bill if Bill is in Draft Sales
          if (location && location.state && location.state.fromDraftSales) {
            const draftSalesList = JSON.parse(
              localStorage.getItem("draftSales")
            );

            const filteredDraftSales =
              draftSalesList.length &&
              draftSalesList.filter(
                (bill) => bill.id !== location.state.bill_id
              );

            localStorage.setItem(
              "draftSales",
              JSON.stringify(filteredDraftSales)
            );

            // console.log("Draft Sales Has been deleted");

            this.setState({ location: null });
          }

          const transactionDate = new Date();

          self.props.getAllCustomers().then((res) => {
            const customerList =
              res && res.data.filter((customer) => customer.deleted === false);

            const customer =
              self.props.selectedCustomer &&
              customerList.find(
                (item) => item.user_id === self.props.customerValue
              );

            // Show Success Message only When BankTransferMOdal is Open
            self.props.visible &&
              Modal.success({
                title: `Payment ${
                  amount ? `of ${user.currency}${amount}` : ""
                } Received Successfully from ${first_name ? first_name : ""} ${
                  last_name ? last_name : ""
                }`,
                onOk: () => {
                  clearSelectedCustomer();
                  clearCart();
                  setPaymentType("");
                },
                content: (
                  <div style={{ display: "flex" }}>
                    <ReactToPrint
                      trigger={() => (
                        <Button type="primary">Print Receipt</Button>
                      )}
                      content={() => self.componentRef}
                    />
                    <div style={{ display: "none" }}>
                      <SalesReceipt
                        currency={user.currency || user.employer.currency}
                        saleTotal={self.cartTotalAmount()}
                        paidWith={"bank transfer"}
                        transaction={self.props.cartList}
                        saleId={ref_code}
                        purchaseTime={moment(transactionDate || Date.now()).format("DD-MM-YYYY hh:mm a")}
                        customer={customer}
                        user={self.props.user}
                        ref={(el) => (self.componentRef = el)}
                        channel={self.state.selectedBank.code}
                        payment_reference={""}
                      />
                    </div>
                  </div>
                ),
              });

            self.setState((prevState) => ({
              ...prevState,
              isLoading: false,
              selectedBank: {
                name: "",
                code: "",
                isLoading: false,
              },
            }));

            // Close Current Modal only when its open
            toggleBankTransferModal(false);

            // Close Record Sale Modal only when its open
            self.props.isRecordSalePaymentVisible && toggleTransactionModal();

            let secondsToGo = 60;

            if (business.subscription_plan === "Free") {
              const country =
                business.currency === "NGN"
                  ? "ng"
                  : business.currency === "GHS"
                  ? "gh"
                  : "int";

              let subscription_plan = "Basic";

              const subPaymentType = typesObj[country + subscription_plan];

              const daysLeft = Math.ceil(
                (new Date(business.subscription_expires_on) - Date.now()) /
                  milisecondsToDaysConst
              );

              const modal =
                daysLeft <= 0 &&
                Modal.success({
                  title: `${strings.holdOn} ${secondsToGo}`,
                  content: <span>{strings.yourSubscriptionHasExpired}</span>,
                  okText: strings.renew,
                  onOk: () => toggleRenewalModal(),
                });

              const timer =
                daysLeft <= 0 &&
                setInterval(() => {
                  secondsToGo--;
                  modal.update({
                    title: `${strings.holdOn} ${secondsToGo}`,
                  });
                }, 1000);

              daysLeft <= 0 &&
                setTimeout(() => {
                  clearInterval(timer);
                  modal.destroy();
                }, secondsToGo * 1000);
            } else {
              const country =
                business.currency === "NGN"
                  ? "ng"
                  : business.currency === "GHS"
                  ? "gh"
                  : "int";

              const subPaymentType =
                typesObj[country + business.subscription_plan];

              const daysLeft = Math.ceil(
                (new Date(business.subscription_expires_on) - Date.now()) /
                  milisecondsToDaysConst
              );

              const modal =
                daysLeft <= 0 &&
                Modal.success({
                  title: `${strings.holdOn} ${secondsToGo}`,
                  content: <span>{strings.yourSubscriptionHasExpired}</span>,
                  okText: strings.renew,
                  onOk: () => toggleRenewalModal(),
                });

              const timer =
                daysLeft <= 0 &&
                setInterval(() => {
                  secondsToGo--;
                  modal.update({
                    title: `${strings.holdOn} ${secondsToGo}`,
                  });
                }, 1000);

              daysLeft <= 0 &&
                setTimeout(() => {
                  clearInterval(timer);
                  modal.destroy();
                }, secondsToGo * 1000);
            }
          });
        }
      });
    }
  }

  cartTotalAmount = () => {
    const { isTwoPaymentMethod, getCartTotalAmount, mtier_amount } = this.props;

    const cartTotalAmount = isTwoPaymentMethod
      ? getCartTotalAmount() - mtier_amount
      : getCartTotalAmount();
    return cartTotalAmount;
  };

  handleAddCustomer = (bank) => {
    const {
      form: { validateFields, resetFields },
      selectedCustomer,
      customer,
      location,
      cartList,
      customerValue,
      allLoyaltyPrograms,
      hasDiscount,
      hasInstantRewardDiscount,
      instantRewardDiscount,
      discountAmount,
      clearDiscount,
    } = this.props;

    const parsedCartList = cloneArray(cartList);

    const loyaltyPrograms = JSON.parse(localStorage.getItem("loyaltyPrograms"));

    parsedCartList.forEach((cartItem) => {
      let loyaltyInfo = loyaltyPrograms.filter(
        (loyaltyProgram) =>
          cartItem.merchant_loyalty_program_id === loyaltyProgram.id
      );
      cartItem.program_type = loyaltyInfo.length
        ? loyaltyInfo[0].program_type
        : null;
      cartItem.points = computeLoyaltyPoints.apply(this, [
        cartItem,
        allLoyaltyPrograms,
      ]);
      cartItem.stamps = computeLoyaltyStamps.apply(this, [
        cartItem,
        allLoyaltyPrograms,
      ]);
      cartItem.user_id = customerValue;
    });

    if (
      selectedCustomer ||
      (location && location.state && location.state.selectedCustomer)
    ) {
      if (bank && (bank.name || bank.code)) {
        this.setState({
          selectedBank: {
            name: bank.name,
            code: bank.code,
            image_url: bank.image_url,
            isLoading: true,
          },
        });

        const invoicePayload = {
          user_id: customerValue,
          customer_id: customer.id,
          due_amount: this.cartTotalAmount(),
          // business_branch_id: "",
          transactions: parsedCartList,
          payment_method: "ussd",
          status: "unpaid",
          payment_message: `${strings.dialTheUSSDCode}: * ${bank.code} * 000 * 380 *`,
          due_date: new Date(),
          send_notification: false,
          has_discount: hasDiscount,
          discount_amount: discountAmount,
        };

        if (this.props.isTwoPaymentMethod) {
          invoicePayload.mtier_amount = this.props.mtier_amount;
          invoicePayload.loyalty_id = this.props.loyalty_id;
        }

        this.props
          .saveInvoice(invoicePayload)
          .then(async ({ data }) => {
            // console.log(data);
            // Clears Discount Data
            hasDiscount && clearDiscount();

            await this.props.sendNotification({
              data: {
                customer_id: this.props.customer.id,
                invoice_id: data.id,
              },
            });

            const { invoice_id } = data;

            this.setState({
              invoiceId: invoice_id,
              isLoading: true,
              selectedBank: {
                ...this.state.selectedBank,
                isLoading: false,
              },
            });
          })
          .catch((err) => {
            // console.log(err);
          });
      } else {
        message.error(strings.pleaseSelectABank, 5);
      }
    } else {
      validateFields((err, values) => {
        if (err) {
          return;
        }

        // if (values.phone_number.length !== 14) {
        //   message.error("Specify your 14 digits phone number please!");
        //   return;
        // }

        const phone_number =
          values.phone_number[0] == "+"
            ? values.phone_number
            : `+${values.phone_number}`;

        this.setState({
          selectedBank: {
            name: bank.name,
            code: bank.code,
            image_url: bank.image_url,
            isLoading: true,
          },
        });

        this.props
          .addNewCustomer({
            first_name: values.name ? values.name.split(" ")[0] : phone_number,
            last_name: values.name ? values.name.split(" ")[1] : "",
            phone_number: phone_number,
            sex: "-",
            bank_ussd_code: bank.code,
          })
          .then((res) => {
            // console.log(res.data);
            const { id: customer_id, user_id } = res.data;

            const invoicePayload = {
              user_id: user_id,
              customer_id,
              due_amount: this.cartTotalAmount(),
              transactions: parsedCartList,
              payment_method: "ussd",
              status: "unpaid",
              payment_message: `${strings.dialTheUSSDCode}: * ${bank.code} * 000 * 380 *`,
              due_date: new Date(),
              send_notification: false,
              has_discount: hasDiscount || hasInstantRewardDiscount,
              discount_amount: discountAmount || instantRewardDiscount,
            };

            if (this.props.isTwoPaymentMethod) {
              invoicePayload.mtier_amount = this.props.mtier_amount;
              invoicePayload.loyalty_id = this.props.loyalty_id;
            }

            this.props
              .saveInvoice(invoicePayload)
              .then(({ data }) => {
                // console.log(data);

                const { invoice_id } = data;

                this.setState({
                  invoiceId: invoice_id,
                  isLoading: true,
                  selectedBank: {
                    ...this.state.selectedBank,
                    isLoading: false,
                  },
                });
                resetFields();
              })
              .catch((err) => {
                // console.log(err);
              });
          })
          .catch((err) => {
            // console.log(err);
            this.setState({
              selectedBank: {
                name: "",
                code: "",
                image_url: "",
                isLoading: false,
              },
            });
          });
      });
    }
  };

  render() {
    const {
      visible: isBankTransferModalVisible,
      toggleBankTransferModal,
      form: { getFieldDecorator },
      selectedCustomer,
      location,
      customer,
      hasDiscount,
      discountAmount,
      hasInstantRewardDiscount,
      instantRewardDiscount,
      user,
    } = this.props;

    const { isLoading, invoiceId, selectedBank } = this.state;

    const totalAmountToBePaid = (() => {
      if (hasDiscount && discountAmount) {
        return this.cartTotalAmount() - discountAmount;
      } else if (hasInstantRewardDiscount && instantRewardDiscount) {
        return this.cartTotalAmount() - instantRewardDiscount;
      } else return this.cartTotalAmount();
    })();

    return (
      <Modal
        title={`${strings.payWithUSSD} ${
          selectedCustomer ? strings.sendBillTo + selectedCustomer : ""
        }`}
        open={isBankTransferModalVisible}
        width={600}
        onCancel={() => {
          Modal.confirm({
            title: strings.doYouWantToCancelTransaction,
            onOk: () => {
              toggleBankTransferModal(false);
              this.setState({
                isLoading: false,
                selectedBank: { name: "", code: "", isLoading: false },
              });
            },
            onCancel: () => {},
          });
        }}
        footer={null}
        destroyOnClose
      >
        <div id="pay_with_bank_transfer">
          {isLoading ? (
            <div style={{ textAlign: "center" }}>
              <p>
                {strings.waitingForUSSDTransfer} - {user.currency}
                {totalAmountToBePaid}
              </p>
              <Spin />
              <div style={{ margin: "15px 0" }}>
                <img
                  src={selectedBank.image_url}
                  alt={selectedBank.name}
                  style={{ width: "80px", height: "80px" }}
                />
              </div>

              <p>
                <QRCode
                  size={150}
                  value={`tel:*${selectedBank.code}*000*380${invoiceId}#`}
                />
              </p>

              <p style={{ marginTop: "10px" }}>
                Code to Dial: {`*${selectedBank.code}*000*380${invoiceId}#`}
              </p>
            </div>
          ) : // If Customer has no Bank Code
          customer && !customer.bank_ussd_code ? (
            <>
              <div>
                <h3>Select a Bank</h3>
              </div>
              <div className="banks">
                {bankcodes.map((bank, index) => {
                  return (
                    <Link
                      key={index}
                      to="#"
                      className="card-bank-item"
                      onClick={() => {
                        this.handleAddCustomer(bank);
                      }}
                      disabled={
                        selectedBank.name && selectedBank.name !== bank.name
                      }
                    >
                      <div style={{ marginBottom: "15px" }}>
                        <img
                          src={bank.image_url}
                          alt={bank.name}
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>

                      <Button
                        className="bank-item"
                        size="small"
                        onClick={() => {
                          this.handleAddCustomer(bank);
                        }}
                        loading={
                          selectedBank.name === bank.name
                            ? selectedBank.isLoading
                            : false
                        }
                      >
                        *{bank.code}#
                      </Button>
                    </Link>
                  );
                })}
              </div>
            </>
          ) : // If there's no Customer Selected
          !selectedCustomer ? (
            <>
              <Form layout="vertical">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormItem>
                    {getFieldDecorator("phone_number", {
                      rules: [
                        {
                          required: true,
                          message: strings.pleaseEnterCustomerPhoneNumber,
                        },
                      ],
                    })(
                      <PhoneInput
                        inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                        inputStyle={{
                          border: "1px solid #d9d9d9",
                          width: "100%",
                          height: "40px",
                        }}
                        placeholder={strings.phoneNumber}
                        country="ng"
                      />
                    )}
                  </FormItem>

                  <Form.Item className="wrap">
                    {getFieldDecorator("name")(
                      <Input placeholder="Name" size="large" />
                    )}
                  </Form.Item>
                </div>
              </Form>

              <div>
                <h3>{strings.pleaseSelectABank}</h3>
              </div>
              <div className="banks">
                {bankcodes.map((bank, index) => {
                  return (
                    <Link
                      key={index}
                      to="#"
                      className="card-bank-item"
                      onClick={() => {
                        this.handleAddCustomer(bank);
                      }}
                      disabled={
                        selectedBank.name && selectedBank.name !== bank.name
                      }
                    >
                      <div style={{ marginBottom: "15px" }}>
                        <img
                          src={bank.image_url}
                          alt={bank.name}
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>

                      <Button
                        className="bank-item"
                        size="small"
                        onClick={() => {
                          this.handleAddCustomer(bank);
                        }}
                        loading={
                          selectedBank.name === bank.name
                            ? selectedBank.isLoading
                            : false
                        }
                      >
                        *{bank.code}#
                      </Button>
                    </Link>
                  );
                })}
              </div>
            </>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Spin style={{ padding: "30px" }} />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const BankTransferForm = Form.create()(BankTransferModal);

const mapStateToProps = (state) => ({
  products: state.product.allProducts,
  invoices: state.invoice.allInvoices,
  paymentMessage: state.invoice.paymentMessage,
  error: state.error,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
});

export default connect(mapStateToProps, {
  addNewCustomer,
  getAllCustomers,
  saveInvoice,
  getPaymentMessage,
  sendNotification,
  toggleRenewalModal,
})(BankTransferForm);
