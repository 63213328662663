import React, { useState } from "react";
import {
  Card,
  Col,
  Row,
  Modal,
  message,
} from "antd";
import { PlusOutlined, UserAddOutlined, RedoOutlined } from "@ant-design/icons";
import {
  ENROLL_CUSTOMER_WITHOUT_POINTS,
  ENROLL_CUSTOMER_SUCCESS,
} from "../../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";
import { useMutation } from "@apollo/client";
import AddCustomerModal from "../../Dashboard/Layouts/Customers/_partials/AddCustomerModal";
import RedeemLoyaltyModal from "./RedeemLoyaltyModal";
import CreditLoyaltyModal from "./CreditLoyalty";

const LoyaltyMode = ({ user, activeLoyalty, customers }) => {
  const [addCustomer, setAddCustomer] = useState(false);
  const [creditLoyaltyModal, setCreditLoyaltyModal] = useState(false);
  const [redeemLoyaltyModal, setRedeemLoyaltyModal] = useState(false);

  const [enrollCustomerWithoutPoints] = useMutation(
    ENROLL_CUSTOMER_WITHOUT_POINTS,
    {
      onCompleted: ({ enrollCustomerWithoutPoints }) => {
        enrollCustomerWithoutPoints &&
          enrollCustomerWithoutPoints.length &&
          enrollCustomerWithoutPoints[0].id &&
          Modal.success({
            title: ENROLL_CUSTOMER_SUCCESS,
          });
      },
      onError: (error) => {
        message.error(error.message, 5);
      },
    }
  );

  const addCustomerToLoyalty = ({
    customer_id,
    first_name,
    last_name,
    email,
  }) => {
    enrollCustomerWithoutPoints({
      variables: {
        loyalty_id: activeLoyalty.id,
        customerListId: [
          {
            customer_id,
            first_name,
            last_name,
            email,
          },
        ],
      },
    });
  };

  return (
    <>
      {addCustomer && (
        <AddCustomerModal
          showModal={addCustomer}
          closeModal={() => setAddCustomer(false)}
          addCustomerToLoyalty={addCustomerToLoyalty}
        />
      )}
      {creditLoyaltyModal && (
        <CreditLoyaltyModal
          visible={creditLoyaltyModal}
          onCancel={() => setCreditLoyaltyModal(false)}
          customers={customers}
          activeLoyalty={activeLoyalty}
        />
      )}
      {redeemLoyaltyModal && (
        <RedeemLoyaltyModal
          visible={redeemLoyaltyModal}
          onCancel={() => setRedeemLoyaltyModal(false)}
          customers={customers}
          user={user}
          activeLoyalty={activeLoyalty}
        />
      )}
      <Row style={{ width: "100%" }} justify="center" gutter={[10, 10]}>
        <Col sm={24} md={12} lg={8}>
          <Card
            hoverable={true}
            style={{
              height: 250,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            onClick={() => setAddCustomer(true)}
          >
            <p style={{ textAlign: "center", fontWeight: 600, fontSize: 30 }}>
              <UserAddOutlined />
            </p>
            <p>
              <b>Add/Enroll Customers</b>
            </p>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <Card
            hoverable={true}
            style={{
              height: 250,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            onClick={() => setCreditLoyaltyModal(true)}
          >
            <p style={{ textAlign: "center", fontWeight: 600, fontSize: 30 }}>
              <PlusOutlined />
            </p>
            <p>
              <b>Credit Loyalty Points</b>
            </p>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <Card
            hoverable={true}
            style={{
              height: 250,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            onClick={() => setRedeemLoyaltyModal(true)}
          >
            <p style={{ textAlign: "center", fontWeight: 600, fontSize: 30 }}>
              <RedoOutlined />
            </p>
            <p>
              <b>Redeem Loyalty Points</b>
            </p>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LoyaltyMode;
