import React, { useState } from "react";
import { Modal, Button, Input, message } from "antd";
import { Form } from "@ant-design/compatible";
import strings from "../../../strings";
import api from "../../../api";

const VerifyTokenModal = ({
  visible,
  closeModal,
  form,
  isRequired,
  content,
  phoneNumber,
  onOk,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const handleVerifyOtp = async () => {
    if (!isRequired) {
      closeModal();
      return onOk();
    }
    const values = await form.validateFields();
    try {
      if (values.OTP) {
        setLoading(true);
        const res = await api.HttpClient.put("/verify_merchant_phone_number", {
          phone_number: phoneNumber,
          code: values.OTP,
        });

        setLoading(false);
        if (res.status === 200) {
          form.resetFields();
          onOk();
          closeModal();
        } else {
          form.resetFields();
          message.error("Invalid code, try again");
        }
      }
    } catch (error) {
      message.error(error.message);
      return setLoading(false);
    }
  };

  const { getFieldDecorator } = form;

  return (
    <Modal
      title={strings.proceedWithPayment}
      open={visible}
      onCancel={() => {
        closeModal();
        onCancel();
      }}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {strings.cancel}
        </Button>,
        <Button type="primary" onClick={handleVerifyOtp} loading={loading}>
          Proceed to pay
        </Button>,
      ]}
    >
      {content}
      {isRequired && (
        <Form layout="vertical">
          <Form.Item label="Verify OTP to Redeem Points">
            {getFieldDecorator("OTP", {
              rules: [
                {
                  required: isRequired,
                  message: "Please type in the OTP sent to your phone",
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default Form.create()(VerifyTokenModal);
