import React from "react";
import { connect } from "react-redux";
import { Button, Modal, Spin } from "antd";
import CategoryItem from "./CategoryItem";

import { getAllCategories } from "../../../../../../redux/actions/categoryActions";

import "./index.scss";
import strings from "../../../../../../strings";

class ViewCategoryModal extends React.Component {
  state = { loading: false };

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = () => {
    this.setState({ loading: true });
    this.props
      .getAllCategories()
      .then(res => this.setState({ loading: false }))
      .catch(err => this.setState({ loading: false }));
  };

  render() {
    const {
      isViewCategoryVisible,
      closeModal,
      allCategories,
      toggleEditCategoryModal,
      toggleDeleteCategoryModal,
      toggleAddCategoryModal,
      getAllCategories,
    } = this.props;

    const { loading } = this.state;

    return (
      <Modal
        title={`${strings.categoryList} - ${allCategories.length || 0} ${
          strings.categories
        }`}
        okText={strings.actions}
        cancelText={strings.cancel}
        visible={isViewCategoryVisible}
        destroyOnClose
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {strings.close}
          </Button>,
        ]}
      >
        <Button
          className="add-category-btn"
          onClick={() => toggleAddCategoryModal()}
        >
          {strings.addCategory}
        </Button>

        <div className="category-list">
          {loading ? (
            <div className="loader">
              <Spin />
            </div>
          ) : (
            allCategories.length &&
            allCategories.map((category, key) => {
              return (
                <CategoryItem
                  key={key}
                  category={category}
                  toggleEditCategoryModal={toggleEditCategoryModal}
                  toggleDeleteCategoryModal={toggleDeleteCategoryModal}
                  fetchCategories={this.fetchCategories}
                />
              );
            })
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  allCategories: state.category.allCategories,
});

export default connect(mapStateToProps, { getAllCategories })(
  ViewCategoryModal
);
