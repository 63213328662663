import React, { useEffect, useState } from 'react';
import { 
    Modal, 
    Button, 
    Input, 
    InputNumber, 
    Select, 
    Spin, 
    Upload, 
    message,
    Tabs,
    Card,
    Table
   } from 'antd';
import api from '../../../../../../api';
import { Icon, Form  } from "@ant-design/compatible";
import AddBundleForm from './addbundle';
import strings from '../../../../../../strings';
import EditBundleForm from './editbundle';
import { transformInToFormObject } from '../../../../../../utils/generateFormData';
import BundleDetails from './bundleDetails';


const FormItem = Form.Item, 
Option = Select.Option
const { TabPane } = Tabs;


const AddBundleModal = ({ open, onCancel, form, user }) => {

    const { getFieldDecorator, setFieldsValue } = form;
    const [loading, setLoading ] = useState(false);
    const [ bundleProducts, setBundleProducts ] = useState([]);
    const [ editVisible, setEditVisible ] = useState(false);
    const [ editGroup, setEditGroup ] = useState([]);
    const [ openAddModal, setOpenAddModal ] = useState(false);
    const [ bundleDetailsVisible, setBundleDetailsVisible ] = useState(false);
    const [ bundleDetail, setBundleDetail ] = useState([]);
    const [ deleteLoading, setDeleteLoading ] = useState(false);
    const [ showSure, setShowSure ] = useState(false);
    const [ bundleToDelete, setBundleToDelete ] = useState({});
    const [ errorMsg, setErrorMsg ] = useState("");
    const [ isErrorMsg, setIsErrorMsg ] = useState(false);
    const [ addloading, setAddloading ] = useState(false);
    const hint = {
        color: "orange",
        fontSize: "13px",
    };
    

    useEffect(()=>{
        getProductBundle();
    }, []);
    
    const getProductBundle = () => {
        setLoading(true);
        api.HttpClient.get(`/merchant/${user.id}/bundles`)
        .then((res)=>{
            setLoading(false);
            setBundleProducts(res.data);
        })
        .catch((err)=>{
            setLoading(false);
            Modal.error({
                title: `${err.message}`
            })
        })
    }

    const handleDeleteBundle = ( id ) => {
        setDeleteLoading(true);
        api.HttpClient.delete(`/products/bundle/${id}`)
        .then((res)=>{
            if(res.status === 200 || res.status === 204){
                setDeleteLoading(false);
                Modal.success({
                    title: `${bundleToDelete.name} deleted succesfully`,
                    onOk: () => {
                        setShowSure(false);
                        getProductBundle();
                    }
                });
            }else{
                setDeleteLoading(false);
                Modal.error({
                    title: `Unable to delete Bundle, try again`
                })
            }
        })
        .catch((err)=>{
            setDeleteLoading(false);
            Modal.error({
                title: `${err.message}`
            })
        })
    }

    const columns = [
        {
          title: "Bundle Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
        },
        // {
        //     title: "Product Sku",
        //     dataIndex: "barcode",
        //     key: "barcode",
        // },
        // {
        //     title: "Products",
        //     dataIndex: "products",
        //     key: "products",
        //     render: (value, data) => {
        //         return <ul>{value.map((dat)=>{
        //             return <li>{dat.name}</li>
        //         })}</ul>
        //     }
        // },
        // {
        //     title: "quantity",
        //     dataIndex: "quantity",
        //     key: "quantity",
        //     render: (value, data) => {
        //         return <ul>{value.map((dat)=>{
        //             return <li>{dat}</li>
        //         })}</ul>
        //     }
        // },
        {
          title: "Actions",
          dataIndex: "id",
          key: "id",
          render: (value, data) => (
            <>
              <Icon
                type="edit"
                title="edit group"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditVisible(true);
                  setEditGroup(data);
                }}
              />{" "}
              <Icon
                title="delete group"
                style={{ marginLeft: "10px" }}
                onClick={(e) => {
                    e.stopPropagation();
                    setBundleToDelete(data);
                    setShowSure(true);
                }}
                type="delete"
              />{" "}
              {/* <Button
                type="link"
                onClick={() => {
                    setBundleDetail(data);
                    setBundleDetailsVisible(true);
                }}
              >
                Details
              </Button> */}
            </>
          ),
        },
      ];

   

  return (
    <>
    <Modal
        open={open}
        onCancel={onCancel}
        title={`Bundle Product`}
        onOk={onCancel}
        okButtonProps={{loading: addloading}}
        destroyOnClose
    >
        <Card
          size="small"
          headStyle={{ marginTop: loading ? "0" : "-20px" }}
          loading={loading}
          bordered={false}
          extra={[
            <Button type="primary" onClick={() => setOpenAddModal(true)}>
              Add New
            </Button>,
          ]}
        >
          {bundleProducts.length === 0 && (
            <p>
              <em>You don't have any product bundle</em>
            </p>
          )}
          {bundleProducts.length > 0 && (
            <Table 
              dataSource={bundleProducts} 
              columns={columns} 
              loading={loading}
              onRow={(data)=>({ 
                onClick: () => {
                  setBundleDetail(data);
                  setBundleDetailsVisible(true);
                  return false;
                }
              })}
            />
          )}
        </Card>
    </Modal>
    <Modal 
        open={showSure}
        onCancel={()=>setShowSure(false)}
        onOk={()=>handleDeleteBundle(bundleToDelete.id)}
        okButtonProps={{ loading: deleteLoading }}
        title={`Delete ${bundleToDelete.name}`}
        destroyOnClose
    >
        {`Are you sure you want to delete ${bundleToDelete.name}?`}
    </Modal>
    <AddBundleForm 
        open={openAddModal}
        onCancel={()=>setOpenAddModal(false)}
        user={user}
        getProductBundle={getProductBundle}
    />
    <EditBundleForm 
        open={editVisible}
        onCancel={()=>setEditVisible(false)}
        editGroup={editGroup}
        user={user}
        getProductBundle={getProductBundle}
    />
    {bundleDetailsVisible && <BundleDetails 
      open={bundleDetailsVisible}
      onCancel={()=>setBundleDetailsVisible(false)}
      bundleProduct={bundleDetail && bundleDetail}
    />}
    </>
  )
}

const AddBundleModalForm = Form.create()(AddBundleModal)
export default AddBundleModalForm;