import React, { useEffect, useState } from "react";
import { Modal, Select, Button, InputNumber } from "antd";
import { Form } from "@ant-design/compatible";
import { connect } from "react-redux";
import {
  addProductSupplies,
  getSuppliers,
} from "../../../../../../redux/actions/productActions";
import { getAllBusinessBranches } from "../../../../../../redux/actions/branchActions";
import strings from "../../../../../../strings";

const FormItem = Form.Item;
const Option = Select.Option;

const AddProductSuppliesForm = ({
  visible,
  closeModal,
  form,
  currentProduct,
  suppliers,
  allBranches,
  addProductSupplies,
  getAllBusinessBranches,
  getSuppliers,
  products,
  user,
  updateProducts
}) => {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { getFieldDecorator } = form;

  const isAdminIsManager =
    user.role_id === 1 || (user.role && user.role.id === 2);

  useEffect(() => {
    getSuppliers();
    getAllBusinessBranches();
  }, [getAllBusinessBranches, getSuppliers]);

  useEffect(() => {
    currentProduct &&
      form.setFieldsValue({
        product_id: currentProduct.name,
      });

    allBranches.length < 2 &&
      form.setFieldsValue({
        business_branch_id: allBranches[0].name,
      });
  }, [currentProduct, getSuppliers, allBranches]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      setIsLoading(true);

      const {
        supplier_id,
        quantity,
        product_id,
        variant_id,
        unit_price,
        selling_price,
        business_branch_id,
      } = values;

      const payload = {
        supplier_id,
        created_at: new Date(),
        products: [
          {
            business_branch_id:
              allBranches.length < 2 ? allBranches[0].id : business_branch_id,
            quantity,
            unit_price,
            selling_price,
            product_id: currentProduct ? currentProduct.id : product_id,
            variant_id,
          },
        ],
      };

      const res = await addProductSupplies(payload);

      if (res && res.status === 200) {
        setIsLoading(false);
        updateProducts();

        Modal.success({
          title: strings.productSuppliesAddedSuccessfully,
          onOk: () => {
            closeModal();
          },
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSelectChange = value => {
    const currProduct = products.find(product => product.id === value);

    currProduct && setSelectedProduct(currProduct);
  };

  return (
    <Modal
      title={strings.addProductSupplies}
      open={visible}
      closeModal={closeModal}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          {strings.close}
        </Button>,
        <Button
          key="submit"
          loading={isLoading}
          type="primary"
          onClick={() => handleSubmit()}
        >
          {strings.submit}
        </Button>,
      ]}
      destroyOnClose
    >
      <Form layout="vertical">
        <FormItem label={strings.supplier}>
          {getFieldDecorator("supplier_id", {
            rules: [
              {
                required: true,
                message: strings.pleaseSelectSupplier,
              },
            ],
          })(
            <Select
              size="large"
              showSearch
              filterOption={(input, option) => {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {suppliers.map(supplier => (
                <Option key={supplier.id} value={supplier.id}>
                  {supplier.user.first_name}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>

        <FormItem label={strings.nameOfProduct}>
          {getFieldDecorator("product_id", {
            rules: [
              {
                required: true,
                message: strings.pleaseSelectProduct,
              },
            ],
          })(
            <Select
              size="large"
              onChange={handleSelectChange}
              showSearch
              filterOption={(input, option) => {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {currentProduct ? (
                <Option value={currentProduct.id}>{currentProduct.name}</Option>
              ) : (
                products.map(product => (
                  <Option key={product.id} value={product.id}>
                    {product.name}
                  </Option>
                ))
              )}
            </Select>
          )}
        </FormItem>

        {currentProduct &&
        currentProduct.variants &&
        currentProduct.variants.length ? (
          <FormItem label={strings.productVariant}>
            {getFieldDecorator("variant_id", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseSelectAVariant,
                },
              ],
            })(
              <Select size="large">
                {currentProduct.variants.map(variant => (
                  <Option value={variant.id}>
                    {variant.value} - {variant.type} - {variant.price}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        ) : selectedProduct &&
          selectedProduct.variants &&
          selectedProduct.variants.length ? (
          <FormItem label={strings.productVariant}>
            {getFieldDecorator("variant_id", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseSelectAVariant,
                },
              ],
            })(
              <Select>
                {selectedProduct.variants.map(variant => (
                  <Option value={variant.id}>
                    {variant.value} - {variant.type} - {variant.price}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        ) : null}

        <FormItem label={strings.quantity}>
          {getFieldDecorator("quantity", {
            rules: [
              {
                required: true,
                message: strings.pleaseEnterVariantQuantity,
              },
            ],
          })(<InputNumber size="large" style={{ width: "100%" }} />)}
        </FormItem>

        {isAdminIsManager && (
          <FormItem
            label={`${strings.costPrice} ${
              user.currency ? user.currency : ""
            } (${strings.costPrice})`}
          >
            {getFieldDecorator("unit_price", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterUnitPrice,
                },
              ],
            })(
              <InputNumber
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\bNGN\s?|(,*)/g, "")}
                size="large"
                style={{ width: "100%" }}
              />
            )}
          </FormItem>
        )}

        <FormItem
          label={`${strings.sellingPrice} ${
            user.currency ? user.currency : ""
          }`}
        >
          {getFieldDecorator("selling_price", {
            rules: [
              {
                required: true,
                message: strings.pleaseEnterProductSellingPrice,
              },
            ],
          })(
            <InputNumber
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={value => value.replace(/\bNGN\s?|(,*)/g, "")}
              size="large"
              style={{ width: "100%" }}
            />
          )}
        </FormItem>

        <FormItem label={strings.businessBranch}>
          {getFieldDecorator("business_branch_id", {
            rules: [
              {
                required: true,
                message: strings.pleaseSelectBranch,
              },
            ],
          })(
            <Select
              size="large"
              showSearch
              filterOption={(input, option) => {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {allBranches.map(branch => (
                <Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  allBranches: state.branch.allBranches,
  suppliers: state.product.suppliers,
  products: state.product.allProducts,
});

const AddProductSuppliesModal = Form.create()(AddProductSuppliesForm);

export default connect(mapStateToProps, {
  getSuppliers,
  addProductSupplies,
  getAllBusinessBranches,
})(AddProductSuppliesModal);