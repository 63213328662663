import React, { useState, useEffect } from "react";
import { Modal, Input, Button } from "antd";
import { Form } from "@ant-design/compatible";

import { sendSmsToCustomer } from "../../../../../redux/actions/customerActions";
import { connect } from "react-redux";
import strings from "../../../../../strings";
import { WhatsAppOutlined, MessageOutlined } from "@ant-design/icons"


const { TextArea } = Input;

const ShortSMSModal = ({
    form,
    customer,
    sendSmsToCustomer,
    closeModal,
    isBroadcast,
    totalCustomers,
    thankYouMessage,
    showModal,
    smsBalance
}) => {
    const [ loading, setLoading ] = useState(false);
    const [ textCount, setTextCount ] = useState(0);
    const [ characterLimit, setCharacterLimit ] = useState(160);
    const [ totalSmsCount, setTotalSMSCount ] = useState(0);
    const { getFieldDecorator } = form;
    const [ message, setMessage ] = useState("");

    useEffect(()=>{
        const chars = 0;

        const totalSmsCount = Math.ceil(chars / characterLimit);
        const textRemaining =
        chars > characterLimit
            ? totalSmsCount * characterLimit - chars
            : characterLimit - chars;

        setTotalSMSCount(totalSmsCount);
        setTextCount(textRemaining)

        if (thankYouMessage) {
            form.setFieldsValue({
                message_text: thankYouMessage,
            });

            const chars = thankYouMessage.length;

            // Calculate text remaining in a message
            // const textRemaining =
            //   totalSmsCount * characterLimit -
            //   (chars % (totalSmsCount * characterLimit) ||
            //     totalSmsCount * characterLimit);

            const totalSmsCount = Math.ceil(chars / characterLimit);
            const textRemaining =
            chars > characterLimit
            ? totalSmsCount * characterLimit - chars
            : characterLimit - chars;

            setTotalSMSCount(totalSmsCount);
            setTextCount(textRemaining);
        }
    }, []);
    
    const onChange = e => {
        const chars = e.target.value.length;
    
        // Calculate text remaining in a message
        // const textRemaining =
        //   totalSmsCount * characterLimit -
        //   (chars % (totalSmsCount * characterLimit) ||
        //     totalSmsCount * characterLimit);
    
        const totalSmsCount = Math.ceil(chars / characterLimit);
        const textRemaining =
          chars > characterLimit
            ? totalSmsCount * characterLimit - chars
            : characterLimit - chars;
    
            setTotalSMSCount(totalSmsCount);
            setTextCount(textRemaining);
            setMessage(e.target.value);
    };

    const handleSendSmsMessageToCustomer = () => {
        setLoading(true);
    
        form.validateFields((err, values) => {
          if (err) {
            setLoading(false);
            return;
          }
    
          const isExceeded =
            Number(totalSmsCount) * Number(totalCustomers) > smsBalance;
    
            const payload = {
              phone_number: customer.phone_number,
              message_text: values.message_text,
            };
    
            if (smsBalance < 1) {
                setLoading(false);
    
              Modal.warn({
                title: strings.smsUnitsLow,
                content: strings.pleaseTopUpSmsUnits,
              });
    
              return;
            } else {
              sendSmsToCustomer(payload)
                .then(res => {
                  if (res.status && res.status === 200) {
                    Modal.success({
                      title: strings.smsSuccessfullySentForDelivery,
                    });
    
                    setLoading(false);
                    closeModal();
                  }
                })
                .catch(() => {
                    setLoading(false);
                });
            }
          
        });
    };
    
    
    return (
        <Modal
          title={`Send a message to ${customer.first_name ||
            strings.allCustomers} - ${smsBalance} ${strings.unitsAvailable}`}
          okText={strings.sendSms}
          // cancelText={strings.cancel}
          open={showModal}
          destroyOnClose
          onCancel={closeModal}
          okButtonProps={{ loading }}
          footer={[
            <div>
                {/* <Button
                    onClick={closeModal}
                >
                    Cancel
                </Button> */}
                <Button
                    onClick={handleSendSmsMessageToCustomer}
                    type="primary"
                    loading={loading}
                >
                    <MessageOutlined /> {strings.sendSms}
                </Button>
                <Button
                    type="primary"

                >
                    <a  
                      href= {`https://wa.me/${customer.phone_number}?text=${message}`}
                      data-action="share/whatsapp/share"
                      target="_blank" 
                      rel="noreferrer"
                    ><WhatsAppOutlined /> Send via whatsApp</a>
                </Button>
            </div>
          ]}
        >
          <Form layout="vertical" >
            <Form.Item label={strings.message}>
              {getFieldDecorator("message_text", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseTypeInTheMessage,
                  },
                ],
              })(<TextArea rows={4} onChange={onChange} />)}
            </Form.Item>
          </Form>
          <p>
            <span>
              {totalSmsCount} {strings.message}(s) / {textCount} characters remaining
            </span>
          </p>
  
          <p className="mt-20">
            <strong>{strings.note}: </strong>{strings.avoidUseOfSpecialCharacters}
          </p>
        </Modal>
      );
}


const ShortSMSModalForm = Form.create()(ShortSMSModal);

export default connect(null, { sendSmsToCustomer })(
  ShortSMSModalForm
);
