import React, { useState } from "react";
import { Tabs } from "antd";
import OfflineSales from "../OfflineSales";
import DraftSales from "../DraftSales";
import strings from "../../../../strings";

const { TabPane } = Tabs;

const Offline_Draft = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  return (
    <section className="offline_draft">
      <Tabs
        activeKey={activeTab}
        onChange={(activeKey) => setActiveTab(activeKey)}
      >
        <TabPane tab={strings.draftSales} key="1">
          <DraftSales {...props} />
        </TabPane>
        <TabPane tab={strings.offlineSales} key="2">
          <OfflineSales {...props} />
        </TabPane>
      </Tabs>
    </section>
  );
};

export default Offline_Draft;
