import Rollbar from 'rollbar';

const rollbar = new Rollbar({
  accessToken: process.env.ROLLABAR_ACCESSTOKEN,
    environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,   
    captureUncaught: true,
    captureUnhandledRejections: true,
});

export default rollbar;
