import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Modal,
  Form,
  Input,
  message,
  InputNumber,
  Spin,
  notification,
  Select,
} from "antd";
import { EyeTwoTone, EyeInvisibleTwoTone } from "@ant-design/icons";
import api from "../../../../api";
import socketIOClient from "socket.io-client";
import QRCode from "react-qr-code";

const BeepBeepAccount = ({ user }) => {
  const [token, setToken] = useState(localStorage.getItem("beepbeeptoken"));
  const [category, setCategory] = useState(
    localStorage.getItem("beepbeepcategory")
  );
  const [categories, setCategories] = useState([]);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const [createAccountLoading, setCreateAccountLoading] = useState(false);
  const [showLoginAccountModal, setShowLoginAccountModal] = useState(false);
  const [topupAmount, setTopupAmount] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [wallet, setWallet] = useState({});
  const [showTopupModal, setShowTopupModal] = useState(false);
  const [getWalletBalanceLoading, setGetWalletBalanceLoading] = useState(false);

  const [form] = Form.useForm();

  const {
    first_name,
    last_name,
    email,
    contact_number,
    business_name,
    business_type,
  } = user;

  const savedUser = localStorage.getItem("beepbeepuserdetails");
  const beepbeepUserDetails = savedUser ? JSON.parse(savedUser) : null;
  const accountNumber = process.env.REACT_APP_BEEPBEEP_VFD_VIRTUAL_ACCOUNT;
  const headers = {
    Authorization: "Bearer " + token,
  };
  useEffect(() => {
    beepbeepUserDetails &&
      getUserDetails(
        beepbeepUserDetails._id,
        localStorage.getItem("beepbeeptoken")
      );
  }, []);

  useEffect(() => {
    !!topupAmount && handleVpaySocket();
  }, [topupAmount]);

  useEffect(() => {
    const getAllCategories = () =>
      api.HttpClient.get(
        `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/category`
      )
        .then((res) => {
          if (res.status === 200) {
            setCategories(res.data.data);
          }
        })
        .catch((err) => message.error(err.message));
    getAllCategories();
  }, []);

  const createNewWallet = (id, accessToken) => {
    api.HttpClient.post(
      `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/wallets`,
      {
        user: id,
        amountOut: 0,
        balance: 0,
      },
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    )
      .then((res) => {
        setLoginLoading(false);
        if (res.status === 201) {
          updateUserWallet(id, res.data._id, accessToken);
        }
      })
      .catch((err) => {
        setLoginLoading(false);
        message.error(err.message);
      });
  };

  const getUserDetails = (id, accessToken = token) => {
    setGetWalletBalanceLoading(true);
    api.HttpClient.get(
      `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/wallets?user=${id}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    )
      .then((res) => {
        setGetWalletBalanceLoading(false);
        if (res.status === 200) {
          setWallet(res.data.data[0]);
        }
      })
      .catch((err) => {
        setGetWalletBalanceLoading(false);
        message.error(err.message);
      });
  };

  const updateWalletBalance = (id, amount) => {
    api.HttpClient.patch(
      `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/wallets/${id}`,
      {
        balance: wallet.balance + amount,
      },
      { headers }
    )
      .then((res) => {
        setLoginLoading(false);
        if (res.status === 200) {
          Modal.success({
            title: "Wallet topup successful",
            onOk: () => {
              getUserDetails(beepbeepUserDetails._id, token);
              setTopupAmount(null);
              setShowTopupModal(false);
            },
          });
        }
      })
      .catch((err) => {
        setLoginLoading(false);
        message.error(err.message);
      });
  };

  const updateUserWallet = (userId, walletId, accessToken) => {
    api.HttpClient.patch(
      `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/users/${userId}`,
      {
        wallet: walletId,
      },
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    )
      .then((res) => {
        setLoginLoading(false);
        if (res.status === 200) {
          getUserDetails(userId, accessToken);
        }
      })
      .catch((err) => {
        setLoginLoading(false);
        message.error(err.message);
      });
  };

  const loginToBeepbeep = () => {
    setLoginLoading(true);
    form.validateFields().then((values) => {
      const payload = {
        email,
        password: values.password,
        strategy: "local",
      };
      api.HttpClient.post(
        `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/authentication`,
        payload
      )
        .then((res) => {
          setLoginLoading(false);
          if (res.status === 201) {
            const { accessToken, user } = res.data;
            localStorage.setItem("beepbeeptoken", accessToken);
            localStorage.setItem("beepbeepuserdetails", JSON.stringify(user));
            Modal.success({
              title: "Account login successful",
              onOk: () => {
                setToken(accessToken);
                setShowLoginAccountModal(false);
              },
            });
          }
        })
        .catch((err) => {
          setLoginLoading(false);
          message.error(err.message);
        });
    });
  };

  const handleCreateAccount = () =>
    form.validateFields().then((values) => {
      const payload = {
        ...values,
        firstName: first_name,
        lastName: last_name,
        email,
        phoneNumber: contact_number,
        businessName: business_name + " - Loystar",
        businessType: business_type,
        address: `${user.address_line1} ${user.address_line2}`,
        state: user.state,
        role: "5f1982f489493029ca2cc295",
      };
      setCreateAccountLoading(true);
      api.HttpClient.post(
        `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/users`,
        payload
      )
        .then((res) => {
          setCreateAccountLoading(false);
          if (res.status === 201) {
            api.HttpClient.post(
              `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/authentication`,
              {
                email,
                password: values.password,
                strategy: "local",
              }
            )
              .then((res) => {
                setLoginLoading(false);
                if (res.status === 201) {
                  const { accessToken, user } = res.data;
                  localStorage.setItem("beepbeeptoken", accessToken);
                  localStorage.setItem(
                    "beepbeepuserdetails",
                    JSON.stringify(user)
                  );
                  setToken(accessToken);
                  createNewWallet(user._id, accessToken);
                  setShowCreateAccountModal(false);
                  Modal.success({
                    title: "Account created successfully",
                    onOk: () => {
                      setShowLoginAccountModal(false);
                    },
                  });
                }
              })
              .catch((err) => {
                setLoginLoading(false);
                message.error(err.message);
              });
          }
        })
        .catch((err) => {
          setCreateAccountLoading(false);
          message.error(err.message);
        });
    });

  const handleTopup = () => {
    form.validateFields().then((val) => {
      setTopupAmount(val.amount);
    });
  };

  const handleVpaySocket = () => {
    const socket = socketIOClient("https://www.vpay.africa");
    socket.emit(
      "ACCOUNT_NUMBER",
      JSON.stringify({
        accountnumber: Number(accountNumber),
      })
    );
    socket.on("FUNDING_SUCCESSFUL", (res) => {
      const data = JSON.parse(res);
      console.log({ data });
      notification.success({
        message: `Payment ${
          data.amount
            ? `of ${user.currency || user.employer?.currency}${data.amount}`
            : ""
        } Received Successfully from ${data.originator_account_name}`,
        duration: 0,
        placement: "topRight",
      });
      if (data.amount) {
        updateWalletBalance(wallet._id, data.amount);
      }
    });
  };

  const handleSaveCategory = () => {
    form.validateFields().then((val) => {
      localStorage.setItem("beepbeepcategory", val.category);
      setCategory(val.category);
    });
  };

  return (
    <Card
      title="BeepBeep Account"
      extra={
        !token
          ? [
              <Button onClick={() => setShowCreateAccountModal(true)}>
                Create Account
              </Button>,
              <Button
                style={{ marginLeft: 20 }}
                onClick={() => setShowLoginAccountModal(true)}
              >
                Login
              </Button>,
            ]
          : [
              <Button onClick={() => setShowTopupModal(true)}>
                Topup Wallet
              </Button>,
            ]
      }
      loading={getWalletBalanceLoading}
    >
      {showCreateAccountModal && (
        <Modal
          title="Create Account"
          visible={showCreateAccountModal}
          onCancel={() => setShowCreateAccountModal(false)}
          footer={[
            <Button onClick={() => setShowCreateAccountModal(false)}>
              Cancel
            </Button>,
            <Button
              type="primary"
              loading={createAccountLoading}
              onClick={handleCreateAccount}
            >
              Create Account
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <p>Enter a new password to create your BeepBeep account</p>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Please enter password" }]}
            >
              <Input.Password
                iconRender={(visible) =>
                  !visible ? <EyeInvisibleTwoTone /> : <EyeTwoTone />
                }
                size="large"
                type="password"
              />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {showLoginAccountModal && (
        <Modal
          title="Create Account"
          visible={showLoginAccountModal}
          onCancel={() => setShowLoginAccountModal(false)}
          footer={[
            <Button onClick={() => setShowLoginAccountModal(false)}>
              Cancel
            </Button>,
            <Button
              type="primary"
              loading={loginLoading}
              onClick={loginToBeepbeep}
            >
              Login
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <p>Enter your password to login.</p>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Please enter password" }]}
            >
              <Input.Password
                iconRender={(visible) =>
                  !visible ? <EyeInvisibleTwoTone /> : <EyeTwoTone />
                }
                size="large"
                type="password"
              />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {showTopupModal && (
        <Modal
          title="Topup Wallet Balance"
          visible={showTopupModal}
          onCancel={() => setShowTopupModal(false)}
          footer={[
            <Button onClick={() => setShowTopupModal(false)}>Cancel</Button>,
            <Button type="primary" onClick={handleTopup}>
              Pay
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <p>Enter amount to topup.</p>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber size="large" />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {topupAmount && (
        <Modal
          title="Topup Wallet Balance"
          visible={topupAmount}
          onCancel={() => setTopupAmount(false)}
          footer={[
            <Button onClick={() => setTopupAmount(false)}>Cancel</Button>,
          ]}
        >
          <div id="pay_with_bank_transfer">
            <div style={{ textAlign: "center" }}>
              <p>
                Waiting for transfer to bank account - {accountNumber} - VFD MFB
              </p>
              <p>
                Amount: {user.currency || user.employer?.currency}
                {topupAmount}
              </p>
              <Spin />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>Scan QRCode to pay </p>
              <QRCode
                size={150}
                value={accountNumber || "vpay account number"}
              />
            </div>
          </div>
        </Modal>
      )}
      {token ? (
        <>
          <p style={{ marginLeft: 20 }}>
            You are logged in to BeepBeep as {beepbeepUserDetails.email}.
          </p>
          <div>
            <h3 style={{ marginLeft: 20 }}>
              <b>
                Wallet Balance: {user.currency}
                {wallet?.balance?.toFixed(2) || 0}
              </b>
            </h3>
          </div>
          <div style={{ margin: "30px 20px" }}>
            <Form form={form} layout="vertical">
              <Form.Item
                name="category"
                label="Product Category"
                rules={[{ required: true, message: "Please select category" }]}
                initialValue={category}
              >
                <Select
                  size="large"
                  placeholder="Select One"
                  style={{ width: 400 }}
                  disabled={!!category}
                >
                  {categories.map((category) => (
                    <Select.Option key={category._id} value={category._id}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
            {!category && (
              <Button type="primary" onClick={handleSaveCategory}>
                Save
              </Button>
            )}
            {category && (
              <Button type="primary" onClick={() => setCategory(null)}>
                Change
              </Button>
            )}
          </div>

        </>
      ) : (
        <p style={{ marginLeft: 20 }}>
          You are not logged in to Beepbeep. Create account or Login to
          continue.
        </p>
      )}
    </Card>
  );
};

export default BeepBeepAccount;
