import {
  CHANGE_MULTILEVEL_STATUS,
  CHANGE_BOOKING_SUBSCRIPTION,
} from "../constants";

export const changeMultiLevelStatus = value => dispatch => {
  dispatch({
    type: CHANGE_MULTILEVEL_STATUS,
    payload: value,
  });
};

export const changeBookingStatus = value => dispatch => {
  dispatch({
    type: CHANGE_BOOKING_SUBSCRIPTION,
    payload: value,
  });
};
