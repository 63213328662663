import React from "react";
import { connect } from "react-redux";
import { Button, message, Modal, Tooltip, Upload } from "antd";
import { Icon } from "@ant-design/compatible";
import { ExcelRenderer } from "react-excel-renderer";
import { importCustomers } from "../../../../../../redux/actions/customerActions";
import strings from "../../../../../../strings";

const { Dragger } = Upload;

const CSV_TEMPLATE_URL =
  "https://loystar.co/wp-content/uploads/2021/02/Customer_Import_Template.csv";


const GOOGLE_CSV_TEMPLATE_URL =
  "https://docs.google.com/spreadsheets/d/13onNBZhGQuNBmSJH3zv4XTBiHEgGdwWqlCKW8Wzn8Bs/edit#gid=1546638574";

class ImportCustomerModal extends React.Component {
  state = {
    fileList: [],
    isUploading: false,
    errors: [],
    strangeHeaders: [],
    rows: [],
  };

  handleUpload = () => {
    const { fileList } = this.state;
    const { user, importCustomers } = this.props;

    this.setState({ isUploading: true });

    const formData = new FormData();

    const data = { merchant_email: user.email, csv_file: fileList[0] };

    for (const key in data) {
      formData.append(key, data[key]);
    }

    importCustomers(formData)
      .then(res => {
        this.setState({ isUploading: false });

        if (res && res.status === 200) {
          this.setState({ fileList: [] });
          Modal.success({
            title: strings.yourFileQueuedForUpload,
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ isUploading: false });
        Modal.error({
          title: strings.thereWasAnErrorPerformingOperation,
        });
      });
  };

  render() {
    const {
      isCustomerImportModalVisible,
      toggleCustomerImportModal,
    } = this.props;

    const { isUploading, strangeHeaders, errors } = this.state;

    const props = {
      name: "csv_file",
      multiple: false,
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });

        this.setState({ errors: [] });
      },
      beforeUpload: async file => {
        // Clear Any Errors or Files if Presert
        this.setState({ fileList: [], errors: [], strangeHeaders: [] });

        // console.log(file);

        if (!file) {
          message.error(strings.pleaseSelectFile, 5);

          return;
        }

        // Check If File is Excel File
        const isCsv = new RegExp("(.*?).(csv)$");

        if (!isCsv.test(file.name)) {
          this.setState({
            errorMessage: strings.oopsOnlyCsvFilesAllowed,
          });
          message.error(strings.oopsOnlyCsvFilesAllowed, 5);

          return;
        }

        const isLt5M = file.size / 1024 / 1024 < 5;

        if (!isLt5M) {
          this.setState({
            errorMessage: strings.fileShouldBeSmallerThan5Mb,
          });
          message.error(strings.fileShouldBeSmallerThan5Mb, 5);

          return;
        }

        let errors = [];
        let strangeHeaders = []; // Headers Not Accepted in CSV

        const acceptedHeaders = [
          "first name",
          "last name",
          "email",
          "phone number",
          "date of birth",
          "postcode",
          "state",
          "gender",
          "country",
          "address line 1",
          "address line 2",
        ]; // Headers Accepted in CSV File

        try {
          const resp = await ExcelRenderer(file);

          const {
            rows: [incomingHeaders, ...rest],
          } = resp;

          // console.log(resp);

          incomingHeaders.forEach(val => {
            if (!acceptedHeaders.includes(val.toLowerCase().trim())) {
              strangeHeaders.push(val);
            }
          });

          // console.log(strangeHeaders);

          if (strangeHeaders.length === 0) {
            let customers = [];

            rest.forEach(item => {
              const [
                first_name,
                last_name,
                email,
                phone_number,
                date_of_birth,
                postcode,
                state,
                gender,
                country,
                address_line_1,
                address_line_2,
              ] = item;

              customers.push({
                first_name: first_name === undefined ? "" : first_name,
                last_name: last_name === undefined ? "" : last_name,
                email: email === undefined ? "" : email,
                phone_number:
                  phone_number === undefined
                    ? ""
                    : phone_number && phone_number[0] !== "+"
                    ? `+${phone_number}`
                    : phone_number,
                date_of_birth: date_of_birth === undefined ? "" : date_of_birth,
                postcode: postcode === undefined ? "" : postcode,
                state: state === undefined ? "" : state,
                gender: gender === undefined ? "-" : gender,
                country: country === undefined ? "" : country,
                address_line_1:
                  address_line_1 === undefined ? "" : address_line_1,
                address_line_2:
                  address_line_2 === undefined ? "" : address_line_2,
              });
            });

            // Validate Each Product for Simple Product
            customers.forEach((customer, index) => {
              let { first_name, phone_number, email } = customer;

              const currRow = index + 2;

              // Validate Main Fields
              if (!first_name) {
                errors.push(
                  `${strings.customerFirstNameIsRequired} ${currRow}`
                );
              }

              if (!phone_number && !email) {
                errors.push(
                  `${strings.customerPhoneNumberAndEmailIsRequired} ${currRow}`
                );
              }
            });

            // console.log(customers);
          }
        } catch (error) {
          console.log(error);
        }

        if (strangeHeaders.length > 0) {
          this.setState({ strangeHeaders });
          return;
        }

        if (errors.length > 0) {
          // console.log("Error Length", errors.length);
          this.setState({ errors });
          return;
        }

        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList: this.state.fileList,
    };

    return (
      <Modal
        title={strings.importCustomers}
        visible={isCustomerImportModalVisible}
        destroyOnClose
        onCancel={toggleCustomerImportModal}
        footer={[
          <Button
            key="upload-btn"
            onClick={this.handleUpload}
            type="primary"
            disabled={this.state.fileList.length === 0}
            loading={isUploading}
          >
            {strings.upload}
          </Button>,
        ]}
      >
        <div className="product-import-wrapper">
          <p className="tutorial-wrapper">
            <span></span>

            <Tooltip
              title={
                <span>
                  Click this icon to download the CSV file template.{" "}
                  <a href={GOOGLE_CSV_TEMPLATE_URL} rel="noopener" target="blank">
                    (Get the Google sheet version)
                  </a>
                </span>
              }
            >
              <a
                href={CSV_TEMPLATE_URL}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon type="download" style={{ fontSize: "30px" }} />
              </a>
            </Tooltip>
          </p>

          <Dragger {...props} customRequest={() => {}}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{strings.clickOrDragFileToUpload}</p>
          </Dragger>

          <div style={{ marginTop: "1.5rem", marginLeft: "0.5rem" }}>
            {errors.length > 0 &&
              errors.map((err, key) => (
                <span key={key} style={{ display: "block", color: "#f5222d" }}>
                  {err}
                </span>
              ))}

            {strangeHeaders.length > 0 && (
              <span style={{ display: "block", color: "#f5222d" }}>
                {strings.missingOrInvalidColumnHeader}
              </span>
            )}
          </div>

          {this.state.rows.length > 2 && this.state.fileList.length > 0 && (
            <p style={{ marginTop: "2rem" }}>
              {strings.youHaveImported} {this.state.rows.length - 1}{" "}
              {strings.products}
            </p>
          )}
        </div>
      </Modal>
    );
  }
}

export default connect(null, { importCustomers })(ImportCustomerModal);
