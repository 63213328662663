import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { Link, useLocation } from "react-router-dom";

import "../../index.scss";
import ViewDetailsModal from "../ViewDetailsModal";

const Payments = ({ apps }) => {
  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.isFromCheckout) {
      toggleViewDetailsModal();
    }
  }, [location.state]);

  useEffect(()=>{
    handleSelectApp();
  }, []);

  const handleSelectApp = () => {
    const split = location.search && location.search.split("?")[1].split("&")[1];
    if(split && split.length > 0){
      const selected = apps && apps.filter((app)=>app.app_name.toLowerCase().includes(split));
      selected.length > 0 && setCurrentIntegration({
        ...selected[0],
      });
      selected.length > 0 && toggleViewDetailsModal();
      // location.search = "";
    }
  }
  const toggleViewDetailsModal = () =>
    setIsViewDetailsModalOpen((open) => !open);

  return (
    <main>
      <Card bordered={false}>
        <section className="mt-20">
          <Row gutter={[16, 16]}>
            {apps.map((app) => (
              <Col key={app.id} xs={24} md={12} lg={8} xl={8}>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();

                    setCurrentIntegration({
                      ...app,
                    });
                    toggleViewDetailsModal();
                  }}
                >
                  <Card className="integration-card-item">
                    <div className="img-wrapper">
                      <img src={app.icon} alt={app.app_name} />
                    </div>

                    <div className="name">
                      <h4>{app.app_name}</h4>
                      <span>{app.company_name}</span>
                    </div>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </section>
      </Card>

      {isViewDetailsModalOpen ? (
        <ViewDetailsModal
          visible={isViewDetailsModalOpen}
          toggleViewDetailsModal={toggleViewDetailsModal}
          currentIntegration={currentIntegration}
        />
      ) : null}
    </main>
  );
};

export default Payments;
