import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Modal, message as mess } from "antd";

export default function CheckoutForm ({ 
    open, 
    onCancel, 
    handlePayment,
    updateBalanceLoading,
    fromStripeSubscription,
    resolveSubscription,
    resolveUnsuccessfulPayment 
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ isSuceeded, setIsSuceeded ] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if(paymentIntent.status === "succeeded"){
            setIsSuceeded(true);
            mess.success("Payment is successful");
            if(fromStripeSubscription){
              resolveSubscription();
            }else{
              handlePayment();
            }
        }else{
            setIsSuceeded(false);
            Modal.error({
                title: "Payment is unsuccessful, pls try again or contact admin",
                onOk: () => {
                  if(fromStripeSubscription){
                    resolveUnsuccessfulPayment();
                  }
                }
            })
        }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_SITE_URL}/d/account`,
      }
    });

    if (error && (error.type === "card_error" || error.type === "validation_error")) {
        message.error(error.message)
      setMessage(error.message);
    }
    setIsLoading(false);
  };

  return (
    <Modal 
        open={open}
        onCancel={onCancel}
        title="Make Payment"
        onOk={handleSubmit}
        footer={[
            <Button 
                className='bg-black rounded-xl text-white p-2 mt-6 mb-2' 
                disabled={isLoading || !stripe || !elements}
                type="primary"
                onClick={handleSubmit}
                key="submit"
                loading={isLoading || updateBalanceLoading}
            >
                Pay now
            </Button>
        ]}
    >
        <form>
            <p className="text-black mb-4">{!isSuceeded ? "Complete your payment here!" : "Payment completed!"}</p>
            {!isSuceeded && <PaymentElement />}
        </form>
    </Modal>
  );
}
