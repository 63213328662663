import React from "react";
import { Button, Input, Modal, message } from "antd";
import { useMutation } from "@apollo/client";
import { Form } from "@ant-design/compatible";
import {
  EDIT_LOYALTY_CUSTOMER,
  EDIT_CUSTOMER_SUCCESS,
  LIST_TIERED_CUSTOMERS,
} from "../../../Loyalties/constants";

import strings from "../../../../../../../../strings";

const EditMemberModal = ({ visible, closeModal, form, customer }) => {
  const { getFieldDecorator } = form;

  const [editLoyaltyCustomer, { loading: isEditCustomerLoading }] = useMutation(
    EDIT_LOYALTY_CUSTOMER,
    {
      onCompleted: data => {
        form.resetFields();

        data.editCustomer &&
          Modal.success({
            title: EDIT_CUSTOMER_SUCCESS,
            okText: "Ok",
            onOk: () => {
              closeModal();
            },
            okButtonProps: {
              id: "btn-component",
            },
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: LIST_TIERED_CUSTOMERS }],
    }
  );

  const onCreate = async values => {
    await editLoyaltyCustomer({
      variables: {
        id: customer.id,
        data: { ...values },
      },
    });
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      await onCreate(values);
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  return (
    <Modal
      title="Edit Loyalty Customer"
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {strings.cancel}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isEditCustomerLoading}
          id="btn-component"
          onClick={handleSubmit}
        >
          {strings.edit}
        </Button>,
      ]}
    >
      <Form layout="vertical" name="edit_customer" id="loyaltyForm">
        <Form.Item name="first_name" label={strings.firstName} className="wrap">
          {getFieldDecorator("first_name", {
            initialValue: customer.first_name,
          })(<Input size="large" />)}
        </Form.Item>
        <Form.Item name="last_name" label={strings.lastName} className="wrap">
          {getFieldDecorator("last_name", { initialValue: customer.last_name })(
            <Input size="large" />
          )}
        </Form.Item>
        <Form.Item
          name="email"
          type="email"
          label={strings.email}
          className="wrap"
        >
          {getFieldDecorator("email", {
            initialValue: customer.email,
            rules: [
              {
                required: false,
                message: strings.pleaseEnterCustomerEmail,
              },
            ],
          })(<Input size="large" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(EditMemberModal);
