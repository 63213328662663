export default class Notification {
  constructor(client) {
    this.client = client;
  }

  sendNotification(notificationData) {
    return this.client.post(
      "/ussd/send_due_payment_notification",
      notificationData
    );
  }
}
