import React, { useState, useEffect } from "react";
import { Modal, Button, Input, message, Radio, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  CREATE_TIER,
  CREATE_TIER_SUCCESS,
  GET_PARTNER,
  LIST_PARTNERS,
  LIST_LOYALTIES,
  LIST_TIERS,
} from "../../../../constants";
import strings from "../../../../../../../../../../strings";

const Option = Select.Option;

const CreateTierModal = ({
  visible,
  closeModal,
  tierList,
  loyalty_id,
  form,
}) => {
  const [rewardSponsor, setRewardSponsor] = useState("self");
  const [partnersId, setPartnersId] = useState("");
  const [rewards, setRewards] = useState([]);

  const { getFieldDecorator } = form;

  useEffect(() => {
    if (tierList.length) {
      form.setFieldsValue({
        min_spending_target:
          tierList.length &&
          tierList.length > 0 &&
          tierList[tierList.length - 1].max_spending_target,
      });
    }
  }, []);

  const { loading: partnersLoading, data: partnersData } = useQuery(
    LIST_PARTNERS,
    {
      onError(error) {
        message.error(error.message, 5);
      },
    }
  );

  const [getPartner, { loading: getPartnerLoading }] = useLazyQuery(
    GET_PARTNER,
    {
      onCompleted: data => {
        setRewards(data.getPartner.rewards);
      },
      onError: error => {
        message.error(error.message, 5);
      },
    }
  );

  const [createTier, { loading: createTierLoading }] = useMutation(
    CREATE_TIER,
    {
      onCompleted: () => {
        closeModal();
        form.resetFields();

        Modal.success({
          title: CREATE_TIER_SUCCESS,
          okButtonProps: {
            id: "btn-component",
          },
        });
      },
      onError: error => {
        // message.error(error.message, 5);
      },
      refetchQueries: [{ query: LIST_TIERS, variables: { loyalty_id } }, { query: LIST_LOYALTIES}],
    }
  );

  const onCreate = values => {
    createTier({
      variables: {
        name: values.name,
        min_spending_target: Number(values.min_spending_target),
        max_spending_target: Number(values.max_spending_target),
        is_reward_self: values.rewardSponsor === "self" ? true : false,
        reward: values.reward,
        instruction: values.instruction,
        loyalty_id: loyalty_id,
        partners_id: values.rewardPartner ? partnersId : null,
      },
    });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        onCreate(values);
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title={strings.createATier}
      className="create_tier_modal"
      visible={visible}
      onCancel={() => {
        Modal.confirm({
          title: strings.stopCreatingTierConfirmation,
          onOk: closeModal,
          onCancel: () => {},
        });
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            Modal.confirm({
              title: strings.stopCreatingTierConfirmation,
              onOk: closeModal,
              onCancel: () => {},
            });
          }}
        >
          {strings.cancel}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={createTierLoading}
          id="btn-component"
          onClick={() => handleSubmit()}
        >
          {strings.createTier}
        </Button>,
      ]}
    >
      <Form layout="vertical" name="create_tier" layout="vertical" id="tierForm">
        <Form.Item label={strings.nameOfTier} className="wrap">
          {getFieldDecorator("name", {
            rules: [{ required: true }],
          })(<Input />)}
        </Form.Item>

        <div className="spending_target">
          <Form.Item label={strings.minimumSpendingTarget} className="wrap">
            {getFieldDecorator("min_spending_target", {
              rules: [
                {
                  required: true,
                  message: strings.minimumSpendingTargetRequired,
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label={strings.maximumSpendingTarget} className="wrap">
            {getFieldDecorator("max_spending_target", {
              rules: [
                {
                  required: true,
                  message: strings.maximumSpendingTargetRequired,
                },
              ],
            })(<Input />)}
          </Form.Item>
        </div>

        <Form.Item name="rewardSponsor" label={strings.rewardSponsor}>
          {getFieldDecorator("rewardSponsor", {
            rules: [
              { required: true, message: strings.pleaseChooseARewardSponsor },
            ],
          })(
            <Radio.Group
              onChange={e => {
                setRewardSponsor(e.target.value);
              }}
            >
              <Radio value="self">{strings.self}</Radio>
              <Radio value="partner">{strings.partner}</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        {rewardSponsor === "partner" ? (
          <>
            <Form.Item name="rewardPartner" label={strings.rewardPartner}>
              {getFieldDecorator("rewardPartner", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseSelectRewardPartner,
                  },
                ],
              })(
                <Select
                  loading={partnersLoading}
                  placeholder={strings.pleaseSelectRewardPartner}
                  onChange={(_, option) => {
                    console.log(option);
                    setPartnersId(option.key);
                    getPartner({ variables: { id: option.key } });
                  }}
                >
                  {partnersData && partnersData.listPartners
                    ? partnersData.listPartners.map(partner => (
                        <Option key={partner.id} value={partner.company_name}>
                          {partner.company_name}
                        </Option>
                      ))
                    : null}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              label={strings.rewards}
              rules={[
                {
                  required: true,
                  message: strings.pleaseSelectAReward,
                },
              ]}
            >
              {getFieldDecorator("reward", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseSelectAReward,
                  },
                ],
              })(
                <Select
                  loading={getPartnerLoading}
                  placeholder={strings.pleaseSelectAReward}
                  onChange={option => {}}
                >
                  {rewards && rewards.length !== 0
                    ? rewards.map(reward => (
                        <Option key={reward.id} value={reward.name}>
                          {reward.name}
                        </Option>
                      ))
                    : null}
                </Select>
              )}
            </Form.Item>

            <Form.Item label={strings.instructionsOnRedeemingReward}>
              {getFieldDecorator("instruction", {
                rules: [
                  { required: true, message: strings.pleaseFillInThisField },
                ],
              })(<Input />)}
            </Form.Item>
          </>
        ) : null}

        {rewardSponsor === "self" ? (
          <Form.Item
            name="reward"
            label={strings.rewards}
            rules={[{ required: true }]}
          >
            {getFieldDecorator("reward", {
              rules: [{ required: true }],
            })(<Input />)}
          </Form.Item>
        ) : null}
      </Form>
    </Modal>
  );
};

export default Form.create()(CreateTierModal);
