import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, message, Breadcrumb, Modal } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import {
  LIST_TIERED_CUSTOMERS,
  LIST_TIERS,
  ENROLL_CUSTOMER_WITHOUT_POINTS,
  ENROLL_CUSTOMER_SUCCESS,
  LIST_UNENROLLED_MEMBERS,
} from "../../constants";
import CustomTable from "../../../../../../../../components/CustomDataTable";
import { Link } from "react-router-dom";
import AwardPointsModal from "./AwardPointsModal";
import strings from "../../../../../../../../strings";

const EnrollMembers = ({ match, user }) => {
  const [selectedRowKeys, setselectedRowkeys] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [isAwardPointsModalOpen, setIsAwardPointsModalOpen] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});

  const loyalty_id = match.params.id;

  const { loading: isCustomerLoading, data } = useQuery(
    LIST_UNENROLLED_MEMBERS,
    {
      onError: error => {
        message.error(error.message, 5);
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (data) {
      const customerData =
        data &&
        data.listUnenrolledMembers
          .filter(customer => customer)
          .map((customer, idx) => {
            const customerId = customer ? customer.customer_id : idx;
            setCustomerDetails(prevState => {
              return { ...prevState, [[customerId]]: customer };
            });
            return {
              ...customer,
              key: customer ? customer.customer_id : idx,
            };
          });

      setCustomerData(customerData);
    }
  }, [data]);

  const [
    enrollCustomerWithoutPoints,
    { loading: enrollCustomerLoading },
  ] = useMutation(ENROLL_CUSTOMER_WITHOUT_POINTS, {
    onCompleted: ({ enrollCustomerWithoutPoints }) => {
      enrollCustomerWithoutPoints &&
        enrollCustomerWithoutPoints.length &&
        enrollCustomerWithoutPoints[0].id &&
        Modal.success({
          title: ENROLL_CUSTOMER_SUCCESS,
          okButtonProps: {
            id: "btn-component",
          },
        });

      clearSelectedCustomers();
    },
    onError: error => {
      message.error(error.message, 5);
    },
    refetchQueries: [
      { query: LIST_UNENROLLED_MEMBERS },
      { query: LIST_TIERED_CUSTOMERS },
      { query: LIST_TIERS, variables: { loyalty_id } },
    ],
  });

  const columns = [
    {
      title: strings.firstName,
      dataIndex: "first_name",
      key: "first_name",
      render: data => data || "N/A",
    },
    {
      title: strings.lastName,
      dataIndex: "last_name",
      key: "last_name",
      render: data => data || "N/A",
    },
    {
      title: strings.email,
      dataIndex: "email",
      key: "email",
      render: data => data || "N/A",
    },
  ];

  const toggleAwardPointsModal = () => setIsAwardPointsModalOpen(open => !open);

  const rowSelection = {
    selectedRowKeys,
    onChange: selectedRowKeys => {
      setselectedRowkeys(selectedRowKeys);
    },
  };

  const clearSelectedCustomers = () => {
    setselectedRowkeys([]);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const customerListId = selectedRowKeys.map(id => ({
    customer_id: id,
    first_name: customerDetails[id].first_name,
    last_name: customerDetails[id].last_name,
    email: customerDetails[id].email,
  }));

  const loyaltyUrl = match.path.split("/:id")[0];

  return (
    <section className="loyalty-enroll-members">
      <Card
        title={strings.enrollMembersToLoyalty}
        extra={[
          <Button
            key={1}
            type="primary"
            id="btn-component"
            disabled={selectedRowKeys.length === 0}
            onClick={() => {
              toggleAwardPointsModal();
            }}
          >
            {strings.awardPoints}
          </Button>,

          <Button
            className="ml-1"
            loading={enrollCustomerLoading}
            key={2}
            type="primary"
            id="btn-component"
            disabled={selectedRowKeys.length === 0}
            onClick={() => {
              enrollCustomerWithoutPoints({
                variables: {
                  loyalty_id: loyalty_id,
                  customerListId,
                },
              });
            }}
          >
            {strings.enroll}
          </Button>,
        ]}
      >
        <div className="enroll_customer_bread">
          <Breadcrumb className="page_breadcrumb">
            <Breadcrumb.Item>{strings.home}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${loyaltyUrl}`}>{strings.loyalty}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="*">{strings.enrollCustomers}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <span style={{ marginLeft: 8 }}>
          {hasSelected
            ? `${strings.selected} ${selectedRowKeys.length} ${strings.customers}(s)`
            : ""}
        </span>

        <CustomTable
          loading={isCustomerLoading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={customerData}
          onRow={record => {
            return {
              onClick: () => {},
            };
          }}
        />

        <AwardPointsModal
          visible={isAwardPointsModalOpen}
          closeModal={toggleAwardPointsModal}
          customerListId={customerListId}
          clearSelectedCustomers={clearSelectedCustomers}
          match={match}
          user={user}
        />
      </Card>
    </section>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(EnrollMembers);
