import React from "react";
import strings from "../../../../strings";

export const getTableColumns = function() {
  return [
    {
      title: strings.name,
      dataIndex: "name",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      ...this.getColumnSearchProps("name"),
    },
    {
      title: strings.programType,
      dataIndex: "program_type",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      ...this.getColumnSearchProps("program_type"),
    },
    {
      ...this.getColumnSearchProps("reward"),
      title: strings.reward,
      dataIndex: "reward",
      render: (record, data) => {
        if (record.toLowerCase() === "discount") {
          return record + ` (${data.reward_value}%)`;
        } else return record;
      },
    },
    {
      ...this.getColumnSearchProps("threshold"),
      title: strings.threshold,
      dataIndex: "threshold",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
    },
    {
      title: strings.createdAt,
      dataIndex: "created_at",
    },
  ];
};
