import api from "../../api";

export const sendNotification = (requestPayload = {}) => async dispatch => {
  const res = await api.notification.sendNotification(requestPayload);

  if (res && res.status === 200) {
    const { message } = res.data;
    return message;
  }

  Promise.resolve(res);
};
