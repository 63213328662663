import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Button, Modal, message, Row, Col, Input } from "antd";
import { Form, Icon } from "@ant-design/compatible";
import { PlusOutlined } from "@ant-design/icons";
import AddCustomField from "./AddCustomField";
import api from "../../../../api";

const CustomFields = ({ form, user }) => {
  const [customFields, setCustomFields] = useState([]);
  const [addModalVisible, showAddModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editFields, setEditFields] = useState(false);
  const [value, setValue ] = useState("");
  const [ editLoading, setEditLoading ] = useState(false);

  useEffect(() => {
    getFields();
  }, []);

  // const addApp = () => {
  //   const payload = {
  //     app_name: "Bookings And Appointments",
  //     base_url: "https://enye-loystar-backend.herokuapp.com",
  //     category: "Marketing",
  //     company_name: "Loystar",
  //     cost: 10000,
  //     description:
  //       "Set your work hours, book appointments for your customers for your added services and more",
  //     icon:
  //       "https://loystar.co/wp-content/uploads/2021/02/loystar-logo-round.png",
  //     status: "inactive",
  //     website_link: "",
  //   };
  //   api.HttpClient.post("/apps", { data: payload })
  //     .then(res => {
  //       setLoading(false);
  //       if (res.status === 200) {
  //         showAddModalVisible(false);
  //         Modal.success({
  //           title: "Success",
  //           content: "You have added this app successfully",
  //         });
  //       }
  //     })
  //     .catch(err => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // };

  const handleShowEditField = () => {
    setEditFields(!editFields)
  }

  const handleUpdateFields = (field) => {
    if(value === "") return message.warn("Can't submit empty fields")
    const custom_data = [{
      name: field,
      value
    }]
    setEditLoading(true);
    api.HttpClient.put("/merchant/customer_custom_fields/65918/edit", { custom_data })
    .then((res)=>{
      if( res.status === 200){
        setEditLoading(false);
        Modal.success({
          title: "Success",
          content: "You have edited this field successfully",
        });
        setEditFields(false);
        getFields();
      }
    })
    .catch((err) => {
      setEditLoading(false);
      console.log(err);
    });
  }

  const getFields = () => {
    api.HttpClient.get("/merchants/get_custom_fields").then((res) => {
      if (res.status === 200) {
        setCustomFields(res.data.data);
      }
    });
  };

  const handleAddFormSubmit = (e) => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      setLoading(true);
      let payload = {
        data: {
          fields: [values.field_name],
        },
      };

      api.HttpClient.post("/merchants/add_custom_fields", payload)
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            showAddModalVisible(false);
            Modal.success({
              title: "Success",
              content: "You have added this field successfully",
            });
            getFields();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    });
  };

  const handleDeleteField = (field) => {
    Modal.confirm({
      title: "Delete Custom Data",
      content: "Do you want to delete this custom data?",
      onOk: async () => {
        try {
          const res = await api.HttpClient.delete(
            `/merchants/custom_fields/${field}`
          );
          if (res.status === 204) {
            Modal.success({
              title: "Delete successful",
              content: "You have deleted custom data successfully.",
            });
            getFields();
          }
        } catch (err) {
          message.error(err.message);
        }
      },
    });
  };

  const renewSubscription = (email) => {
    const data = {
      plan: "Pro",
      currency_code: "NGN",
      expires_on: "30/10/2022",
    };
    api.HttpClient.post(`/add_merchant_current_plan?email=${email}`, {
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          Modal.success({
            title: "Success",
            content: "You have renewed subscription successfully",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   renewSubscription("tina4especial@yahoo.com");
  // }, []);

  return (
    <>
      <AddCustomField
        visible={addModalVisible}
        onCancel={() => showAddModalVisible(false)}
        handleAddFormSubmit={handleAddFormSubmit}
        loading={loading}
        form={form}
      />
      <Card
        title="Custom Fields"
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginTop: "20px" }}
            onClick={() => showAddModalVisible(true)}
          >
            Add New Custom Field
          </Button>
        }
      >
        <div>
          <p>
            <strong>Fields Added</strong>
          </p>
          <ul>
            {customFields.length > 0 ? (
              customFields.map((field) => (
                <Row gutter={8}>
                  <Col span={10}>
                    <div>
                      {editFields ? 
                        <div 
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                        >
                          <Input 
                            type="text" 
                            placeholder={field}
                            value={value}
                            onChange={(e)=>setValue(e.target.value)}
                          />
                          <Button 
                            type="primary" 
                            onClick={()=>handleUpdateFields(field)}
                            loading={editLoading}
                          >
                            Update
                          </Button>
                        </div> : 
                          <li>{field.toLowerCase()}</li>}
                    </div>
                  </Col>
                  {/* <Col>
                    <Icon
                      title="edit field"
                      type="edit"
                      onClick={() => handleShowEditField()}
                    />
                  </Col>
                  <Col>
                    <Icon
                      title="delete field"
                      type="delete"
                      onClick={() => handleDeleteField(field)}
                    />
                  </Col> */}
                </Row>
              ))
            ) : (
              <em>You have no custom fields added yet.</em>
            )}
          </ul>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Form.create()(CustomFields));
