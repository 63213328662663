import React from "react";
import { useMutation } from "@apollo/client";
import {
  EDIT_GIFT_CARD,
  LIST_GIFT_CARDS,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";
import { Modal, Form, Button, Input, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { connect } from "react-redux";
import Axios from "axios";

const ResendCardRecipient = ({
  card,
  visible,
  onCancel,
  customer,
  imageUrl,
  user,
}) => {
  const [form] = Form.useForm();

  const {
    currency,
    address_line1,
    address_line2,
    state,
    country,
    contact_number,
    image: merchant_logo_url,
  } = user.employer ? user.employer : user;

  const [editCard, { loading: editCardLoading }] = useMutation(EDIT_GIFT_CARD, {
    onCompleted: (data) => {
      if (data.editGiftCard) {
        Modal.success({
          title: `You have successfully sent gift card - ${data.editGiftCard.card_number}`,
          onOk: onCancel,
        });
      }
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
    refetchQueries: [LIST_GIFT_CARDS],
  });

  const sendCardToRecipient = () => {
    form.validateFields().then(async (values) => {
      const payload = {
        id: card.id,
        sender_name: customer.first_name,
        currency,
        address_line1,
        address_line2,
        state,
        country,
        contact_number,
        merchant_logo_url,
        ...values,
      };

      if (imageUrl) {
        const formData = new FormData();
        const file = new File([imageUrl], "image.jpeg");
        formData.append("image", file);
        const res = await Axios.post(
          `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/upload`,
          formData
        );
        const url = res.data.imageCloudData.url;
        payload.gift_card_url = url;
      }
      return editCard({
        variables: {
          data: payload,
        },
      });
    });
  };

  return (
    <Modal
      title="Resend Gift Card To Recipient"
      visible={visible}
      onCancel={onCancel}
      footer={[<Button onClick={onCancel}>Cancel</Button>]}
    >
      <Form layout="vertical" form={form} style={{ marginTop: 20 }}>
        <Form.Item
          label="Recipient Name"
          name="recipient_name"
          rules={[
            { required: true, message: "Enter recipient name to continue" },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Recipient Email"
          name="recipient_email"
          extra="Card details will be sent to email"
          rules={[
            { required: true, message: "Enter recipient email to continue" },
          ]}
        >
          <Input size="large" type="email" />
        </Form.Item>
        <Form.Item
          label="Recipient Phone number"
          name="recipient_phone_number"
          rules={[
            {
              required: true,
              message: "Enter recipient phone number to continue",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Secret code"
          name="secret_code"
          extra="Secret code will be used to retrieve card if lost"
          rules={[
            { required: true, message: "Enter a secret code to continue" },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Message"
          name="sender_message"
          extra="Your message will be sent with the gift card"
          rules={[{ required: true, message: "Enter message to continue" }]}
        >
          <TextArea size="large" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={sendCardToRecipient}
            loading={editCardLoading}
          >
            Send Card
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps)(ResendCardRecipient);
