import { useMutation, useQuery } from "@apollo/client";
import { Modal, Button, Form, Select, Input, message, InputNumber } from "antd";
import React, { useState } from "react";
import {
  GET_CUSTOMER,
  REDEEM_CUSTOMER_POINTS,
} from "../../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";

const RedeemLoyaltyModal = ({
  visible,
  onCancel,
  user,
  customers,
  customer,
  activeLoyalty,
}) => {
  const [customerId, setCustomerId] = useState(null);
  const [form] = Form.useForm();

  const { data: getCustomer, loading: getCustomerLoading } = useQuery(
    GET_CUSTOMER,
    {
      variables: {
        id: customer ? customer.user_id : customerId,
      },
      skip: !customerId && !customer,
      fetchPolicy: "network-only",
    }
  );

  const customerPoints = getCustomer?.customer?.points.find(
    (point) => point.loyalty_id === activeLoyalty.id
  );

  const [redeemCustomerPoints] = useMutation(REDEEM_CUSTOMER_POINTS, {
    onCompleted: (data) => {
      if (!!data?.redeemCustomerPoints) {
        form.resetFields();
        onCancel();
        Modal.success({
          title: "Loyalty points redeemed successfully",
        });
      }
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
  });
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (Number(values.amount) > customerPoints.amount) {
        return Modal.error({
          title: "Insufficient points!",
          content: (
            <div>
              Customer has{" "}
              <b>{customerPoints?.points_value.toFixed(2) || 0} </b> points
              worth{" "}
              <b>
                {user.currency || user.employer.currency}
                {customerPoints?.amount?.toFixed(1) || 0}
              </b>
            </div>
          ),
        });
      }

      Modal.confirm({
        title: "Redeem Loyalty Points",
        content: (
          <div>
            <p>
              <span>Customer has</span>
              <b style={{ margin: "0 5px" }}>
                {customerPoints?.points_value.toFixed(2) || 0}
              </b>
              <span>points worth</span>
              <b style={{ margin: "0 5px" }}>
                {user.currency || user.employer.currency}
                {customerPoints?.amount?.toFixed(1) || 0}
              </b>
            </p>
            <p>
              You are about to redeem{" "}
              {(Number(values.amount) * Number(customerPoints?.points_value)) /
                Number(customerPoints?.amount)}{" "}
              points
            </p>
          </div>
        ),
        okText: "Proceed",
        onOk: () =>
          redeemCustomerPoints({
            variables: {
              loyalty_id: activeLoyalty.id,
              customer_id: Number(customerId),
              amount: Number(values.amount),
            },
          }),
      });
    });
  };

  const customerName = `${customer?.first_name ? customer.first_name : ""} ${
    customer?.last_name ? customer.last_name : ""
  } ${customer?.phone_number ? ":" + customer.phone_number : ""}
`;

  return (
    <Modal
      visible={visible}
      title="Redeem Loyalty Points"
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>Cancel</Button>,
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={getCustomerLoading}
          disabled={!customerPoints?.points_value}
        >
          Redeem
        </Button>,
      ]}
    >
      <h3 style={{ padding: 0, marginBottom: 20 }}>
        Active Loyalty: <b>{activeLoyalty?.name || "No active loyalty"}</b>
      </h3>
      <Form form={form} layout="vertical">
        <Form.Item
          label="Select Customer"
          name="customer_id"
          rules={[{ required: true, message: "Please select customer" }]}
          initialValue={customer ? customerName : null}
        >
          <Select
            allowClear
            showSearch
            placeholder="Select Customer"
            optionFilterProp="children"
            size="large"
            onChange={(val) => setCustomerId(val)}
            filterOption={(input, option) => {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                (option.props.id && option.props.id.indexOf(input) >= 0)
              );
            }}
            disabled={!!customer}
          >
            {customers &&
              customers.map((customer) => (
                <Select.Option
                  key={customer.user_id}
                  value={customer.user_id}
                  id={customer.loyalty_id}
                >
                  {`${customer?.first_name ? customer.first_name : "N/A"} ${
                    customer?.last_name ? customer.last_name : ""
                  } ${customer?.phone_number ? ":" + customer.phone_number : ""}
`}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: "Please enter amount to redeem" }]}
        >
          <InputNumber
            size="large"
            placeholder="Enter amount"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RedeemLoyaltyModal;
