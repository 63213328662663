import React from "react";
import { Modal, Button, Row, Checkbox } from "antd";
import PropTypes from "prop-types";
import {
  getAllProducts,
  updateProductItemInStore,
} from "../../../../../../redux/actions/productActions";
import {
  handleCategoryChange,
  // hanldeEnterOnSearchInput,
  hanldeProductSearch,
} from "../../../../../Sales/functions";
import { getAllCategories } from "../../../../../../redux/actions/categoryActions";
import { connect } from "react-redux";
import Filter from "../../../../../Sales/_partials/Filter";
import ProductList from "../../../../../Sales/_partials/ProductList";

import "./index.scss";
import strings from "../../../../../../strings";

class AddProductToInvoiceModal extends React.Component {
  state = {
    loading: false,
    products: [],
    searchTerm: "",
  };

  hanldeProductSearch = hanldeProductSearch.bind(this);
  handleCategoryChange = handleCategoryChange.bind(this);

  componentWillMount() {
    this.props.getAllCategories();
  }

  componentDidMount() {
    this.setState({
      products: this.props.products,
    });
  }

  hanldeEnterOnSearchInput = e => {
    if (e.keyCode === 13) {
      const allProducts = this.props.products;
      const currentProduct = { ...this.state.products[0] };
      if (
        this.state.products.length === 1 &&
        (currentProduct.quantity > 0 ||
          typeof currentProduct.quantity === "object") &&
        currentProduct.variants.length === 0
      ) {
        currentProduct.quantity = 1;
        this.setState({ searchTerm: "", products: allProducts });
      } else if (
        this.state.products.length === 1 &&
        currentProduct.quantity > 0 &&
        currentProduct.variants.length > 0
      ) {
        this.props.showVariantModal(currentProduct);
      }
    } else if (e.keyCode === 8 && e.target.value.length === 1) {
    }
  };

  render() {
    const {
      user,
      showModal,
      closeModal,
      categories,
      addProductToCart,
    } = this.props;

    const { products, searchTerm } = this.state;

    return (
      <Modal
        title={strings.addNewProductToInvoice}
        open={showModal}
        destroyOnClose
        onCancel={closeModal}
        footer={[
          <Button
            key="back"
            onClick={() => {
              closeModal();
            }}
          >
            {strings.cancel}
          </Button>,
        ]}
      >
        <div>
          <Filter
            categories={categories}
            onChangeCategory={this.handleCategoryChange}
            onSearch={this.hanldeProductSearch}
            searchTerm={searchTerm}
            onSearchEnter={this.hanldeEnterOnSearchInput}
          />
          <div
            style={{
              height: "300px",
              display: "flex",
              padding: "10px",
              flexWrap: "wrap",
              justifyContent: "space-around",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Row
              gutter={5}
              style={{ marginRight: "0px", marginLeft: "0px" }}
              className="product-list"
            >
              <>
                <ProductList
                  data={products}
                  addProductToCart={addProductToCart}
                  user={user}
                />
              </>
            </Row>
          </div>
        </div>
      </Modal>
    );
  }
}

AddProductToInvoiceModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  products: state.product.allProducts,
  categories: state.category.allCategories,
  user: state.auth.user,
  customers: state.customer.allCustomers,
  error: state.error,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
});

export default connect(mapStateToProps, {
  getAllProducts,
  updateProductItemInStore,
  getAllCategories,
})(AddProductToInvoiceModal);
