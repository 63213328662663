import React, { useState, useEffect, Suspense } from "react";
import { connect } from "react-redux";
import { ApolloProvider, useLazyQuery, useSubscription } from "@apollo/client";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Signup from "./containers/Auth/Signup";
// import Signin from "./containers/Auth/Signin";
import ResetPassword from "./containers/Auth/ResetPassword";
import OTP from "./containers/Auth/OTP";
import Dashboard from "./containers/Dashboard";
import Sales from "./containers/Sales";
import ScanQrCode from "./containers/Sales/_partials/ScanQrCode";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import addNotification from 'react-push-notification';
import KDSSigninForm from "./components/kitchenScreen/loginScreen";
// import Cohere from "cohere-js";

// Redux
import store from "./redux/store";
import { setCurrentUser, signoutUser } from "./redux/actions/authActions";
import { setNetworkStatus } from "./redux/actions/networkStatusActions";
import setAuthToken from "./utils/setAuthToken";

import "./App.scss";
import "@ant-design/compatible/assets/index.css";
import {
  deleteOfflineSale,
  notifyMerchant,
  recordOfflineSale,
} from "./utils/backgroundFunctions";
import client from "./utils/client";
import api from "./api";
import ResetCode from "./containers/Auth/ResetPassword/ResetCode";
import { notification, Button, Spin } from "antd";
import { notification2, notification25 } from "./noticationSounds";
import { PAY_ONE_ORDER, SUBSCRIBE_INSTORE_ORDERS, SUBSCRIBE_LOYSTAR_BOOKINGS } from "./constants";
import { openOrderScreen as activateKitchenScreen } from "./redux/actions/orderActions";
// import { Provider, ErrorBoundary } from "@rollbar/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import KitchScreen from "./components/kitchenScreen";
import SupplierPage from "./containers/SupplierPage";
import rollbar from "./utils/rollbar";
const Signin = React.lazy(() => import("./containers/Auth/Signin"));
const access_token = JSON.parse(localStorage.getItem("loystar-access-token"));
const multiLevelToken = localStorage.getItem("multi-level-token");
const mtierToken = localStorage.getItem("mtier-token");

// Cohere.init("RtbpsNLCYdWkF67C2BzDjDVg");

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLABAR_ACCESSTOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT
}

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    rollbar.error(error, info);
  }

  render() {
    return this.props.children;
  }
}

if (access_token !== "undefined" && access_token !== null) {
  // Set auth token header auth
  setAuthToken({ ...access_token, mtier: mtierToken });

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(userDetails));

  const signupDate = new Date(userDetails.created_at).getTime();
  const isDateBeforeSeptember12th2023 = signupDate < new Date('2023-09-12').getTime();
  
  window.usetifulTags = {
      userId: userDetails.email,
      segment: userDetails.business_type || userDetails.employer?.business_type,
      language: localStorage.getItem("current-language") || "English",
      role: userDetails.role_id === 1 ? "Admin" : userDetails.role?.name,
      firstName: userDetails.first_name || userDetails.username,
      currency: userDetails.currency || userDetails.employer?.currency,
      plan: userDetails.subscription_plan === "Free" ? "free" : "paid",
      isDateBeforeSeptember12th2023: isDateBeforeSeptember12th2023 ? "true" : "false",
  };

  window.$crisp?.push(["set", "user:email", userDetails.email]);
  window.$crisp?.push([
    "set",
    "user:nickname",
    userDetails.first_name || userDetails.username,
  ]);

  const currentTime = Date.now() / 1000;

  // Check for token expiration
  if (access_token.expiry < currentTime) {
    // Sign out user
    store.dispatch(signoutUser());
  }
}

const isExpired = (token) => {
  const jwtToken = jwt_decode(token);
  // console.log(jwtToken);
  if (jwtToken.exp < new Date().getTime() / 1000) {
    return true;
  }

  return false;
};
// nothing new to add
if (multiLevelToken !== "undefined" && multiLevelToken !== null) {
  if (isExpired(multiLevelToken)) {
    store.dispatch(signoutUser());
  }

  api.HttpClient.defaults.headers.common["multi-level-token"] = multiLevelToken;
}

const App = ({ 
  auth, 
  displayOrder, 
  isOrderPaid, 
  paidID
}) => {
  const homeBranch = localStorage.getItem("homebranch") ? JSON.parse(localStorage.getItem("homebranch")) : {id: null};
  // const [width, setWidth] = useState(0);
  const [isOnline, setIsOnline] = useState(false);
  const [syncError, setSyncError] = useState([]);
  const [ incomingOrder, setIncomingOrder ] = useState(null);

  const { isAuthenticated, user } = auth;
  const updateNetwork = () => {
    store.dispatch(setNetworkStatus(navigator.onLine));
    setIsOnline(navigator.onLine);
  };
  const [ payOneOrder, { loading: payOrderLoading, error: payOneOrderError, data: payOrderData }] = useLazyQuery(PAY_ONE_ORDER);

  const { data: inSData } = useSubscription(SUBSCRIBE_INSTORE_ORDERS, {
    onData: (data)=>{
      const newOrdered =  data.data.data.subscribeInstoreOrders;
      setIncomingOrder(newOrdered)

      newOrdered && addNotification({
        title: `New Order: ${newOrdered.order_id} from ${newOrdered.customer.first_name}`,
        message: `Order ${newOrdered.order_id} just arrived from ${newOrdered.customer.first_name } with phone: ${newOrdered.customer.phone_number }. Tap here to start processing`,
        theme: 'darkblue',
        backgroundTop: 'green',
        native: true,
        duration: 300000, 
        onClick: ()=> window.location = "/d/orders"
      });

      
      notification.success({
        message:  `Order ${newOrdered.order_id} just arrived from ${newOrdered.customer.first_name } with phone: ${newOrdered.customer.phone_number }. Tap here to start processing`,
        placement: "topRight",
        duration: null,
        onClick: ()=> {
          window.location = "/d/orders"
        }
      });

      const audio = new Audio();
      audio.src ="https://www.loystar.co/wp-content/uploads/2023/05/Happybellmerge.mp3"
      audio.play();

     },
      variables: { merchant_id: user?.employer ? user?.employer?.id  : user.id, branch_id: user?.business_branch ? user?.business_branch.id : homeBranch.id  }
  });

  // const { data: bookingData } = useSubscription(SUBSCRIBE_LOYSTAR_BOOKINGS, {
  //   onData: (data)=>{
  //     console.log("jfj", data);
      
  //    },
  //     variables: { merchant_id: user?.employer ? user?.employer.id  : user?.id }
  // });
//  user.role_id === 1 ? user?.id : user?.employer.id
  useEffect(()=>{
    displayOrder && addNotification({
      title: displayOrder.title,
      message: displayOrder.message,
      theme: 'darkblue',
      backgroundTop: 'green',
      native: true,
      duration: 300000, 
    });
    displayOrder && notification.success({
      message:  displayOrder.message,
      placement: "topRight"
    })
    // displayOrder && new Audio(notification2).play();
  }, [displayOrder]);

  
//to set order state to paid
  useEffect(()=>{
    isOrderPaid && payOneOrder({variables: {id: paidID}});
  }, [paidID]);

  useEffect(() => {
    async function fetchData() {
      const offlineSales = JSON.parse(localStorage.getItem("offlineSales"));

      const hasOfflineSales = offlineSales && offlineSales.length;

      window.addEventListener("online", updateNetwork);
      window.addEventListener("offline", updateNetwork);

      if (isAuthenticated && isOnline && hasOfflineSales) {
        // Loop Through Sales then POST to Server then notify user, all sales have been synced
        const requests = offlineSales.map((sale) => {
          const {
            transactionObj,
            cartList,
            addLoyaltyProgramToSale,
            shared_loyalty_txn,
            payment_reference,
          } = sale.payload;

          return recordOfflineSale(
            transactionObj,
            cartList,
            addLoyaltyProgramToSale,
            shared_loyalty_txn,
            payment_reference,
            sale.formattedData.created_at
          )
            .then((res) => {
              // Remove Sale from LocalStorage
              deleteOfflineSale(sale.formattedData.id);

              return { status: "success", data: res.data };
            })
            .catch((err) => {
              setSyncError((prevState) => [
                ...prevState,
                { status: "error", error: err, sale },
              ]);

              console.log({ status: "error", error: err, sale });
              return { status: "error", error: err.response, sale };
            });
        });

        try {
          await Promise.all(requests);

          if (!syncError.length) {
            notifyMerchant(
              "You are back online! All your offline sales have been synced!",
              "success"
            );
          } else {
            notifyMerchant(
              "An error occurred while syncing your offline sales. Some sales may not be synced!",
              "error"
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    fetchData();

    return () => {
      window.removeEventListener("online", updateNetwork);
      window.removeEventListener("offline", updateNetwork);
    };
  }, [isOnline, isAuthenticated]);

  const fallBackDiv = (
    <div style={{ 
      display: "flex", 
      flexDirection: "column", 
      alignItems: "center", 
      justifyContent: "center",
      backgroundColor: "#fff5f5",
      height: "100vh"
    }}>
      <Spin size="large"/>
      <h2 style={{ fontSize: "20px"}}>Loading....</h2>
    </div>
  )

  // try{
    return (
      <div className="App">
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECHAPTCHA_SITE_KEY}>
          {/* <Provider config={rollbarConfig}> */}
            <ErrorBoundary>
              <Router>
                <Suspense fallback={fallBackDiv}>              
                <ScrollToTop>
                  <Switch>
                    <Route exact path="/" component={Signin} />
                    <Route exact path="/d/purchase-orders/:uuid" component={SupplierPage} />
                    <PrivateRoute path="/d" component={Dashboard} />
                    <PrivateRoute path="/me" component={Dashboard} />
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/signin" component={Signin} />
                    <Route exact path="/otp" component={OTP} />
                    <Route exact path="/loystar-kds" component={KitchScreen} />
                    <Route exact path="/signin-kds" component={KDSSigninForm} />
                    
                    <PrivateRoute exact path="/sales" component={Sales} />
                    <PrivateRoute exact path="/qr" component={ScanQrCode} />
                    <Switch>
                      <Route exact path="/reset-password" component={ResetPassword} />
                      <Route exact path="/reset-code" component={ResetCode} />
                    </Switch>
                  </Switch>
                </ScrollToTop>
                </Suspense>
              </Router>
            </ErrorBoundary>
          {/* </Provider> */}
        </GoogleReCaptchaProvider>
      </div>
    );
  // }catch(err){
  //   <div className="refresh-btn-div">
  //     <Button
  //       type="primary"
  //       onClick={() => window.location.reload(false)}
  //     >
  //       Refresh
  //     </Button>
  //   </div>
  // }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  displayOrder: state.allOrders.displayOrder,
  isOrderPaid: state.allOrders.isOrderPaid,
  paidID: state.allOrders.paidID,
  openOrderScreen: state.allOrders.openOrderScreen,
  isKitchenScreenActive: state.appStore.isKitchenScreenActive
});

export default connect(mapStateToProps, {activateKitchenScreen})(App);
