import { 
  GET_ORDERS, 
  UPDATE_ORDER, 
  CANCEL_ORDER, 
  MERCHANT_DETAILS, 
  ORDERED_ITEMS, 
  ORDERS, 
  DISPLAY_ORDERS, 
  PAY_ORDER, 
  ORDERED_ITEMS_LOADING, 
  ORDERED_ITEMS_SUCCESS, 
  OPEN_ORDER_SCREEN 
} from "../constants";
import api from "../../api";
import Axios from "axios";
export const getAllOrders = () => async dispatch => {
  const res = await api.orders.getAllOrders();
  if (res && res.status === 200) {
    dispatch({
      type: GET_ORDERS,
      payload: res.data,
    });
  }

  return Promise.resolve(res);
};

export const updateOrder = id => async dispatch => {
  const res = await api.orders.updateOrder(id);

  if (res && res.status === 200) {
    dispatch({
      type: UPDATE_ORDER,
      payload: res.data,
    });
  }
};

export const cancelOrder = id => async dispatch => {
  const res = await api.orders.cancelOrder(id);

  if (res && res.status === 200) {
    dispatch({
      type: CANCEL_ORDER,
      payload: res.data,
    });
  }
};

export const addNewCustomer = (requestPayload = {}) => dispatch => {
  return api.customer.addNewCustomer(requestPayload);
};

export const getMerchantByDispatch = ( userDetails) => dispatch => {


  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  dispatch({ type: MERCHANT_DETAILS, payload: userDetails });

}

export const getOrderedItems = ( order, orderItems ) => async (dispatch, getState )=> {
  const isProduct = localStorage.getItem("products")
  const isBundleProduct = localStorage.getItem("bundle_product");
  const merchantProducts = getState().product.allProducts.length > 0 ? getState().product.allProducts : JSON.parse(isProduct);
  const orderItem = orderItems.getOrderItems.filter((item)=>{
    return item.order_id === order.id
  }) 


  // console.log(order, orderItems, orderItem,merchantProducts);
  dispatch({ type: ORDERED_ITEMS_LOADING })
 
  if(merchantProducts.length > 0){
      // console.log(orderItem)
    const bundleProduct = JSON.parse(isBundleProduct);
    const newOrderitem = orderItem.map((oneItem)=>{
      
      if(oneItem.bundle_id){
        
        
        const bundleItem = bundleProduct.find(prod => prod.id === oneItem.bundle_id);
        // console.log("itememem", orderItem, bundleItem);
        const newItem = { ...bundleItem };

        // delete oneItem.__typename;
        // newItem.__typename && delete newItem.__typename;
        // const vv = {...newItem.variants[0]}
        // vv.quantityLeft = null;
        
        // newItem.products = {...oneItem};
        // newItem.product.__typename && delete newItem.product.__typename;
        // newItem.product.id = oneItem.id
        // newItem.product_name = oneItem.name;
        // newItem.product_id = oneItem.custom_quantities.length > 0 ? oneItem.custom_quantities[0].product_id : oneItem.item_id;
        // newItem.product_type = "product";
        // newItem.product_picture = oneItem.picture;
        // newItem.product_quantity = oneItem.quantity;
        // newItem.product_tax = oneItem.tax;
        // newItem.product_price = oneItem.price;
        // newItem.variants = newItem.variants.length !== 0 ? variants : [];
        // newItem.product_variants = newItem.variants.length !== 0 ? newItem.variants : [];
        dispatch({ type: ORDERED_ITEMS_SUCCESS, payload: merchantProducts});
        return newItem;
      }else{
        const newItem = { ...oneItem };
        // delete oneItem.__typename;
        newItem.__typename && delete newItem.__typename;

        const vv = {...newItem.variants[0]}

        vv.quantityLeft = null;

        const variants = [ {...vv} ]

        const checkVariant = merchantProducts.filter((dat)=>{
          return dat.id === newItem.item_id;
        })

        const variat = variants.map((varia) => {
          if(varia.__typename) delete varia.__typename;
          return varia
        })
        newItem.id = oneItem.service_id ? oneItem.service_id : oneItem.variants.length > 0 ? oneItem.item_id : oneItem.custom_quantities.length > 0 ? oneItem.custom_quantities[0].product_id : oneItem.item_id;

        newItem.original_price = checkVariant[0]?.original_price ? checkVariant[0]?.original_price : "";

        if(newItem.variants.length === 0) newItem.product = {...oneItem};
        if(newItem.variants.length === 0)  newItem.product.__typename && delete newItem.product.__typename;
       
        if(newItem.variants.length === 0) newItem.product.id = oneItem.id

        if(newItem.variants.length === 0) newItem.product_quantity = oneItem.quantity;
        
        if(newItem.variants.length === 0) newItem.product_picture = oneItem.picture;
        
        if(newItem.variants.length === 0) newItem.product_name = oneItem.name;
        

        // newItem.product_name = oneItem.name;
        newItem.product_id = oneItem.custom_quantities.length > 0 ? oneItem.custom_quantities[0].product_id : oneItem.item_id;
        newItem.product_type = "product";
        
        newItem.product_tax = oneItem.tax;
        if(newItem.variants.length > 0)  newItem.track_inventory = true;

        if(newItem.variants.length > 0)  newItem.bundle_products = [];

        if(newItem.variants.length > 0)  newItem.bundles = [];

        if(newItem.variants.length > 0)  newItem.has_custom_qty = false;

        if(newItem.variants.length > 0)  delete newItem.bundle_id;
        
        newItem.unit = oneItem.unit;
        // newItem.product_price = oneItem.price;
        newItem.variants = newItem.variants.length !== 0 ? variat : [];
        // newItem.product_variants = newItem.variants.length !== 0 ? newItem.variants : [];
        dispatch({ type: ORDERED_ITEMS_SUCCESS, payload: merchantProducts});
        return newItem;
      }
    })

    const payload = newOrderitem;
  
    dispatch({ type: ORDERED_ITEMS, payload: payload });
  
  }
 
}

export const getOrder = ( orders ) => dispatch => {

  dispatch({ type: ORDERS, payload: orders});

}

export const displayOrder = ( order )=>(dispatch)=>{
  dispatch ({type: DISPLAY_ORDERS, payload: order })
}

export const payOrder = (order_id)=> (dispatch )=>{
  const payOrderState = {
    isOrderPaid: true,
    paidID: order_id
  } 
  dispatch ({type: PAY_ORDER, payload: payOrderState})
}

export const openOrderScreen = ( open ) => (dispatch) => {
  // console.log("open", open);
  dispatch({ type: OPEN_ORDER_SCREEN, payload: open })
}