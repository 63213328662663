import React, { Component } from "react";
import { Row, Col, Select, Input, Button, Spin } from "antd";
import { Form, Icon } from "@ant-design/compatible";
import { connect } from "react-redux";
import { checkEmailAvailability } from "../../../redux/actions/authActions";
import strings from "../../../strings";

const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const Option = Select.Option;

class PersonalData extends Component {
  state = {
    isEmailLoading: false,
    businessTypes: [
      { id: 1, type: strings.foodAndDrinks },
      { id: 2, type: strings.salonAndBeauty },
      { id: 3, type: strings.fashionAndAccessories },
      { id: 4, type: strings.gymAndFitness },
      { id: 5, type: strings.travelAndHotel },
      { id: 6, type: strings.homeAndGifts },
      { id: 7, type: strings.washingAndCleaning },
      { id: 8, type: strings.gadgetsAndElectronics },
      { id: 9, type: strings.groceries },
      { id: 10, type: strings.others },
       { id: 11, type: strings.photography },
      { id: 12, type: strings.bookstore },
      { id: 13, type: strings.healthcare },
    ],
  };

  handleSubmit = () => {
    const { next, form, setPersonalDataFields } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setPersonalDataFields(values);

      next();
    });
  };

  isValidEmail = email => {
    const expression = /\S+@\S+\.\S+/;
    return expression.test(String(email).toLowerCase());
  };

  handleEmailValidation = async (rule, value, callback) => {
    const { checkEmailAvailability } = this.props;

    if (value) {
      if (!this.isValidEmail(value)) {
        callback();
      } else {
        this.setState({
          isEmailLoading: true,
        });

        try {
          const res = await checkEmailAvailability(value);

          const { email_available } = res.data;
          this.setState({ isEmailLoading: false });

          if (!email_available) {
            callback(strings.emailInUse);
          }
        } catch (err) {
          callback();
          this.setState({ isEmailLoading: false });
        }
      }
    } else {
      callback();
    }
  };

  handlePhoneValidation = async (rule, value, callback) => {
    const { checkPhoneAvailability } = this.props;

    if (!value) {
      callback("");
    }

    let phone = value.split(" ").join("");

    try {
      if (value) {
        this.setState({ isPhoneLoading: true });

        const res = await checkPhoneAvailability(phone);

        this.setState({ isPhoneLoading: false });

        const { phone_available } = res.data;

        if (!phone_available) {
          callback(strings.phoneNumberInUse);
        }
      } else {
        callback();
      }
    } catch (error) {
      callback();
      this.setState({ isPhoneLoading: false });
    }
  };

  render() {
    const { isEmailLoading, businessTypes } = this.state;
    const { form, previous } = this.props;

    const { getFieldDecorator } = form;

    const businessTypeOptions = businessTypes.map(type => (
      <Option key={type.id} value={type.id}>
        {type.type}
      </Option>
    ));

    return (
      <div className="" style={{ maxWidth: "500px" }}>
        {/* <h1 className="title">Sign up</h1> */}
        <div className="">
          <Form layout="vertical" >
            <Row gutter={10}>
              <Col md={12} sm={24}>
                {/* Firstname Input */}
                <FormItem>
                  {getFieldDecorator("first_name", {
                    rules: [
                      {
                        message: strings.pleaseEnterFirstName,
                        required: true,
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder={strings.firstName}
                    />
                  )}
                </FormItem>
                {/* ======Firstname */}
              </Col>
              <Col md={12} sm={24}>
                {/* Lastname */}
                <FormItem>
                  {getFieldDecorator("last_name", {
                    rules: [
                      {
                        message: strings.pleaseEnterLastName,
                        required: true,
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder={strings.lastName}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            {/* Email Input */}
            <FormItem>
              {getFieldDecorator("email", {
                rules: [
                  {
                    message: strings.pleaseEnterEmail,
                    required: true,
                  },
                  {
                    type: "email",
                    message: strings.pleaseEnterValidEmail,
                  },
                  {
                    validator: this.handleEmailValidation,
                  },
                ],
              })(
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Input
                    size="large"
                    type="email"
                    prefix={
                      <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder={strings.email}
                    style={{
                      width: isEmailLoading ? "90%" : "100%",
                    }}
                  />

                  {isEmailLoading && <Spin indicator={antIcon} />}
                </div>
              )}
            </FormItem>
            {/* ======Email */}

            {/* Business name Input */}
            <FormItem>
              {getFieldDecorator("business_name", {
                rules: [
                  {
                    message: strings.pleaseEnterBusinessName,
                    required: true,
                  },
                ],
              })(
                <Input
                  size="large"
                  prefix={
                    <Icon type="bank" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder={strings.businessName}
                />
              )}
            </FormItem>
            {/* ======Business name */}

            {/* Business category Input */}
            <FormItem>
              {getFieldDecorator("business_type_id", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseSelectBusinessCategory,
                  },
                ],
              })(
                <Select placeholder="Business Category" size="large">
                  {businessTypeOptions}
                </Select>
              )}
            </FormItem>
            {/* =========Business category */}

            <div className="control-buttons">
              <Button
                size="large"
                onClick={() => previous()}
                style={{ marginRight: "1rem" }}
              >
                {strings.previous}
              </Button>

              <Button
                loading={this.state.isLoading}
                size="large"
                type="primary"
                onClick={() => this.handleSubmit()}
              >
                {strings.next}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const PersonalDataForm = Form.create()(PersonalData);

export default connect(null, { checkEmailAvailability })(PersonalDataForm);
