import React, { useState, useEffect} from "react";
import { Input, Modal, Button, Badge, message } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";
import { clearShowCustomQtyModalData } from "../../../../redux/actions/saleActions";

// Redux
import { connect } from "react-redux";

import "../VariantsModal/index.scss";
import strings from "../../../../strings";

const CustomQuantity = ({
  showCustomQuantityModal, 
  toggleModal, 
  product, 
  form,
  addNewCategory,
  getAllCategories, 
  currency,
  onClick,
  isNitroActive,
  sale,
  clearShowCustomQtyModalData,
  handleBlurFactor
  })=> {
    const FormItem = Form.Item;
    const [ loading, setLoading ] = useState(false);
    const [ customProduct, setCustomProduct ] = useState(null)
    const [ selectedCustomQuantity, setSelectedCustomQuantity ] = useState({});
    const [ groupedVariants, setGroupedVariants ] = useState({});
    const [ quantity, setQuantity ] = useState(1.0);
    const [ modalIsUp, setModalIsUp ] = useState(false);
    const [ tryUpdate, setTryUpdate ] = useState(false);
    const [ trackChange, setTrackChange ] = useState(false);
    const [ isSelected, setIsSelected ]= useState({});
    const [ isAnySelected, setIsAnySeleteced ] = useState(false);
    const { getFieldDecorator } = form;

 
    useEffect(()=>{
        form.setFieldsValue({ quantity: quantity });
    }, [quantity]);

    useEffect(() => {
      setCustomProduct(product)
      handleBlurFactor(false);
    }, [product]);


    useEffect(()=>{
        setSelectedCustomQuantity(selectedCustomQuantity);
    }, [selectedCustomQuantity])


    const changeQuantity = (type) => {
    const selectedCust = [{...isSelected}]
    if (selectedCust.length > 0) {
      if (type === "plus") {
        if(Number(product.quantity) <= (Number(selectedCust[0].quantity) * quantity)){
            Modal.warning({
                title: `${strings.lowStockFor} ${selectedCust[0].name}: ${selectedCust[0].quantity}`,
            });
            return;
        }
        setQuantity(quantity+1);
        form.setFieldsValue({ quantity: quantity });
      } else if (type === "minus") {
        if (quantity <= 1) {
          return;
        }
        setQuantity(quantity-1);
      } else {
        if (!isNaN(type) && Number(type) >= 0) {
          setQuantity(Number(type));
          form.setFieldsValue({ quantity: quantity });
        } else {
          Modal.warning({
            title: strings.pleaseSupplyPositiveQuantityNumber,
          });
        }
      }
    } else {
      Modal.warning({
        title: "Please select a custom quantity",
      });
    }
  };

  const selectCustomQuantity = (custom) => {
      setQuantity(1)
      form.setFieldsValue({ quantity: quantity });
      setIsSelected(custom);
      setIsAnySeleteced(true);
  };

  const clearSelectedVariants = () => {

      // setSelectedCustomQuantity(selectedCustomQuantity);
      setQuantity(1);
      setIsSelected({});
      setIsAnySeleteced(false);
      form.setFieldsValue({ quantity: quantity });
  };

  const handleOnClick = () => {
      if (isAnySelected === false) {
        return Modal.warning({
          title: "Please select a custom quantity",
        });
      }

      const custom_quantities = [{...isSelected}]
      let productCopy = { ...product };

      delete productCopy.custom_quantities;
      const item = { ...productCopy, custom_quantities };

      item.custom_quantities = custom_quantities;
      if(isNitroActive){
        item.quantity = quantity
      }else{
        item.quantity = quantity;
      }
      item.price = isSelected.price;

      if (custom_quantities.length === 0) {
      Modal.warning({
          title: "Please select a custom quantity",
      });
      } else {
          onClick(item, "bulk");
          form.setFieldsValue({ quantity: 1 });
          // setModalIsUp(true);
          setQuantity(1.0);
          setIsSelected({});
          if(sale && sale.showCustomQtyModal){
            clearShowCustomQtyModalData()
          }
          setIsAnySeleteced(false);
      }
      handleBlurFactor(true);
  };

  const displayCustomQty = () => {
    const customQuantity = customProduct.custom_quantities;

    return customQuantity.map((custom, index)=>{
      const { name, price, quantity, id } = custom;
      return (
        <div key={index}>
          <div className="">
            <Badge
              key={id}
              // count={quantity}
              className={
              isSelected?.id === id
                ? strings.variantSelected
                : strings.variant
              }
              onClick={() => selectCustomQuantity(custom)}
            >
              <span style={{ paddingRight: "10px" }}>{name} ({quantity} units)</span>
              <span style={{ paddingRight: "10px" }}>-</span>
              <span>
              {currency}
              {customProduct.tax_rate
                  ? (
                      (customProduct.tax_rate / 100) * Number(price) +
                      parseFloat(price)
                  ).toFixed(2)
                  : Number(price)}
              </span>
            </Badge>
          </div>
      </div>
    )})
  };

    return (
      <Modal
          title={`${customProduct && customProduct.name} ${customProduct && customProduct.price}`}
          okText={strings.add}
          cancelText={strings.cancel}
          onOk={handleOnClick}
          onCancel={()=>{
            toggleModal();
            handleBlurFactor(true);
          }}
          destoryOnClose
          open={showCustomQuantityModal}
          okButtonProps={{ loading: loading }}
      >
          <div className="variants">
            <span>Select Quantity:</span>
            <Button
                htmlType="button"
                className="clear-button"
                disabled={!isAnySelected}
                onClick={clearSelectedVariants}
            >
                {strings.clearCustQty}
          </Button>
            <div className="variant-wrapper" style={{width: "100%"}}>{customProduct && displayCustomQty()}</div>
          </div>

          <Form layout="vertical" >
          <span>{strings.quantity}:</span>
          <div className="variant_quantity_container">
              <div>
              <Button
                  htmlType="button"
                  onClick={() => {
                      changeQuantity("minus");
                  }}
              >
                  -
              </Button>
              </div>
              <FormItem label="">
              {getFieldDecorator("quantity", {
                  rules: [
                  {
                      required: true,
                      message: strings.pleaseEnterQuantity,
                  },
                  ],
              })(
                  <Input
                  size="large"
                  onChange={(e) => {
                      if(isAnySelected === false ){
                        return Modal.warning({
                              title: strings.pleaseSelectACustom,
                          });
                          
                      }
                      setQuantity(e.target.value)
                      form.setFieldsValue({ quantity: quantity });
                  }}
                  className="variant_input center"
                  type="number"
                  />
              )}
              </FormItem>
              <div>
              <Button
                  htmlType="button"
                  onClick={() => {
                      changeQuantity("plus");
                  }}
              >
                  +
              </Button>
              </div>
          </div>
          </Form>
      </Modal>
    );
}


CustomQuantity.propTypes = {
  // product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  // sales: PropTypes.object.isRequired,
}; 
const CustomQuantityForm = Form.create()(CustomQuantity);

const mapStateToProps = (state) => ({
  products: state.product.allProducts,
  branchProducts: state.product.branchProducts,
  isNitroActive: state.appStore.isNitroActive,
  sale: state.sale.sale
});

export default connect(mapStateToProps, {clearShowCustomQtyModalData})(CustomQuantityForm);
