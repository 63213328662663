import React, { Fragment } from "react";
import strings from "../../../../../../strings";
import numberFormatter from "../../../../../../utils/numberFormatter";

const StaffPrice = ({
  paymentOptions,
  staffs,
  plan,
  renewal,
  upgrade,
  staffPrice,
  noOfStaffs,
  staff_discount_percentage,
  addons,
}) => {
  return (
    <Fragment>
      {paymentOptions === "renewal" && staffs.length >= 1 && (
        <p style={{
          display: "flex", 
          alignItems: "center",
          justifyContent:"space-between"
        }}>
          <span>
            {strings.staff} ({staffs.length}) @ {plan.currency}
            {numberFormatter(staffPrice)}
            {staffs.length > 3 &&
              ` || ${staff_discount_percentage * 100}% ${strings.discountApplies}`}
          </span>

          <span>
            {plan.currency}
            {numberFormatter(renewal.total_staff_price)}{" "}
          </span>
        </p>
      )}

      {paymentOptions === "upgrade" && addons.hasStaffs && noOfStaffs >= 1 && (
        <p style={{
          display: "flex", 
          alignItems: "center",
          justifyContent:"space-between"
        }}>
          <span>
            {strings.staff} ({noOfStaffs}) @ {plan.currency}
            {numberFormatter(staffPrice)}
            {noOfStaffs > 3 &&
              ` || ${staff_discount_percentage * 100}% ${strings.discountApplies}`}
          </span>

          <span>
            {plan.currency}
            {numberFormatter(upgrade.total_staff_price)}{" "}
          </span>
        </p>
      )}
    </Fragment>
  );
};

export default StaffPrice;
