import React, { useState } from "react";
import { Tabs } from "antd";
import WorkHours from "../WorkHours";
import ServicesCategory from "../ServicesCategory";
import "../index.scss";

const { TabPane } = Tabs;

const Index = () => {
  const [activeTab, setActiveTab] = useState("1");

  return (
    <Tabs activeKey={activeTab} onChange={activeKey => setActiveTab(activeKey)}>
      <TabPane tab="Work Hours" key="1">
        <WorkHours />
      </TabPane>
      <TabPane tab="Categories" key="2">
        <ServicesCategory />
      </TabPane>
    </Tabs>
  );
};

export default Index;
