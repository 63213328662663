import React from "react";
import { Radio, Checkbox, Form, InputNumber, Tooltip } from "antd";
import { Icon } from "@ant-design/compatible";
import "./index.scss";
import strings from "../../../../strings";

const Discount = ({
  user,
  hasDiscount,
  discountType,
  handleDiscountTypeChange,
  setHasDiscount,
  toggleDiscount,
  customDiscountValue,
  handleSubmit,
  handleCustomDiscountChange,
  handleDiscountAmountChange,
  discountAmount,
}) => {
  const label = (
    <div className="discount-type-header">
      <span style={{ fontSize: "12px", fontWeight: "bold" }}>
        Select Discount Mode
      </span>

      <Radio.Group
        onChange={e => handleDiscountTypeChange(e.target.value)}
        value={discountType}
      >
        <Radio value="amount">{strings.amount}</Radio>
        <Radio value="percent">{strings.percent}</Radio>
      </Radio.Group>
    </div>
  );

  const currency = user.currency || (user.employer && user.employer.currency);

  return (
    <div className="discount-wrapper">
      {!hasDiscount && (
        <div className="checkbox-wrapper">
          <Checkbox onChange={toggleDiscount} checked={hasDiscount}>
            {strings.applyDiscount}
          </Checkbox>
        </div>
      )}

      {hasDiscount && (
        <Form layout="vertical" onSubmit={handleSubmit}>
          {label}

          {discountType === "percent" && (
            <Form.Item className="discount-form-item">
              <InputNumber
                value={customDiscountValue}
                onChange={handleCustomDiscountChange}
                min={0}
                max={100}
                className="custom-discount-field"
                formatter={value => `${value}%`}
                parser={value => value.replace("%", "")}
              />
              <Tooltip className="ml-1" title="Cancel" trigger="hover">
                <Icon type="close" onClick={() => setHasDiscount(false)} />
              </Tooltip>
            </Form.Item>
          )}

          {discountType === "amount" && (
            <Form.Item className="discount-form-item">
              <InputNumber
                defaultValue={1000}
                formatter={value => `${currency}${value}`}
                parser={value => value.replace(currency, "")}
                value={discountAmount}
                onChange={handleDiscountAmountChange}
                min={1}
                className="custom-discount-field"
              />
              <Tooltip className="ml-1" title="Cancel" trigger="hover">
                <Icon type="close" onClick={() => setHasDiscount(false)} />
              </Tooltip>
            </Form.Item>
          )}
        </Form>
      )}
    </div>
  );
};

export default Discount;
