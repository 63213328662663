import React, { useState } from "react";
import { Input, Select, Button, Modal, Radio, message } from "antd";
import { Form } from "@ant-design/compatible";

const FormItem = Form.Item;
const { Option } = Select;

const SingleFormField = ({ getFieldDecorator }) => {
  const [singleFieldInputType, setSingleFieldInputType] = useState(null);
  return (
    <>
      <FormItem label="Field Input Type">
        {getFieldDecorator("field_input_type", {
          rules: [
            {
              required: true,
              message: "Please enter custom field input type",
            },
          ],
        })(
          <Select
            placeholder="Select Field Input Type"
            size="large"
            onChange={val => setSingleFieldInputType(val)}
          >
            <Option value="name">Name field</Option>
            <Option value="email">Email field</Option>
            <Option value="phone_number">Phone Number field</Option>
            <Option value="text">Text field</Option>
            <Option value="number">Number field</Option>
            <Option value="select">Select Options</Option>
            <Option value="checklist">Check List</Option>
            <Option value="radio">Radio Fields</Option>
            <Option value="switch">Switch button</Option>
          </Select>
        )}
      </FormItem>
      {singleFieldInputType === "select" && (
        <>
          <FormItem label="Options to Select">
            {getFieldDecorator("field_options", {
              rules: [
                {
                  required: true,
                  message: "Please enter the options list to select from",
                },
              ],
            })(
              <Input
                placeholder="Enter options separated by a comma and a space"
                size="large"
              />
            )}
          </FormItem>
        </>
      )}

      {singleFieldInputType === "checklist" && (
        <>
          <FormItem label="Options to Check">
            {getFieldDecorator("field_options", {
              rules: [
                {
                  required: true,
                  message: "Please enter the options list to check from",
                },
              ],
            })(
              <Input
                placeholder="Enter options separated by a comma and a space"
                size="large"
              />
            )}
          </FormItem>
        </>
      )}
      {singleFieldInputType === "radio" && (
        <>
          <FormItem label="Radio Choices">
            {getFieldDecorator("field_options", {
              rules: [
                {
                  required: true,
                  message: "Please enter the options list to select from",
                },
              ],
            })(
              <Input
                placeholder="Enter options separated by a comma and a space"
                size="large"
              />
            )}
          </FormItem>
        </>
      )}
      <FormItem label="Is Field Compulsory?">
        {getFieldDecorator("required_field", {
          rules: [
            {
              required: true,
              message: "Please choose if field must be filled or not",
            },
          ],
        })(
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        )}
      </FormItem>
      <FormItem label="Additional Notes">
        {getFieldDecorator("notes")(
          <Input.TextArea
            placeholder="Enter any additional instructions"
            size="large"
            rows={2}
          />
        )}
      </FormItem>
    </>
  );
};

const AddCustomField = ({ form, visible, onCancel, handleAddFieldSubmit }) => {
  const { getFieldDecorator } = form;

  const confirmFormCancel = () => {
    Modal.confirm({
      title: "Cancel Field Creation",
      content: "Do you want to stop adding this field?",
      onOk: onCancel,
    });
  };

  const handleAddFormSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const savedFields = JSON.parse(localStorage.getItem("form-fields"));
      if (savedFields) {
        const field = savedFields.fields.find(
          myField =>
            myField.field_name.toLowerCase() === values.field_name.toLowerCase()
        );
        if (field)
          return message.error("You already have a form field with that name!");
      }
      handleAddFieldSubmit(values);
      onCancel();
      form.resetFields();
    });
  };

  return (
    <div>
      <Modal
        title="Add New Field"
        visible={visible}
        footer={[
          <Button type="primary" onClick={handleAddFormSubmit}>
            Save Field
          </Button>,
          <Button onClick={confirmFormCancel}>Cancel</Button>,
        ]}
      >
        <Form layout="vertical" onSubmit={handleAddFormSubmit}>
          <FormItem label="Field Name">
            {getFieldDecorator("field_name", {
              rules: [
                {
                  required: true,
                  message: "Please enter field name",
                },
              ],
            })(<Input placeholder="Enter field name" size="large" />)}
          </FormItem>

          <SingleFormField getFieldDecorator={getFieldDecorator} />
        </Form>
      </Modal>
    </div>
  );
};

export default Form.create()(AddCustomField);
