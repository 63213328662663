import React from "react";
import { ApolloConsumer } from "@apollo/client";

const WithApolloClient = (Component) => {
  class withApolloClient extends React.Component {
    render() {
      return (
        <ApolloConsumer>
          {(client) => {
            return <Component {...this.props} client={client} />;
          }}
        </ApolloConsumer>
      );
    }
  }

  return withApolloClient;
};

export default WithApolloClient;
