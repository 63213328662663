import React, { useEffect, useState} from 'react';
import "../index.scss";
import { Modal, Switch } from 'antd';
import { activateNitro } from '../../../../../../../redux/actions/appActions';
import { connect } from 'react-redux';
import WithApolloClient from '../../../../../../../utils/withApolloClient';


const ActivateNitro = ( props ) => {
    const isNitroActive = props.state.appStore.isNitroActive;
    // console.log("NitroMode: ",isNitroActive);
    const [ isActive, setIsActive ] = useState(false);
    const [ checked, setChecked ] = useState(isNitroActive);

    useEffect(()=>{
        setChecked(isNitroActive)
    }, [isNitroActive])
    const HandleChange = (e) => {
        setIsActive(e);
        props.activateNitro(e);
    }

  return (
    <>
        <Modal
            open={props.open}
            onCancel={props.onCancel}
            onOk={props.onCancel}
        >
            <div>
                <h2>Activate Nitro</h2>
                <p>This mode Enables you..</p>
                <Switch 
                    onChange={(e)=>HandleChange(e)}
                    checked={checked}
                />
            </div>
        </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  state: state,
  isNitroActive: state.appStore.isNitroActive
});

export default connect(mapStateToProps, { activateNitro })(WithApolloClient(ActivateNitro));