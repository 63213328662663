import React, { useState } from 'react';
import { Modal, Input, Button, message } from 'antd';
import Axios from 'axios';


const AuthModal = ({ open, onCancel, user, grantPermission}) => {

    const [email, setEmail ] = useState("");
    const [password, setPassword ] = useState("");
    // const [userDetails, setUserDetails ] = useState({});
    const [ loading, setLoading ] = useState(false)

    const loginAuth = async ( {email, password }) => {
        setLoading(true);
        const res = await fetch(
        `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/auth/login`,
        {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({
            email,
            password,
            }),
        }
        );
        const data = await res.json();

        if (res.status === 200){
            setLoading(false);
            const permitted = data.data;
            if(user.role && user.role.id === 2){
                if(permitted.role && permitted.role.id === 3){
                    return message.warn("Permission not Granted");

                }else if(permitted.role && permitted.role.id === 2){
                    return message.warn("Permission not Granted");

                }else if(permitted.role_id === 1){
                    if(permitted.id !== user.employer.id) return message.warn("Permission not Granted");

                    message.success("Permission Granted!");
                    grantPermission();
                    onCancel();
                }
            }else if(user.role && user.role.id === 3){

                if(permitted.role && permitted.role.id === 3){
                    return message.warn("Permission not Granted");

                }else if(permitted.role && permitted.role.id === 2){

                    if(permitted.employer.id !== user.employer.id){
                        return message.warn("Permission not Granted");  
                    }
                    message.warn("Permission Granted!");
                    grantPermission();
                    onCancel();

                }else if(permitted.role_id === 1){
                    if(permitted.id !== user.employer.id){
                        return message.warn("Permission not Granted"); 
                    }

                    message.success("Permission Granted!");
                    grantPermission();
                    onCancel();
                }
            }
        }else{
            setLoading(false);
            console.log(res);
            message.error("Unable to Authorize, Please Try Again")
        }
    }




  return (
    <Modal
        open={open}
        onCancel={onCancel}
        footer={null}
        title="Please authorize transaction update"
    >
        <>
        {/* <div style={{
                height: "15px"
            }}></div> */}
            <Input 
                placeholder='email'
                value={email}
                onChange={(e)=>{
                    setEmail(e.target.value);
                }}
            />
            <div style={{
                height: "10px"
            }}></div>
            <Input 
                placeholder='password'
                value={password}
                onChange={(e)=>{
                    setPassword(e.target.value);
                }}
                type="password"
            />
            <div style={{
                height: "10px"
            }}></div>
            <div>
                <Button
                    loading={loading}
                    onClick={()=>loginAuth({ email, password})}
                    type="primary"
                >
                    Authorize
                </Button>
            </div>
        </>
    </Modal>
  )
}

export default AuthModal