import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  InputNumber,
  Select,
  Switch,
  message,
  Radio,
  Upload,
  Image,
  Checkbox
} from "antd";
import { Form } from "@ant-design/compatible";
import { UploadOutlined } from "@ant-design/icons"
import api from "../../../../../../api";
import strings from "../../../../../../strings";

const Option = Select.Option;
const FormItem = Form.Item;

const EditLoyaltyModal = ({
  currLoyalty,
  closeModal,
  isEditLoyaltyModalOpen,
  form,
  getAllLoyaltyPrograms,
  toggleLoyaltyDetailModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loyaltyIds, setLoyaltyIds] = useState([]);
  const [showCustomDays, setShowCustomDays] = useState(false);
  const [applyTo, setApplyTo] = useState(currLoyalty.apply_to);
  const [ images, setImages ] = useState([])
  const [ loyltyImage, setLoyaltyImage ] = useState([]);
  const [ loadingImg, setLoadingImg ] = useState(false);
  const [ apply_once, setApply_once ] = useState(false);
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

  const programType = currLoyalty.program_type;
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const maxFileSize = 4000000;
  const url = "https://api.cloudinary.com/v1_1/loystar/image/upload";

  const uploadProps = {
    name: "file",
    action: url,
    headers: {
      body: JSON.stringify({ upload_preset: "hyemnes2", folder: "test_img" }),
    },
    onChange(info) {
      if (info.file.status === "done") {
        // console.log("info",info);
        message.success(`${info.file.name} ${strings.fileUploadSuccessful}`);
        // setstate({ ...state, imageUrl: info.file.response.imageCloudData.url });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} ${strings.fileUploadFailed}`);
      }
    },
  };

  useEffect(() => {
    fetchLoyaltyId();
    setFieldsValue({
      ...currLoyalty,
    });
    setImages(currLoyalty.images)
    setApply_once(currLoyalty.apply_once || apply_once);
  }, [currLoyalty, setFieldsValue]);

  const rewardGroups = currLoyalty.instant_rewards_groups;
  const rewardObj = {};
  const rewardsDetailsObj = {};
  const initialGroups = [];
  loyaltyIds &&
    loyaltyIds.forEach(
      group =>
        (rewardObj[
          group.id
        ] = `${group.name} (${group.customers.length} customers)`)
    );
  rewardGroups &&
    rewardGroups.forEach(group => {
      if (rewardObj[group.group_id]) {
        initialGroups.push(rewardObj[group.group_id]);
        rewardsDetailsObj[rewardObj[group.group_id]] = group.group_id;
      }
    });

  const handleEditLoyalty = () => {
    validateFields(async (err, values) => {
      if (err) return;

      setIsLoading(true);

      const newGroups = values.group_ids
        ? values.group_ids.map(value => {
            if (Number(value)) return value;
            if (rewardsDetailsObj[value]) return rewardsDetailsObj[value];
          })
        : undefined;

      const payload = {
        ...currLoyalty,
        ...values,
        apply_once,
        images,
        group_ids: newGroups,
        instant_rewards_groups: newGroups,
      };
      try {
        const res = await api.loyalty.editLoyaltyProgram(
          currLoyalty.id,
          payload
        );

        if (res && res.status === 200) {
          setIsLoading(false);
          form.resetFields();
          Modal.success({
            title: strings.loyaltyProgramEdited,
            content: strings.thisLoyaltyProgramEditedSuccessfully,
            onOk: () => {
              toggleLoyaltyDetailModal();
              closeModal();
            },
          });
          getAllLoyaltyPrograms();
        }
      } catch (error) {
        setIsLoading(false);
        message.error("An error occured, please try again later");
      }
    });
  };

  const fetchLoyaltyId = () => {
    api.HttpClient.get("/customer_groups")
      .then(res => {
        if (res.status && res.status === 200) {
          const assignedIds = res.data.filter(id => id.status !== null);
          return setLoyaltyIds(assignedIds);
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };


  const handleProductPicture = async (files, fileLists) => {
    if (files.size > maxFileSize) {
      message.warning(strings.fileTooLargeLessThan4Mb);
      return;
    }

    if(images.length >= 3){
      message.warning("cannot add more than three images");
      return;
    }

    setLoadingImg(true);
    const formData = new FormData();
    formData.append("file", files)
    formData.append("upload_preset", uploadPreset)

    const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
    {
      method: "POST",
      body: formData
    })
    
    const parsed = await response.json();
    
    setLoadingImg(false);
    setImages((prev)=> {
      return [...prev, parsed.secure_url]
    })
    

    setLoyaltyImage((prev)=>{
      return [...prev, files]
    })
    return false;
  };

  const handleRemoveProductPicture = file => {
    const index = loyltyImage.indexOf(file);

    setLoyaltyImage((prev)=>{
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return newFileList;
    });

    setImages((prev)=>{
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return newFileList;
    });
  };

  return (
    <Modal
      title="Edit Loyalty"
      open={isEditLoyaltyModalOpen}
      destroyOnClose
      onCancel={() => {
        Modal.confirm({
          title: strings.doYouWantToCloseDialog,
          cancelText: strings.cancel,
          onOk: closeModal,
        });
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            Modal.confirm({
              title: strings.doYouWantToCloseDialog,
              cancelText: strings.cancel,
              onOk: closeModal,
            });
          }}
        >
          {strings.cancel}
        </Button>,
        <Button
          type="primary"
          key="edit"
          loading={isLoading}
          onClick={handleEditLoyalty}
        >
          {strings.edit}
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <FormItem label={strings.name}>
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: strings.pleaseEnterLoyaltyName,
              },
            ],
          })(<Input size="large" />)}
        </FormItem>

        <FormItem label={strings.programType}>
          {getFieldDecorator("program_type", {
            rules: [
              {
                required: true,
                message: strings.pleaseSelectProgramType,
              },
            ],
          })(
            <Select size="large" disabled>
              <Option value="SimplePoints">{strings.simplePoints}</Option>
              <Option value="Stamps Program">{strings.stampsProgram}</Option>
              <Option value="InstantRewards">Membership Discount</Option>
            </Select>
          )}
        </FormItem>

        {programType !== "InstantRewards" && (
          <FormItem label="Threshold">
            {getFieldDecorator("threshold", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterLoyaltyThreshold,
                },
              ],
            })(<InputNumber size="large" style={{ width: "100%" }} />)}
          </FormItem>
        )}
        {programType !== "InstantRewards" && (
          <FormItem
            label={strings.whatIsTheReward}
            extra={strings.egNextPurchaseIsFree}
          >
            {getFieldDecorator("reward", {
              rules: [
                {
                  required: true,
                  message: strings.reward,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>
        )}
        {programType !== "InstantRewards" && (
          <FormItem
            label={"Images"}
            extra={"You can add up to three images"}
          >
            {getFieldDecorator("images", {
              rules: [
                {
                  required: false,
                },
              ],
            })(<>
              <Upload
                {...uploadProps}
                fileList={loyltyImage}
                beforeUpload={handleProductPicture}
                onRemove={handleRemoveProductPicture}
                multiple
              >
                <Button loading={loadingImg}>
                  <UploadOutlined /> Upload Image
                </Button>
              </Upload>
            </>)}
            {images && images.map((imag)=>{
              return <Image src={imag} alt={imag} height={150} width={150}/>
            })}
          </FormItem>
        )}

        {programType === "InstantRewards" && (
          <FormItem label={strings.whatIsTheReward}>
            {getFieldDecorator("reward", {
              initialValue: "discount",
              rules: [
                {
                  required: true,
                  message: "Enter reward type",
                },
              ],
            })(
              <Select size="large">
                <Option value="discount">Discount</Option>
                <Option disabled value="airtime">
                  Airtime
                </Option>
                <Option disabled value="doublePoints">
                  Double points
                </Option>
              </Select>
            )}
          </FormItem>
        )}
        {programType === "InstantRewards" && (
          <FormItem label="Discount Value" extra="Enter discount percentage">
            {getFieldDecorator("reward_value", {
              rules: [
                {
                  required: true,
                  message: "Please enter a discount value",
                },
              ],
            })(<Input size="large" type="number" />)}
          </FormItem>
        )}
        {programType === "InstantRewards" && (
          <FormItem label="Frequency of Redemption ">
            {getFieldDecorator("eligibility_period", {
              rules: [
                {
                  required: true,
                  message: "Please select a frequency",
                },
              ],
            })(
              <Select
                size="large"
                onChange={val => val === -1 && setShowCustomDays(true)}
              >
                <Option value={null}>Select frequency</Option>
                <Option value={0}>Infinite</Option>
                <Option value={1}>Once daily</Option>
                <Option value={2}>Once in 2 days</Option>
                <Option value={3}>Once in 3 days</Option>
                <Option value={7}>Once weekly</Option>
                <Option value={30}>Once monthly</Option>
                <Option value={-1}>Custom</Option>
              </Select>
            )}
          </FormItem>
        )}

        {showCustomDays && (
          <FormItem>
            <span>
              {" "}
              Once every
              {getFieldDecorator("custom_eligibility_period", {
                rules: [
                  {
                    required: true,
                    message: "Please select a frequency",
                  },
                ],
              })(
                <InputNumber style={{ margin: "0 10px" }} size="large" />
              )}{" "}
              days
            </span>
          </FormItem>
        )}

        {programType === "InstantRewards" && (
          <FormItem label="Apply Loyalty To">
            {getFieldDecorator("apply_to", {
              initialValue: applyTo,
              rules: [
                {
                  required: true,
                  message:
                    "Confirm if loyalty sould be applied to all customers or some.",
                },
              ],
            })(
              <Radio.Group onChange={e => setApplyTo(e.target.value)}>
                <Radio value="everyone"> Everyone</Radio>
                <Radio value="set"> Set</Radio>
              </Radio.Group>
            )}
          </FormItem>
        )}

        {applyTo === "set" && (
          <FormItem label="Customer Groups">
            {getFieldDecorator("group_ids", {
              initialValue: initialGroups,
            })(
              <Select
                allowClear
                mode="multiple"
                showSearch
                placeholder="Select assigned IDs"
                optionFilterProp="children"
                size="large"
                style={{ width: "80%" }}
                filterOption={(input, option) => {
                  return (
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    (option.props.id && option.props.id.indexOf(input) >= 0)
                  );
                }}
                disabled={false}
              >
                {loyaltyIds.map(loyaltyCode => (
                  <Option
                    key={loyaltyCode.name}
                    value={loyaltyCode.id}
                    disabled={
                      rewardsDetailsObj[
                        `${loyaltyCode.name} (${loyaltyCode.customers.length} customers)`
                      ]
                        ? true
                        : false
                    }
                  >
                    {loyaltyCode.name} ({loyaltyCode.customers.length}{" "}
                    customers)
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        )}
        {programType === "InstantRewards" && (
          <FormItem label="Verify with OTP">
            {getFieldDecorator("verify_otp", {
              rules: [
                {
                  required: true,
                  message:
                    "Confirm if verification is needed before redeeming rewards",
                },
              ],
            })(<Switch defaultChecked={currLoyalty.verify_otp} size="large" />)}
          </FormItem>
        )}
        {programType === "InstantRewards" && (
          <FormItem label="Activate Loyalty">
            {getFieldDecorator("active")(
              <Switch defaultChecked={currLoyalty.active} size="large" />
            )}
          </FormItem>
        )}
        <FormItem label={strings.creditOneStampPerVisit}>
          {getFieldDecorator("apply_once", {
            rules: [
              {
                required: false,
                message: strings.creditOneStampPerVisit,
              },
            ],
          })(<Checkbox  value={apply_once} onChange={(e)=>setApply_once(e.target.checked)}/>)}
        </FormItem>
      </Form>
    </Modal>
  );
};

const EditLoyaltyForm = Form.create()(EditLoyaltyModal);

export default EditLoyaltyForm;
