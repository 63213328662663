import { Tag } from "antd";
import React from "react";
import strings from "../../../../../../strings";

export const getLoyaltyTableColumns = pathname => [
  {
    title: strings.nameOfLoyalty,
    dataIndex: "name",
    key: "name",
    render: record => (record ? <span>{record}</span> : <span>N/A</span>),
  },
  {
    title: strings.numberOfTiers,
    dataIndex: "noOfTiers",
    key: "noOfTiers",
    render: record => (record ? <span>{record}</span> : <span>N/A</span>),
  },
  {
    title: strings.amountToPointsRatio,
    dataIndex: "amountToPointsRatio",
    key: "amountToPointsRatio",
    render: record => (record ? <span>{record}</span> : <span>N/A</span>),
  },
  {
    title: strings.status,
    key: "status",
    dataIndex: "status",
    render: status => {
      let color;
      status === "Inactive" ? (color = "volcano") : (color = "green");
      return (
        <Tag color={color}>
          <span style={{ textTransform: "uppercase" }}>{status}</span>
        </Tag>
      );
    },
  },
  // {
  //   title: "Action",
  //   key: "action",
  //   align: "center",
  //   render: (record) => (
  //     <Fragment>
  //       <Button type="primary" id="btn-component">
  //         <Link to={`${pathname}/${record.id}/enroll`}>Enroll Members</Link>
  //       </Button>
  //       <Button type="primary">
  //         <Link to={`${pathname}/${record.id}`}>View Tier</Link>
  //       </Button>
  //     </Fragment>
  //   ),
  // },
];
