import React, { Fragment } from "react";
import numberFormatter from "../../../../utils/numberFormatter";
import "../SalesReceipt/index.scss";
import strings from "../../../../strings";

class SalesBill extends React.Component {
  calculateVat(transactions) {
    let result = 0;
    transactions.forEach((transaction) => {
      if (transaction.tax_rate || transaction.product?.tax_rate) {
        result +=
          Number(
            transaction.product?.variant?.price ||
              (transaction.variants && transaction.variants[0]?.price) ||
              transaction.product?.original_price ||
              transaction.original_price
          ) *
          Number(transaction.quantity) *
          (Number(transaction.product?.tax_rate || transaction.tax_rate || 0) /
            100);
      }
    });
    return result;
  }

  render() {
    const {
      transaction,
      saleTotal,
      user,
      selectedCustomer,
      customer,
      customersList,
      currency,
      discountAmount,
      hasDiscount,
    } = this.props;

    // OBJECTIVE:- Trying to get customer details when selectedCustomer is not chosen. e.g When viewing the Transaction Detail on the Admin Dashboard

    // Get Customer Details from Transaction
    const customerDetails =
      Array.isArray(customersList) &&
      customersList.length &&
      customersList.filter(
        (customer) => customer.id === transaction[0].customer_id
      );

    const loyalties =
      customer && customer.id
        ? [
            ...customer.loyalty_points.simple_points,
            ...customer.loyalty_points.stamps_program,
          ]
        : Array.isArray(customerDetails) &&
          customerDetails.length && [
            ...customerDetails[0].loyalty_points.simple_points,
            ...customerDetails[0].loyalty_points.stamps_program,
          ];

    let currentLoyalty = [];

    Array.isArray(loyalties) &&
      loyalties.length &&
      loyalties.forEach((loyalty) => {
        if (
          transaction.find(
            (trans) =>
              trans.merchant_loyalty_program_id ===
              loyalty.merchant_loyalty_program_id
          )
        ) {
          currentLoyalty.push(loyalty);
        }
      });

    const businessName = user
      ? typeof user.role === "object"
        ? user?.employer.business_name/*should be business_name*/
        : user.business_name
      : "";

    const businessContact = user
      ? user.contact_number
        ? user.contact_number
        : user.username        /*should be name*/
      : "";

    const businessAddress = user
      ? user.address_line1
        ? user.address_line1
        : user.business_branch
        ? user.business_branch.address_line1
        : ""
      : "";

    const businessAddressLine2 = user
      ? user.address_line2
        ? user.address_line2
        : user.business_branch
        ? user.business_branch.address_line2
        : ""
      : "";

    const cashierName = user
      ? user.first_name
        ? user.first_name
        : user.username
      : "";

    // const loyalty_balance =
    //   selectedCustomer || customer || customerDetails[0] ? (
    //     <Fragment>
    //       {currentLoyalty.length
    //         ? currentLoyalty.map((loyalty, key) => {
    //             const {
    //               accumulated_points,
    //               accumulated_stamps,
    //               program_name,
    //               threshold,
    //               reward,
    //             } = loyalty;

    //             const pointsBalanceToRedeem = threshold - accumulated_points;

    //             const stampsBalanceToRedeem = threshold - accumulated_stamps;

    //             if (accumulated_points) {
    //               return (
    //                 <span key={key}>
    //                   {/* Hello{" "}
    //                   {(customer && customer.first_name) ||
    //                     (customerDetails.length &&
    //                       customerDetails[0].first_name)}
    //                   ,  */}
    //                   You have {accumulated_points}pts on the {program_name}{" "}
    //                   offer. Earn {pointsBalanceToRedeem} more points to redeem{" "}
    //                   {reward}.
    //                 </span>
    //               );
    //             } else {
    //               return (
    //                 <span key={key}>
    //                   {/* Hello{" "}
    //                   {(customer && customer.first_name) ||
    //                     (customerDetails.length &&
    //                       customerDetails[0].first_name)}
    //                   ,  */}
    //                   {strings.youHave} {accumulated_stamps}{" "}
    //                   {strings.starsOnThe} {program_name} {strings.offer}.{" "}
    //                   {strings.get}
    //                   {stampsBalanceToRedeem} {strings.moreStarsToRedeem}{" "}
    //                   {reward}.
    //                 </span>
    //               );
    //             }
    //           })
    //         : null}
    //     </Fragment>
    //   ) : null;

    // const hasStampDuty = paidWith === "card" && currency === "NGN";

    // const stampDuty = 50.0;
    const vatToRemove = this.calculateVat(transaction).toFixed(2);

    return (
      <div className="draftbill">
        <div className="address-info">
          {/* company */}
          <div>
            <h5 className="bold">{businessName} </h5>
            <span className="contact-info padding-left padding-right">
              {businessAddress ? businessAddress : user.address}
            </span>
            <br />
            <span className="contact-info padding-left padding-right">
              {businessAddressLine2 ? businessAddressLine2 : user.address}
            </span>
            <div className="contact-info">
              <span>{strings.servedBy}: {cashierName}</span>
            </div>

            {/* <div className="invoice-info">
            <span>Receipt #{saleId}</span>
            <span>
              {purchaseTime} <br />
              Served
              <br /> by {cashierName}
            </span>

            <span>Ref: {payment_reference}</span>
          </div> */}
          </div>
        </div>

        <div className="contact-info-draft">
          <div>
            <span>Customer: </span>
            <span className="">
              {customer
                ? `${customer.first_name} ${customer.last_name}`
                : customerDetails.length
                ? `${customerDetails[0].first_name} ${customerDetails[0].last_name}`
                : selectedCustomer}
            </span>
            <br />
          </div>

          <div className="contact-info">
            <span>{this.props.timeOfTransaction}</span>

            <span className="ml-2">{this.props.dateOfTransaction}</span>
          </div>
        </div>

        <table>
          <tbody>
            {transaction &&
              transaction.map((product, key) => (
                <Fragment key={key}>
                  <tr>
                    <td colSpan="3" className="bold">
                      <div className="padding-left">
                        {product.name || product.product_name}
                      </div>
                      <div>
                        {product.variants && product.variants.length > 0 ? (
                          <div className="image padding-left">
                            {product.variants.map((variant) => {
                              return `${variant.type} : ${variant.value}`;
                            })}
                          </div>
                        ) : null}
                      </div>
                    </td>
                  </tr>

                  <tr className="item-list">
                    <td className="bold" colSpan="1">
                      {product.product_quantity || product.quantity}
                    </td>
                    <td className="bold" colSpan="1">{`@${
                      product.product?.variant?.price ||
                      (product.variants && product.variants[0]?.price) ||
                      product.product?.original_price ||
                      product.original_price
                    }`}</td>
                    <td className="bold padding-right" colSpan="1">{`${
                      currency ? currency : ""
                    }${(
                      Number(
                        product.product?.variant?.price ||
                          (product.variants && product.variants[0]?.price) ||
                          product.product?.original_price ||
                          product.original_price
                      ) * Number(product.product_quantity || product.quantity)
                    ).toFixed(2)}`}</td>
                  </tr>
                </Fragment>
              ))}
          </tbody>
        </table>

        <div className="total-container">
          <ul className="subtotal">
            <li>
              <span>{strings.subTotal}</span>
              <span>
                {currency}
                {numberFormatter(
                  (
                    saleTotal -
                    vatToRemove +
                    Number(discountAmount || 0)
                  ).toFixed(2)
                )}
              </span>
            </li>

            {hasDiscount ? (
              <li>
                <span>{strings.discount}</span>
                <span>
                  {discountAmount ? currency : ""}
                  {discountAmount ? numberFormatter(discountAmount) : "--"}
                </span>
              </li>
            ) : null}

            <li>
              <span>{strings.taxVat}</span>
              <span>
                {currency}
                {vatToRemove}
              </span>
            </li>
            {/* {
              hasStampDuty && (
                  <li>
                    <span>Stamp Duty</span>
                    <span>{currency}{stampDuty}</span>
                  </li>
                )
            } */}
          </ul>
          {/* <div className="cash-container">
            <div className="cash">
              <span>
                {paidWith} {channel && `(${channel})`}
              </span>
              <span>
                {currency}
                {
                  // hasStampDuty ? numberFormatter((parseFloat(saleTotal)+ parseInt(stampDuty))) :
                  hasDiscount
                    ? numberFormatter(saleTotal - discountAmount)
                    : numberFormatter(saleTotal)
                }
              </span>
            </div>
          </div> */}
          <div className="total">
            <h4 className="bold">{strings.total}</h4>
            <h4 className="bold">
              {currency}
              {/* {hasStampDuty ? numberFormatter((parseFloat(saleTotal)+ parseInt(stampDuty))) :  */}
              {hasDiscount
                ? numberFormatter(saleTotal - discountAmount)
                : numberFormatter(saleTotal)}
            </h4>
          </div>
          ( <strong>{strings.unpaid}</strong> )
        </div>

        <div className="footer loyalty-balance">
          <p className="">{strings.callCashierToCompletePayment}.</p>
          <p>---</p>
          <p className="smallfont">POWERED BY LOYSTAR ☺</p>
          <span className="smallfont">WWW.LOYSTAR.CO</span>
        </div>
      </div>
    );
  }
}

export default SalesBill;
