import React from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import { languages } from "../../languages";
import { changeLanguage } from "../../redux/actions/languageActions";
import WithApolloClient from "../../utils/withApolloClient";
import "./index.scss";

const { Option } = Select;
const defaultLanguage = localStorage.getItem("current-language") || "English";

const ChangeLanguage = props => {
  const handleChange = value => {
    props.changeLanguage(value);
    localStorage.setItem("current-language", value);
    window.location.href = window.location.href;
  };
  return (
    <div className="signIn-language-select">
      <Select defaultValue={defaultLanguage} onChange={handleChange}>
        {languages.map(language => (
          <Option value={language.name} key={language.language}>
            {language.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

const mapStateToProps = state => ({
  language: state.language,
});

export default connect(mapStateToProps, { changeLanguage })(
  WithApolloClient(ChangeLanguage)
);
