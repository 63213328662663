import React, { Fragment } from "react";
import strings from "../../../../strings";
import numberFormatter from "../../../../utils/numberFormatter";
import "./index.scss";

class SalesReceipt extends React.Component {
  calculateVat(transactions) {
    let result = 0;
    transactions.forEach((transaction) => {
     if (transaction.tax_rate || transaction.product?.tax_rate) {
        result +=
          Number(
            transaction.product?.variant?.price ||
              (transaction.variants && transaction.variants[0]?.price) ||
              transaction.product?.original_price ||
              transaction.original_price
          ) *
          Number(transaction.quantity) *
          (Number(transaction.product?.tax_rate || transaction.tax_rate || 0) /
            100);
      }
    });
    return result;
  }

  render() {
    const {
      transaction,
      saleTotal,
      user,
      saleId,
      purchaseTime,
      currency,
      paidWith,
      selectedCustomer,
      customer,
      customersList,
      channel,
      payment_reference,
      hasDiscount,
      discountAmount,
    } = this.props;
    // OBJECTIVE:- Trying to get customer details when selectedCustomer is not chosen. e.g When viewing the Transaction Detail on the Admin Dashboard

    // Get Customer Details from Transaction
    const customerDetails =
      Array.isArray(customersList) &&
      customersList.length &&
      transaction.length &&
      customersList.filter(
        (customer) => customer.id === transaction[0].customer_id
      );

    const loyalties =
      customer && customer.id
        ? [
            ...customer.loyalty_points.simple_points,
            ...customer.loyalty_points.stamps_program,
          ]
        : Array.isArray(customerDetails) &&
          customerDetails.length && [
            ...customerDetails[0].loyalty_points.simple_points,
            ...customerDetails[0].loyalty_points.stamps_program,
          ];

    let currentLoyalty = [];

    Array.isArray(loyalties) &&
      loyalties.length &&
      loyalties.forEach((loyalty) => {
        if (
          transaction.find(
            (trans) =>
              trans.merchant_loyalty_program_id ===
              loyalty.merchant_loyalty_program_id
          )
        ) {
          currentLoyalty.push(loyalty);
        }
      });

    const businessName = user
      ? typeof user.role === "object"
        ? user.business_branch.name?.toLowerCase().includes("home")
          ? user.employer.business_name
          : user.business_branch.name
        : user.business_name
      : "";

    const businessContact = user
      ? user.employer
        ? user.employer.contact_number
          ? user.employer.contact_number
          : user.employer.email
        : user.contact_number
        ? user.contact_number
        : user.email
      : "";

    const businessAddress = user
      ? user.address_line1
        ? user.address_line1
        : user.business_branch
        ? user.business_branch.address_line1
        : ""
      : "";

    const businessAddressLine2 = user
      ? user.address_line2
        ? user.address_line2
        : user.business_branch
        ? user.business_branch.address_line2
        : ""
      : "";

    
    const cashierName = user
      ? user.first_name
        ? user.first_name
        : user.username
      : "";

    const fullname = customer
      ? `${customer.first_name} ${customer.last_name}`
      : customerDetails.length &&
        `${customerDetails[0].first_name} ${customerDetails[0].last_name}`;

    const customerName = selectedCustomer || fullname;

    const loyalty_balance =
      selectedCustomer || customer || customerDetails[0] ? (
        <Fragment>
          {currentLoyalty.length
            ? currentLoyalty.map((loyalty, key) => {
                const {
                  accumulated_points,
                  accumulated_stamps,
                  program_name,
                  threshold,
                  reward,
                } = loyalty;

                const pointsBalanceToRedeem = threshold - accumulated_points;

                const stampsBalanceToRedeem = threshold - accumulated_stamps;

                if (accumulated_points) {
                  if (pointsBalanceToRedeem < 0) {
                    return (
                      <span key={key}>
                        Dear{" "}
                        {selectedCustomer ||
                          (customer && customer.first_name) ||
                          customerDetails[0].first_name}
                        , Well done! You are due to redeem {reward}. Show code{" "}
                        {(customer && customer.token) ||
                          customerDetails[0].token}{" "}
                        to get your reward on your next visit. Thank you :)
                      </span>
                    );
                  } else {
                    return (
                      <span key={key}>
                        You have {accumulated_points}pts on the {program_name}{" "}
                        offer. Earn {pointsBalanceToRedeem} more points to
                        redeem {reward}.
                      </span>
                    );
                  }
                } else {
                  if (stampsBalanceToRedeem < 0) {
                    return (
                      <span key={key}>
                        Dear{" "}
                        {selectedCustomer ||
                          (customer && customer.first_name) ||
                          customerDetails[0].first_name}
                        , Well done! You are due to redeem {reward}. Show code{" "}
                        {(customer && customer.token) ||
                          customerDetails[0].token}{" "}
                        to get your reward on your next visit. Thank you :)
                      </span>
                    );
                  } else {
                    return (
                      <span key={key}>
                        You have {accumulated_stamps}stars on the {program_name}{" "}
                        offer. Get
                        {stampsBalanceToRedeem} more stars to redeem {reward}.
                      </span>
                    );
                  }
                }
              })
            : null}
        </Fragment>
      ) : null;

    const hasStampDuty = paidWith === "card" && currency === "NGN";
    const stampDuty = 50.0;
    const vatToRemove = this.calculateVat(transaction).toFixed(2);

    // console.log({ transaction });

    return (
      <div id="receipt">
        <div className="header">
          {user.image && (
            <img src={user.image} style={{ width: 50 }} alt="merchant logo" />
          )}
          {user.employer && user.employer.image && (
            <img src={user.employer.image} style={{ width: 50 }} alt="merchant logo" />
          )}
          <h5 className="bold">{businessName}</h5>
          <span className="contact-info padding-left padding-right">
            {businessAddress ? businessAddress : user.address}
          </span>
          <br />
          <span className="contact-info padding-left padding-right">
            {businessAddressLine2 ? businessAddressLine2 : user.address}
          </span>
          <div className="contact-info">
            <span>{businessContact}</span>
          </div>
          <div className="invoice-info">
            <span>
              {strings.receipt} #{saleId}
            </span>
            <span>
              {purchaseTime} <br />
              {strings.servedBy}
              <br /> {cashierName}
            </span>

            <span>
              {strings.customer}: {customerName || ""}
            </span>

            <span>
              {strings.reference}: {payment_reference}
            </span>
          </div>
        </div>

        <table>
          <tbody>
            {transaction &&
              transaction.map((product, key) => (
                <Fragment key={key}>
                  <tr>
                    <td colSpan="3" className="bold">
                      <div className="padding-left">
                        {product.name || product.product_name}
                      </div>
                      <div>
                        {product.variants && product.variants.length > 0 ? (
                          <div className="image padding-left">
                            {product.variants.map((variant) => {
                              return `${variant.type} : ${variant.value}`;
                            })}
                          </div>
                        ) : null}
                      </div>

                      <div>
                        {product.custom_quantities && product.custom_quantities.length > 0 ? (
                          <div className="image padding-left">
                            {product.custom_quantities.map((quantity) => {
                              return `${quantity.name}`;
                            })}
                          </div>
                        ) : null}
                      </div>
                    </td>
                  </tr>

                  <tr className="item-list">
                    <td className="bold" colSpan="1">
                      {product.product_quantity || product.quantity}
                    </td>
                    <td className="bold" colSpan="1">{`@${
                      product.product?.variant?.price ||
                      (product.variants && product.variants[0]?.price) || (product.custom_quantities && product.custom_quantities[0]?.price) ||
                      product.product?.original_price ||
                      product.original_price || product.price
                    }`}</td>
                    <td className="bold padding-right" colSpan="1">{`${
                      currency ? currency : ""
                    }${(
                      Number(
                        product.product?.variant?.price ||
                          (product.variants && product.variants[0]?.price) || (product.custom_quantities && product.custom_quantities[0]?.price) ||
                          product.product?.original_price ||
                          product.original_price || product.price
                      ) * Number(product.product_quantity || product.quantity)
                    ).toFixed(2)}`}</td>
                  </tr>
                </Fragment>
              ))}
          </tbody>
        </table>

        <div className="total-container">
          <ul className="subtotal">
            <li>
              <span>{strings.subTotal}</span>
              <span>
                {currency}
                {numberFormatter(
                  (
                    saleTotal -
                    vatToRemove +
                    Number(discountAmount || 0)
                  ).toFixed(2)
                )}
              </span>
            </li>

            {hasDiscount ? (
              <li>
                <span>{strings.discount}</span>
                <span>
                  {discountAmount ? currency : ""}
                  {discountAmount ? numberFormatter(discountAmount) : "--"}
                </span>
              </li>
            ) : null}

            <li>
              <span>{strings.taxVat}</span>
              <span>
                {currency}
                {vatToRemove}
              </span>
            </li>
            {/* {
              hasStampDuty && (
                  <li>
                    <span>Stamp Duty</span>
                    <span>{currency}{stampDuty}</span>
                  </li>
                )
            } */}
          </ul>

          <div className="cash-container">
            <div className="cash">
              <span>
                {paidWith} {channel && `(${channel})`}
              </span>
              <span>
                {currency}
                {numberFormatter(saleTotal)}
              </span>
            </div>
          </div>
          <div className="total">
            <h4 className="bold">{strings.total}</h4>
            <h4 className="bold">
              {currency}
              {/* {hasStampDuty ? numberFormatter((parseFloat(saleTotal)+ parseInt(stampDuty))) :  */}
              {numberFormatter(
                (Number(saleTotal) + Number(discountAmount || 0)).toFixed(2)
              )}
            </h4>
          </div>
        </div>

        <div className="footer loyalty-balance">
          <p className="">
            {strings.thankYouForPatronage}
            {(customer && ` ${customer.first_name}`) ||
              (customerDetails.length && ` ${customerDetails[0].first_name}`) ||
              ""}
            . {loyalty_balance}
          </p>
          <p>---</p>
          <p className="smallfont">
            POWERED BY {user.email.match("ulesson") ? "ULESSON" : "LOYSTAR"} ☺
          </p>
          <span className="smallfont">
            WWW.{user.email.match("ulesson") ? "ULESSON.COM" : "LOYSTAR.CO"}
          </span>
        </div>
      </div>
    );
  }
}

export default SalesReceipt;
