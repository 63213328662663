import {
  Select,
  Button,
  Modal,
  message,
  Form,
  DatePicker,
  Radio,
  InputNumber,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import {
  GET_WORK_HOURS,
  GET_BOOKINGS,
  CREATE_BOOKING,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";
import moment from "moment";
import strings from "../../../../../../strings";
import AddCustomerModal from "../../../Customers/_partials/AddCustomerModal";
const { Option } = Select;

const BookingCreateForm = ({
  visible,
  customers,
  onCancel,
  branches,
  services,
  branchObj,
  currentDate,
}) => {
  const [staffs, setStaffs] = useState([]);
  const [showRepeat, setShowRepeat] = useState(false);
  const [repeatFreq, setRepeatFreq] = useState(0);
  const [repeatNumber, setRepeatNumber] = useState(0);
  const [ showAddCustomerModal, setShowAddCustomerModal ] = useState(false);
  const { data } = useQuery(GET_WORK_HOURS);

  const [form] = Form.useForm();

  const disabledDate = current => {
    if (
      current <
      moment()
        .endOf("day")
        .subtract(1, "d")
    )
      return true;
    const dayOfWeek = moment(current)
      .format("dddd")
      .toLocaleLowerCase();
    return !data?.getWorkHours[dayOfWeek];
  };

  const getDisabledHours = timeString => {
    if (!timeString) return [];
    const [openTime, closeTime] = timeString.split(" - ");
    const upper = moment(closeTime.trim(), "hh:mma").hours();
    const lower = moment(openTime.trim(), "hh:mma").hours();
    return getHourRange(upper, lower);
  };

  const openAddCustomerModal = () => {
    setShowAddCustomerModal(true);
  };

  const onCustomerSearch = (value) => {
    if(value === "add") openAddCustomerModal()
  }

  const getHourRange = (upper, lower) => {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      if (lower > i || i > upper) hours.push(i);
    }
    return hours;
  };

  const disabledDateTime = current => {
    const dayOfWeek = moment(current)
      .format("dddd")
      .toLowerCase();
    const availableHours = data?.getWorkHours[dayOfWeek];

    return {
      disabledHours: () => getDisabledHours(availableHours),
    };
  };

  const handleSelect = val => setStaffs(branchObj[val]?.staff);

  const [createBooking, { loading: isCreateBookingLoading }] = useMutation(
    CREATE_BOOKING,
    {
      onCompleted: data => {
        form.resetFields();

        data.createBooking &&
          Modal.success({
            title: "Booking created successfully",
            onOk: onCancel,
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: GET_BOOKINGS }],
    }
  );

  const handleCreateBooking = () => {
    form
      .validateFields()
      .then(values => {
        const booking_date = moment(values.booking_date).format(
          "DD-MM-YYYY   hh:mma"
        );
        if (
          moment(values.booking_date) <
          moment()
            .endOf("day")
            .subtract(1, "d")
        ) {
          return Modal.error({
            title: "Cannot book past date",
            content: "You can only book appointments for future dates",
          });
        }
        const branch_id = values.branch === "any" ? 0 : values.branch;
        const branch_name = branch_id ? branchObj[values.branch].name : "Any";
        const service_id = values.service;
        const service = services.find(serv => serv.id === values.service);
        const {
          service_name,
          amount_charged,
          completion_time,
          loyalty_id,
        } = service;
        const staff =
          values.staff === "any"
            ? "Any"
            : staffs.find(staf => staf.id === values.staff);
        const getStaffDetails = staff => {
          if (staff === "Any") return { staff_name: "Any" };
          else {
            const {
              id: staff_id,
              username: staff_name,
              email: staff_email,
            } = staff;
            return { staff_name, staff_email, staff_id };
          }
        };
        const { customer_id, repeat } = values;
        const createBookingObj = {
          customer_id,
          service_id,
          service_name,
          amount_charged,
          completion_time,
          loyalty_id: Number(loyalty_id),
          branch_name,
          branch_id,
          repeat,
          repeat_frequency: repeatFreq,
          repeat_number: repeatNumber,
          booking_date,
          ...getStaffDetails(staff),
        };

        return createBooking({ variables: { data: createBookingObj } });
      })
      .catch(err => console.log(err));
  };

  return (
    <><Modal
      open={visible}
      title="Create Booking"
      onCancel={onCancel}
      footer={[
        <Button
          type="primary"
          onClick={handleCreateBooking}
          loading={isCreateBookingLoading}
        >
          Create
        </Button>,
        <Button onClick={onCancel} type="ghost">
          Cancel
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          name="customer_id"
          label="Select Customer"
          rules={[
            {
              required: true,
              message: "Please select customer!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Select Customer"
            optionFilterProp="children"
            size="large"
            style={{ width: "80%" }}
            filterOption={(input, option) => {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                (option.props.id && option.props.id.indexOf(input) >= 0)
              );
            }}
            onChange={onCustomerSearch}
            disabled={false}
          >
            <Option key="add">{strings.addNewCustomer}</Option>
            {customers &&
              customers.map(customer => (
                <Option
                  key={customer.user_id}
                  value={customer.id}
                  id={customer.loyalty_id}
                >
                  {`${customer.first_name ? customer.first_name : "N/A"} ${
                    customer.last_name ? customer.last_name : ""
                  } ${customer.phone_number ? ":" + customer.phone_number : ""}
                  `}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="service"
          label="Select Service"
          rules={[
            {
              required: true,
              message: "Please select service to be booked!",
            },
          ]}
        >
          <Select
            placeholder="Select Service"
            size="large"
            style={{ width: "80%" }}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                (option.props.id && option.props.id.indexOf(input) >= 0)
              );
            }}
          >
            {services &&
              services.map(service => (
                <Option key={service.id} value={service.id} id={service.id}>
                  {service.service_name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="branch"
          label="Select Branch"
          rules={[
            {
              required: true,
              message: "Please select branch to be booked!",
            },
          ]}
        >
          <Select
            placeholder="Select Branch"
            size="large"
            style={{ width: "80%" }}
            onSelect={handleSelect}
          >
            <Option value="any" key="any" id="any">Any</Option>
            {branches &&
              branches.map(branch => (
                <Option key={branch.id} value={branch.id} id={branch.id}>
                  {branch.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="staff"
          label="Select Staff"
          rules={[
            {
              required: true,
              message: "Please assign staff!",
            },
          ]}
        >
          <Select
            placeholder="Select Staff"
            size="large"
            style={{ width: "80%" }}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                (option.props.id && option.props.id.indexOf(input) >= 0)
              );
            }}
          >
            <Option value="any">Any</Option>
            {staffs &&
              staffs.map(staff => (
                <Option key={staff.id} value={staff.id} id={staff.id}>
                  {`${staff.username} (${staff.role.name})`}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="booking_date"
          label="Booking Date"
          rules={[{ required: true, message: "Choose appointment date!" }]}
          initialValue={currentDate ? moment(currentDate) : ""}
        >
          <DatePicker
            size="large"
            showTime
            showNow={false}
            format="DD-MM-YYYY   hh:mma"
            // disabledDate={disabledDate}
            // disabledTime={disabledDateTime}
          />
        </Form.Item>

        <Form.Item
          name="repeat"
          label="Repeat Booking"
          rules={[{ required: true, message: "Repeat booking?" }]}
        >
          <Radio.Group
            onChange={e => setShowRepeat(e.target.value)}
            value={showRepeat}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {showRepeat && (
          <Form.Item name="repeat_frequency" label="Repeat every">
            <InputNumber min={1} onChange={val => setRepeatFreq(val)} /> week(s)
          </Form.Item>
        )}
        {showRepeat && (
          <Form.Item name="repeat_number" label="Repeat">
            <InputNumber onChange={val => setRepeatNumber(val)} min={1} />{" "}
            time(s)
          </Form.Item>
        )}
      </Form>
    </Modal>
    {showAddCustomerModal && <AddCustomerModal
      showModal={showAddCustomerModal}
      closeModal={()=>setShowAddCustomerModal(false)}
    />}
  </>
  );
};

export default BookingCreateForm;
