import React from "react";
import { connect } from "react-redux";
import { Button, Card, Input, Modal, message } from "antd";
import { Icon } from "@ant-design/compatible";
import { 
  SearchOutlined, 
  DeleteOutlined
} from "@ant-design/icons";
import moment from "moment";
import CustomDataTable from "../../../../components/CustomDataTable";
import Highlighter from "react-highlight-words";
import ViewDraftDetails from "./_partials/ViewDraftDetails";
import DeleteDraftSalesForm from "./_partials/DeleteDraftSales";
import strings from "../../../../strings";
import { addProductToCart } from "./functions";
import AddProductToDraftModal from "./_partials/ProductModal";
import "./index.scss";

const parseReport = function(data) {
  const result = data.map(report => {
    const tempReport = { ...report };
    tempReport.createdDate = report.created_at.split("T")[0];
    tempReport.createdTime = moment(report.created_at).format("h:mm:ss A");
    tempReport.paymentType = report.is_paid_with_card
      ? "Card"
      : report.is_paid_with_cash
      ? "Cash"
      : "Mobile";
    return tempReport;
  });
  return result.sort((a, b) => moment(a.created_at) < moment(b.created_at));
};
class DraftSales extends React.Component {
  state = {
    draftSales: [],
    loading: false,
    isViewDraftModalOpen: false,
    isDeleteDraftModalOpen: false,
    bill: {},
    isProductModalOpen: false,
    selectedRowKeys: [],
    isBulkDeleteModalVisible: false
  };

  addProductToCart = addProductToCart.bind(this);

  componentDidMount() {
    const { user } = this.props;

    this.setState({ loading: true });
    const draftSales = JSON.parse(localStorage.getItem("draftSales"));

    if (draftSales && draftSales.length) {
      //delete all dratfs sales greater than 60 days
      const merchantSales = draftSales.filter(draft => draft.merchant_id === user.id);

      const draftSalesLessThanSixtyDays = merchantSales.filter(draft => this.getSixtyDaysBeforeNow(draft.created_at) === false);

      localStorage.setItem("draftSales", JSON.stringify(draftSalesLessThanSixtyDays));
      this.setState({
        draftSales: draftSalesLessThanSixtyDays,
      });
    } else {
      this.setState({ draftSales: [] });
    }
    this.setState({ loading: false });
  }

  getSixtyDaysBeforeNow(date) {
    const now = new Date();
    const createdDate = new Date(date).getTime();
    
    const sixtyDays = 5184000000;

    const dateDifference = now.getTime() - createdDate;

    if(dateDifference > sixtyDays) return true;
    
    return false;
  }

  toggleViewDraftModal = () => {
    this.setState({
      isViewDraftModalOpen: !this.state.isViewDraftModalOpen,
    });
  };

  toggleDeleteDraftModal = () => {
    this.setState({
      isDeleteDraftModalOpen: !this.state.isDeleteDraftModalOpen,
    });
  };

  updateDraftSales = async () => {
    try {
      const isDraftSales = localStorage.getItem("draftSales");

      const upDatedDraftSales = isDraftSales && JSON.parse(isDraftSales);

      this.setState({
        draftSales: upDatedDraftSales,
      });
    } catch (error) {
      console.log(error);
      Promise.reject(error);
      message.error(strings.anErrorOccured, 5);
    }
  };

  deleteDraftSales = async id => {
    try {
      const { draftSales } = this.state;

      const latestDrafts = draftSales.filter(draft => draft.id !== id);

      // console.log({ latestDrafts });

      localStorage.setItem("draftSales", JSON.stringify(latestDrafts));

      this.setState({
        draftSales: latestDrafts,
      });
    } catch (error) {
      console.log(error);
      Promise.reject(error);
      message.error(strings.anErrorOccured, 5);
    }
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`${strings.search} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {strings.search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          {strings.reset}
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!record[dataIndex]) return;

      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state[dataIndex]]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  toggleBulkDeleteModal = () => {
    this.setState({
      isBulkDeleteModalVisible: !this.state.isBulkDeleteModalVisible,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  handleDeleteDrafts = () => {
    const { user } = this.props;

    let value;
    return Modal.info({
      title: strings.pleaseTypeIn + " delete " + strings.toConfirm,
      content: (
        <Input 
          onChange={(e) => value = e.target.value}
          value={value}
        />
      ),
      okCancel: true,
      onOk: () => {
        if(value !== "delete") return message.info("Type delete to continue");

        const drafts = JSON.parse(localStorage.getItem("draftSales"));

        const savedDrafts = drafts.filter(draft => !this.state.selectedRowKeys.includes(draft.id));

        localStorage.setItem("draftSales", JSON.stringify(savedDrafts));

        this.setState({
          draftSales: savedDrafts.filter(draft => draft.merchant_id === user.id),
          selectedRowKeys: []
        });
      }
    })
    
  }

  render() {
    const { 
      isViewDraftModalOpen, 
      isDeleteDraftModalOpen,
      isProductModalOpen,
      selectedRowKeys 
    } = this.state;

    const { user } = this.props;

    const columns = [
      {
        title: strings.customer,
        dataIndex: "formattedCustomerName",
        render: record =>
          record ? <span>{record}</span> : <span>Guest Customer</span>,
        ...this.getColumnSearchProps("formattedCustomerName"),
      },
      {
        title: strings.amount,
        dataIndex: "total",
        key: "total",
      },
      {
        title: strings.status,
        dataIndex: "status",
        key: "status",
      },
      {
        title: strings.createdDate,
        dataIndex: "createdDate",
        render: record => (record ? <span>{record}</span> : <span>N/A</span>),
        sorter: (a, b) => {
          return moment(a.createdDate) < moment(b.createdDate);
        },
      },
      {
        title: strings.createdTime,
        dataIndex: "createdTime",
        render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      },
      {
        title: strings.servedBy,
        dataIndex: "staff",
        render: record =>
          record ? <span>{record.username}</span> : <span>Admin</span>,
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;
    const selectOne = selectedRowKeys.length === 1;
    const isAdmin = user.role_id && user.role_id === 1;

    const dataSource = parseReport(this.state.draftSales);
    const currency =
      this.props.user.currency ||
      (this.props.user.employer && this.props.user.employer.currency);

    // console.log(dataSource, 'so')
    return (
      <div className="page-container draft-sales">
        <Card 
          bordered={false}
        >
          <section className="subheader-btn">
            {isAdmin && hasSelected && (
              <Button
                icon={<DeleteOutlined />}
                onClick={this.handleDeleteDrafts}
              >
                {strings.deleteAll}
              </Button>
            )}
          </section>
          
          <section>
            <CustomDataTable
              columns={columns}
              dataSource={dataSource.map((sales) => {
                return {
                  ...sales,
                  key: sales.id
                }
              }).sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )}
              loading={this.state.loading}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    this.setState(
                      {
                        bill: record,
                      },
                      () => {
                        this.toggleViewDraftModal();
                      }
                    );
                  },
                };
              }}
              rowSelection={rowSelection}
            />
          </section>

          {isViewDraftModalOpen ? (
            <ViewDraftDetails
              closeModal={this.toggleViewDraftModal}
              isViewDraftModalOpen={isViewDraftModalOpen}
              currentBill={this.state.bill}
              toggleDeleteDraftModal={this.toggleDeleteDraftModal}
              history={this.props.history}
              discountAmount={this.props.draft_bill?.discountAmount}
              has_Discount={this.props.draft_bill?.has_Discount}
              currency={currency}
              updateDraftSales={this.updateDraftSales}
            />
          ) : null}

          {isDeleteDraftModalOpen ? (
            <DeleteDraftSalesForm
              isDeleteDraftModalOpen={isDeleteDraftModalOpen}
              closeModal={this.toggleDeleteDraftModal}
              currentBill={this.state.bill}
              toggleViewDraftModal={this.toggleViewDraftModal}
              deleteDraftSales={this.deleteDraftSales}
            />
          ) : null}

          {isProductModalOpen && 
            <AddProductToDraftModal 
              addProductToCart={this.addProductToCart}
              showModal={isProductModalOpen}
              closeModal={()=>this.setState({ isProductModalOpen: false })}
            />
          }
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  draft_bill: state.sale.draft_bill,
});

export default connect(mapStateToProps, null)(DraftSales);
