import React, { useRef, useState } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_WORK_HOURS } from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";
import { Calendar, Badge, Button, Carousel, Card, Row, Col, Modal } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import BookingDetails from "../Bookings/BookingDetails";
import BookingCreateForm from "../Bookings/CreateBooking";

const DailyBookingDetails = ({
  goBack,
  currDate,
  workHours,
  bookingsObj,
  customers,
  branches,
  branchObj,
  services,
}) => {
  const [currentDate, setCurrentDate] = useState(currDate);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [showCreateBookingModal, setShowCreateBookingModal] = useState(false);
  const [createNewBookingTime, setCreateNewBookingTime] = useState(null);

  //get current day of week and working hours for the day
  const dayOfWeek = moment(currentDate).format("dddd").toLocaleLowerCase();
  const [opening, closing] = !!workHours
    ? workHours[dayOfWeek].split(" - ")
    : [null, null];
  const formattedDate = moment(currentDate).format("dddd, DD MMMM, YYYY");

  //get the bookings for the day
  const openingHours = formattedDate + " " + opening;
  const closingHours = formattedDate + " " + closing;
  const bookingsForTheDay =
    bookingsObj[moment(currentDate).format("DD-MM-YYYY")];

  //push all available hours into an array
  let hourArr = [];
  let openingHourMoment = moment(openingHours, "dddd, DD MMMM, YYYY hh:mma");
  let closingHourMoment = moment(closingHours, "dddd, DD MMMM, YYYY hh:mma");
  while (openingHourMoment < closingHourMoment) {
    hourArr.push(openingHourMoment.format("dddd, DD MMMM, YYYY hh:mma"));
    openingHourMoment.add(1, "h");
  }

  //save bookings for each hour and into otherBookings if not during work hours
  const hourlyBookings = {};
  const otherBookings = [];
  bookingsForTheDay &&
    bookingsForTheDay.forEach((booking) => {
      const bookingTimeMoment = moment(
        booking.booking_date,
        "DD-MM-YYYY   hh:mma"
      );
      const hours = bookingTimeMoment.hours();
      hourlyBookings[hours]
        ? (hourlyBookings[hours] = [...hourlyBookings[hours], booking])
        : (hourlyBookings[hours] = [booking]);

      if (
        hours < moment(openingHours, "dddd, DD MMMM, YYYY hh:mma").hours() ||
        hours > moment(closingHours, "dddd, DD MMMM, YYYY hh:mma").hours()
      ) {
        otherBookings.push(booking);
      }
    });

  const getCustomerName = (id) => {
    const customer = customers && customers.find(cstmr => cstmr.id === id || cstmr.user_id === id);
    // const customer = customers.find((cstmr) => cstmr.id === id);
    return `${customer && customer.first_name ? customer.first_name : "N/A"} ${customer &&
      customer.last_name ? customer.last_name : ""}`;
    // ${customer && customer.phone_number ? ":" + customer.phone_number : ""}
  };

  const title = (
    <Row>
      <Col span={8}>
        <Button onClick={goBack}>Back</Button>
      </Col>
      <Col>
        <Row>
          <Col span={4}>
            <ArrowLeftOutlined
              style={{ margin: "0 20px" }}
              onClick={() => {
                setCurrentDate(moment(currentDate).subtract(1, "d"));
              }}
            />
          </Col>
          <Col span={16}>{formattedDate}</Col>
          <Col span={4}>
            <ArrowRightOutlined
              style={{ margin: "0 20px" }}
              onClick={() => {
                setCurrentDate(moment(currentDate).add(1, "d"));
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
  const createNewBooking = (hour) => {
    if (
      moment(hour, "dddd, DD MMMM, YYYY hh:mma") <
      moment().endOf("day").subtract(1, "d")
    ) {
      return Modal.error({
        title: "Cannot book past date",
        content: "You can only book appointments for future dates",
      });
    } else {
      setShowCreateBookingModal(true);
      setCreateNewBookingTime(moment(hour, "dddd, DD MMMM, YYYY hh:mma"));
    }
  };
  return (
    <Card title={title} bordered={false}>
      {hourArr.length > 0 &&
        hourArr.map((hour) => (
          <Card hoverable style={{ padding: "20px" }}>
            <Row>
              <Col span={6}>
                {moment(hour, "dddd, DD MMMM, YYYY hh:mma").format("hh:mma")}
              </Col>
              <Col>
                {hourlyBookings[
                  moment(hour, "dddd, DD MMMM, YYYY hh:mma").hours()
                ] ? (
                  hourlyBookings[
                    moment(hour, "dddd, DD MMMM, YYYY hh:mma").hours()
                  ].map((booking) => (
                    <Row
                      onClick={() => {
                        setShowDetailsModal(true);
                        setCurrentBooking(booking);
                      }}
                    >
                      <Badge
                        status="success"
                        text={
                          getCustomerName(booking.customer_id) +
                          ": " +
                          booking.service_name +
                          " - " +
                          booking.booking_date.split(" ")[3]
                        }
                      />
                    </Row>
                  ))
                ) : (
                  <em
                    style={{ opacity: "0.5" }}
                    onClick={() => createNewBooking(hour)}
                  >
                    No appointment booked. Click to add
                  </em>
                )}
              </Col>
            </Row>
          </Card>
        ))}
      {otherBookings.length > 0 && (
        <h4 style={{ marginTop: "30px" }}>Other bookings in the day:</h4>
      )}
      {otherBookings.map((booking) => (
        <Card hoverable style={{ padding: "20px" }}>
          <Row
            onClick={() => {
              setShowDetailsModal(true);
              setCurrentBooking(booking);
            }}
          >
            <Col span={6}>
              {moment(booking.booking_date, "DD-MM-YYYY   hh:mma").format(
                "hh:mma"
              )}
            </Col>
            <Col>
              <Row>
                <Badge
                  status="success"
                  text={
                    getCustomerName(booking.customer_id) +
                    " - " +
                    booking.service_name
                  }
                />
              </Row>
            </Col>
          </Row>
        </Card>
      ))}

      {currentBooking && (
        <BookingDetails
          onCancel={() => {
            setShowDetailsModal(false);
            setCurrentBooking(null);
          }}
          currentBooking={currentBooking}
          visible={showDetailsModal}
          customers={customers}
          branchObj={branchObj}
          branches={branches}
          services={services}
        />
      )}

      {showCreateBookingModal && (
        <BookingCreateForm
          visible={showCreateBookingModal}
          onCancel={() => {
            setShowCreateBookingModal(false);
            setCreateNewBookingTime(null);
          }}
          customers={customers}
          services={services}
          branches={branches}
          branchObj={branchObj}
          currentDate={createNewBookingTime}
        />
      )}
    </Card>
  );
};

const CalendarView = ({
  allBookings,
  customers,
  branches,
  branchObj,
  services,
}) => {
  const [currDate, setCurrDate] = useState(null);
  const { data } = useQuery(GET_WORK_HOURS);
  const myCarousel = useRef();

  const bookingsObj = {};
  const monthlyBookings = {};
  allBookings &&
    allBookings.forEach((booking) => {
      const date = booking.booking_date.split(" ")[0];
      const month = moment(date, "DD-MM-YYYY").month();
      monthlyBookings[month]
        ? monthlyBookings[month].push(booking)
        : (monthlyBookings[month] = [booking]);
      bookingsObj[date]
        ? bookingsObj[date].push(booking)
        : (bookingsObj[date] = [booking]);
    });

  function getListData(value) {
    const currentDate = moment(value).format("DD-MM-YYYY");
    if (bookingsObj[currentDate]) return bookingsObj[currentDate];
    else return [];
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <div style={{ overflow: "hidden" }}>
        {listData.length > 0 && (
          <p>
            <Badge
              style={{ opacity: "0.7" }}
              status="success"
              text={
                listData.length + " booking" + (listData.length > 1 ? "s" : "")
              }
            />
          </p>
        )}
      </div>
    );
  }

  function monthCellRender(value) {
    const monthBookings = monthlyBookings[value.month()];
    return monthBookings ? (
      <Badge
        style={{ opacity: "0.7" }}
        status="success"
        text={
          monthBookings.length +
          " booking" +
          (monthBookings.length > 1 ? "s" : "")
        }
      />
    ) : null;
  }

  const showDailyBooking = (val, mode) => {
    console.log({ mode });
    setCurrDate(val);
    myCarousel.current.next();
  };

  return (
    <Carousel ref={myCarousel}>
      <Calendar
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
        onSelect={(val) => showDailyBooking(val)}
        onPanelChange={() => setCurrDate(null)}
      />
      {currDate && (
        <DailyBookingDetails
          goBack={() => {
            setCurrDate(null);
            myCarousel.current.prev();
          }}
          workHours={data?.getWorkHours}
          currDate={currDate}
          bookingsObj={bookingsObj}
          customers={customers}
          branchObj={branchObj}
          branches={branches}
          services={services}
        />
      )}
    </Carousel>
  );
};

export default CalendarView;
