import {
  Select,
  Button,
  Modal,
  message,
  Form,
  DatePicker,
  Radio,
  InputNumber,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import {
  GET_WORK_HOURS,
  GET_BOOKINGS,
  EDIT_BOOKING,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";
import moment from "moment";
const { Option } = Select;
const EditBooking = ({
  onCancel,
  currentBooking,
  customers,
  branchObj,
  branches,
  services,
  visible,
  closeModal,
}) => {
  const [staffs, setStaffs] = useState(
    currentBooking.branch_name.toLowerCase() === "any"
      ? []
      : branchObj[currentBooking.branch_id].staff
  );
  const [showRepeat, setShowRepeat] = useState(currentBooking.repeat);
  const [repeatFreq, setRepeatFreq] = useState(currentBooking.repeat_frequency);
  const [repeatNumber, setRepeatNumber] = useState(
    currentBooking.repeat_number
  );
  const { data } = useQuery(GET_WORK_HOURS);

  const [form] = Form.useForm();

  const disabledDate = current => {
    const dayOfWeek = moment(current)
      .format("dddd")
      .toLocaleLowerCase();
    return !data?.getWorkHours[dayOfWeek];
  };

  const getDisabledHours = timeString => {
    if (!timeString) return [];
    const [openTime, closeTime] = timeString.split(" - ");
    const upper = moment(closeTime.trim(), "hh:mma").hours();
    const lower = moment(openTime.trim(), "hh:mma").hours();
    return getHourRange(upper, lower);
  };

  const getHourRange = (upper, lower) => {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      if (lower > i || i > upper) hours.push(i);
    }
    return hours;
  };

  const disabledDateTime = current => {
    const dayOfWeek = moment(current)
      .format("dddd")
      .toLowerCase();
    const availableHours = data?.getWorkHours[dayOfWeek];

    return {
      disabledHours: () => getDisabledHours(availableHours),
    };
  };

  const handleSelect = val => setStaffs(branchObj[val]?.staff);

  const [editBooking, { loading: isEditBookingLoading }] = useMutation(
    EDIT_BOOKING,
    {
      onCompleted: data => {
        form.resetFields();

        data.editBooking &&
          Modal.success({
            title: "Booking edited successfully",
            onOk: closeModal,
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: GET_BOOKINGS }],
    }
  );

  const handleEditBooking = () => {
    form
      .validateFields()
      .then(values => {
        const booking_date = moment(values.booking_date).format(
          "DD-MM-YYYY   hh:mma"
        );
        const branch_id = values.branch === "Any" ? 0 : values.branch;
        const branch_name = branch_id ? branchObj[values.branch].name : "Any";
        const service_id = values.service;
        const service = services.find(serv => serv.id === values.service);
        const {
          service_name,
          amount_charged,
          completion_time,
          loyalty_id,
        } = service;
        const staff =
          values.staff === "Any"
            ? "Any"
            : staffs.find(staf => staf.id === values.staff);
        const getStaffDetails = staff => {
          if (staff === "Any") return { staff_name: "Any" };
          else {
            const {
              id: staff_id,
              username: staff_name,
              email: staff_email,
            } = staff;
            return { staff_name, staff_email, staff_id };
          }
        };
        const { customer_id, repeat } = values;
        const editBookingObj = {
          customer_id,
          service_id,
          service_name,
          amount_charged,
          completion_time,
          loyalty_id: Number(loyalty_id),
          branch_name,
          branch_id,
          repeat,
          repeat_frequency: repeatFreq,
          repeat_number: repeatNumber,
          booking_date,
          ...getStaffDetails(staff),
        };

        return editBooking({ variables: { id: currentBooking.id, data: editBookingObj } });
      })
      .catch(err => console.log(err));
  };

  const {
    booking_date,
    customer_id,
    repeat,
    repeat_frequency,
    repeat_number,
    service_id: service,
  } = currentBooking;
  const initialValues = {
    booking_date: moment(booking_date, "DD-MM-YYYY hh:mma"),
    branch:
      currentBooking.branch_name.toLowerCase() === "any"
        ? "Any"
        : currentBooking.branch_id,
    customer_id,
    service,
    staff:
      currentBooking.staff_name.toLowerCase() === "any"
        ? "Any"
        : currentBooking.staff_id,
    repeat,
    repeat_frequency,
    repeat_number,
  };

  return (
    <Modal
      visible={visible}
      title="Edit Booking"
      footer={[
        <Button
          type="primary"
          onClick={handleEditBooking}
          loading={isEditBookingLoading}
        >
          Edit
        </Button>,
        <Button onClick={onCancel} type="ghost">
          Cancel
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} initialValues={initialValues}>
        <Form.Item
          name="customer_id"
          label="Select Customer"
          rules={[
            {
              required: true,
              message: "Please select customer!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Select Customer"
            optionFilterProp="children"
            size="large"
            style={{ width: "80%" }}
            filterOption={(input, option) => {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                (option.props.id && option.props.id.indexOf(input) >= 0)
              );
            }}
            disabled={false}
          >
            {customers &&
              customers.map(customer => (
                <Option
                  key={customer.user_id}
                  value={customer.id}
                  id={customer.loyalty_id}
                >
                  {`${customer.first_name ? customer.first_name : "N/A"} ${
                    customer.last_name ? customer.last_name : ""
                  } ${customer.phone_number ? ":" + customer.phone_number : ""}
    `}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="service"
          label="Select Service"
          rules={[
            {
              required: true,
              message: "Please select service toLowerCase() be booked! ?",
            },
          ]}
        >
          <Select
            placeholder="Select Service"
            size="large"
            style={{ width: "80%" }}
          >
            {services &&
              services.map(service => (
                <Option key={service.id} value={service.id} id={service.id}>
                  {service.service_name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="branch"
          label="Select Branch"
          rules={[
            {
              required: true,
              message: "Please select branch toLowerCase() be booked! ?",
            },
          ]}
        >
          <Select
            placeholder="Select Branch"
            size="large"
            style={{ width: "80%" }}
            onSelect={handleSelect}
          >
            <Option value="Any">Any</Option>
            {branches &&
              branches.map(branch => (
                <Option key={branch.id} value={branch.id} id={branch.id}>
                  {branch.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="staff"
          label="Select Staff"
          rules={[
            {
              required: true,
              message: "Please assign staff!",
            },
          ]}
        >
          <Select
            placeholder="Select Staff"
            size="large"
            style={{ width: "80%" }}
          >
            <Option value="Any">Any</Option>
            {staffs &&
              staffs.map(staff => (
                <Option key={staff.id} value={staff.id} id={staff.id}>
                  {`${staff.username} (${staff.role.name})`}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="booking_date"
          label="Booking Date"
          rules={[{ required: true, message: "Choose appointment date!" }]}
        >
          <DatePicker
            size="large"
            showTime
            format="DD-MM-YYYY   hh:mma"
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
          />
        </Form.Item>

        <Form.Item
          name="repeat"
          label="Repeat Booking"
          rules={[{ required: true, message: "Repeat booking?" }]}
        >
          <Radio.Group
            onChange={e => setShowRepeat(e.target.value)}
            value={showRepeat}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {showRepeat && (
          <Form.Item name="repeat_frequency" label="Repeat every">
            <InputNumber
              min={1}
              onChange={val => setRepeatFreq(val)}
              value={repeatFreq}
            />{" "}
            week(s)
          </Form.Item>
        )}
        {showRepeat && (
          <Form.Item name="repeat_number" label="Repeat">
            <InputNumber
              onChange={val => setRepeatNumber(val)}
              min={1}
              value={repeatNumber}
            />{" "}
            time(s)
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default EditBooking;
