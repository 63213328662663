import React, { useState, useEffect } from "react";
import { Modal, InputNumber, Button } from "antd";
import { Form } from "@ant-design/compatible";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import api from "../../../../../../api";
import strings from "../../../../../../strings";

const FLUTTERWAVE_PUBLIC_KEY = process.env.REACT_APP_FLUTTERWAVE_KEY;

const SMS_BASE_PRICE = process.env.REACT_APP_SMS_BASE_PRICE; //

const SMSTopUpModal = ({ user, updateSMSBalance, form }) => {
  const [numOfSMSUnits, setNumOfSMSUnits] = useState(null);
  const [isTopUpSuccess, setIsTopUpSuccess] = useState(false);

  useEffect(() => {
    if (isTopUpSuccess) {
      updateSMSPoints(numOfSMSUnits)
        .then((res) => {
          if (res.status === 200) {
            const { current_balance } = res.data;

            form.resetFields();

            Modal.success({
              title: `${strings.youhaveSuccessfullyToppedUp} ${strings.youNowHave} ${current_balance} ${strings.smsUnits}`,
              onOk: () => updateSMSBalance(),
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [isTopUpSuccess]);

  const config = {
    public_key: FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: Date.now(),
    // amount: total,
    currency: user.currency,
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: user.email,
      phonenumber: user.contact_number,
      name: `${user.first_name} ${user.last_name}`,
    },
  };

  const updateSMSPoints = async (numOfSMSUnits) => {
    try {
      if (numOfSMSUnits) {
        const res = await api.merchants.updateSMSPoints(numOfSMSUnits);

        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFlutterPayment = useFlutterwave(config);

  const { getFieldDecorator, validateFields } = form;

  const handlePayment = (e) => {
    e.preventDefault();

    validateFields((errors, values) => {
      if (errors) return;

      setNumOfSMSUnits(values.noOfSMSUnits);

      let topUpAmount = SMS_BASE_PRICE * values.noOfSMSUnits;

      config.amount = Number(topUpAmount.toFixed(2));

      handleFlutterPayment({
        callback: paymentCallback,
        onClose: () => {},
      });
    });
  };

  const paymentCallback = (response) => {
    // console.log(response);
    closePaymentModal();

    setIsTopUpSuccess(true);
  };

  return (
    <Form layout="vertical" onSubmit={handlePayment}>
      <Form.Item label={strings.enterNumberOfSmsUnits}>
        {getFieldDecorator("noOfSMSUnits", {
          rules: [
            {
              required: true,
              type: "number",
              message: strings.pleaseEnterANumericValue,
            },
          ],
        })(<InputNumber className="sms_topup_input" size="large" />)}
      </Form.Item>

      <Button type="primary" htmlType="submit">
        {strings.pay}
      </Button>
    </Form>
  );
};

const SMSTopUpModalForm = Form.create()(SMSTopUpModal);

export default SMSTopUpModalForm;
