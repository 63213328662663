import React, { Component } from "react";
import { connect } from "react-redux";
import { 
  Button, 
  Card, 
  Icon, 
  message, 
  Modal, 
  Popover, 
  Input,
  Select 
} from "antd";
import AccountVerificationModal from "./_partials/AccountVerificationModal";
import { getAllBusinessBranches } from "../../../../redux/actions/branchActions";
import api from "../../../../api";
import strings from "../../../../strings";
import {
  loginToVpay,
  addCluster,
  addTill,
  addCashierTill,
  getCluster,
  getTill,
  getCashierTill
} from "./functions";

const minimum_sms_units = process.env.REACT_APP_MINIMUM_SMS_UNITS;
const Option = Select.Option;
class BankScreen extends Component {
  state = {
    isLoading: false,
    vpayLoading: false,
    cashierVpayLoading: false,
    showAccountVerificationModal: false,
    sms_balance: null,
    isPayoutActive: false,
    bank_account_name: "",
    bank_account_number: "",
    bank_name: "",
    branchesModalOpen: false,
    cahsierModalOpen: false,
    branchesAccountNumber: {},
    actionsContent: "",
    terminalId: "",
    terminalProvider: "",
    terminalLabel: "",
    setTerminal: false,
    terminalLoading: false,
    branchLoading: false,
    allStaff: [],
    selectStaffId: null
  };

  // static getDerivedStateFromProps() {}

  componentDidMount() {
    const {
      bank_account_name,
      bank_account_number,
      bank_name,
      payout_active,
    } = this.props.user;

    this.getMerchantSMSBalance();
    this.props.getAllBusinessBranches();
    this.getAllBranchesNuban();
    this.getALLBranches();

    this.setState({
      isPayoutActive: payout_active,
      bank_account_name,
      bank_account_number,
      bank_name,
    });
  }

  handleSaveVpaySettings = async () => {
    const { 
      selectStaffId, 
      terminalId, 
      terminalLabel, 
      terminalProvider
    } = this.state;

    if( 
      selectStaffId === null || 
      terminalId === "" || 
      terminalLabel === "" || 
      terminalProvider === ""
    ){
      return Modal.error({
        title: "All field must me filled"
      })
    }

    const data = {
      terminal_id: terminalId,
      terminal_provider: terminalProvider,
      terminal_label: terminalLabel
    }

    this.setState({ terminalLoading: true });

    await api.HttpClient.put(`/merchant_cashier/${selectStaffId}/update_paystack_details`, { data })
    .then((res)=>{
      Modal.success({
        title: `${res.data.message}`
      });
      this.setState({ terminalLoading: false, setTerminal: false });
    })
    .catch((err)=>{
      this.setState({ terminalLoading: false });
      Modal.error({
        title: `${err.message}`
      })
    })

  }

  getALLBranches = async () => {
    this.setState({ branchLoading: true });
    const res = await api.branch.getAllBusinessBranches();

    if(res.status === 200){
      let allStaff = [];

      await res.data.map((branch)=>{
        allStaff.push(...branch.staff)
      });

      this.setState({ 
        branchLoading: false,
        allStaff: allStaff 
      });
    }
  }

  getAllBranchesNuban = () =>
    this.props.allBranches &&
    this.props.allBranches.forEach(branch => this.handleGetTill(branch.id));

  generateVpayAccount = () => {
    this.setState({ vpayLoading: true });
    loginToVpay()
      .then(res => {
        addCluster()
          .then(data => {
            getCluster()
              .then(res => {
                this.setState({ vpayLoading: false });
                if (res.status === 200) {
                  this.setState({ branchesModalOpen: true });
                }
              })
              .catch(err => console.log(err));
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log({ err }));
  };

  generateCashierVpayAccount = () => {
    this.setState({ cashierVpayLoading: true });
    loginToVpay()
      .then(res => {
        addCluster()
          .then(data => {
            getCluster()
              .then(res => {
                this.setState({ cashierVpayLoading: false });
                if (res.status === 200) {
                  this.setState({ cahsierModalOpen: true });
                }
              })
              .catch(err => console.log(err));
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log({ err }));
  };

  handleAddTill = id => {
    this.setState({ vpayLoading: true });
    addTill(id)
      .then(res => {
        this.setState({ vpayLoading: false });
        if (res.status === 200 && res.data.status === "success") {
          Modal.success({
            title: "Bank account created successfully",
            onOk: this.getAllBranchesNuban,
          });
        } else
          return Modal.error({
            title: "Branch registration failed",
            content: res.data.message,
          });
      })
      .catch(err => {
        this.setState({ vpayLoading: false });
        Modal.error({
          title: "Branch registration failed",
          content:
            "Add a new branch manager with phone number or update the existing branch manager account to include a phone number",
        });
      });
  };

  // getCashierTill(id).catch(err=>console.log(err, "err", err.message))
  getCashierNubanTill = (id, tillId) => {
    getCashierTill(id, tillId)
    .then((res)=>{
      console.log(res.data);
      this.setState({ cahsierModalOpen: false })
    })
    .catch(err=>console.log(err, "err", err.message))
  }

  handleAddCashierTill = id => {
    this.setState({ cashierVpayLoading: true });
    
    addCashierTill(id)
      .then(res => {
        this.setState({ cashierVpayLoading: false });
        if (res.status === 200 ) {
          Modal.success({
            title: "Cashier's Bank account created successfully",
            onOk: this.getCashierNubanTill(id, res.data.id),
          });
        } else
          return Modal.error({
            title: "Cashier registration failed",
            content: res.data.message,
          });
      })
      .catch(err => {
        console.log(err, err.message);
        this.setState({ cashierVpayLoading: false });
        Modal.error({
          title: "Cahsier registration failed",
          content:
            "Add a new branch manager with phone number or update the existing branch manager account to include a phone number",
        });
      });
  };

  handleGetTill = id => {
    this.setState({ vpayLoading: true });
    getTill(id)
      .then(res => {
        this.setState({ vpayLoading: false });
        this.setState(prev => ({
          branchesAccountNumber: {
            ...prev.branchesAccountNumber,
            [id]: res.data,
          },
        }));
      })
      .catch(err => {
        this.setState({ vpayLoading: false });
      });
  };

  updatePayout = ({
    payout_active,
    bank_account_name,
    bank_account_number,
    bank_name,
  }) =>
    this.setState({
      isPayoutActive: payout_active,
      bank_account_name,
      bank_account_number,
      bank_name,
    });

  getMerchantSMSBalance = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await api.merchants.getMerchantSMSBalance();

      if (res.status === 200) {
        this.setState({ isLoading: false, sms_balance: res.data.balance });
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  toggleAccountVerificationModal = () => {
    const { sms_balance } = this.state;

    if (sms_balance >= minimum_sms_units) {
      this.setState({
        showAccountVerificationModal: !this.state.showAccountVerificationModal,
      });
    } else {
      message.error(strings.atLeast4SmsUnitsRrequired, 5);
    }
  };

  render() {
    const {
      showAccountVerificationModal,
      isLoading,
      sms_balance,
      isPayoutActive,
      bank_account_name,
      bank_account_number,
      bank_name,
      branchesModalOpen,
      cahsierModalOpen,
      branchesAccountNumber,
      vpayLoading,
      cashierVpayLoading,
      setTerminal,
      terminalLoading,
      terminalId,
      terminalLabel,
      terminalProvider,
      branchLoading,
      allStaff,
      selectStaffId
    } = this.state;

    const { user, allBranches } = this.props;

    return (
      <div className="page-container bank-screen">
        {branchesModalOpen && (
          <Modal
            open={branchesModalOpen}
            title="Get Unique Bank Account Number"
            onCancel={() => this.setState({ branchesModalOpen: false })}
            footer={[
              <Button
                type="primary"
                onClick={() => this.setState({ branchesModalOpen: false })}
              >
                Cancel
              </Button>,
            ]}
          >
            <p>Select branch to generate account number</p>
            {allBranches.map(branch => (
              <Card size="small" bordered={false}>
                <Button
                  onClick={() => this.handleAddTill(branch.id)}
                  loading={vpayLoading}
                  disabled={!!branchesAccountNumber[branch.id]?.nuban}
                  title={
                    !branchesAccountNumber[branch.id]?.nuban
                      ? "Geerate account number for this branch"
                      : "Branch already has an account number"
                  }
                >
                  {branch.name}
                </Button>
              </Card>
            ))}
          </Modal>
        )}
        {cahsierModalOpen && (
          <Modal
            open={cahsierModalOpen}
            title="Get Unique Cashier's Bank Account Number"
            onCancel={() => this.setState({ cahsierModalOpen: false })}
            footer={[
              <Button
                type="primary"
                onClick={() => this.setState({ cahsierModalOpen: false })}
              >
                Cancel
              </Button>,
            ]}
          >
            <p>Select staff to generate account number</p>
            {allBranches.map(branch => {
              return branch.staff.map((staf)=>{
                return <Card size="small" bordered={false}>
                <Button
                  onClick={() => this.handleAddCashierTill(staf.id)}
                  loading={vpayLoading}
                  // disabled={!!branchesAccountNumber[branch.id]?.nuban}
                  title={
                    !branchesAccountNumber[branch.id]?.nuban
                      ? "Generate account number for this branch"
                      : "Branch already has an account number"
                  }
                > 
                  {staf.username}
                </Button>
              </Card>
              })
          })}
          </Modal>
        )}

        <Card bordered={false}>
          {isPayoutActive ? (
            <div className="inactive-payout">
              <p>
                {strings.accountName}: <strong>{bank_account_name}</strong>
              </p>

              <p>
                {strings.accountNumber}: <strong>{bank_account_number}</strong>
              </p>

              <p>
                {strings.bankName}: <strong>{bank_name}</strong>
              </p>

              <p>
                <Icon
                  type="check-circle"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                  style={{ marginRight: "1rem" }}
                />
                {strings.verified}
              </p>

              {allBranches.length > 0 &&
                allBranches.map(branch => (
                  <p>
                    {branch.name} Account Number:{" "}
                    <strong>
                      {branchesAccountNumber[branch.id]?.nuban ||
                        "Not generated"}
                    </strong>
                  </p>
                ))}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="primary"
                  onClick={this.toggleAccountVerificationModal}
                  disabled={user.currency !== "NGN"}
                  loading={isLoading}
                >
                  {strings.reConnectBankAccount}
                </Button>

                <Button
                  type="primary"
                  onClick={()=>this.setState({ setTerminal: true })}
                  // disabled={user.currency !== "NGN"}
                  loading={branchLoading}
                >
                  Assign Terminal To  Staff
                </Button>
                <Popover 
                  title="Generate Vpay Nuban" 
                  content={
                  <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                    <Button
                      onClick={this.generateVpayAccount}
                      disabled={user.currency !== "NGN"}
                      loading={vpayLoading}
                    >
                      {/* {strings.edit} */}
                      Generate Branch's Vpay Nuban
                    </Button>
                    <Button 
                      onClick={this.generateCashierVpayAccount}
                      disabled={user.currency !== "NGN"}
                      loading={cashierVpayLoading}
                    >
                      Generate Cahsier's Vpay Nuban
                    </Button>
                  </div>
                }
                  >
                  <Button
                    type="primary"
                    // onClick={this.generateVpayAccount}
                    disabled={user.currency !== "NGN"}
                    // loading={vpayLoading}
                  >
                    Generate Vpay NUBAN
                  </Button>
                </Popover>
              </div>
            </div>
          ) : (
            <div className="active-payout mt-30">
              <p>{strings.pleaseVerifyYourAccountToComplyWithKyc}</p>
              <Button
                type="primary"
                onClick={this.toggleAccountVerificationModal}
                disabled={user.currency !== "NGN"}
                loading={isLoading}
              >
                {strings.connectBankAccount}
              </Button>
            </div>
          )}
        </Card>

        {showAccountVerificationModal ? (
          <AccountVerificationModal
            visible={showAccountVerificationModal}
            closeModal={this.toggleAccountVerificationModal}
            sms_balance={sms_balance}
            updatePayout={this.updatePayout}
          />
        ) : null}
        {setTerminal && <Modal
          open={setTerminal}
          onCancel={()=>this.setState({ setTerminal: false })}
          onOk={()=>this.handleSaveVpaySettings()}
          title="Assign terminal to staff"
          confirmLoading={terminalLoading}
        >
          <div>
            <Select 
              size="large"
              placeholder="Select Customer"
              style={{ width: "100%" }}
              onSelect={(value)=> {
                this.setState({ selectStaffId: value });
              }}
            >
              {allStaff.map((staff, index)=>{
                return <Option key={staff.id} value={staff.id}>{`${staff.username}`}</Option>
              })}
            </Select>
            <div style={{height: "7px"}}></div>
            <Input 
              value={terminalId}
              onChange={(e)=>this.setState({ terminalId: e.target.value})}
              placeholder="Set terminal ID"
            />
            <div style={{height: "7px"}}></div>
            <Input 
              value={terminalLabel}
              onChange={(e)=>this.setState({ terminalLabel: e.target.value})}
              placeholder="set terminal label"
            />
            <div style={{height: "7px"}}></div>
            <Input 
              value={terminalProvider}
              onChange={(e)=>this.setState({ terminalProvider: e.target.value})}
              placeholder="set terminal provider"
            />
            <div style={{height: "7px"}}></div>
          </div>
        </Modal> 
      }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  allBranches: state.branch.allBranches,
});

export default connect(mapStateToProps, { getAllBusinessBranches })(BankScreen);
