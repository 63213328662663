import React, { useEffect, useState } from "react";
import { Modal, Popover, Button, Tree, Spin, Image } from "antd";
import { Icon } from "@ant-design/compatible";
import ReactToPrint from "react-to-print";
import ProductsBarCode from "../ProdctsBarCode";
import "./index.scss";

import { getShock } from "../../functions";
import strings from "../../../../../../strings";
import api from "../../../../../../api";
import EditCustomModalForm from "./editCustom";
import moment from "moment";
import ProductHistory from "../ProductHistory";
const { TreeNode } = Tree;

const ViewDetailsModal = ({
  currentProduct,
  categories,
  showModal,
  closeModal,
  toggleEditProductModal,
  toggleDeleteProductModal,
  toggleSendInventoryModal,
  toggleUpdateInventoryModal,
  toggleAddProductSuppliesModal,
  user,
  branchId,
  updateProducts,
  isPurchaseOrderActive,
  getSingleProductHistory
}) => {
  let category = null;
  let componentRef = null;

  // console.log(currentProduct);
  const {
    sku,
    product_sku,
    unit,
    name,
    price,
    cost_price,
    variants,
    picture,
    quantity,
    merchant_product_category_id,
    custom_quantities,
    extra_pictures,
    publish_to_loystar_shop,
    stock_notification,
    start_expiration_notification,
    expiry_date,
    supplier
  } = currentProduct;

  const isAdmin = user.role_id === 1;

  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ showEditModal, setShowEditModal ] = useState(false);
  const [ customToEdit, setCustomToEdit ] = useState({});
  const [ showProductHx, setShowProductHx ] = useState(false);
  // filter out product category
  if (merchant_product_category_id) {
    const filteredCategory = categories.filter(
      category => merchant_product_category_id === category.id
    );

    category = filteredCategory.length ? filteredCategory[0].name : null;
  }

  const handleEdit = ( custom ) => {
    setCustomToEdit(custom);
    setShowEditModal(true);
  }

  const handleDelete = async (id, name) => {
    Modal.info({
      title: `Are you sure you want to delete ${name}`,
      
      onOk: async ()=>{
        setIsDeleting(true);
        const res = await api.product.deleteCustomQuantity(id);

        if(res.status === 204 || res.status == 200){
          setIsDeleting(false);
          Modal.success({
            title: `${name} deleted successfully`,
            onOk: ()=>{
              closeModal();
              !isNaN(branchId)
                ? updateProducts(user.id, branchId)
                : updateProducts();
            }
          });
        }
      },
      okButtonProps: { loading: isDeleting },
      closable: true,
      
    });
  }
  useEffect(()=>{
    setIsDeleting(isDeleting)
  }, [isDeleting]);
  useEffect(()=>{
    setCustomToEdit(customToEdit)
  }, [customToEdit])
  // Content of the action column
  const actionsContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button onClick={toggleEditProductModal}>
        <Icon type="edit" /> {strings.edit}
      </Button>
      {isAdmin && <Button onClick={toggleDeleteProductModal}>
        <Icon type="delete" />
        {strings.delete}
      </Button>}

     {<Button className="" onClick={toggleAddProductSuppliesModal}>
        {strings.receiveInventory}
      </Button>}

      {currentProduct && currentProduct.track_inventory && <Button className="" onClick={() => setShowProductHx(true)}>
        {strings.changeHistory}
      </Button>}

      {currentProduct && currentProduct.track_inventory ? (
        <Button onClick={toggleSendInventoryModal}>
          <Icon type="share-alt" />
          {strings.sendInventory}
        </Button>
      ) : null}

      {/* {
        currentProduct && currentProduct.track_inventory && (
          <Button onClick={toggleUpdateInventoryModal}>
            <Icon type="audit" />
            Update Inventory
          </Button>
        )
      } */}

      <div style={{ display: "flex" }}>
        <ReactToPrint
          trigger={() => <Button>{strings.printProductTag}</Button>}
          content={() => componentRef}
        />
        <div style={{ display: "none" }}>
          <ProductsBarCode
            products={currentProduct}
            ref={el => (componentRef = el)}
            user={user}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
    <Modal
      title={strings.productDetails}
      okText={strings.actions}
      cancelText={strings.cancel}
      open={showModal}
      destroyOnClose
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          {strings.close}
        </Button>,
        <Popover title={strings.actions} content={actionsContent}>
          <Button type="primary">{strings.actions}</Button>
        </Popover>,
      ]}
    >
      <div className="images">
        <div style={{display: "flex", alignItems: "center", gap:20}}>
          {/* <div>
            {picture ? (
              <div
                style={{ width: "200px", height: "200px", marginBottom: "30px" }}
              >
                <img src={picture} alt={name} />
                <p style={{textAlign:"center", margin:0}}>{strings.productImage}</p>
              </div>
            ) : null}
          </div> */}

          <div> 
            {<div className="image-container">
              {picture && (
                <div
                  style={{ width: "100px", height: "100px", overflow: "hidden" }}
                >
                  <Image src={picture} alt={name} style={{ width: "100px", height: "100px", overflow: "hidden" }} />
                  {/* <p style={{textAlign:"center", margin:0}}>{strings.productImage}</p> */}
                </div>
              )}
              {extra_pictures.length > 0 && (
                extra_pictures.map((picture, index) => {
                  return <div key={index}>
                    <div
                      style={{ 
                        width: "100px", 
                        height: "100px", 
                        // marginTop: "30px",
                        display: "flex",
                        flexDirection: "column" 
                      }}
                    >
                      <Image src={picture} alt={name} />
                    </div>
                  </div>
                })
              )}
            </div>}
            {/* <p style={{textAlign:"center", marginTop:10}}>{strings.extraImage}</p> */}
          </div>
          
        </div>
       
        <p>
          <strong>{strings.name}</strong>: {name}
        </p>
        <p>
          <strong>{strings.price}</strong>: {price}
        </p>
        {isAdmin && (
          <p>
            <strong>{strings.costPrice}</strong>:{" "}
            {cost_price ? cost_price : "--"}
          </p>
        )}
        <p>
          <strong>{strings.quantity}</strong>: {getShock(quantity)}
        </p>
        <p>
          <strong>{strings.category}</strong>: {category ? category : "--"}
        </p>
        {/* <p>
          <strong>{strings.publish}</strong>: {publish_to_loystar_shop ? "Yes" : "No"}
        </p> isPurchaseOrderActive*/}
        <p>
          <strong>{strings.unit}</strong>: {unit}
        </p>
        <p>
          <strong>SKU</strong>: {sku}
        </p>
        {product_sku && (
          <p>
            <strong>Product SKU</strong>: {product_sku}
          </p>
        )}

        {isPurchaseOrderActive && supplier && (
          <p>
            <strong>Supplier</strong>: {supplier.business_name}: {supplier.phone_number}
          </p>
        )}

        {expiry_date && (
          <p>
            <strong>Product Expires on</strong>: {moment(expiry_date).format("DD-MM-YYYY")}
          </p>
        )}

        {stock_notification && (
          <>
            <p style={{margin:0}}>
              <strong>Product Notification</strong>
            </p>
            <Tree
              showLine
              switcherIcon={<Icon type="down" />}
              defaultExpandedKeys={["0-0-0"]}
            >
              <>
               

                <TreeNode 
                  key={"1"} 
                  title="Stock Notification"
                  style={{position: "relative"}}
                >
                  <TreeNode title={`Low stock levels - ${stock_notification.low_stock_level}`} />
                  <TreeNode title={`Notification Frequency - ${stock_notification.notification_frequency}`} />
                  <TreeNode title={`Notification Type - ${stock_notification.notification_type}`} />
                  {start_expiration_notification && <TreeNode title={`Notification Starts - ${moment(start_expiration_notification).format("DD-MM-YYYY")}`} />}
                  {/* {isAdmin && (
                    <TreeNode
                      title={`${strings.costPrice} - ${cost_price}`}
                    />
                  )} */}
                </TreeNode>
              </>
            
            </Tree>
          </>
        )}

        <div>
          <p style={{ marginBottom: 0 }}>
            <strong>{strings.variants}</strong>:
          </p>

          <Tree
            showLine
            switcherIcon={<Icon type="down" />}
            defaultExpandedKeys={["0-0-0"]}
          >
            {variants.length > 0
              ? variants.map((variant, key) => {
                  const { type, value, quantity, price, cost_price, picture } = variant;
                  return (
                  <>
                    <TreeNode 
                      key={key} 
                      title={ <div style={{
                        display: "flex",
                        // alignItems:"flex-end",
                        gap: 5
                      }}>
                        <p>{`${strings.type} - ${type}`}</p>
                        {picture && <Image 
                            src={picture}
                            alt={value}
                            height={30}
                            width={30}
                          />
                        }
                      </div>} 
                      style={{position: "relative"}}
                    >
                      <TreeNode title={`${strings.value} - ${value}`} />
                      <TreeNode title={`${strings.quantity} - ${quantity}`} />
                      <TreeNode title={`${strings.price} - ${price}`} />
                      {isAdmin && (
                        <TreeNode
                          title={`${strings.costPrice} - ${cost_price}`}
                        />
                      )}
                    </TreeNode>
                  </>);
                })
              : "--"}
          </Tree>
        </div>

        <div>
          <p style={{ marginTop: "1rem", marginBottom: 0 }}>
            <strong>{strings.customQty}</strong>:{isDeleting && <Spin />}
          </p>
{/* 
          <Tree
            showLine
            switcherIcon={<Icon type="down" />}
            defaultExpandedKeys={["0-0-0"]}
          > */}
            <ul>
            {custom_quantities.length > 0
              ? custom_quantities.map((custom, key) => {
                  const { name, quantity, price, barcode, id } = custom;

                  return (
                    <li>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0 10% 0 0"}}>
                        <span>{name} ({quantity} units) - {price}</span>
                        <div style={{display: "flex", alignItems: "center", gap: "0.5rem", margin: "0"}}>
                          <Icon type="edit" onClick={()=>handleEdit(custom)}/>
                          <Icon type="delete" onClick={()=>handleDelete(id, name)} />
                        </div>
                      </div>
                    </li>
                    // <TreeNode key={key} title={`${strings.customName} - ${name}`}>
                    //   <TreeNode title={`${strings.customName} - ${name}`} />
                    //   <TreeNode title={`${strings.quantity} - ${quantity}`} />
                    //   <TreeNode title={`${strings.price} - ${price}`} />
                    // </TreeNode>
                  );
                })
              : "--"}
          </ul>
          {/* </Tree> */}
        </div>
      </div>
    </Modal>
    {showEditModal && 
      <EditCustomModalForm 
        open={showEditModal}
        onCancel={()=>setShowEditModal(false)}
        user={user}
        updateProducts={updateProducts}
        branchId={branchId}
        custom={customToEdit}
        closeModal={closeModal}
      />}

    {showProductHx && 
      <ProductHistory 
        open={showProductHx}
        onCancel={()=>setShowProductHx(false)}
        user={user}
        getProductHistory={getSingleProductHistory}
        product={currentProduct}
      />}
    </>
  );
};

export default ViewDetailsModal;
