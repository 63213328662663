import React from "react";
import { Modal,Input } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import {
  addPaymentMessage,
  getPaymentMessage,
} from "../../../../../redux/actions/invoiceActions";

import "./index.scss";
import strings from "../../../../../strings";

const FormItem = Form.Item;
const { TextArea } = Input;

class InvoiceDescriptionModal extends React.Component {
  state = {
    loading: false,
    description: "",
  };
  componentDidMount() {
    this.props.form.setFieldsValue({
      description: this.props.paymentMessage,
    });
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.error) {
    //   this.setState({ loading: false });
    // }
    if (nextProps.paymentMessage !== this.props.paymentMessage) {
      this.props.form.setFieldsValue({
        description: nextProps.paymentMessage,
      });
    }
  }

  handleAddInvoice = () => {
    const { form, closeModal } = this.props;
    this.setState({ loading: true });
    form.validateFields((err, values) => {
      if (err) {
        this.setState({ loading: false });
        return;
      }

      this.setState({ loading: true });
      this.props.addPaymentMessage(values.description).then(res => {
        this.props.form.resetFields();
        this.props.getPaymentMessage();
        this.setState({ loading: false });
        closeModal();
        Modal.success({
          title: strings.youveSuccessfullyAddedInvoicePaymentMessage,
        });
      });
    });
  };

  render() {
    const {
      showModal,
      closeModal,
      form: { getFieldDecorator },
    } = this.props;

    const { loading } = this.state;

    return (
      <Modal
        title={strings.paymentMessage}
        open={showModal}
        okText={strings.save}
        cancelText={strings.cancel}
        okButtonProps={{ loading }}
        onCancel={closeModal}
        onOk={this.handleAddInvoice}
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
      >
        <Form layout="vertical" >
          <div id="add-customer">
            <FormItem label={strings.description}>
              {getFieldDecorator("description", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseEnterPaymentInstructions,
                  },
                ],
              })(
                <TextArea
                  rows={2}
                  placeholder={strings.description + "..."}
                ></TextArea>
              )}
            </FormItem>
          </div>
        </Form>
      </Modal>
    );
  }
}

const InvoiceDescription = Form.create()(InvoiceDescriptionModal);

InvoiceDescription.propTypes = {
  addPaymentMessage: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  paymentMessage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  error: state.error,
  paymentMessage: state.invoice.paymentMessage,
});

export default connect(mapStateToProps, {
  addPaymentMessage,
  getPaymentMessage,
})(InvoiceDescription);
