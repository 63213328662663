import Axios from "axios";


// export const returnPurchaseOrder = (id, data) => async (dispatch) =>  {
//     const res = await api.productSupplies.returnPurchaseOrder(id, data);

//     return Promise.resolve(res);
// }
//////////////////////////////////////////////////fez delivery///////////////////////////////////////
export const createDeliveryFez = async (data) => {
    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/create_fez_delivery`;
    const response = await Axios.post(url, data);

    return Promise.resolve(response);
}

export const getFezDeliveryPrice = async (data)  => {
    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/fetch_delivery_price`;
    const response = await Axios.post(url, data);

    return Promise.resolve(response);
}
