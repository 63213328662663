import { Modal } from 'antd'
import React, { useEffect } from 'react'
import strings from '../../../../../strings'

const DeliveryDetails = ({
    open,
    onCancel,
    delivery,
    user,
    branches
}) => {
    const { 
        recipient,
        destinationInformation,
        order_id,
        order_amount,
        delivery_amount,
        status,
        delivery_date,
        delivery_time,
        branch_id
    } = delivery;

    useEffect(() => {
        const deliveryBranch = branches && branches.find(branch => branch.id === delivery.branch_id)
    }, [delivery])
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title="Delivery Details"
        destroyOnClose
        footer={null}
    >
        <p>
          <strong>{strings.customerInformation}</strong>: {JSON.parse(recipient).name} - {JSON.parse(recipient).mobile}
        </p>
        <p>
          <strong>{strings.deliveryAddress}</strong>: {JSON.parse(destinationInformation).address}
        </p>
        
        <p>
          <strong>{strings.orderId}</strong>: #{order_id}
        </p>

        <p>
          <strong>{strings.orderAmount}</strong>: {user.employer ? user.employer.currency : user.currency}{order_amount}
        </p>

        <p>
          <strong>{strings.deliveryAmount}</strong>: {user.employer ? user.employer.currency : user.currency}{delivery_amount}
        </p>

        <p>
          <strong>{strings.status}</strong>: {status}
        </p>

        <p>
          <strong>{strings.createdAt}</strong>: {delivery_date} {delivery_time}
        </p>

    </Modal>
  )
}

export default DeliveryDetails