import React from "react";
import { connect } from "react-redux";
import { Input, Modal } from "antd";
import { Form } from "@ant-design/compatible";
import {
  editCategory,
  getAllCategories,
} from "../../../../../../redux/actions/categoryActions";
import strings from "../../../../../../strings";

const FormItem = Form.Item;

class EditCategoryModal extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.props.form.setFieldsValue({
      name: this.props.currCategory.name,
    });
  }

  handleEditCategory = () => {
    const {
      form,
      currCategory,
      editCategory,
      getAllCategories,
      closeModal,
      toggleCategoryModal,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      const data = {
        name: values.name,
        id: currCategory.id,
      };

      this.setState({ loading: true });
      editCategory(currCategory.id, data)
        .then(res => {
          if (res && res.status === 200) {
            form.resetFields();
            this.setState({ loading: false });
            getAllCategories();

            Modal.success({
              title: strings.youveSuccessfullyEditedThisCategory,
            });

            toggleCategoryModal();
            closeModal();
          }
        })
        .catch(err => this.setState({ loading: false }));
    });
  };

  render() {
    const {
      isEditCategoryModalOpen,
      closeModal,
      form: { getFieldDecorator },
      currCategory,
    } = this.props;

    return (
      <Modal
        title={`${strings.edit} ${currCategory.name}`}
        okText={strings.edit}
        cancelText={strings.cancel}
        onOk={this.handleEditCategory}
        onCancel={closeModal}
        destoryOnClose
        visible={isEditCategoryModalOpen}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical" >
          <FormItem label={strings.categoryName}>
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterCategoryName,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const EditCategoryForm = Form.create()(EditCategoryModal);

export default connect(null, { editCategory, getAllCategories })(
  EditCategoryForm
);
