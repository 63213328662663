import React, { useState, useEffect } from 'react';
import { Modal, message, Button, Input, Checkbox } from "antd";
import {
    CONFIRM_ORGANISATION_GIFTCARD,
    USE_ORGANISATION_GIFTCARD
  } from "../../../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";
import { useLazyQuery } from '@apollo/client';
import WithApolloClient from '../../../../utils/withApolloClient';
import { connect, useSelector } from 'react-redux';
import MessageModal from './message';

const PartnerGiftcardModal = ({
    open, 
    onCancel, 
    handlePayment, 
    location,
    user,
    transactionDate,
    clearDiscount,
    getCartTotalAmount,
    recordingGiftCardSale,
    cartList
}) => {
    const [ cardNumber, setCardNumber ] = useState(null);
    const [ phoneNumber, setPhoneNumber ] = useState(null);
    const [ inputPhone, setInputPhone ] = useState(false);
    const [ messageModal, setMessageModal ] = useState(false);
    const [ dataMessage, setDataMessage ] = useState(null)
    const [confirmCard, { loading: conLoading,  error: conError, data: conData, refetch: refetchConfirm }] = useLazyQuery(CONFIRM_ORGANISATION_GIFTCARD);
    const [payWithGiftCard, { loading: useLoading,  error: useError, data: useData, refetch }] = useLazyQuery(USE_ORGANISATION_GIFTCARD);
     

    const handleConfirmGiftCard = ( ) => {
        if(cardNumber === null) return message.warning("Please Input Card Number")
        if(conData){
            return refetchConfirm();
        }
        confirmCard({variables: {
            data: {
                card_number: cardNumber,
                phone_number: phoneNumber,
                merchant_id: user && user.id.toString()
            }
        }})
    }

    const handlePaymentWithGiftcard = () => {
        if(cardNumber === null) return message.warning("Please Input Card Number");
        if(useData){
            return refetch();
        }
        
        const newCartList = [];

        cartList.map((list)=>{
            const newlist = {
                item_id: list.item_id || null,
                name: list.name,
                quantity: list.quantity,
                price: list.price,
                description: list.description, 
                picture: list.picture,
                created_at: list.created_at,
                merchant_id: list.merchant_id,
                tax: list.tax,
                tax_rate: list.tax_rate,
                tax_type: list.tax_type,
                order_id: list.order_id || null,
                amount: list.amount,
                total_quantity: list.total_quantity,
                track_inventory: list.track_inventory,
                merchant_loyalty_program_id: list.merchant_loyalty_program_id,
                merchant_product_category_id: list.merchant_product_category_id,
                product_type: list.product_type
            }
            newCartList.push(newlist);
        })
        payWithGiftCard(
            {
                variables: {
                data: {
                    amount: Number(getCartTotalAmount()),
                    // amount: 1,
                    card_number: cardNumber,
                    cartItems: newCartList
                },
            },
            onCompleted: (data) => {
                console.log("data",data);
            },
            onError: (error) => {
                console.log("err", error);
            }
            })
    }

    const handleRecoredSales = ( ) => {
        handlePayment(
            "gift_card",
            location,
            false,
            null,
            cardNumber,
            clearDiscount,
            transactionDate || Date.now(),
            new Date(transactionDate || Date.now()).getTime(),
          );
    }
    useEffect(()=>{
        if(useData){
            handleRecoredSales();
        }
    }, [useData])
    useEffect(()=>{
    if(conData){
        setDataMessage(conData);
        setMessageModal(true);
    }
    if(useData){
        setDataMessage(useData);
        setMessageModal(true);
    }
    }, [conData, useData])
      
  return (
    <>
    <MessageModal 
        open={messageModal}
        onCancel={()=>setMessageModal(false)}
        data={dataMessage}
    />
    <Modal
        open={open}
        onCancel={onCancel}
        className="partnersGiftCard"
        footer={[
            <div style={{
                display: "flex",
                justifyContent: "space-around"
            }}>
                <Button
                    onClick={handleConfirmGiftCard}
                    loading={conLoading}
                >
                    Confirm Card
                </Button>
                <Button
                    onClick={handlePaymentWithGiftcard}
                    loading={recordingGiftCardSale || useLoading}
                >
                    Use Gift Card
                </Button>
            </div>
        ]}
    >
        <Input
            placeholder="Input Card Number"
            value={cardNumber}
            onChange={(e)=> {
                setCardNumber(e.target.value)
            }}
        />
        {inputPhone && 
            <Input
            placeholder="Input Phone Number"
            value={phoneNumber}
            onChange={(e)=> {
                setPhoneNumber(e.target.value)
            }}
        />
        }
        <div style={{display: "flex"}}>
        <Checkbox 
            onChange={(e)=>{
                setInputPhone(e.target.checked)
            }}
            value={inputPhone}
        />
        <p>Input Customer's Phone Number</p>
        </div>
    </Modal></>
  )
}

const mapStateToProps = (state) => ({
    state: state,
    user: state.auth.user,
    merchant_details: state.merchant_details,
    user: state.auth.user || state.merchant.user,
});


export default connect(mapStateToProps)(WithApolloClient(PartnerGiftcardModal));  