import { gql } from "@apollo/client";


export const GET_ORDERS = gql`
  query getOrders{
    getOrders{
        id
        customer {
          first_name
          last_name
          user_id
        }
        product_id
        products {
          name
          picture
      }
      order_items 
        {
            item_name
            quantity
            total_price
        }
        created_at
        status
        paid_at
        paid_time
        expiry_date
	  }
  }
`;

export const GET_INSTORE_ORDERS = gql`
  query getInstoreOrders($merchant_id: Int, $branch_id: Int, $homebranch_id: Int){
    getInstoreOrders(merchant_id: $merchant_id, branch_id: $branch_id, homebranch_id: $homebranch_id){
        id
        order_id
        merchant_id
        branch_id
        customer {
          first_name
          last_name
          user_id
          phone_number
          email
          delivery_address
        }
        orderNotes
        total
        processed_by
        reference_code
        order_type
        created_at
        status
        paid_at
        paid_time
        expiry_date
        updated_at
	  }
  }
`;

export const SUBSCRIBE_INSTORE_ORDERS = gql`
  subscription subscribeInstoreOrders($merchant_id: Int, $branch_id: Int){
    subscribeInstoreOrders(merchant_id: $merchant_id, branch_id: $branch_id){
        id
        order_id
        merchant_id
        branch_id
        customer {
          first_name
          last_name
          user_id
          phone_number
          email
          delivery_address
        }
        orderNotes
        total
        processed_by
        reference_code
        order_type
        created_at
        status
        paid_at
        paid_time
        expiry_date
        updated_at
	  }
  }
`;

export const SUBSCRIBE_LOYSTAR_BOOKINGS = gql`
  subscription subscribeBookings($merchant_id: Int){
    subscribeBookings(merchant_id: $merchant_id){
      id
      service_name
      customer_id
      staff_name
      staff_id
      staff_email
      booking_date
      status
      payment_status
      amount_charged
      completion_time
      loyalty_id
      branch_name
      branch_id
      repeat
      repeat_frequency
      repeat_number
      service_id
      created_at
      merchant_id
	  }
  }
`;

export const GET_ONE_ORDER = gql`
  query getOneOrder($id: String, $processed_by: String, $reference_code: String){
    getOneOrder(id: $id, processed_by: $processed_by, reference_code: $reference_code){
        id
        order_id
        merchant_id
        customer {
          first_name
          last_name
          user_id
          phone_number
          email
          delivery_address
        }
        total
        orderNotes
        order_type
        created_at
        status
        paid_at
        processed_by
        reference_code
        paid_time
        expiry_date
        updated_at
	  }
  }
`;

//pay orders
export const PAY_ONE_ORDER = gql`
  query payOneOrder($id: String!){
    payOneOrder(id: $id){
      id
      order_id
      merchant_id
      customer {
        first_name
        last_name
        user_id
        phone_number
        email
        delivery_address
      }
      orderNotes
      total
      order_type
      processed_by
      reference_code
      created_at
      status
      paid_at
      paid_time
      updated_at
      expiry_date
	  }
  }
`;
// readyOneOrder

export const READY_ONE_ORDER = gql`
  query readyOneOrder($id: String!){
    readyOneOrder(id: $id){
        id
        order_id
        merchant_id
        customer {
          first_name
          last_name
          user_id
          phone_number
          email
          delivery_address
        }
        orderNotes
        total
        order_type
        processed_by
        reference_code
        created_at
        status
        paid_at
        paid_time
        expiry_date
        updated_at
	  }
  }
`;
export const GET_ONE_ORDERITEM = gql`
  query getOrderItems($merchant_id: Int!, $branch_id: Int, $homebranch_id: Int){
    getOrderItems(merchant_id: $merchant_id, branch_id: $branch_id, homebranch_id: $homebranch_id){
        id
        item_id
        order_id
        name
        quantity
        price
        description
        merchant_id
        bundle_id
        service_id
        service_name
        product_type
        picture
        amount
        tax
        tax_rate
        tax_type
        created_at
        variants {
            branch_product_id
            cost_price
            created_at
            id
            price
            quantity
            type
            updated_at
            value
        }
        custom_quantities {
          barcode
          created_at
          id
          merchant_id
          name
          price
          product_id
          quantity
          updated_at
        }
        updated_at
	  }
  }
`;

export const DELETE_ONE_ORDER = gql`
  query deleteOneOrder($id: String!){
    deleteOneOrder(id: $id){
      id
        order_id
        merchant_id
        customer {
          first_name
          last_name
          user_id
          phone_number
        }
        orderNotes
        total
        order_type
        processed_by
        reference_code
        created_at
        status
        paid_at
        paid_time
        expiry_date
        updated_at
	  }
  }
`;

export const DELETE_ORDERITEMS = gql`
  query deleteOneOrderItem($order_id: String!){
    deleteOneOrderItem(order_id: $order_id){
        message
	  }
  }
`;

export const ADD_ORDERS = gql`
	 mutation AddOrders( $data: order! ) {
		addOrders(data: $data) {
			order_id
			customer{
				user_id
			}
			status
	 	}
	 }
`

export const CREATE_DELIVERY = gql`
  mutation createDelivery( $data: deliveryData ){
	createDelivery( data: $data){
		message
		deliverydata{
			id
			recipient
			status
			delivery_time
			delivery_date
			delivery_amount
			order_amount
			destinationInformation
			merchant_id
			user_id
			recipient
			order_id
			orderID
			branch_id
			delivery_id
		}
	}
  }
`

export const GET_DELIVERY_REQUEST = gql`
  query getDeliveryRequests($merchant_id: Int, $branch_id: Int){
    getDeliveryRequests( merchant_id: $merchant_id, branch_id: $branch_id,){
      message
      deliverydata{
        id
        recipient
        status
        delivery_time
        delivery_date
        delivery_amount
        order_amount
        destinationInformation
        merchant_id
        user_id
        recipient
        order_id
        orderID
        branch_id
        delivery_id
      }
    }
  }
`
// processDeliveryRequest(id:String):deliveryMessage
export const PROCESS_DELIVERY_REQUEST = gql`
  query processDeliveryRequest($id: String){
    processDeliveryRequest( id: $id){
      message
      deliverydata{
        id
        recipient
        status
        delivery_time
        delivery_date
        delivery_amount
        order_amount
        destinationInformation
        merchant_id
        user_id
        recipient
        order_id
        orderID
        branch_id
        delivery_id
      }
    }
  }
`

export const SUBSCRIBE_DELIVERY = gql`
  subscription subscribeDelivery($merchant_id: Int, $branch_id: Int){
    subscribeDelivery(merchant_id: $merchant_id, branch_id: $branch_id){
      message
      deliverydata{
        id
        recipient
        status
        delivery_time
        delivery_date
        delivery_amount
        order_amount
        destinationInformation
        merchant_id
        user_id
        recipient
        order_id
        orderID
        branch_id
        delivery_id
      }
	  }
  }
`;