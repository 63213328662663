import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Tooltip, Upload, message } from 'antd';
import { Icon } from '@ant-design/compatible';
import strings from '../../../../../../strings';
import { ExcelRenderer } from "react-excel-renderer";
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_SERVICE, GET_SERVICES, GET_CATEGORIES, ADD_CATEGORY } from '../../../Apps/MultiLevelLoyalty/Loyalties/constants';


const { Dragger } = Upload;
const ImportServiceModal = ({ open, onCancel }) => {
    const [ activeTabKey, SetActiveTabKey ] = useState(1);
    const [ errors, setErrors ] = useState([]);
    const [ fileList, setFileList ] = useState([]);
    const [ strangeHeaders, setStrangeHeaders ] = useState([]);
    const [ rows, setRows ] = useState([]);
    const [ serviceToCreate, setServiceToCreate ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ serviceCategories, setServiceCategories ] = useState([]);
    const [ categoriesInFile, setCategoriesFile ] = useState([]);
    const CSV_TEMPLATE_URL =
  "https://docs.google.com/spreadsheets/d/124K7onyXlpcK0qlcMFwVQh3dyMgOs-_UOtR4imwvamg/edit#gid=1403202796";
    // const [ importType, setImportType ] = useState()

    const { loading: loadingCategories, data } = useQuery(GET_CATEGORIES);

    const [addCategory, { loading: isAddCategoryLoading }] = useMutation(
      ADD_CATEGORY,
      {
        onCompleted: data => {
          // form.resetFields();
          // data.addCategory &&
          //   Modal.success({
          //     title: "Category added successfully",
          //     onOk: onCancel,
          //   });
        },
        onError: error => {
          message.error(error.message, 5);
        },
        refetchQueries: [{ query: GET_CATEGORIES }],
      }
    );

    useEffect(() => {
      
      let categoryObj = {}
      if(data.getCategories){
        data.getCategories.map(value => {
          categoryObj[value.category_name] = value.category_name
        })
        console.log("c", categoryObj);
        for ( let i in data.getCategories){
          console.log("i", i);
          
        }

        console.log("d", categoryObj);
      }
     
      console.log("jk", data.getCategories);
    }, [data]);

    useEffect(() => {
      console.log("jkj", categoriesInFile);
    }, [categoriesInFile, data]);

    const handleSaveCategory = () => {
      let nA = [];
      let neObj = {};

      categoriesInFile.map(c => {
        if(neObj[c]){
          return
        }else{
          neObj[c] = c
        }
      })
      // console.log("n", neObj, Object.values(neObj));
      Object.values(neObj).map(v => {
        if(data.getCategories.includes(v)){
          console.log("yes");
        }else{
          nA.push(v)
        }
      }); 

      nA.map(val => addCategory({ variables: { category_name: val }}))
    }
    

    const props = {
        name: "csv_file",
        multiple: false,
        onRemove: file => {
            setFileList((prev)=>{
                const index = prev.indexOf(file);
                const newFileList = prev.slice();
                newFileList.splice(index, 1);
                return newFileList;
            })
        //   this.setState(state => {
        //     const index = state.fileList.indexOf(file);
        //     const newFileList = state.fileList.slice();
        //     newFileList.splice(index, 1);
        //     return {
        //       fileList: newFileList,
        //     };
        //   });
  
          setErrors([]);
        },
        beforeUpload: async file => {
          // Clear Any Errors or Files if Presert
          setFileList([]);
          setErrors([]);
          setStrangeHeaders([]);
          
          
  
          if (!file) {
            message.error(strings.pleaseSelectFile, 5);
            return;
          }
  
          // Check If File is Excel File
          // const isExcel =
          //   file.type === "application/vnd.ms-excel" ||
          //   file.type ===
          //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  
          // Check If File is Excel File
  
          const isCsv = new RegExp("(.*?).(csv)$");
  
          if (!isCsv.test(file.name)) {
            this.setState({
              errorMessage: strings.oopsOnlyCsvFilesAllowed,
            });
            message.warning(strings.oopsOnlyCsvFilesAllowed, 5);
  
            return;
          }
  
          const isLt5M = file.size / 1024 / 1024 < 5;
  
          if (!isLt5M) {
            this.setState({
              errorMessage: strings.fileShouldBeSmallerThan5Mb,
            });
            message.warning(strings.fileShouldBeSmallerThan5Mb, 5);
  
            return;
          }
  
          let errors = [];
          let strangeHeader = []; // Headers Not Accepted in CSV
  
          if(activeTabKey === 1){
            const acceptedHeaders = [
              "service name",
              "category name",
              "service type",
              "retail price",
              "price",
              "duration",
              "extra time",
              "tax",
              "image_url",
              "loyalty_id",
              "completion_time",
              "description",
              "category name",
              "treatment type",
              "resource",
              "online booking",
              "available for",
              "voucher sales",
              "commissions",
              "service id",
              "location"
            ]; // Headers Accepted in CSV File

            try {
              const resp = await ExcelRenderer(file);
  
              // console.log(resp.rows);
  
              const {
                rows: [incomingHeaders, ...rest],
              } = resp;
  
              incomingHeaders.forEach(val => {
                if (!acceptedHeaders.includes(val.toLowerCase().trim())) {
                  strangeHeader.push(val);
                }
              });
  
              if (strangeHeader.length === 0) {
                let products = [];
  
                rest.forEach(item => {
                  // console.log("item", item);
                  const [
                    service_name,
                    service_type,
                    description,
                    amount_charged,
                    duration,
                    online_booking,
                    location,
                    // extra_time,
                    // tax,
                    // category_name,
                    // treatment_type,
                    // resource,
                    // available_for,
                    // voucher_sales,
                    // commisions,
                    // service_ID,
                    loyalty_id,
                    image_url,
                    // retail_price,
                    completion_time,
                  ] = item;
  
                products.push({
                  service_name: service_name === undefined ? "" : service_name,
                  service_type: service_type === undefined ? "" : service_type,
                  description: description === undefined ? "" : description,
                  // amount_charged: price === undefined ? retail_price : price,
                  // price: price === undefined ? null : price,
                  location: location === undefined ? "" : location,
                  //  retail_price: retail_price === undefined ? "" : retail_price,
                  duration: duration === undefined ? "" : 60,
                  // tax: tax === undefined ? "" : tax,
                  // extra_time: extra_time === undefined ? null : extra_time,
                  image_url: image_url === undefined ? "" : image_url,
                  loyalty_id: loyalty_id === undefined ? null : loyalty_id,
                  amount_charged: amount_charged === undefined ? null : amount_charged,
                  completion_time: completion_time === undefined ? null : completion_time,
                  // category_name: category_name === undefined ? "" : category_name,
                  // treatment_type: treatment_type === undefined ? "" : treatment_type,
                  // resource: resource === undefined ? "" : resource,
                  online_booking: online_booking === undefined ? "" : online_booking,
                  // available_for: available_for === undefined ? "" : available_for,
                  // voucher_sales: voucher_sales === undefined ? "" : voucher_sales,
                  // commisions: commisions === undefined ? "" : commisions,
                  // service_ID: service_ID === undefined ? "" : service_ID
                });
              });
  
                /*
                    Check if Number of Products Imported hasn't exceeded limit set for merchant's plan (i.e Free Plan = 20 products, Basic = 60 Products, pro = Unlimited)
              */
                
  
                console.log("prod",products, rest);
  
                // Validate Each Product for Simple Product
                products.forEach((product, index) => {
                  const {
                    service_name,
                    service_type,
                    amount_charged,
                    duration,
                  } = product;

                  setCategoriesFile((prev) => {
                    return [...prev, service_type]
                  })
  
                  const currRow = index + 2;
  
                  // Validate Main Fields
                  if (!service_name) {
                    errors.push(
                      `service name is required on row ${currRow}`
                    );
                  }
  
                  if (!service_type) {
                    errors.push(
                      `service type is required on row ${currRow}`
                    );
                  }

                  if (!amount_charged) {
                    errors.push(
                      `service price is required on row ${currRow}`
                    );
                  }

                  if (!duration) {
                      errors.push(
                        `duration is required on row ${currRow}`
                      );
                    }
  
                  // Check if Product has Inventory Tracking Enabled or Not
                  
                });

                setServiceToCreate(products);
              }
              // console.log("strange",strangeHeader, incomingHeaders, acceptedHeaders);
            } catch (error) {
              console.log("fin",error);
            }
          }
  
          if (strangeHeader.length > 0) {
            setStrangeHeaders(strangeHeader);
            return;
          }
  
          if (errors.length > 0) {
            // console.log("Error Length", errors.length);
            setErrors(errors);
            return;
          }
  
          setFileList((prev)=>{
            return [...prev, file]
          })
        //   this.setState(state => ({
        //     fileList: [...state.fileList, file],
        //   }));
          return false;
        },
        fileList: fileList,
    };

    // const convertToMinute = (duration) => {
    //     console.log("dur", duration);
    //     if(duration=== "1h"){
    //         return 60
    //     }else if(duration.includes("hm",)){
    //         console.log(duration);
    //     }
    // }

    const createServices = async () => {
        if(serviceToCreate.length === 0 ) return Modal.info({title: "Please add file to create services"});

        
        setLoading(true);
        categoriesInFile.length > 0 && handleSaveCategory();
        await serviceToCreate.map((service)=>{
            const values = {...service}
            // convertToMinute(service.duration)
            createService({ variables: { data: values } });
        });

        setLoading(false);
        Modal.success({
          title: "Services created successfully",
          onOk: ()=>{
            onCancel();
          }
        });

    }

    const [createService, { loading: isCreateServiceLoading }] = useMutation(
        CREATE_SERVICE,
        {
          onCompleted: data => {
           
          },
          onError: error => {
            message.error(error.message, 5);
          },
          refetchQueries: [{ query: GET_SERVICES }],
        }
    );

    const tabItems = [
        {
            key: 1, 
            label: "Import services",
            children: [
                <div className="product-import-wrapper">
                <p className="tutorial-wrapper">
                  <span></span>
                  {/* <span className="tutorial-link">
                    Click this <a href="#">Link</a> to watch a video on how to use the
                    product import feature
                  </span> */}
      
                  <Tooltip
                    title={
                      <span>
                        {strings.clickIconToDownloadCsv}{" "}
                        <a href={CSV_TEMPLATE_URL} rel="noopener" target="blank">
                          {"(" + strings.getGoogleSheetVersion + ")"}
                        </a>
                      </span>
                    }
                  >
                    <a
                      href={`${CSV_TEMPLATE_URL}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Icon type="download" style={{ fontSize: "30px" }} />
                    </a>
                  </Tooltip>
                </p>
      
                <Dragger {...props} customRequest={() => {}}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">{strings.clickOrDragToAddSingleServices}</p>
                </Dragger>
      
              <div style={{ marginTop: "1.5rem", marginLeft: "0.5rem" }}>
                {errors.length > 0 &&
                  errors.map((err, key) => 
                    (
                    <span key={key} style={{ display: "block", color: "#f5222d" }}>
                      {err}
                    </span>
                  ))}
      
                {strangeHeaders.length > 0 && (
                  <span style={{ display: "block", color: "#f5222d" }}>
                    {strings.missingOrInvalidColumnHeader}
                  </span>
                )}
              </div>
      
              {rows.length > 2 && fileList.length > 0 && (
                <p style={{ marginTop: "2rem" }}>
                  {strings.youHaveImported} {rows.length - 1}{" "}
                  {strings.products}
                </p>
              )}
              </div>
              ]
        },
        // {key: 2, label: "Import multiple services"}
    ]
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title="Import Services"
        okText="Create Services"
        onOk={createServices}
        confirmLoading={isCreateServiceLoading}
    >
        <Tabs 
            items={tabItems}
            activeKey={activeTabKey}
            onChange={(key)=>SetActiveTabKey(key)}
        >

        </Tabs>
    </Modal>
  )
}

export default ImportServiceModal