import React from "react";
import { connect } from "react-redux";
import { Button, Input, Modal } from "antd";
import { Form, Icon } from "@ant-design/compatible";

import { editCategory } from "../../../../../../redux/actions/categoryActions";
import strings from "../../../../../../strings";

class CategoryItem extends React.Component {
  state = {
    isEdit: false,
    loading: false,
  };

  // componentDidMount() {
  //   const { form, category } = this.props;

  // }

  componentDidUpdate(prevProps, prevState) {
    const { form, category } = this.props;
    if (!prevState.isEdit && this.state.isEdit) {
      form.setFieldsValue({
        name: category.name,
      });
    }
  }

  handleEditCategory = e => {
    e.preventDefault();

    const { form, category, editCategory, fetchCategories } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      this.setState({ loading: true });

      const data = {
        id: category.id,
        name: values.name,
      };

      editCategory(category.id, data)
        .then(res => {
          if (res && res.status === 200) {
            this.setState({ isEdit: !this.state.isEdit });

            this.setState({ loading: false });

            Modal.success({
              title: strings.youveSuccessfullyEditedThisCategory,
              onOk: () => fetchCategories(),
            });
          }
        })
        .catch(err => this.setState({ loading: false }));
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      category,

      toggleDeleteCategoryModal,
    } = this.props;

    const { isEdit, loading } = this.state;

    return (
      <div
        className="category-item"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="item">
          {!isEdit ? (
            <p>{category.name}</p>
          ) : (
            <Form
              onSubmit={this.handleEditCategory}
              className="category-item-input"
            >
              <Form.Item className="formItem">
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: strings.pleaseEnterCategoryName,
                    },
                  ],
                })(<Input size="large" />)}
              </Form.Item>

              <Button loading={loading} type="primary" htmlType="submit">
                {strings.update}
              </Button>
            </Form>
          )}
        </div>

        <div className="actions">
          <button
            onClick={() => this.setState({ isEdit: !this.state.isEdit })}
            className="edit-btn"
          >
            <Icon type="edit" />
          </button>

          <button
            onClick={() => toggleDeleteCategoryModal(category)}
            className="delete-btn"
          >
            <Icon type="delete" />
          </button>
        </div>
      </div>
    );
  }
}

const CategoryItemForm = Form.create()(CategoryItem);

export default connect(null, { editCategory })(CategoryItemForm);
