import React, { useState } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Modal } from "antd";
import api from "../../../../../../api";

import { getAllLoyaltyPrograms } from "../../../../../../redux/actions/loyaltyActions";
import strings from "../../../../../../strings";

const DeleteLoyaltyModal = ({
  currLoyalty,
  closeModal,
  isDeleteLoyaltyModalOpen,
  form,
  getAllLoyaltyPrograms,
  toggleLoyaltyDetailModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { getFieldDecorator, validateFields } = form;

  const handleDeleteLoyalty = () => {
    validateFields((err, values) => {
      if (err) return;

      if (values.delete.toLowerCase() !== "delete") {
        Modal.error({
          title: strings.pleaseTypeIn + " delete " + strings.toDeleteLoyalty,
        });
      } else {
        setIsLoading(true);

        api.loyalty
          .deleteLoyaltyProgram(currLoyalty.id)
          .then(res => {
            setIsLoading(false);
            Modal.success({
              title: strings.loyaltyProgramDeleted,
              content: strings.thisLoyaltyProgramDeletedSuccessfully,
              onOk: () => getAllLoyaltyPrograms(),
            });
            toggleLoyaltyDetailModal();
            closeModal();
          })
          .catch(err => setIsLoading(false));
      }
    });
  };

  return (
    <Modal
      title={strings.deleteLoyalty}
      visible={isDeleteLoyaltyModalOpen}
      onCancel={closeModal}
      destroyOnClose
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {strings.cancel}
        </Button>,
        <Button
          type="primary"
          key="edit"
          loading={isLoading}
          onClick={handleDeleteLoyalty}
        >
          {strings.delete}
        </Button>,
      ]}
    >
      <Form layout="vertical" >
        <Form.Item
          label={strings.pleaseTypeIn + " delete " + strings.toConfirm}
        >
          {getFieldDecorator("delete", {
            rules: [
              {
                required: true,
                message: strings.pleaseTypeIn + " delete " + strings.toConfirm,
              },
            ],
          })(<Input size="large" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const DeleteLoyaltyForm = Form.create()(DeleteLoyaltyModal);

export default connect(null, { getAllLoyaltyPrograms })(DeleteLoyaltyForm);
