import React, { useState, useEffect} from "react";
import { Input, Modal, Button, Badge, message } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

import "../VariantsModal/index.scss";
import strings from "../../../../strings";

const CustomQuantityNitro = ({open,
                            onCancel,
                            toggleModal, 
                            product, 
                            form,
                            addNewCategory,
                            getAllCategories, 
                            currency,
                            onClick,
                            isNitroActive
                        })=> {

                        
        
    const FormItem = Form.Item;
    const [ loading, setLoading ] = useState(false);
    const [ selectedCustomQuantity, setSelectedCustomQuantity ] = useState({});
    const [ groupedVariants, setGroupedVariants ] = useState({});
    const [ quantity, setQuantity ] = useState(1.0);
    const [ modalIsUp, setModalIsUp ] = useState(false);
    const [ tryUpdate, setTryUpdate ] = useState(false);
    const [ trackChange, setTrackChange ] = useState(false);
    const [ isSelected, setIsSelected ]= useState({});
    const [ isAnySelected, setIsAnySeleteced ] = useState(false);
    // const [ product, setProduct ] = useState({})
    const { getFieldDecorator } = form;
 
    useEffect(()=>{
        form.setFieldsValue({ quantity: quantity });
    }, [quantity]);

    useEffect(()=>{
        const groupedVariants = {};
        const selectVariantsObj = {};
        product.variants.forEach(variant => {
            if (groupedVariants[variant.type]) {
                groupedVariants[variant.type].push(variant);
            } else {
                selectVariantsObj[variant.type] = [];
                groupedVariants[variant.type] = [variant];
            }
        });
        setSelectedCustomQuantity(selectVariantsObj);
        setGroupedVariants(groupedVariants);
        setModalIsUp(true);
    }, [product])

    useEffect(()=>{
        setSelectedCustomQuantity(selectedCustomQuantity);
    }, [selectedCustomQuantity])

    useEffect(()=>{
        const groupedVariants = {};
        const selectVariantsObj = {};
        product.variants.forEach(variant => {
            if (groupedVariants[variant.type]) {
                groupedVariants[variant.type].push(variant);
            } else {
                selectVariantsObj[variant.type] = [];
                groupedVariants[variant.type] = [variant];
            }
        });
        setSelectedCustomQuantity(selectVariantsObj);
        setGroupedVariants(groupedVariants);
        setModalIsUp(true);
    }, [])

    const handleAddCategory = () => {
        // const { form, addNewCategory, getAllCategories, toggleModal } = this.props;
    
        form.validateFields((err, values) => {
            if (err) return;
    
            setLoading(true);
            addNewCategory(values).then(res => {
            if (res && res.status === 200) {
                form.resetFields();
                setLoading(false)
                getAllCategories();
    
                Modal.success({
                title: strings.youveSuccessfullyAddedANewCategory,
                });
                toggleModal();
            }
            });
        });
    };

    const getSelectedCustQuantity = () => {
        // return quantities = [];

    };

    const inStock = quantity => {
    let variants = getSelectedCustQuantity();
    let result = true;
    let outOfStockVariant = {};
    try {
      variants.forEach(variant => {
        if (variant.quantityLeft) {
          if (quantity > variant.quantity) {
            result = false;
            outOfStockVariant = variant;
            throw new Error(strings.lowStock);
          }
        } else {
          if (quantity > variant.quantity) {
            result = false;
            outOfStockVariant = variant;
            throw new Error(strings.lowStock);
          }
        }
      });
    } catch (error) {
      return [result, outOfStockVariant];
    }
    return [result, outOfStockVariant];
  };

    const changeQuantity = (type) => {
    const selectedCust = [{...isSelected}]
    if (selectedCust.length > 0) {
      if (type === "plus") {
        if(Number(product.quantity) <= (Number(selectedCust[0].quantity) * quantity)){
            Modal.warning({
                title: `${strings.lowStockFor} ${selectedCust[0].name}: ${selectedCust[0].quantity}`,
            });
            return;
        }
        setQuantity(quantity+1);
        form.setFieldsValue({ quantity: quantity });
      } else if (type === "minus") {
        if (quantity <= 1) {
          return;
        }
        setQuantity(quantity-1);
      } else {
        if (!isNaN(type) && Number(type) >= 0) {
          setQuantity(Number(type));
          form.setFieldsValue({ quantity: quantity });
        } else {
          Modal.warning({
            title: strings.pleaseSupplyPositiveQuantityNumber,
          });
        }
      }
    } else {
      Modal.warning({
        title: "Please select a custom quantity",
      });
    }
  };

    const selectCustomQuantity = (custom) => {
        setQuantity(1)
        form.setFieldsValue({ quantity: quantity });
        setIsSelected(custom);
        setIsAnySeleteced(true);
    };

    const clearSelectedVariants = () => {

        // setSelectedCustomQuantity(selectedCustomQuantity);
        setQuantity(1);
        setIsSelected({});
        setIsAnySeleteced(false);
        form.setFieldsValue({ quantity: quantity });
    };

    const handleOnClick = () => {
        if (isAnySelected === false) {
          return Modal.warning({
            title: "Please select a custom quantity",
          });
        }

        const custom_quantities = [{...isSelected}]
        let productCopy = { ...product };

        // delete productCopy.custom_quantities;
        const item = { ...productCopy };

        // item.custom_quantities = custom_quantities;
        if(isNitroActive){
          item.quantity = quantity
        }else{
          item.quantity = quantity;
        }
        item.price = isSelected.price;

        if (custom_quantities.length === 0) {
            Modal.warning({
                title: "Please select a custom quantity",
            });
        } else {
            onClick(item, custom_quantities);
            form.setFieldsValue({ quantity: 1 });
            onCancel();
            // setModalIsUp(true);
            setQuantity(1.0);
            setIsSelected({});
            setIsAnySeleteced(false);
        }
    };

    const displayCustomQty = () => {
        const customQuantity = product.custom_quantities;

        return customQuantity.map((custom, index)=>{
            const { name, price, quantity, id } = custom;
            return <div key={index}>
                <div className="">
                  <Badge
                      key={id}
                      // count={quantity}
                      className={
                      isSelected?.id === id
                          ? strings.variantSelected
                          : strings.variant
                      }
                      onClick={() => {
                        selectCustomQuantity(custom);
                      }}
                  >
                      <span style={{ paddingRight: "10px" }}>{name} ({quantity} units)</span>
                      <span style={{ paddingRight: "10px" }}>-</span>
                      <span>
                      {currency}
                      {product.tax_rate
                          ? (
                              (product.tax_rate / 100) * Number(price) +
                              parseFloat(price)
                          ).toFixed(2)
                          : Number(price)}
                      </span>
                  </Badge>
                </div>
                
          </div>
        })
      };

    return (
        <>{product.length !== 0 && <Modal
            title={`${product.name} ${product.price}`}
            okText={strings.add}
            cancelText={strings.cancel}
            onOk={handleOnClick}
            open={open}
            onCancel={onCancel}
            destoryOnClose
            okButtonProps={{ loading: loading }}
        >
            <div className="variants">
              <span>Select Quantity:</span>
              <Button
                  htmlType="button"
                  className="clear-button"
                  disabled={!isAnySelected}
                  onClick={clearSelectedVariants}
              >
                  {strings.clearCustQty}
            </Button>
              <div className="variant-wrapper" style={{width: "100%"}}>{displayCustomQty()}</div>
            </div>

            <Form layout="vertical" >
            <span>{strings.quantity}:</span>
            <div className="variant_quantity_container">
                <div>
                <Button
                    htmlType="button"
                    onClick={() => {
                        changeQuantity("minus");
                    }}
                >
                    -
                </Button>
                </div>
                <FormItem label="">
                {getFieldDecorator("quantity", {
                    rules: [
                    {
                        required: true,
                        message: strings.pleaseEnterQuantity,
                    },
                    ],
                })(
                    <Input
                    size="large"
                    onChange={(e) => {
                        if(isAnySelected === false ){
                          return Modal.warning({
                                title: strings.pleaseSelectAVariant,
                            });
                            
                        }
                        setQuantity(e.target.value)
                        form.setFieldsValue({ quantity: quantity });
                    }}
                    className="variant_input center"
                    type="number"
                    />
                )}
                </FormItem>
                <div>
                <Button
                    htmlType="button"
                    onClick={() => {
                        changeQuantity("plus");
                    }}
                >
                    +
                </Button>
                </div>
            </div>
            </Form>
        </Modal>}</>
    );
}


CustomQuantityNitro.propTypes = {
  // product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  sales: PropTypes.object.isRequired,
}; 
const CustomQuantityNitroForm = Form.create()(CustomQuantityNitro);

const mapStateToProps = (state) => ({
  products: state.product.allProducts,
  branchProducts: state.product.branchProducts,
  isNitroActive: state.appStore.isNitroActive,
});

export default connect(mapStateToProps, {})(CustomQuantityNitroForm);
