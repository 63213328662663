import React, { useState, useEffect, useRef } from 'react';
import { Input, Button } from 'antd';
import NewWindow from "react-new-window";
import "./index.scss";
import Axios from 'axios';

// http://localhost:7890/products/65431
const PriceChecker = ({ open, onCancel }) => {

    const [ value, setValue ] = useState("");
    const [ product, setProduct ] = useState([]);
    const [ searchedProduct, setSearchedProduct ] = useState({});
    const [ openNew, setOpenNew ] = useState(false);
    const [ user, setUser ] = useState([]);
    const [ errorMsg, setErrorMsg ] = useState(false);
    const myRef = useRef();

    useEffect(()=>{
        const isProduct = localStorage.getItem("products");
        const isMerchant = localStorage.getItem("userDetails");
        const productexist = isProduct && JSON.parse(isProduct);
        const merchant = isMerchant && JSON.parse(isMerchant);
        setProduct(productexist);
        setUser(merchant);
    }, []);

    // useEffect(()=>{
    //     myRef.current.focus();
    // }, []);

    const dataCoumn = [
        {
            title: "NAME",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "PRODUCT SKU",
            dataIndex: "product_sku",
            key: "product_sku"
        },
        {
            title: "SKU",
            dataIndex: "sku",
            key: "sku"
        },
        {
            title: "PRICE",
            dataIndex: "price",
            key: "price",
            render: (text, record)=>{
                return `${user.currency} ${text}`
            }
        },
    ]
    const handleChangeAndSearch = async (e) => {
        const { value } = e.target;
        setValue(value);
        if(e.code === "Enter"){
            if(value !== ""){
                const userDetails = JSON.parse(localStorage.getItem("userDetails"));
                let nitroLocation;
                if(user.employer){
                    nitroLocation = userDetails?.employer?.nitro_location;
                }else{
                    nitroLocation = userDetails?.nitro_location;
                }
                let NITROBASEURL;
                if(nitroLocation === ""){
                    NITROBASEURL = process.env.REACT_APP_NITRO_BASE_URL;
                }else if(nitroLocation === null){
                    NITROBASEURL = process.env.REACT_APP_NITRO_BASE_URL;
                }else{
                    NITROBASEURL = nitroLocation;
                }
                // const NITROBASEURL = nitroLocation !== "" && nitroLocation !== null ? `${nitroLocation}` : process.env.REACT_APP_NITRO_BASE_URL;
                
                await Axios.get(`${NITROBASEURL}/products/${value}`)
                .then((res)=>{
                    if(res.status === 200){
                        const product = res.data.data;
                        setSearchedProduct(product);
                        setOpenNew(true);
                        setValue("");
                        setErrorMsg(false);
                        myRef.current.focus();
                    }
                })
                .catch((err)=>{
                    setErrorMsg(true);
                    setValue("");
                    myRef.current.focus();
                });
            }
        }
    }

    const handleChangeAndSearchBTN = async ( ) => {
        if(value !== ""){
            const userDetails = JSON.parse(localStorage.getItem("userDetails"));
            let nitroLocation;
            if(user.employer){
            nitroLocation = userDetails?.employer?.nitro_location;
            }else{
            nitroLocation = userDetails?.nitro_location;
            }

            let NITROBASEURL;

            if(nitroLocation === ""){
                NITROBASEURL = process.env.REACT_APP_NITRO_BASE_URL;
            }else if(nitroLocation === null){
                NITROBASEURL = process.env.REACT_APP_NITRO_BASE_URL;
            }else{
                NITROBASEURL = nitroLocation;
            }
            // const NITROBASEURL = nitroLocation !== "" && nitroLocation !== null ? `${nitroLocation}:7890` : process.env.REACT_APP_NITRO_BASE_URL;
            
            await Axios.get(`${NITROBASEURL}/products/${value}`)
            .then((res)=>{
                if(res.status === 200){
                    const product = res.data.data;
                    setSearchedProduct(product);
                    setOpenNew(true);
                    setValue("");
                    setErrorMsg(false);
                    myRef.current.focus();
                }
            })
            .catch((err)=>{
                setErrorMsg(true);
                setValue("");
                myRef.current.focus();
            });
        }
    }


  return (
    <>
        {open && 
            <NewWindow
                title="Price Checker"
                center="screen"
                name="Price Checker"
                onUnload={()=>{
                    onCancel(); 
                    setOpenNew(false)
                }}
            >
                <div 
                    className="itemdiv" 
                    style={{padding: "50px"}}
                >
                    <h4>Scan the product Below</h4>
                    <Input 
                        type="text" 
                        value={value} 
                        onChange={(e)=>setValue(e.target.value)}
                        onKeyDown={(e)=>handleChangeAndSearch(e)}
                        autoFocus={true}
                        ref={myRef}
                        style={{
                            height: "67px",
                            width: "100%"
                        }}
                    />
                    <div className="butn-div">
                        <Button 
                            className="thisbtn"    
                            type="primary"
                            onClick={()=>handleChangeAndSearchBTN()}
                        >Check</Button>
                    </div>

                    {openNew 
                        && searchedProduct 
                            && <div className="searchresult">
                                    <p className="searchvalue">{searchedProduct.name}</p>
                                    <div>
                                    {searchedProduct.variants.length !== 0 && 
                                        searchedProduct.variants.map((varia, index)=>{
                                            return <div key={index} className='variantsdisplay'>
                                                <p>{varia.value}</p>
                                                <p>{`${user && user.currency} ${varia.price}`}</p>
                                            </div>
                                        })
                                    }</div>
                                    {searchedProduct.variants.length === 0 && <p className="searchvalue">{`${user && user.currency} ${searchedProduct.price}`}</p>}
                                </div>
                    }
                    {errorMsg && <p className="searchvalue">No Products Found</p>}
                </div>
            </NewWindow>}
    </>
  )
}

export default PriceChecker;