import React, { useEffect, useState } from 'react';
import { Card, Button, message, Input } from "antd";
import strings from '../../../../strings';
import { getAllReferralPrograms } from '../../../../redux/actions/referralProgramAction';
import CreateReferral from './partials/CreateReferral';
import CustomDataTable from '../../../../components/CustomDataTable';
import moment from 'moment';
import { connect } from 'react-redux';
import ReferralDetails from './partials/ReferralDetails';
import { getAllLoyaltyPrograms } from '../../../../redux/actions/loyaltyActions';
import "./index.scss";

const ReferralPrograms = ({ getAllLoyaltyPrograms, user }) => {

  const [ loading, setLoading ] = useState(false);
  const [ openCreateModal, setOpenCreateModal ] = useState(false);
  const [ allReferralPrograms, setAllReferralPrograms ] = useState([]);
  const [ openDetailsModal, setOpenDetailsModal ] = useState(false);
  const [ referralDetails, setReferralDetails ] = useState({});

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  useEffect(() => {
    handleGetAllReferralProgram();
    getAllLoyaltyPrograms();
  }, []);

  const column = [
    {
      title: strings.name,
      dataIndex: "name",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      onFilter: (value, record) => {
        // return String(record.paymentType).indexOf(value) === 0;
        return String(record.paymentType).toLowerCase().includes(value.toLowerCase());
      },
      filterDropdown: (...props) => {
        const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
          props[0];
        return (
          <section className="filter-container">
            <Input 
              onChange={(e) => setSelectedKeys([e.target.value])}
              value={selectedKeys[0]}
              // style={{ margin: "0 10px" }}
              placeholder="Search referral program name"
              onPressEnter={() =>
                confirm()
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <footer
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop: "solid 1px #E6E6E6",
              }}
            >
              <Button
                type="link"
                onClick={() => {
                  confirm();
                }}
              >
                Ok
              </Button>
              <Button
                type="link"
                onClick={() => {
                  clearFilters();
                }}
              >
                {strings.reset}
              </Button>
            </footer>
          </section>
        );
      },
      onFilter: (value, record) => {
        if (!record.name) return;
        return record.name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      filterMultiple: false,
    },
    {
      title: strings.eventCode,
      dataIndex: "event_code",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      filterDropdown: (...props) => {
        const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
          props[0];
        return (
          <section className="filter-container">
            <Input 
              onChange={(e) => setSelectedKeys([e.target.value])}
              value={selectedKeys[0]}
              placeholder="Search event code"
              onPressEnter={() =>
                confirm()
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <footer
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop: "solid 1px #E6E6E6",
              }}
            >
              <Button
                type="link"
                onClick={() => {
                  confirm();
                }}
              >
                Ok
              </Button>
              <Button
                type="link"
                onClick={() => {
                  clearFilters();
                }}
              >
                {strings.reset}
              </Button>
            </footer>
          </section>
        );
      },
      onFilter: (value, record) => {
        if (!record.event_code) return;
        return record.event_code
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      filterMultiple: false,
    },
    {
      title: "Active",
      dataIndex: "active",
      render: record => (record ? <span>True</span> : <span>False</span>),
      // ...this.getColumnSearchProps("active"),
    },
    // {
    //   title: strings.rewards,
    //   dataIndex: "reward",
    //   render: record => (record ? <span>{record}</span> : <span>N/A</span>),
    //   filterDropdown: (...props) => {
    //     const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
    //       props[0];
    //     return (
    //       <section className="filter-container">
    //         <Input 
    //           onChange={(e) => setSelectedKeys([e.target.value])}
    //           value={selectedKeys[0]}
    //           // style={{ margin: "0 10px" }}
    //           placeholder="Search referral program name"
    //           onPressEnter={() =>
    //             confirm()
    //           }
    //           style={{ width: 188, marginBottom: 8, display: "block" }}
    //         />
    //         <footer
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             alignItems: "center",
    //             borderTop: "solid 1px #E6E6E6",
    //           }}
    //         >
    //           <Button
    //             type="link"
    //             onClick={() => {
    //               confirm();
    //             }}
    //           >
    //             Ok
    //           </Button>
    //           <Button
    //             type="link"
    //             onClick={() => {
    //               clearFilters();
    //             }}
    //           >
    //             {strings.reset}
    //           </Button>
    //         </footer>
    //       </section>
    //     );
    //   },
    //   onFilter: (value, record) => {
    //     if (!record.reward) return;
    //     return record.reward
    //       .toString()
    //       .toLowerCase()
    //       .includes(value.toLowerCase());
    //   },
    //   filterMultiple: false,
    // },
    {
      title: strings.rewardType,
      dataIndex: "reward_type",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
    },
    // {
    //   title: strings.orgID,
    //   dataIndex: "organisation_id",
    //   render: record => (record ? <span>{record}</span> : <span>N/A</span>),
    // },
    {
      title: strings.createdDate,
      dataIndex: "createdAt",
      render: record => (record ? <span>{record.split("T")[0]}</span> : <span>N/A</span>),
      sorter: (a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      },
    },
    {
      title: strings.createdTime,
      dataIndex: "createdAt",
      render: record => (record ? <span>{moment(record).format("h:mm:ss A")}</span> : <span>N/A</span>),
      // sorter: (a, b) => {
      //   return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      // },
    },
  ]

  const handleGetAllReferralProgram = () => {
    setLoading(true);

    getAllReferralPrograms(user)
    .then((res) => {
      setAllReferralPrograms(res.data);
      setLoading(false);
    })
    .catch((err) => {
      message.error(`${err?.response?.data?.message || "Unable to get referral program"}`)
      setLoading(false);
    })
  }

  const handleRowSelection = (programs) => {
    setReferralDetails(programs);
    setOpenDetailsModal(true);
  }

  return (
    <main>
      <Card
        title={strings.referralProgram}
        loading={loading}
        extra={
          <>
            <Button 
              type="primary"
              onClick={() => setOpenCreateModal(true)}
            >
              {strings.createReferralProgram}
            </Button>
          </>
        }
      >
        <CustomDataTable 
          columns={column}
          dataSource={allReferralPrograms}
          loading={loading}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleRowSelection(record);
              },
            };
          }}
          // rowSelection={rowSelection}

        />
      </Card>

      {openCreateModal && (
        <CreateReferral 
          open={openCreateModal}
          onCancel={() => setOpenCreateModal(false)}
          handleGetAllReferralProgram={handleGetAllReferralProgram}
        />
      )}

      {openDetailsModal && (
        <ReferralDetails 
          open={openDetailsModal}
          onCancel={() => setOpenDetailsModal(false)}
          referralDetails={referralDetails}
          handleGetAllReferralProgram={handleGetAllReferralProgram}
          user={user}
        />
      )}

      
    </main>
  )
}

const mapStateToProps = state => ({
  user: state.auth.user,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
});

export default connect(mapStateToProps, {
  getAllLoyaltyPrograms
})(ReferralPrograms);