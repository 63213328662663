import React from "react";
import { Modal, Input, Select, message } from "antd";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";

// Redux
import { connect } from "react-redux";
import { redeemReward } from "../../../../../../redux/actions/customerActions";
import { getAllLoyaltyPrograms } from "../../../../../../redux/actions/loyaltyActions";
import strings from "../../../../../../strings";

// import "./index.scss";

const FormItem = Form.Item;
const Option = Select.Option;

class RedeemRewardModal extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    const { allLoyaltyPrograms } = this.props;
    if (allLoyaltyPrograms.length === 0) {
      this.props.getAllLoyaltyPrograms();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ loading: false });
    }
  }

  handleRedeemReward = () => {
    this.setState({ loading: true });
    const { form, customer, closeModal } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        this.setState({ loading: false });
        return;
      }

      this.setState({ loading: true });
      this.props
        .redeemReward({
          redemption_code: values.rewardCode,
          customer_id: customer.id,
          loyalty_program_id: values.loyaltyProgramId,
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            this.props.form.resetFields();

            Modal.success({
              title: strings.youSuccessfullyRedeemedReward,
              onOk: () => closeModal(),
            });

            this.setState({ loading: false });
          }
        })
        .catch(() => {
          this.setState({ loading: false });

          Modal.error({
            title: strings.redeemRewardFailed,
          });
        });
    });
  };

  render() {
    const {
      showModal,
      closeModal,
      form: { getFieldDecorator },
      allLoyaltyPrograms,
    } = this.props;

    const { loading } = this.state;

    return (
      <Modal
        title={strings.redeemReward}
        visible={showModal}
        okText={strings.redeemReward}
        cancelText={strings.cancel}
        okButtonProps={{ loading }}
        onCancel={closeModal}
        onOk={this.handleRedeemReward}
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
      >
        <Form layout="vertical">
          <div id="add-customer">
            <FormItem label={strings.sixDigitCode}>
              {getFieldDecorator("rewardCode", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseEnterCustomerRewardCode,
                  },
                ],
              })(<Input size="large" placeholder={strings.enterRewardCode} />)}
            </FormItem>
            <FormItem label={strings.selectLoyaltyProgram}>
              {getFieldDecorator("loyaltyProgramId", {
                rules: [
                  {
                    required: true,
                    message: strings.selectLoyaltyProgram,
                  },
                ],
              })(
                <Select size="large" style={{ width: "200px !important" }}>
                  <Option value="add-new-program">
                    {strings.selectLoyaltyProgram}
                  </Option>
                  {allLoyaltyPrograms &&
                    allLoyaltyPrograms.map((loyaltyProgram) => (
                      <Option value={loyaltyProgram.id} key={loyaltyProgram.id}>
                        {loyaltyProgram.name} - {loyaltyProgram.reward} -{" "}
                        {`${loyaltyProgram.threshold} ${
                          loyaltyProgram.program_type === "SimplePoints"
                            ? strings.points
                            : strings.stamps
                        }`}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
          </div>
        </Form>
      </Modal>
    );
  }
}

const RedeemRewardForm = Form.create()(RedeemRewardModal);

RedeemRewardForm.propTypes = {
  error: PropTypes.string.isRequired,
  redeemReward: PropTypes.func.isRequired,
  getAllLoyaltyPrograms: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
});

export default connect(mapStateToProps, {
  redeemReward,
  getAllLoyaltyPrograms,
})(RedeemRewardForm);
