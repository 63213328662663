import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Input,
  Button,
  Row,
  Col,
  Icon,
  Card,
  Select,
  Radio,
  message,
  Switch,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import {
  ADD_FORM,
  LIST_FORMS,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";
import { useMutation } from "@apollo/client";

import AddCustomField from "./AddCustomField";

const { Option } = Select;

const CustomFields = ({ form, user, visible, onCancel }) => {
  const [addFieldModalVisible, showAddFieldModalVisible] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [editField, setEditField] = useState(null);
  const [editCustomFieldVisible, setEditCustomFieldVisible] = useState(false);

  const { getFieldDecorator } = form;

  const [addForm, { loading: addFormLoading }] = useMutation(ADD_FORM, {
    onCompleted: data => {
      data.addForm &&
        Modal.success({
          title: "Form created successfully",
          onOk: () => {
            form.resetFields();
            localStorage.removeItem("form-fields");
            onCancel();
          },
          okButtonProps: {
            id: "btn-component",
          },
        });
    },
    onError: error => {
      message.error(error.message, 5);
    },
    refetchQueries: [{ query: LIST_FORMS }],
  });

  const handleAddFormSubmit = async () => {
    return form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (formFields.length < 1)
        return message.error(
          "You cannot create a form without adding form fields!"
        );
      let payload = { ...values, form_fields: JSON.stringify(formFields) };

      return addForm({ variables: { ...payload } });
    });
  };

  const handleAddFieldSubmit = field => {
    const newFields = [...formFields, field];
    setFormFields(newFields);
  };

  const confirmFormCancel = () => {
    Modal.confirm({
      title: "Cancel Form Creation",
      content: "Do you want to stop creating this form?",
      onOk: onCancel,
    });
  };

  const confirmDeleteField = field => {
    Modal.confirm({
      title: "Delete Form Field",
      content: "Do you want to delete this form field?",
      onOk: () => handleDeleteField(field),
    });
  };

  const handleDeleteField = field => {
    const newFields = formFields.filter(
      myField => myField.field_name !== field.field_name
    );
    localStorage.setItem("form-fields", JSON.stringify({ fields: newFields }));
    setFormFields(newFields);
  };

  const handleEditField = field => {
    const myFieldIndex = formFields.findIndex(
      myField => myField.field_name === field.field_name
    );
    const newFields = formFields.slice();
    newFields[myFieldIndex] = field;
    setFormFields(newFields);
    localStorage.setItem("form-fields", JSON.stringify({ fields: newFields }));
    setEditField(null);
    setEditCustomFieldVisible(false);
  };

  const EditCustomFieldForm = ({
    form,
    field,
    onCancel,
    visible,
    handleEditField,
  }) => {
    const [singleFieldInputType, setSingleFieldInputType] = useState(
      field.field_input_type
    );

    const { getFieldDecorator } = form;
    const FormItem = Form.Item;
    const {
      field_name,
      field_input_type,
      select_options,
      checklist_options,
      radio_options,
      required_field,
      notes,
    } = field;
    const handleEditData = () => {
      form.validateFields((err, values) => {
        if (err) return;
        handleEditField(values);
      });
    };

    return (
      <Modal
        title="Edit Form Field"
        visible={visible}
        footer={[
          <Button type="ghost" onClick={onCancel}>
            Cancel
          </Button>,
          <Button type="primary" onClick={handleEditData}>
            Edit
          </Button>,
        ]}
      >
        <FormItem label="Field Name">
          {getFieldDecorator("field_name", {
            initialValue: field_name,
            rules: [
              {
                required: true,
              },
            ],
          })(<Input size="large" />)}
        </FormItem>
        <FormItem label="Field Input Type">
          {getFieldDecorator("field_input_type", {
            initialValue: field_input_type,
            rules: [
              {
                required: true,
                message: "Please enter custom field input type",
              },
            ],
          })(
            <Select
              placeholder="Select Field Input Type"
              size="large"
              onChange={val => setSingleFieldInputType(val)}
            >
              <Option value="name">Name field</Option>
              <Option value="email">Email field</Option>
              <Option value="phone_number">Phone Number field</Option>
              <Option value="text">Text field</Option>
              <Option value="number">Number field</Option>
              <Option value="select">Select Options</Option>
              <Option value="checklist">Check List</Option>
              <Option value="radio">Radio Fields</Option>
              <Option value="switch">Switch button</Option>
            </Select>
          )}
        </FormItem>
        {singleFieldInputType === "select" && (
          <>
            <FormItem label="Options to Select">
              {getFieldDecorator("select_options", {
                initialValue: select_options,
                rules: [
                  {
                    required: true,
                    message: "Please enter the options list to select from",
                  },
                ],
              })(
                <Input
                  placeholder="Enter options separated by a comma and a space"
                  size="large"
                />
              )}
            </FormItem>
          </>
        )}

        {singleFieldInputType === "checklist" && (
          <>
            <FormItem label="Options to Check">
              {getFieldDecorator("checklist_options", {
                initialValue: checklist_options,
                rules: [
                  {
                    required: true,
                    message: "Please enter the options list to check from",
                  },
                ],
              })(
                <Input
                  placeholder="Enter options separated by a comma and a space"
                  size="large"
                />
              )}
            </FormItem>
          </>
        )}
        {singleFieldInputType === "radio" && (
          <>
            <FormItem label="Radio Choices">
              {getFieldDecorator("radio_options", {
                initialValue: radio_options,
                rules: [
                  {
                    required: true,
                    message: "Please enter the options list to select from",
                  },
                ],
              })(
                <Input
                  placeholder="Enter options separated by a comma and a space"
                  size="large"
                />
              )}
            </FormItem>
          </>
        )}
        <FormItem label="Is Field Compulsory?">
          {getFieldDecorator("required_field", {
            initialValue: required_field,
            rules: [
              {
                required: true,
                message: "Please choose if field must be filled or not",
              },
            ],
          })(
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="Additional Notes">
          {getFieldDecorator("notes", { initialValue: notes })(
            <Input.TextArea
              placeholder="Enter any additional instructions"
              size="large"
              rows={2}
            />
          )}
        </FormItem>
      </Modal>
    );
  };

  const EditCustomField = Form.create()(EditCustomFieldForm);

  return (
    <>
      {editField && (
        <EditCustomField
          field={editField}
          visible={editCustomFieldVisible}
          handleEditField={handleEditField}
          onCancel={() => {
            setEditField(null);
            setEditCustomFieldVisible(false);
          }}
        />
      )}
      <Modal
        visible={visible}
        title="Add New Form"
        width={700}
        onCancel={confirmFormCancel}
        onOk={handleAddFormSubmit}
        okText="Create"
        okButtonProps={{ loading: addFormLoading }}
      >
        <Form layout="vertical">
          <Form.Item label="Form Name">
            {getFieldDecorator("form_name", {
              rules: [
                {
                  required: true,
                  message: "Please enter form name",
                },
              ],
            })(<Input placeholder="Enter form name" size="large" />)}
          </Form.Item>
          <Form.Item label="Recipient Emails">
            {getFieldDecorator("recipient_emails", {
              rules: [
                {
                  required: true,
                  message: "Please enter emails",
                },
              ],
            })(
              <Input
                placeholder="Separate multiple emails with a comma and space"
                type="email"
                size="large"
              />
            )}
          </Form.Item>
          <Form.Item label="Add to main menu">
            {getFieldDecorator("active")(<Switch />)}
          </Form.Item>
        </Form>
        <div>
          <h3>Form Fields</h3>
          {formFields.length < 1 && <em>No form fields added yet</em>}
          {formFields.length > 0 &&
            formFields.map(field => (
              <div>
                <Card hoverable>
                  <Row key={field.field_name}>
                    <Col span={20}>{field.field_name}</Col>
                    <Col span={2}>
                      <Icon
                        type="edit"
                        title="Edit field"
                        onClick={() => {
                          setEditField(field);
                          setEditCustomFieldVisible(true);
                        }}
                      />
                    </Col>
                    <Col span={2}>
                      <Icon
                        type="delete"
                        title="Delete field"
                        onClick={() => confirmDeleteField(field)}
                      />
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginTop: "20px" }}
          onClick={() => showAddFieldModalVisible(true)}
        >
          Add New Field
        </Button>
      </Modal>
      <AddCustomField
        visible={addFieldModalVisible}
        onCancel={() => showAddFieldModalVisible(false)}
        handleAddFieldSubmit={handleAddFieldSubmit}
      />
    </>
  );
};

export default connect()(Form.create()(CustomFields));
