import React from "react";
import { Modal, Input } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";
import {
  deleteInvoice,
  getAllInvoices,
} from "../../../../../../redux/actions/invoiceActions";

import { connect } from "react-redux";
import strings from "../../../../../../strings";

class DeleteInvoiceModal extends React.Component {
  state = {
    loading: false,
  };

  handleDeleteInvoice = () => {
    const {
      form,
      deleteInvoice,
      getAllInvoices,
      invoice: { id },
      closeModal,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      if (values.delete.toLowerCase() !== "delete") {
        Modal.error({
          title: strings.pleaseTypeIn + " delete" + strings.toDeleteThisInvoice,
        });
      } else {
        this.setState({ loading: true });
        deleteInvoice(id).then(res => {
          this.setState({ loading: false });
          Modal.success({
            title: strings.invoiceDeletedSuccessfully,
          });

          getAllInvoices();
          closeModal();
        });
      }
    });
  };

  render() {
    const {
      invoice: { number },
      form: { getFieldDecorator },
      showModal,
      closeModal,
    } = this.props;

    return (
      <Modal
        title={`${strings.deleteInvoice} ${number}`}
        okText="Delete"
        cancelText="Cancel"
        visible={showModal}
        destroyOnClose
        onCancel={closeModal}
        onOk={this.handleDeleteInvoice}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical" >
          <Form.Item
            label={strings.pleaseTypeIn + " delete " + strings.toConfirm}
          >
            {getFieldDecorator("delete", {
              rules: [
                {
                  required: true,
                  message:
                    strings.pleaseTypeIn +
                    " delete " +
                    strings.toConfirm +
                    "deletion",
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const DeleteInvoiceForm = Form.create()(DeleteInvoiceModal);

DeleteInvoiceForm.propTypes = {
  invoice: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(null, {
  deleteInvoice,
  getAllInvoices,
})(DeleteInvoiceForm);
