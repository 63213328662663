import { GET_ALL_CATEGORIES } from "../constants";
import api from "../../api";

export const getAllCategories = (time_stamp = 0) => async dispatch => {
  const res = await api.category.getAllCategories(time_stamp);
  if (res && res.status === 200) {
    dispatch({
      type: GET_ALL_CATEGORIES,
      payload: (res && res.data) || [],
    });
  }
  return Promise.resolve(res);
};

export const addNewCategory = (resquestPayload = {}) => dispatch => {
  return api.category.addNewCategory(resquestPayload);
};

export const editCategory = (id, data) => dispatch => {
  return api.category.editCategory(id, data);
};

export const deleteCategory = id => dispatch => {
  return api.category.deleteCategory(id);
};
