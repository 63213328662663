
import { GET_MERCHANT_PLAN_DATE } from "../constants";
import { SET_CURRENT_USER } from "../constants";

const initialState = {
    isCurrentPlanDate: null,
};

const merchantReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: payload.id ? true : false,
        user: payload.id ? payload : {},
      };

    case GET_MERCHANT_PLAN_DATE:
      return {
        ...state,
        isCurrentPlanDate: payload,
      };

    default:
      return state;
  }
};

export default merchantReducer;
