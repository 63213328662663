import React, { useEffect, useState } from "react";
import { Card, Tabs, Modal } from "antd";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import BookKeeping from "./_partials/BookKeeping";
import Payments from "./_partials/Payments";
import AppCategory from "./_partials/AppCategory";
import { fetchAllApps } from "../../../../redux/actions/appActions";
import strings from "../../../../strings";
import { activateStripForLoystar } from "../../../../redux/actions/appActions";
import { disableAppIntegration, fetchActiveApps } from "../../../../redux/actions/appActions";
import Nitro from "./_partials/Nitro";

const { TabPane } = Tabs;

const AppStore = ({ 
  fetchAllApps, 
  apps, 
  activateStripForLoystar, 
  disableAppIntegration,
  fetchActiveApps
 }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("1");
  const [isLoading, setIsLoading] = useState(false);


  const tabList = [
    {name: "payments", key: "1"},
    {name: "bookkeeping", key: "2"},
    {name: "marketing", key: "6"},
    {name: "logistics", key: "3"},
    {name: "communication", key: "4"},
    {name: "ecommerce", key: "5"},
  ]

  useEffect(()=>{
    location && location.search !== "" && handleActiveKey();
  }, []);

  const handleActiveKey = () => {
    // console.log("locate", location);
    const split = location && location.search.split("?")[1].split("&")[0];
    const searchPartList = tabList.filter((tabl)=>split.toLowerCase().trim() === tabl.name);
    // console.log("here",split, searchPartList);
    searchPartList.length > 0 && setActiveTab(searchPartList[0].key);
  }


  const disableApp = async () => {
    const ap = apps && apps.find((app)=>{
      return app.app_name === "Stripe"
    });

    const { app_name, id } = ap
    setIsLoading(true);
    try {
      const res = await disableAppIntegration(id);
      if (res && res.status === 200) {
        setIsLoading(false);

        const isStripe = app_name === "Stripe";

        isStripe && activateStripForLoystar(false);
    
        const { message } = res.data;

        // Modal.success({
        //   title: message,
        //   onOk: async () => {
            await fetchActiveApps();
            // toggleViewDetailsModal();
        //   },
        // });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const { search } = location;
    if(apps.length > 0){
      if(search !== ""){
        const searchQuery = search.split("=")[1];
        if (searchQuery === "true") {
          Modal.success({
            title: "Stripe account successfully activated. You can now collect online payments with with Card, Apple Pay, Google Pay and more.",
            onOk: () => {
              activateStripForLoystar(true);
            }
          })
        } else if(searchQuery === "false"){
          Modal.error({
            title: "Unable to complete stripe onboarding, please try again",
            onOk: () => {
              disableApp();
              activateStripForLoystar(false);
            }
          })
        }
      }
    }
    
  }, [location, apps]);

  useEffect(() => {
    if (location.state && location.state.isFromCheckout) {
      setActiveTab("2");
    }
    setIsLoading(true);

    fetchAllApps()
      .then((res) => setIsLoading(false))
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [location.state, fetchAllApps]);

  return (
    <Card
      className="app-store"
      title={strings.addOns}
      bordered={false}
      loading={isLoading}
    >
      <section className="page-container integrations">
        <Tabs
          activeKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
        >
          <TabPane tab="Payments" key="1">
            <Payments
              apps={apps.filter(
                ({ category }) => category.toLowerCase() === "payments"
              )}
            />
          </TabPane>

          <TabPane tab="Book Keeping" key="2">
            <BookKeeping
              apps={apps.filter((app) => app.category === "book keeping")}
            />
          </TabPane>

          <TabPane tab="Marketing" key="6">
            <AppCategory
              apps={apps.filter(
                ({ category }) => category.toLowerCase() === "marketing"
              )}
            />
          </TabPane>
          <TabPane tab="Logistics" key="3">
            <AppCategory
              apps={apps.filter(
                ({ category }) => category.toLowerCase() === "logistics"
              )}
            />
          </TabPane>

          <TabPane tab="Communication" key="4">
            <AppCategory
              apps={apps.filter(
                ({ category }) => category.toLowerCase() === "communication"
              )}
            />
          </TabPane>

          <TabPane tab="e-Commerce" key="5">
            <AppCategory
              apps={apps.filter(
                ({ category }) => category.toLowerCase() === "e-commerce"
              )}
            />
          </TabPane>

          {/* <TabPane tab="Nitro" key="7">
            <Nitro
              // apps={apps.filter(
              //   ({ category }) => category.toLowerCase() === "nitro"
              // )}
            />
          </TabPane> */}
        </Tabs>
      </section>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  apps: state.appStore.apps,
  appCategories: state.appStore.categories,
});

export default connect(mapStateToProps, { 
  fetchAllApps, 
  activateStripForLoystar, 
  disableAppIntegration, 
  fetchActiveApps
 })(AppStore);
