import React, { useState, useEffect} from 'react'
import { Col, Row } from "antd";


const BundleListItem = ({ item, currency, onClick }) => {
    return (
        <Col xs={12} sm={12} md={8} lg={6}>
          <div className="item">
            <a
              href
              onClick={() =>onClick(item)} 
            >
              <div className="item-image-container">
                {/* <img src={item.picture} alt={item.name} /> */}
                {item.picture ? (
                  <img src={item.picture} alt={item.name} />
                ) : item.images.length > 0 ? (
                  <img src={item.images[0]} alt={item.name} />
                ) :  (
                  <span>{item && item?.name?.substr(0, 2)}</span>
                )}
              </div>
              <div className="item-details">
                <h5>{item.name}</h5>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <h4 className="price">
                    {currency}
                    {item.price}
                  </h4>
                  {item.track_inventory && (
                    <span
                    //   className={`quantity ${this.computeQuantityClass(item)}`}
                    >
                      {item.quantity ? parseInt(item.quantity) : 0}
                    </span>
                  )}
                </div>
              </div>
            </a> 
          </div>
        </Col>
      );
}

export default BundleListItem