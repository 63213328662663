import React, { useEffect } from "react";
import strings from "../../../../strings";
import numberFormatter from "../../../../utils/numberFormatter";
import AppsPrice from "./_partial/AppsPrice";

const BasicPaymentDetails = ({
  plan,
  staffs,
  noOfStaffs,
  staffData,
  duration,
  setTotal,
  paymentOptions,
  hasStaffs,
  updateMetaInfo,
  activePaidApps,
  user,
  totalAppsPrice
}) => {
  const {
    price: staffPrice,
    discount_percentage: staff_discount_percentage,
  } = staffData;

  const base_price = Number(plan.price ? plan.price : 0);

  // Discount Amount Charged for Staffs
  const staffDiscount = Number(staffPrice) * staff_discount_percentage;

  const discounted_staff_price = numOfStaffs => {
    return ((Number(staffPrice) - staffDiscount) * numOfStaffs).toFixed(2);
  };

  const undiscounted_staff_price = numOfStaffs => {
    return (Number(staffPrice) * numOfStaffs).toFixed(2);
  };

  const renewal = {
    total_staff_price:
      staffs.length > 3
        ? discounted_staff_price(staffs.length)
        : undiscounted_staff_price(staffs.length),

    total_apps_price: 
      totalAppsPrice 
        ? Number(totalAppsPrice) 
          : 0
  };

  const upgrade = {
    total_staff_price:
      noOfStaffs > 3
        ? discounted_staff_price(noOfStaffs)
        : undiscounted_staff_price(noOfStaffs),
  };

  const renewal_subTotal = (
    Number(base_price) + 
    Number(renewal.total_staff_price) + 
    renewal.total_apps_price
  ).toFixed(2);

  const upgrade_subTotal = (
    Number(base_price) + Number(upgrade.total_staff_price)
  ).toFixed(2);

  const subTotal =
    paymentOptions === "renewal"
      ? renewal_subTotal
      : paymentOptions === "upgrade"
      ? upgrade_subTotal
      : 0;

  const total = Number(subTotal * duration).toFixed(2);

  useEffect(() => {
    if (total) {
      setTotal(total);
      updateMetaInfo({
        // no_of_staffs: "",
        // no_of_branches: "",
        no_of_months: duration,
      });
    }
  }, [total]);

  return (
    <div className="payment-breakdown">
      <p>
        <strong>{strings.paymentDetails}</strong>
      </p>

      <div className="details">
        <p>
          <span>{strings.basePrice}: </span>
          <span>{`${plan && plan.currency}${numberFormatter(
            base_price && base_price.toFixed(2)
          )}`}</span>
        </p>

        {paymentOptions === "renewal" && staffs.length >= 1 && (
          <p>
            <span>
              {strings.staff} ({staffs.length}) @ {plan.currency}
              {numberFormatter(staffPrice)}
              {staffs.length > 3 &&
                ` || ${staff_discount_percentage * 100}% discount applies`}
            </span>

            <span>
              {plan.currency}
              {numberFormatter(renewal.total_staff_price)}{" "}
            </span>
          </p>
        )}

        {paymentOptions === "renewal" 
          && activePaidApps 
            && activePaidApps.length > 0 
              && <AppsPrice 
            activePaidApps={activePaidApps}
            user={user}
            totalAppsPrice={totalAppsPrice}
            fromStripeSubscription={false}
        />}

        {paymentOptions === "upgrade" && hasStaffs && noOfStaffs >= 1 && (
          <p>
            <span>
              {strings.staff} ({noOfStaffs}) @ {plan.currency}
              {numberFormatter(staffPrice)}
              {noOfStaffs > 3 &&
                ` || ${staff_discount_percentage * 100}% discount applies`}
            </span>

            <span>
              {plan.currency}
              {numberFormatter(upgrade.total_staff_price)}{" "}
            </span>
          </p>
        )}
      </div>

      <div className="total-wrapper">
        <div className="subtotal">
          <p>
            <strong>{strings.subTotal}: </strong>
            <span>
              {plan.currency}
              {numberFormatter(subTotal)}
            </span>
          </p>

          <p>
            <strong>{strings.durationMonths}: </strong>
            <span>
              x <span className="multiply">{duration}</span>
            </span>
          </p>
        </div>

        <p className="total">
          <strong>{strings.total}: </strong>
          <span>
            {plan.currency}
            {numberFormatter(total)}
          </span>
        </p>
      </div>
    </div>
  );
};

export default BasicPaymentDetails;
