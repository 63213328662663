import React, { useState } from "react";
import { Modal, Popover, message, Button } from "antd";
import { useMutation } from "@apollo/client";
import { NavLink } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  COMPLETE_BOOKING,
  DELETE_BOOKING,
  GET_BOOKINGS,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";

import EditBooking from "./EditBooking";

const BookingDetails = ({
  onCancel,
  currentBooking,
  visible,
  customers,
  branchObj,
  branches,
  services,
  user
}) => {
  const [showEditModal, setShowEditModal] = useState(false);

  const [deleteBooking] = useMutation(DELETE_BOOKING, {
    onCompleted: data => {
      data.deleteBooking &&
        Modal.success({
          title: "Booking deleted successfully",
          onOk: onCancel,
        });
    },
    onError: error => {
      message.error(error.message, 5);
    },
    refetchQueries: [{ query: GET_BOOKINGS }],
  });

  const [completeBooking] = useMutation(COMPLETE_BOOKING, {
    onCompleted: data => {
      data.completeBooking &&
        Modal.success({
          title: "Booking marked as completed",
          onOk: onCancel,
        });
    },
    onError: error => {
      message.error(error.message, 5);
    },
    refetchQueries: [{ query: GET_BOOKINGS }],
  });

  const confirmDeleteBooking = () =>
    Modal.confirm({
      title: "Delete Booking",
      content: "Are you sure you want to delete this booking?",
      onOk: () => deleteBooking({ variables: { id: currentBooking.id } }),
    });

  const confirmCompleteBooking = () =>
    Modal.confirm({
      title: "Complete Booking",
      content: "Are you sure you want to mark this booking as completed?",
      onOk: () => completeBooking({ variables: { id: currentBooking.id } }),
    });

  const isAdmin = user.role_id === 1;

  const actionsContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        key={1}
        icon={<EditOutlined />}
        onClick={() => setShowEditModal(true)}
      >
        Edit
      </Button>
      {isAdmin && <Button
        key={2}
        icon={<DeleteOutlined />}
        onClick={confirmDeleteBooking}
        style={{ marginTop: "5px" }}
      >
        Delete
      </Button>}
      <Button
        key={3}
        icon={<CheckCircleOutlined />}
        onClick={confirmCompleteBooking}
        disabled={currentBooking.status === "completed"}
        style={{ marginTop: "5px" }}
      >
        Mark as completed
      </Button>
      <Button
        key={4}
        disabled={currentBooking.payment_status === "paid"}
        style={{ marginTop: "5px" }}
      >
        <NavLink to={`/sales?services/${currentBooking.id}`}>
          Checkout Booking
        </NavLink>
      </Button>
    </div>
  );

  const {
    customer_id,
    service_name,
    amount_charged,
    completion_time,
    branch_name,
    repeat,
    repeat_frequency,
    repeat_number,
    staff_name,
    booking_date,
    created_at,
    payment_status,
  } = currentBooking;

  const customer = customers && customers.find(cstmr => cstmr.id === customer_id || cstmr.user_id === customer_id);

  return (
    <Modal
      open={visible}
      title="Booking Details"
      onCancel={onCancel}
      footer={[
        <Popover key="actions" title="Actions" content={actionsContent}>
          <Button type="primary" onClick={() => setShowEditModal(true)}>
            Actions
          </Button>
        </Popover>,
        <Button onClick={onCancel} type="ghost">
          Cancel
        </Button>,
      ]}
    >
      {showEditModal && (
        <EditBooking
          visible={showEditModal}
          currentBooking={currentBooking}
          onCancel={() => setShowEditModal(false)}
          closeModal={onCancel}
          customers={customers}
          services={services}
          branches={branches}
          branchObj={branchObj}
        />
      )}

      <p>
        <strong>Customer Name: </strong>
        {`${customer && customer.first_name ? customer.first_name : "N/A"} ${
          customer && customer.last_name ? customer.last_name : ""
        }`}
        {/* ${customer && customer.phone_number ? ":" + customer.phone_number : ""} */}
      </p>
      <p>
        <strong>Service Name: </strong>
        {service_name}
      </p>
      <p>
        <strong>Amount: </strong>
        {amount_charged}
      </p>
      <p>
        <strong>Duration: </strong>
        {completion_time} mins
      </p>
      <p>
        <strong>Branch: </strong> {branch_name}
      </p>
      <p>
        <strong>Staff: </strong> {staff_name}
      </p>
      <p>
        <strong>Appointment Date: </strong> {booking_date}
      </p>
      <p>
        <strong>Created At: </strong>{" "}
        {new Date(Number(created_at)).toLocaleString()}
      </p>
      <p>
        <strong>Payment status: </strong> {payment_status}
      </p>
      {repeat && (
        <>
          <p>
            <strong>Repeat Frequency: </strong> Every {repeat_frequency} weeks
          </p>
          <p>
            <strong>Ends after: </strong> {repeat_number} times
          </p>
        </>
      )}
    </Modal>
  );
};

export default BookingDetails;
