import React from "react";
import { Col } from "antd";
import PropTypes from "prop-types";

import VariantsModal from "../VariantsModal/index";

import { connect } from "react-redux";
import VariantsModals from "../VariantsModal/inde";
import VariantsModal2 from "../VariantsModal/index2";
import CustomQuantity from "../CustomQtyModal";
import { clearShowCustomQtyModalData } from "../../../../redux/actions/saleActions";

class ProductListItem extends React.Component {
  state = {
    showVariantModal: false,
    showCustomQuantityModal: false,
    stateItem: null
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.sales) {
      const { sale } = nextProps.sales;
      const { item } = this.props;

      if (sale) {
        if (sale.id === item.id && sale.type === "SHOW_VARIANT_MODAL") {
          this.toggleModal();
        }

        if (sale.id === item.id && sale.type === "SHOW_CUSTOMQTY_MODAL") {
          this.toggleCustomQtyModal();
        }
      }
    }
  }

  computeQuantityClass = ({ quantity: quant }) => {
    const quantity = parseInt(quant);
    if (quantity === 0) {
      return "no-stock";
    } else if (quantity > 0 && quantity <= 5) {
      return "low-stock";
    } else if (quantity > 5) {
      return "stocked";
    }
  };

  toggleModal = () => {
    const { showVariantModal } = this.state;
    this.setState({
      showVariantModal: !showVariantModal,
    });
  };

  toggleCustomQtyModal = () => {
    const { showCustomQuantityModal } = this.state;
    this.setState({
      showCustomQuantityModal: !showCustomQuantityModal,
    });
    
  };

  handleVariantClick = (item, type) => {
    const { onClick } = this.props;
    this.setState({
      showVariantModal: false,
    });

    onClick(item, type);
  };

  handleCustomQtyClick = (item, type) => {
    const { onClick } = this.props;
    this.setState({
      showCustomQuantityModal: false,
    });

    onClick(item, type);
  };
  confirmProductPrice = () => {
    const { item, toggleCustomPriceModal, onClick } = this.props;

    if (Number(item.price) === 0) {
      toggleCustomPriceModal(item);
    } else {
      if (item.variants && item.variants.length > 0) {
        this.toggleModal();
      } else if(item.custom_quantities && item.custom_quantities.length > 0){
        this.setState({ stateItem: item })
        this.toggleCustomQtyModal();
      }else {
        const newItem = { ...item };
        newItem.quantity = 1;
        onClick(newItem);
      }
    }
  };

  render() {
    const { item, currency, cartList, sale, user } = this.props;
    const { showVariantModal, showCustomQuantityModal, stateItem } = this.state;

    const isCashier = user.role && user.role.id === 3;
    const isAdminIsManager = user.role && user.role.id === 2 || user.role_id === 1
    return (
      <Col xs={12} sm={12} md={8} lg={6}>
        <div className="item">
          <a
            href
            onClick={() => {
              if (this.props.location?.state?.fromInstoreOrder) {
                window.history.replaceState({}, document.title);
                window.location.reload();
              }
              if (Number.isInteger(item.quantity) && item.quantity < 1) return;

              this.confirmProductPrice();
            }}
            
          >
            <div className="item-image-container">
              {/* <img src={item.picture} alt={item.name} /> */}
              {item.picture ? (
                <img src={item.picture} alt={item.name} />
              ) : (
                <span>{item && item?.name?.substr(0, 2)}</span>
              )}
            </div>
            <div className="item-details">
              <h5>{item.name}</h5>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <h4 className="price">
                  {currency}
                  {item.price}
                </h4>
                {isCashier && !user.employer.hide_cashier_inventory && item.track_inventory && (
                  <span
                    className={`quantity ${this.computeQuantityClass(item)}`}
                  >
                    {item.quantity ? parseInt(item.quantity) : 0}
                  </span>
                )}
                {isAdminIsManager && item.track_inventory && (
                  <span
                    className={`quantity ${this.computeQuantityClass(item)}`}
                  >
                    {item.quantity ? parseInt(item.quantity) : 0}
                  </span>
                )}
              </div>
            </div>
          </a>
          {/* {item.variants.length > 0 ? (
            <VariantsModal
              product={item}
              toggleModal={() => {
                this.toggleModal();
              }}
              showVariantModal={showVariantModal}
              onClick={this.handleVariantClick}
              currency={currency}
            />
          ) : null} */}
          {showVariantModal && <VariantsModal2 
            product={item}
              toggleModal={() => {
                this.toggleModal();
              }}
              showVariantModal={showVariantModal}
              cartList={cartList}
              // showVariantModal={true}
              onClick={this.handleVariantClick}
              currency={currency}
              handleBlurFactor={this.props.handleBlurFactor}
          />}

          {showCustomQuantityModal && <CustomQuantity 
              product={item}
              toggleModal={() => {
                this.toggleCustomQtyModal();
              }}
              showCustomQuantityModal={showCustomQuantityModal}
              cartList={cartList}
              // showVariantModal={true}
              onClick={this.handleCustomQtyClick}
              currency={currency}
              handleBlurFactor={this.props.handleBlurFactor}
          />}
        </div>
      </Col>
    );
  }
}

ProductListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  sales: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sales: state.sale,
  sale: state.sale.sale,
});

export default connect(mapStateToProps, {})(ProductListItem);
