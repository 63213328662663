export default class Credley {
    constructor(client){
        this.client = client;
    }

    initiateTransaction(phone_number){
        return this.client.post("/merchant/credpal_customer/initiate_checkout", { phone_number })
    }

    checkoutTransaction(data){
        return this.client.post("/merchant/credpal_customer/checkout", { data })
    }

    updateCustomerBVN(data){
        return this.client.put("/merchant/customer/update_bvn", {data})
    }
}