import React from "react";
import { Modal, Button, message, Input } from "antd";
import { Form } from "@ant-design/compatible";
import { useMutation } from "@apollo/client";
import {
  DELETE_TIER,
  DELETE_TIER_SUCCESS,
  LIST_LOYALTIES,
  LIST_TIERS,
} from "../../../../constants";
import strings from "../../../../../../../../../../strings";

const DeleteTierModal = ({ visible, closeModal, tierId, loyalty_id, form }) => {
  const [deleteTier, { loading: isDeleteTierLoading }] = useMutation(
    DELETE_TIER,
    {
      onCompleted: data => {
        console.log(data);
        closeModal();

        Modal.success({
          title: DELETE_TIER_SUCCESS,
          okButtonProps: {
            id: "btn-component",
          },
        });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [
        // { query: GET_LOYALTY, variables: { id: loyalty_id } },
        { query: LIST_TIERS, variables: { loyalty_id } },
        { query: LIST_LOYALTIES }
      ],
    }
  );

  const handleDeleteTier = async () => {
    try {
      const values = await form.validateFields();

      if (values.delete.toLowerCase() !== "delete") {
        Modal.error({
          title: strings.pleaseTypeIn + " delete " + strings.toDeleteThisTier,
        });
      } else {
        await deleteTier({ variables: { id: tierId } });
      }
    } catch (error) {
      if (error) {
        return;
      }
    }
  };

  const { getFieldDecorator } = form;

  return (
    <Modal
      title={strings.deleteTier}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {strings.cancel}
        </Button>,
        <Button
          key="delete"
          loading={isDeleteTierLoading}
          type="primary"
          onClick={handleDeleteTier}
        >
          {strings.delete}
        </Button>,
      ]}
    >
      <Form layout="vertical" >
        <Form.Item label={strings.pleaseTypeIn + " delete " + strings.toConfirm}>
          {getFieldDecorator("delete", {
            rules: [
              {
                required: true,
                message: strings.pleaseTypeIn + " delete " + strings.toConfirm,
              },
            ],
          })(<Input size="large" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(DeleteTierModal);
