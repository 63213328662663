import React, { useEffect, useState } from "react";
import { Card, message, Button, Modal, Input, Tabs } from "antd";
import { connect } from "react-redux";
import { Icon } from "@ant-design/compatible";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./index.scss";
import strings from "../../../../../../strings";
import { getAllOffers, getBirthdayMessage, createBirthdayOffer, editBirthdayMessage } from "../../../../../../redux/actions/birthdayOfferActions";

const { TabPane } = Tabs;

const BirthdayMessageModal = ({
    open,
    onCancel,
    getBirthdayMessage,
    createBirthdayOffer,
    // createOffer,
    editBirthdayMessage,
    getAllOffers,
    user
}) => {
    const [ loading, setLoading ] = useState(false);
    const [ openOfferModal, setOpenOfferModal ] = useState(false);
    const [ openMessageModal, setOpenMessageModal] = useState(false);
    const [ offer, setOffer ] = useState(null);
    const [ isEdit, setIsEdit ] = useState(false);
    const [ insertPoint, setInsertPoint ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ messageValue, setMessageValue ] = useState(false);
    const [ message_id, setMessageId ] = useState(false);

    useEffect(() => {
        handleGetBirthdayMessage();
        getAllOffers(user.id)
        .then(result => {
            setOffer(result.data.offer_description);
        })
        .catch(error => {
            console.log(error, "=-=-=-=-=-err=-=-=-=-=-");
        });
    }, []);

    const toggleCreateOfferModal = (action="create") => {
        setOpenOfferModal(!openOfferModal);
        action === "create" ? setIsEdit(false) : setIsEdit(true);
    }

    const handleGetBirthdayMessage = () => {
        setLoading(true);
        getBirthdayMessage()
        .then(res => {
          if(res.status === 200){
            setLoading(false);
            setMessageValue(res.data.preset_sms_text);
            setMessageId(res.data.id);
          }
        })
        .catch(err => {
            setLoading(false);
          console.log("err", err.message);
        })
    }

    const handleCreateBirthdayOffer = () => {
        setIsLoading(true);
        createBirthdayOffer({ offer_description: offer })
        .then(res => {
          if(res.status === 200){
            setIsLoading(false);
            setOffer(res.data.offer_description);
            toggleCreateOfferModal("edit");
          }
        })
        .catch(err => {
        setIsLoading(false);
          console.log("err", err.message);
          
        })
    }
    
    // const createOffer = () => {
    //     const offer = offer.trim();
    //     if (offer) {
    //       createOffer();
    //     } else {
    //       message.error(strings.inputAnOfferPlease);
    //     }
    // }
    const toggleMessageModal = () => {
        setOpenMessageModal(!openMessageModal);
    }
    
    const insertValue = (type) => {
        // const { messageValue, insertPoint } = this.state;
        if (!insertPoint) {
          message.info(strings.pleaseSelectTheInsertPoint);
          return;
        }
        const firstHalf = messageValue.substring(0, insertPoint);
        const secondHalf = messageValue.substring(insertPoint);
        //${secondHalf} ${secondHalf}
        if (type === "name") {
            setMessageValue(`${messageValue} [CUSTOMER_NAME]`);
        } else {
            setMessageValue(`${messageValue} [BIRTHDAY_OFFER]`);
        }
    }

    const  upDateOfferMessage = () => {
        setIsLoading(true);
        editBirthdayMessage(message_id, messageValue)
        .then(res => {
          if(res.status === 200){
            setIsLoading(false);
            setMessageValue(res.data.preset_sms_text )
            
            handleGetBirthdayMessage();
            toggleMessageModal();
          }
        })
        .catch(err => {
          setIsLoading(false);
          console.log("err", err.message);
          
        })
        
    }

  return (
    <Modal
        open={open}
        onCancel={onCancel}
        footer={null}
        width={700}
    >
        <main>
        <Card
          title={strings.birthdayOfferAndMessage}
          loading={loading}
          extra={<div></div>}
        >
          <section className="invoices-table mt-40">
            <Tabs defaultActiveKey="1" onChange={() => {}}>
            <TabPane tab={strings.birthdayMessage} key="1">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {messageValue}
                  <Button onClick={toggleMessageModal}>
                    <Icon type="edit" />
                    {strings.edit}
                  </Button>
                </div>
              </TabPane>
              <TabPane tab={strings.birthdayOffer} key="2">
                <div>
                  {offer && <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "60%"}}>
                        <p>{offer}</p>
                        <div style={{ display: "flex", alignItems: "center", gap:5}}>
                          <EditOutlined onClick={() => toggleCreateOfferModal("edit")} style={{cursor: "pointer"}} />
                          <DeleteOutlined />
                        </div>
                    </div>}
                  {!offer && <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1>{strings.noOfferFound}</h1>
                    <span>{strings.settingABirthdayOffer}</span>
                    <Button onClick={() => toggleCreateOfferModal("create")}>
                      {strings.createOffer}
                    </Button>
                  </div>}
                </div>
              </TabPane>
            </Tabs>
          </section>
        </Card>
        <Modal
          title={isEdit ? strings.editOffer : strings.createOffer}
          cancelText={strings.cancel}
          open={openOfferModal}
          destroyOnClose
          onCancel={() => toggleCreateOfferModal()}
          okText={isEdit ? strings.editOffer : strings.createOffer}
          onOk={handleCreateBirthdayOffer}
          okButtonProps={{loading: isLoading}}
        >
          <label htmlFor="">{strings.whatIsTheOffer}</label>
          <Input
            placeholder=""
            name="offer"
            onChange={event => {
                setOffer(event.target.value);
            }}
            value={offer}
          />
        </Modal>
        <Modal
          title={strings.editMessage}
          cancelText={strings.cancel}
          open={openMessageModal}
          destroyOnClose
          onCancel={toggleMessageModal}
          okText={strings.save}
          onOk={upDateOfferMessage}
          okButtonProps={{loading: isLoading}}
        >
          <Input.TextArea
            onMouseUp={event => {
                setInsertPoint(event.target.selectionStart);
            }}
            onChange={(e) => setMessageValue(e.target.value)}
            rows={4}
            placeholder=""
            name="message"
            value={messageValue}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Button
              onClick={() => {
                insertValue("name");
              }}
              style={{ margin: "10px 0" }}
            >
              {strings.insert} CUSTOMER_NAME
            </Button>
            {offer && <Button
              onClick={() => {
                insertValue("offer");
              }}
              style={{ margin: "10px 0" }}
            >
              {strings.insert} BIRTHDAY_OFFER
            </Button>}
          </div>
          <div>
            [CUSTOMER_NAME]: {strings.theNameOfCustomerInserted}
            <br />
            [BIRTHDAY_OFFER]: {strings.theBirtdayOfferInserted}
          </div>
        </Modal>
      </main>
    </Modal>
  )
}


const mapStateToProps = state => ({
    invoices: state.invoice.allInvoices,
    user: state.auth.user,
    error: state.error,
    products: state.product.allProducts,
    customers: state.customer.allCustomers,
});

export default connect(mapStateToProps, {
    getAllOffers,
    getBirthdayMessage,
    createBirthdayOffer,
    editBirthdayMessage,
    // createOffer
  })(BirthdayMessageModal);