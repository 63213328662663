import React from "react";
import { Card, Button, Row, Col } from "antd";

const BookingDetails = ({
  onCancel,
  currentBooking,
  customers,
  addServiceToCart,
  loading,
  toggleTransactionModal,
  bookingPaymentSuccessful,
}) => {
  const title = (
    <Row>
      <Col span={8}>
        <Button onClick={onCancel}>Back</Button>
      </Col>
      <Col>Booking Details</Col>
    </Row>
  );

  const {
    customer_id,
    service_name,
    amount_charged,
    completion_time,
    branch_name,
    repeat,
    repeat_frequency,
    repeat_number,
    staff_name,
    booking_date,
    created_at,
    payment_status,
  } = currentBooking;

  const customer = customers.find(cstmr => cstmr.id === customer_id || cstmr.user_id === customer_id);

  const customerName = customer
    ? `${customer.first_name ? customer.first_name : "N/A"} ${
        customer.last_name ? customer.last_name : ""
      } ${customer.phone_number ? ":" + customer.phone_number : ""}`
    : "N/A";

  return (
    <Card title={title} style={{ width: "100%" }}>
      <div style={{ marginLeft: "2rem" }}>
        <p>
          <strong>Customer Name: </strong>
          {customerName}
        </p>
        <p>
          <strong>Service Name: </strong>
          {service_name}
        </p>
        <p>
          <strong>Amount: </strong>
          {amount_charged}
        </p>
        <p>
          <strong>Duration: </strong>
          {completion_time}
        </p>
        <p>
          <strong>Branch: </strong> {branch_name}
        </p>
        <p>
          <strong>Staff: </strong> {staff_name}
        </p>
        <p>
          <strong>Appointment Date: </strong> {booking_date}
        </p>
        <p>
          <strong>Created At: </strong>
          {new Date(Number(created_at)).toLocaleString()}
        </p>
        <p>
          <strong>Payment status: </strong> {payment_status}
        </p>
        {repeat && (
          <>
            <p>
              <strong>Repeat Frequency: </strong> Every {repeat_frequency} weeks
            </p>
            <p>
              <strong>Ends after: </strong> {repeat_number} times
            </p>
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "2rem 0 5rem 0",
          }}
        >
          <Button
            type="primary"
            size="large"
            style={{ width: "50%" }}
            onClick={() => {
              addServiceToCart(currentBooking);
              toggleTransactionModal();
            }}
            disabled={payment_status === "paid" || bookingPaymentSuccessful}
            loading={loading}
          >
            {payment_status === "paid" || bookingPaymentSuccessful
              ? "Payment Made"
              : "Checkout Booking"}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default BookingDetails;
