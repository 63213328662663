import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Input,
  Button,
  Row,
  Col,
  Icon,
  Card,
  Select,
  Radio,
  message,
  Switch,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { useMutation } from "@apollo/client";
import AddCustomField from "./AddCustomField";
import {
  EDIT_FORM,
  LIST_FORMS,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";

const { Option } = Select;

const EditForm = ({ form, user, visible, onCancel, editForm }) => {
  const [addFieldModalVisible, showAddFieldModalVisible] = useState(false);
  const [formFields, setFormFields] = useState(editForm.form_fields);
  const [editField, setEditField] = useState(null);
  const [editCustomFieldVisible, setEditCustomFieldVisible] = useState(false);

  const { getFieldDecorator } = form;

  const [handleEditForm, { loading: editFormLoading }] = useMutation(
    EDIT_FORM,
    {
      onCompleted: data => {
        data.editForm &&
          Modal.success({
            title: "Form edited successfully",
            okButtonProps: {
              id: "btn-component",
            },
            onOk: onCancel,
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: LIST_FORMS }],
    }
  );

  const handleEditFormSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (formFields.length < 1)
        return message.error(
          "You cannot create a form without adding form fields!"
        );
      let payload = {
        ...values,
        form_fields: JSON.stringify(formFields),
      };

      return handleEditForm({ variables: { id: editForm.id, data: payload } });
    });
  };

  const handleAddFieldSubmit = field => {
    const newFields = [...formFields, field];
    setFormFields(newFields);
  };

  const confirmFormCancel = () => {
    Modal.confirm({
      title: "Cancel Form Creation",
      content: "Do you want to stop editing this form?",
      onOk: onCancel,
    });
  };

  const confirmDeleteField = field => {
    Modal.confirm({
      title: "Delete Form Field",
      content: "Do you want to delete this form field?",
      onOk: () => handleDeleteField(field),
    });
  };

  const handleDeleteField = field => {
    const newFields = formFields.filter(
      myField => myField.field_name !== field.field_name
    );
    setFormFields(newFields);
  };

  const handleEditField = field => {
    const myFieldIndex = formFields.findIndex(
      myField => myField.field_name === field.field_name
    );
    const newFields = formFields.slice();
    newFields[myFieldIndex] = field;
    setFormFields(newFields);
    setEditField(null);
    setEditCustomFieldVisible(false);
  };

  const EditCustomFieldForm = ({
    form,
    field,
    onCancel,
    visible,
    handleEditField,
  }) => {
    const [singleFieldInputType, setSingleFieldInputType] = useState(
      field.field_input_type
    );

    const { getFieldDecorator } = form;
    const FormItem = Form.Item;
    const {
      field_name,
      field_input_type,
      field_options,
      required_field,
      notes,
    } = field;
    const handleEditData = () => {
      form.validateFields((err, values) => {
        if (err) return;
        return handleEditField(values);
      });
    };

    return (
      <Modal
        title="Edit Form Field"
        visible={visible}
        footer={[
          <Button type="ghost" onClick={onCancel}>
            Cancel
          </Button>,
          <Button type="primary" onClick={handleEditData}>
            Edit
          </Button>,
        ]}
      >
        <FormItem label="Field Name">
          {getFieldDecorator("field_name", {
            initialValue: field_name,
            rules: [
              {
                required: true,
              },
            ],
          })(<Input size="large" />)}
        </FormItem>
        <FormItem label="Field Input Type">
          {getFieldDecorator("field_input_type", {
            initialValue: field_input_type,
            rules: [
              {
                required: true,
                message: "Please enter custom field input type",
              },
            ],
          })(
            <Select
              placeholder="Select Field Input Type"
              size="large"
              onChange={val => setSingleFieldInputType(val)}
            >
              <Option value="name">Name field</Option>
              <Option value="email">Email field</Option>
              <Option value="phone_number">Phone Number field</Option>
              <Option value="text">Text field</Option>
              <Option value="number">Number field</Option>
              <Option value="select">Select Options</Option>
              <Option value="checklist">Check List</Option>
              <Option value="radio">Radio Fields</Option>
              <Option value="switch">Switch button</Option>
            </Select>
          )}
        </FormItem>
        {singleFieldInputType === "select" && (
          <>
            <FormItem label="Options to Select">
              {getFieldDecorator("field_options", {
                initialValue: field_options,
                rules: [
                  {
                    required: true,
                    message: "Please enter the options list to select from",
                  },
                ],
              })(
                <Input
                  placeholder="Enter options separated by a comma and a space"
                  size="large"
                />
              )}
            </FormItem>
          </>
        )}

        {singleFieldInputType === "checklist" && (
          <>
            <FormItem label="Options to Check">
              {getFieldDecorator("field_options", {
                initialValue: field_options,
                rules: [
                  {
                    required: true,
                    message: "Please enter the options list to check from",
                  },
                ],
              })(
                <Input
                  placeholder="Enter options separated by a comma and a space"
                  size="large"
                />
              )}
            </FormItem>
          </>
        )}
        {singleFieldInputType === "radio" && (
          <>
            <FormItem label="Radio Choices">
              {getFieldDecorator("field_options", {
                initialValue: field_options,
                rules: [
                  {
                    required: true,
                    message: "Please enter the options list to select from",
                  },
                ],
              })(
                <Input
                  placeholder="Enter options separated by a comma and a space"
                  size="large"
                />
              )}
            </FormItem>
          </>
        )}
        <FormItem label="Is Field Compulsory?">
          {getFieldDecorator("required_field", {
            initialValue: required_field,
            rules: [
              {
                required: true,
                message: "Please choose if field must be filled or not",
              },
            ],
          })(
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="Additional Notes">
          {getFieldDecorator("notes", { initialValue: notes })(
            <Input.TextArea
              placeholder="Enter any additional instructions"
              size="large"
              rows={2}
            />
          )}
        </FormItem>
      </Modal>
    );
  };

  const EditCustomField = Form.create()(EditCustomFieldForm);

  return (
    <>
      {editField && (
        <EditCustomField
          field={editField}
          visible={editCustomFieldVisible}
          handleEditField={handleEditField}
          onCancel={() => {
            setEditField(null);
            setEditCustomFieldVisible(false);
          }}
        />
      )}
      <Modal
        visible={visible}
        title="Edit Form"
        width={700}
        onCancel={confirmFormCancel}
        onOk={handleEditFormSubmit}
        okText="Edit"
        okButtonProps={{ loading: editFormLoading }}
      >
        <Form layout="vertical" >
          <Form.Item label="Form Name">
            {getFieldDecorator("form_name", {
              initialValue: editForm.form_name,
              rules: [
                {
                  required: true,
                  message: "Please enter form name",
                },
              ],
            })(<Input placeholder="Enter form name" size="large" />)}
          </Form.Item>
          <Form.Item label="Recipient Emails">
            {getFieldDecorator("recipient_emails", {
              initialValue: editForm.recipient_emails,
              rules: [
                {
                  required: true,
                  message: "Please enter emails",
                },
              ],
            })(
              <Input
                placeholder="Separate multiple emails with a comma and space"
                type="email"
                size="large"
              />
            )}
          </Form.Item>
          <Form.Item label="Add to main menu">
            {getFieldDecorator("active")(
              <Switch defaultChecked={editForm.active} />
            )}
          </Form.Item>
        </Form>
        <div>
          <h3>Form Fields</h3>
          {formFields.length < 1 && <em>No form fields added yet</em>}
          {formFields.length > 0 &&
            formFields.map(field => (
              <div>
                <Card hoverable>
                  <Row key={field.field_name}>
                    <Col span={20}>{field.field_name}</Col>
                    <Col span={2}>
                      <Icon
                        type="edit"
                        title="Edit field"
                        onClick={() => {
                          setEditField(field);
                          setEditCustomFieldVisible(true);
                        }}
                      />
                    </Col>
                    <Col span={2}>
                      <Icon
                        type="delete"
                        title="Delete field"
                        onClick={() => confirmDeleteField(field)}
                      />
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginTop: "20px" }}
          onClick={() => showAddFieldModalVisible(true)}
        >
          Add New Field
        </Button>
      </Modal>
      <AddCustomField
        visible={addFieldModalVisible}
        onCancel={() => showAddFieldModalVisible(false)}
        handleAddFieldSubmit={handleAddFieldSubmit}
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Form.create()(EditForm));
