import React, { Fragment } from "react";
import strings from "../../../../../../strings";
import numberFormatter from "../../../../../../utils/numberFormatter";

const EcommercePrice = ({
  paymentOptions,
  ecommerceBranch,
  hasEcommerce,
  numOfEcommerceBranch,
  ecommercePrice,
  plan,
  renewal,
  upgrade,
}) => {
  return (
    <Fragment>
      {paymentOptions === "renewal" && ecommerceBranch.length > 0 ? (
        <p style={{
          display: "flex", 
          alignItems: "center",
          justifyContent:"space-between"
        }}>
          <span>
            {strings.ecommerceIntegration} ({ecommerceBranch.length}) @ {plan.currency}{" "}
            {numberFormatter(ecommercePrice)}
          </span>

          <span>
            {plan.currency}
            {numberFormatter(renewal.ecommerce_branch_price)}
          </span>
        </p>
      ) : null}

      {paymentOptions === "upgrade" &&
      hasEcommerce &&
      numOfEcommerceBranch > 0 ? (
        <p style={{
          display: "flex", 
          alignItems: "center",
          justifyContent:"space-between"
        }}>
          <span>
            {strings.ecommerceIntegration} ({numOfEcommerceBranch}) @ {plan.currency}{" "}
            {numberFormatter(ecommercePrice)}
          </span>

          <span>
            {plan.currency}
            {numberFormatter(upgrade.ecommerce_branch_price)}
          </span>
        </p>
      ) : null}
    </Fragment>
  );
};

export default EcommercePrice;
