import React, { useState, useEffect } from 'react';
import { Modal, message, Button, Input, Checkbox } from "antd";


const MessageModal = ({
    open, 
    onCancel, 
    data, 
}) => {
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        className="messageModal"
        onOk={onCancel}
    >
    {data?.confirmGiftCard &&
    <>
    <p>{data?.confirmGiftCard?.message}</p>
    <p>Gift Card Balance is {data?.confirmGiftCard?.giftCard_balance}</p>
    </>
    }
    {data?.useVoucher && 
        <>
        <p>{data?.useVoucher?.message}</p>
        <p>Gift Card Balance is {data?.useVoucher?.giftCard_balance}</p>  
        </>
    } 
    </Modal>
  )
}


export default MessageModal;  