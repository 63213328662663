import React from "react";
import { Modal, Input } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";
import { deleteProduct } from "../../../../../../redux/actions/productActions";

import { connect } from "react-redux";
import strings from "../../../../../../strings";

class DeleteProductModal extends React.Component {
  state = {
    loading: false,
  };

  handleDeleteProduct = () => {
    const {
      form,
      deleteProduct,
      updateProducts,
      product: { id },
      closeModal,
      closeViewDetailsModal,
      user,
      branchId,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      if (values.delete.toLowerCase() !== "delete") {
        Modal.error({
          title:
            strings.pleaseTypeIn + " delete " + strings.toDeleteThisProduct,
        });
      } else {
        this.setState({ loading: true });
        deleteProduct(id).then(res => {
          this.setState({ loading: false });
          Modal.success({
            title: strings.productDeletedSuccessfully,
          });
          closeModal();
          closeViewDetailsModal();
          !isNaN(branchId)
            ? updateProducts(user.id, branchId)
            : updateProducts();
        });
      }
    });
  };

  render() {
    const {
      product: { name },
      form: { getFieldDecorator },
      showModal,
      closeModal,
    } = this.props;

    return (
      <Modal
        title={`${strings.delete} ${name}`}
        okText={strings.delete}
        cancelText={strings.cancel}
        open={showModal}
        destroyOnClose
        onCancel={closeModal}
        onOk={this.handleDeleteProduct}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical" >
          <Form.Item
            label={strings.pleaseTypeIn + " delete " + strings.toConfirm}
          >
            {getFieldDecorator("delete", {
              rules: [
                {
                  required: true,
                  message:
                    strings.pleaseTypeIn + " delete " + strings.toConfirm,
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const DeleteProductForm = Form.create()(DeleteProductModal);

DeleteProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeViewDetailsModal: PropTypes.func.isRequired,
};

export default connect(null, {
  deleteProduct,
})(DeleteProductForm);
