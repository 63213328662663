import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  message,
  Upload,
  InputNumber
} from "antd";
import { Form, Icon } from "@ant-design/compatible";
import { UploadOutlined } from "@ant-design/icons";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import strings from "../../../../strings";
import {
  ADD_PARTNER,
  LIST_PARTNERS,
  DELETE_PARTNER,
  ADD_REWARD,
} from "../Apps/MultiLevelLoyalty/Loyalties/constants";

const Option = Select.Option;
const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const AddPartners = (props) => {
  const [state, setState] = useState({
    loading: false,
    region: "",
    country: "",
    countries: [],
    countryCodes: null,
    isEmailLoading: false,
    isPhoneLoading: false,
  });
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    // fetch all countries with their currencies and calling codes
    fetch(`https://restcountries.com/v2/all`)
      .then((res) => res.json())
      .then((res) => {
        let countries = res.map((country) =>
          country.currencies ? country.currencies[0].code : null
        );
        let countryCodes = res
          .map(({ callingCodes, alpha3Code }) => ({
            callingCode: callingCodes[0],
            country: alpha3Code,
          }))
          .sort((a, b) => {
            // remove spaces from  calling code for efficent sorting
            const _a = Number(a.callingCode.replace(/ +/g, ""));
            const _b = Number(b.callingCode.replace(/ +/g, ""));

            return _a - _b;
          });

        // remove duplicated values
        countries = [...new Set(countries)];
        countryCodes = [...new Set(countryCodes)];

        setState((prev) => ({ ...prev, countries, countryCodes }));
      });
  }, []);

  const [addPartner, { loading: isAddPartnerLoading }] = useMutation(
    ADD_PARTNER,
    {
      onCompleted: (data) => {
        console.log(data);
        data.addPartner &&
          Modal.success({
            title: "Partner Added Successfully",
            okText: strings.addNew,
            onOk: () => {},
            okButtonProps: {
              id: "btn-component",
            },
          });
      },
      onError: (error) => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: LIST_PARTNERS }],
    }
  );

  const { form } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return message.error(err);
      }

      let company_rep_phone =
        // eslint-disable-next-line eqeqeq
        values.company_rep_phone[0] == "+"
          ? values.company_rep_phone
          : `+${values.company_rep_phone}`;

      company_rep_phone = company_rep_phone.split(" ").join("");

      const addRewardPayload = {
        name: values.reward_name,
        type: values.reward_type,
        quantity: values.reward_quantity,
        description: values.reward_description,
      };

      const addPartnersPayload = {
        company_name: values.company_name,
        company_rep_name: values.company_rep_name,
        company_id: values.company_id,
        company_rep_phone,
        company_rep_email: values.company_rep_email,
        company_address_1: values.company_address_1,
        company_address_2: values.company_address_2,
        banner: imageUrl,
        postal_code: Number(values.postal_code),
        state: region,
        country: country,
        partner_category: values.partner_category,
        status: "inactive",
        rewards: JSON.stringify(addRewardPayload),
      };

      addPartner({
        variables: {
          data: addPartnersPayload,
        },
      }).then((res) => {
        form.resetFields();
      });
    });
  };
  const { isEmailLoading, isPhoneLoading, country, region } = state;

  const partnerCategories = [
    { id: 1, type: strings.fineDining },
    { id: 2, type: strings.luxuryFashion },
    { id: 3, type: strings.hotels },
    { id: 4, type: strings.travel },
    { id: 5, type: strings.fashion },
    { id: 6, type: strings.health },
    { id: 7, type: strings.foodAndBeverage },
    { id: 8, type: strings.gadgetsAndElectronics },
    { id: 9, type: strings.furniture },
    { id: 11, type: strings.entertainment },
    { id: 12, type: strings.automobile },
    { id: 13, type: strings.education },
    { id: 14, type: strings.beautyAndSpa },
    { id: 15, type: strings.staycation },
    { id: 16, type: strings.trips },
    { id: 17, type: strings.oilAndGas },
    { id: 18, type: strings.events },
    { id: 10, type: strings.laundry },
    { id: 19, type: strings.valentine },
    { id: 20, type: strings.blackFriday },
  ];

  const uploadProps = {
    name: "image",
    action: `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/upload`,
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} ${strings.fileUploadSuccessful}`);
        setImageUrl(info.file.response.imageCloudData.url);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} ${strings.fileUploadFailed}`);
      }
    },
  };

  const { getFieldDecorator } = props.form;

  function selectCountry(val) {
    setState((prev) => ({ ...prev, country: val }));
  }

  function selectRegion(val) {
    setState((prev) => ({ ...prev, region: val }));
  }

  const businessTypeOptions = partnerCategories.map((type) => (
    <Option key={type.id} value={type.type}>
      {type.type}
    </Option>
  ));

  return (
    <div className="page-container account-screen">
      {/* Main Content */}
      <Card
        bordered={false}
        // title="My Account"
        // extra={
        //   <Button type="primary" onClick={handleShowChangePassword}>
        //     Change Password
        //   </Button>
        // }
      >
        <section className="mt-20">
          <Form layout="vertical" onSubmit={handleSubmit}>
            <h2 style={{ marginBottom: "10px" }}>
              {strings.addNewLoyaltyPartner}
            </h2>

            <Row gutter={12}>
              <Col lg={8} md={12} sm={24}>
                {/* Firstname Input */}
                <FormItem>
                  {getFieldDecorator("company_name", {
                    rules: [
                      {
                        message: strings.pleaseEnterCompanyName,
                        required: true,
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder={strings.companyName}
                    />
                  )}
                </FormItem>
              </Col>

              <Col lg={8} md={12} sm={24}>
                {/* Firstname Input */}
                <FormItem>
                  {getFieldDecorator("company_id", {
                    rules: [
                      {
                        message: strings.pleaseEnterCompanyId,
                        required: true,
                      },
                    ],
                  })(
                    <InputNumber
                      size="large"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      style={{width: "100%"}}
                      placeholder={strings.companyId}
                    />
                  )}
                </FormItem>
              </Col>

              <Col lg={8} md={12} sm={24}>
                <FormItem>
                  {getFieldDecorator("company_rep_name", {
                    rules: [
                      {
                        message: strings.pleaseEnterCompanyRepName,
                        required: true,
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder={strings.companyRepName}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={12}>
              {/* Email Input */}
              <Col lg={8} md={12} sm={24}>
                <FormItem>
                  {getFieldDecorator("company_rep_email", {
                    rules: [
                      {
                        message: strings.pleaseEnterCompanyRepEmail,
                        required: true,
                      },
                      {
                        type: "email",
                        message: strings.pleaseEnterValidEmail,
                      },
                      {
                        /* {
                          validator: handleEmailValidation,
                        }, */
                      },
                    ],
                  })(
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        size="large"
                        type="email"
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }))
                        }
                        prefix={
                          <Icon
                            type="mail"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder={strings.companyRepEmail}
                        style={{
                          width: isEmailLoading ? "90%" : "100%",
                        }}
                      />

                      {isEmailLoading && <Spin indicator={antIcon} />}
                    </div>
                  )}
                </FormItem>
              </Col>
              {/* ======Email */}

              {/* Phone number Input */}
              <Col lg={8} md={12} sm={24}>
                <FormItem>
                  {getFieldDecorator("company_rep_phone", {
                    rules: [
                      {
                        message: strings.pleaseEnterCompanyRepPhone,
                        required: true,
                      },
                      {
                        /* {
                          validator: handlePhoneValidation,
                        }, */
                      },
                    ],
                  })(
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <PhoneInput
                        inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                        inputStyle={{
                          border: "1px solid #d9d9d9",
                          width: `${isPhoneLoading ? "90%" : "100%"}`,
                          height: "40px",
                        }}
                        country="ng"
                        onChange={(data, phoneCountry) => {
                          setState((prev) => ({
                            ...prev,
                            phoneCountry,
                          }));
                        }}
                      />
                      {isPhoneLoading && <Spin indicator={antIcon} />}
                    </div>
                  )}
                </FormItem>
              </Col>
            </Row>
            {/* ======Phone number */}

            <Row gutter={12}>
              <Col lg={8} md={12} sm={24}>
                {/* Address Line 1*/}
                <FormItem>
                  {getFieldDecorator("company_address_1", {
                    rules: [
                      {
                        required: true,
                        message: strings.pleaseEnterAddress,
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={
                        <Icon
                          type="environment"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder={strings.addressLine1}
                    />
                  )}
                </FormItem>
                {/* =========Address Line 1 */}
              </Col>

              <Col lg={8} md={12} sm={24}>
                {/* Address Line 2*/}
                <FormItem>
                  {getFieldDecorator("company_address_2", {
                    rules: [
                      {
                        required: true,
                        message: strings.pleaseEnterAddress,
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={
                        <Icon
                          type="environment"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder={strings.addressLine2}
                    />
                  )}
                </FormItem>
                {/* =========Address Line 2 */}
              </Col>
            </Row>

            <Row gutter={12}>
              <Col lg={8} md={12} sm={24}>
                {/* Business category Input */}
                <FormItem>
                  {getFieldDecorator("partner_category", {
                    rules: [
                      {
                        required: true,
                        message: strings.pleaseSelectBusinessCategory,
                      },
                    ],
                  })(
                    <Select placeholder={strings.partnerCategory} size="large">
                      {businessTypeOptions}
                    </Select>
                  )}
                </FormItem>
              </Col>
              {/* =========Business category */}

              <Col lg={8} md={12} sm={24}>
                <div className="custom-input">
                  <CountryDropdown
                    label={strings.country}
                    value={state.country}
                    defaultOptionLabel={strings.selectCountry}
                    onChange={(val) => selectCountry(val)}
                    className="ant-input-lg ant-input"
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col lg={8} md={12} sm={24}>
                <div className="custom-input" style={{ width: "100%" }}>
                  <RegionDropdown
                    label={strings.state}
                    country={state.country}
                    value={state.region}
                    onChange={(val) => selectRegion(val)}
                    className="ant-input-lg ant-input"
                    defaultOptionLabel={strings.selectRegion}
                  />
                </div>
              </Col>

              <Col lg={8} md={12} sm={24}>
                <FormItem className="custom-input" style={{ width: "100%" }}>
                  {getFieldDecorator("postal_code", {
                    rules: [
                      {
                        required: false,
                        message: strings.pleaseEnterPostCode,
                      },
                    ],
                  })(<Input placeholder={strings.postCode} size="large" />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={8} md={12} sm={24}>
                <div style={{ marginBottom: 30 }}>
                  <Upload listType="picture" multiple={false} {...uploadProps}>
                    <Button icon={<UploadOutlined />}>
                      {strings.clickToUpload}
                    </Button>
                  </Upload>
                </div>
              </Col>
            </Row>

            <Button
              type="primary"
              htmlType="submit"
              loading={isAddPartnerLoading}
            >
              {strings.save}
            </Button>
          </Form>
          <RewardForm />
          <DeleteForm />
        </section>
      </Card>
    </div>
  );
};

const AddRewards = (props) => {
  const { form } = props;
  const { getFieldDecorator } = props.form;

  const { loading: partnersLoading, data: partnersData } = useQuery(
    LIST_PARTNERS,
    {
      onError(error) {
        message.error(error.message, 5);
      },
    }
  );

  const rewardTypes = [
    {
      id: 1,
      type: strings.discount,
    },
    { id: 2, type: strings.freeItem },
  ];
  const rewardTypeOptions = rewardTypes.map((type) => (
    <Option key={type.id} value={type.type}>
      {type.type}
    </Option>
  ));

  const [addReward, { loading: isAddRewardLoading }] = useMutation(ADD_REWARD, {
    onCompleted: (data) => {
      console.log(data);
      data.addReward &&
        Modal.success({
          title: "Reward Added Successfully",
          okText: strings.addNew,
          onOk: () => {},
          okButtonProps: {
            id: "btn-component",
          },
        });
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
    refetchQueries: [{ query: LIST_PARTNERS }],
  });

  const handleAddReward = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return message.error(err);
      }
      const payload = {
        partner_id: values.partner_id,
        name: values.reward_name,
        type: values.reward_type,
        quantity: Number(values.reward_quantity),
        description: values.reward_description,
      };

      addReward({
        variables: { ...payload },
      }).then((res) => {
        if (res.data.addReward) {
          form.resetFields();
        }
      });
    });
  };

  return (
    <Form layout="vertical" onSubmit={handleAddReward}>
      <section className="sms-info" style={{ marginTop: "2rem" }}>
        <h4 style={{ marginBottom: "10px" }}>{strings.addReward}</h4>
      </section>

      <Row gutter={12}>
        <Col lg={16} md={24} sm={24}>
          <Form.Item name="partner_id" label={strings.rewardPartner}>
            {getFieldDecorator("partner_id", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseSelectRewardPartner,
                },
              ],
            })(
              <Select
                loading={partnersLoading}
                placeholder={strings.pleaseSelectRewardPartner}
              >
                {partnersData && partnersData.listPartners
                  ? partnersData.listPartners.map((partner) => (
                      <Option key={partner.id} value={partner.id}>
                        {partner.company_name}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col lg={8} md={12} sm={24}>
          {/* Firstname Input */}
          <FormItem>
            {getFieldDecorator("reward_name", {
              rules: [
                {
                  message: strings.pleaseEnterRewardName,
                  required: true,
                },
              ],
            })(
              <Input
                size="large"
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={strings.rewardName}
              />
            )}
          </FormItem>
        </Col>

        <Col lg={8} md={12} sm={24}>
          <FormItem>
            {getFieldDecorator("reward_type", {
              rules: [
                {
                  message: strings.pleaseEnterRewardType,
                  required: true,
                },
              ],
            })(
              <Select placeholder={strings.rewardType} size="large">
                {rewardTypeOptions}
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col lg={8} md={12} sm={24}>
          <FormItem>
            {getFieldDecorator("reward_quantity", {
              rules: [
                {
                  message: strings.pleaseEnterRewardQuantity,
                  required: true,
                },
              ],
            })(
              <Input
                size="large"
                type="number"
                placeholder={strings.rewardQuantity}
              />
            )}
          </FormItem>
        </Col>
        <Col lg={8} md={12} sm={24}>
          {/* Firstname Input */}
          <FormItem>
            {getFieldDecorator("reward_description", {
              rules: [
                {
                  message: strings.pleaseEnterRewardDescription,
                  required: true,
                },
              ],
            })(
              <Input
                size="large"
                prefix={
                  <Icon type="info" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={strings.rewardDescription}
              />
            )}
          </FormItem>
        </Col>
      </Row>

      <Button type="primary" htmlType="submit" loading={isAddRewardLoading}>
        {strings.save}
      </Button>
    </Form>
  );
};

const DeletePartners = (props) => {
  const { form } = props;
  const { getFieldDecorator } = props.form;

  const { loading: partnersLoading, data: partnersData } = useQuery(
    LIST_PARTNERS,
    {
      onError(error) {
        message.error(error.message, 5);
      },
    }
  );

  const [deletePartner, { loading: isDeleterPartnersLoading }] = useMutation(
    DELETE_PARTNER,
    {
      onCompleted: (data) => {
        data.deletePartner &&
          Modal.success({
            title: "Partner Deleted Successfully",
            okText: "Continue",
            onOk: () => {},
            okButtonProps: {
              id: "btn-component",
            },
          });
      },
      onError: (error) => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: LIST_PARTNERS }],
    }
  );

  const handleDeletePartner = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return message.error(err);
      }
      deletePartner({
        variables: { id: values.partner_id },
      }).then((res) => {
        form.resetFields();
      });
    });
  };

  return (
    <Form layout="vertical" onSubmit={handleDeletePartner}>
      <section className="sms-info" style={{ marginTop: "2rem" }}>
        <h4 style={{ marginBottom: "10px" }}>Delete Partner</h4>
      </section>

      <Row gutter={12}>
        <Col lg={16} md={24} sm={24}>
          <Form.Item name="partner_id" label={strings.rewardPartner}>
            {getFieldDecorator("partner_id", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseSelectRewardPartner,
                },
              ],
            })(
              <Select
                loading={partnersLoading}
                placeholder={strings.pleaseSelectRewardPartner}
              >
                {partnersData && partnersData.listPartners
                  ? partnersData.listPartners.map((partner) => (
                      <Option key={partner.id} value={partner.id}>
                        {partner.company_name}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Button
        type="primary"
        htmlType="submit"
        loading={isDeleterPartnersLoading}
      >
        {strings.delete}
      </Button>
    </Form>
  );
};

const AccountForm = Form.create()(AddPartners);

const RewardForm = Form.create()(AddRewards);

const DeleteForm = Form.create()(DeletePartners);

export default AccountForm;
