import React from "react";
import { connect } from "react-redux";
import { Button, Card, Input, Modal } from "antd";
import { Icon } from "@ant-design/compatible";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import CustomDataTable from "../../../../components/CustomDataTable";
import { getAllCustomers } from "../../../../redux/actions/customerActions";
import {
  recordOfflineSale,
  deleteOfflineSale,
} from "../../../../utils/backgroundFunctions";
import Highlighter from "react-highlight-words";
import ViewOfflineDetails from "./_partials/ViewOfflineDetails";
import strings from "../../../../strings";

const parseReport = function (data) {
  const result = data.map((report) => {
    const tempReport = { ...report.formattedData };
    tempReport.createdDate = moment(report.formattedData.created_at).format(
      "DD:MM:YYYY"
    );
    tempReport.createdTime = moment(report.formattedData.created_at).format(
      "h:mm:ss A"
    );
    tempReport.paymentType = report.formattedData.is_paid_with_card
      ? "Card"
      : report.is_paid_with_cash
      ? "Cash"
      : "Mobile";
    return tempReport;
  });
  return result.sort((a, b) => moment(a.created_at) < moment(b.created_at));
};

class OfflineSales extends React.Component {
  state = {
    offlineSales: [],
    loading: false,
    isViewDraftModalOpen: false,
    isDeleteDraftModalOpen: false,
    bill: {},
    syncError: [],
    syncSalesLoading: false,
  };

  componentDidMount() {
    const { user, customers, getAllCustomers } = this.props;

    if (customers.length === 0) {
      getAllCustomers();
    }

    this.setState({ loading: true });
    const offlineSales = JSON.parse(localStorage.getItem("offlineSales"));

    if (offlineSales && offlineSales.length) {
      this.setState({
        offlineSales: offlineSales.filter(
          (sale) => sale.formattedData.merchant_id === user.id
        ),
      });
    } else {
      this.setState({ offlineSales: [] });
    }
    this.setState({ loading: false });

    // window.addEventListener("storage", this.updateState);
  }

  // componentWillUnmount() {
  //   window.removeEventListener("storage", this.updateState);
  // }

  updateState = () => {
    const { user } = this.props;

    const offlineSales = JSON.parse(localStorage.getItem("offlineSales"));

    if (offlineSales && offlineSales.length) {
      this.setState({
        offlineSales: offlineSales.filter(
          (sale) => sale.formattedData.merchant_id === user.id
        ),
      });
    } else {
      this.setState({ offlineSales: [] });
    }
  };

  toggleViewDraftModal = () => {
    this.setState({
      isViewDraftModalOpen: !this.state.isViewDraftModalOpen,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${strings.search} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {strings.search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          {strings.reset}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!record[dataIndex]) return;

      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state[dataIndex]]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  syncOfflineSales = async () => {
    const { offlineSales } = this.state;

    const hasOfflineSales = offlineSales && offlineSales.length > 0;

    if (!navigator.onLine) {
      return Modal.error({ title: "You must be online to sync sales" });
    }
    if (!hasOfflineSales) {
      return Modal.error({
        title: "You don't have any offline sales to sync!",
      });
    }
    const checkForSales = JSON.parse(localStorage.getItem("offlineSales"))
    if (checkForSales.length === 0) {
      window.location.reload();
      return;
    }
    if (hasOfflineSales) {
      this.setState({ syncSalesLoading: true });
      const requests = offlineSales.map((sale) => {
        const {
          transactionObj,
          cartList,
          addLoyaltyProgramToSale,
          shared_loyalty_txn,
          payment_reference,
          reference_code
        } = sale.payload;
        return recordOfflineSale(
          transactionObj,
          cartList,
          addLoyaltyProgramToSale,
          shared_loyalty_txn,
          payment_reference,
          sale.formattedData.created_at,
          reference_code
        )
          .then((res) => {
            // Remove Sale from LocalStorage
            deleteOfflineSale(sale.formattedData.id);

            return { status: "success", data: res.data };
          })
          .catch((err) => {
            this.setState((prev) => ({
              ...prev,
              syncError: [
                prev.syncError,
                { status: "error", error: err, sale },
              ],
            }));
            return { status: "error", error: err.response, sale };
          });
      });

      try {
        await Promise.all(requests);
        this.setState({ syncSalesLoading: false });

        if (this.state.syncError.length < 1) {
          Modal.success({
            title:
              "You are back online! All your offline sales have been synced!",
          });
        } else {
          Modal.error({
            title:
              "An error occurred while syncing your offline sales. Some sales may not be synced!",
          });
        }
      } catch (error) {
        this.setState({ syncSalesLoading: false });
        console.log(error);
      }
    }
  };

  render() {
    const { isViewDraftModalOpen } = this.state;
    const { customers } = this.props;

    const columns = [
      {
        title: strings.customer,
        dataIndex: "formattedCustomerName",
        render: (record) =>
          record ? <span>{record}</span> : <span>Guest Customer</span>,
        ...this.getColumnSearchProps("formattedCustomerName"),
      },
      {
        title: strings.amount,
        dataIndex: "total",
        key: "total",
      },
      {
        title: strings.status,
        dataIndex: "status",
        key: "status",
      },
      {
        title: strings.createdDate,
        dataIndex: "createdDate",
        render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
        sorter: (a, b) => {
          return moment(a.createdDate) < moment(b.createdDate);
        },
      },
      {
        title: strings.createdTime,
        dataIndex: "createdTime",
        render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
      },
      {
        title: strings.servedBy,
        dataIndex: "staff",
        render: (record) =>
          record ? <span>{record.username}</span> : <span>Admin</span>,
      },
    ];

    const tableData = this.state.offlineSales.map((sale) => {
      let customer = customers.find(
        (customer) => customer.user_id === sale.formattedData.userId
      );

      return {
        ...sale,
        formattedData: {
          ...sale.formattedData,
          formattedCustomerName: customer
            ? `${customer.first_name} ${customer.last_name}`
            : strings.guestCustomer,
        },
      };
    });

    const dataSource = parseReport(tableData);

    return (
      <div className="page-container draft-sales">
        <Card
          bordered={false}
          extra={[
            <Button
              style={{ marginRight: 20 }}
              loading={this.state.syncSalesLoading}
              onClick={this.syncOfflineSales}
              type="primary"
            >
              Sync Sales
            </Button>,
          ]}
        >
          <section>
            <CustomDataTable
              columns={columns}
              dataSource={dataSource.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )}
              loading={this.state.loading}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.setState(
                      {
                        bill: record,
                      },
                      () => {
                        this.toggleViewDraftModal();
                      }
                    );
                  },
                };
              }}
            />
          </section>

          {isViewDraftModalOpen ? (
            <ViewOfflineDetails
              closeModal={this.toggleViewDraftModal}
              isViewDraftModalOpen={isViewDraftModalOpen}
              currentBill={this.state.bill}
              toggleDeleteDraftModal={this.toggleDeleteDraftModal}
              history={this.props.history}
            />
          ) : null}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  customers: state.customer.allCustomers,
});

export default connect(mapStateToProps, { getAllCustomers })(OfflineSales);
