export default class Merchants {
  constructor(client) {
    this.client = client;
  }

  getCustomFields() {
    return this.client.get("/merchants/get_custom_fields");
  }

  getPricingPlanData(planName) {
    return this.client.post("/get_pricing_plan_data", { data: planName });
  }

  addMerchantCurrentPlan(merchantEmail, subscriptionData) {
    return this.client.post(
      `/add_merchant_current_plan_v2?email=${merchantEmail}`,
      {
        data: subscriptionData,
      }
    );
  }

  getMerchantSubscriptionPlan() {
    return this.client.get("/get_merchant_current_subscription");
  }

  updateSMSPoints(numOfSms) {
    return this.client.post(`/merchants/sms_top_up?sms_top_up=${numOfSms}`);
  }

  getMerchantSMSBalance() {
    return this.client.get(`/get_merchant_sms_balance`);
  }

  // sendBVNConfirmationCode(bvn) {
  //   return this.client.post(`/merchants/send_bvn_confirmation_code`, { bvn });
  // }

  // resendBVNConfirmationCode() {
  //   return this.client.get(`/merchants/resend_bvn_confirmation_code`);
  // }

  // validateConfirmationCode(code) {
  //   return this.client.post("/merchants/validate_bvn_confirmation_code", {
  //     confirmation_code: code,
  //   });
  // }

  updateAccountDetails(accountData) {
    return this.client.post("/merchants/update_account_details", {
      data: accountData,
    });
  }

  fetchNigerianBanks() {
    return this.client.get("/merchants/fetch_nigerian_banks");
  }

  enableAppIntegration(app_id) {
    return this.client.post("/merchants/enable_integration", {
      data: {
        app_id,
      },
    });
  }

  disableAppIntegration(app_id) {
    return this.client.post("/merchants/disable_integration", {
      app_id: app_id,
    });
  }

  fetchAllApps() {
    return this.client.get("/apps");
  }

  updateNitroLocation(data) {
    return this.client.put(`/merchant/remote_ip`, data );
  }

  saveVpaySettings( data ) {
    return this.client.put("/merchant/update_vpay", { data });
  }

  createPayStackVirtualTerminal(  ) {
    return this.client.post("/merchants/virtual_terminal");
  }

  getPayStackVirtualTerminal() {
    return this.client.get("/merchants/fetch_virtual_terminals");
  }

  saveStripsDetails(data){
    return this.client.put("merchant/merchant_stripe_details", { data });
  }

  getStripsDetails(){
    return this.client.get("merchant/merchant_stripe_details");
  }

  fetchActiveApps() {
    return this.client.get("/merchants/my_apps");
  }

  fetchTransferFeeCredits() {
    return this.client.get("/merchants/get_merchant_transfer_fee_balance");
  }

  topUpTransferFeeCredits(data) {
    return this.client.post("/merchants/top_up_transfer_credits", {
      transfer_credits_top_up: data,
    });
  }

  fetchMerchantDisbursementsByDuration(pageNumber, from, to) {
    return this.client.get(
      `/disbursements/for_merchant?page=${pageNumber}&from=${from}&to=${to}`
    );
  }

  fetchMerchantDisbursements(pageNumber) {
    return this.client.get(`/disbursements/for_merchant?page=${pageNumber}`);
  }

  fetchMerchantDisbursementsByStatus(payload) {
    const { status, pageNumber, from, to } = payload;

    if (status && from && to && pageNumber > 1) {
      return this.client.get(
        `/disbursements/for_merchant?status=${status}&page=${pageNumber}&from=${from}&to=${to}`
      );
    } else if (status && !from && !to && pageNumber > 1) {
      return this.client.get(
        `/disbursements/for_merchant?status=${status}&page=${pageNumber}`
      );
    } else {
      return this.client.get(`/disbursements/for_merchant?status=${status}`);
    }
  }
}
