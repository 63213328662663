import React from "react";
import { Form, Input, Button, Modal } from "antd";

const FormItem = Form.Item;

const AddCustomerField = ({
  form,
  visible,
  onCancel,
  handleAddFormSubmit,
  loading,
}) => {
  const { getFieldDecorator } = form;

  const confirmFormCancel = () => {
    Modal.confirm({
      title: "Cancel Field Creation",
      content: "Do you want to stop adding this custom field?",
      onOk: onCancel,
    });
  };
  return (
    <div>
      <Modal
        visible={visible}
        footer={[
          <Button
            type="primary"
            loading={loading}
            onClick={handleAddFormSubmit}
          >
            Save Field
          </Button>,
          <Button onClick={confirmFormCancel}>Cancel</Button>,
        ]}
      >
        <Form layout="vertical" onSubmit={handleAddFormSubmit}>
          <FormItem label="Field Name">
            {getFieldDecorator("field_name", {
              rules: [
                {
                  required: true,
                  message: "Please enter custom field name",
                },
              ],
            })(<Input placeholder="Enter field name" size="large" />)}
          </FormItem>
        </Form>
      </Modal>
    </div>
  );
};

export default AddCustomerField;
