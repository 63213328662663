import { Progress } from 'antd'
import React from 'react';
import "./index.scss";

const ProgressLoader = ({
    total,
    currentValue,
    title = "Products..."
}) => {
    const calculatePercent = (current, total) => {
        return Math.round(( Number(current) / Number(total)) * 100);
    }
  return (
    <div className='progress-overlay'>
        <div className='product-progress'>
          <p className='loading'>Loading {title}</p>
          <Progress size="default" showInfo={false} strokeColor="#d90068" percent={total && currentValue ? calculatePercent(currentValue, total): 0} />
          {total && currentValue ? <p className='complete'>{calculatePercent(currentValue, total)}% Complete</p> : ""}
      </div>
    </div>
  
  )
}

export default ProgressLoader