import React, { useState } from "react";
import { Modal, Button, } from "antd";
import { Icon } from "@ant-design/compatible";
import openInNewTab from '../../utils/openInNewTab';
import strings from "../../strings";

const SendOptionModal = ({
  cancel,
  openOptionModal,
  invoice,
  sendInvoiceToCustomerAsMail,
  getDownloadableInvoiceLink,
  getDownloadableReceiptLink,
  user,
  saleId,
  isReceipt,
  sendReceipt,
  getAllInvoices
}) => {

  const [emailLoading, setEmailLoading] = useState(false);
  const [whatsappLoading, setWhatsappLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const businessName = user.business_name || user.employer.business_name;
  let customerPhone = '';

  if (invoice && invoice.customere) {
    customerPhone = invoice.customer.phone_number[0] === '+' ?
      invoice.customer.phone_number.slice(1) : invoice.customer.phone_number;
  }

  return (
    <Modal
      title="Send Option"
      visible={openOptionModal}
      onCancel={() => {
        getAllInvoices();
        cancel();
      }}
      footer={[
        <Button key="back" onClick={cancel}>
          {strings.cancel}
        </Button>
      ]}
    >
      <div className="pay-buttons" style={{ display: 'flex', justifyContent: 'space-around'}}>
        <Button
          size="large"
          onClick={() => {
            if (isReceipt && saleId) {
              setEmailLoading(true);
              sendReceipt(saleId)
              .then(() => {
                Modal.destroyAll();
                setEmailLoading(false);
                Modal.success({
                  title: strings.receiptSentToEmail,
                });
              })
              .catch(() => {
                setEmailLoading(false);
              })
            } else {
              setEmailLoading(true);
              sendInvoiceToCustomerAsMail(invoice.id)
              .then((response) => {
                Modal.destroyAll();
                // cancel();
                setEmailLoading(false);
                Modal.success({
                  title: strings.invoiceSentToEmail,
                });
              })
              .catch(() => {
                setEmailLoading(false);
                // cancel();
              })
            }
          }}
          loading={emailLoading}
        >
          <Icon type="mail" />
          {strings.email}
        </Button>
        <Button
          size="large"
          onClick={() => {
            if (isReceipt && saleId) {
              setWhatsappLoading(true);
              getDownloadableReceiptLink(saleId)
              .then((response) => {
                Modal.destroyAll();
                // cancel();
                setWhatsappLoading(false);
                openInNewTab(`
                  http://api.whatsapp.com/send?phone=${customerPhone}&text=${strings.pleaseDownloadReceipt} ${invoice.number} ${strings.from} - ${(businessName).toUpperCase()}: https://${response.data.message}`, true)
                Modal.success({
                  title: strings.receiptToWhatsapp
                });
                setWhatsappLoading(false);
              })
              .catch((error) => {
                setWhatsappLoading(false);
              })
            } else {
              setWhatsappLoading(true);
              getDownloadableInvoiceLink(invoice.id)
              .then((response) => {
                Modal.destroyAll();
                // cancel();
                setWhatsappLoading(false);
                openInNewTab(`
                  http://api.whatsapp.com/send?phone=${customerPhone}&text=${strings.pleaseDownloadInvoice} ${invoice.number} ${strings.from} - ${(businessName).toUpperCase()}: https://${response.data.message}`, true)
                Modal.success({
                  title: strings.invoiceSentToWhatsapp
                });
                setWhatsappLoading(false);
              })
              .catch((error) => {
                setWhatsappLoading(false);
              })
            }
          }}
          loading={whatsappLoading}
        >
          <i class="fab fa-whatsapp" style={{ marginRight: '10px' }} />
          Whatsapp
        </Button>
        <Button
          size="large"
          onClick={() => {
            if (isReceipt && saleId) {
              setDownloading(true);
              getDownloadableReceiptLink(saleId)
              .then((response) => {
                Modal.destroyAll();
                // cancel();
                setDownloading(false);
                openInNewTab(`https://${response.data.message}`, false)
                Modal.success({
                  title: strings.downloadingReceipt,
                });
                setDownloading(false);
              })
              .catch((error) => {
                setDownloading(false);
              })
            } else {
              setDownloading(true);
              getDownloadableInvoiceLink(invoice.id)
              .then((response) => {
                Modal.destroyAll();
                // cancel();
                setDownloading(false);
                openInNewTab(`https://${response.data.message}`, false)
                Modal.success({
                  title: strings.downloadingInvoice
                });
                setDownloading(false);
              })
              .catch((error) => {
                setDownloading(false);
              })
            }
          }}
          loading={downloading}
        >
          <Icon type="file-pdf" />
          {strings.downloadPdf}
        </Button>
      </div>
    </Modal>
  );
};

SendOptionModal.propTypes = {
};

export default SendOptionModal;
