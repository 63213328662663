export default class Orders {
  constructor(client) {
    this.client = client;
  }

  getAllOrders() {
    return this.client.get("/orders_list?page[number]=1&page[size]=500");
  }

  updateOrder(id) {
    return this.client.patch(`/merchants/orders/${id}`, {
      order: { status: "completed", id, merchant: {} },
    });
  }
  cancelOrder(id) {
    return this.client.patch(`/merchants/orders/${id}`, {
      order: { status: "rejected", id, merchant: {} },
    });
  }
}
 