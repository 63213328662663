import { Modal } from 'antd'
import React from 'react'
import strings from '../../../../../strings'

const EditSupply = ({
    open,
    onCancel,
    supplyDetails
}) => {
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={strings.editSupply}
    >

    </Modal>
  )
}

export default EditSupply