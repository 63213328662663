import React from "react";
import { Button, Modal, Popover, Image } from "antd";
import strings from "../../../../../../strings";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./index.scss";

const LoyaltyDetailModal = ({
  user,
  currLoyalty,
  showLoyaltyDetailModal,
  closeModal,
  toggleDeleteLoyaltyModal,
  toggleEditLoyaltyModal,
}) => {
  const isAdminIsManager =
    user.role_id === 1 || (user.role && user.role.id === 2);

  // Content of the action column
  const actionsContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button icon={<EditOutlined />} onClick={toggleEditLoyaltyModal}>
        {strings.edit}
      </Button>
      <Button icon={<DeleteOutlined />} onClick={toggleDeleteLoyaltyModal}>
        {strings.delete}
      </Button>
    </div>
  );

  const {
    name,
    program_type,
    threshold,
    reward,
    total_user_points,
    total_user_stamps,
    reward_value,
    active,
    verify_otp,
    instant_rewards_groups,
    apply_to,
    images,
  } = currLoyalty;

  const imageTexts = [
    "  Welcome Image: This image is sent when the customer enrolls for the first time on your program.",
    "Transaction Image: This image is sent before reaching the transaction threshold.",
    "Congratulation Image: This image is sent when the spending to threshold is met.",
  ];

  return (
    <Modal
      title={strings.loyaltyDetails}
      open={showLoyaltyDetailModal}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          {strings.close}
        </Button>,

        isAdminIsManager && (
          <Popover title={strings.actions} content={actionsContent}>
            <Button type="primary">{strings.actions}</Button>
          </Popover>
        ),
      ]}
    >
      <div>
        <p>
          <strong>{strings.name}</strong>: {name || "-"}
        </p>

        <p>
          <strong>{strings.programType}</strong>: {program_type || "-"}
        </p>

        <p>
          <strong>{strings.reward}</strong>: {reward || "-"}
        </p>

        {program_type !== "InstantRewards" && (
          <>
            <p>
              <strong>{strings.threshold}</strong>: {threshold || "-"}
            </p>

            {/* <p>
              <strong>{strings.totalUserPoints}</strong>:{" "}
              {total_user_points || "-"}
            </p>

            <p>
              <strong>{strings.totalUserStamps}</strong>:{" "}
              {total_user_stamps || "-"}
            </p> */}
          </>
        )}

        <div>
          {images.map((imageUrl, index) => (
            <img
              key={index}
              src={imageUrl}
              alt={`Image ${index}`}
              className="image-item image-small" // Apply the image-small class
              title={imageTexts[index]}
            />
          ))}
        </div>

        {program_type === "InstantRewards" && (
          <>
            <p>
              <strong>Reward Value</strong>: {reward_value || "-"}
            </p>
            <p>
              <strong>Verify OTP</strong>: {verify_otp.toString()}
            </p>
            <p>
              <strong>Active</strong>: {active.toString()}
            </p>
            <p>
              <strong>Apply Loyalty to: </strong>
              {apply_to}
            </p>
            {apply_to === "set" && (
              <p>
                <strong>Customer Groups: </strong>
                <ul>
                  {instant_rewards_groups.map((group) => (
                    <li>{group.group_name || "N/A"}</li>
                  ))}
                </ul>
              </p>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default LoyaltyDetailModal;
