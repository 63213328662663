import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Spin } from "antd";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import client from "./utils/client";
import { ApolloProvider } from "@apollo/client";
// const App = React.lazy(() => import("./App"));

// const App = React.lazy(() => import('./App.js'));

// const fallBackDiv = (
//   <div style={{ 
//     display: "flex", 
//     flexDirection: "column", 
//     alignItems: "center", 
//     justifyContent: "center",
//     backgroundColor: "#fff5f5",
//   }}>
//     <Spin size="large"/>
//     <h2 style={{ fontSize: "20px"}}>Loading....</h2>
//   </div>
// )
ReactDOM.render(
  <Provider store={store}>
  <ApolloProvider client={client}>
    {/* <Suspense fallback={fallBackDiv}> */}
      <App />
    {/* </Suspense> */}
    {/* <App /> */}
  </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
