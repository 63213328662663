import React from "react";
import { Modal, Input } from "antd";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";

import { deleteSalesTransaction } from "../../../../../redux/actions/saleActions";
import { connect } from "react-redux";
import strings from "../../../../../strings";

class DeleteTransactionModal extends React.Component {
  state = {
    loading: false,
  };

  handleDeleteTransaction = () => {
    const {
      form,
      deleteSalesTransaction,
      transaction: { id, total, transactions },
      closeModal,
      transactionList,
      closeTransactionDetailsModal,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      if (Number(values.delete) !== Number(total)) {
        Modal.error({
          title: `${strings.pleaseTypeIn} ${total} ${strings.toDeleteThisTransaction}`,
        });
      } else {
        this.setState({ loading: true });
        deleteSalesTransaction(id)
          .then(res => {
            this.setState({ loading: false });
            Modal.success({
              title: strings.transactionDeletedSuccessfully,
            });

            // Reset Transaction State
            let updatedTransactions = transactionList.filter(
              transaction => transaction.id !== id
            );

            this.props.resetTransactions(updatedTransactions);

            closeModal();
            closeTransactionDetailsModal();
          })
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      showDeleteTransactionModal,
      transaction: { total },

      closeModal,
    } = this.props;

    return (
      <Modal
        title={strings.deleteTransaction}
        okText={strings.delete}
        cancelText={strings.cancel}
        visible={showDeleteTransactionModal}
        destroyOnClose
        onCancel={closeModal}
        onOk={this.handleDeleteTransaction}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical" >
          <Form.Item
            label={`${strings.pleaseTypeIn} ${total} ${strings.toConfirm}`}
          >
            {getFieldDecorator("delete", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseTypeInYourTotal,
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const DeleteTransactionForm = Form.create()(DeleteTransactionModal);

DeleteTransactionForm.propTypes = {
  transaction: PropTypes.object.isRequired,
  showDeleteTransactionModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(null, {
  deleteSalesTransaction,
})(DeleteTransactionForm);
