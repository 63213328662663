import React, { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import { Modal, Spin, notification, message, Button, Input } from "antd";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import { getAllCustomers } from "../../../../redux/actions/customerActions";
import strings from "../../../../strings";
import {
  EDIT_GIFT_CARD,
  LIST_GIFT_CARDS,
} from "../../../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";
import { loginToVpay } from "../../../Dashboard/Layouts/Account/functions";
import Axios from "axios";

const VpayTransfer = ({
  user,
  cartList,
  handlePayment,
  hasDiscount,
  discountAmount,
  clearDiscount,
  selectedCustomer,
  location,
  toggleVpayTransferModal,
  toggleTransactionModal,
  visible,
  instantRewardDiscount,
  hasInstantRewardDiscount,
  isTwoPaymentMethod,
  getCartTotalAmount,
  mtier_amount,
  accountNumber,
  qrcode,
  cardId,
  clearTopupGiftCard,
  topupGiftCard,
  categories,
  transactionDate,
  splitPaymentActive,
  splitPaymentMethods,
  setSplitPaymentMethods
}) => {
  const [state, setState] = useState({ location: "" });
  const [ showPostSales, setShowPostSales ] = useState(false);
  const [ minutes, setMinutes ] = useState(1);
  const [ seconds, setSeconds ] = useState(60);
  const [ time, setTime ] = useState(120);
  const [ inputRef, setInputRef ] = useState("");
  

  useEffect(()=>{
    const timerFunc = setInterval(()=>{
      setSeconds(seconds - 1);
      setTime(time - 1);
      if(seconds === 1){
        setMinutes(minutes - 1);
        setSeconds(60);
      }
    }, 1000)

    if(seconds === 0 && minutes === 0 && time === 0){
        setShowPostSales(true);
        clearInterval(timerFunc);
    }

    return () => clearInterval(timerFunc);
  });

  const [totalPayable, setTotalPayable] = useState(() => {
    const cartTotalAmount = (() => {
      const cartTotalAmount = isTwoPaymentMethod
        ? getCartTotalAmount() - mtier_amount
        : getCartTotalAmount();
      return cartTotalAmount;
    })();

    if (hasDiscount && discountAmount) {
      return cartTotalAmount - discountAmount;
    } else if (hasInstantRewardDiscount && instantRewardDiscount) {
      return cartTotalAmount - instantRewardDiscount;
    } else return cartTotalAmount;
  });


  const [editCard] = useMutation(EDIT_GIFT_CARD, {
    onCompleted: (data) => {
      if (data.editGiftCard) {
        Modal.success({
          title: `You have successfully credited gift card - ${
            data.editGiftCard.card_number
          } with NGN${totalPayable - 100}`,
        });
      }
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
    refetchQueries: [LIST_GIFT_CARDS],
  });

  useEffect(() => {
    if (location && location.state && location.state.fromDraftSales) {
      setState({ location: location });
    }

    const getCategoryName = (id) => {
      const category = categories.find((one) => one.id === id);
      return category ? category.name : "Uncategorized";
    };
    const addClusterPurchase = async (reference) => {
      const loginRes = await loginToVpay();
      const token = loginRes.data.token;
      let description = "",
        product_category = "",
        quantity = "";

      for (let product of cartList) {
        description += `${product.name} x ${product.quantity} - ${product.amount} | `;
        quantity += product.quantity;
        product_category += getCategoryName(
          product.merchant_product_category_id
        );
      }

      const payload = {
        description,
        product_category,
        quantity,
        transaction_reference: reference,
      };
      const headers = {
        "b-access-token": token,
        publicKey: "2d14ba1b-4819-4af6-923e-ec7a21056f50",
      };
      Axios.post(
        "https://www.vpay.africa/api/service/v1/query/merchant/cluster/purchase/add",
        payload,
        { headers }
      )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log({ err }));
    };

    if (user.payout_active || user.employer?.payout_active) {
      const socket = socketIOClient("https://www.vpay.africa");
      socket.emit(
        "ACCOUNT_NUMBER",
        JSON.stringify({
          accountnumber: Number(accountNumber),
        })
      );
      socket.on("FUNDING_SUCCESSFUL", (res) => {
        const data = JSON.parse(res);
        // console.log({ data });
        const {
          amount,
          originator_account_name,
          originator_account_number,
          originator_bank,
          reference,
        } = data;

        if (data.amount < Number(totalPayable)) {
          return Modal.error({
            title: "Amount received not enough!",
            content: `${
              user.currency || user.employer?.currency
            }${amount.toFixed(
              2
            )} received from ${originator_account_name} instead of ${
              user.currency || user.employer?.currency
            }${Number(totalPayable).toFixed(2)} expected.
          Please send ${user.currency || user.employer?.currency}${Number(
              totalPayable - amount
            ).toFixed(2)} more to complete this transaction`,
            onOk: () => setTotalPayable((prev) => Number(prev - data.amount)),
          });
        } else {
          const { location } = state;

          addClusterPurchase(reference);

          // Delete Bill if Bill is in Draft Sales
          if (location && location.state && location.state.fromDraftSales) {
            const draftSalesList = JSON.parse(
              localStorage.getItem("draftSales")
            );

            const filteredDraftSales =
              draftSalesList.length &&
              draftSalesList.filter(
                (bill) => bill.id !== location.state.bill_id
              );

            localStorage.setItem(
              "draftSales",
              JSON.stringify(filteredDraftSales)
            );
            setState({ location: null });
          }

          notification.success({
            message: `Payment ${
              amount
                ? `of ${user.currency || user.employer?.currency}${amount}`
                : ""
            } Received Successfully from ${originator_account_name}`,
            duration: 0,
            placement: "topRight",
          });

          if (topupGiftCard) {
            editCard({
              variables: { data: { id: cardId, amount: amount - 100 } },
            });
          }

          const myReference = reference.split("-").slice(2).join("-");

          if(splitPaymentActive){
            if(splitPaymentMethods && splitPaymentMethods.method1 === null){
              // if(amountRemaining === null) return message.error("enter the amount transterred");
              setSplitPaymentMethods(prev => {
                return {
                  ...prev,
                  method1: "m_transfer"
                }
              });
              return toggleVpayTransferModal(false);
            }else if (splitPaymentMethods && splitPaymentMethods.method2 === null){
              setSplitPaymentMethods(prev => {
                return {
                  ...prev,
                  method2: "m_transfer"
                }
              });
      
              if (hasDiscount || hasInstantRewardDiscount) {
                handlePayment(
                  `${splitPaymentMethods.method1} + m_transfer`,
                  location,
                  true,
                  discountAmount || instantRewardDiscount,
                  `${originator_account_name}|${originator_account_number}|${originator_bank}|${myReference}_method1:${splitPaymentMethods.method1}-method2:m_transfer`,
                  clearDiscount,
                  transactionDate || Date.now()
                );
              } else {
                handlePayment(
                  `${splitPaymentMethods.method1} + m_transfer`,
                  location,
                  false,
                  null,
                  `${originator_account_name}|${originator_account_number}|${originator_bank}|${myReference}_method1:${splitPaymentMethods.method1}-method2:m_transfer`,
                  clearDiscount,
                  transactionDate || Date.now()
                );
              }
            }
          }else{
            if (hasDiscount || hasInstantRewardDiscount) {
              handlePayment(
                "m_transfer",
                location,
                true,
                discountAmount || instantRewardDiscount,
                `${originator_account_name}|${originator_account_number}|${originator_bank}|${myReference}`,
                clearDiscount,
                transactionDate || Date.now()
              );
            } else {
              handlePayment(
                "m_transfer",
                location,
                false,
                null,
                `${originator_account_name}|${originator_account_number}|${originator_bank}|${myReference}`,
                clearDiscount,
                transactionDate || Date.now()
              );
            }
          }

          // Close Current Modal only when its open
          toggleVpayTransferModal();
          toggleTransactionModal();
          clearTopupGiftCard();
        }
      });
    }
  }, [
    toggleTransactionModal,
    totalPayable,
    clearTopupGiftCard,
    location,
    state,
    toggleVpayTransferModal,
    user,
    hasDiscount,
    hasInstantRewardDiscount,
    handlePayment,
    discountAmount,
    instantRewardDiscount,
    clearDiscount,
    topupGiftCard,
    accountNumber,
    editCard,
    cardId,
    cartList,
    categories,
  ]);

  const handlePostSales = () => {
    if(splitPaymentActive){
      if(splitPaymentMethods && splitPaymentMethods.method1 === null){
        // if(amountRemaining === null) return message.error("enter the amount transterred");
        setSplitPaymentMethods(prev => {
          return {
            ...prev,
            method1: "m_transfer"
          }
        });
        return toggleVpayTransferModal(false);
      }else if (splitPaymentMethods && splitPaymentMethods.method2 === null){
        setSplitPaymentMethods(prev => {
          return {
            ...prev,
            method2: "m_transfer"
          }
        });

        handlePayment(
          `${splitPaymentMethods.method1} + m_transfer`,
          location,
          false,
          null,
          `${inputRef}_method1:${splitPaymentMethods.method1}-method2:m_transfer`,
          clearDiscount,
          Date.now()
        );
      }
    }else{
      handlePayment(
        "m_transfer",
        location,
        false,
        null,
        inputRef,
        clearDiscount,
        Date.now()
      );
    }
  }

  return (
    <Modal
      title={`Pay With Vpay ${
        selectedCustomer ? strings.sendBillTo + selectedCustomer : ""
      }`}
      open={visible}
      width={600}
      onCancel={() => {
        Modal.confirm({
          title: strings.doYouWantToCancelTransaction,
          onOk: () => {
            toggleVpayTransferModal(false);
            setState({
              isLoading: false,
            });
          },
          onCancel: () => {},
        });
      }}
      footer={null}
      destroyOnClose
    >
      <div id="pay_with_bank_transfer">
        <div style={{ textAlign: "center" }}>
          <p>Waiting for transfer to bank account - {accountNumber}</p>
          <p>
              Bank Name: VFD Microfinance bank
          </p>
          <p>
            Amount: {user.currency || user.employer?.currency}
            {Number(totalPayable).toFixed(2)}
          </p>
          <Spin />

          <p style={{ marginTop: "10px" }}></p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>Scan QRCode to pay </p>
          <QRCode size={150} value={qrcode || "loading"} />
        </div>
        {showPostSales ? <div
          style={{
            padding: "0 25%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            marginTop: "2rem"
          }}
        >
          <label>Payment received?</label>
          <Input 
            placeholder="Enter Account name or reference)"
            autoFocus  
            onChange={(e)=>setInputRef(e.target.value)}
          />
          <div
            style={{
              textAlign: "center"
            }}
          >
            <Button 
              type="primary"
              onClick={()=>handlePostSales()}
            >
              Confirm Transaction
          </Button>
          </div>
        </div> : <p style={{textAlign: "center", marginTop: "2rem"}}>0{minutes}:{seconds < 10 ? "0" + seconds : seconds}</p>}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getAllCustomers,
})(VpayTransfer);
