import { Route, Switch } from "react-router";
import Loyalties from "./Loyalties";
import EnrollMembers from "./Loyalties/_partials/EnrollMembers";
import Tier from "./Loyalties/_partials/Tier";
import Members from "./Members";

const MultiLevelLoyalty = ({ match: { path } }) => {
  // path === /d/apps/multi-level/

  return (
    <Switch>
      <Route exact path={`${path}/programs`} component={Loyalties} />

      <Route exact path={`${path}/programs/:id`} component={Tier} />

      <Route
        exact
        path={`${path}/programs/:id/enroll`}
        component={EnrollMembers}
      />

      <Route exact path="/d/apps/multi-level/members" component={Members} />
    </Switch>
  );
};

export default MultiLevelLoyalty;
