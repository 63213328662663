export default class BirthdayMessageAndOffers {
  constructor(client) {
    this.client = client;
  }

  getAllOffers(merchantId) {
    return this.client.get(`/get_merchant_birthday_offer`);
  }

  createBirthdayOffer(data) {
    return this.client.post(`/birthday_offers`, { data });
  }

  saveOffer(merchantId, branchId, time_stamp = 0) {
    // return this.client.post(birthday_offers "{"data"=>{"offer_description"=>"Free cake"}, "birthday_offer"=>{}});
  }

  deleteOffer(productData) {
    return this.client.delete(`/api/v2/birthday_offers/:${productData}`, { data: productData })
  }

  getBirthdayMessage() {
    return this.client.get(`/get_merchant_birthday_preset_sms`);
  }///

  editBirthdayMessage(merchantId, message) {
    return this.client.put(`/birthday_offer_preset_sms/${merchantId}`,
    { data: { preset_sms_text: message, id: merchantId, merchant_id: merchantId }})
  }
 
}
