import React, { useState, useEffect, useRef } from 'react';
import api from '../../../../../api';
import { Modal, Button, message, Spin, Input } from 'antd';
import strings from '../../../../../strings';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { connect } from 'react-redux';
import Pusher from "pusher-js";
import QRCode from 'react-qr-code';

import { 
    getAllCustomers, 
    addNewCustomer 
} from '../../../../../redux/actions/customerActions';
import { 
    saveInvoice, 
    getPaymentMessage,
} from '../../../../../redux/actions/invoiceActions';
import { toggleRenewalModal } from '../../../../../redux/actions/renewalActions';
import { sendNotification } from '../../../../../redux/actions/notificationAction';
import SalesReceipt from '../../SalesReceipt';

const PaystackPaymentModal = ({ 
    user, 
    open, 
    onCancel,
    getAllCustomers,
    saveInvoice,
    location,
    selectedCustomer,
    customerValue,
    clearSelectedCustomer,
    clearCart,
    setPaymentType,
    cartList,
    isTwoPaymentMethod,
    getCartTotalAmount,
    mtier_amount,
    isRecordSalePaymentVisible,
    toggleTransactionModal,
    instantRewardDiscount,
    hasInstantRewardDiscount,
    discountAmount,
    hasDiscount,
    handlePayment,
    clearDiscount,
    splitPaymentActive,
    setSplitPaymentMethods,
    splitPaymentMethods,
    amountRemaining,
    totalAmount
}) => {

    const componentRef = useRef();
    const [ fetchLoading, setFetchLoading ] = useState(false);
    const [ showPostSales, setShowPostSales ] = useState(false);
    const [ payStackDetails, setPayStackDetails ] = useState(null);
    const [ minutes, setMinutes ] = useState(1);
    const [ seconds, setSeconds ] = useState(60);
    const [ time, setTime ] = useState(120);
    const [ inputRef, setInputRef ] = useState("")
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
    const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
    const authUrl = `${BASE_URL}/pusher/auth`;
    const { client } = api.HttpClient.defaults.headers.common;
    const milisecondsToDaysConst = 86400000;
    const business = user.employer ? { ...user.employer } : { ...user };
    const [totalPayable, setTotalPayable] = useState(() => {
        const cartTotalAmount = (() => {
          const cartTotalAmount = isTwoPaymentMethod
            ? getCartTotalAmount() - mtier_amount
            : getCartTotalAmount();
          return cartTotalAmount;
        })();
    
        if (hasDiscount && discountAmount) {
          return cartTotalAmount - discountAmount;
        } else if (hasInstantRewardDiscount && instantRewardDiscount) {
          return cartTotalAmount - instantRewardDiscount;
        } else return cartTotalAmount;
    });

    const typesObj = {
        ngBasic: "basic-pay",
        ngPro: "pro-pay",
        ghBasic: "basic-pay-gh",
        ghPro: "pro-pay-gh",
        intBasic: "basic-pay-int",
        intPro: "pro-pay-int",
    };

    const cartTotalAmount = () => {
        const cartTotalAmount = isTwoPaymentMethod
            ? getCartTotalAmount() - mtier_amount
            : getCartTotalAmount();
        return cartTotalAmount;
    };

    useEffect(()=>{
        getPayStackNuban();
    }, []);

    useEffect(()=>{
      const timerFunc = setInterval(()=>{
        setSeconds(seconds - 1);
        setTime(time - 1);
        if(seconds === 1){
          setMinutes(minutes - 1);
          setSeconds(60);
        }
      }, 1000)
  
      if(seconds === 0 && minutes === 0 && time === 0){
          setShowPostSales(true);
          clearInterval(timerFunc);
      }
  
      return () => clearInterval(timerFunc);
    });

    useEffect(()=>{
        if (user.payout_active || user.employer.payout_active) {
            // Pusher.log = (e) => console.log(e);
      
            const pusher = new Pusher(PUSHER_KEY, {
              cluster: PUSHER_CLUSTER,
              authEndpoint: authUrl,
              auth: {
                params: {
                  uid: user.employer ? user.employer.uid : user.uid,
                  access_token:
                    api.HttpClient.defaults.headers.common["access-token"],
                  client,
                },
              },
            });
      
            const channel = pusher.subscribe(
              `private-ussd_payment_${user.employer ? user.employer.id : user.id}`
            );
      
            let self = this;
      
            channel.bind("new_payment_made", function (data) {
              // console.log("Pusher Data", data);
      
              const {
                status,
                ref_code,
                invoice: { status: invoiceStatus, paid_at, invoice_id, amount },
                customer: { first_name, last_name, phone_number, email },
              } = data;
      
              if (status === "success" && invoiceStatus === "paid") {
                // const { location } = self.state;
      
                // console.log(location);
      
                // Delete Bill if Bill is in Draft Sales
                if (location && location.state && location.state.fromDraftSales) {
                  const draftSalesList = JSON.parse(
                    localStorage.getItem("draftSales")
                  );
      
                  const filteredDraftSales =
                    draftSalesList.length &&
                    draftSalesList.filter(
                      (bill) => bill.id !== location.state.bill_id
                    );
      
                  localStorage.setItem(
                    "draftSales",
                    JSON.stringify(filteredDraftSales)
                  );
      
                  // console.log("Draft Sales Has been deleted");
      
                //   this.setState({ location: null });
                }
      
                const transactionDate = new Date();
      
                getAllCustomers().then((res) => {
                  const customerList =
                    res && res.data.filter((customer) => customer.deleted === false);
      
                  const customer =
                    selectedCustomer &&
                    customerList.find(
                      (item) => item.user_id === customerValue
                    );
      
                  // Show Success Message only When BankTransferMOdal is Open
                  open &&
                    Modal.success({
                      title: `Payment ${
                        amount ? `of ${user.currency}${amount}` : ""
                      } Received Successfully from ${first_name ? first_name : ""} ${
                        last_name ? last_name : ""
                      }`,
                      onOk: () => {
                        clearSelectedCustomer();
                        clearCart();
                        setPaymentType("");
                      },
                      content: (
                        <div style={{ display: "flex" }}>
                          <ReactToPrint
                            trigger={() => (
                              <Button type="primary">Print Receipt</Button>
                            )}
                            content={() => componentRef.current}
                          />
                          <div style={{ display: "none" }}>
                            <SalesReceipt
                              currency={user.currency || user.employer.currency}
                              saleTotal={cartTotalAmount()}
                              paidWith={"bank transfer"}
                              transaction={cartList}
                              saleId={ref_code}
                              purchaseTime={moment(transactionDate || Date.now()).format("DD-MM-YYYY hh:mm a")}
                              customer={customer}
                              user={user}
                              ref={componentRef}
                            //   channel={self.state.selectedBank.code}
                              payment_reference={""}
                            />
                          </div>
                        </div>
                      ),
                    });
      
                //   self.setState((prevState) => ({
                //     ...prevState,
                //     isLoading: false,
                //     selectedBank: {
                //       name: "",
                //       code: "",
                //       isLoading: false,
                //     },
                //   }));
      
                  // Close Current Modal only when its open
                  onCancel();
      
                  // Close Record Sale Modal only when its open
                  isRecordSalePaymentVisible && toggleTransactionModal();
      
                  let secondsToGo = 60;
      
                  if (business.subscription_plan === "Free") {
                    const country =
                      business.currency === "NGN"
                        ? "ng"
                        : business.currency === "GHS"
                        ? "gh"
                        : "int";
      
                    let subscription_plan = "Basic";
      
                    const subPaymentType = typesObj[country + subscription_plan];
      
                    const daysLeft = Math.ceil(
                      (new Date(business.subscription_expires_on) - Date.now()) /
                        milisecondsToDaysConst
                    );
      
                    const modal =
                      daysLeft <= 0 &&
                      Modal.success({
                        title: `${strings.holdOn} ${secondsToGo}`,
                        content: <span>{strings.yourSubscriptionHasExpired}</span>,
                        okText: strings.renew,
                        onOk: () => toggleRenewalModal(),
                      });
      
                    const timer =
                      daysLeft <= 0 &&
                      setInterval(() => {
                        secondsToGo--;
                        modal.update({
                          title: `${strings.holdOn} ${secondsToGo}`,
                        });
                      }, 1000);
      
                    daysLeft <= 0 &&
                      setTimeout(() => {
                        clearInterval(timer);
                        modal.destroy();
                      }, secondsToGo * 1000);
                  } else {
                    const country =
                      business.currency === "NGN"
                        ? "ng"
                        : business.currency === "GHS"
                        ? "gh"
                        : "int";
      
                    const subPaymentType =
                      typesObj[country + business.subscription_plan];
      
                    const daysLeft = Math.ceil(
                      (new Date(business.subscription_expires_on) - Date.now()) /
                        milisecondsToDaysConst
                    );
      
                    const modal =
                      daysLeft <= 0 &&
                      Modal.success({
                        title: `${strings.holdOn} ${secondsToGo}`,
                        content: <span>{strings.yourSubscriptionHasExpired}</span>,
                        okText: strings.renew,
                        onOk: () => toggleRenewalModal(),
                      });
      
                    const timer =
                      daysLeft <= 0 &&
                      setInterval(() => {
                        secondsToGo--;
                        modal.update({
                          title: `${strings.holdOn} ${secondsToGo}`,
                        });
                      }, 1000);
      
                    daysLeft <= 0 &&
                      setTimeout(() => {
                        clearInterval(timer);
                        modal.destroy();
                      }, secondsToGo * 1000);
                  }
                });
              }
            });
          }
    }, []);

    const handlePostSales = () => {
      if(splitPaymentActive){
        if(splitPaymentMethods && splitPaymentMethods.method1.method === null){
          setSplitPaymentMethods(prev => {
            return {
              ...prev,
              method1: {
                method: "m_transfer",
                amount: amountRemaining
              }
            }
          })
        }else if (splitPaymentMethods && splitPaymentMethods.method2 === null){
          setSplitPaymentMethods(prev => {
            return {
              ...prev,
              method1: {
                method: "m_transfer",
                amount: totalAmount
              }
            }
          })

          handlePayment(
            `${splitPaymentMethods.method1.method} + m_transfer`,
            location,
            false,
            null,
            `${inputRef}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-method2:m_transfer:${totalAmount}`,
            clearDiscount,
            Date.now()
          ); 
        }

      }else{
        handlePayment(
          "m_transfer",
          location,
          false,
          null,
          `${inputRef}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-method2:m_transfer:${totalAmount}`,
          clearDiscount,
          Date.now()
        ); 
      }
    }

    const getPayStackNuban = async () => {
        setFetchLoading(true);
        await api.merchants.getPayStackVirtualTerminal()
        .then((res)=>{
            setFetchLoading(false);
            if(res.status === 200){
                const name = user.username ? user.username : user.first_name
                const selectedAccount = res.data.filter((accounts)=>accounts.name.toLowerCase().includes(name));
                selectedAccount.length > 0 
                    ? setPayStackDetails(selectedAccount[0])
                        : setPayStackDetails(res.data[0]);
            }
        })
        .catch((err)=>{
            setFetchLoading(false);
            message.error("unable to fetch terminal details")
        })
    };

  return (
    <>
        <Modal
            open={open}
            // onCancel={onCancel}
            onCancel ={()=>{
                Modal.confirm({
                    title: strings.doYouWantToCancelTransaction,
                    onOk: () => {
                        onCancel();
                    },
                    onCancel: () => {},
                })}
            }
            title="Pay with Paystack"   
        >

            {payStackDetails ? <div style={{ textAlign: "center" }}>
                <p>Waiting for transfer to bank account - {payStackDetails && payStackDetails.paymentMethods[0].account_number}</p>
                <p>
                    Account Name: {payStackDetails && payStackDetails.paymentMethods[0].account_name}
                </p>
                <p>
                    Bank Name: {payStackDetails && payStackDetails.paymentMethods[0].bank}
                </p>
                <p>
                    Amount: {user.currency || user.employer?.currency}
                    {Number(totalPayable).toFixed(2)}
                </p>
                <Spin />

                <p style={{ marginTop: "10px" }}></p>
            </div> : <div style={{ textAlign: "center" }}><Spin /></div>}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                >
                <p>Scan QRCode to pay </p>
                <QRCode size={150} value={`https://paystack.shop/pay/${payStackDetails && payStackDetails.code.toLowerCase()}` || "loading"} />
            </div>
            {showPostSales ? <div
              style={{
                padding: "0 25%",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                marginTop: "2rem"
              }}
            >
              <label>Payment received?</label>
              <Input 
                placeholder="Enter Account name or reference)"
                autoFocus  
                onChange={(e)=>setInputRef(e.target.value)}
              />
              <div
                style={{
                  textAlign: "center"
                }}
              >
                <Button 
                  type="primary"
                  onClick={()=>handlePostSales()}
                >
                  Confirm Transaction
              </Button>
              </div>
            </div> : <p style={{textAlign: "center", marginTop: "2rem"}}>0{minutes}:{seconds < 10 ? "0" + seconds : seconds}</p>}
        </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
    products: state.product.allProducts,
    invoices: state.invoice.allInvoices,
    paymentMessage: state.invoice.paymentMessage,
    error: state.error,
    allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
});
  
export default connect(mapStateToProps, {
    addNewCustomer,
    getAllCustomers,
    saveInvoice,
    getPaymentMessage,
    sendNotification,
    toggleRenewalModal,
})(PaystackPaymentModal);
  