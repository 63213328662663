import React, { useEffect, useState } from 'react';
import { 
    Modal, 
    Button, 
    Input, 
    InputNumber, 
    Select, 
    Spin, 
    Upload, 
    message,
    Tabs,
    Image
   } from 'antd';
import api from '../../../../../../api';
import { Icon, Form  } from "@ant-design/compatible";
import strings from '../../../../../../strings';
import { transformInToFormObject } from '../../../../../../utils/generateFormData';
import EditBundleForm from './editbundle';
import { getBase64 } from '../../../../../../utils/covertBase64';


const FormItem = Form.Item, 
Option = Select.Option
const { TabPane } = Tabs;

const AddBundle = ({ form, open, onCancel, user, getProductBundle }) => {

    const { getFieldDecorator, setFieldsValue } = form;
    const [loading, setLoading ] = useState(false);
    const [ products, setProducts ] = useState([]);
    const [ productsId, setProductsId ] = useState([]);
    const [ textProdId, setTextProdId ] = useState([])
    const [ bundleProducts, setBundleProducts ] = useState([])
    const [ quantity, setQuantity ] = useState([]);
    const [ singleQty, setSingleQty ] = useState("");
    const [ openQuantity, setOpenuantity ] = useState(false);
    const [ modalName, setModalName ] = useState("");
    const [ valued, setValued ] = useState("");
    const [ fileList, setFileList ] = useState([]);
    const [ multiProduct, setMultiProducts ] = useState([]);
    const [ value, setValue ] = useState("");
    const [ errorMsg, setErrorMsg ] = useState("");
    const [ isErrorMsg, setIsErrorMsg ] = useState(false);
    const [ addloading, setAddloading ] = useState(false);
    const [ searchLoading, setSearchloading ] = useState(false)
    const hint = {
        color: "orange",
        fontSize: "13px",
    };
    const maxFileSize = 4000000;

    useEffect(()=>{
        setProducts(products)
    }, [products])

    const handleRemoveProductPicture = file => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.filter((file, i) => i !== index)
        setFileList(newFileList);
    };


      const handleProductPicture = async file => {
        if (file.size > maxFileSize) {
          message.warning(strings.fileTooLargeLessThan4Mb);
          return;
        }

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file);
        }

        setFileList((prev)=>{
            return [
                ...prev,
                file
            ]
        })
      };

    const handleEdit = () =>{
        form.validateFields(async (err, values)=>{
            if(err === null){
                const { name, price, description, barcode, product_ids } = values;
                if(product_ids.length === 0){
                    return Modal.error({
                        title: "Please select at least 2 products!"
                    })
                }

                const data = {
                    name,
                    quantity: quantity,
                    price,
                    barcode: barcode === undefined ? "" : barcode,
                    description: description === undefined ? "" : description,
                    images: fileList,
                    product_ids: product_ids
                }

                
                
                const formData = new FormData();
                formData.append("data[name]", name);
                // formData.append("data[price]", quantity);
                formData.append("data[price]", price);
                barcode && formData.append("data[barcode]", barcode);
                description && formData.append("data[description]", description);
                fileList.map((file) => {
                    formData.append("data[images][]", file)
                });
                product_ids.map((productId) => {
                    formData.append("data[product_ids][]", productId);
                });
                quantity.map((qty) => {
                    formData.append("data[quantity][]", qty);
                });
                // formData.append("product_ids", productsId);
                // return console.log("payload", formData);

                // for( var pair of formData.entries()){
                //     console.log(...pair);
                // }
                setAddloading(true);
                const response = await api.product.addBundleProduct(formData);

                if(response.status === 200){
                    setAddloading(false);
                    Modal.success({
                        title: `Bundle - ${name} created successfully`,
                        onOk: () =>{
                            getProductBundle();
                            onCancel();
                        }
                    });
                }else{
                    setAddloading(false);
                    Modal.error({
                        title: `${response.err.message}`,
                        onOk: () =>{
                            onCancel();
                        }
                    });
                }
                

            }
        })
   }

    const handleSearch = (name) => {
        const doesProductExist = products.filter((prod)=>{
            return prod.name === name;
        });
        if(doesProductExist.length > 0){
            const errorM = "The product you are searching for is already added!"
            setErrorMsg(errorM);
            setValue("");
            return setIsErrorMsg(true);
        }
        setLoading(true);
        api.HttpClient.get(`/search_product/${name}`)
        .then((res)=>{
            setLoading(false);
            if(res.status === 200){
                if(res.data.length === 1){
                    const produc = {
                        name: res.data[0].name,
                        quantity: ""
                    }
                    setErrorMsg("");
                    setIsErrorMsg(false);
                    setProducts(prev=>[...prev, produc])
                    setProductsId((prev)=>[...prev, res.data[0].id.toString()]);
                    setModalName(res.data[0].name);
                    setOpenuantity(true);
                }else if(res.data.length > 1){
                    const product = res.data.filter((prod)=>{
                        return prod.name.trim() === name;
                    });
                    if(product.length === 1){
                        const produc = {
                            name: product[0].name,
                            quantity: ""
                        }
                        setErrorMsg("");
                        setIsErrorMsg(false);
                        setProducts(prev=>[...prev, produc])
                        setProductsId((prev)=>[...prev, product[0].id.toString()]);
                        setModalName(product[0].name);
                        setOpenuantity(true);

                    }else{   
                        setErrorMsg("");
                        setIsErrorMsg(false);
                        setMultiProducts(res.data);
                    }
                }
            }
        })
        .catch((err)=>{
            setLoading(false);
            console.log(err);
        })
   }

   const handleQuantityChoice = (value) =>{
        setQuantity((prev)=>{
            return [
                ...prev,
                value.toString()
            ]
        })
        setOpenuantity(false);
        setSingleQty("");
   }

   const handleOnChange = (e) => {
    

   }
   const handleChoice = (e) => {
    const { code, target } = e;
    const { value } = target;

    if(value === "") return;
    setSearchloading(true);
    api.HttpClient.get(`/search_product/${value}`)
    .then((res)=>{
        if(res.status === 200){
            setSearchloading(false);
            if(res.data.length > 0){
                setBundleProducts(res.data)
            }
        }
    })
    .catch((err)=>{
        setSearchloading(false);
        console.log(err);
    })
   }

   const handledelete = (name) =>{
    const newProduct = products.filter((prod)=>{
        return prod.name != name;
    });
    setProducts(newProduct);
   }

    return (
        <>
        <Modal
            open={open}
            onCancel={onCancel}
            title={`Add Bundle Product`}
            onOk={()=>handleEdit()}
            okButtonProps={{loading: addloading}}
            destroyOnClose
        >
            <Form onFinish={()=>handleEdit()} layout="vertical">
                <FormItem label="Select Products">
                    {getFieldDecorator("product_ids", {
                        rules: [
                        {
                            required: true,
                            message: "Please select at least 2 products!",
                        },
                        ],
                    })(
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            placeholder="Select Products"
                            optionFilterProp="children"
                            size="large"
                            onChange={(e)=>handleOnChange(e)}
                            onKeyDown={(e)=>handleChoice(e)}
                            onDeselect={(e)=>{
                                const index = textProdId.indexOf(e)
                                const newText = textProdId.filter((id)=>{
                                    return id !== e
                                });
                                if( index > -1){
                                    const qty = [ ...quantity ]
                                    qty.splice(index, 1);
                                    setQuantity(qty);
                                }
                                setTextProdId(newText);
                            }}
                            onSelect={(e)=>{
                                setOpenuantity(true);
                                setTextProdId((prev)=>{
                                    return [
                                        ...prev,
                                        e
                                    ]
                                });
                            }}
                            style={{ width: "100%" }}
                            loading={searchLoading}
                            filterOption={(input, option) => {
                                return (
                                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            disabled={false}
                        >
                        {bundleProducts &&
                            bundleProducts.map(bundleProduct => (
                            <Option
                                key={bundleProduct.name}
                                value={bundleProduct.id}
                                id={bundleProduct.id}
                                name={bundleProduct.name}
                            >
                                {bundleProduct.name ? bundleProduct.name : "N/A"} 
                            </Option>
                            ))}
                        </Select>
                    )}
                </FormItem>
                
                <FormItem label="Name">
                    {getFieldDecorator("name", {
                        // initialValue: name,
                        rules: [{ required: true, message: "Please enter name" }] 
                    })(
                            <Input 
                            size="large" 
                            placeholder="Name"
                        />
                        )}
                </FormItem>
    
                <FormItem label="Price">
                    {getFieldDecorator("price", {
                        // initialValue: `${price}`,
                        rules: [{ required: true, message: "Please enter price" }] 
                    })(
                            <InputNumber size="large" style={{width: "100%"}} placeholder="Price"/>
                        )}
                </FormItem>
    
                <FormItem label="Description">
                    {getFieldDecorator("description", {
                        // initialValue: quantity,
                        // rules: [{ required: true, message: "Please enter quantity" }]
                     })(
                            <Input size="large" style={{width: "100%"}} placeholder="Description"/>
                        )}
                </FormItem>
    
                <FormItem label="Product Sku">
                    {getFieldDecorator("barcode", {
                        // initialValue: barcode,
                        // rules: [{ required: true, message: "Please enter product sku" }]
                     })(
                            <Input size="large" placeholder="Product Sku"/>
                        )}
                </FormItem>
    
                <FormItem label={strings.productImage}>
                    {getFieldDecorator("images")(
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Upload
                          // {...this.uploadProps}
                          fileList={fileList}
                          beforeUpload={handleProductPicture}
                          onRemove={handleRemoveProductPicture}
                          multiple
                        >
                          <Button>
                            <Icon type="upload" /> {strings.selectProductImage}
                          </Button>
                          <p style={hint}>
                            {user.currency === "NGN"
                              ? strings.productsWithPicturesArePublished
                              : ""}
                          </p>
                        </Upload>
                      </div>
                    )}
                  </FormItem>
                  {fileList.length > 0 && (
                      <div style={{display: "flex", alignItems: "center", gap:15, marginBottom: 20}}>
                        {fileList.map((file, index) => {
                          return (
                            <div style={{position: "relative"}}>
                                <Image
                                    src={file.preview}
                                    alt="images"
                                    key={index}
                                    height={60}
                                    width={60}
                                />
                                <Icon 
                                    type="close" 
                                    style={{
                                    position: "absolute", 
                                    right: 5, 
                                    cursor: "pointer"
                                    }}  
                                    onClick={() => handleRemoveProductPicture(file)}
                                />
                            </div>
                            
                          )
                        })}
                      </div>
                    )}
                
            </Form>
        </Modal>
        <Modal
            open={openQuantity}
            onCancel={()=>setOpenuantity(false)}
            title={`Choose quantity of ${modalName} to add`}
            footer={null}
            destroyOnClose
        >
            <p>Enter Quantity of {modalName}</p>
            <InputNumber
                value={singleQty}
                onChange={(value)=>setSingleQty(value)}
                onKeyDown={(e)=>{
                    if(e.code === "Enter"){
                        handleQuantityChoice(e.target.value)
                    }
                }}
            />
            <Button
                type='primary'
                onClick={()=>handleQuantityChoice(singleQty)}
            >
                Add
            </Button>
        </Modal>
        </>
      )
}

const AddBundleForm = Form.create()(AddBundle)
export default AddBundleForm;