import React, { useState, useEffect } from 'react';
import api from '../../../../api';
import { Button, Modal, message } from 'antd';
import strings from '../../../../strings';
import moment from 'moment';
import CustomTable from '../../../../components/CustomDataTable';

const PayStackNuban = () => {
    const [ paySatckDetails, setPayStackDetails ] = useState([]);
    const [ createNubanLoading, setCreateNubanLoading ] = useState(false);
    const [ fetchLoading, setFetchLoading ] = useState(false);
    const [ openDetails, setOpenDetails ] = useState(false);
    const [ detailsToShow, setDetailsToShow ] = useState([]);

    useEffect(()=>{
        getPayStackNuban();
    }, []);

    const handleCreateNuban = async () => {
        setCreateNubanLoading(true);
        await api.merchants.createPayStackVirtualTerminal()
        .then((res)=>{
            setCreateNubanLoading(false);
            Modal.success({
                title: "Nuban created successfully",
                onOk: () => {
                    getPayStackNuban();
                }
            })
        })
        .catch((err)=>{
            setCreateNubanLoading(false);
            Modal.error({
                title: `unable to create, ${err.message}`
            })
        })
    }

    const getPayStackNuban = async () => {
        setFetchLoading(true);
        await api.merchants.getPayStackVirtualTerminal()
        .then((res)=>{
            setFetchLoading(false);
            if(res.status === 200){
                setPayStackDetails(res.data);
            }
        })
        .catch((err)=>{
            setFetchLoading(false);
            message.error("unable to fetch terminal details")
        })
    };

    const columns = [
        {
          title: strings.terminalId,
          dataIndex: "id",
          key: "id"
        },
        {
          title: strings.terminalName,
          dataIndex: "name",
          key: "name",
          render: (record) => (<span style={{ wordBreak: "break-all"}}>{record}</span>)
        //   ...this.getColumnSearchProps("customerName"),
        },
        {
          title: strings.status,
          dataIndex: "active",
          render: (record) => (record ? <span>Active</span> : <span>Not Active</span>),
          filters: ["Active", "Not Active"].map((status) => ({
            text: status,
            value: status,
          })),
          onFilter: (value, record) => {
            return record.status === value;
          },
        },
        {
          title: strings.accountName,
          dataIndex: "paymentMethods",
          render: (record) => {
            return record.map((rec)=>{
                return <span style={{ wordBreak: "break-all"}}>{rec.account_name}</span>
            })
          },
        },
        {
            title: strings.accountNum,
            dataIndex: "paymentMethods",
            render: (record) => {
              return record.map((rec)=>{
                  return <span>{rec.account_number}</span>
              })
            },
        },
        {
            title: strings.terminalBank,
            dataIndex: "paymentMethods",
            render: (record) => {
              return record.map((rec)=>{
                  return <span>{rec.bank}</span>
              })
            },
        },
        {
            title: strings.dedicatedId,
            dataIndex: "paymentMethods",
            render: (record) => {
              return record.map((rec)=>{
                  return <span>{rec.dedicated_nuban_id}</span>
              })
            },
        },
        {
            title: strings.type,
            dataIndex: "paymentMethods",
            render: (record) => {
              return record.map((rec)=>{
                  return <span style={{ wordBreak: "break-all"}}>{rec.type}</span>
              })
            },
        },
        {
          title: strings.createdDate,
          dataIndex: "created_at",
          render: (record) => (record ? <span>{moment(record).format("DD-MM-YYYY   hh:mma")}</span> : <span>N/A</span>),
          sorter: (a, b) => moment(a.created_at) > moment(b.created_at),
        },
      ];

  return (
    <>
        <div style={{ margin: "20px"}}>
            <h2 style={{ textAlign: "center" }}>Virtual Terminals</h2>
            <div style={{ marginBottom: "30px"}}>
                <Button
                    type="primary"
                    onClick={handleCreateNuban}
                    loading={createNubanLoading}
                >
                    Generate Virtual Terminal
                </Button>
            </div>
            <CustomTable 
                loading={fetchLoading}
                columns={columns}
                dataSource={paySatckDetails}
                onRow={(record)=>{
                    return {
                        onClick: (e) => {
                            setDetailsToShow(record);
                            setOpenDetails(true);
                        }
                    }
                }}
            />
        </div>
        {openDetails 
        && <Modal
            open={openDetails}
            onCancel={()=>setOpenDetails(false)}
            title={`${detailsToShow && detailsToShow.name} details`}
            onOk={()=>setOpenDetails(false)}
        >
            <p>
                <strong>{strings.terminalName}</strong>: {detailsToShow.name}
            </p>
            <p>
                <strong>{strings.status}</strong>: {detailsToShow.active ? "Active" : "Not Active"}
            </p>
            <p>
                <strong>{strings.accountName}</strong>: {detailsToShow.paymentMethods[0].account_name}
            </p>
            <p>
                <strong>{strings.accountNum}</strong>: {detailsToShow.paymentMethods[0].account_number}
            </p>
            <p>
                <strong>{strings.terminalBank}</strong>: {detailsToShow.paymentMethods[0].bank}
            </p>
            <p>
                <strong>{strings.dedicatedId}</strong>: {detailsToShow.paymentMethods[0].dedicated_nuban_id}
            </p>
            <p>
                <strong>{strings.type}</strong>: {detailsToShow.paymentMethods[0].type}
            </p>
            <p>
                <strong>{strings.createdDate}</strong>: {moment(detailsToShow.created_at).format("DD-MM-YYYY   hh:mma")}
            </p>
        </Modal>}
    </>
  )
}

export default PayStackNuban;