import React, { useEffect, useState } from 'react';
import { Modal, Button, Radio, Select, Spin, Tag } from 'antd';
import strings from '../../strings';
import { connect } from 'react-redux';
import { getPricingPlanData } from '../../redux/actions/merchantAction';
import { addMerchantCurrentPlan } from '../../redux/actions/merchantAction';
import { getAllBusinessBranches } from '../../redux/actions/branchActions';
import { fetchActiveApps } from '../../redux/actions/appActions';
import { signoutUser } from '../../redux/actions/authActions';
import { toggleSubscribeModal } from '../../redux/actions/renewalActions';
import paymentLogo from "../../icons/badges.png"
import axios from 'axios';
import CheckoutForm from './CheckoutForm';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from 'moment';
import AppsPrice from '../../containers/Sales/_partials/RenewalModal/_partial/AppsPrice';
import { useApolloClient } from '@apollo/client';

const RadioGroup = Radio.Group;
const Option = Select.Option;
const nairaDollarRate = process.env.REACT_APP_NAIRA_DOLLAR_RATE || 700;
const clientKey = process.env.REACT_APP_STRIPE_CLIENT_KEY;
const milisecondsToDaysConst = 86400000;
const stripePromise = loadStripe(clientKey);
const headers = {
    ["Access-Control-Allow-Origin"]: "*",
    ["multi-level-token"]: "",
    ["user-auth"]: "",
}


const SubscriptionModal = ({
    user, 
    allBranches,
    addMerchantCurrentPlan,
    signoutUser,
    isSubscribeModalOpen,
    toggleSubscribeModal,
    subscriptionPlanPrice,
    getSubscription,
    activePaidApps,
    totalAppsPrice,
    loadingAllBranches,
    subscriptionStatus
}) => {
    const client = useApolloClient();
    const [ action, setAction ] = useState(null);
    const [ selectedPlan, setSelectedPlan ] = useState(null);
    const [ showPlanDetails, setShowPlanDetails ] = useState(false);
    const [ showDuration, setShowDuration ] = useState(false);
    const [ duration, setDuration ] = useState(1);
    const [ selectedDuration, setSelectedDuration ] = useState(null);
    const [ durationInterval, setDurationInterval ] = useState([
        // {key: "1D",value: "1 day"},
        {key: "1M",value: "1 month"},
        {key: "3M", value: "3 month"},
        {key: "6M", value: "6 month"},
        {key: "1Y", value: "1 year"}
    ]);
    const [ timeKey, setTimeKey ] = useState(null);
    const [ checkoutFormOpen, setCheckoutFormOpen ] = useState(false);
    const [ clientSecret, setClientSecret ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ subscriptionID, setSubScriptionID ] = useState(null);
    const [staffs, setStaffs] = useState([]);
    const [plan, setPlan] = useState({});
    const [totalPrice, setTotal] = useState(0);
    const [noOfStaffs, setNumOfStaffs] = useState(0);
    const [numOfBranches, setNumOfBranches] = useState(0);
    const [numOfEcommerceBranch, setNumOfEcommerceBranch] = useState(0);
    const [ totalBranchPrice, setTotalBranchPrice ] = useState({
        unit: 0,
        total: 0
    });
    const [ totalStaffPrice, setTotalStaffPrice ] = useState({
        unit: 0,
        total: 0
    });
    const [ totalECommercePrice, setTotalECommercePrice ] = useState({
        unit: 0,
        total: 0
    });
    const [ activeAppState, setActiveAppState ] = useState([]);
    const [ activeAppStateTotal, setActiveAppStateTotal ] = useState([]);
    const [ fromSubscription, setFromSubscription ] = useState(false);
    
    const [ planDetails, setPlanDetails ] = useState({
        price: "",
        currency: ""
    });
    const [ planInterval, setPlanInterval ] = useState({
        intervalPeriod: "",
        intervalTime: ""
    })
    const [ planName, setPlanName ] = useState(null);
    const [ planAlone, setPlanAlone ] = useState(null)

    useEffect(()=>{
        const appsCount = activePaidApps && activePaidApps.filter(app => app.app.app_name !== "Instant bank Transfer \u0026 USSD");
        activePaidApps && setActiveAppState([...appsCount])
    }, [activePaidApps]);

    const basic_price_id = 
        duration === "1" ? process.env.REACT_APP_BASIC_PRICE_ID_1M 
        : duration === "3" ? process.env.REACT_APP_BASIC_PRICE_ID_3M 
        : duration === "6" ? process.env.REACT_APP_BASIC_PRICE_ID_6M 
        : process.env.REACT_APP_BASIC_PRICE_ID_1Y;

    const basic_plus_price_id = duration === "1" ? process.env.REACT_APP_BASIC_PLUS_PRICE_ID_1M 
    : duration === "3" ? process.env.REACT_APP_BASIC_PLUS_PRICE_ID_3M 
    : duration === "6" ? process.env.REACT_APP_BASIC_PLUS_PRICE_ID_6M 
    : process.env.REACT_APP_BASIC_PLUS_PRICE_ID_1Y; 
    
    const pro_plan_id =  duration === "1" ? process.env.REACT_APP_PRO_PRICE_ID_1M 
    : duration === "3" ? process.env.REACT_APP_PRO_PRICE_ID_3M 
    : duration === "6" ? process.env.REACT_APP_PRO_PRICE_ID_6M 
    : process.env.REACT_APP_PRO_PRICE_ID_1Y;

    const staff_price = duration === "1" ? process.env.REACT_APP_STAFF_PRICE_ID_1M 
    : duration === "3" ? process.env.REACT_APP_STAFF_PRICE_ID_3M 
    : duration === "6" ? process.env.REACT_APP_STAFF_PRICE_ID_6M 
    : process.env.REACT_APP_STAFF_PRICE_ID_1Y;

    const branch_price = duration === "1" ? process.env.REACT_APP_BRANCH_PRICE_ID_1M 
    : duration === "3" ? process.env.REACT_APP_BRANCH_PRICE_ID_3M 
    : duration === "6" ? process.env.REACT_APP_BRANCH_PRICE_ID_6M 
    : process.env.REACT_APP_BRANCH_PRICE_ID_1Y;

    const product_bundle =  duration === "1" ? process.env.REACT_APP_PRODUCT_BUNDLE_PRICE_ID_1M 
    : duration === "3" ? process.env.REACT_APP_PRODUCT_BUNDLE_PRICE_ID_3M 
    : duration === "6" ? process.env.REACT_APP_PRODUCT_BUNDLE_PRICE_ID_6M 
    : process.env.REACT_APP_PRODUCT_BUNDLE_PRICE_ID_1Y;

    const KDS = duration === "1" ? process.env.REACT_APP_KDS_PRICE_ID_1M 
    : duration === "3" ? process.env.REACT_APP_KDS_PRICE_ID_3M 
    : duration === "6" ? process.env.REACT_APP_KDS_PRICE_ID_6M 
    : process.env.REACT_APP_KDS_PRICE_ID_1Y;

    const multiTierLoyalty = duration === "1" ? process.env.REACT_APP_MULTI_TIER_LOYALTY_PRICE_ID_1M 
    : duration === "3" ? process.env.REACT_APP_MULTI_TIER_LOYALTY_PRICE_ID_3M 
    : duration === "6" ? process.env.REACT_APP_MULTI_TIER_LOYALTY_PRICE_ID_6M 
    : process.env.REACT_APP_MULTI_TIER_LOYALTY_PRICE_ID_1Y;

    const serviceAndBooking = duration === "1" ? process.env.REACT_APP_SERVICE_AND_BOOKING_PRICE_ID_1M 
    : duration === "3" ? process.env.REACT_APP_SERVICE_AND_BOOKING_PRICE_ID_3M 
    : duration === "6" ? process.env.REACT_APP_SERVICE_AND_BOOKING_PRICE_ID_6M 
    : process.env.REACT_APP_SERVICE_AND_BOOKING_PRICE_ID_1Y;

    const eCommerce_price = duration === "1" ? process.env.REACT_APP_WOO_COMMERCE_PRICE_ID_1M 
    : duration === "3" ? process.env.REACT_APP_WOO_COMMERCE_PRICE_ID_3M 
    : duration === "6" ? process.env.REACT_APP_WOO_COMMERCE_PRICE_ID_6M 
    : process.env.REACT_APP_WOO_COMMERCE_PRICE_ID_1Y;

    const wooCommerce = duration === "1" ? process.env.REACT_APP_WOO_COMMERCE_PRICE_ID_1M 
    : duration === "3" ? process.env.REACT_APP_WOO_COMMERCE_PRICE_ID_3M 
    : duration === "6" ? process.env.REACT_APP_WOO_COMMERCE_PRICE_ID_6M 
    : process.env.REACT_APP_WOO_COMMERCE_PRICE_ID_1Y;

    const acceptedCurrency = ["GBP", "EUR", "USD", "NGN"]

    //////useEffects for all branches, staffs and ecommerce
    useEffect(() => {
        if (allBranches.length) {
          // Filter Branches that are not Ecommerce
          const filteredBranches = allBranches.filter(
            (branch) => branch.name !== "Ecommerce"
          );
    
          const ecommerceBranch = allBranches.filter(
            (branch) => branch.name.toLowerCase() === "ecommerce"
          );
    
          setNumOfEcommerceBranch(ecommerceBranch.length);
          setNumOfBranches(filteredBranches.length);
    
          const staffs = filteredBranches.flatMap((branch) => {
            return branch.staff;
          });

          setNumOfStaffs(staffs.length);
    
          setStaffs(staffs);
        }
    }, [allBranches]);

    useEffect(()=>{
        const totalAppsState = activeAppState.reduce((prev, curr)=>{
            return prev + Number(curr.app.cost);
        }, 0) 
        setActiveAppStateTotal(totalAppsState);
    }, [activeAppState]);


    /////////////STAFF, BRANCH AND ECOMMERCE ///////////////////
    // useEffect(()=>{
    //     const filteredBranches = allBranches.filter(
    //         (branch) => branch.name !== "Ecommerce"
    //     );
    
    //     // Price for Ecommerce Branch
    //     const ecommercePriceToFixed = Number(ecommercePrice).toFixed(2);
    
    //     const branchDiscount = Number(branchPrice) * branch_discount_percentage;
    
    //     const discounted_branch_price = (numOfBranches) =>
    //         (Number(branchPrice) - branchDiscount) * (numOfBranches - 1).toFixed(2); // One which is substracted from the incoming ```numOfBranches``` represent the Default/Base Branch.
    
    //     const undiscounted_branch_price = (numOfBranches) =>
    //         (Number(branchPrice) * numOfBranches).toFixed(2);

    //     const staffDiscount = Number(staffPrice) * staff_discount_percentage;
    
    //     const discounted_staff_price = (numOfStaffs) =>
    //         ((Number(staffPrice) - staffDiscount) * numOfStaffs).toFixed(2);
    
    //     const undiscounted_staff_price = (numOfStaffs) =>
    //         (Number(staffPrice) * numOfStaffs).toFixed(2);

    //     // setTotalStaffPrice(()=>{
    //     //     return noOfStaffs > 3
    //     //         ? discounted_staff_price(noOfStaffs)
    //     //         : undiscounted_staff_price(noOfStaffs)
    //     // });

    //     // setTotalBranchPrice(()=>{
    //     //     return numOfBranches >= 4
    //     //     ? discounted_branch_price(numOfBranches)
    //     //         : numOfBranches < 4 && numOfBranches > 1
    //     //             ? undiscounted_branch_price(numOfBranches - 1) // One subtracted here represents the Default/Base Branch
    //     //                 : 0
    //     // });

    //     setTotalECommercePrice(()=>{
    //         return  numOfEcommerceBranch > 0
    //         ? Number(ecommercePrice) * numOfEcommerceBranch
    //         : 0
    //     })

    //     const renewal = {
    //         total_staff_price:
    //             noOfStaffs > 3
    //             ? discounted_staff_price(noOfStaffs)
    //             : undiscounted_staff_price(noOfStaffs),
        
    //         total_branch_price:
    //             numOfBranches >= 4
    //             ? discounted_branch_price(numOfBranches)
    //             : numOfBranches < 4 && numOfBranches > 1
    //             ? undiscounted_branch_price(numOfBranches - 1) // One subtracted here represents the Default/Base Branch
    //             : 0,
        
    //         ecommerce_branch_price:
    //             numOfEcommerceBranch > 0
    //             ? Number(ecommercePrice) * numOfEcommerceBranch
    //             : 0,
        
    //         total_apps_price: 
    //             totalAppsPrice 
    //             ? Number(totalAppsPrice) 
    //                 : 0
    //     };

    //     const renewal_subTotal = 
    //         Number(planDetails.price) + Number(renewal.total_staff_price) +
    //         Number(renewal.total_branch_price) +
    //         Number(renewal.ecommerce_branch_price )+ 
    //         Number(renewal.total_apps_price)
        

    //     // console.log("klklk",renewal_subTotal, planPrice());

    //     const subTotal = renewal_subTotal;
        
    //     const total = Number(subTotal * duration).toFixed(2);

    //     setTotal(total);

    // }, [noOfStaffs, numOfBranches, numOfEcommerceBranch, duration])

    //////END OF STAFF, BRANCH AND ECOMMERCE////////////////////////////////
    //////SUBSCRIPTION FUNCTIONS AND CALLS/////////////////////////////////

    const cancelSubscription = async () => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/cancel-subscription`, { merchant_id: user.id }, {
            headers: {
                ["Access-Control-Allow-Origin"]: "*",
                ["multi-level-token"]: "",
                ["user-auth"]: "",
            }
        })
        .then((res)=>{
            if(res.status === 200){
                Modal.success({
                    title: `Your subscription to ${user.subscription_plan} plan has been cancelled`,
                    content: `Your current plan will expire on ${moment(user.subscription_expires_on).format("DD-MM-YYYY hh:mma")}`,
                    onOk: () => {
                        localStorage.removeItem("client_stripe_secret");
                        localStorage.removeItem("is_from_subscription");
                        localStorage.removeItem("stripe_subscription_id");
                        localStorage.removeItem("no_of_staff_branch");
                        localStorage.removeItem("plan_details");
                        localStorage.removeItem("plan_name");
                        localStorage.removeItem("plan_alone");
                        getSubscription(user.id);
                        toggleSubscribeModal();
                        setLoading(false);
                    }
                });
            }
        })
        .catch((err)=>{
            console.log(err.message, err.response.data.error);
            Modal.error({
                title: `Unable to cancel subscription, ${err.response?.data.error || err.message}`,
                onOk: () => {
                    setLoading(false);
                }
            })
        })
    }

    const silentCancelSubscription = async () => {
        // setLoading(true);
        await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/cancel-subscription`, { merchant_id: user.id }, {
            headers: {
                ["Access-Control-Allow-Origin"]: "*",
                ["multi-level-token"]: "",
                ["user-auth"]: "",
            }
        })
        .then((res)=>{
            if(res.status === 200){
                localStorage.removeItem("client_stripe_secret");
                localStorage.removeItem("is_from_subscription");
                localStorage.removeItem("stripe_subscription_id");
                // localStorage.removeItem("no_of_staff_branch");
                localStorage.removeItem("plan_details");
                localStorage.removeItem("plan_name");
                localStorage.removeItem("plan_alone");
                // getSubscription(user.id);
            }
        })
        .catch((err)=>{
            console.log(err.message);
        })
    }

    useEffect(()=>{
        const isFromSub = localStorage.getItem("is_from_subscription");
        const isClient = localStorage.getItem("client_stripe_secret");
        const isPlanName = localStorage.getItem("plan_name");
        if(!isFromSub) return;
        if(!isClient) return;
        if(!isPlanName) return;

        setClientSecret(JSON.parse(isClient));
        setPlanName(JSON.parse(isPlanName));
        setFromSubscription(true);
        setCheckoutFormOpen(true);
    }, []);

    const convertToDollar = (amount) => {
        return (Number(amount) / nairaDollarRate).toFixed(2);
    };

    const handleSubscription = async () => {
        const customerDetails = {
            email: user.email,
            name: `${user.first_name} ${user.last_name}`,
            address_line1: user.address_line1,
            city: user.address_line2,
            state: user.state,
            country: user.country,
            postal_code: user.postal_code || ""
        }

        let items = [];
        let priceId;
        if(selectedPlan === "basic"){
            priceId =  basic_price_id;
        }else if( selectedPlan === "pro"){
            priceId =  pro_plan_id;
        }else{
            priceId =  basic_plus_price_id;
        }
        items.push({
            price: priceId
        })

        if(!planName) return Modal.error({
            title: "Select subscription plan"
        }); 

        numOfBranches - 1 > 0 
        && items.push({
            price:  branch_price,
            quantity: numOfBranches - 1
        });
        noOfStaffs > 0 
        && items.push({
            price: staff_price,
            quantity: noOfStaffs
        });
        numOfEcommerceBranch > 0 
        && items.push({
            price: eCommerce_price,
            quantity: numOfEcommerceBranch
        });

        let appsToPay = [];
        activePaidApps && await activePaidApps.map((apps)=>{
            if(apps.app.app_name === "Product Bundles"){
                appsToPay.push({
                    price: product_bundle
                })
            }else if(apps.app.app_name === "Services, Bookings & Appointments"){
                appsToPay.push({
                    price: serviceAndBooking 
                })
            }else if(apps.app.app_name === "Loystar KDS - Kitchen Display System"){
                appsToPay.push({
                    price: KDS
                })
            }else if(apps.app.app_name === "Multi-Level Loyalty"){
                appsToPay.push({
                    price: multiTierLoyalty
                })
            }else if (apps.app.app_name === "Loystar Woo-Commerce Plugin for Wordpress"){
                appsToPay.push({
                    price: wooCommerce
                })
            }
        });

        appsToPay.length > 0 && items.push(...appsToPay)

        // console.log("items",items, numOfBranches, noOfStaffs, numOfEcommerceBranch, branch_price)
        if(planInterval.intervalPeriod === "") return Modal.error({
            title: "Select subscription interval"
        })

        const data = {
            customerDetails,
            items,
            currency: acceptedCurrency.includes(user.currency) ? user.currency.toLowerCase() : "gbp",
            merchant_id: user.id
        };

        setLoading(true);
        await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/create-subscription`, data, {
            headers: {
                ["Access-Control-Allow-Origin"]: "*",
                ["multi-level-token"]: "",
                ["user-auth"]: "",
            }
        })
        .then((res)=>{
            setSubScriptionID(res.data.id);

            setClientSecret(res.data.latest_invoice.payment_intent.client_secret);

            const subscribedPlans = res.data.latest_invoice.lines.data;

            subscribedPlans && subscribedPlans.map((plan)=>{
                if(plan.price.lookup_key.split("_")[0].toLowerCase().includes(planAlone.toLowerCase().split(" ")[0])){
                    // if(user && user.currency !== "GBP"){
                        setPlanDetails((prev)=>{
                            return{
                                ...prev,
                                price: (parseFloat(plan.amount) / 100).toFixed(2)
                            }
                        })
                    // }
                }else if(plan.price.lookup_key.toLowerCase().includes("branch")){
                    const unitPrice = (parseFloat(plan.amount) / 100);

                    setTotalBranchPrice({
                        unit: (unitPrice / (numOfBranches - 1)).toFixed(2),
                        total: unitPrice.toFixed(2)
                    });
                }else if(plan.price.lookup_key.toLowerCase().includes("staff")){
                    const unitPrice = (parseFloat(plan.amount) / 100);

                    setTotalStaffPrice({
                        unit: (unitPrice / noOfStaffs).toFixed(2),
                        total: unitPrice.toFixed(2)
                    });
                }else if(plan.price.lookup_key.toLowerCase().includes("woocommerce")){
                    const unitPrice = (parseFloat(plan.amount) / 100);

                    setTotalECommercePrice({
                        unit: (unitPrice / numOfEcommerceBranch).toFixed(2),
                        total: unitPrice.toFixed(2)
                    });
                }else if(plan.price.lookup_key.toLowerCase().includes("kds")){
                    const unitPrice = (parseFloat(plan.amount) / 100).toFixed(2);

                    const findout = activeAppState.filter(ap => ap.app.app_name === "Loystar KDS - Kitchen Display System");
                    const notApps = activeAppState.filter(ap => ap.app.app_name !== "Loystar KDS - Kitchen Display System");

                    findout[0].app.cost = unitPrice;

                    setActiveAppState([
                        ...findout,
                        ...notApps
                    ]);
                }else if(plan.price.lookup_key.toLowerCase().includes("services")){
                    const unitPrice = (parseFloat(plan.amount) / 100).toFixed(2);

                    const findout = activeAppState.filter(ap => ap.app.app_name === "Services, Bookings & Appointments");
                    const notApps = activeAppState.filter(ap => ap.app.app_name !== "Services, Bookings & Appointments");

                    findout[0].app.cost = unitPrice;

                    setActiveAppState([
                        ...findout,
                        ...notApps
                    ]);
                }else if(plan.price.lookup_key.toLowerCase().includes("bundle")){
                    const unitPrice = (parseFloat(plan.amount) / 100).toFixed(2);

                    const findout = activeAppState.filter(ap => ap.app.app_name === "Product Bundles");
                    const notApps = activeAppState.filter(ap => ap.app.app_name !== "Product Bundles");

                    findout[0].app.cost = unitPrice;

                    setActiveAppState([
                        ...findout,
                        ...notApps
                    ]);
                }else if(plan.price.lookup_key.toLowerCase().includes("multitier")){
                    const unitPrice = (parseFloat(plan.amount) / 100).toFixed(2);

                    const findout = activeAppState.filter(ap => ap.app.app_name === "Multi-Level Loyalty");
                    const notApps = activeAppState.filter(ap => ap.app.app_name !== "Multi-Level Loyalty");

                    findout[0].app.cost = unitPrice;

                    setActiveAppState([
                        ...findout,
                        ...notApps
                    ]);
                }
            });

            const total = subscribedPlans.reduce((prev, curr)=> {
                return prev + Number(curr.amount)
            }, 0);

            setTotal((total / 100).toFixed(2));

            localStorage.setItem("stripe_subscription_id", JSON.stringify(res.data.subscriptionId));
            localStorage.setItem("client_stripe_secret", JSON.stringify(res.data.latest_invoice.payment_intent.client_secret));
            localStorage.setItem("is_from_subscription", JSON.stringify(true));
            localStorage.setItem("plan_name", JSON.stringify(planName));
            localStorage.setItem("plan_alone", JSON.stringify(planAlone));
            localStorage.setItem("plan_details", JSON.stringify(planDetails));
            localStorage.setItem("no_of_staff_branch", JSON.stringify(`${noOfStaffs}_${numOfBranches}`));
            localStorage.setItem("duration", JSON.stringify(duration));
            localStorage.setItem("timeKey", JSON.stringify(timeKey));

            setTimeout(() => {
                setLoading(false);
                setShowPlanDetails(true);        
            }, 1000);
        })
        .catch((err)=>{
            setLoading(false);
            console.log(err.response.data.error);
            Modal.error({
                title: `unable to create subscription, ${err.response.data.error}`
            })
        })
    }

    const getPlanById = async () => {
        await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_stripe_product_ids`, 
        { 
            plan: planAlone.toUpperCase(),
            interval: "month",
            duration: 1
        }, 
        {
            headers: headers
        })
        .then((res)=>{
            if(res.status === 200 && res.data.product_id.data.length > 0){
                const oneMonthPlan = res.data.product_id.data[0];
                // if(user && user.currency === "GBP"){
                //     setPlanDetails((prev)=>{
                //         return{
                //             ...prev,
                //             price: (parseFloat(oneMonthPlan.unit_amount) / 100).toFixed(2)
                //         }
                //     })
                // }
            }
        })
        .catch((err)=>{
            console.log(err.message);
        })
    }

    const resolveSubscription = () => {
        const duratio = JSON.parse(localStorage.getItem("duration"));
        const timeKey = JSON.parse(localStorage.getItem("timeKey"));
        const currentDate = new Date(Date.now());
        const numberOfMonthsFromNow = timeKey === "1D" ? Number(currentDate.getDay()) + parseFloat(duratio) : Number(currentDate.getMonth()) + parseFloat(duratio);
        const expiresOn = timeKey === "1D" ? new Date(currentDate.getDay() + 1) : new Date(currentDate.setMonth(numberOfMonthsFromNow));
        const NOC = expiresOn.toLocaleDateString();
        const noOfDays = timeKey === "1D" ? 1 : (expiresOn.getTime() - new Date().getTime()) / milisecondsToDaysConst; 
        const noOfDaysFreePlan = (expiresOn.getTime() - new Date(user.subscription_expires_on).getTime()) / milisecondsToDaysConst; 
        const trans_ref = "stripe_subscription_" + Math.round(Math.random() * 100000000);
        const planAlone = JSON.parse(localStorage.getItem("plan_alone"));
        const planDet = JSON.parse(localStorage.getItem("plan_details"));
        const noOfStaffAndBranch = JSON.parse(localStorage.getItem("no_of_staff_branch"));

        addMerchantCurrentPlan(user.email, {
            plan: planAlone,
            currency_code: user.currency,
            no_of_days: user.subscription_plan === "Free" ? noOfDaysFreePlan : noOfDays,
            expires_on: NOC,
            transaction_reference: `${trans_ref}_no_of_staff:${noOfStaffAndBranch.split("_")[0]}_no_of_branch:${noOfStaffAndBranch.split("_")[1]}_payee_email_address:${user?.email}`,
          }).then((res) => {
            localStorage.removeItem("client_stripe_secret");
            localStorage.removeItem("is_from_subscription");
            localStorage.removeItem("stripe_subscription_id");
            localStorage.removeItem("no_of_staff_branch");
            // localStorage.removeItem("plan_details");
            localStorage.removeItem("plan_name");
            // localStorage.removeItem("duration");
            // localStorage.removeItem("timeKey");
            // localStorage.removeItem("plan_alone");
            toggleSubscribeModal();
      
            Modal.success({
              title: `${strings.yourAccountSubscribed} ${planName} ${strings.successfully}.`,
              content: "You will be logged out. Please log back in continue enjoying the service",
              onOk: () => {
                signoutUser(client);
              }
            });
        });
    }

    const resolveUnsuccessfulPayment = () => {
        silentCancelSubscription();
        Modal.error({
            title: "Unable to complete subscription, please try again",
            onOk: () => {
                toggleSubscribeModal();
                setTimeout(() => {
                    getSubscription(user.id)
                }, 3000);
            }
        })
        
    }

    const handleRadioChange = (e) => {
        const { value } = e.target;
        setAction(value);
        setShowPlanDetails(false);
        setShowDuration(false);
    }

    const handlePlanSelect = (e) => {
        const { value } = e.target;
        const { basic, pro, basic_plus } = subscriptionPlanPrice;

        if(value === "basic"){
            setPlanName("Basic Plan");
            setPlanAlone("Basic");
            setPlan(basic);
        }else if (value === "pro"){
            setPlanName("Pro Plan");
            setPlanAlone("Pro");
            setPlan(pro);
        }else{
            setPlanName("Basic Plus Plan");
            setPlanAlone("Basic Plus");
            setPlan(basic_plus);
        }
        setShowDuration(true);
        setSelectedPlan(value);
    }

    const handleDurationSelect = (value) => {
        // if(value === "1D" && planAlone !== "Basic") return Modal.error({
        //     title: "1 Day plan is only available to basic plan"
        // })
        const selectedTime = durationInterval.find(time => time.key === value);
        setTimeKey(value);
        setSelectedDuration(selectedTime.value);
        let durate;
        setDuration(()=>{
            if(value === "1Y"){
                durate = 12;
                return 12
            }else{
                durate = selectedTime.value.split(" ")[0];
                return selectedTime.value.split(" ")[0];
            }
        });
        setPlanInterval({
            intervalPeriod: selectedTime.value.split(" ")[1],
            intervalTime: selectedTime.value.split(" ")[0]
        });

        if(subscriptionID !== null) silentCancelSubscription();
    }

    useEffect(()=>{
        if(!planName) return;
        if(planInterval.intervalPeriod === "") return;
        getPlanById();
        handleSubscription();
    }, [planInterval, planAlone]);

   

    const radioOptions =  [
        { label: "Subscribe", value: "subscribe", disabled: subscriptionStatus === "active" ? true : subscriptionStatus === "incomplete" ? true : false},
        // { label: "Update Subscription", value: "change" },
        { label: "Cancel Subscription", value: "cancel", disabled: subscriptionStatus === "not_active" ? true : false},
    ]


    const checkBoxOptions =  [
        { label: "Basic Plan", value: "basic" },
        { label: "Basic Plus Plan", value: "basic_plus" },
        { label: "Pro Plan", value: "pro"},
    ] 

    const stripeOption = {
        clientSecret
    }

    const setOkText = 
    action === "subscribe" 
    ? "Subscribe" 
        : action === "cancel" 
            ? "Unsubscribe" 
                : action === "change" 
                    ? "Confirm Change" 
                        : "Cancel";

    const buttonAction = () =>{
        action === "subscribe" 
        ? setCheckoutFormOpen(true) 
            : action === "cancel" 
                ? cancelSubscription() 
                    : action === "change" 
                        ? toggleSubscribeModal()
                            : toggleSubscribeModal();
    }

  return (
    <>
    <Modal
        title="Plan Subscription"
        open={isSubscribeModalOpen}
        onCancel={()=>toggleSubscribeModal()}
        footer={[
            <>
                {subscriptionStatus === "incomplete" && 
                    <Button
                        type='primary'
                        onClick={()=>setCheckoutFormOpen(true)}
                        // disabled={action === "subscribe" ? loadingAllBranches : false}
                        loading={loading}
                    >
                        {strings.pay}
                    </Button>
                }
                <Button
                    type='primary'
                    onClick={buttonAction}
                    disabled={action === "subscribe" ? loadingAllBranches : false}
                    loading={loading}
                >
                    {setOkText}
                </Button>
            </>
        ]}
    >
        <div 
            style={{
                display: "flex", 
                alignItems: "center", 
                gap: "40px",
                marginBottom: "10px"
            }}
        >
            <h3 style={{margin: 0}}>Subscription Status:</h3> 
            <Tag 
                color={
                    subscriptionStatus === "active" ? "green" : subscriptionStatus === "incomplete" ? "orange" : "red"} 
                style={{scale: "1.5"}}>
                    {subscriptionStatus === "active" ? "ACTIVE" : subscriptionStatus === "incomplete" ? "AWAITING PAYMENT" : "NOT ACTIVE"}
            </Tag> 
        </div>
        {subscriptionStatus === "incomplete" && <p style={{margin: "5px 0"}}>{strings.subscriptionNotYetActive}</p>}
        <p>
            {strings.youAreCurrentlyEnrolledOnA}{" "}
            <strong>{user.subscription_plan}</strong> {strings.plan}.
        </p>

        <p style={{marginBottom: "3px"}}>{strings.pleaseChooseAnAction}</p>
        <RadioGroup 
            options={radioOptions}
            onChange={handleRadioChange}
            value={action}
            style={{
                display: "flex",
                flexDirection: "column"
            }}
        />
        {action === "subscribe" &&
            <div>
                <p style={{margin: "10px 0 0"}}>{strings.pleaseSelectPlanToSubscribeTo}</p>
                <RadioGroup 
                    options={checkBoxOptions}
                    value={selectedPlan}
                    onChange={handlePlanSelect}
                />
            </div>
        }
        {showPlanDetails && loadingAllBranches && <div style={{marginTop: "8px"}}><Spin /></div>}
        {showDuration &&
            <div>
                <p style={{margin: "10px 0 0"}}><b>Select Subscription Frequency</b></p>
                <Select 
                    onChange={handleDurationSelect}
                    style={{width: "60%"}}
                    size='large'
                    placeholder={strings.selectADuration}
                >
                    {durationInterval && durationInterval.map((interval, index)=> {
                        return <Option 
                            key={interval.key} 
                            value={interval.key}
                        >{interval.value}</Option>
                    })}
                </Select>
            </div>
        }
        { showPlanDetails  && !loadingAllBranches
            && 
            <>
            <hr style={{width: "100%"}} />
            <p style={{margin: 0}}><b>Payment Details</b></p>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <p style={{margin: 0}}><b>Base Price</b></p>
                {`${user.currency}${planDetails.price}`}
            </div>
            </>
        }
        {showPlanDetails && !loadingAllBranches &&
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px"
            }}>
                <div>
                    {staffs.length >= 1 && (
                        <p style={{
                            display:"flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: 0
                        }}>
                            <span>
                                {strings.staff} ({staffs.length}) @ {user.currency}
                                {Number(totalStaffPrice.unit).toFixed(2)}
                                {/* {staffs.length > 3 &&
                                ` || ${staff_discount_percentage * 100}% ${strings.discountApplies}`} */}
                            </span>

                            <span>
                                {user.currency}
                                {totalStaffPrice.total}{" "}
                            </span>
                        </p>
                    )}
                </div>
                <div>
                {numOfBranches >= 4 ? (
                    <p style={{
                        display:"flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: 0
                    }}>
                        <span>
                            {strings.branch} ({numOfBranches - 1}) @ {user.currency}
                            {Number(totalBranchPrice.unit).toFixed(2)}{" "}
                            {/* {`|| ${branch_discount_percentage * 100}% ${strings.discountApplies}`} */}
                        </span>

                        <span>
                            {user.currency}
                            {totalBranchPrice.total}
                        </span>
                    </p>
                ) : numOfBranches < 4 && numOfBranches > 1 ? (
                    <p style={{
                        display:"flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: 0
                    }}>
                        <span>
                            {strings.branch} ({numOfBranches - 1}) @ {user.currency}
                            {Number(totalBranchPrice.unit).toFixed(2)}
                        </span>

                        <span>
                            {user.currency}
                            {totalBranchPrice.total}
                        </span>
                    </p>
                ) : null}
                </div>
                <div>
                {numOfEcommerceBranch > 0 ? (
                    <p style={{
                        display:"flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: 0
                    }}>
                        <span>
                            {strings.ecommerceIntegration} ({numOfEcommerceBranch}) @ {user.currency}{" "}
                            {Number(totalECommercePrice.unit).toFixed(2)}
                        </span>

                        <span>
                            {user.currency}
                            {totalECommercePrice.total}
                        </span>
                    </p>
                ) : null}
                </div>
            </div>
        }
        {showPlanDetails && activePaidApps  && !loadingAllBranches
          && activePaidApps.length > 0 
            && <AppsPrice 
            activePaidApps={activeAppState}
            user={user}
            totalAppsPrice={activeAppStateTotal}
            fromStripeSubscription={true}
        />}
        {showPlanDetails  && !loadingAllBranches && 
            <div style={{
                display:"flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: 0,
                marginTop: "10px"
            }}>
                <p style={{margin: 0}}><b>Total {selectedDuration && `(x ${selectedDuration})`}: </b></p>
                <p style={{margin: 0}}><b>{user.currency}{totalPrice}</b></p>
            </div>
        }
        <img
            style={{ margin: "30px 0 -20px 0" }}
            src={paymentLogo}
            alt="payment options"
        />
    </Modal>

    {clientSecret && checkoutFormOpen &&
        <Elements options={stripeOption} stripe={stripePromise}>
            <CheckoutForm 
                open={checkoutFormOpen}
                onCancel={()=>setCheckoutFormOpen(false)}
                // handlePayment={handlePayment}
                // updateBalanceLoading={updateBalanceLoading}
                fromStripeSubscription={fromSubscription}
                resolveSubscription={resolveSubscription}
                resolveUnsuccessfulPayment={resolveUnsuccessfulPayment}
            />
        </Elements>
    }
    </>
  )
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    allBranches: state.branch.allBranches,
    loadingAllBranches: state.branch.loadingAllBranches,
    isSubscribeModalOpen: state.renewal.isSubscribeModalOpen,
    subscriptionStatus: state.renewal.subscriptionStatus,
});
  
export default connect(mapStateToProps, {
    getPricingPlanData,
    addMerchantCurrentPlan,
    getAllBusinessBranches,
    signoutUser,
    fetchActiveApps,
    toggleSubscribeModal
})(SubscriptionModal);