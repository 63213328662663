import React, { useEffect, useState } from 'react';
import { 
    Modal, 
    Form, 
    Button, 
    Input, 
    InputNumber, 
    Switch, 
    Upload,
    message ,
    Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import strings from '../../../../../strings';
import { connect } from 'react-redux';
import { createReferralProgram } from '../../../../../redux/actions/referralProgramAction';


const FormItem = Form.Item;
const Textarea = Input.TextArea;
const Option = Select.Option;


const CreateReferral = ({ 
    open, 
    onCancel, 
    handleGetAllReferralProgram,
    allLoyaltyPrograms,
    user
 }) => {
    const [form] = Form.useForm();
    const [ random, setRandom ] = useState(false);
    const [ active, setActive ] = useState(false);
    const [ image, setImage ] = useState(null);
    const [ uploadingImage, setUploadingImage ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ reward_type, setReward_type ] = useState("");
    const [ reward ] = useState("ref_gift_voucher");
    
    const handleProductPicture = async (files, fileLists) => {
        const maxFileSize = 4000000;
        
        const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
        const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
    
        if (files.size > maxFileSize) return message.warning(strings.fileTooLargeLessThan4Mb);
          
        setUploadingImage(true);
        const formData = new FormData();
        formData.append("file", files)
        formData.append("upload_preset", uploadPreset)
    
        const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        {
          method: "POST",
          body: formData
        })
        
        const parsed = await response.json();
    
        setImage(parsed.secure_url);
                
        setUploadingImage(false);
        return false;
    };
    
    const handleRemoveProductPicture = file => {
        setImage(null);
        setUploadingImage(false);
    };

    const handleCreateReferral = (value) => {
        const { getFieldsValue } = form;
        setLoading(true);
        const fieldValue = getFieldsValue()
        delete fieldValue.image
        
        const payload = {
            ...getFieldsValue(),
            image,
            random,
            active
        }
        
        createReferralProgram(payload, user)
        .then((res) => {
            Modal.success({
                title: "Referral program created",
                onOk: () => {
                    handleGetAllReferralProgram();
                    onCancel();
                }
            })
        })
        .catch((err) => {
            Modal.error({
                title: `${err.response.data.message || "Unable to creeate referral program"}, try again`
            })
        })
    }

    const rewardsTypes = [
        "giftcard",
        "airtime",
        "stamps/points"
    ]

  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={strings.createReferralProgram}
        okText="Create program"
        confirmLoading={loading}
        onOk={handleCreateReferral}
    >
        <Form
            onFinish={handleCreateReferral}
            layout="vertical"
            form={form}
        >
            <FormItem
                name="name"
                rules={[
                    {required: true, message: "please enter program's name"}
                ]}
                label="Program name"
            >
                <Input 
                    size="large"
                />
            </FormItem>

            <FormItem
                name="reward_type"
                rules={[
                    {required: true, message: "please enter reward type"}
                ]}
                label="Reward type"
            >
                <Select 
                    size="large" 
                    placeholder="select reward type"
                    onChange={(value) => setReward_type(value)}
                    // value={reward_type}
                >
                    {rewardsTypes && rewardsTypes.map((type) => {
                        if(user.currency !== "NGN" && type==="airtime") return;
                        return <Option key={type} value={type}>{type}</Option>
                    })}
                </Select>
            </FormItem>

            {/* {reward_type === "stamps/points" && <FormItem
                name="reward"
                rules={[
                    {required: true, message: "please enter reward"}
                ]}
                label="Select loyalty program"
            >
                <Select size="large">
                    {allLoyaltyPrograms &&
                    allLoyaltyPrograms.map(loyaltyProgram => (
                        <Option
                        value={loyaltyProgram.name}
                        key={loyaltyProgram.id}
                        >
                        {loyaltyProgram.name}
                        </Option>
                    ))}
                </Select>
            </FormItem>}
            {reward_type === "giftcard" && <FormItem
                name="reward"
                rules={[
                    {required: true, message: "please enter reward"}
                ]}
                label="Enter giftcard amount"
            >
                <InputNumber 
                    size="large"
                    style={{width: "100%"}}
                />
            </FormItem>} */}

            {/* {reward_type === "airtime" &&  */}
            <FormItem
                name="reward"
                rules={[
                    {required: true, message: "please enter reward"}
                ]}
                label="Reward"
                initialValue={reward}
            >
               <Input
                    size="large"
                    style={{width: "100%"}}
                    value={reward}
                    disabled={true}
                />
            </FormItem>
            {/* } */}

            <FormItem
                name="no_of_winners"
                rules={[
                    {required: true, message: "please enter numbers of participant"}
                ]}
                label="How many participant do you want to reward"
            >
                <InputNumber 
                    size="large"
                    style={{width: "100%"}}
                />
            </FormItem>

            <FormItem
                name="custom_template_name"
                rules={[
                    {required: false, message: "please enter custom template name"}
                ]}
                label="Custom template name"
            >
                <Input 
                    size="large"
                />
            </FormItem>


            <FormItem
                name="random"
                label="Select winner randomly"
            >
                <Switch 
                    checked={random}
                    onChange={(value) => setRandom(value)}
                />
            </FormItem>

            <FormItem
                name="active"
                label="Active"
            >
                <Switch 
                    checked={active}
                    onChange={(value) => setActive(value)}
                />
            </FormItem>

            <FormItem
                name="image"
                label="Cover Image"
            >
                <Upload
                    onRemove={handleRemoveProductPicture}
                    beforeUpload={handleProductPicture}
                >
                    <Button loading={uploadingImage} htmlType="button">
                        <UploadOutlined /> Upload Image
                    </Button>
                </Upload>
            </FormItem>
            <span style={{color: "#ee8900", fontSize: "12px", marginTop: "-27px", display: "block"}}>This is the image the user will receive when the opt into the program</span>

            <FormItem
                name="share_message"
                label="Share message"
                rules={[
                    {required: true, message: "please enter share message"}
                ]}
            >
                <Textarea 
                    rows={3}
                />
            </FormItem>
            <span style={{color: "#ee8900", fontSize: "12px", marginTop: "-27px", display: "block"}}>This is the message a customer will see when they refer new cusotmer</span>


            <FormItem
                name="optin_message"
                label="Opt in message"
                rules={[
                    {required: true, message: "please enter Opt in message"}
                ]}
            >
                <Textarea 
                    rows={3}
                />
            </FormItem>
            <span></span>
        </Form>
    </Modal>
  )
}

const mapStateToProps = state => ({
    user: state.auth.user,
    allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
});

export default connect(mapStateToProps, {
})(CreateReferral);
  