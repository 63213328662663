import { Table, Card, Button, Dropdown } from "antd";
import { PlusOutlined, CalendarOutlined } from "@ant-design/icons";
import { useQuery, useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllCustomers } from "../../../../../../redux/actions/customerActions";
import { getAllBusinessBranches } from "../../../../../../redux/actions/branchActions";
import {
  GET_BOOKINGS,
  GET_SERVICES,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";
import { SUBSCRIBE_LOYSTAR_BOOKINGS } from "../../../../../../constants";
import BookingCreateForm from "./CreateBooking";
import BookingDetails from "./BookingDetails";
import CalendarView from "../Calendar";
import moment from "moment";

const Bookings = ({
  customers,
  getAllCustomers,
  getAllBusinessBranches,
  branches,
  user
}) => {
  const [createFormVisible, setCreateFormVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [bookingDetailsVisible, setBookingDetailsVisible] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);

  const { data, loading, refetch } = useQuery(GET_BOOKINGS, {
    variables: {branch_id: user.business_branch ? user.business_branch.id : null}
  });
  const { data: servicesData } = useQuery(GET_SERVICES);

  useEffect(() => {
    getAllBusinessBranches();
    getAllCustomers();
    data && data.getBookings && setTableData(data.getBookings);
  }, [data, getAllCustomers, getAllBusinessBranches]);

  const { data: inData } = useSubscription(SUBSCRIBE_LOYSTAR_BOOKINGS, {
    onData: (data)=>{
      // console.log("DAT", data);
        refetch(); 
      },
      variables: { merchant_id: user?.employer ? user?.employer?.id : user?.id }
  });

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customer_id",
      render: data => {
        let customer;
        customer = customers.find(cstmr => cstmr.id === data || cstmr.user_id === data);
        return customer
          ? `${customer && customer.first_name ? customer.first_name : "N/A"} ${customer &&
              customer.last_name ? customer.last_name : ""
            } ${customer && customer.phone_number ? ":" + customer.phone_number : ""}`
          : "N/A";
      },
      ellipsis: true,
    },
    {
      title: "Booking Date",
      dataIndex: "booking_date",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.booking_date, "DD-MM-YYYY h:mma") -
        moment(b.booking_date, "DD-MM-YYYY h:mma"),
    },
    {
      title: "Services",
      dataIndex: "service_name",
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
    },
    {
      title: "Staff",
      dataIndex: "staff_email",
      ellipsis: true,
      render: value => (value ? value : "Any"),
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        {
          text: "Assigned",
          value: "assigned",
        },
        {
          text: "Not Assigned",
          value: "not assigned",
        },
        {
          text: "Completed",
          value: "completed",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
  ];

  const branchObj = {};
  branches && branches.forEach(branch => (branchObj[branch.id] = branch));

  return (
    <main>
      <Card
        loading={loading}
        title="Bookings"
        extra={[
          <Button
            icon={<CalendarOutlined />}
            style={{ marginRight: "15rem" }}
            type="ghost"
            onClick={() => {
              setCalendarVisible(!calendarVisible);
            }}
          >
            {calendarVisible ? "Table View" : "Calendar View"}
          </Button>,
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              setCreateFormVisible(true);
            }}
          >
            Add New Booking 
          </Button>,
        ]}
      >
        {currentBooking && (
          <BookingDetails
            onCancel={() => {
              setBookingDetailsVisible(false);
              setCurrentBooking(null);
            }}
            visible={bookingDetailsVisible}
            currentBooking={currentBooking}
            customers={customers}
            services={servicesData?.getServices}
            branches={branches}
            branchObj={branchObj}
            user={user}
          />
        )}
        <BookingCreateForm
          visible={createFormVisible}
          onCancel={() => {
            setCreateFormVisible(false);
          }}
          customers={customers}
          services={servicesData?.getServices}
          branches={branches}
          branchObj={branchObj}
        />
        {calendarVisible && (
          <CalendarView
            allBookings={data?.getBookings}
            customers={customers}
            branchObj={branchObj}
            branches={branches}
            services={servicesData?.getServices}
          />
        )}
        {!calendarVisible && (
          <Table
            columns={columns}
            dataSource={tableData}
            onRow={record => ({
              onClick: () => {
                setBookingDetailsVisible(true);
                setCurrentBooking(record);
              },
            })}
          />
        )}
        {}
      </Card>
    </main>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  customers: state.customer.allCustomers,
  branches: state.branch.allBranches,
  error: state.error,
});

export default connect(mapStateToProps, {
  getAllCustomers,
  getAllBusinessBranches,
})(Bookings);
