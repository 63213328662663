import React from "react";
import { Modal, Input } from "antd";
import { Form } from "@ant-design/compatible";
import strings from "../../../../../../strings";

class DeleteDraftSales extends React.Component {
  state = {
    loading: false,
  };

  handleDeleteDraftSale = () => {
    const {
      form,
      currentBill: { id, total },
      closeModal,
      toggleViewDraftModal,
      deleteDraftSales,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      if (values.delete !== total) {
        Modal.error({
          title: `${strings.pleaseTypeIn} ${total} ${strings.toDeleteThisSale}`,
        });
      } else {
        this.setState({ loading: true });
        deleteDraftSales(id)
          .then(res => {
            this.setState({ loading: false });
            Modal.success({
              title: strings.draftSaleDeletedSuccessfully,
            });

            closeModal();
            toggleViewDraftModal();
          })
          .catch(err => {
            // console.log(err);
            // message.error("An Error Occured.", 5);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      isDeleteDraftModalOpen,
      currentBill: { total },

      closeModal,
    } = this.props;

    return (
      <Modal
        title={strings.deleteSale}
        okText={strings.delete}
        cancelText={strings.cancel}
        visible={isDeleteDraftModalOpen}
        destroyOnClose
        onCancel={closeModal}
        onOk={this.handleDeleteDraftSale}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical" >
          <Form.Item
            label={`${strings.pleaseTypeIn} ${total} ${strings.toConfirm}`}
          >
            {getFieldDecorator("delete", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseTypeInYourTotal,
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const DeleteDraftSalesForm = Form.create()(DeleteDraftSales);

export default DeleteDraftSalesForm;
