import React, { useState } from 'react';
import { Modal, Button, Popover } from "antd";
import strings from '../../../../../strings';
import { Icon } from "@ant-design/compatible";
import EditSupply from './EditSupply';
import { deleteSuppliers } from '../../../../../redux/actions/productActions';
import { connect } from 'react-redux';

const SupplyDetailsModal = ({
    open,
    onCancel,
    supplyDetails,
    fetchProductSupplies,
    deleteSuppliers
}) => {
    // console.log("g", supplyDetails);
    const [ openEditSupplyModal, setOpenEditSupplyModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const { 
        supplier, 
        quantity, 
        received_by, 
        unit_price, 
        product,
        id
    } = supplyDetails;

    const deleteSupplier = () => {
        Modal.info({
            title: strings.areYouSureDeleteSupply,
            okCancel: true,
            okButtonProps: {loading: loading},
            onOk: () => {
                setLoading(true);
                deleteSuppliers(id)
                .then(res => {
                    if(res.status === 200 || res.status === 201){
                        Modal.success({
                            title: strings.supplyDeleteSuccess,
                            onOk: () => {
                                fetchProductSupplies();
                                setLoading(false);
                            }
                        })
                    }
                })
                .catch(err => {
                    Modal.error({
                        title: err?.response?.data?.message || "Unable to delete this suppy detail, please try again",
                        onOk: () => {
                            setLoading(false);
                        }
                    })
                })
            }
        })
    }

    const toggleEditSupplyModal = () => {
        setOpenEditSupplyModal(!openEditSupplyModal)
    }

    const toggleDeleteSupplyModal = () => {

    }

    const actionsContent = (
        <div style={{ display: "flex", flexDirection: "column", gap:2 }}>
          <Button onClick={toggleEditSupplyModal}>
            <Icon type="edit" /> {strings.edit}
          </Button>
          <Button onClick={deleteSupplier}>
            <Icon type="delete" />
            {strings.delete}
          </Button>
        </div>
    );
  return (
    <>
        <Modal
        open={open}
        onCancel={onCancel}
        title={strings.supplyDetails}
        footer={null}
            // [
        //     <Button key="back" onClick={onCancel}>
        //     {strings.close}
        //   </Button>,
        //   <Popover title={strings.actions} content={actionsContent}>
        //     <Button type="primary">{strings.actions}</Button>
        //   </Popover>,
        // ]
    
    >
        <p>
          <strong>{strings.supplier}</strong>: {supplier}
        </p>

        <p>
          <strong>{strings.receivedBy}</strong>: {received_by}
        </p>

        <p>
          <strong>{strings.quantity}</strong>: {quantity}
        </p>

        <p>
          <strong>{strings.unitPrice}</strong>: {unit_price}
        </p>

        <p>
          <strong>{strings.product}</strong>: {product.name}
        </p>
    </Modal>


    {openEditSupplyModal && (
        <EditSupply 
            open={openEditSupplyModal}
            onCancel={() => setOpenEditSupplyModal(false)}
            supplyDetails={supplyDetails}
        />
    )}
    </>
    
  )
}


const mapStateToProps = state => ({
    user: state.auth.user,
    supplies: state.product.supplies,
  });
  
  export default connect(mapStateToProps, {
    deleteSuppliers,
  })(SupplyDetailsModal);