import moment from "moment";
import strings from "../../../../strings";
export const suppliesColumn = () => [
  {
    title: strings.supplier,
    dataIndex: "supplier",
    render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
    // ...this.getColumnSearchProps("customerName"),
  },
  // {
  //   title: "Business Name",
  //   dataIndex: "product.name",
  //   key: "product.name",
  //   render: (record, value) => {
  //     return <span>{record}</span>;
  //   },
  // },
  {
    title: strings.productName,
    dataIndex: "product",
    key: "product_name",
    render: (record) => {
      return <span>{record.name}</span>;
    },
  },
  {
    title: strings.quantity,
    dataIndex: "quantity",
    render: (record) => <span>{record}</span>,
  },
  {
    title: strings.unitPrice,
    dataIndex: "unit_price",
    render: (record) => <span>{record}</span>,
  },
  {
    title: strings.createdDate,
    dataIndex: "date",
    defaultSortOrder: "ascend",
    sorter: (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf(),
    key: Math.random(),
    render: (record) =>
      record ? (
        <span>{moment(record).format("YYYY-MM-DD")}</span>
      ) : (
        <span>N/A</span>
      ),
  },
  {
    title: strings.createdTime,
    dataIndex: "date",
    key: Math.random(),
    defaultSortOrder: "ascend",
    sorter: (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf(),
    render: (record) =>
      record ? (
        <span>{moment(record).format("hh:mm:ss A")}</span>
      ) : (
        <span>N/A</span>
      ),
  },
  {
    title: strings.receivedBy,
    dataIndex: "received_by",
    render: (record) => <span>{record}</span>,
  },
];
