import React from "react";
import ProductListItem from "../ProductListItem";
import PropTypes from "prop-types";
import strings from "../../../../strings";
import { Spin } from "antd";
import { ScanOutlined } from "@ant-design/icons"

const ProductList = ({
  data,
  addProductToCart,
  toggleCustomPriceModal,
  user,
  location,
  cartList,
  isNitroActive,
  isNitroSearchEmpty,
  isGettingFromServer,
  handleBlurFactor,
  progressLoading
}) => (
  <>
    {data && data.length ? (
      data.map((product) => {
        if (!product.deleted) {
          return (
            <ProductListItem
              key={product.id}
              item={product}
              onClick={addProductToCart}
              currency={user.currency}
              toggleCustomPriceModal={toggleCustomPriceModal}
              location={location}
              cartList={cartList}
              user={user}
              handleBlurFactor={handleBlurFactor}
            />
          );
        }
      })
    ) : (
      
      <>
      {/* <p style={{ margin: 20 }}>{
        isNitroActive && isGettingFromServer ? <Spin /> ?  isNitroActive && !isGettingFromServer : ( isNitroSearchEmpty ? strings.nitroEmpty :  strings.scanProductItem )
        : strings.noProductItem}</p> */}
        {isNitroActive && (
          isGettingFromServer ? 
          <Spin /> : 
          (isNitroSearchEmpty 
            ? (<p 
            style={{
              margin: 20, 
              textAlign: "center", 
              fontSize: 32, 
              fontWeight: "bolder", 
              width: "100%"
            }}>
          {strings.nitroEmptyProducts}</p>) 
          :<p 
            style={{
              margin: 20, 
              textAlign: "center", 
              fontSize: 32, 
              fontWeight: "bolder", 
              width: "100%"
            }}>
          <ScanOutlined /> {strings.nitroEmpty}</p>)
        )}
        <p style={{margin: 20}}>{!isNitroActive && strings.scanProductItem}</p>
      </>
    )}
  </>
);

ProductList.propTypes = {
  data: PropTypes.array.isRequired,
  addProductToCart: PropTypes.func.isRequired,
};

export default ProductList;
