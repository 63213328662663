// Add Merchants Email in this format ["demo@loystar.co", "hello@loystar.co"]

export const disabledInvoiceButtonEmails = [
  "abisoyeadebayo.gasland@gmail.com",
  "supervisor-ijebu-ode@gaslandnigeria.com",
  "supervisor-oluloye@gaslandnigeria.com",
  "james@gaslandnigeria.com",
  "supervisor-iyana-church@gaslandnigeria.com",
  "supervisor-ondo@gaslandnigeria.com",
  "supervisor-oyo2@gaslandnigeria.com",
  "ring-road-supervisor@gaslandnigeria.com",
  "supervisor-akanran@gaslandnigeria.com",
  "supervisor-hub@gaslandnigeria.com",
  "supervisor-igando@gaslandnigeria.com",
  "supervisor-molete@gaslandnigeria.com",
  "supervisor-oyo@gaslandnigeria.com",
  "supervisor-ashi@gaslandnigeria.com",
  "supervisor-nihort@gaslandnigeria.com",
  "supervisor-felele@gaslandnigeria.com",
  "supervisor-ife@gaslandnigeria.com",
  "supervisor-oke-aro@gaslandnigeria.com",
  "oluwaseyi118@yahoo.com",
];

export const disabledCardButtonEmails = [
  "abisoyeadebayo.gasland@gmail.com",
  "supervisor-ijebu-ode@gaslandnigeria.com",
  "supervisor-oluloye@gaslandnigeria.com",
  "james@gaslandnigeria.com",
  "supervisor-iyana-church@gaslandnigeria.com",
  "supervisor-ondo@gaslandnigeria.com",
  "supervisor-oyo2@gaslandnigeria.com",
  "ring-road-supervisor@gaslandnigeria.com",
  "supervisor-akanran@gaslandnigeria.com",
  "supervisor-hub@gaslandnigeria.com",
  "supervisor-igando@gaslandnigeria.com",
  "supervisor-molete@gaslandnigeria.com",
  "supervisor-oyo@gaslandnigeria.com",
  "supervisor-ashi@gaslandnigeria.com",
  "supervisor-nihort@gaslandnigeria.com",
  "supervisor-felele@gaslandnigeria.com",
  "supervisor-ife@gaslandnigeria.com",
  "supervisor-oke-aro@gaslandnigeria.com",
  "dayo@ulesson.com",
  "adebowale@ulesson.com",
  "chimda@ulesson.com",
  "paul@ulesson.com",
  "michealajiboye@ulesson.com",
  "franklin@ulesson.com",
  "daniel@ulesson.com",
  "godwin@ulesson.com",
  "oluwaseyi118@yahoo.com",
];

export const disabledInvoiceFieldEmails = [];

export const disabledCashButtonEmails = [
  "dayo@ulesson.com",
  "adebowale@ulesson.com",
  "chimda@ulesson.com",
  "paul@ulesson.com",
  "michealajiboye@ulesson.com",
  "franklin@ulesson.com",
  "daniel@ulesson.com",
  "godwin@ulesson.com",
  "oluwaseyi118@yahoo.com",
];

export const disabledBankTransferButtonEmails = [
  "dayo@ulesson.com",
  "adebowale@ulesson.com",
  "chimda@ulesson.com",
  "paul@ulesson.com",
  "michealajiboye@ulesson.com",
  "franklin@ulesson.com",
  "daniel@ulesson.com",
  "godwin@ulesson.com",
  "oluwaseyi118@yahoo.com",
];

export const disabledGiftCardButtonEmails = [
  "dayo@ulesson.com",
  "adebowale@ulesson.com",
  "chimda@ulesson.com",
  "paul@ulesson.com",
  "michealajiboye@ulesson.com",
  "franklin@ulesson.com",
  "daniel@ulesson.com",
  "godwin@ulesson.com",
  "oluwaseyi118@yahoo.com",
];

export const disabledPointPaymentEmails = [
  "dayo@ulesson.com",
  "adebowale@ulesson.com",
  "chimda@ulesson.com",
  "paul@ulesson.com",
  "michealajiboye@ulesson.com",
  "franklin@ulesson.com",
  "daniel@ulesson.com",
  "godwin@ulesson.com",
  "oluwaseyi118@yahoo.com",
];
