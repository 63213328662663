import React from "react";
import { Modal, Input } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";
import { deleteProduct } from "../../../../../../redux/actions/productActions";

import { connect } from "react-redux";
import strings from "../../../../../../strings";

class BulkDeleteProductModal extends React.Component {
  state = {
    loading: false,
  };

  handleDeleteProduct = async () => {
    const {
      form,
      deleteProduct,
      updateProducts,
      productIds,
      closeModal,
      user,
      branchId,
    } = this.props;

    try {
      const values = await form.validateFields();

      if (values.delete.toLowerCase() !== "delete") {
        Modal.error({
          title:
            strings.pleaseTypeIn + " delete " + strings.toDeleteThisProduct,
        });
      } else {
        this.setState({ loading: true });

        const productList = productIds.map(prodId => {
          return deleteProduct(prodId);
        });

        Promise.all(productList)
          .then(res => {
            this.setState({ loading: false });

            Modal.success({
              title: strings.productDeletedSuccessfully,
            });

            closeModal();

            !isNaN(branchId)
              ? updateProducts(user.id, branchId)
              : updateProducts();
          })
          .catch(err => {
            this.setState({ loading: false });

            Modal.error({
              title: strings.anErrorOccuredProductsDeleted,
            });

            closeModal();

            !isNaN(branchId)
              ? updateProducts(user.id, branchId)
              : updateProducts();
          });
      }
    } catch (error) {
      if (error) return;
    }
  };

  render() {
    const {
      form: { getFieldDecorator },
      showModal,
      closeModal,
      productIds,
    } = this.props;

    return (
      <Modal
        title={strings.bulkDelete}
        okText={strings.delete}
        cancelText={strings.cancel}
        visible={showModal}
        destroyOnClose
        onCancel={closeModal}
        onOk={this.handleDeleteProduct}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical" >
          <Form.Item
            label={`${strings.youAreAboutToDelete} ${productIds.length} ${strings.product}(s). ${strings.pleaseTypeIn} "delete" ${strings.toConfirm}`}
          >
            {getFieldDecorator("delete", {
              rules: [
                {
                  required: true,
                  message:
                    strings.pleaseTypeIn + " delete " + strings.toConfirm,
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const BulkDeleteProductForm = Form.create()(BulkDeleteProductModal);

BulkDeleteProductForm.propTypes = {
  productIds: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(null, {
  deleteProduct,
})(BulkDeleteProductForm);
