import React from "react";
import { Modal, Input } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";
import {
  deleteCustomer,
  getAllCustomers,
} from "../../../../../../redux/actions/customerActions";

import { connect } from "react-redux";
import strings from "../../../../../../strings";

class DeleteCustomerModal extends React.Component {
  state = {
    loading: false,
  };

  handleDeleteCustomer = () => {
    const {
      form,
      deleteCustomer,
      getAllCustomers,
      customer: { id },
      closeModal,
      pageSize,
      pageNumber
    } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      if (values.delete.toLowerCase() !== "delete") {
        Modal.error({
          title: strings.pleaseTypeIn + "'delete'" + strings.toDeleteThisCustomer,
        });
      } else {
        this.setState({ loading: true });
        deleteCustomer(id).then(res => {
          this.setState({ loading: false });
          Modal.success({
            title: strings.customerHasBeenDeletedSuccessfully,
          });

          getAllCustomers(pageNumber, pageSize);
          closeModal();
        });
      }
    });
  };

  render() {
    const {
      customer: { first_name },
      form: { getFieldDecorator },
      showModal,
      closeModal,
    } = this.props;

    return (
      <Modal
        title={`${strings.delete} ${first_name}`}
        okText={strings.delete}
        cancelText={strings.cancel}
        open={showModal}
        destroyOnClose
        onCancel={closeModal}
        onOk={this.handleDeleteCustomer}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical" >
          <Form.Item
            label={strings.pleaseTypeIn + " delete " + strings.toConfirm}
          >
            {getFieldDecorator("delete", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseTypeIn + " delete " + strings.toConfirm,
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const DeleteCustomerForm = Form.create()(DeleteCustomerModal);

DeleteCustomerForm.propTypes = {
  customer: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(null, {
  deleteCustomer,
  getAllCustomers,
})(DeleteCustomerForm);
