import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Modal, Button, message } from 'antd';

const PaypalModal = ({ 
    open,
    onCancel,
    onPay, 
    user, 
    total
}) => {
    const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const nairaDollarRate = process.env.REACT_APP_NAIRA_DOLLAR_RATE || 700;
    const paypalAmount =
    user.currency !== "NGN" ? total : (total / nairaDollarRate).toFixed(2);
  return (
    <Modal
        title="Pay With Paypal"
        open={open}
        onCancel={onCancel}
        footer={[
          <Button onClick={onCancel}>Cancel</Button>,
        ]}
      >
        <div style={{ marginTop: 5 }}>
          <PayPalScriptProvider
            options={{
              "client-id": PAYPAL_CLIENT_ID,
              currency: user.currency === "NGN" ? "USD" : user.currency,
            }}
          >
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: paypalAmount,
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then((details) => {
                    onCancel();
                    message.success(`Payment successful`);
                    onPay();
                });
              }}
            />
          </PayPalScriptProvider>
        </div>
      </Modal>
  )
}

export default PaypalModal