import { GET_ALL_INVOICES, GET_PAYMENT_MESSAGE } from "../constants";

const initialState = {
  allInvoices: [],
  paymentMessage: ''
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_INVOICES:
      return {
        ...state,
        allInvoices: payload
      };
    case GET_PAYMENT_MESSAGE:
      return {
        ...state,
        paymentMessage: payload
      };
    default:
      return state;
  }
};
export default reducer;
