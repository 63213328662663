import { GET_ALL_LOYALTY_PROGRAMS } from "../constants";
import api from "../../api";

export const getAllLoyaltyPrograms = (time_stamp = 0) => async dispatch => {
  const res = await api.loyalty.getAllLoyaltyPrograms(time_stamp);

  if (res && res.status === 200) {
    dispatch({
      type: GET_ALL_LOYALTY_PROGRAMS,
      payload: (res && res.data) || [],
    });
  }

  return Promise.resolve(res);
};
