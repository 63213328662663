export default class Loyalty {
  constructor(client) {
    this.client = client;
  }

  addNewLoyaltyProgram(programData) {
    return this.client.post("/merchant_loyalty_programs", {
      data: { ...programData },
    });
  }

  getAllLoyaltyPrograms(time_stamp) {
    return this.client.post("/get_merchant_loyalty_programs", {
      data: { time_stamp },
    });
  }

  editLoyaltyProgram(id, programData) {
    return this.client.patch(`/merchant_loyalty_programs/${id}`, {
      data: { ...programData },
    });
  }

  deleteLoyaltyProgram(id) {
    return this.client.post(
      `/merchant_loyalty_programs/set_delete_flag_to_true/${id}`
    );
  }
}
