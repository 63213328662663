import React, { useEffect } from "react";
import BasePrice from "./_partial/BasePrice";
import BranchPrice from "./_partial/BranchPrice";
import EcommercePrice from "./_partial/EcommercePrice";
import PriceTotal from "./_partial/PriceTotal";
import StaffPrice from "./_partial/StaffPrice";
import AppsPrice from "./_partial/AppsPrice";

const ProPaymentDetails = ({
  plan,
  staffs,
  staffData,
  branchData,
  ecommerceData,
  allBranches,
  duration,
  setTotal,
  noOfStaffs,
  numOfBranches,
  numOfEcommerceBranch,
  paymentOptions,
  updateMetaInfo,
  addons,
  activePaidApps,
  user,
  totalAppsPrice
}) => {
  // ======================== PRICES ======================== //
  const {
    price: staffPrice,
    discount_percentage: staff_discount_percentage,
  } = staffData;

  const {
    price: branchPrice,
    discount_percentage: branch_discount_percentage,
  } = branchData;

  const {
    price: ecommercePrice,
    // discount_percentage: ecommerce_discount_percentage,
  } = ecommerceData;
  // ======================== PRICES ======================== //

  // Filter Branches that are not Ecommerce
  const filteredBranches = allBranches.filter(
    (branch) => branch.name !== "Ecommerce"
  );

  // Get Ecommerce Related Branch
  const ecommerceBranch = allBranches.filter(
    (branch) => branch.name === "Ecommerce"
  );

  // Base Price Charged for Merchant
  const base_price = Number(plan.price ? plan.price : 0);

  // Price for Ecommerce Branch
  const ecommercePriceToFixed = Number(ecommercePrice).toFixed(2);

  // ======================== BRANCH ======================== //

  // Branch Discount Charged for Branches
  const branchDiscount = Number(branchPrice) * branch_discount_percentage;

  const discounted_branch_price = (numOfBranches) =>
    (Number(branchPrice) - branchDiscount) * (numOfBranches - 1).toFixed(2); // One which is substracted from the incoming ```numOfBranches``` represent the Default/Base Branch.

  const undiscounted_branch_price = (numOfBranches) =>
    (Number(branchPrice) * numOfBranches).toFixed(2);
  // ======================== BRANCH ======================== //

  // ======================== STAFF ======================== //
  // Discount Amount Charged for Staffs
  const staffDiscount = Number(staffPrice) * staff_discount_percentage;

  // Discount Amount to be Charged for Staffs
  const discounted_staff_price = (numOfStaffs) =>
    ((Number(staffPrice) - staffDiscount) * numOfStaffs).toFixed(2);

  const undiscounted_staff_price = (numOfStaffs) =>
    (Number(staffPrice) * numOfStaffs).toFixed(2);

  // ======================== STAFF ======================== //

  // ======================== RENEWAL ======================== //

  /**
   *
   * EXCLUDE DEFAULT/BASE/HQ BRANCH FROM CALCULATION
   */

  const renewal = {
    total_staff_price:
      staffs.length > 3
        ? discounted_staff_price(staffs.length)
        : undiscounted_staff_price(staffs.length),

    total_branch_price:
      filteredBranches.length >= 4
        ? discounted_branch_price(filteredBranches.length)
        : filteredBranches.length < 4 && filteredBranches.length > 1
        ? undiscounted_branch_price(filteredBranches.length - 1) // One subtracted here represents the Default/Base Branch
        : 0,

    ecommerce_branch_price:
      ecommerceBranch.length > 0
        ? Number(ecommercePrice) * ecommerceBranch.length
        : 0,

    total_apps_price: 
      totalAppsPrice 
        ? Number(totalAppsPrice) 
          : 0
  };
  // ======================== RENEWAL ======================== //

  // ======================== UPGRADE ======================== //
  const upgrade = {
    total_staff_price:
      noOfStaffs > 3
        ? discounted_staff_price(noOfStaffs)
        : undiscounted_staff_price(noOfStaffs),

    total_branch_price:
      numOfBranches >= 3
        ? discounted_branch_price(numOfBranches + 1)
        : undiscounted_branch_price(numOfBranches), // Added One to the parameters to replace default branch which will be deducted in the ```discounted_branch_price``` function

    ecommerce_branch_price:
      numOfEcommerceBranch > 0
        ? Number(ecommercePrice) * numOfEcommerceBranch
        : 0,
  };
  // ======================== UPGRADE ======================== //

  const renewal_subTotal = (
    Number(base_price) +
    Number(renewal.total_staff_price) +
    Number(renewal.total_branch_price) +
    renewal.ecommerce_branch_price + 
    renewal.total_apps_price
  ).toFixed(2);

  const upgrade_subTotal = (
    Number(base_price) +
    Number(upgrade.total_staff_price) +
    Number(upgrade.total_branch_price) +
    upgrade.ecommerce_branch_price
  ).toFixed(2);

  const subTotal =
    paymentOptions === "renewal"
      ? renewal_subTotal
      : paymentOptions === "upgrade"
      ? upgrade_subTotal
      : 0;

  const total = Number(subTotal * duration).toFixed(2);

  useEffect(() => {
    if (total) {
      setTotal(total);
      // console.log("Branch Priec", branchPrice.toFixed(2));

      if (paymentOptions === "renewal") {
        updateMetaInfo({
          no_of_staffs_paid: staffs.length,
          no_of_branches_paid:
            filteredBranches.length > 1 ? filteredBranches.length : 0,
          hasEcommerce:
            ecommerceBranch.length > 0
              ? Number(ecommercePrice) * ecommerceBranch.length
              : 0,
          no_of_months: duration,
        });
      } else {
        updateMetaInfo({
          no_of_staffs_paid: noOfStaffs,
          no_of_branches_paid: numOfBranches >= 1 ? numOfBranches : 0,
          hasEcommerce: (addons && addons.hasEcommerce) || false,
          no_of_months: duration,
        });
      }
    }
  }, [total]);

  return (
    <div className="payment-breakdown" style={{ marginTop: "1.2rem" }}>
      <p>
        <strong>Payment Details</strong>
      </p>
      <div className="details">
        <BasePrice plan={plan} base_price={base_price} />

        {/* ======== STAFFS ======== */}
        <StaffPrice
          paymentOptions={paymentOptions}
          staffs={staffs}
          plan={plan}
          renewal={renewal}
          upgrade={upgrade}
          staffPrice={staffPrice}
          noOfStaffs={noOfStaffs}
          staff_discount_percentage={staff_discount_percentage}
          addons={addons}
        />
        {/* ======== STAFFS ======== */}

        {/* ======== BRANCHES ======== */}
        <BranchPrice
          paymentOptions={paymentOptions}
          filteredBranches={filteredBranches}
          plan={plan}
          renewal={renewal}
          upgrade={upgrade}
          branchPrice={branchPrice}
          branch_discount_percentage={branch_discount_percentage}
          numOfBranches={numOfBranches}
          addons={addons}
        />
        {/* ======== BRANCHES ======== */}

        {/* ======== ECOMMERCE ======== */}
        <EcommercePrice
          paymentOptions={paymentOptions}
          ecommerceBranch={ecommerceBranch}
          hasEcommerce={(addons && addons.hasEcommerce) || false}
          numOfEcommerceBranch={numOfEcommerceBranch}
          ecommercePrice={ecommercePrice}
          plan={plan}
          renewal={renewal}
          upgrade={upgrade}
        />
        {/* ======== ECOMMERCE ======== */}

        {/* ======== APPS ADDS ON ========== */}
        {activePaidApps 
          && activePaidApps.length > 0 
            && <AppsPrice 
            activePaidApps={activePaidApps}
            user={user}
            totalAppsPrice={totalAppsPrice}
            fromStripeSubscription={false}
        />}
        {/* ======== APPS ADDS ON ========== */}
      </div>

      <PriceTotal
        plan={plan}
        subTotal={subTotal}
        total={total}
        duration={duration}
      />
    </div>
  );
};

export default ProPaymentDetails;
