import { GET_ALL_BRANCHES, LOADING_ALL_BRANCHES } from "../constants";
import api from "../../api";

export const addNewBusinessBranch = requestPayload => dispatch => {
  return api.branch.addNewBusinessBranch(requestPayload);
};

export const getAllBusinessBranches = () => async dispatch => {
  dispatch({
    type: LOADING_ALL_BRANCHES,
    payload: true
  });
  const res = await api.branch.getAllBusinessBranches();

  if(res && res.status === 200) {
    dispatch({
      type: LOADING_ALL_BRANCHES,
      payload: false
    });

    dispatch({
      type: GET_ALL_BRANCHES,
      payload: res.data
    });

    const homebranch = res.data && res.data.filter((branch)=>{
      return branch.name.toLowerCase().includes("home");
    });

    const home = {
      id: homebranch.length > 0 ? homebranch[0].id : null,
      name: homebranch.length > 0 ? homebranch[0].name : null,
    }

    localStorage.setItem("homebranch", JSON.stringify(home))
  }

  return Promise.resolve(res);
};

export const addNewStaff = requestPayload => async dispatch => {
  return api.branch.addNewStaff(requestPayload);
}


export const editStaff = requestPayload => async dispatch => {
  return api.branch.editStaff(requestPayload);
}

export const isStaffExist = staff_name => async dispatch => {
  return api.branch.isStaffExist(staff_name);
}

export const deleteStaff = staffId => async dispatch => {
  return api.branch.deleteStaff(staffId);
}

export const archiveStaff = staffId => async dispatch => {
  return api.branch.archiveStaff(staffId);
}

export const archiveBranch = staffId => async dispatch => {
  return api.branch.archiveBranch(staffId);
}

export const editBranch = (id, branchData) => async dispatch => {
  return api.branch.editBusinessBranch(id, branchData);
}

export const unArchiveStaff = staffId => async dispatch => {
  return api.branch.unArchiveStaff(staffId);
}

export const unArchiveBranch = staffId => async dispatch => {
  return api.branch.unArchiveBranch(staffId);
}