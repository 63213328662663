import { 
  GET_ORDERS, 
  UPDATE_ORDER, 
  CANCEL_ORDER, 
  DISPLAY_ORDERS, 
  PAY_ORDER, 
  OPEN_ORDER_SCREEN 
} from "../constants";

const initialState = {
  allOrders: [],
  displayOrder: null,
  isOrderPaid: false,
  paidID: null,
  openOrderScreen: false
 
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ORDERS:
      return {
        ...state,
        allOrders: payload,
      };
    case UPDATE_ORDER:
      return {
        ...state,
      };
    case CANCEL_ORDER:
      return {
        ...state,
      };
    case DISPLAY_ORDERS: {
      return {
        ...state,
        displayOrder: payload,
      }
    }
    case PAY_ORDER: {
      return {
        ...state,
        isOrderPaid: payload.isOrderPaid,
        paidID: payload.paidID
      }
    }
    case OPEN_ORDER_SCREEN: {
      return {
        ...state,
        openOrderScreen: payload
      }
    }
    default:
      return state;
  }
};
export default reducer;
