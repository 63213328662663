import { GET_ALL_LOYALTY_PROGRAMS } from "../constants";

const initialState = {
  allLoyaltyPrograms: []
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_LOYALTY_PROGRAMS:

      const allLoyaltyPrograms = payload.filter(loyalty => (loyalty.deleted !== true));
      return {
        ...state,
        allLoyaltyPrograms
      };
    default:
      return state;
  }
};
export default reducer;
