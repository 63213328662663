import React, { useState, useEffect } from "react";
import api from "../../../../api";
import { Button, Modal, message, Input, InputNumber, Select } from "antd";
import strings from "../../../../strings";
import moment from "moment";
import {
  createPaymentLabel,
  updatePaymentLabel,
  deletePaymentLabel,
  getAllPaymentLabel,
} from "../../../../redux/actions/saleActions";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import CustomTable from "../../../../components/CustomDataTable";

const PaymentLabels = ({
  createPaymentLabel,
  updatePaymentLabel,
  deletePaymentLabel,
  getAllPaymentLabel,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [allPaymentLabels, setAllPaymentLabels] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [detailsToShow, setDetailsToShow] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);

  const handleGetPaymentOptions = async () => {
    setLoading(true);
    getAllPaymentLabel()
      .then((res) => {
        setLoading(false);
        setAllPaymentLabels(res.data);
      })
      .catch((err) => {
        setLoading(false);
        Modal.error({
          title: `unable to get payment options, ${err.message}`,
        });
      });
  };

  const handleCreateLabel = async () => {
    if (!labelName) return message.warn("Input the label name to continue");
    if (!paymentType) return message.warn("Select payment type to continue");
    if (!accountNumber)
      return message.warn("Input the account number to continue");

    const payload = {
      label_name: labelName,
      payment_type: paymentType,
      account_number: accountNumber,
      business_branch_id: user?.business_branch
        ? user?.business_branch.id
        : null,
    };

    setCreateLoading(true);
    createPaymentLabel(payload)
      .then((res) => {
        setCreateLoading(false);
        Modal.success({
          title: "Payment Label created successfully",
          onOk: () => {
            handleGetPaymentOptions();
            setOpenCreateModal(false);
          },
        });
      })
      .catch((err) => {
        setCreateLoading(false);
        Modal.error({
          title: `unable to create payment label, ${err.message}`,
        });
      });
  };

  const handleDeleteLabel = (id) => {
    setDeleteLoading(true);
    deletePaymentLabel(id)
      .then((res) => {
        setDeleteLoading(false);
        Modal.success({
          title: "Payment Label deleted successfully",
          onOk: () => {
            handleGetPaymentOptions();
          },
        });
      })
      .catch((err) => {
        setDeleteLoading(false);
        Modal.error({
          title: `unable to delete payment label, ${err.message}`,
        });
      });
  };

  const handleUpdateLabel = () => {
    if (!labelName) return message.warn("Input the label name to continue");
    if (!paymentType) return message.warn("Select payment type to continue");
    if (!accountNumber)
      return message.warn("Input the account number to continue");

    const payload = {
      label_name: labelName,
      payment_type: paymentType,
      account_number: accountNumber,
      business_branch_id: user?.business_branch
        ? user?.business_branch.id
        : null,
    };

    setEditLoading(true);
    updatePaymentLabel(selectedLabel.id, payload)
      .then((res) => {
        setEditLoading(false);
        Modal.success({
          title: "Payment Label edited successfully",
          onOk: () => {
            handleGetPaymentOptions();
            setOpenCreateModal(false);
            setSelectedLabel(null);
          },
        });
      })
      .catch((err) => {
        setEditLoading(false);
        Modal.error({
          title: `unable to edit payment label, ${err.message}`,
        });
      });
  };

  const handlePaymentEditClick = (selectedLabel) => {
    setLabelName(selectedLabel?.label_name);
    setPaymentType(selectedLabel?.payment_type);
    setAccountNumber(selectedLabel?.account_number);
  };

  const handlePaymentCancelClick = () => {
    setSelectedLabel(null);
    setOpenCreateModal(false);
    setLabelName("");
    setPaymentType("");
    setAccountNumber("");
  };

  useEffect(() => {
    handleGetPaymentOptions();
  }, []);

  const columns = [
    {
      title: "Label Name",
      dataIndex: "label_name",
      key: "1",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "2",
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "3",
    },
    {
      title: strings.actions,
      key: "4",
      dataIndex: "paymentMethods",
      render: (record, detail) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedLabel(detail);
                handlePaymentEditClick(detail);
                setOpenCreateModal(true);
              }}
            />
            <DeleteOutlined
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteLabel(detail.id)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ margin: "20px" }}>
        <div style={{ marginBottom: "30px" }}>
          <Button type="primary" onClick={() => setOpenCreateModal(true)}>
            Create Payment Label
          </Button>
        </div>

        <CustomTable
          loading={loading}
          columns={columns}
          dataSource={allPaymentLabels}
          onRow={(record) => {
            return {
              onClick: (e) => {},
            };
          }}
        />

        {openCreateModal && (
          <Modal
            open={openCreateModal}
            onCancel={() => handlePaymentCancelClick()}
            title={
              selectedLabel
                ? `Edit Payment Label - ${selectedLabel.label_name}`
                : "Create Payment Label"
            }
            footer={
              <div
                style={{
                  marginBottom: "30px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button onClick={() => handlePaymentCancelClick()}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    selectedLabel ? handleUpdateLabel() : handleCreateLabel()
                  }
                  loading={selectedLabel ? editLoading : createLoading}
                >
                  {selectedLabel ? "Edit" : "Create"}
                </Button>
              </div>
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Input
                value={labelName}
                placeholder="Label Name"
                onChange={(e) => setLabelName(e.target.value)}
              />

              <Select
                value={paymentType}
                onChange={(value) => setPaymentType(value)}
                style={{ width: "100%" }}
              >
                <Select.Option value="Card">Card</Select.Option>
                <Select.Option value="Transfer">Transfer</Select.Option>
              </Select>
              <InputNumber
                value={accountNumber}
                placeholder="Account Number"
                onChange={(value) => setAccountNumber(value)}
                style={{ width: "100%" }}
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isRenewalModalOpen: state.renewal.isRenewalModalOpen,
  isCurrentPlanDate: state.merchant.isCurrentPlanDate,
  isNitroActive: state.appStore.isNitroActive,
  isBeepBeepActive: state.appStore.isBeepBeepActive,
  isWhatsAppActive: state.appStore.isWhatsAppActive,
  isSubscribeModalOpen: state.renewal.isSubscribeModalOpen,
  subscriptionStatus: state.renewal.subscriptionStatus,
  allBranches: state.branch.allBranches,
});

export default connect(mapStateToProps, {
  createPaymentLabel,
  updatePaymentLabel,
  deletePaymentLabel,
  getAllPaymentLabel,
})(PaymentLabels);
