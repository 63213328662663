import React from "react";
import { Modal, message, Button, Input } from "antd";
import { Form } from "@ant-design/compatible";
import { useMutation } from "@apollo/client";
import {
  ENROLL_CUSTOMER_SUCCESS,
  ENROLL_CUSTOMER_WITH_POINTS,
  LIST_UNENROLLED_MEMBERS,
  LIST_TIERED_CUSTOMERS,
  LIST_TIERS,
} from "../../../constants";
import strings from "../../../../../../../../../strings";

const AwardPointsModal = ({
  match,
  visible,
  customerListId,
  closeModal,
  clearSelectedCustomers,
  loyalty_id,
  customer,
  user,
  form,
}) => {
  const loyaltyId = loyalty_id || match.params.id;

  customerListId = customer ? [{ customer_id: customer.id }] : customerListId;

  const [
    enrollCustomerWithPoints,
    { loading: enrollCustomerWithPointsLoading },
  ] = useMutation(ENROLL_CUSTOMER_WITH_POINTS, {
    onCompleted: ({ enrollCustomerWithPoints }) => {
      closeModal();

      enrollCustomerWithPoints &&
      enrollCustomerWithPoints.length &&
      enrollCustomerWithPoints[0].id
        ? Modal.success({
            title: ENROLL_CUSTOMER_SUCCESS,
          })
        : Modal.warn({
            title: strings.pointsAwardedSuccessfully,
            content: enrollCustomerWithPoints[0].messages,
          });

      // Clear Selected Customers when more than one Customer are Enrolled
      !customer && clearSelectedCustomers();
    },
    onError: error => {
      message.error(error.message, 5);
    },
    refetchQueries: [
      { query: LIST_UNENROLLED_MEMBERS },
      { query: LIST_TIERED_CUSTOMERS },
      { query: LIST_TIERS, variables: { loyalty_id: loyaltyId } },
    ],
  });

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        enrollCustomerWithPoints({
          variables: {
            loyalty_id: loyaltyId,
            customerListId,
            points: Number(values.points),
          },
        });
        form.resetFields();
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const { getFieldDecorator } = form;

  return (
    <Modal
      title={strings.awardPointValuesToCustomer + "(s)"}
      className=""
      visible={visible}
      onCancel={() => closeModal()}
      footer={[
        <Button key="cancel" onClick={() => closeModal()}>
          {strings.cancel}
        </Button>,
        <Button
          loading={enrollCustomerWithPointsLoading}
          key="submit"
          type="primary"
          id="btn-component"
          disabled={(customer && !customer.id) || customerListId.length === 0}
          onClick={() => {
            handleOk();
          }}
        >
          {strings.award}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} name="award_point_form" layout="vertical">
        <Form.Item name="points" label={strings.enterPointValueToAward}>
          {getFieldDecorator("points", {
            rules: [
              {
                required: true,
              },
            ],
          })(<Input type="number" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(AwardPointsModal);
