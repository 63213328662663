import React from "react";
import { Modal, Input } from "antd";
import { Form } from "@ant-design/compatible";
import compareToFirstPassword from "../../../../../../utils/comparePasswords";
import { signoutUser } from "../../../../../../redux/actions/authActions";
import { connect } from "react-redux";
import api from "../../../../../../api";
import strings from "../../../../../../strings";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons"

const FormItem = Form.Item;

const ChangeStaffPasswordModalForm = ({
  handleShowChangePassword,
  showChangePassword,
  form,
  signoutUser,
  currentStaff,
  currentBranchId,
  getBusinessBranch
}) => {
  const { getFieldDecorator } = form;
  const [isChangingPassword, setIsChangingPassword] = React.useState(false);

  // console.log("cd", currentStaff, currentBranchId);
  const handleSubmit = () => {
    form.validateFields(async (err, values) => {
      if (err) return;

      setIsChangingPassword(true);
      const res = await api.auth.staffResetPassword(values);

      setIsChangingPassword(false);

      if (res && res.status === 200) {
        Modal.success({
          title: strings.passwordResetSuccessfulAndSignOut,
          okText: strings.signOut,
          onOk: () => signoutUser(),
        });
      }
    });
  };

  const handleAdminPasswordChange = () => {
    form.validateFields(async (err, values) => {
      if (err) return;

      setIsChangingPassword(true);
      const res = await api.auth.staffAdminResetPassword({
        ...values, 
        email: currentStaff.email,
        business_branch_id: currentBranchId
      });

      setIsChangingPassword(false);

      if (res && res.status === 200) {
        Modal.success({
          title: strings.passwordResetSuccessfulForStaff,
          // okText: strings.signOut,
          onOk: () => {
            handleShowChangePassword()
            getBusinessBranch();
          },
        });
      }
    });
  };

  return (
    <Modal
      open={showChangePassword}
      onCancel={handleShowChangePassword}
      destroyOnClose
      onOk={handleAdminPasswordChange}
      okButtonProps={{
        loading: isChangingPassword,
        disabled: isChangingPassword,
      }}
    >
      <Form layout="vertical" >
        {/* <FormItem label={strings.enterCurrentPassword}>
          {getFieldDecorator("current_password", {
            rules: [
              {
                required: true,
                message: strings.pleaseEnterCurrentPassword,
              },
            ],
          })(<Input size="large" type="password" />)}
        </FormItem> */}
        {/* Password Input */}
        <FormItem label={strings.choosePassword}>
          {getFieldDecorator("password", {
            rules: [
              {
                message: strings.pleaseEnterPassword,
                required: true,
              },
            ],
          })(<Input.Password size="large" type="password" />)}
        </FormItem>
        {/* ======Password */}
        {/* Password Input */}
        <FormItem label={strings.confirmPassword}>
          {getFieldDecorator("password_confirmation", {
            rules: [
              {
                message: strings.confirmPasswordMessage,
                required: true,
              },
              {
                validator: (rule, value, callback) => {
                  compareToFirstPassword(form, rule, value, callback);
                },
              },
            ],
          })(<Input.Password size="large" type="password"  />)}
        </FormItem>
        {/* ======Password */}
      </Form>
    </Modal>
  );
};

const ChangeStaffPasswordModal = Form.create()(ChangeStaffPasswordModalForm);

export default connect(null, { signoutUser })(ChangeStaffPasswordModal);
