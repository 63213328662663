
import { ORDERS } from "../constants";

const initialState = {
    orders: [],
};

const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDERS:
      return {
        ...state,
        orders: payload,
      };

    default:
      return state;
  }
};

export default ordersReducer;
