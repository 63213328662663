import React, { useState, useEffect} from 'react';
import { Modal, Badge } from 'antd';
import strings from '../../../../strings';

const CustomVariant = ({ 
  open, 
  onCancel, 
  product, 
  handleNitroVariantSelect,
  handleBlurFactor 
}) => {

    const [ selectedVariants, setSelectedVariants ] = useState({});
    const [ groupedVariants, setGroupedVariants ] = useState({});
    const [ isSelected, setIsSelected ]= useState({});
    const [ user, setUser ] = useState([]);

    useEffect(()=>{
        const isMerchant = localStorage.getItem("userDetails");
        const merchant = isMerchant && JSON.parse(isMerchant);
        setUser(merchant);
        handleBlurFactor(false);
    }, []);

    useEffect(()=>{
        const groupedVariants = {};
        const selectVariantsObj = {};
        product.variants.forEach(variant => {
            if (groupedVariants[variant.type]) {
                groupedVariants[variant.type].push(variant);
            } else {
                selectVariantsObj[variant.type] = [];
                groupedVariants[variant.type] = [variant];
            }
        });
        setSelectedVariants(selectVariantsObj);
        setGroupedVariants(groupedVariants);
    }, [product]);

    const displayVariants = () => {
        const variants = groupedVariants;
        const keys = Object.keys(variants);
        return keys.map(key => {
          return (
            <div key={key}>
              <span className="type">{`${key}:`}</span>
              <div className="variant-wrapper">
                {variants[key].map(variant => {
                  return (
                    <Badge
                      key={variant.id}
                      // count={variant.quantity}
                    //   className={
                    //     selectedVariants[key].indexOf(variant) >= 0
                    //       ? strings.variantSelected
                    //       : strings.variant
                    //   }
                      className={
                        isSelected?.id === variant.id
                          ? strings.variantSelected
                          : strings.variant
                      }
                      onClick={() => {
                        selectVariant(variant, key);
                      }}
                    >
                      <span style={{ paddingRight: "10px" }}>{variant.value}</span>
                      <span style={{ paddingRight: "10px" }}>-</span>
                      <span>
                        {user && user.currency}
                        {product.tax_rate
                          ? (
                              (product.tax_rate / 100) * variant.price +
                              parseFloat(variant.price)
                            ).toFixed(2)
                          : variant.price}
                      </span>
                    </Badge>
                  );
                })}
              </div>
            </div>
          );
        });
      };

      const selectVariant = (variant, key) => {
        // setQuantity(1)
        const keys = Object.keys(selectedVariants);
        let check = false;
        keys.forEach(selectedKey => {
          if (selectedVariants[selectedKey].length > 0 && key !== selectedKey) {
            if (selectedVariants[selectedKey][0].price !== variant.price) {
              check = true;
            }
          }
        });
        if (check) {
          Modal.warning({
            title: strings.thePreviousVariantSelectionNotAvailable,
          });
          return;
        }
        selectedVariants[key] = [variant];

        setIsSelected(variant)
        setSelectedVariants(selectedVariants)
        // console.log(selectedVariants, variant);
        handleNitroVariantSelect(product, variant);
        onCancel();
    };

  return (
    <>
        <Modal
            title={`${product.name} ${product.price}`}
            // okText={strings.add}
            cancelText={strings.cancel}
            // onOk={handleOnClick}
            footer={null}
            onCancel={onCancel}
            destoryOnClose
            open={open}
            // okButtonProps={{ loading: loading }}
        >
            <div className="variants">
              <span>Select Variants:</span>
              {/* <Button
                  htmlType="button"
                  className="clear-button"
                  disabled={!(getSelectedVariants().length > 0)}
                  onClick={clearSelectedVariants}
              >
                  {strings.clearVariants}
            </Button> */}
              <div>{displayVariants()}</div>
            </div>

        </Modal>
    </>
  ) 
}

export default CustomVariant