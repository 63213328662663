import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, Button, Row, Col, Icon, Modal, message } from "antd";
import { Form } from "@ant-design/compatible";
import { PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import AddFormModal from "./_partials/AddFormModal";
import { useMutation } from "@apollo/client";
import EditFormModal from "./_partials/EditFormModal";
import {
  DELETE_FORM,
  LIST_FORMS,
} from "../../Apps/MultiLevelLoyalty/Loyalties/constants";
import ViewFormDetails from "./_partials/ViewFormDetails";

const CustomFields = ({ form }) => {
  const [addModalVisible, showAddModalVisible] = useState(false);
  const [editFormVisible, setEditFormVisible] = useState(false);
  const [editForm, setEditForm] = useState(null);
  const [savedForms, setSavedForms] = useState([]);
  const [viewFormDetails, setViewFormDetails] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);

  const { loading, data } = useQuery(LIST_FORMS);

  useEffect(() => {
    data && data.listForms && setSavedForms(data.listForms);
  }, [data]);

  const [deleteForm] = useMutation(DELETE_FORM, {
    onCompleted: data => {
      data.deleteForm &&
        Modal.success({
          title: "Form deleted successfully",
          okButtonProps: {
            id: "btn-component",
          },
        });
    },
    onError: error => {
      message.error(error.message, 5);
    },
    refetchQueries: [{ query: LIST_FORMS }],
  });

  const confirmDeleteForm = form => {
    Modal.confirm({
      title: "Delete Form",
      content: "Do you want to delete this form?",
      onOk: () => handleDeleteField(form.id),
    });
  };

  const handleDeleteField = id => deleteForm({ variables: { id } });

  const showModalDetails = form => {
    setCurrentForm(form);
    setViewFormDetails(true);
  };

  return (
    <>
      <AddFormModal
        visible={addModalVisible}
        onCancel={() => showAddModalVisible(false)}
        form={form}
      />
      {editForm && (
        <EditFormModal
          visible={editFormVisible}
          editForm={editForm}
          onCancel={() => {
            setEditFormVisible(false);
            setEditForm(null);
          }}
        />
      )}
      {viewFormDetails && (
        <ViewFormDetails
          currentForm={currentForm}
          visible={viewFormDetails}
          onCancel={() => {
            setCurrentForm(null);
            setViewFormDetails(false);
          }}
        />
      )}
      <Card
        title="Form Builder"
        bordered={false}
        loading={loading}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showAddModalVisible(true)}
          >
            Add New Form
          </Button>
        }
      >
        <h4>Forms Added</h4>
        <div style={{ marginTop: "20px" }}>
          <ul>
            {savedForms && savedForms.length > 0 ? (
              savedForms.map(form => (
                <Card
                  hoverable
                  style={{ marginTop: "10px", width: "50vw" }}
                  key={form.id}
                >
                  <Row>
                    <Col onClick={() => showModalDetails(form)} span={10}>
                      {form.form_name}
                    </Col>
                    <Col onClick={() => showModalDetails(form)} span={10}>
                      {form.form_fields.length} fields
                    </Col>
                    <Col span={2}>
                      <Icon
                        type="edit"
                        title="Edit form"
                        onClick={() => {
                          setEditForm(form);
                          setEditFormVisible(true);
                        }}
                      />
                    </Col>
                    <Col span={2}>
                      <Icon
                        type="delete"
                        title="Delete form"
                        onClick={() => {
                          confirmDeleteForm(form);
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              ))
            ) : (
              <li>
                <em>You have no forms saved yet</em>
              </li>
            )}
          </ul>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Form.create()(CustomFields));
