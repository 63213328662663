import React from 'react'
import { connect } from 'react-redux'
import strings from '../../strings'

const OnlineIndicator = ({online}) => {
  return (
    <span style={{
      backgroundColor: online ? "#4CAF50" : "#ff4f42",
      padding: "5px 15px",
      borderRadius: "16px",
      color: "#fff"
    }}>{online ? strings.online : strings.offline}</span>
  )
}

const mapStateToProps = (state) => ({
  online: state.networkStatus
})

export default connect(mapStateToProps)(OnlineIndicator)