import React from "react";
import { Modal, Input, Select, message } from "antd";
import { Form, Icon } from "@ant-design/compatible";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  addNewStaff,
  getAllBusinessBranches,
  isStaffExist,
} from "../../../../../../redux/actions/branchActions";
import strings from "../../../../../../strings";
import PhoneInput from "react-phone-input-2";
import PaymentModal from "../paymentModal";
import { debounce } from 'lodash';

const FormItem = Form.Item;
const Option = Select.Option;

class AddNewStaffModal extends React.PureComponent {
  state = {
    loading: false,
    openPaymentModal: false,
    priceToPay: null,
    existLoading: false,
    doesStaffExist: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ loading: false });
    }
  }

  handleAddStaff = () => {
    const form = this.props.form;
    const { closeModal } = this.props;

    form.validateFields((err, values) => {
      if (err) return;
    if(this.state.doesStaffExist) return message.error(strings.userExistError)
      this.setState({ loading: true });

      this.props.addNewStaff(values).then(res => {
        if (res.status && res.status === 200) {
          this.props.form.resetFields();

          Modal.success({
            title: strings.successfullyAddedStaff,
          });

          this.setState({ loading: false });
          this.props.getAllBusinessBranches();
          this.props.getBusinessBranch();
          closeModal();
        }
      });
    });
  };

  handleIsStaffNameExist = async (username) => {
    if(username.length < 5) return;
    this.setState({ existLoading: true });
    const { isStaffExist } = this.props;

    isStaffExist(username)
    .then(res => {
      if(res.status === 200){
        const msg = res.data.message;
        this.setState({ 
          doesStaffExist: msg === "Staff exist" ? true :  false,
          existLoading: false
        });
        message.warn(strings.userExistError);
      }
    })
    .catch(err => {
      this.setState({ 
        doesStaffExist: false,
        existLoading: false
      });
    });
  }

  handleDebounce = debounce(value => this.handleIsStaffNameExist(value), 500);

  openPriceModal = () => {
    const form = this.props.form;
    const { validateFields } = form;
    validateFields((err, value)=>{
      if (err) {
        return Modal.error({ title: "Please fill all fields" });
      }
      this.setState({ openPaymentModal: true }); 
    });
  }

  render() {
    const {
      visible,
      form: { getFieldDecorator },
      branches,
      closeModal,
      staffPrice,
      user,
      allStaff,
      staffPaidFor
    } = this.props;

    const { loading, priceToPay, openPaymentModal, doesStaffExist } = this.state;
    console.log(allStaff, staffPaidFor);

    return (
      <>
        <Modal
          open={visible}
          title={strings.addNewStaff}
          okText={strings.addStaff}
          onOk={()=>{
            if(Number(allStaff.length) >= Number(staffPaidFor)){
              this.openPriceModal();
            }else{
              this.handleAddStaff();
            }
          }}
          onCancel={closeModal}
          okButtonProps={{ loading: loading }}
          destroyOnClose
        >
          <div>
            <p>
              {strings.additionalStaffWillIncur}{" "}
              <strong>
                {user.currency}
                {staffPrice} {strings.perStaff}
              </strong>
            </p>
          </div>

          <Form layout="vertical">
            {/* Staff Email Input */}
            <FormItem>
              {getFieldDecorator("email", {
                rules: [
                  {
                    message: strings.pleaseEnterStaffEmail,
                    required: true,
                  },
                ],
              })(
                <Input
                  size="large"
                  type="email"
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder={strings.enterStaffEmail}
                />
              )}
            </FormItem>

            {/* Staff Phone Number Input */}
            <FormItem>
                {getFieldDecorator("phone_number", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter staff phone number",
                    },
                  ],
                })(
                  <PhoneInput
                    inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                    inputStyle={{
                      border: "1px solid #d9d9d9",
                      width: "100%",
                      height: "40px",
                    }}
                    placeholder={strings.phoneNumber}
                    country="ng"
                  />
                //   <Input
                //   size="large"
                //   type="number"
                //   prefix={
                //     <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                //   }
                //   placeholder={strings.phoneNumber}
                // />
                )}
              </FormItem>

            {/* Staff Username Input */}
            <FormItem>
              {getFieldDecorator("username", {
                rules: [
                  {
                    message: strings.pleaseEnterStaffUsername,
                    required: true,
                  },
                  {
                    validator: async (rule, value, callback) => {
                      const hasSpaces = value.split(" ").length > 1;
                      const isEmail = value.split("@").length > 1;
                      const doesExist = await this.handleDebounce(value);
                      // const isUserNameTaken = allStaff.filter(staff => staff.username.toLowerCase().includes(value.toLowerCase()));

                      if (hasSpaces) {
                        callback(strings.spacesNotAllowedInUsername);
                      } else if (isEmail) {
                        callback("Email is not allowed in username");
                      // } else if(doesStaffExist){
                      //   callback(strings.usernameMustBeUnique);
                      }else {
                        callback();
                      }
                    },
                  },
                ],
              })(
                <Input
                  size="large"
                  type="text"
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder={strings.enterStaffUsername}
                />
              )}
            </FormItem>
            {/* Staff Password Input */}
            <FormItem>
              {getFieldDecorator("password", {
                rules: [
                  {
                    message: strings.pleaseEnterStaffPassword,
                    required: true,
                  },
                ],
              })(
                <Input
                  size="large"
                  prefix={
                    <Icon type="safety" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder={strings.enterStaffPassword}
                />
              )}
            </FormItem>
            {/* Staff Role Input */}
            <FormItem>
              {getFieldDecorator("role_id", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseSelectStaffRole,
                  },
                ],
              })(
                <Select
                  placeholder={strings.selectStaffRole}
                  size="large"
                  style={{ width: "100%" }}
                >
                  <Option value={1}>{strings.admin}</Option>
                  <Option value={2}>{strings.manager}</Option>
                  <Option value={3}>{strings.cashier}</Option>
                </Select>
              )}
            </FormItem>
            {/* Business Branch Input */}
            <FormItem>
              {getFieldDecorator("business_branch_id", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseSelectBusinessToAttachStaff,
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder={strings.searchForBranchToAttachStaff}
                  optionFilterProp="children"
                  size="large"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {branches &&
                    branches.map(branch => (
                      <Option key={branch.id} value={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
          </Form>
        </Modal>
        { openPaymentModal && 
          <PaymentModal 
            open={openPaymentModal}
            onCancel={()=>this.setState({ openPaymentModal: false })}
            title={`${user.currency}${staffPrice}`}
            total={staffPrice}
            paymentDescription="How would you like to pay"
            onPay={this.handleAddStaff}
          />
        }
      </>
    );
  }
}

const AddNewStaffForm = Form.create()(AddNewStaffModal);

AddNewStaffForm.propTypes = {
  error: PropTypes.string.isRequired,
  addNewStaff: PropTypes.func.isRequired,
  getAllBusinessBranches: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  error: state.error,
});

export default connect(mapStateToProps, {
  addNewStaff,
  getAllBusinessBranches,
  isStaffExist
})(AddNewStaffForm);
