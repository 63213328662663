import React, { useState } from "react";
import { Modal, Button } from "antd";
import AccountDetailsForm from "./_partials/AccountDetails";
import strings from "../../../../../../strings";

const AccountVerificationModal = props => {
  const [state, setState] = useState({
    isDisabled: true,
    isLoading: false,
    handlerFn: () => {},
  });

  const updateState = newState => {
    setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  const { visible, closeModal } = props;

  return (
    <Modal
      className="account-verification-modal"
      title={strings.connectBankAccount}
      open={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {strings.cancel}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={state.isLoading}
          onClick={state.handlerFn}
        >
          {strings.submit}
        </Button>,
      ]}
    >
      <AccountDetailsForm
        closeModal={props.closeModal}
        updateState={updateState}
        updatePayout={props.updatePayout}
      />
    </Modal>
  );
};

export default AccountVerificationModal;
