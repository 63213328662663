import React, { useState } from 'react';
import { Form, Icon } from "@ant-design/compatible";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import strings from '../../../../strings';
import PhoneInput from "react-phone-input-2";
import { Modal, Select, Input, Card, Button, message } from 'antd';
import { addNewStaff, getAllBusinessBranches } from '../../../../redux/actions/branchActions';
import { multiLevelSignin } from '../../../../redux/actions/authActions';
import Axios from 'axios';



const FormItem = Form.Item;
const Option = Select.Option;
const AddCustomer = ({ 
  form,
  addNewStaff,
  getAllBusinessBranches,
  multiLevelSignin 
}) => {
  const { getFieldDecorator } = form;
  const [ loading, setLoading ] = useState(false);
  const [ openSignModal, setOpenSignModal ] = useState(true);
  const [ email, setEmail ] = useState(null);
  const [ password, setPassword ] = useState(null);
  const [ branches, setBranches ] = useState([]);
  const [ clientAuth, setClientAuth ] = useState({
    uid: "",
    access_token: "",
    client: ""
  })


  const businessBranch = async ({ client, uid, access_token }) => {
    try {
      const response = await Axios.get(`${process.env.REACT_APP_API_BASE_URL}/business_branches`, {
        headers: {
          client,
          uid,
          ["access-token"]: access_token
        }
      });
      
      response.data.map((res) => {
        setBranches((prevState) => {
          const isFound = prevState.filter(pr => pr.id === res.id);
          if(isFound.length > 0) return [...prevState];
          return [
            ...prevState,
            {
              id: res.id,
              name: res.name
            }
          ]
        })
      });
    } catch (error) {
      message.error(error.message)
    }
  }

  const handleAddStaff = () => {
    form.validateFields((err, values) => {
      if (err) return;

      setLoading(true);

      Axios.post(`${process.env.REACT_APP_API_BASE_URL}/add_staff`, { data: values}, {
        headers: {
          uid: clientAuth.uid,
          ["access-token"]: clientAuth.access_token,
          client: clientAuth.client
        }
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          form.resetFields();

          Modal.success({
            title: strings.successfullyAddedStaff,
            onOk: () => {
              setLoading(false);
            }
          });
          businessBranch(clientAuth);
        }
      })
      .catch((err) => {
        Modal.error({
          title: "Unable to add staff",
          onOk: () => setLoading(false)
        })
      })
    });
  };

  const handleSubmit = async e => {
    if(email === null) return message.error("Please e all fields");
    
    try {
      setLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/auth/get_uRewards_auth`,
          {
              method: "POST",
              headers: {
              "Content-Type": "application/json",
              },
              body: JSON.stringify({email}),
          }
        );
        const data = await res.json();

        const { client, access_token, uid } = data.data;

        const authData = {
          client,
          uid,
          access_token
        }

        setClientAuth({
          client,
          access_token,
          uid
        })

        setLoading(false);
        setOpenSignModal(false);
        businessBranch(authData);
    } catch (error) {
      setLoading(false);
      if( email === null){
        message.error("Please enter an admin email or staff username");
      }else if( password === null){
        message.error(strings.pleaseEnterPassword);
      }else{
        message.error("Unable to login, ensure username and password are valid");
      }
    }
  };

  return (
    <div className="page-container account-screen">
        <Card bordered={false}>
          <Button 
            type="primary" 
            onClick={() => setOpenSignModal(true)} 
            style={{ marginBottom: "30px"}}
          >
            Change Merchant
          </Button>
          <Form layout="vertical">
            {/* Staff Email Input */}
            <FormItem>
              {getFieldDecorator("email", {
                rules: [
                  {
                    message: strings.pleaseEnterStaffEmail,
                    required: true,
                  },
                ],
              })(
                <Input
                  size="large"
                  type="email"
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder={strings.enterStaffEmail}
                />
              )}
            </FormItem>

            {/* Staff Phone Number Input */}
            <FormItem>
                {getFieldDecorator("phone_number", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter staff phone number",
                    },
                  ],
                })(
                  <PhoneInput
                    inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                    inputStyle={{
                      border: "1px solid #d9d9d9",
                      width: "100%",
                      height: "40px",
                    }}
                    placeholder={strings.phoneNumber}
                    country="ng"
                  />
                //   <Input
                //   size="large"
                //   type="number"
                //   prefix={
                //     <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                //   }
                //   placeholder={strings.phoneNumber}
                // />
                )}
              </FormItem>

            {/* Staff Username Input */}
            <FormItem>
              {getFieldDecorator("username", {
                rules: [
                  {
                    message: strings.pleaseEnterStaffUsername,
                    required: true,
                  },
                  {
                    validator: (rule, value, callback) => {
                      const hasSpaces = value.split(" ").length > 1;
                      const isEmail = value.split("@").length > 1;

                      if (hasSpaces) {
                        callback(strings.spacesNotAllowedInUsername);
                      } else if (isEmail) {
                        callback("Email is not allowed in username");
                      } else {
                        callback();
                      }
                    },
                  },
                ],
              })(
                <Input
                  size="large"
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder={strings.enterStaffUsername}
                />
              )}
            </FormItem>
            {/* Staff Password Input */}
            <FormItem>
              {getFieldDecorator("password", {
                rules: [
                  {
                    message: strings.pleaseEnterStaffPassword,
                    required: true,
                  },
                ],
              })(
                <Input
                  size="large"
                  prefix={
                    <Icon type="safety" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder={strings.enterStaffPassword}
                />
              )}
            </FormItem>
            {/* Staff Role Input */}
            <FormItem>
              {getFieldDecorator("role_id", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseSelectStaffRole,
                  },
                ],
              })(
                <Select
                  placeholder={strings.selectStaffRole}
                  size="large"
                  style={{ width: "100%" }}
                >
                  <Option value={1}>{strings.admin}</Option>
                  <Option value={2}>{strings.manager}</Option>
                  <Option value={3}>{strings.cashier}</Option>
                </Select>
              )}
            </FormItem>
            {/* Business Branch Input */}
            <FormItem>
              {getFieldDecorator("business_branch_id", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseSelectBusinessToAttachStaff,
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder={strings.searchForBranchToAttachStaff}
                  optionFilterProp="children"
                  size="large"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {branches.length > 0 &&
                    branches.map(branch => (
                      <Option key={branch.id} value={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
            <Button className="" type="primary" loading={loading} onClick={handleAddStaff}>Add Staff</Button>
          </Form>
        </Card>

        <Modal
          open={openSignModal}
          onCancel={() => setOpenSignModal(false)}
          title="sign in to account you want to add staff to"
          okText={strings.signIn}
          onOk={handleSubmit}
          confirmLoading={loading}
        >
          <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
            <Input
              size="large"
              type="email"
              prefix={
                <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder={strings.enterStaffEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
              
           
            {/* <Input.Password
              size="large"
              prefix={
                <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              type="password"
              placeholder={strings.password}
              onChange={(e) => setPassword(e.target.value)}
            /> */}
          </div>
            {/* ======Password */}

        </Modal>
    </div>
    
  )
}

const AddNewStaffForm = Form.create()(AddCustomer);

const mapStateToProps = state => ({
  error: state.error,
});
// const mapDispatchToProps = dispatch => {
//   return {
//     multiLevelSignin: userData => dispatch(multiLevelSignin(userData)),
//   };
// };

export default connect(mapStateToProps, {
  addNewStaff,
  multiLevelSignin,
  getAllBusinessBranches,
})(AddNewStaffForm);