import React from "react";
import { Input, Modal, InputNumber, Select } from "antd";
import { Form } from "@ant-design/compatible";
import api from "../../../../../../api";
import strings from "../../../../../../strings";

const FormItem = Form.Item;
const Option = Select.Option;

class AddLoyaltyProgramModal extends React.Component {
  state = {
    loading: false,
    programTypeLabel: strings.spendingTarget,
    programTypeHint: strings.spendingTargetHint1,
  };

  handleAddLoyaltyProgram = () => {
    const { form, toggleModal } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      this.setState({ loading: true });
      api.loyalty
        .addNewLoyaltyProgram(values)
        .then(res => {
          if (res && res.status === 200) {
            form.resetFields();
            Modal.success({
              title: strings.loyaltyProgramAdded,
              content: strings.loyaltyProgramAddedSuccessfully,
              onOk: () => this.props.getAllLoyaltyPrograms(),
            });
            toggleModal();
          }
        })
        .catch(err => this.setState({ loading: false }));
    });
  };

  handleProgramTypeChange = value => {
    if (value.toLowerCase() === "simplepoints") {
      this.setState({
        programTypeLabel: strings.spendingTarget,
        programTypeHint: strings.spendingTargetHint1,
      });
    }

    if (value.toLowerCase() === "stampsprogram") {
      this.setState({
        programTypeLabel: strings.stampsTarget,
        programTypeHint: strings.spendingTargetHint2,
      });
    }
  };

  render() {
    const { form, visible, toggleModal } = this.props;
    const { getFieldDecorator } = form;
    const { programTypeLabel, programTypeHint } = this.state;

    return (
      <Modal
        title={strings.addNewLoyaltyProgram}
        okText={strings.addLoyaltyProgram}
        cancelText={strings.cancel}
        onOk={this.handleAddLoyaltyProgram}
        onCancel={() => {
          Modal.confirm({
            title: strings.doYouWantToCloseDialog,
            onOk: toggleModal,
            onCancel: () => {},
          });
        }}
        destoryOnClose
        visible={visible}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical" >
          <FormItem label={strings.loyaltyProgramType}>
            {getFieldDecorator("program_type", {
              initialValue: "SimplePoints",
              rules: [
                {
                  required: true,
                  message: strings.pleaseSelectLoyaltyProgramType,
                },
              ],
            })(
              <Select
                size="large"
                defaultValue="SimplePoints"
                onChange={this.handleProgramTypeChange}
              >
                <Option value="SimplePoints">SimplePoints</Option>
                <Option value="StampsProgram">StampsProgram</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label={strings.nameOfProgram}>
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterProgramName,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>
          <FormItem label={programTypeLabel} extra={programTypeHint}>
            {getFieldDecorator("threshold", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterLoyaltyThreshold,
                },
              ],
            })(<InputNumber size="large" style={{ width: "100%" }} />)}
          </FormItem>
          <FormItem
            label={strings.whatIsTheReward}
            extra={strings.egNextPurchaseIsFree}
          >
            {getFieldDecorator("reward", {
              rules: [
                {
                  required: true,
                  message: strings.reward,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const AddLoyaltyProgram = Form.create()(AddLoyaltyProgramModal);

export default AddLoyaltyProgram;
