import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Input, message, Modal, Popover, Tag } from "antd";
import { Form } from "@ant-design/compatible";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  addNewProduct,
  getAllProducts,
} from "../../../../../../../../redux/actions/productActions";
import {
  addNewCategory,
  getAllCategories,
} from "../../../../../../../../redux/actions/categoryActions";
import { getAllLoyaltyPrograms } from "../../../../../../../../redux/actions/loyaltyActions";
import { transformInToFormObject } from "../../../../../../../../utils/generateFormData";
import api from "../../../../../../../../api";
import {
  ACTIVATE_LOYALTY,
  DEACTIVATE_LOYALTY,
  ADD_PARTNER_TO_LOYALTY,
  OPT_OUT_PARTNER_LOYALTY,
  LIST_LOYALTIES,
} from "../../constants";
import strings from "../../../../../../../../strings";

const LoyaltyDetailModal = ({
  visible,
  closeModal,
  currentLoyalty,
  toggleDeleteLoyaltyModal,
  toggleEditLoyaltyModal,
  pathname,
  products,
  addNewProduct,
  allCategories,
  allLoyaltyPrograms,
  getAllProducts,
  getAllCategories,
  addNewCategory,
  user,
  getAllLoyaltyPrograms,
}) => {
  useEffect(() => {
    getAllProducts();
    getAllCategories();
    getAllLoyaltyPrograms();
  }, [getAllCategories, getAllLoyaltyPrograms, getAllProducts]);

  const [showAddPartnerModal, setShowAddPartnerModal] = useState(false);

  const [activateLoyalty, { loading: isActivateLoyaltyLoading }] = useMutation(
    ACTIVATE_LOYALTY,
    {
      onCompleted: data => {
        // console.log("Activate Loyalty", data);

        const pointPrice = data && data.activateLoyalty.currency_value;

        data &&
          data.activateLoyalty.id &&
          Modal.success({
            title: strings.loyaltyActivationSuccessful,
            onOk: async () => {
              closeModal();

              const pointProduct = products.filter(
                product => product.name.toLowerCase() === "points"
              );

              if (pointProduct.length < 0) {
                await createPointProduct(pointPrice);
              }
            },
          });
      },
      onError: error => {
        console.log("Activate Loyalty Error", error);
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: LIST_LOYALTIES }],
      // update: (cache, { data }) => {
      //   const cachedData = cache.readQuery({
      //     query: LIST_LOYALTIES,
      //   });
      //   console.log({ cachedData });

      //   cache.writeQuery({
      //     query: LIST_LOYALTIES,
      //     data: {
      //       listLoyalties: [
      //         ...cachedData.listLoyalties,
      //         ...data.activateLoyalty,
      //       ],
      //     },
      //   });
      // },
    }
  );

  const [
    deActivateLoyalty,
    { loading: isDeactivateLoyaltyLoading },
  ] = useMutation(DEACTIVATE_LOYALTY, {
    onCompleted: data => {
      Modal.success({
        title: strings.loyaltyDeactivationSuccessful,
        onOk: () => closeModal(),
      });
    },
    onError: error => {
      message.error(error.message, 5);
    },
  });

  const [
    optOutPartnerLoyalty,
    { loading: isOptOutPartnerLoading },
  ] = useMutation(OPT_OUT_PARTNER_LOYALTY, {
    onCompleted: data => {
      Modal.success({
        title: "Loyalty removed successfully",
        onOk: () => closeModal(),
      });
    },
    onError: error => {
      message.error(error.message, 5);
    },
    refetchQueries: [{ query: LIST_LOYALTIES }],
  });

  const createPointCategory = async () => {
    try {
      const data = {
        name: "Points",
      };

      const res = await addNewCategory(data);
      if (res && res.status === 200) {
        console.log(res.data);
        await getAllCategories();
        return res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createLoyaltyPrograms = async () => {
    try {
      const data = {
        program_type: "SimplePoints",
        name: "Points Loyalty",
        threshold: 10000,
        reward: "Free Ride",
      };

      const res = await api.loyalty.addNewLoyaltyProgram(data);
      if (res && res.status === 200) {
        console.log(res.data);

        await getAllLoyaltyPrograms();

        return res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createPointProduct = async pointPrice => {
    try {
      const pointCategory = allCategories.filter(
        category => category.name.toLowerCase() === "points"
      );

      let categoryId;
      let loyaltyId;

      if (pointCategory.length < 0) {
        let { id } = await createPointCategory();

        categoryId = id;
      }

      if (allLoyaltyPrograms.length < 0) {
        const { id } = await createLoyaltyPrograms();

        loyaltyId = id;
      }

      let payload = {
        name: "Point",
        description: "",
        merchant_product_category_id: pointCategory
          ? pointCategory[0].id
          : categoryId,
        merchant_loyalty_program_id: allLoyaltyPrograms.length
          ? allLoyaltyPrograms[0].id
          : loyaltyId,
        price: pointPrice,
      };

      const formData = transformInToFormObject(payload);

      const res = await addNewProduct(formData);

      if (res && res.status === 200) {
        getAllProducts();

        closeModal();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const confirmOptOutLoyalty = () => {
    Modal.confirm({
      content: "Do you want to opt out of this shared loyalty program?",
      onOk: () =>
        optOutPartnerLoyalty({
          variables: {
            loyalty_id: currentLoyalty.id,
            merchant_id: user.id,
          },
        }),
    });
  };

  const {
    created_at,
    name,
    noOfTiers,
    status,
    currency_value,
    points,
    owner_id,
    image_url,
  } = currentLoyalty;

  const is_owner = owner_id === user.id;

  // Content of the action column
  const actionsContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        key={1}
        icon={<EditOutlined />}
        onClick={toggleEditLoyaltyModal}
        disabled={!is_owner}
      >
        {strings.edit}
      </Button>
      <Button
        key={2}
        icon={<DeleteOutlined />}
        onClick={toggleDeleteLoyaltyModal}
        disabled={!is_owner}
      >
        {strings.delete}
      </Button>

      <Button key={4}>
        <Link to={`${pathname}/${currentLoyalty.id}`}>{strings.viewTier}</Link>
      </Button>
      <Button key={3}>
        <Link to={`${pathname}/${currentLoyalty.id}/enroll`}>
          {strings.enrollCustomers}
        </Link>
      </Button>
      {is_owner && (
        <Button
          key={89}
          onClick={() => setShowAddPartnerModal(true)}
          disabled={!is_owner}
        >
          Share Loyalty
        </Button>
      )}
      {!is_owner && (
        <Button
          key={85}
          loading={isOptOutPartnerLoading}
          onClick={() => confirmOptOutLoyalty()}
        >
          Opt Out
        </Button>
      )}

      {status && status.toLowerCase() === "inactive" ? (
        <Button
          key="activate"
          icon={<DeleteOutlined />}
          loading={isActivateLoyaltyLoading}
          disabled={!is_owner}
          onClick={() =>
            activateLoyalty({ variables: { id: currentLoyalty.id } })
          }
        >
          {strings.activate}
        </Button>
      ) : (
        <Button
          key="deactivate"
          icon={<DeleteOutlined />}
          loading={isDeactivateLoyaltyLoading}
          disabled={!is_owner}
          onClick={() =>
            deActivateLoyalty({ variables: { id: currentLoyalty.id } })
          }
        >
          {strings.deactivate}
        </Button>
      )}
    </div>
  );

  let color;
  status === "Inactive" ? (color = "volcano") : (color = "green");

  const ShareForm = props => {
    const [addPartnerToLoyalty, { loading: isAddPartnerLoading }] = useMutation(
      ADD_PARTNER_TO_LOYALTY,
      {
        onCompleted: data => {
          Modal.success({
            title: "Partner added successfully",
            onOk: () => closeModal(),
          });
        },
        onError: error => {
          message.error(error.message, 5);
        },
        refetchQueries: [{ query: LIST_LOYALTIES }],
      }
    );
    const handleAddPartner = async () => {
      try {
        const values = await form.validateFields();

        const email = values.partner_email;

        const res = await api.HttpClient.get(
          `${process.env.REACT_APP_API_BASE_URL}/merchants/email?data[email]=${email}`
        );

        await addPartnerToLoyalty({
          variables: {
            id: props.loyaltyId,
            partner_id: res.data.id,
          },
        }).catch(err => console.log(err));
      } catch (error) {
        if (error) {
          return;
        }
      }
    };
    const { form } = props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        title="Share Loyalty"
        visible={showAddPartnerModal}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            {strings.cancel}
          </Button>,
          <Button
            key="Add"
            loading={isAddPartnerLoading}
            type="primary"
            onClick={handleAddPartner}
          >
            {strings.add}
          </Button>,
        ]}
      >
        <Form layout="vertical" >
          <Form.Item label="Share Loyalty">
            {getFieldDecorator("partner_email", {
              rules: [
                {
                  required: true,
                  message: "Please enter partner email",
                },
                {
                  type: "email",
                  message: strings.pleaseEnterValidEmail,
                },
              ],
            })(<Input type="email" placeholder="Enter email" size="large" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const AddPartnersModal = Form.create()(ShareForm);
  return (
    <>
      <AddPartnersModal loyaltyId={currentLoyalty.id} />
      <Modal
        title={currentLoyalty.name}
        visible={visible}
        onCancel={closeModal}
        currentLoyalty={currentLoyalty}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            {strings.cancel}
          </Button>,
          <Popover
            key="actions"
            title={strings.actions}
            content={actionsContent}
          >
            <Button type="primary">{strings.actions}</Button>
          </Popover>,
        ]}
      >
        <div>
          <p>
            <strong>{strings.nameOfLoyalty}:</strong> {name}
          </p>

          <div>
            <p>
              <strong>{strings.loyaltyArtwork}:</strong>
            </p>

            <div style={{ marginBottom: "1rem" }}>
              <img src={image_url} alt={name} />
            </div>
          </div>

          <p>
            <strong>{strings.numberOfTiers}:</strong> {noOfTiers}
          </p>

          <p>
            <strong>{strings.amountToPointsRatio}:</strong> N{currency_value} -{" "}
            {points} point
          </p>
          <p>
            <strong>{strings.loyaltyStatus}: </strong>{" "}
            <Tag color={color}>{status}</Tag>
          </p>
          <p>
            <strong>{strings.createdOn}:</strong> {created_at}
          </p>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  products: state.product.allProducts,
  allCategories: state.category.allCategories,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
});

export default connect(mapStateToProps, {
  addNewProduct,
  getAllProducts,
  addNewCategory,
  getAllCategories,
  getAllLoyaltyPrograms,
})(LoyaltyDetailModal);
