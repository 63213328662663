import React from "react";
import { connect } from "react-redux";
import {
  Input,
  Modal,
  InputNumber,
  Select,
  Switch,
  Radio,
  message,
  Upload,
  Button,
  Image,
  Checkbox
} from "antd";
import { Form } from "@ant-design/compatible";
import { UploadOutlined } from "@ant-design/icons";
import api from "../../../../../api";
import { getAllLoyaltyPrograms } from "../../../../../redux/actions/loyaltyActions";
import strings from "../../../../../strings";

const FormItem = Form.Item;
const Option = Select.Option;

class AddLoyaltyProgramLocal extends React.Component {
  state = {
    loading: false,
    programTypeLabel: strings.spendingTarget,
    programTypeHint: strings.spendingTargetHint,
    programType: "simplepoints",
    reward_value: "",
    applyTo: "everyone",
    loyaltyIds: [],
    loyltyImage: [],
    images: [],
    loadingImg: false,
    apply_once: false
  };

  componentDidMount() {
    this.fetchLoyaltyId();
  }

  

  handleAddLoyaltyProgram = () => {
    const { form, toggleModal, getAllLoyaltyPrograms } = this.props;
    const { images, apply_once } = this.state;

    form.validateFields((err, values) => {
      if (err) return;

      this.setState({ loading: true });
      const payload = {
        ...values,
        apply_once: apply_once,
        images,
        eligibility_period:
          values.eligibility_period === -1
            ? values.custom_eligibility_period
            : values.eligibility_period,
      };

      api.loyalty
        .addNewLoyaltyProgram(payload)
        .then(res => {
          if (res && res.status === 200) {
            form.resetFields();
            Modal.success({
              title: strings.loyaltyProgramAdded,
              content: strings.loyaltyProgramAddedSuccessfully,
              onOk: () => getAllLoyaltyPrograms(),
            });
            toggleModal();
          }
        })
        .catch(err => {
          Modal.error({
            title: err?.response?.data?.error || err?.message
          })
          this.setState({ loading: false })
        });
    });
  };

  fetchLoyaltyId = () => {
    api.HttpClient.get("/customer_groups")
      .then(res => {
        if (res.status && res.status === 200) {
          const assignedIds = res.data.filter(id => id.status !== null);
          return this.setState({ loyaltyIds: assignedIds });
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  handleProgramTypeChange = value => {
    if (value.toLowerCase() === "simplepoints") {
      this.setState({
        programTypeLabel: strings.spendingTarget,
        programTypeHint: strings.spendingTargetHint1,
        programType: "simplepoints",
      });
    }

    if (value.toLowerCase() === "stampsprogram") {
      this.setState({
        programTypeLabel: "Stamps target",
        programTypeHint: strings.spendingTargetHint2,
        programType: "stampsprogram",
      });
    }
    if (value.toLowerCase() === "instantrewards") {
      this.setState({ programType: "InstantRewards" });
    }
  };

  // uploadProps = {
  //   name: "file",
  //   action: url,
  //   headers: {
  //     body: JSON.stringify({ upload_preset: "hyemnes2", folder: "test_img" }),
  //   },
  //   onChange(info) {
  //     if (info.file.status === "done") {
  //       console.log("info",info);
  //       message.success(`${info.file.name} ${strings.fileUploadSuccessful}`);
  //       // setstate({ ...state, imageUrl: info.file.response.imageCloudData.url });
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} ${strings.fileUploadFailed}`);
  //     }
  //   },
  // };

  handleProductPicture = async (files, fileLists) => {
    const maxFileSize = 4000000;
    const { images } = this.state;
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

    if (files.size > maxFileSize) {
      message.warning(strings.fileTooLargeLessThan4Mb);
      return;
    }

    if(images.length >= 3){
      message.warning("cannot add more than three images");
      return;
    }

    this.setState({ loadingImg: true })
    const formData = new FormData();
    formData.append("file", files)
    formData.append("upload_preset", uploadPreset)

    const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
    {
      method: "POST",
      body: formData
    })
    
    const parsed = await response.json();
    
    this.setState({ loadingImg: false })

    this.setState(state => {
      return { loyltyImage: [...state.loyltyImage, files] }
    });

    this.setState(state => {
      return  { images: [...state.images, parsed.secure_url] }
  
    });

    return false;
  };

  handleRemoveProductPicture = file => {
    const { images, loadingImg, loyltyImage } = this.state;
    const index = loyltyImage.indexOf(file);

    this.setState(state => {
      const newFileList = state.loyltyImage.slice();
      const newImageList = state.images.slice();
      newFileList.splice(index, 1);
      newImageList.splice(index, 1);
      return {
        loyltyImage: newFileList,
        images: newImageList,
        loadingImg: false,
      };
    });
  };

  render() {
    const { form, visible, toggleModal } = this.props;
    const { getFieldDecorator } = form;
    const { programTypeLabel, programTypeHint, loyltyImage, loadingImg, apply_once } = this.state;

    return (
      <Modal
        title={strings.addNewLoyaltyProgram}
        okText={strings.addLoyaltyProgram}
        cancelText={strings.cancel}
        onOk={this.handleAddLoyaltyProgram}
        onCancel={() => {
          Modal.confirm({
            title: strings.doYouWantToCloseDialog,
            onOk: toggleModal,
            onCancel: () => {},
          });
        }}
        destoryOnClose
        open={visible}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical">
          <FormItem label={strings.loyaltyProgramType}>
            {getFieldDecorator("program_type", {
              initialValue: "SimplePoints",
              rules: [
                {
                  required: true,
                  message: strings.pleaseSelectProgramType,
                },
              ],
            })(
              <Select size="large" onChange={this.handleProgramTypeChange}>
                <Option value="SimplePoints">SimplePoints</Option>
                <Option value="StampsProgram">StampsProgram</Option>
                <Option value="InstantRewards">InstantRewards</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label={strings.nameOfProgram}>
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterProgramName,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>
          {this.state.programType !== "InstantRewards" && (
            <FormItem label={programTypeLabel} extra={programTypeHint}>
              {getFieldDecorator("threshold", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseEnterLoyaltyThreshold,
                  },
                ],
              })(<InputNumber size="large" style={{ width: "100%" }} />)}
            </FormItem>
          )}
          {this.state.programType !== "InstantRewards" && (
            <FormItem
              label={strings.whatIsTheReward}
              extra={strings.egNextPurchaseIsFree}
            >
              {getFieldDecorator("reward", {
                rules: [
                  {
                    required: true,
                    message: strings.reward,
                  },
                ],
              })(<Input size="large" />)}
            </FormItem>
          )}
          {this.state.programType === "InstantRewards" && (
            <FormItem label={strings.whatIsTheReward}>
              {getFieldDecorator("reward", {
                initialValue: "discount",
                rules: [
                  {
                    required: true,
                    message: "Enter reward type",
                  },
                ],
              })(
                <Select size="large" onChange={(value) =>this.setState({ reward_value: value })}>
                  <Option value="discount">Discount</Option>
                  {this.props.isAirtimeRewardsActive && <Option value="airtime">
                    Airtime
                  </Option>}
                  {/* <Option disabled value="doublePoints">
                    Double points
                  </Option> */}
                </Select>
              )}
            </FormItem>
          )}
          {this.state.programType === "InstantRewards" && this.state.reward_value === "discount" && (
            <FormItem label="Discount Value" extra="Enter discount percentage">
              {getFieldDecorator("reward_value", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a discount value",
                  },
                ],
              })(<Input size="large" min={1} max={100} type="number" />)}
            </FormItem>
          )}
          {this.state.programType === "InstantRewards" && this.state.reward_value === "airtime" && (
            <FormItem label="Airtime Amount" extra="Enter Airtime amount">
              {getFieldDecorator("reward_value", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a airtime amount",
                  },
                ],
              })(<Input size="large" min={1} type="number" />)}
            </FormItem>
          )}

          {this.state.programType !== "InstantRewards" && (
          <FormItem
            label={"Images"}
            extra={"You can add up to three images"}
          >
            {getFieldDecorator("images", {
              rules: [
                {
                  required: false,
                },
              ],
            })(<>
              <Upload
                {...this.uploadProps}
                fileList={loyltyImage}
                beforeUpload={this.handleProductPicture}
                onRemove={this.handleRemoveProductPicture}
                multiple
                accept=".jpg, .png, .jpeg, .gif, .tiff, .tif, .svg"
              >
                <Button loading={loadingImg}>
                  <UploadOutlined /> Upload Image
                </Button>
              </Upload>
            </>)}
            {this.state.images && this.state.images.map((imag)=>{
              return <Image src={imag} alt={imag} height={150} width={150}/>
            })}
          </FormItem>
        )}

          {this.state.programType === "InstantRewards" && (
            <FormItem label="Frequency of Redemption ">
              {getFieldDecorator("eligibility_period", {
                rules: [
                  {
                    required: true,
                    message: "Please select a frequency",
                  },
                ],
              })(
                <Select
                  size="large"
                  onChange={val =>
                    val === -1 && this.setState({ showCustomDays: true })
                  }
                >
                  <Option value={null}>Select frequency</Option>
                  <Option value={0}>Infinite</Option>
                  <Option value={1}>Once daily</Option>
                  <Option value={2}>Once in 2 days</Option>
                  <Option value={3}>Once in 3 days</Option>
                  <Option value={7}>Once weekly</Option>
                  <Option value={30}>Once monthly</Option>
                  <Option value={-1}>Custom</Option>
                </Select>
              )}
            </FormItem>
          )}

          {this.state.showCustomDays && (
            <FormItem>
              <span>
                {" "}
                Once every
                {getFieldDecorator("custom_eligibility_period", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter frequency",
                    },
                  ],
                })(
                  <InputNumber style={{ margin: "0 10px" }} size="large" />
                )}{" "}
                days
              </span>
            </FormItem>
          )}

          {this.state.programType === "InstantRewards" && (
            <FormItem label="Apply Loyalty To">
              {getFieldDecorator("apply_to", {
                initialValue: this.state.applyTo,
                rules: [
                  {
                    required: true,
                    message:
                      "Confirm if loyalty sould be applied to all customers or some.",
                  },
                ],
              })(
                <Radio.Group
                  onChange={e => this.setState({ applyTo: e.target.value })}
                >
                  <Radio value="everyone"> Everyone</Radio>
                  <Radio value="set"> Set</Radio>
                </Radio.Group>
              )}
            </FormItem>
          )}

          {this.state.applyTo === "set" && (
            <FormItem label="Group IDs">
              {getFieldDecorator("group_ids")(
                <Select
                  allowClear
                  mode="multiple"
                  showSearch
                  placeholder="Select assigned IDs"
                  optionFilterProp="children"
                  size="large"
                  style={{ width: "80%" }}
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0 ||
                      (option.props.id && option.props.id.indexOf(input) >= 0)
                    );
                  }}
                  disabled={false}
                >
                  {this.state.loyaltyIds.map(loyaltyCode => (
                    <Option key={loyaltyCode.name} value={loyaltyCode.id}>
                      {loyaltyCode.name} ({loyaltyCode.customers.length}{" "}
                      customers)
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          )}
          {this.state.programType === "InstantRewards" && (
            <FormItem label="Verify with OTP">
              {getFieldDecorator("verify_otp", {
                rules: [
                  {
                    required: true,
                    message:
                      "Confirm if verification is needed before redeeming rewards",
                  },
                ],
              })(<Switch size="large" />)}
            </FormItem>
          )}
          {this.state.programType === "InstantRewards" && (
            <FormItem label="Activate Loyalty">
              {getFieldDecorator("active")(<Switch size="large" />)}
            </FormItem>
          )}
          <FormItem label={strings.creditOneStampPerVisit}>
            {getFieldDecorator("apply_once", {
              rules: [
                {
                  required: false,
                  message: strings.creditOneStampPerVisit,
                },
              ],
            })(<Checkbox  value={apply_once} onChange={(e)=>this.setState({ apply_once: e.target.checked })}/>)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  isAirtimeRewardsActive: state.appStore.isAirtimeRewardsActive
});

const AddLoyaltyProgram = Form.create()(AddLoyaltyProgramLocal);

export default connect(mapStateToProps, { getAllLoyaltyPrograms })(AddLoyaltyProgram);
