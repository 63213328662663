import React, { Component } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import {
  Button,
  Select,
  Input,
  Modal,
  DatePicker,
  Popover,
  Checkbox
} from "antd";
import { Icon } from "@ant-design/compatible";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import cloneArray from "../../utils/cloneArray";
import CartList from "../../containers/Sales/_partials/CartList";
import {
  handleInvoiceValues,
  computeLoyaltyPoints,
  computeLoyaltyStamps,
} from "../../containers/Sales/functions";
import { getCartAmount } from "../../containers/Dashboard/Layouts/Invoices/functions";
import numberFormatter from "../../utils/numberFormatter";
import SelectCustomer from "../../containers/Sales/_partials/CartFooter/SelectCustomer";
import AddProductToInvoiceModal from "../../containers/Dashboard/Layouts/Invoices/_partials/AddProductToInvoiceModal";
import {
  saveInvoice,
  sendInvoiceToCustomerAsMail,
  getDownloadableInvoiceLink,
  getPaymentMessage,
  editInvoice,
  sendReceipt,
  getDownloadableReceiptLink,
  getAllInvoices,
  addPaymentMessage,
} from "../../redux/actions/invoiceActions";
import { getAllLoyaltyPrograms } from "../../redux/actions/loyaltyActions";
import { sendNotification } from "../../redux/actions/notificationAction";
import openInNewTab from "../../utils/openInNewTab";
import banks from "../../banks.json";
import strings from "../../strings";
import { disabledInvoiceFieldEmails } from "../../containers/Sales/_partials/RecordSalePaymentModal/emails";

const Option = Select.Option;

const milisecondsToDaysConst = 86400000;

class InvoiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleInfo: false,
      paymentMethod: null,
      description: "",
      reference: "",
      openOptionModal: false,
      recordingInvoice: false,
      printInvoice: false,
      invoiceURl: "",
      dueDate: moment().format("L"),
      currentDate: moment().format("L"),
      recordingSaveAndSendInvoice: false,
      selectedCustomer: null,
      cartList: [],
      customerValue: null,
      type: null,
      invoiceId: null,
      deletingInvoice: false,
      showAddProductToInvoiceModal: false,
      customerBankUssdCode: "",
      amount_paid: null,
      has_product_decreased: false,
      has_product_decreased_edit: false
    };
  }

  handleInvoiceValues = handleInvoiceValues.bind(this);
  getCartAmount = getCartAmount.bind(this);

  componentDidMount() {
    const {
      getPaymentMessage,
      invoice,
      type,
      selectedCustomer,
      cartList,
      customerValue,
      invoiceId,
    } = this.props;

    

    if (invoice?.payment_reference) {
      this.setState({ reference: invoice.payment_message });
    }

    if(invoice?.has_product_decreased){
      this.setState({
        has_product_decreased: invoice.has_product_decreased, 
        has_product_decreased_edit: invoice.has_product_decreased 
      })
    }

    if (invoice?.payment_message) {
      this.setState({
        description: invoice.payment_message,
      });
    } else {
      if (type === "create") {
        getPaymentMessage();
      }
    }

    if(type === "edit" && invoice.invoice_payment_histories.length > 0){
      const totalPaid = invoice.invoice_payment_histories.reduce((prev, curr)=>{
        return prev + Number(curr.paid_amount)
      }, 0);

      this.setState({ amount_paid: totalPaid })
    }else{
      this.setState({ amount_paid: 0 })
    }

    if (type === "edit") {
      this.setState({
        amountPaid: null,
        dueDate: invoice.due_date ? invoice.due_date : moment().format("L"),
        currentDate: invoice.created_at ? invoice.created_at : moment(invoice.created_at),
        status: invoice.status ? invoice.status : "",
        paymentMethod: invoice.payment_method ? invoice.payment_method : null,
        reference: invoice.payment_reference,
      });

      this.handlePrintInvoice(invoice.id);
      
    }
    this.setState({
      selectedCustomer,
      cartList,
      customerValue,
      type,
      invoiceId,
    });
    this.props.getAllLoyaltyPrograms();
  }

  componentWillReceiveProps(nextProps) {
    const {
      paymentMessage,
      selectedCustomer,
      cartList,
      customerValue,
      invoiceId,
      type,
    } = nextProps;
    if (
      paymentMessage &&
      paymentMessage.trim().length !== 0 &&
      paymentMessage !== this.state.paymentMessage
    ) {
      this.setState({
        description: nextProps.paymentMessage,
      });
    }
    if (cartList.length > 0) {
      this.setState({
        selectedCustomer,
        cartList,
        customerValue,
        invoiceId,
        type,
      });
    }
  }

  onStatusChange = (value) => {
    // if(value === 'ussd') {
    //   checkIfCustomerHasBankCode()
    // }

    this.setState({
      paymentMethod: value,
    });
  };

  onReferenceChange = (e) => {
    this.setState({
      reference: e.target.value,
    });
  };

  toggleOptionModal = () => {
    this.setState({
      openOptionModal: !this.state.openOptionModal,
    });
  };

  getStatus = (hasDiscount, discountAmount) => {
    let cartAmount = parseFloat(this.getCartAmount());

    if (hasDiscount) {
      cartAmount = cartAmount - discountAmount;
    }

    let { amountPaid } = this.state;

    amountPaid = parseFloat(amountPaid) || 0;

    if (this.props.invoice) {
      const paidAmount = parseFloat(this.props.invoice.paid_amount) || 0;
      amountPaid += paidAmount;
    }
    return !amountPaid || amountPaid === 0
      ? "unpaid"
      : amountPaid === cartAmount
      ? "paid"
      : amountPaid < cartAmount
      ? "partial"
      : "paid";
  };

  handleSaveInvoice = (sendOption) => {
    const {
      location,
      customer,
      allLoyaltyPrograms,
      paymentMessage,
      addPaymentMessage,
      hasDiscount,
      discountAmount,
      clearDiscount,
      user,
      fetchInvoices
    } = this.props;

    const {
      description,
      paymentMethod,
      amountPaid,
      dueDate,
      selectedCustomer,
      cartList,
      customerValue,
      type,
      invoiceId,
      reference,
      currentDate, 
      amount_paid,
      has_product_decreased,
      has_product_decreased_edit
    } = this.state;

    // console.log("cartList",cartList);

    const invoicePaidAmount = this.props.invoice
      ? this.props.invoice.paid_amount
        ? this.props.invoice.paid_amount
        : amount_paid
      : 0;

    const amountDue = (
      parseFloat(this.getCartAmount()) - invoicePaidAmount
    ).toFixed(2);

    

    if (selectedCustomer && cartList.length > 0) {
      const parsedCartList = cloneArray(cartList);

      parsedCartList.forEach((cartItem) => {
        let loyaltyInfo = allLoyaltyPrograms.filter(
          (loyaltyProgram) =>
            cartItem.merchant_loyalty_program_id === loyaltyProgram.id
        );
        cartItem.program_type = loyaltyInfo.length
          ? loyaltyInfo[0].program_type
          : null;
        cartItem.points = computeLoyaltyPoints.apply(this, [
          cartItem,
          allLoyaltyPrograms,
        ]);
        cartItem.stamps = computeLoyaltyStamps.apply(this, [
          cartItem,
          allLoyaltyPrograms,
        ]);
        cartItem.user_id = customerValue;
      });

      if (type === "create") {
        let payload;

        if (paymentMethod === "ussd") {
          payload = {
            user_id: customerValue,
            transactions: parsedCartList,
            payment_method: paymentMethod,
            status: this.getStatus(hasDiscount, discountAmount),
            payment_message: description,
            due_date: dueDate,
            due_amount: amountDue,
            created_at: currentDate,
            send_notification: false,
            has_discount: hasDiscount,
            business_branch_id: user.business ? user.business.id : "",
            staff_id: user.id,
            payment_reference: reference,
            discount_amount:
              discountAmount && discountAmount > 0 ? discountAmount : null,
            has_product_decreased
          };
        } else {
          payload = {
            user_id: customerValue,
            transactions: parsedCartList,
            payment_method: paymentMethod,
            status: this.getStatus(hasDiscount, discountAmount),
            payment_message: description,
            due_date: dueDate,
            created_at: currentDate,
            paid_amount: amountPaid,
            business_branch_id: user.business_branch
              ? user.business_branch.id
              : "",
            staff_id: user.id,
            send_notification: false,
            has_discount: hasDiscount,
            payment_reference: reference,
            discount_amount:
              discountAmount && discountAmount > 0 ? discountAmount : null,
            has_product_decreased
          };
        }

        this.props
          .saveInvoice(payload)
          .then(async ({ data }) => {
            if (!paymentMessage) {
              addPaymentMessage(description);
            }

            // Delete Bill if Bill is in Draft Sales
            if (location && location.state && location.state.fromDraftSales) {
              const draftSalesList = JSON.parse(
                localStorage.getItem("draftSales")
              );

              const filteredDraftSales =
                draftSalesList.length &&
                draftSalesList.filter(
                  (bill) => bill.id !== location.state.bill_id
                );

              localStorage.setItem(
                "draftSales",
                JSON.stringify(filteredDraftSales)
              );

              // console.log("Draft Sales Has been deleted");
            }

            // CLear Discount
            hasDiscount && clearDiscount();

            if (!sendOption) {
              this.setState({
                recordingInvoice: false,
              });
              Modal.success({
                title: "Invoice saved!",
                content:
                  this.props.user.role?.id === 3 ? null : (
                    <a
                      style={{ textDecoration: "underline" }}
                      href="/d/transactions/invoices"
                    >
                      View Invoice
                    </a>
                  ),
                onOk: () => {
                  this.closeModal();
                  this.props.handleLoading && this.props.handleLoading(true);
                  this.props.getAllInvoices();
                  this.setState({
                    recordingInvoice: false,
                    paymentMessage: "",
                    reference: "",
                  });
                },
              });
            } else {
              if (paymentMethod === "ussd" && customer && customer.id) {
                // console.log("Sending Notification");
                await this.props.sendNotification({
                  data: {
                    customer_id: customer.id,
                    invoice_id: data.id,
                  },
                });
              }

              setTimeout(() => {
                this.handleSendInvoice(
                  sendOption,
                  data.id,
                  data.number,
                  data.customer.phone_number
                );
              }, 5000);
            }
          })
          .catch(() => {
            this.setState({
              recordingInvoice: false,
              recordingSaveAndSendInvoice: false,
            });
          });
      } else {

        if (amountPaid ===null && paymentMethod === null) {
          Modal.error({
            title: "Please select a Payment Method and input amount paid!",
          });
          return this.setState({
            recordingInvoice: false,
            recordingSaveAndSendInvoice: false,
          });
        }

        if (amountPaid && paymentMethod === null) {
          Modal.error({
            title: "Please select a Payment Method!",
          });
          return this.setState({
            recordingInvoice: false,
            recordingSaveAndSendInvoice: false,
          });
        }

        if (amountPaid && paymentMethod === "") {
          Modal.error({
            title: "Please select a Payment Method!",
          });
          return this.setState({
            recordingInvoice: false,
            recordingSaveAndSendInvoice: false,
          });
        } 

        if (paymentMethod && amountPaid === null) {
          Modal.error({
            title: "Please input amount paid!",
          });
          return this.setState({
            recordingInvoice: false,
            recordingSaveAndSendInvoice: false,
          });
        }

        if (paymentMethod && amountPaid === "") {
          Modal.error({
            title: "Please input amount paid!",
          });
          return this.setState({
            recordingInvoice: false,
            recordingSaveAndSendInvoice: false,
          });
        } else {
          let payload;

          if (paymentMethod === "mobile") {
            payload = {
              invoiceId,
              user_id: customerValue,
              items: parsedCartList,
              status: this.getStatus(hasDiscount, discountAmount),
              payment_method: paymentMethod,
              payment_message: description,
              due_date: dueDate,
              created_at: currentDate,
              due_amount: amountDue,
              business_branch_id: this.props?.invoice.business_branch_id || "",
              staff_id: this.props?.invoice.staff_id || "",
              payment_reference:
                reference || this.props?.invoice.payment_reference,
              has_discount: hasDiscount,
              discount_amount:
                discountAmount && discountAmount > 0 ? discountAmount : null,
              has_product_decreased: has_product_decreased_edit
            };
          } else {
            payload = {
              id: invoiceId,
              invoiceId,
              user_id: customerValue,
              items: parsedCartList,
              status: this.getStatus(hasDiscount, discountAmount),
              payment_method: paymentMethod,
              payment_message: description,
              due_date: dueDate,
              created_at: currentDate,
              paid_amount: amountPaid,
              has_discount: hasDiscount,
              business_branch_id: this.props?.invoice.business_branch_id || "",
              staff_id: this.props?.invoice.staff_id || "",
              payment_reference:
                reference || this.props.invoice?.payment_reference,
              discount_amount:
                discountAmount && discountAmount > 0 ? discountAmount : null,
              has_product_decreased: has_product_decreased_edit
            };
          }

          this.props
            .editInvoice(payload)
            .then(async ({ data }) => {
              if (!sendOption) {
                Modal.success({
                  title: "Invoice saved!",
                  onOk: () => {
                    this.closeModal();
                    this.props.getAllInvoices();
                    this.props.handleLoading(true);
                    fetchInvoices && fetchInvoices()
                  },
                });
                this.setState({
                  recordingInvoice: false,
                  paymentMessage: "",
                  reference: "",
                });
              } else {
                if (paymentMethod === "ussd" && customer && customer.id) {
                  // console.log("Sending Notification");
                  await this.props.sendNotification({
                    data: {
                      customer_id: customer.id,
                      invoice_id: data.id,
                    },
                  });
                }

                setTimeout(() => {
                  this.handleSendInvoice(
                    sendOption,
                    data.id,
                    data.number,
                    data.customer.phone_number
                  );
                }, 5000);
              }
            })
            .catch(() => {
              this.setState({
                recordingInvoice: false,
                recordingSaveAndSendInvoice: false,
              });
            });
        }
      }
    } else {
      if (!selectedCustomer) {
        Modal.error({
          title: "Please select a Customer!",
        });
      } else {
        Modal.error({
          title:
            "Cart List can not be empty, close the invoice and add Item to cart!",
        });
      }
      this.setState({
        recordingInvoice: false,
        recordingSaveAndSendInvoice: false,
      });
    }
  };

  closeModal = () => {
    if (this.props.type === "edit") {
      this.props.handleLoading(true);
      this.props.getAllInvoices();
    }

    // If Merchant Account's Expired, Show Renewal Modal
    const typesObj = {
      ngBasic: "basic-pay",
      ngPro: "pro-pay",
      ghBasic: "basic-pay-gh",
      ghPro: "pro-pay-gh",
      intBasic: "basic-pay-int",
      intPro: "pro-pay-int",
    };

    const business = this.props.user.employer
      ? { ...this.props.user.employer }
      : { ...this.props.user };

    if (business.subscription_plan === "Free") return null;

    const country =
      business.currency === "NGN"
        ? "ng"
        : business.currency === "GHS"
        ? "gh"
        : "int";

    const subPaymentType = typesObj[country + business.subscription_plan];

    const daysLeft = Math.ceil(
      (new Date(business.subscription_expires_on) - Date.now()) /
        milisecondsToDaysConst
    );

    let secondsToGo = 60;

    const modal =
      daysLeft <= 0 &&
      Modal.success({
        title: `Hold On ${secondsToGo}`,
        content: (
          <span>
            Your subscription has expired and your account is now limited. Click
            on the Button below to renew your account
          </span>
        ),
        okText: (
          <a
            href={`https://loystar.co/loystar-${subPaymentType}`}
            target="new"
            rel="noopener"
          >
            Renew
          </a>
        ),
      });

    const timer =
      daysLeft <= 0 &&
      setInterval(() => {
        secondsToGo--;
        modal.update({
          title: `Hold On ${secondsToGo}`,
        });
      }, 1000);

    daysLeft <= 0 &&
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);

    this.props.toggleShowCashForm(false, "modal-cancel");
    this.props.handleSaveInvoiceDone();
    this.props.onCancel();
    this.setState({
      invoice: null,
      amountPaid: null,
      dueDate: `${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}`,
      status: null,
      paymentMethod: null,
    });
  };

  dueDateOnChange = (date, dateString) => {
    this.setState({
      dueDate: dateString,
    });
  };

  currentDateOnChange = (date, dateString) => {
    // console.log(`${new Date(dateString).getFullYear()}/${new Date(dateString).getMonth()}/${new Date(dateString).getDate()}`)
    this.setState({
      currentDate: dateString,
    });
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleVisibleInfoChange = (visibleInfo) => {
    this.setState({ visibleInfo });
  };

  handleVisibleInfoContent = () => {
    const paymentHistory = this.props.invoice.invoice_payment_histories;
    return (
      <div
        className="popover-content info-content"
        style={{ display: "flex", flexDirection: "column" }}
        onClick={this.hide}
      >
        <div
          style={{
            fontSize: "12px",
            width: "150px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #ccc",
          }}
        >
          <span>Amount Paid</span>
          <span style={{ position: "relative" }}>
            Date Paid
            <span
              style={{
                position: "absolute",
                right: "-12px",
                top: "-12px",
                cursor: "pointer",
              }}
              onClick={() => {
                this.handleVisibleInfoChange(false);
              }}
            >
              <Icon type="close" />
            </span>
          </span>
        </div>
        {paymentHistory.map((item) => {
          return (
            <div
              key={item.id}
              style={{
                fontSize: "12px",
                width: "150px",
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #ccc",
              }}
            >
              <span>
                {this.props.user.currency || ""}
                {item.paid_amount}
              </span>
              <span>{item.paid_at.split("T")[0]}</span>
            </div>
          );
        })}
      </div>
    );
  };

  handlePrint = () => {
    const { invoiceURl } = this.state;
    openInNewTab(`https://${invoiceURl}`, false);
    Modal.success({
      title: "Downloading Invoice",
      onOk: this.closeModal,
    });
  }

  handlePrintInvoice = (id) => {
    this.props
        .getDownloadableInvoiceLink(id)
        .then((response) => {

          // openInNewTab(`https://${response.data.message}`, false);
          // Modal.success({
          //   title: "Downloading Invoice",
          //   onOk: this.closeModal,
          // });
          this.setState({
            invoiceURl: response.data.message,
            printInvoice: true,
          });
        })
        .catch((err) => {
          console.log(err)
          // Modal.info({
          //   title:
          //     "Invoice created successfully but PDF Generation is taking longer than usual. Please check back shortly.",
          //   onOk: this.closeModal,
          // });
          this.setState({
            printInvoice: false,
          });
      });    
  };

  handleSendInvoice = (option, id, number, phone) => {
    const customerPhone = phone[0] === "+" ? phone.slice(1) : phone;

    const businessName =
      this.props.user.business_name || this.props.user.employer.business_name;

    const email = this.props.customer ? this.props.customer.email : "null";

    if (option === "email") {
      this.props
        .sendInvoiceToCustomerAsMail(id)
        .then(() => {
          Modal.success({
            title: `Invoice has been sent to customer's email (${email}).`,
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        })
        .catch(() => {
          Modal.error({
            title:
              "Invoice created successfully but an error occured while sending as e-mail. Please try again from the invoice list",
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        });
    } else if (option === "whatsapp") {
      this.props
        .getDownloadableInvoiceLink(id)
        .then((response) => {
          const text = `Hello ${
            this.state.selectedCustomer
          }, please download invoice ${number} from ${businessName.toUpperCase()}: https://${
            response.data.message
          }`;

          openInNewTab(
            `
            http://api.whatsapp.com/send?phone=${customerPhone}&text=${text}`,
            true
          );

          Modal.success({
            title: "Invoice forwarded to WhatsApp",
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        })
        .catch(() => {
          Modal.info({
            title:
              "Invoice created successfully but an error occured sending to WhatsApp. Try again on the invoice list",
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        });
    } else {
      // where i should include jsPDF file

      this.props
        .getDownloadableInvoiceLink(id)
        .then((response) => {
          openInNewTab(`https://${response.data.message}`, false);
          Modal.success({
            title: "Downloading Invoice",
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        })
        .catch(() => {
          Modal.info({
            title:
              "Invoice created successfully but PDF Generation is taking longer than usual. Please check back shortly.",
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        });
    }
  };

  handleSendReceipt = (option, id, number, phone) => {
    const customerPhone = phone[0] === "+" ? phone.slice(1) : phone;
    const email = this.props.customer ? this.props.customer.email : "null";

    if (option === "email") {
      this.props
        .sendReceipt(id)
        .then(() => {
          Modal.success({
            title: `Receipt has been sent to customer's email (${email}).`,
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        })
        .catch(() => {
          Modal.error({
            title:
              "An error occured sending the receipt via e-mail, please try again or contact support",
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        });
    } else if (option === "whatsapp") {
      this.props
        .getDownloadableReceiptLink(id)
        .then((response) => {
          openInNewTab(
            `
            http://api.whatsapp.com/send?phone=${customerPhone}&text=Thank you for your patronage ${this.state.selectedCustomer}. Here is your payment receipt: https://${response.data.message}`,
            true
          );
          Modal.success({
            title: "Receipt forwarded to WhatsApp",
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        })
        .catch(() => {
          Modal.error({
            title:
              "An error occured sending the receipt via WhatsApp, Please try again.",
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        });
    } else {
      // where i should include jsPDF file

      this.props
        .getDownloadableReceiptLink(id)
        .then((response) => {
          openInNewTab(`https://${response.data.message}`, false);
          Modal.success({
            title: "Downloading Invoice",
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        })
        .catch(() => {
          Modal.info({
            title:
              "Invoice created successfully but PDF Generation is taking longer than usual. Please check back shortly.",
            onOk: this.closeModal,
          });
          this.setState({
            recordingSaveAndSendInvoice: false,
          });
        });
    }
  };

  switchInvoiceReceipt = (type) => {
    const { invoice } = this.props;

    this.setState({
      recordingSaveAndSendInvoice: true,
    });
    if (
      invoice === undefined ||
      (invoice !== undefined && invoice.status !== "paid")
    ) {
      this.handleSaveInvoice(type);
    } else {
      this.handleSendReceipt(
        type,
        invoice.id,
        invoice.number,
        invoice.customer.phone_number
      );
    }
  };

  closeAddProductToInvoiceModal = () => {
    this.setState({
      showAddProductToInvoiceModal: false,
    });
  };

  saveBankUssdCode = (val) => {
    const bankCode = banks.find((bank) => bank.name === val);

    this.setState({ customerBankUssdCode: bankCode.code });
  };

  render() {
    const {
      selectedCustomer,
      addProductToCart,
      removeItemFromCart,
      reduceItemFromCart,
      handleShowBulkQuantityModal,
      toggleShowInvoicePrompt,
      user,
      invoicePrompt,
      visible,
      toggleShowCashForm,
      onCancel,
      customerValue,
      onCustomerSearch,
      customers,
      cleanupSelectCustomerDropdown,
      type,
      customer,
      invoice,
      payment_reference,
      toggleDeleteInvoiceModal,
      addNewProductToCart,
      hasDiscount,
      discountAmount,
    } = this.props;

    // const { id } = invoice;

    const {
      // description,
      paymentMethod,
      recordingInvoice,
      printInvoice,
      amountPaid,
      dueDate,
      recordingSaveAndSendInvoice,
      deletingInvoice,
      showAddProductToInvoiceModal,
      cartList,
      currentDate,
      amount_paid,
      has_product_decreased,
      has_product_decreased_edit
    } = this.state;

    const isBankUssdCodePresent = customer && customer.bank_ussd_code;

    const bankUssdCode =
      isBankUssdCodePresent &&
      banks.find((bank) => bank.short === customer.bank_ussd_code);

    const isAdmin = user.role_id && user.role_id === 1;

    const isAdminIsManager =
      user.role_id === 1 || (user.role && user.role.id === 2);

    const isAdminIsManagerIsCashier =
      user.role_id === 1 ||
      (user.role && user.role.id === 2) ||
      (user.role && user.role.id === 3);

    const isUserBlackListed = disabledInvoiceFieldEmails.includes(user.email);

    const content = (
      <div
        className="popover-content"
        style={{ display: "flex", flexDirection: "column" }}
        onClick={this.hide}
      >
        <Button
          style={{
            textAlign: "left",
            width: "100% !important",
            border: "0",
            boxShadow: "0 0 0 0",
          }}
          onClick={() => {
            this.switchInvoiceReceipt("email");
          }}
        >
          <Icon type="mail" />
          {strings.sendViaEmail}
        </Button>
      </div>
    );

    const isCommission =
      user.commission === null ||
      (user.employer && user.employer.commission === null);

    const invoicePaidAmount = this.props.invoice
      ? this.props.invoice.paid_amount
        ? this.props.invoice.paid_amount
        : amount_paid
      : 0;

    const totalAmountToBePaid =
      hasDiscount && discountAmount
        ? this.getCartAmount() - discountAmount
        : this.getCartAmount();

    const amountLeft = this.props.invoice ? Number(this.props.invoice.total_after_discount) - invoicePaidAmount : totalAmountToBePaid;
    // console.log(amountLeft);

    const amountDue = this.props.invoice
      ? this.props.invoice.due_amount
        ? this.props.invoice.due_amount
        : amountLeft
      : amountLeft; 

      function convertDate(date){
        let dat = new Date(date).toLocaleDateString("en-uk", {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric"
        });
        return dat;
    } 

    // console.log(convertDate(currentDate));
    // const amountDue =
    //   this.props.invoice &&
    //   (this.props.invoice.amountDue.toFixed(2) ||
    //     (parseFloat(this.getCartAmount()) - invoicePaidAmount).toFixed(2));
    // const amountDue = (
    //   parseFloat(this.getCartAmount()) - invoicePaidAmount
    // ).toFixed(2);

    const styles = StyleSheet.create({
      page: {
        backgroundColor: "#E4E4E4",
      },
      section: {
        margin: 10,
        padding: 10,
        lineHeight: "1.5px",
        flexGrow: 1,
      },
      title: {
        fontWeight: "heavy",
        fontSize: "12px",
      },
      text: {
        fontSize: "12px",
      },
    });

    const isInvoicePaid = invoice?.status === "paid";

    // Create Document Component
    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
              borderBottom: "1px solid gray",
            }}
          >
            <View
              style={{
                ...styles.section,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                {user.image && <image src={user.image} alt="logo" />}
              </View>
              <View>
                <Text style={styles.title}> {invoice.business_name}</Text>
                <Text style={styles.text}>{user.address_line1}</Text>
                <Text style={styles.text}>
                  {user.address_line2}, {user.state}
                </Text>
                <Text style={styles.text}>{user.contact_number}</Text>
                <Text style={styles.text}>{user.email}</Text>
              </View>
            </View>
            <View style={{ ...styles.section, marginLeft: 30 }}>
              <Text style={styles.title}>
                INVOICE: {`${invoice.number || ""}`}
              </Text>
              <Text style={{ ...styles.title, marginTop: "5px" }}>
                DATE: {`${convertDate(currentDate) || ""}`}
              </Text>
              <Text style={{ ...styles.title, marginTop: "5px" }}>
                BALANCE {isInvoicePaid ? "PAID" : "DUE"}:
                {`${user.currency ? user.currency : ""}${numberFormatter(
                  amountLeft
                )}`}
              </Text>
            </View>
          </View>

          <View
            style={{
              padding: 10,
              borderBottom: "1px solid gray",
              paddingLeft: 30,
            }}
          >
            <Text style={{ ...styles.text, marginBottom: 5 }}>INVOICE TO:</Text>
            <Text style={{ ...styles.text, marginBottom: 5 }}>
              {invoice.customer?.first_name} {invoice.customer.last_name || ""}
            </Text>
            <Text style={{ ...styles.text, marginBottom: 5 }}>
              {invoice.customer.email || ""}
            </Text>
            <Text style={{ ...styles.text, marginBottom: 5 }}>
              {invoice.customer.address_line1 || ""}
            </Text>
            <Text style={{ ...styles.text, marginBottom: 5 }}>
              {invoice.customer.address_line2 || ""}
            </Text>
            <Text style={{ ...styles.text, marginBottom: 5 }}>
              {invoice.customer.phone_number}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid gray",
              marginTop: "20px",
              padding: 10,
              paddingLeft: 20,
            }}
          >
            <Text style={{ width: "80%", fontSize: "12px" }}>PRODUCT NAME</Text>
            <Text style={{ width: "20%", fontSize: "12px" }}>RATE</Text>
            <Text style={{ width: "10%", fontSize: "12px" }}>QTY</Text>
            <Text style={{ width: "20%", fontSize: "12px" }}>AMOUNT</Text>
          </View>

          {cartList.length &&
            cartList.map((item) => (
              <View
                style={{
                  display: "flex",
                  borderBottom: "1px solid gray",
                  flexDirection: "row",
                  padding: 10,
                  paddingLeft: 20,
                }}
              >
                <View style={{ width: "80%" }}>
                  <Text style={styles.text}>{item.name || "N/A"}</Text>
                </View>
                <View style={{ width: "20%" }}>
                  <Text style={styles.text}>{item.price}</Text>
                </View>
                <View style={{ width: "10%" }}>
                  <Text style={styles.text}>{item.quantity}</Text>
                </View>
                <View style={{ width: "20%" }}>
                  <Text style={styles.text}>{item.amount}</Text>
                </View>
              </View>
            ))}

          <View style={{ marginLeft: "60%", marginTop: 10 }}>
            <Text style={{ marginRight: "40%", fontSize: "12px" }}>
              Total:{" "}
              {`${user.currency ? user.currency : ""}${numberFormatter(
                totalAmountToBePaid
              )}`}
            </Text>

            <Text style={{ marginTop: 10, fontSize: "12px", fontWeight: 700 }}>
              BALANCE {isInvoicePaid ? "PAID" : "DUE"}:{" "}
              {`${user.currency ? user.currency : ""}${numberFormatter(
                amountLeft
              )}`}
            </Text>
          </View>

          <Text style={{ margin: 30, fontSize: "12px" }}>
            Payment Message: {invoice.payment_message}
          </Text>

          {!isInvoicePaid && (
            <View style={{ marginTop: "30px", marginLeft: 30 }}>
              <Text style={{ ...styles.text, marginBottom: 10 }}>
                Payment Info{" "}
              </Text>
              <Text style={{ ...styles.text, marginBottom: 5 }}>
                Kindly pay into:
              </Text>
              <Text style={{ ...styles.text, marginBottom: 5 }}>
                {user.bank_account_number}
              </Text>
              <Text style={{ ...styles.text, marginBottom: 5 }}>
                {user.bank_name}
              </Text>
            </View>
          )}
        </Page>
      </Document>
    );

    const pdfLink = invoice && (
      <PDFDownloadLink
        document={<MyDocument />}
        fileName={`${invoice.number}.pdf`}
      >
        <Button type="primary" style={{  marginLeft: "20px" }}>
          Download {invoice.status === "paid" ? "Receipt" : "Invoice"}
        </Button>
      </PDFDownloadLink>
    );

    return (
      <>
        <Modal
          title={`${strings.invoice}: ${
            user.currency ? user.currency : ""
          }${numberFormatter(totalAmountToBePaid)}
        ${
          hasDiscount && discountAmount
            ? `(${user.currency}${discountAmount} ${strings.wasDeducted})`
            : ""
        }
        ${
          invoicePrompt
            ? selectedCustomer
              ? `${strings.for} ${selectedCustomer}`
              : ""
            : ""
        }`}
          open={visible}
          onCancel={() => {
            Modal.confirm({
              title:
                invoice && invoice.status === strings.paid
                  ? strings.closeInvoice
                  : strings.closeInvoiceConfirmation,
              okText: strings.yes,
              cancelText: strings.no,
              onOk: () => {
                toggleShowCashForm(false, "modal-cancel");
                this.setState({ reference: "" });
                onCancel();
              },
            });
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                Modal.confirm({
                  title:
                    invoice && invoice.status === "paid"
                      ? strings.closeInvoice
                      : strings.closeInvoiceConfirmation,
                  okText: strings.yes,
                  cancelText: strings.no,
                  onOk: () => {
                    if (type === "create") {
                      return toggleShowInvoicePrompt(false);
                    }
                    onCancel();
                  },
                });
              }}
            >
              Cancel
            </Button>,
          ]}
          destroyOnClose
        >
          <div id="pay">
            <div className="invoice">
              {type === "edit" && <div
                style={{display: "flex", alignItems: "center", gap: "5px"}}
              >
                <b>Invoice ID: </b>
                <p style={{margin: "0"}}>{this.props.invoice.number}</p>
              </div>}
              <p className="items-title">Select Customer</p>
              <SelectCustomer
                customerValue={customerValue}
                onCustomerSearch={onCustomerSearch}
                customers={customers}
                cleanupSelectCustomerDropdown={cleanupSelectCustomerDropdown}
                customerName={selectedCustomer}
                type={type}
                isDisabled={
                  this.props.invoice && this.props.invoice.status !== "unpaid"
                    ? true
                    : false
                }
              />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "5px",
                }}
              >
                <p className="items-title">
                  {strings.item}
                  {cartList.length > 1 ? `s` : ""}
                </p>
                {type === "edit" && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.setState({
                        showAddProductToInvoiceModal: true,
                      });
                    }}
                    disabled={invoice && invoice.status === "paid"}
                  >
                    {strings.addProduct}
                  </Button>
                )}
              </div>

              <div className="cart">
                {cartList.length === 0 && (
                  <span style={{ paddingLeft: "5px" }}>Cart Empty</span>
                )}
                <CartList
                  data={cartList}
                  increaseItemQuantity={invoice && invoice.status === "paid" ? null : addProductToCart}
                  removeItemFromCart={invoice && invoice.status === "paid" ? null : removeItemFromCart}
                  reduceItemFromCart={invoice && invoice.status === "paid" ? null : reduceItemFromCart}
                  handleShowBulkModal={handleShowBulkQuantityModal}
                />
              </div>

              {cartList.length !== 0 && (
                <span
                  style={{
                    marginTop: "10px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                  }}
                >
                  {payment_reference && (
                    <span className="payment_reference">
                      <b>
                        {strings.paymentReference}: {payment_reference}
                      </b>
                    </span>
                  )}
                  <span>
                    <b style={{ margin: "0 20px" }}>{strings.amountPaid}:</b>
                    <b>
                      {user.currency || ""}
                      {invoicePaidAmount}
                    </b>
                    {type === "edit" &&
                      invoice &&
                      invoice.invoice_payment_histories.length > 0 && (
                        <Popover
                          content={this.handleVisibleInfoContent()}
                          trigger="click"
                          visible={this.state.visibleInfo}
                          onVisibleChange={this.handleVisibleInfoChange}
                          className="payment-history"
                        >
                          <Icon type="info-circle" />
                        </Popover>
                      )}
                  </span>
                  {hasDiscount && discountAmount && (
                    <span>
                      <b style={{ margin: "0 20px" }}>
                        {strings.discountAmount}:
                      </b>
                      <b>
                        {user.currency || ""}
                        {numberFormatter(discountAmount)}
                      </b>
                    </span>
                  )}
                  <span>
                    <b style={{ margin: "0 20px" }}>{strings.amountDue}:</b>
                    <b>
                      {user.currency || ""}
                      {numberFormatter(amountDue)}
                    </b>
                  </span>
                </span>
              )}

              <div style={{ display: "flex" }}>
                {isAdminIsManagerIsCashier && !isUserBlackListed && (
                  <div className="status-wrapper">
                    <p>Amount Paid</p>
                    <Input
                      placeholder={strings.amount}
                      name="amountPaid"
                      onChange={this.handleInvoiceValues}
                      value={amountPaid}
                      size="large"
                      type="number"
                      disabled={
                        paymentMethod === "ussd" || invoice?.status === "paid"
                      }
                    />
                  </div>
                )}

                {/* <div className="status-wrapper">
                  <p>{strings.dueDate}</p>
                  <DatePicker
                    defaultValue={moment(dueDate)}
                    disabled={true}
                    size="large"
                    onChange={this.dueDateOnChange}
                    style={{ width: "100%" }}
                  />
                </div> */}
                {isAdminIsManager &&<div className="status-wrapper">
                  <p>{strings.backDate}</p>
                  <DatePicker
                    defaultValue={type === "edit"? moment(invoice.created_at) : moment(currentDate)}
                    // value={currentDate}  
                    disabled={invoice?.status === "paid"}
                    // disabled={true}
                    size="large"
                    onChange={this.currentDateOnChange}
                    style={{ width: "100%" }}
                  />
                </div>
              }
              </div>

              
              {isAdminIsManagerIsCashier && !isUserBlackListed && (
                <div className="input-holder">
                  <div className="status-wrapper">
                    <p>{strings.paymentType}</p>
                    <Select
                      size="large"
                      className="status-select"
                      onChange={this.onStatusChange}
                      placeholder="Select type"
                      disabled={invoice?.status === "paid"}
                      // defaultValue={""}
                      value={paymentMethod}
                    >
                      <Option key="unpaid" value="">
                        Unpaid
                      </Option>
                      <Option key="card" value="card">
                        {strings.card}
                      </Option>
                      <Option key="cash" value="cash">
                        {strings.cash}
                      </Option>

                      {user.currency === "NGN" && !isCommission && (
                        <Option key="mobile" value="mobile">
                          {strings.bankTransfer} (USSD)
                        </Option>
                      )}

                      <Option key="mtransfer" value="mtransfer">
                        {strings.bankTransfer}
                      </Option>
                    </Select>
                  </div>

                  <div className="status-wrapper">
                    <p>Transaction Reference</p>
                    <Input
                      size="large"
                      className="reference field"
                      disabled={invoice?.status === "paid"}
                      placeholder="Enter reference"
                      onChange={this.onReferenceChange}
                      defaultValue={invoice?.payment_reference}
                    />
                  </div>

                  {paymentMethod === "ussd" && (
                    <div className="bank_ussd_code status-wrapper">
                      <p className="items-title">{strings.customerBank}</p>
                      <Select
                        placeholder={strings.selectBank}
                        allowClear
                        onChange={this.saveBankUssdCode}
                        disabled={invoice?.status === "paid"}
                        size="large"
                        style={{ width: "100%" }}
                        defaultValue={
                          isBankUssdCodePresent && bankUssdCode.name
                        }
                      >
                        {banks.map((bank, key) => (
                          <Option key={bank.code} value={bank.name}>
                            {bank.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
              )}

              <div style={{display:"flex", alignItems:"center", gap:5, margin: "5px 0 8px"}}>
                <Checkbox 
                  checked={has_product_decreased}
                  onChange={(e) =>this.setState({ has_product_decreased: e.target.checked})}
                  disabled={invoice && invoice?.status === "paid"}
                />
                <p style={{margin: 0}}>{strings.reduceProdInv}</p>
              </div>

              <p className="items-title">{strings.paymentMessage}</p>
              <div className="description">
                <textarea
                  rows={2}
                  placeholder={strings.description + "..."}
                  onChange={this.handleInvoiceValues}
                  defaultValue={invoice?.payment_message}
                  // disabled={invoice?.status === "paid"}
                  name="description"
                  style={{
                    padding: "11px",
                    width: "100%",
                    border: "1px solid #d9d9d9",
                    borderRadius: "5px",
                    outline: "none",
                  }}
                ></textarea>
              </div>

              {invoice && invoice.status === "paid" ? (
                <div style={{ paddingLeft: "5px" }}>
                  <Button
                    type="primary"
                    loading={recordingInvoice}
                    onClick={() => {
                      this.setState({
                        recordingInvoice: true,
                      });
                      this.handleSaveInvoice();
                    }}
                    style={{ marginRight: "20px" }}
                    disabled={invoice.status === "paid"}
                  >
                    {type === "create" ? strings.save : strings.save}
                  </Button>
                  <Popover
                    content={content}
                    trigger="click"
                    visible={this.state.visible}
                    onVisibleChange={this.handleVisibleChange}
                  >
                    <Button
                      type="primary"
                      loading={recordingSaveAndSendInvoice}
                      style={{ marginRight: "20px" }}
                    >
                      {strings.sendReceipt}
                    </Button>
                  </Popover>
                  {/* {isAdminIsManager && invoice && pdfLink} */}

                  {type === "edit" && printInvoice ? <Button
                    type="primary"
                    // loading={printInvoice}
                    onClick={() => {
                      this.handlePrint();
                    }}
                    style={{ marginLeft: "20px" }}
                  >
                    Print Invoice
                  </Button>: isAdminIsManager && invoice && pdfLink}

                  {isAdmin && (
                    <Button
                      type="primary"
                      loading={deletingInvoice}
                      onClick={() => {
                        this.setState({
                          deletingInvoice: true,
                        });
                        this.closeModal();
                        toggleDeleteInvoiceModal();
                        this.setState({
                          deletingInvoice: false,
                        });
                      }}
                    >
                      {strings.delete}
                    </Button>
                  )}
                </div>
              ) : (
                <div style={{ paddingLeft: "5px" }}>
                  <Button
                    type="primary"
                    loading={recordingInvoice}
                    onClick={() => {
                      this.setState({
                        recordingInvoice: true,
                      });
                      this.handleSaveInvoice();
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    {type === "create" ? strings.save : strings.save}
                  </Button>

                  {isAdminIsManager && (
                    <Popover
                      content={content}
                      trigger="click"
                      visible={this.state.visible}
                      onVisibleChange={this.handleVisibleChange}
                    >
                      <Button
                        type="primary"
                        loading={recordingSaveAndSendInvoice}
                        style={{ marginRight: "20px" }}
                      >
                        {type === "create"
                          ? strings.saveAndSend
                          : strings.resend}
                      </Button>
                    </Popover>
                  )}
                  {/* {isAdminIsManager && invoice && pdfLink} */}

                  {type === "edit" && isAdmin && (
                    <Button
                      type="primary"
                      loading={deletingInvoice}
                      onClick={() => {
                        this.setState({
                          deletingInvoice: true,
                        });
                        this.closeModal();
                        toggleDeleteInvoiceModal();
                        this.setState({
                          deletingInvoice: false,
                        });
                      }}
                    >
                      {strings.delete}
                    </Button>
                  )}

                   {type === "edit" && printInvoice ? <Button
                    type="primary"
                    // loading={printInvoice}
                    onClick={() => {
                      this.handlePrint();
                    }}
                    style={{ marginLeft: "20px" }}
                  >
                    Print Invoice
                  </Button>: isAdminIsManager && invoice && pdfLink}
                </div>
              )}
            </div>
          </div>
          {type === "edit" && (
            <AddProductToInvoiceModal
              showModal={showAddProductToInvoiceModal}
              closeModal={this.closeAddProductToInvoiceModal}
              addProductToCart={addNewProductToCart}
            />
          )}
        </Modal>
      </>
    );
  }
}

InvoiceForm.propTypes = {
  selectedCustomer: PropTypes.object.isRequired,
  cartList: PropTypes.object.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  addNewProductToCart: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
  reduceItemFromCart: PropTypes.func.isRequired,
  handleShowBulkQuantityModal: PropTypes.func.isRequired,
  toggleShowInvoicePrompt: PropTypes.func.isRequired,
  getCustomer: PropTypes.func.isRequired,
  paymentMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.product.allProducts,
  invoices: state.invoice.allInvoices,
  paymentMessage: state.invoice.paymentMessage,
  error: state.error,
  user: state.auth.user,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
});

export default connect(mapStateToProps, {
  saveInvoice,
  sendInvoiceToCustomerAsMail,
  getDownloadableInvoiceLink,
  getPaymentMessage,
  editInvoice,
  sendReceipt,
  getDownloadableReceiptLink,
  getAllInvoices,
  addPaymentMessage,
  getAllLoyaltyPrograms,
  sendNotification,
})(InvoiceForm);
