import { 
  SUBSCRIPTION_STATUS, 
  TOGGLE_RENEWAL_MODAL, 
  TOGGLE_SUBSCRIBE_MODAL,
  SUBSCRIPTION_ACCESS 
} from "../constants";

const initialState = {
  isRenewalModalOpen: false,
  isSubscribeModalOpen: false,
  subscriptionStatus: "not_active",
  subscriptionAccessStatus: false 
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_RENEWAL_MODAL:
      return {
        ...state,
        isRenewalModalOpen: !state.isRenewalModalOpen,
      };
      case TOGGLE_SUBSCRIBE_MODAL:
        return {
          ...state,
          isSubscribeModalOpen: !state.isSubscribeModalOpen,
      };
      case SUBSCRIPTION_STATUS:
        return {
          ...state,
          subscriptionStatus: payload,
      };
      case SUBSCRIPTION_ACCESS:
        return {
          ...state,
          subscriptionAccessStatus: payload,
      };
    default:
      return state;
  }
};
export default reducer;
