import React from "react";
import { Modal, Input, Button } from "antd";
import { Form, Icon } from "@ant-design/compatible";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  editStaff,
  getAllBusinessBranches,
} from "../../../../../../redux/actions/branchActions";
import strings from "../../../../../../strings";

const FormItem = Form.Item;

class EditStaffModal extends React.PureComponent {
  state = {
    loading: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ loading: false });
    }
  }

  handleAddStaff = () => {
    const form = this.props.form;

    form.validateFields((err, values) => {
      if (err) return;

      this.setState({ loading: true });

      this.props
        .editStaff({ ...this.props.currentStaff, ...values })
        .then(res => {
          if (res.status && res.status === 200) {
            form.resetFields();

            Modal.success({
              title: "Staff edited successfully",
            });

            this.setState({ loading: false });
            this.props.getAllBusinessBranches();
          }
        });
    });
  };

  render() {
    const {
      visible,
      form: { getFieldDecorator },
      closeModal,
      currentStaff,
      editStaffPassword,
    } = this.props;

    const { loading } = this.state;

    return (
      <Modal
        open={visible}
        title="Edit Staff"
        okText="Edit"
        onOk={this.handleAddStaff}
        onCancel={closeModal}
        okButtonProps={{ loading: loading }}
        destroyOnClose
        footer={null}
      >
        <div style={{ marginBottom: "20px" }}>
          <Button type="primary" onClick={editStaffPassword}>
            Change Staff Password
          </Button>
        </div>
        <Form layout="vertical">
          {/* Staff Email Input */}
          <FormItem>
            {getFieldDecorator("email", {
              initialValue: currentStaff.email,
              rules: [
                {
                  message: strings.pleaseEnterStaffEmail,
                  required: true,
                },
              ],
            })(
              <Input
                size="large"
                type="email"
                prefix={
                  <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={strings.enterStaffEmail}
                disabled
              />
            )}
          </FormItem>

          {/* Staff Phone Number Input */}
          {/* <FormItem>
            {getFieldDecorator("username", {
              initialValue: currentStaff.phone_number,
              rules: [
                {
                  message: "Please enter staff phone number",
                  required: true,
                },
              ],
            })(
              <Input
                size="large"
                type="text"
                prefix={
                  <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Enter staff phone number"
              />
            )}
          </FormItem> */}

          {/* Staff Username Input */}
          <FormItem>
            {getFieldDecorator("username", {
              initialValue: currentStaff.username,
              rules: [
                {
                  message: strings.pleaseEnterStaffUsername,
                  required: true,
                },
                {
                  validator: (rule, value, callback) => {
                    const hasSpaces = value.split(" ").length > 1;
                    const isEmail = value.split("@").length > 1;

                    if (hasSpaces) {
                      callback(strings.spacesNotAllowedInUsername);
                    } else if (isEmail) {
                      callback("Email is not allowed in username");
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(
              <Input
                size="large"
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={strings.enterStaffUsername}
                disabled
              />
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const EditStaffForm = Form.create()(EditStaffModal);

EditStaffForm.propTypes = {
  error: PropTypes.string.isRequired,
  addNewStaff: PropTypes.func.isRequired,
  getAllBusinessBranches: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  error: state.error,
});

export default connect(mapStateToProps, {
  editStaff,
  getAllBusinessBranches,
})(EditStaffForm);
