import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { CopyOutlined } from "@ant-design/icons"
import api from '../../../../api';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import "./index.scss";


const StripleLoyalty = () => {
    const [ loyaltyPrograms, setLoyaltyPrograms ] = useState([]);
    const [ copiedLink, setCopiedLink ] = useState("");

    useEffect(()=> {
        getLoyaltyProgram();
    }, []);

    const getLoyaltyProgram = async () => {
        const res = await api.loyalty.getAllLoyaltyPrograms(0);

        console.log("response",res);

        if(res.status === 200) setLoyaltyPrograms(res.data.filter(loyalty => loyalty.deleted === false));
    }
  return (
    <div className='page-containerstripe'>
        <h2>Please copy this url and paste it in the webhook section of your Stripe account</h2>
        <div className='stripe'>
        {loyaltyPrograms.length > 0 
            && 
            loyaltyPrograms.map((program, index)=>{
                return (
                    <div key={index} className='stripeind'>
                        <h3>{program.name}</h3> -
                        <p>{`${process.env.REACT_APP_API_BASE_URL}/webhooks/stripe_webhook/${program.merchant_id}/${program.id}`}</p>
                        <p>
                            <Tooltip title= { copiedLink === index ? "copied!" : "click to copy url to clipboard"}>
                                <CopyToClipboard 
                                    text={`${process.env.REACT_APP_API_BASE_URL}/webhooks/stripe_webhook/${program.merchant_id}/${program.id}`}
                                    onCopy={() => {
                                        setCopiedLink(index);
                                    }}
                                >
                                    <CopyOutlined 
                                        disabled={copiedLink === index ? true : false }
                                        style={{ color: copiedLink === index ? "rgba(0,0,0, 0.4)" : "#000" }}
                                    />
                                </CopyToClipboard>
                                
                            </Tooltip>
                        </p>
                        
                    </div>
                )
            })
        }
        </div>
    </div>
  )
}

export default StripleLoyalty;