import React, { Fragment } from "react";
import numberFormatter from "../../../../utils/numberFormatter";
import { Tag } from "antd";
import "./index.scss";
import strings from "../../../../strings";

class ShippingWaybill extends React.Component {
  calculateVat(transactions) {
    let result = 0;
    transactions.forEach(transaction => {
      if (transaction.tax_rate) {
        result += transaction.amount * (transaction.tax_rate / 100);
      }
    });
    return result;
  }

  render() {
    const {
      transaction,
      saleTotal,
      user,
      saleId,
      purchaseTime,
      currency,
      paidWith,
      selectedCustomer,
      customer,
      customersList,
      channel,
      payment_reference,
      hasDiscount,
      discountAmount,
    } = this.props;

    // OBJECTIVE:- Trying to get customer details when selectedCustomer is not chosen. e.g When viewing the Transaction Detail on the Admin Dashboard

    // Get Customer Details from Transaction
    const customerDetails =
      Array.isArray(customersList) &&
      customersList.length &&
      transaction.length &&
      customersList.filter(
        customer => customer.id === transaction[0].customer_id
      );

    const loyalties =
      customer && customer.id
        ? [
            ...customer.loyalty_points.simple_points,
            ...customer.loyalty_points.stamps_program,
          ]
        : Array.isArray(customerDetails) &&
          customerDetails.length && [
            ...customerDetails[0].loyalty_points.simple_points,
            ...customerDetails[0].loyalty_points.stamps_program,
          ];

    let currentLoyalty = [];

    Array.isArray(loyalties) &&
      loyalties.length &&
      loyalties.forEach(loyalty => {
        if (
          transaction.find(
            trans =>
              trans.merchant_loyalty_program_id ===
              loyalty.merchant_loyalty_program_id
          )
        ) {
          currentLoyalty.push(loyalty);
        }
      });

    // console.log({
    //   transaction: transaction,
    //   selectedCustomer: selectedCustomer,
    //   customer: this.props.customer,
    //   customerDetails: customerDetails || [],
    //   currentLoyalty: currentLoyalty || [],
    // });

    const businessName = user
      ? typeof user.role === "object"
        ? user.business_branch.name
        : user.business_name
      : "";

    const businessContact = user
      ? user.contact_number
        ? user.contact_number
        : user.email
      : "";

    const businessAddress = user
      ? user.address_line1
        ? user.address_line1
        : user.business_branch
        ? user.business_branch.address_line1
        : ""
      : "";

    const businessAddressLine2 = user
      ? user.address_line2
        ? user.address_line2
        : user.business_branch
        ? user.business_branch.address_line2
        : ""
      : "";

    const cashierName = user
      ? user.first_name
        ? user.first_name
        : user.username
      : "";

    const fullname = customer
      ? `${customer.first_name} ${customer.last_name}`
      : customerDetails.length &&
        `${customerDetails[0].first_name} ${customerDetails[0].last_name}`;

    const customerName = selectedCustomer || fullname;

    // customer details
    // const

    const loyalty_balance =
      selectedCustomer || customer || customerDetails[0] ? (
        <Fragment>
          {currentLoyalty.length
            ? currentLoyalty.map((loyalty, key) => {
                const {
                  accumulated_points,
                  accumulated_stamps,
                  program_name,
                  threshold,
                  reward,
                } = loyalty;

                const pointsBalanceToRedeem = threshold - accumulated_points;

                const stampsBalanceToRedeem = threshold - accumulated_stamps;

                if (accumulated_points) {
                  if (pointsBalanceToRedeem < 0) {
                    return (
                      <span key={key}>
                        {strings.dear}{" "}
                        {selectedCustomer ||
                          (customer && customer.first_name) ||
                          customerDetails[0].first_name}
                        , {strings.wellDoneYouAreDueToRedeem} {reward}. Show
                        code{" "}
                        {(customer && customer.token) ||
                          customerDetails[0].token}{" "}
                        {strings.toGetYourRewardNextVisit} :)
                      </span>
                    );
                  } else {
                    return (
                      <span key={key}>
                        {strings.youHave} {accumulated_points}{" "}
                        {strings.pointsOnThe} {program_name} {strings.offer}.{" "}
                        {strings.earn} {pointsBalanceToRedeem}{" "}
                        {strings.morePointsToRedeem} {reward}.
                      </span>
                    );
                  }
                } else {
                  if (stampsBalanceToRedeem < 0) {
                    return (
                      <span key={key}>
                        {strings.dear}{" "}
                        {selectedCustomer ||
                          (customer && customer.first_name) ||
                          customerDetails[0].first_name}
                        , {strings.wellDoneYouAreDueToRedeem} {reward}.{" "}
                        {strings.showCode}{" "}
                        {(customer && customer.token) ||
                          customerDetails[0].token}{" "}
                        {strings.toGetYourRewardOnNextVisit} :)
                      </span>
                    );
                  } else {
                    return (
                      <span key={key}>
                        {strings.youHave} {accumulated_stamps}{" "}
                        {strings.starsOnThe} {program_name} {strings.offer}.{" "}
                        {strings.get}
                        {stampsBalanceToRedeem} {strings.moreStarsToRedeem}{" "}
                        {reward}.
                      </span>
                    );
                  }
                }
              })
            : null}
        </Fragment>
      ) : null;

    const hasStampDuty = paidWith === "card" && currency === "NGN";
    const stampDuty = 50.0;
    const vatToRemove = this.calculateVat(transaction).toFixed(2);

    return (
      <div className="draftbill">
        <div className="address-info">
          {/* client details */}
          <div>
            <h5 className="bold">{businessName} </h5>

            <span className="contact-info padding-left padding-right">
              {businessAddress ? businessAddress : user.address}
            </span>
            <br />

            <span className="contact-info">
              {businessAddressLine2 ? businessAddressLine2 : user.address}
            </span>

            <div className="contact-info">
              <span>{strings.phoneNumber}:</span>
              <span className="ml-2">{businessContact}</span>
            </div>

            <div className="invoice-info">
              <span>
                {strings.receipt} #{saleId}
              </span>
              <span>
                {purchaseTime} <br />
                {strings.servedBy} {cashierName}
              </span>

              <span>
                {strings.customer}: {customerName || ""}
              </span>

              <span>
                {strings.reference}: {payment_reference}
              </span>
            </div>
          </div>
        </div>
        {/* customer */}
        <div className="contact-info">
          <span>{strings.customer}: </span>
          <span className="contact-info">
            {customer
              ? `${customer.first_name} ${customer.last_name}`
              : customerDetails.length &&
                `${customerDetails[0].first_name} ${customerDetails[0].last_name}`}
          </span>
          <br />

          <div className="contact-info">
            <span>{strings.addressLine1}: </span>
            <span>
              {(customer && customer.address_line1) ||
                (customerDetails.length &&
                  ` ${customerDetails[0].address_line1}`)}
            </span>
          </div>
          <div className="contact-info">
            <span>{strings.addressLine2}: </span>
            <span>
              {(customer && customer.address_line2) ||
                (customerDetails.length &&
                  ` ${customerDetails[0].address_line2}`)}
            </span>
          </div>

          <div className="contact-info">
            <span>{strings.phoneNumber}:</span>
            <span className="ml-2">
              {(customer && customer.phone_number) ||
                (customerDetails.length &&
                  ` ${customerDetails[0].phone_number}`)}
            </span>
          </div>
          <div className="contact-info">
            <span>{strings.email}:</span>
            <span className="ml-2">
              {(customer && customer.email) ||
                (customerDetails.length && ` ${customerDetails[0].email}`)}
            </span>
          </div>
          <div className="contact-info">
            <span>
              {(customer && customer.state) ||
                (customerDetails.length && ` ${customerDetails[0].state}`)}
            </span>
          </div>
          <div className="contact-info">
            <span>{strings.postCode}:</span>
            <span className="ml-2">
              {(customer && customer.postcode) ||
                (customerDetails.length && ` ${customerDetails[0].postcode}`)}
            </span>
          </div>
        </div>

        {/* delievery note */}
        <div className="product-section">
          <div className="delievery-note">
            <h5 className="bold">{strings.delivaryNote}</h5>

            <span className="delievery_text">{this.props.shippingNote}</span>
          </div>

          <table>
            <tbody>
              {transaction &&
                transaction.map((product, key) => (
                  <Fragment key={key}>
                    <tr>
                      <td colSpan="3" className="bold">
                        <div className="padding-left">
                          {product.name || product.product_name}
                        </div>
                        <div>
                          {product.variants && product.variants.length > 0 ? (
                            <div className="image padding-left">
                              {product.variants.map(variant => {
                                return `${variant.type} : ${variant.value}`;
                              })}
                            </div>
                          ) : null}
                        </div>
                      </td>
                    </tr>

                    <tr className="item-list">
                      <td className="bold" colSpan="1">
                        {product.product_quantity || product.quantity}
                      </td>
                      <td
                        className="bold"
                        colSpan="1"
                      >{`@${product.amount}`}</td>
                      <td className="bold padding-right" colSpan="1">{`${
                        currency ? currency : ""
                      }${product.amount}`}</td>
                    </tr>
                  </Fragment>
                ))}
            </tbody>
          </table>

          <div className="total-container">
            <ul className="subtotal">
              <li>
                <span>{strings.subtotal}</span>
                <span>
                  {currency}
                  {numberFormatter((saleTotal - vatToRemove).toFixed(2))}
                </span>
              </li>

              {hasDiscount ? (
                <li>
                  <span>{strings.discount}</span>
                  <span>
                    {discountAmount ? currency : ""}
                    {discountAmount ? numberFormatter(discountAmount) : "--"}
                  </span>
                </li>
              ) : null}

              <li>
                <span>{strings.taxVat}</span>
                <span>
                  {currency}
                  {vatToRemove}
                </span>
              </li>
              {/* {
              hasStampDuty && (
                  <li>
                    <span>Stamp Duty</span>
                    <span>{currency}{stampDuty}</span>
                  </li>
                )
            } */}
            </ul>

            <div className="cash-container">
              <div className="cash">
                <span>
                  {paidWith} {channel && `(${channel})`}
                </span>
                <span>
                  {currency}
                  {// hasStampDuty ? numberFormatter((parseFloat(saleTotal)+ parseInt(stampDuty))) :
                  hasDiscount
                    ? numberFormatter(saleTotal - discountAmount)
                    : numberFormatter(saleTotal)}
                </span>
              </div>
            </div>
            <div className="total">
              <h4 className="bold">TOTAL</h4>
              <h4 className="bold">
                {currency}
                {/* {hasStampDuty ? numberFormatter((parseFloat(saleTotal)+ parseInt(stampDuty))) :  */}
                {numberFormatter(saleTotal)}
              </h4>
            </div>
          </div>
        </div>
        {/* delievery note */}

        <div className="footer loyalty-balance">
          <p className="">
            {strings.thankYouForPatronage}
            {(customer && ` ${customer.first_name}`) ||
              (customerDetails.length && ` ${customerDetails[0].first_name}`) ||
              ""}
            . {loyalty_balance}
          </p>
          <p>---</p>
          <p className="smallfont">POWERED BY LOYSTAR ☺</p>
          <span className="smallfont">WWW.LOYSTAR.CO</span>
        </div>
      </div>
    );
  }
}

export default ShippingWaybill;
