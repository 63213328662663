const ValidateCustomQuantity = (name, price, barcode, quantity) => {
    let result = {
      status: true,
      message: "",
    };
    if (!price || !name || !barcode || !quantity) {
      result.status = false;
      result.message =
        "Please fill out Unit, Barcode, Price and quantity values for Custom Quantity";
    } else if (price && name && barcode && quantity) {
      if (price < 0) {
        result.status = false;
        result.message = "Invalid price supplied";
      }
  
      if (name.trim().length === 0 || name.trim().length < 0) {
        result.status = false;
        result.message = "Invalid custom unit supplied";
      }
      if (barcode.length === 0 || barcode.length < 0) {
        result.status = false;
        result.message = "Invalid barcode supplied";
      }
      if (quantity < 0) {
        result.status = false;
        result.message = "Invalid custom quantity supplied";
      }
    }
    return result;
  };
  
  export default ValidateCustomQuantity;