import React, { useEffect, useState } from "react";
import { Button, message, Tooltip } from "antd";
import { PlusOutlined, MinusOutlined, CloseOutlined, LinkOutlined } from "@ant-design/icons";
import numberFormatter from "../../../../utils/numberFormatter";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { GET_SERVICES } from "../../../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";


const ButtonGroup = Button.Group;


const CartListItem = ({
  item,
  removeItemFromCart,
  increaseItemQuantity,
  reduceItemFromCart,
  handleShowBulkModal,
  handleShowSetPrice,
  location,
  addBundleToCart,
  reduceBundleFromCart,
  removeBundleFromcart,
  checkoutData,
  returnItem,
  onClickReturn,
  returnOneItem,
  setSelectedItem,
  setOpenManangeReturnModal,
  getAllSales
}) => {

  const [ productWAndQuantity, setProductAndQuantity ]  = useState([]);
  const [ services, setServices ] = useState([]);
  const [ serviceToDisplay, setServiceToDisplay ] = useState(null);
  const [ returnLoading, setReturnLoading ] = useState(false);
  const { getServices } = useQuery(GET_SERVICES, {
    variables: {},
    onCompleted: (data) => {
      setServices(data.getServices);
    }
  });

  useEffect(()=>{
    if(item.service_name){
      const serv = services.length > 0 && services.filter((service)=>{
        return service.id === item.service_id
      })

      setServiceToDisplay(serv)
    }
  }, [services])

// console.log("ites", item)

  useEffect(()=>{
    let prodQuant = [];
    item &&item.products && item.products.map((prods, index)=>{
        item && item.product_quantity && item.product_quantity.map((quant, ind)=>{
            if(index === ind){
                let prod = {
                    name: item.products[index].name,
                    quant: item.product_quantity[index]
                }
                prodQuant.push(prod);
            }
        })
    });
    prodQuant.length > 0 && setProductAndQuantity(prodQuant)
  }, [item]);

  useEffect(()=>{
    let prodQuant = [];
    item && item.bundle && item.bundle.bundle_products && item.bundle.bundle_products.length > 0 &&  item.bundle.bundle_products.map((prods, index)=>{
      let prod = {
        name: prods.name,
        quant: item.bundle.bundle.quantity[index]
      }
      prodQuant.push(prod);
    });
    prodQuant.length > 0 && setProductAndQuantity(prodQuant)
  }, [item]);

  const handleReturnItem = (item) => {
    setReturnLoading(true);
    returnOneItem(item.id)
    .then(res => {
      if(res.status === 200){
        setReturnLoading(false);
        setSelectedItem(item);
        // !item.product.track_inventory && message.success(res.data.message);
        // !item.product.track_inventory && getAllSales()
        // item.product.track_inventory && 
        setOpenManangeReturnModal(true);
      }
    })
    .catch(err => {
      setReturnLoading(false);
      console.log("err", err.message);
    })
    
  }
  
  return (
    <div className="cart-list-item">
      <div className="content">
        <>
          {item.picture || item.product_picture ? (
            <div className="image">
              <img src={item.picture || item.product_picture} alt={item.name} />
            </div>
          ) : item.bundle && item.bundle.bundle && item.bundle.bundle.images.length > 0 ? (
            <div className="image">
              <img src={item.bundle.bundle.images[0]} alt={item.name} />
            </div>
          ) : item.image_url ? <div className="image">
          <img src={item.image_url} alt={item.name} />
        </div> :  serviceToDisplay && serviceToDisplay.length > 0 && serviceToDisplay[0].image_url ? <div className="image">
          <img src={serviceToDisplay[0].image_url} alt={item.name} />
        </div> : null}
        </>
        
        <div>
          <h4 className="title">
            {item.name ? item.name : item.service_name ? item.service_name : item.bundle && item.bundle.bundle ? item.bundle.bundle.name : item.product_name}
            <div>
              {item.variants && item.variants.length > 0 ? (
                <div className="image" style={{display: "flex", flexDirection: "column"}}>
                  {item.variants.map((variant, index) => {
                    return <span key={index}>{`${variant.type} : ${variant.value}`}</span>;
                  })}
                </div>
              ) : item.product && item.product.variant ? (
                <div className="image">
                  {`${item.product.variant.type} : ${item.product.variant.value} `}
                </div>
              ) : null}
            </div>
            <div>
              {item.custom_quantities && item.custom_quantities.length > 0 && (
                <div className="image" style={{display: "flex", flexDirection: "column"}}>
                  {item.custom_quantities.map((custom, index) => {
                    return <span key={index}>{`${custom.name} (${custom.quantity} units) : ${custom.price}`} </span>;
                  })}
                </div>
              )}
            </div>
            <div>
              {item.bundle && item.bundle.bundle_products && item.bundle.bundle_products.length > 0 && item.bundle.bundle_products.length > 0 && (
                <div className="image" style={{display: "flex", flexDirection: "column"}}>
                  {productWAndQuantity && productWAndQuantity.map((prods, index)=>{
                    return <span >{`${prods.name} - ${prods.quant} units`} </span>;
                  })}
                </div>
              )}
            </div>
            <div>
              {item.products && item.products.length > 0 && (
                <div className="image" style={{display: "flex", flexDirection: "column"}}>
                  {productWAndQuantity && productWAndQuantity.map((prods, index)=>{
                      return <span >{`${item.products[index].name} - ${item.product_quantity[index]} units`} </span>;
                  })}
                </div>
              )}
            </div>
          </h4>
          <div>
            <span
              role="button"
              onClick={() => {
                handleShowBulkModal && handleShowBulkModal(item);
              }}
              className="quantity"
            >
              <strong>Qty:</strong>{" "}
              {item.quantity
                ? item.quantity
                : item.product && Number(item.product.original_price) === 0
                ? 1
                : Number.parseFloat(item.product_quantity || 0).toFixed(2) || 0}
            </span>{" "}
            <span
              role="button"
              // onClick={() => {
              //   handleShowSetPrice && handleShowSetPrice(item);
              // }}item.amount === 0 ? item.amount :
              // className={checkoutData === "services" ? "quantity" : ""}
            >
              <strong> &nbsp; Amt:</strong>{" "}
              {item.amount 
                ? numberFormatter(Number(item.amount).toFixed(2))
                : item.total_price/1000}
            </span>
          </div>
        </div>
      </div>

      <div className="action"> 
        {increaseItemQuantity && (
          <ButtonGroup style={{ display: "flex" }}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                if(item.products && item.products.length > 0){
                  addBundleToCart(item)
                }else{
                  increaseItemQuantity(item, 1);
                }
              }}
              disabled={location?.state?.fromInstoreOrder ? location?.state?.fromInstoreOrder : Number(item.original_price) === 0}
            />
            <Button
              icon={<MinusOutlined />}
              onClick={() => {
                if(item.products && item.products.length > 0){
                  reduceBundleFromCart(item)
                }else{
                  reduceItemFromCart(item);
                }
              }}
              disabled={location?.state?.fromInstoreOrder ? location?.state?.fromInstoreOrder : Number(item.original_price) === 0}
            />
            <Button
              icon={<CloseOutlined />}
              onClick={() => {
                if(item.products && item.products.length > 0){
                  removeBundleFromcart(item)
                }else{
                  removeItemFromCart(item);
                }
              }}
              disabled={location?.state?.fromInstoreOrder && location?.state?.fromInstoreOrder}
            />
          </ButtonGroup>
        )}
      </div>
      {returnItem && (
        <div>
          {/* <Tooltip title="Refund/Return"> */}
            <Button type="link" loading={returnLoading} onClick={() => handleReturnItem(item)} 
            disabled={item.sales_return && Number(item.quantity) === 0}
            >
              Refund/Return
              {/* <LinkOutlined /> */}
            </Button>
          {/* </Tooltip> */}
         
        </div>
      )}
    </div>
  );
};

CartListItem.propTypes = {
  item: PropTypes.object.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
  reduceItemFromCart: PropTypes.func.isRequired,
  increaseItemQuantity: PropTypes.func.isRequired,
  handleShowBulkModal: PropTypes.func.isRequired,
  handleShowSetPrice: PropTypes.func.isRequired
};

export default CartListItem;
