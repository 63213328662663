import React, { useEffect, useState, useRef } from "react";
import { QRCode } from "react-qrcode-logo";
import { exportComponentAsJPEG } from "react-component-export-image";
import { message, Spin, Modal, Button } from "antd";
import {
  UREWARDS_SUBSCRIPTION,
  LIST_LOYALTIES,
} from "../../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";
import { useSubscription, useQuery } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { GET_MERCHANT_SENDERID } from "../../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";

const wsLink = new WebSocketLink(
  new SubscriptionClient(
    `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL.replace(
      "https",
      "wss"
    )}/graphql`,
    {
      reconnect: true,
    }
  )
);

const client = new ApolloClient({
  link: wsLink,
  cache: new InMemoryCache(),
});

const getIdentifierString = () => String(Date.now() * 32500);

const ScanQrCode = ({
  user,
  setCheckInCustomer,
  allLoyaltyPrograms,
  getAllCustomers,
  checkInCustomer,
}) => {
  const [identifierString, setIdentifierString] = useState(
    getIdentifierString()
  );
  const [ loadingQrDownload, setLoadingQrDownload ] = useState(false);
  const [ urewardsContact, setUrewardsContact] = useState(null);
  const { data: dataGet, 
    loading: loadingGet, 
    error: errorGet, refetch 
  }  = useQuery(GET_MERCHANT_SENDERID, 
    {
      variables: { merchant_id: user.employer ? user.employer.id : user.id},
      onCompleted: (data) => {
        if( data.getMerchantSenderId !== null || data.getMerchantSenderId !== undefined){
          setUrewardsContact(data.getMerchantSenderId.uRewardsId);
        }else{
          setUrewardsContact("");
        }
      },
      onError: (err) => {
        setUrewardsContact("");
        Modal.error({
          title: `${err.message}`
        })
      }
    });


  const number = user.contact_number || user.employer.contact_number;
  let string = "";
  for (let letter of number) {
    string = isNaN(Number(letter)) ? string : (string += letter);
  }

  const { data, loading } = useSubscription(UREWARDS_SUBSCRIPTION, {
    client,
  });
  const identifier_string =
    !loading && data.check_in && data.check_in.identifier_string;

  const customer_id = !loading && data.check_in && data.check_in.customer_id;

  const { data: mtierLoyalty } = useQuery(LIST_LOYALTIES);

  useEffect(() => {
    if (identifier_string === identifierString) {
      async function getNewCustomers() {
        const res = await getAllCustomers();
        return res;
      }
      getNewCustomers()
        .then((res) => {
          console.log({ res });
          if (res.status === 200) {
            const customer = res.data.find(
              (cstmer) => String(cstmer.user_id) === String(customer_id)
            );
            setCheckInCustomer(customer);
            setIdentifierString(getIdentifierString());
          }
        })
        .catch((err) => {
          message.error(err.response?.data?.message || err.message);
        });
    }
  }, [customer_id, identifier_string]);

  const MyQrCode = () => {
    const qrDownloadRef = useRef();

    const exportImage = () => {
      setLoadingQrDownload(true);
      exportComponentAsJPEG(qrDownloadRef, {
        fileName: user.business_name,
      });
      setLoadingQrDownload(false);
    };

    let string = "";
    for (let letter of user.contact_number) {
      string = isNaN(Number(letter)) ? string : (string += letter);
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <QRCode
          crossorigin="anonymous"
          size={200}
          quietZone={50}
          value={`${process.env.REACT_APP_UREWARDS_BASE_URL}/${urewardsContact}`}
          logoImage={user.image}
          logoWidth={100}
          logoHeight={100}
          enableCORS={true}
          logoOpacity={0.6}
          qrStyle="dots"
          ecLevel="H"
          eyeRadius={[
            {
              outer: [10, 10, 0, 10],
              inner: [0, 10, 10, 10],
            },
            [10, 10, 10, 0],
            [10, 0, 10, 10],
          ]}
        />
        <div style={{ position: "absolute", left: "-100000vw" }}>
          <QRCode
            ref={qrDownloadRef}
            crossorigin="anonymous"
            size={1000}
            quietZone={100}
            value={`${process.env.REACT_APP_UREWARDS_BASE_URL}/${urewardsContact}`}
            logoImage={user.image}
            logoWidth={400}
            logoOpacity={0.6}
            logoHeight={400}
            enableCORS={true}
            qrStyle="dots"
            ecLevel="H"
            eyeRadius={[
              {
                outer: [10, 10, 0, 10],
                inner: [0, 10, 10, 10],
              },
              [10, 10, 10, 0],
              [10, 0, 10, 10],
            ]}
          />
        </div>
        <Button onClick={exportImage} className="mt-20">
          Download
        </Button>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>
          <b>
            {(user.business_name || user.employer.business_name).toUpperCase()}
          </b>
        </h2>
        {(
          <div>
            <div style={{ textAlign: "center" }}>
              <p>Scan QRCode</p>
              <Spin style={{ margin: 10 }} />
            </div>

            <div>
              {MyQrCode()}
            </div>
            
            
          </div>
        )}
      </div>

      {checkInCustomer && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>
            <b>{checkInCustomer.first_name}</b> just checked in!
          </p>
          <p>
            Customer Points Balance:{" "}
            {checkInCustomer.loyalty_points?.simple_points[0]
              ?.accumulated_points || 0}
          </p>
        </div>
      )}

      {!checkInCustomer && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3>
            <b>Active Loyalty Programs</b>
          </h3>
          {mtierLoyalty?.listLoyalties.map(
            (loyalty, index) =>
              loyalty.status === "Active" && (
                <div key={index}>
                  <p>
                    <span>
                      <b>{loyalty.name}: </b>
                    </span>
                    <span>
                      {loyalty.currency_value} gives {loyalty.points} points
                    </span>
                  </p>
                </div>
              )
          )}

          {allLoyaltyPrograms.map(
            (loyalty, index) =>
              loyalty.active === true && (
                <div key={index}>
                  <p >
                    <span>
                      <b>
                        {loyalty.name} ({loyalty.program_type}):{" "}
                      </b>
                    </span>
                    <span>
                      {loyalty.reward} ({loyalty.reward_value || ""})
                    </span>
                  </p>
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default ScanQrCode;
