import React, { useEffect, useState } from 'react';
import { Modal, Popover, Button, Input, Form, message, Image } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    FundViewOutlined,
    UserOutlined,
    CopyOutlined,
    CheckOutlined,
    WhatsAppOutlined 
  } from "@ant-design/icons";
import strings from '../../../../../strings';
import moment from 'moment';
import EditReferralProgram from './EditReferralProgram';
import { deleteReferralProgram, getReferralProgramQRCode } from '../../../../../redux/actions/referralProgramAction';
import { QRCode } from "react-qrcode-logo";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReferralProgramParticipant from './ReferralProgramParticipant';

const ReferralDetails = ({ 
    referralDetails, 
    open, 
    onCancel,
    handleGetAllReferralProgram,
    user 
}) => {
    const [ program, setProgram ] = useState({});
    const [ openEditModal, setOpenEditModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ confirmDelete, setConfirmDelete ] = useState(false);
    const [ deleteValue, setDeleteValue ] = useState("");
    const [ qrCodeValue, setQrCodeValue ] = useState(null);
    const [ isCopiedToClipBoard, setIsCopiedToClipBoard ] = useState(false);
    const [ openParticipantModal, setOpenParticipantModal ] = useState(false);

    useEffect(() => {
        setProgram(referralDetails);
        handleGetQrCode(referralDetails.event_code);
    }, [referralDetails]);

    const handleDeleteProgram = () => {
        if(deleteValue !== "delete") return message.error("type delete to continue");
        setLoading(true);

        deleteReferralProgram(program.id, user)
        .then((res) => {
            Modal.success({
                title: `${program.name} deleted successfully`,
                onOk: () => {
                    handleGetAllReferralProgram();
                    setLoading(false);
                    setConfirmDelete(false);
                    onCancel();
                }
            })
        })
        .catch((err) => {
            Modal.error({
                title: `${err?.response?.data?.message || "Unable to delete program"}, try again`,
                onOk: () => {
                    setLoading(false);
                }
            })
        })
    }

    const handleGetQrCode = (id) => {
        setLoading(true);

        getReferralProgramQRCode(id, user)
        .then((res) => {
            setLoading(false);
            setQrCodeValue(res.data);
        })
        .catch((err) => {
            Modal.error({
                title: `${err?.response?.data?.message || "Unable to get QR code"}, please try again`,
                onOk: () => {
                    setLoading(false);
                }
            })
        })
    }

    const handleViewLeaderBoard = () => {

    }

    const popOverContent = (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
                key="edit"
                icon={<EditOutlined />}
                onClick={() => setOpenEditModal(true)}
            >
                {strings.edit}
            </Button>
            <Button
                key="delete"
                icon={<DeleteOutlined />}
                onClick={() => setConfirmDelete(true)}
            >
                {strings.delete}
            </Button>
            {/* <Button
                key="redeem"
                icon={<QrcodeOutlined />}
                onClick={handleGetQrCode}
                loading={loading}
            >
                Get qrcode
            </Button> */}
            <a
               href={`${process.env.REACT_APP_UREWARDS_BASE_URL}/leaderboard?c=${program.event_code}`} 
               target="_blank"
            >
                <Button
                    key="credit_loyalty"
                    icon={<FundViewOutlined />}
                    onClick={handleViewLeaderBoard}
                >
                    View Leaderboard
                </Button>
            </a>
            <Button
                key="delete"
                icon={<UserOutlined />}
                onClick={() => setOpenParticipantModal(true)}
            >
                Participants
            </Button>
        </div>
    )

    
  return (
    <>
        <Modal
            open={open}
            onCancel={onCancel}
            title={program && program.name}
            okText={strings.actions}
            cancelText={strings.cancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                {strings.close}
                </Button>,
    
                <Popover title={strings.actions} content={popOverContent}>
                    <Button type="primary">{strings.actions}</Button>
                </Popover>  
            ]}
        >
            <div style={{position: "relative"}}>
                <p>
                    <strong>{strings.name}</strong>: {program && program.name || "-"}
                </p>
                {/* <p>
                    <strong>{strings.eventCode}</strong>: {program && program.event_code || "-"}
                </p> */}
                {/* <p>
                    <strong>{strings.merchant_id}</strong>: {program && program.merchant_id || "-"}
                </p>
                <p>
                    <strong>{strings.orgID}</strong>: {program && program.organisation_id || "-"}
                </p> */}
                <p>
                    <strong>{strings.noOfWinners}</strong>: {program && program.no_of_winners || "-"}
                </p>
                <p>
                    <strong>{strings.custTempName}</strong>: {program && program.custom_template_name || "-"}
                </p>
                <p>
                    <strong>{strings.rewards}</strong>: {program && program.reward || "-"}
                </p>
                <p>
                    <strong>{strings.rewardType}</strong>: {program && program.reward_type || "-"}
                </p>
                <p>
                    <strong>{strings.active}</strong>: {program && program.active ? "True" : "False"}
                </p>
                <p>
                    <strong>{strings.random}</strong>: {program && program.random ? "True" : "False"}
                </p>
                <p>
                    <strong>{strings.sharemsg}</strong>: {program && program.share_message || "-"}
                </p>
                <p>
                    <strong>{strings.optinMssg}</strong>: {program && program.optin_message || "-"}
                </p>
                <div
                    style={{
                        display: "flex", 
                        alignItems: "center", 
                        gap: "20px",
                    }}
                >
                    <p>
                        <strong style={{display: "block"}}>Image</strong> {program && program.createdAt && (<Image src={program.image} height={140} width={140} alt={program.name} />)}
                    </p>
                    {qrCodeValue && (
                        <div
                            style={{
                                // position: "absolute",
                                // right: 5,
                                // top: 100,
                                display: "flex",
                                flexDirection: "column",
                                gap: "0px"
                            }}
                        >
                            <div style={{textAlign: "center"}}>
                                <p style={{margin:0, fontSize: "14px"}}>Scan QR code</p>
                                <QRCode
                                    size={100}
                                    quietZone={1}
                                    value={qrCodeValue}
                                    logoImage={null}
                                    logoWidth={50}
                                    logoHeight={50}
                                    logoOpacity={0.6}
                                    qrStyle="dots"
                                    ecLevel="H"
                                    eyeRadius={[
                                        {
                                        outer: [10, 10, 0, 10],
                                        inner: [0, 10, 10, 10],
                                        },
                                        [10, 10, 10, 0],
                                        [10, 0, 10, 10],
                                    ]}
                                />
                            </div>
                            
                            
                            <div style={{
                                display: "flex", 
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px"
                            }}
                            >
                                <span>{qrCodeValue}</span>
                                <CopyToClipboard
                                    text={qrCodeValue}
                                    onCopy={() => setIsCopiedToClipBoard(true)}
                                >
                                    
                                    {isCopiedToClipBoard ? <span style={{cursor: "pointer", fontSize: "14px"}}><CheckOutlined /> copied</span> : <span style={{cursor: "pointer",fontSize: "14px"}}><CopyOutlined /> copy</span>}
                                </CopyToClipboard>
                            </div>
                            <div>
                                <span style={{fontSize: "14px"}}>Share via: </span>
                                <a style={{marginLeft: "15px"}} href={qrCodeValue} target="_blank"><WhatsAppOutlined /></a>
                            </div>
                            
                        </div>
                    )}
                </div>
                
                <p>
                    <strong>{strings.createdDate}</strong>: {program &&program.createdAt && `${program.createdAt.split("T")[0]} ${moment(program.createdAt).format("h:mm:ss A")}` || "-"}
                </p>
            </div>
        </Modal>

        {openEditModal && (
            <EditReferralProgram 
                open={openEditModal}
                onCancel={() => setOpenEditModal(false)}
                referralProgram={program}
                setProgram={setProgram}
                handleGetAllReferralProgram={handleGetAllReferralProgram}
            />
        )}

        {openParticipantModal && (
            <ReferralProgramParticipant 
                open={openParticipantModal}
                onCancel={() => setOpenParticipantModal(false)}
                referralProgram={referralDetails}
                user={user}
            />
        )}

        {confirmDelete && (
            <Modal
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                title={`${strings.delete} ${program && program.name}`}
                okText="Delete"
                onOk={handleDeleteProgram}
                confirmLoading={loading}
            >
                <Form layout="vertical">
                    <Form.Item
                        name="delete"
                        rules={[
                            {required: true, message: "type delete to delete program"}
                        ]}
                        label="Type delete to continue"
                    >
                        <Input 
                            size="large" 
                            value={deleteValue}
                            onChange={(e) => setDeleteValue(e.target.value)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )}
    </>
    
  )
}

export default ReferralDetails