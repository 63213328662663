import React from "react";
import { message, Modal,} from "antd";
import moment from "moment";
import Highlighter from "react-highlight-words";

import cloneArray from "../../../../utils/cloneArray";
import strings from "../../../../strings";

const toastMessageDuration = 5; // sec

export const getTableColumns = function () {
  const columns = [
    {
      title: strings.invoiceId,
      dataIndex: "number",
      render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
    },
    {
      title: strings.customer,
      dataIndex: "customerName",
      key: "customerName",
      ...this.getColumnSearchProps("customerName"),
    },
    {
      title: strings.status,
      dataIndex: "status",
      render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
      filters: ["unpaid", "paid", "partial"].map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => {
        return record.status === value;
      },
    },
    {
      title: strings.total,
      dataIndex: "subtotal",
      render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
    },
    {
      title: strings.amountPaid,
      dataIndex: "amountPaid",
      key: "amountPaid",
      render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
    },
    {
      title: strings.amountDue,
      dataIndex: "amountDue",
      key: "amountDue",
      render: (record) =>
        record > 0 ? <span>{record}</span> : <span>N/A</span>,
    },
    {
      title: strings.createdDate,
      dataIndex: "createdDate",
      render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
      sorter: (a, b) => moment(a.createdDate) > moment(b.createdDate),
    },
    {
      title: strings.paidDate,
      dataIndex: "paidDate",
      render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
      sorter: (a, b) => moment(a.paidDate) > moment(b.paidDate),
    },
    {
      title: strings.reference,
      dataIndex: "payment_reference",
      key: "payment_reference",
      ...this.getColumnSearchProps("payment_reference"),
      render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
    },
  ];
  return columns;
};

// {
//   hasDiscount && discountAmount
//     ? this.getCartAmount() - discountAmount
//     : amountDue;
// }

export const parseInvoice = function (data) {
  const { allBranches } = this.props;
  const staffObj = {};
  for (let branch of allBranches) {
    for (let staff of branch.staff) {
      staffObj[staff.id] = staff;
    }
  }

  const result = data.map((invoice) => {
    const tempInvoice = { ...invoice };

    const total = invoice.has_discount
      ? invoice.total_after_discount
      : invoice.subtotal;

    tempInvoice.customerName = `${
      invoice.customer.first_name ? invoice.customer.first_name : ""
    } ${invoice.customer.last_name ? invoice.customer.last_name : ""}`;
    tempInvoice.createdDate = invoice.created_at.split("T")[0];
    tempInvoice.paidDate =
      invoice.paid_at !== null ? invoice.paid_at.split("T")[0] : null;
    tempInvoice.subtotal = total;
    tempInvoice.staff_username = staffObj[invoice.staff_id]
      ? staffObj[invoice.staff_id].username
      : "";
    tempInvoice.amountPaid = invoice.paid_amount || 0;

    tempInvoice.amountDue = (
      Number(total) - Number(invoice.paid_amount)
    ).toFixed(1);

    tempInvoice.payment_reference = invoice.payment_reference;

    return tempInvoice;
  });
  return result.sort((a, b) => (a.id < b.id ? 1 : -1));
};

export const getCartItems = function (data) {
  const result = data.map((invoice) => {
    const tempInvoice = { ...invoice.product };
    tempInvoice.amount = parseFloat(invoice.amount);
    tempInvoice.quantity = invoice.quantity;
    tempInvoice.variants = invoice.variants || [];
    tempInvoice.service_id = invoice.service_id || null;
    tempInvoice.service_name = invoice.service_name || null;
    return tempInvoice;
  });
  return result;
};

export const handleCustomerSearchFieldChange = function (value, ...arg) {
  if (value === "add") {
    this.openAddCustomerModal();
  } else {
    this.setState({
      customerValue: value,
      customerName: arg[0].props.children.split(":")[0],
      addLoyaltyProgramToSale: isNaN(value) ? false : true,
    });
  }
};

export const removeItemFromCart = function (item) {
  const { cartList } = this.state;
  let newList;
  if (item.variants.length > 0) {
    item.variants.sort((a, b) => {
      return a.id < b.id;
    });
    newList = cartList.filter((cartItem) => {
      if (cartItem.variants.length > 0) {
        cartItem.variants.sort((a, b) => {
          return a.id < b.id;
        });

        if (item.id !== cartItem.id) {
          return item.id !== cartItem.id;
        } else if (
          item.id === cartItem.id &&
          JSON.stringify(item.variants) !== JSON.stringify(cartItem.variants)
        ) {
          return (
            JSON.stringify(item.variants) !== JSON.stringify(cartItem.variants)
          );
        }
      }
      return item.id !== cartItem.id;
    });
  } else {
    newList = cartList.filter((product) => {
      return product.id !== item.id;
    });
  }
  this.setState({
    cartList: [...newList],
  });
};

export const decreaseItemQuantity = function (item) {
  const productsInStore = cloneArray(this.props.products);
  let productItemInStoreCopy = productsInStore.filter(
    (product) => product.id === item.id
  )[0];

  if(productItemInStoreCopy === undefined){
    message.warn("This Product is not available");
    return this.removeItemFromCart(item);
  }

  productItemInStoreCopy.quantity += 1;
  this.props.updateProductItemInStore(productsInStore, productItemInStoreCopy);

  if (item.variants.length === 0) {
    if (item.quantity <= 1 ) return this.removeItemFromCart(item);

    const productItem = { ...item };

    const cartList = cloneArray(this.state.cartList);
    cartList.forEach((cartItem) => {
      if (cartItem.id === productItem.id) {
        cartItem.quantity = cartItem.quantity - 1;
        cartItem.amount = cartItem.price * cartItem.quantity;
      }
      this.setState({ cartList: [...cartList] });
    });
  } else {
    const price =
      item.variants.length > 0 ? item.variants[0].price : item.price;
    if (item.quantity === 1) return this.removeItemFromCart(item);
    item.variants.sort((a, b) => {
      return a.id < b.id;
    });
    const productItem = { ...item };
    const cartList = cloneArray(this.state.cartList);
    cartList.forEach((cartItem) => {
      cartItem.variants.sort((a, b) => {
        return a.id < b.id;
      });
      if (
        cartItem.id === productItem.id &&
        JSON.stringify(item.variants) === JSON.stringify(cartItem.variants)
      ) {
        cartItem.quantity = cartItem.quantity - 1;
        cartItem.amount = price * cartItem.quantity;
      }
      this.setState({ cartList: [...cartList] });
    });
  }
};

export const addProductToCart = function (item, type = "single") {
  const productItem = { ...item };
  const productsInStore = cloneArray(this.props.products);

  let productItemInStoreCopy = productsInStore.filter(
    (product) => product.id === productItem.id
  )[0];
  
  if(productItemInStoreCopy === undefined){
    message.warn("This Product is not available");
    return;
  }
  const initialProductsData = this.props.products;
  const initialProductItemData = initialProductsData.filter(
    (product) => product.id === item.id
  )[0];

  const cartList = cloneArray(this.state.cartList);
  const { user } = this.props;

  if (item.track_inventory) {
    if (Number(productItemInStoreCopy.quantity) < 1) {
      // TODO: flag error - product cannot be added to cart. [Refill product]
      message.warning(strings.productOutOfStock, toastMessageDuration);
      return;
    }

    productItemInStoreCopy.quantity =
      type === "single" || type === 1
        ? Number(productItemInStoreCopy.quantity) - 1
        : Number(initialProductItemData.quantity) -
          Number(productItem.quantity);
    this.props.updateProductItemInStore(
      productsInStore,
      productItemInStoreCopy
    );
  }

  // filter cartList array to check for the item
  const isItemInCartList = cartList.filter(
    (cartItem) => productItem.id === cartItem.id
  );
  // if the item does not exist
  if (isItemInCartList.length === 0) {
    if (!productItem.quantity || productItem.quantity <= 0) {
      productItem.quantity = 1;
    }
    const price =
      productItem.variants.length > 0
        ? productItem.variants[0].price
        : productItem.price;
    productItem.amount = price * productItem.quantity;
    productItem.points = productItem.points ? productItem.points : 0;
    productItem.stamps = productItem.stamps ? productItem.stamps : 0;
    productItem.product_id = productItem.id;
    productItem.business_branch_id = user.business_branch
      ? user.business_branch.id
      : null;
    this.setState({ cartList: [...cartList, productItem] });
    message.success(strings.productAdded, toastMessageDuration);
  } else {
    if (item.variants.length === 0) {
      cartList.forEach((cartItem) => {
        if (cartItem.id === productItem.id) {
          if (type !== "bulk") {
            cartItem.quantity = parseInt(cartItem.quantity) + 1;
            cartItem.amount = cartItem.price * cartItem.quantity;
          } else {
            cartItem.quantity = productItem.quantity;
            cartItem.amount = cartItem.price * cartItem.quantity;
          }
        }
      });
      this.setState({ cartList: [...cartList] });
      message.success(strings.productAdded, toastMessageDuration);
    } else {
      productItem.variants.sort((a, b) => {
        return a.id < b.id;
      });
      const product = cartList.filter((cartItem) => {
        if (item.id === cartItem.id) {
          cartItem.variants.sort((a, b) => a.id < b.id);
          return (
            item.id === cartItem.id &&
            JSON.stringify(productItem.variants) ===
              JSON.stringify(cartItem.variants)
          );
        }
      });
      if (product.length === 1) {
        cartList.forEach((cartItem) => {
          if (cartItem.id === productItem.id) {
            cartItem.variants.sort((a, b) => a.id < b.id);
            if (
              JSON.stringify(productItem.variants) ===
              JSON.stringify(cartItem.variants)
            ) {
              const price =
                cartItem.variants.length > 0
                  ? cartItem.variants[0].price
                  : cartItem.price;
              if (type === "bulk") {
                cartItem.quantity = productItem.quantity;
                cartItem.amount = price * cartItem.quantity;
              } else if (productItem.quantity > 1 && type !== 1) {
                cartItem.quantity =
                  parseInt(cartItem.quantity) + productItem.quantity;
                cartItem.amount = price * cartItem.quantity;
              } else if (productItem.quantity > 1 && type === 1) {
                cartItem.quantity = parseInt(cartItem.quantity) + 1;
                cartItem.amount = price * cartItem.quantity;
              } else {
                cartItem.quantity = parseInt(cartItem.quantity) + 1;
                cartItem.amount = price * cartItem.quantity;
              }
            }
          }
        });
        this.setState({ cartList: [...cartList] });
        message.success(strings.productAdded, toastMessageDuration);
      } else {
        if (isNaN(productItem.quantity) || productItem.quantity <= 0) {
          productItem.quantity = 1;
        }
        const price =
          productItem.variants.length > 0
            ? productItem.variants[0].price
            : productItem.price;
        productItem.amount = price * productItem.quantity;
        productItem.points = 0;
        productItem.stamps = 0;
        productItem.product_id = productItem.id;
        productItem.business_branch_id = user.business_branch
          ? user.business_branch.id
          : null;
        this.setState({ cartList: [...cartList, { ...productItem }] });
        message.success(strings.productAdded, toastMessageDuration);
      }
    }
  }
};

const findProduct = (productId, products) => {
  const result = products.find((product) => {
    return product.id === productId;
  });
  return result;
};

export const getCartAmount = function () {
  const { cartList } = this.state;
  let result = 0.0;
  cartList.forEach((item) => {
    result += parseFloat(item.amount);
  });
  return result;
};

export const onFilterChange = function (event) {
  const { value } = event.target;
  const { invoices } = this.props;
  if (value === "all") {
    this.setState({
      invoices,
    });
  } else {
    const result = invoices.filter((invoice) => {
      return invoice.status === value;
    });
    this.setState({
      invoices: result,
    });
  }
};

export const getTotal = function (type, invoices) {
  let result = 0.0;
  if (invoices.length > 0) {
    invoices.forEach((invoice) => {
      if (type === "paid") {
        result += parseFloat(invoice.paid_amount) || 0;
      } else {
        result +=
          (parseFloat(invoice.subtotal) || 0) -
          (parseFloat(invoice.paid_amount) || 0);
      }
    });
  }
  return result.toFixed(2);
};
