// import React from "react";
import { useLazyQuery } from "@apollo/client";
import { UPDATE_MERCHANT_SENDERID } from "../Apps/MultiLevelLoyalty/Loyalties/constants";


export const UpdateSenderid = ((urewardsno, senderid )=>{
    const [ updateID, { data, error } ] = useLazyQuery(UPDATE_MERCHANT_SENDERID);

    updateID({variables: {uRewardsId: urewardsno, senderId: senderid}});

    if(data){
        return data;
    }else{
        return error
    }
})