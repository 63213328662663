import {
  // GET_OFFERS,
  // DELETE_OFFERS,
  // SAVE_OFFERS,
  // GET_BIRTHDAY_MESSAGE,
  // EDIT_BIRTHDAY_MESSAGE
} from "../constants";

const initialState = {
  allInvoices: [],
  paymentMessage: ''
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // case GET_ALL_INVOICES:
    //   return {
    //     ...state,
    //     allInvoices: payload
    //   };
    // case GET_PAYMENT_MESSAGE:
    //   return {
    //     ...state,
    //     paymentMessage: payload
    //   };
    default:
      return state;
  }
};
export default reducer;