import axios from "axios";

const mTierUrl = process.env.REACT_APP_MULTI_LEVEL_BASE_URL;
const merchantDetails = JSON.parse(localStorage.getItem("userDetails"));

const others = {
    client: "",
    ["access-token"]: "",
    uid: "",
    ["multi-level-token"]: null,
    ["user-auth"]: null
}

export const createOrganization = async (payload) => {
    const url = `${mTierUrl}/auth/add_organisation`;

    const response = await axios.post(url, payload, {
        headers: {
            ...others
        }
    })

    return Promise.resolve(response.data);
}

export const loginOrganisation = async (payload) => {
    const url = `${mTierUrl}/auth/organisation_login`;

    const response = await axios.post(url, payload, {
        headers: {
            ...others
        }
    })

    return Promise.resolve(response.data);
}

export const createReferralProgram = async (payload, user) => {
    const url = `${mTierUrl}/create_referal_program`;
    const authorizatio = `Bearer ${user.mtierToken}`

    const response = await axios.post(url, payload, {
        headers: {
            merchant_id: user.id, 
            org_id: user.organization_id, 
            authorization: authorizatio, 
            ...others
        }
    });

    return Promise.resolve(response.data);
}

export const getAllReferralPrograms = async (user) => {
    const url = `${mTierUrl}/all_referal_program`;
    const authorizatio = `Bearer ${user.mtierToken}`

    const response = await axios.get(url, {
        headers: {
            merchant_id: user.id, 
            org_id: user.organization_id, 
            authorization: authorizatio, 
            ...others
        }
    });

    return Promise.resolve(response.data);
}

export const editReferralProgram = async (id, payload, user) => {
    const url = `${mTierUrl}/edit_referal_program`;
    const authorizatio = `Bearer ${user.mtierToken}`;
    

    const response = await axios.patch(url, payload, {
        headers: {
            ...others, 
            merchant_id: user.id, 
            org_id: user.organization_id,  
            authorization: authorizatio, 
            id
        }
    });

    return Promise.resolve(response.data);
}

export const deleteReferralProgram = async (id, user) => {
    const url = `${mTierUrl}/delete_referal_program`;
    const authorizatio = `Bearer ${user.mtierToken}`;

    const response = await axios.delete(url, {
        headers: {
            ...others, 
            merchant_id: user.id, 
            org_id: user.organization_id,  
            authorization: authorizatio, 
            id
        }
    });

    return Promise.resolve(response.data);
}

export const getReferralProgramQRCode = async (event_code, user) => {
    const url = `${mTierUrl}/create_referal_qrcode`;
    const authorization = `Bearer ${user.mtierToken}`;

    const response = await axios.get(url, {
        headers: {
            merchant_id: user.id, 
            org_id: user.organization_id, 
            authorization, 
            event_code, 
            ...others
        }
    });

    return Promise.resolve(response.data);
}

export const getReferralProgramParticipant = async (event_code, user) => {
    const url = `${mTierUrl}/referral_participants`;
    const authorization = `Bearer ${user.mtierToken}`;

    const response = await axios.get(url, {
        headers: {
            merchant_id: user.id, 
            org_id: user.organization_id, 
            authorization, 
            event_code, 
            ...others
        }
    });

    return Promise.resolve(response.data);
}

export const deleteReferralProgramParticipant = async (payload, event_code, user) => {
    const url = `${mTierUrl}/remove_referral_participants`;
    const authorization = `Bearer ${user.mtierToken}`;

    const response = await axios.delete(url, {
        headers: {
            merchant_id: user.id, 
            org_id: user.organization_id, 
            authorization, 
            event_code, 
            ...others
        },
        data: payload
    });

    return Promise.resolve(response.data);
}