import { GET_ERRORS, RESET_ERROR } from "../constants";
import { message } from "antd";

const toastMessageDuration = 5; //sec
const initialState = null;

const reducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ERRORS:
      if (payload && payload.message) {
        message.error(payload.message, toastMessageDuration);
        return payload.message;
      } else if (payload && payload.fullMessage) {
        message.error(payload.fullMessage, toastMessageDuration);
        return payload.fullMessage;
      } else if (payload && payload.errors) {
        message.error(payload.errors, toastMessageDuration);
        return payload.errors;
      } else if (payload && payload.data) {
        message.error(payload.data);
        return payload.data;
      } else if (payload && payload.statusText) {
        message.error(payload.statusText, toastMessageDuration);
        return payload.statusText;
      }
      break;
    case RESET_ERROR:
      return null;
    default:
      return state;
  }
};
export default reducer;
