import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Card, DatePicker, message, Button, notification } from "antd";
import {
  getProductSupplies,
  getProductSuppliesByDuration,
} from "../../../../redux/actions/productActions";
import CustomTable from "../../../../components/CustomDataTable";
import { suppliesColumn } from "./functions";
import strings from "../../../../strings";
import { CSVLink } from "react-csv";
import SupplyDetailsModal from "./partials/SupplyDetailsModal";

const { RangePicker } = DatePicker;

const SupplyHistory = ({
  supplies,
  getProductSupplies,
  getProductSuppliesByDuration,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ isExporting, setIsExporting ] = useState(false);
  const [ exportData, setExportData ] = useState([]);
  const [ begins, setBegin ] = useState(null);
  const [ ends, setEnd ] = useState(null);
  const [ openSupplyDetails, setOpenSupplyDetails ] = useState(false);
  const [ supplyDetails, setSupplyDetails ] = useState(null);
  const csvLinkRef = useRef();

  useEffect(() => {
    const fetchProductSupplies = async () => {
      try {
        setIsLoading(true);

        const res = await getProductSupplies();

        if (res && res.status === 200) {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProductSupplies();
  }, [getProductSupplies]);

  const fetchProductSupplies = async () => {
    try {
      setIsLoading(true);

      const res = await getProductSupplies();

      if (res && res.status === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleExportSupply = () => {

    notification.info({
      message: strings.exportSupplies,
      description: strings.yourDownloadWillStart,
      duration: 2,
      style: {
        top: "3rem",
      },
    });

    const supplyToExport = [...supplies]

    supplyToExport.map((supply)=>{
      let productString;
      productString = `${supply.product.name}(${
        (supply.product.price ? Number(supply.product.price) : "No Price")} x ${Number(supply.product.quantity).toFixed(1)})`

      supply.product = productString;
    })

    //console.log(supplyToExport);

    setExportData(supplyToExport);

    setTimeout(() => {
      const downloadBtn = document.getElementById("csvDownloadBtn");

      downloadBtn && downloadBtn.click();
    }, 1000);

  }

  const acceptedHeaders = [
    {
      label: "Supplier",
      key: "supplier",
    },
    {
      label: "Unit Price",
      key: "unit_price",
    },
    {
      label: strings.date,
      key: "created_at",
    },
    {
      label: "Received By",
      key: "received_by",
    },
    {
      label: "Quantity",
      key: "quantity",
    },
    {
      label: "Merchant ID",
      key: "merchant_id",
    },
    {
      label: "Date",
      key: "date",
    },
    {
      label: "Id",
      key: "id",
    },
    {
      label: "Product",
      key: "product"
    }
  ];

  const handleDurationChange = async (duration, ...rest) => {
    const [begin, end] = duration;
    

    if (begin && end) {
      setIsLoading(true);

      setBegin(begin);
      setEnd(end)
      const res = await getProductSuppliesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0]
      );

      if (res && res.status === 200) {
        setIsLoading(false);
      }
    } else if (!begin && !end) {
      fetchProductSupplies();
    } else if (!begin || !end) {
      message.error(
        `${strings.pleaseEnterAValid} ${!begin ? strings.begin : strings.end} ${
          strings.date
        }.`
      );
    }
  };

  const toggleViewSupplyModal = (record) => {
    setSupplyDetails(record);
    setOpenSupplyDetails(!openSupplyDetails);
  }

  // console.log({supplies})

  return (
    <main>
      <Card
        title={strings.supplyHistory}
        loading={isLoading}
        extra={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ margin: "0 10px" }}>{strings.reportFrom}:</span>
            <RangePicker
              placeholder={[strings.startDate, strings.endDate]}
              onChange={handleDurationChange}
              allowClear="false"
            />
            {supplies && (
                <Button
                  style={{ marginLeft: "1.5rem" }}
                  loading={isExporting}
                  onClick={() => {
                    handleExportSupply();
                  }}
                >
                  {strings.export}
                </Button>
              )}
              {exportData.length > 0 && (
                  <CSVLink
                    id="csvDownloadBtn"
                    data={supplies}
                    headers={acceptedHeaders}
                    filename={`${
                      !begins && !ends
                        ? "AllSupplyHistory.csv"
                        : `SupplyHistory${begins.format().split("T")[0]}__${
                            ends.format().split("T")[0]
                          }.csv`
                    }`}
                    // asyncOnClick={true}
                    ref={csvLinkRef}
                    target="_blank"
                    children=""
                  />
                )}
          </div>
        }
      >
        <div>
          <span>
            {strings.totalSupplies}: {supplies.length || 0}
          </span>
        </div>

        <section>
          <CustomTable
            loading={isLoading}
            dataSource={supplies}
            columns={suppliesColumn()}
            pagination={{ total: supplies.length, defaultPageSize: 30 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  toggleViewSupplyModal(record);
                },
              };
            }}
          />
        </section>
      </Card>


      {openSupplyDetails && (
        <SupplyDetailsModal 
          open={openSupplyDetails}
          onCancel={() => setOpenSupplyDetails(false)}
          supplyDetails={supplyDetails}
          fetchProductSupplies={fetchProductSupplies}
        />
      )}
    </main>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  supplies: state.product.supplies,
});

export default connect(mapStateToProps, {
  getProductSupplies,
  getProductSuppliesByDuration,
})(SupplyHistory);
