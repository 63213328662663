import React from "react";
import { Modal, Button, Input, message } from "antd";
import { Form } from "@ant-design/compatible";
import { useMutation } from "@apollo/client";
import {
  DELETE_LOYALTY,
  DELETE_LOYALTY_SUCCESS,
  LIST_LOYALTIES,
} from "../../constants";
import strings from "../../../../../../../../strings";

const DeleteLoyaltyModal = ({ visible, closeModal, form, loyaltyId }) => {
  const [deleteLoyalty, { loading: isDeleteLoyaltyLoading }] = useMutation(
    DELETE_LOYALTY,
    {
      onCompleted: () => {
        closeModal();

        Modal.success({
          title: DELETE_LOYALTY_SUCCESS,
          okButtonProps: {
            id: "btn-component",
          },
        });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: LIST_LOYALTIES }],
    }
  );

  const handleDeleteLoyalty = async () => {
    try {
      const values = await form.validateFields();

      if (values.delete.toLowerCase() !== "delete") {
        Modal.error({
          title: strings.pleaseTypeIn + "delete " + strings.toDeleteLoyalty,
        });
      } else {
        await deleteLoyalty({ variables: { id: loyaltyId } });
      }
    } catch (error) {
      if (error) {
        return;
      }
    }
  };

  const { getFieldDecorator } = form;

  return (
    <Modal
      title={strings.deleteLoyalty}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {strings.cancel}
        </Button>,
        <Button
          key="delete"
          loading={isDeleteLoyaltyLoading}
          type="primary"
          onClick={handleDeleteLoyalty}
        >
          {strings.delete}
        </Button>,
      ]}
    >
      <Form layout="vertical" >
        <Form.Item
          label={strings.pleaseTypeIn + " delete " + strings.toConfirm}
        >
          {getFieldDecorator("delete", {
            rules: [
              {
                required: true,
                message: strings.pleaseTypeIn + " delete " + strings.toConfirm,
              },
            ],
          })(<Input size="large" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(DeleteLoyaltyModal);
