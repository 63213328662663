import React, { useState } from "react";
import { connect } from "react-redux";
import { 
  toggleRenewalModal, 
  toggleSubscribeModal,
  allowToSub 
} from "../../redux/actions/renewalActions";
import strings from "../../strings";
import { Button, Modal, Tooltip } from "antd";
import { useHistory } from "react-router-dom";

const milisecondsToDaysConst = 86400000;
const typesObj = {
  ngBasic: "basic-pay",
  ngPro: "pro-pay",
  ghBasic: "basic-pay-gh",
  ghPro: "pro-pay-gh",
  intBasic: "basic-pay-int",
  intPro: "pro-pay-int",
};

const SubscriptionNotification = ({ 
  user, 
  toggleRenewalModal, 
  allowToSub 
}) => {
  const [ openModal, setOpenModal ] = useState(false);
  const business = user.employer ? { ...user.employer } : { ...user };

  const country =
    business.currency === "NGN"
      ? "ng"
      : business.currency === "GHS"
      ? "gh"
      : "int";

  const subPaymentType = typesObj[country + business.subscription_plan];

  const daysLeft = Math.ceil(
    (new Date(business.subscription_expires_on) - Date.now()) /
      milisecondsToDaysConst
  );

  const history = useHistory();

  // const daysLeft = 15;

  const actionText =
    user.subscription_plan !== "Free" && daysLeft <= 0
      ? strings.renew
      : user.subscription_plan !== "Free" && daysLeft <= 7 && daysLeft > 0
      ? strings.renew
      : strings.upgrade;

  const style = {
    margin: "5px",
    height: "60%",
    padding: "2px 15px",
    background: "#70C274",
    border: "0",
    borderRadius: "5px",
    outline: "none",
    cursor: "pointer",
  }

  const isAdmin = user.role_id && user.role_id === 1;

  // const popAction = (
  //   <>
  //   <Tooltip title="Manually renew to continue your subscription">
  //     <Button
  //       type="primary"
  //       onClick={() => toggleRenewalModal()}
  //     >
  //       Pay
  //     </Button>
  //   </Tooltip>
  //   <Tooltip title="Automatically renews my subscription when due">
  //     <Button 
  //       type="primary" 
  //       style={{ marginLeft: 10 }} 
  //       onClick={()=>history.push("/d/account#subscribe")}
  //     >
  //       Subscribe
  //     </Button>
  //   </Tooltip>
  //   </>
  // )
 
  const actionBtn = (
    <Button type="primary" style={style} onClick={()=>setOpenModal(true)}>
      {actionText}
      {/* <Popover content={popAction} key="action">
        
      </Popover> */}
    </Button>
  );

  const banner =
    daysLeft > 7 ? (
      <span style={{ color: "#fff", textAlign: "center" }}>
        {strings.youAreOnFreePlan} {strings.clickOn} {actionBtn}
        {strings.to} {actionText} {strings.yourPlanInFewSteps}
      </span>
    ) : daysLeft <= 7 && daysLeft > 0 ? (
      <span style={{ color: "#fff", textAlign: "center" }}>
        {strings.yourSubscriptionExpiresIn} {daysLeft} {strings.days}.{" "}
        {strings.clickOn} {actionBtn} {strings.to} {actionText}{" "}
        {strings.yourPlanInFewSteps}
      </span>
    ) : (
      <span style={{ color: "#fff", textAlign: "center" }}>
        {strings.yourSubscriptionHasExpired} {strings.clickOn} {actionBtn}{" "}
        {strings.to} {actionText} {strings.yourPlanInFewSteps}
      </span>
    );

  const color =
    daysLeft > 7
      ? "orange"
      : daysLeft <= 7 && daysLeft > 0
      ? "#f6cb34"
      : "#fb929c";

  if (business.subscription_plan !== "Free" && daysLeft > 7) return null;

  if (user.ref_code?.toLowerCase() === "beepbeep") return null;

  // if (daysLeft > 7) return null;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: "30px",
          background: color,
          fontSize: "20px",
        }}
      >
        {banner}
      </div>
      <Modal
        open={openModal}
        onCancel={()=>setOpenModal(false)}
        title="Select Renewal Options"
        footer={null}
      >
        <h2 
        style={{
          textAlign: "center"
        }}>Choose renewal method</h2>
        <div 
        style={{
          textAlign: "center"
        }}>
          <Tooltip title="Manually renew to continue your subscription">
            <Button
              type="primary"
              onClick={() =>{
                toggleRenewalModal();
                setOpenModal(false);
              }}
            >
              Pay
            </Button>
          </Tooltip>
          {isAdmin && user.currency !== "NGN" && <Tooltip title="Automatically renews my subscription when due">
            <Button 
              type="primary" 
              style={{ marginLeft: 10 }} 
              onClick={()=>{
                allowToSub(true);
                setTimeout(()=>{
                  history.push("/d/account#subscribe");
                  setOpenModal(false);
                }, 1000);
              }}
            >
              Subscribe
            </Button>
          </Tooltip>}
          </div>
        </Modal>
    </>
  );
};

export default connect(null, { 
  toggleRenewalModal, 
  toggleSubscribeModal,
  allowToSub 
})(SubscriptionNotification);
