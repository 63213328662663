import React from "react";
import { Modal, Button, InputNumber } from "antd";
import { Form } from "@ant-design/compatible";
import strings from "../../../../../../strings";

const InputStaffModal = ({
  isInputStaffModalOpen,
  toggleInputStaffModal,
  setNumOfStaffs,
  setAddons,
  addons,
  form,
}) => {
  const { getFieldDecorator, validateFields } = form;

  const handleStaffInput = () => {
    validateFields((errors, values) => {
      if (errors) return;

      setNumOfStaffs(values.numOfStaffs);
      toggleInputStaffModal();
    });
  };

  return (
    <Modal
      title={strings.enterNumberOfStaffs}
      destroyOnClose
      visible={isInputStaffModalOpen}
      onCancel={() => {
        setAddons({ ...addons, hasStaffs: false });
        toggleInputStaffModal();
      }}
      footer={[
        <Button
          key="1"
          onClick={() => {
            setAddons({ ...addons, hasStaffs: false });
            toggleInputStaffModal();
          }}
        >
          {strings.cancel}
        </Button>,
        <Button key="2" type="primary" onClick={() => handleStaffInput()}>
          {strings.save}
        </Button>,
      ]}
    >
      <Form layout="vertical" >
        <Form.Item label={strings.enterNumberOfStaffsToPayFor}>
          {getFieldDecorator("numOfStaffs", {
            rules: [
              {
                required: true,
                type: "number",
                message: strings.pleaseEnterANumericValue,
              },
            ],
          })(<InputNumber size="large" style={{ width: "100%" }} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const InputStaffModalForm = Form.create()(InputStaffModal);

export default InputStaffModalForm;
