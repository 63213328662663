
import { ORDERED_ITEMS, ORDERED_ITEMS_LOADING, ORDERED_ITEMS_SUCCESS } from "../constants";

const initialState = {
    order_items: [],
    isItFetched: false
};

const orderItemsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDERED_ITEMS_LOADING:
      return {
        ...state,
        isItFetched: false,
      };
    break;
    case ORDERED_ITEMS_SUCCESS: 
      return {
        ...state,
        isItFetched: true,
      }
    case ORDERED_ITEMS:
      return {
        ...state,
        order_items: payload
      };
    default:
      return state;
  }
};

export default orderItemsReducer;
