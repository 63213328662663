import { Card, Button, TimePicker, Row, Col, Form, Modal, message } from "antd";
import { useState } from "react";
import {
  GET_WORK_HOURS,
  SET_WORK_HOURS,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";

const { RangePicker } = TimePicker;

const WorkHours = () => {
  const { loading, data } = useQuery(GET_WORK_HOURS);
  const [showDays, setShowDays] = useState(false);
  const [editHours, setEditHours] = useState(false);

  const [setWorkHours, { loading: isSetWorkHoursLoading }] = useMutation(
    SET_WORK_HOURS,
    {
      onCompleted: data => {
        setEditHours(false);

        data.setWorkHours &&
          Modal.success({
            title: "Work Hours set successfully",
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: GET_WORK_HOURS }],
    }
  );

  const workdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleSubmit = val => {
    let timeStrings = {};
    if (val["monday_friday"]) {
      workdays.forEach(
        day =>
          (timeStrings[day.toLowerCase()] =
            moment(val["monday_friday"][0]).format("hh:mma") +
            " - " +
            moment(val["monday_friday"][1]).format("hh:mma"))
      );
      timeStrings["sunday"] = "";
      timeStrings["saturday"] = "";
    } else {
      for (let day in val) {
        timeStrings[day.toLocaleLowerCase()] = val[day]
          ? moment(val[day][0]).format("hh:mma") +
            " - " +
            moment(val[day][1]).format("hh:mma")
          : "";
      }
    }
    setWorkHours({
      variables: { id: data?.getWorkHours?.id, data: timeStrings },
    });
  };

  return (
    <main>
      <Card
        title="Work Hours"
        bordered={false}
        loading={loading}
        extra={[
          <Button
            disabled={isSetWorkHoursLoading}
            onClick={() => setEditHours(!editHours)}
            type="primary"
          >
            {editHours ? "Done" : "Edit"}
          </Button>,
        ]}
      >
        <h4>Opening Hours</h4>

        {!editHours &&
          workdays.map(day => (
            <Row style={{ marginLeft: "20px" }}>
              <Col span={4}>{day}:</Col>
              <Col>
                {data?.getWorkHours
                  ? data.getWorkHours[day.toLowerCase()]
                    ? data.getWorkHours[day.toLowerCase()]
                    : "Not Open"
                  : "Not Open"}
              </Col>
            </Row>
          ))}

        {editHours && (
          <Form
            onFinish={handleSubmit}
            style={{ marginLeft: "30px" }}
            initialValues={data?.getWorkHours}
          >
            {!showDays && (
              <Form.Item
                name="monday_friday"
                label="Monday - Friday"
                rules={[{ required: true, message: "Enter open hours" }]}
              >
                <RangePicker
                  size="large"
                  format="HH:mm a"
                  placeholder={["Opening Time", "Closing Time"]}
                />
              </Form.Item>
            )}
            {showDays &&
              workdays.map(day => (
                <Form.Item name={day} key={day} label={day}>
                  <RangePicker
                    size="large"
                    format="HH:mm a"
                    placeholder={["Opening Time", "Closing Time"]}
                  />
                </Form.Item>
              ))}
            <Row>
              <Col span={13}>
                <Button onClick={() => setShowDays(!showDays)} type="ghost">
                  {!showDays ? "Set each day" : "Set Monday - Friday"}
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={isSetWorkHoursLoading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    </main>
  );
};

export default WorkHours;
