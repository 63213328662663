import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_GIFT_CARDS,
  DELETE_GIFT_CARD,
  EDIT_GIFT_CARD,
  LIST_GIFT_CARDS,
  SAVE_GIFT_CARD_TEMPLATE,
  GET_MERCHANT,
} from "../Apps/MultiLevelLoyalty/Loyalties/constants";
import { connect } from "react-redux";
import { getAllCustomers } from "../../../../redux/actions/customerActions";
import { setCurrentUser } from "../../../../redux/actions/authActions";
import {
  addNewProduct,
  getAllProducts,
} from "../../../../redux/actions/productActions";
import { transformInToFormObject } from "../../../../utils/generateFormData";
import {
  Card,
  Button,
  Tag,
  Modal,
  Table,
  Form,
  InputNumber,
  Upload,
  Popover,
  message,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  exportComponentAsJPEG,
  // exportComponentAsPDF,
  // exportComponentAsPNG,
} from "react-component-export-image";
import { SketchPicker } from "react-color";

import "./giftcard.scss";

const GiftCards = ({
  customers,
  getAllCustomers,
  products,
  addNewProduct,
  getAllProducts,
  user,
  setCurrentUser,
}) => {
  const [showGiftCards, setShowGiftCards] = useState(false);
  const [showGiftCardDetails, setShowGiftCardDetails] = useState(null);
  const [showCreateCards, setShowCreateCards] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [cardTemplateColor, setCardTemplateColor] = useState(null);

  useEffect(() => {
    getAllCustomers();
  }, []);

  useEffect(() => {
    if (!products || products.length < 1) {
      return getAllProducts();
    } else {
      const topupProduct = products.find(
        (one) => one.name === "Topup Gift Card"
      );
      if (!topupProduct) {
        addNewProduct(
          transformInToFormObject({
            name: "Topup Gift Card",
            price: 0,
            quantity: 0,
            merchant_product_category_id: 4,
          })
        );
      }
    }
  }, []);

  const { data: listCards, loading: listCardLoading } =
    useQuery(LIST_GIFT_CARDS);
  const { data: getMerchant, loading: getMerchantLoading } =
    useQuery(GET_MERCHANT);

  useEffect(() => {
    if (!user.gift_card_template_image_url) {
      !getMerchantLoading &&
        getMerchant.getMerchant &&
        setCurrentUser({ ...user, ...getMerchant.getMerchant });
    }
  }, []);

  const [form] = Form.useForm();

  const componentRef = useRef();

  const giftCards = listCards?.listGiftCards || [];
  const unassignedaCards = giftCards?.filter(
    (card) => !!card.customer_id === false
  );
  const assignedaCards = giftCards?.filter(
    (card) => !!card.customer_id === true
  );

  const [addCards, { loading: addCardsLoading }] = useMutation(ADD_GIFT_CARDS, {
    onCompleted: (data) => {
      form.resetFields();
      console.log({ addCard: data });
      Modal.success({
        title: "Cards created successfully",
        onOk: () => setShowCreateCards(false),
      });
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
    refetchQueries: [LIST_GIFT_CARDS],
  });

  const [deleteCard] = useMutation(DELETE_GIFT_CARD, {
    onCompleted: (data) => {
      data.deleteGiftCard &&
        Modal.success({
          title: "Card deleted successfully",
          onOk: () => setShowGiftCardDetails(null),
        });
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
    refetchQueries: [LIST_GIFT_CARDS],
  });

  const [editCard, { loading: editCardLoading }] = useMutation(EDIT_GIFT_CARD, {
    onCompleted: (data) => {
      if (data.editGiftCard) {
        Modal.success({
          title: `You have successfully edited gift card - ${data.editGiftCard.card_number}`,
          onOk: () => setShowGiftCardDetails(null),
        });
      }
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
    refetchQueries: [LIST_GIFT_CARDS],
  });

  const [saveGiftCardTemplate, { loading: saveGiftCardTemplateLoading }] =
    useMutation(SAVE_GIFT_CARD_TEMPLATE, {
      onCompleted: (data) => {
        if (data.saveGiftCardTemplate) {
          setCurrentUser({ ...user, ...data.saveGiftCardTemplate });
          Modal.success({
            title: `You have successfully saved gift card template`,
          });
        }
      },
      onError: (error) => {
        message.error(error.message, 5);
      },
      refetchQueries: [GET_MERCHANT],
    });

  const createGiftCards = () =>
    form.validateFields().then((val) => {
      return addCards({ variables: { number: val.number } });
    });

  const deleteGiftCard = (card) => {
    Modal.confirm({
      title: "Delete Gift Card",
      content:
        "Are you sure you want to delete this gift card? This action is irreversible.",
      onOk: async () => deleteCard({ variables: { id: card.id } }),
    });
  };

  const unAssignGiftCard = (card) => {
    Modal.confirm({
      title: "Unassign Gift Card",
      content: `Are you sure you want to unassign this gift card from ${getCustomerNameFromId(
        card.customer_id
      )}?. This action is irreversible.`,
      onOk: async () =>
        editCard({ variables: { data: { id: card.id, customer_id: null } } }),
    });
  };

  const activateGiftCard = async () =>
    editCard({
      variables: { data: { id: showGiftCardDetails.id, isActive: true } },
    });
  const deActivateGiftCard = async () =>
    editCard({
      variables: { data: { id: showGiftCardDetails.id, isActive: false } },
    });

  const columns = [
    {
      title: "Card Number",
      dataIndex: "card_number",
      key: "card_number",
      defaultSortOrder: "ascending",
      sorter: (a, b) => a.card_number - b.card_number,
    },
    {
      title: "Status",
      dataIndex: "customer_id",
      key: "customer_id",
      render: (data) =>
        data ? (
          <Tag color="green">Assigned</Tag>
        ) : (
          <Tag color="red">Unassigned</Tag>
        ),
      filters: [
        {
          text: "Assigned",
          value: true,
        },
        {
          text: "Unassigned",
          value: false,
        },
      ],
      onFilter: (value, record) => !!record.status === value,
    },
    {
      title: "Created On",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "ascending",
      render: (data) => new Date(data).toLocaleString(),
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      title: "Expires On",
      dataIndex: "expiry_date",
      key: "expiry_date",
      defaultSortOrder: "ascending",
      render: (data) => new Date(Number(data)).toLocaleString(),
      sorter: (a, b) =>
        new Date(Number(a.expiry_date)) - new Date(Number(b.expiry_date)),
    },
  ];

  const menu = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        key={5}
        disabled={
          !showGiftCardDetails?.customer_id || showGiftCardDetails?.isActive
        }
        loading={editCardLoading}
        onClick={activateGiftCard}
      >
        Activate card
      </Button>
      <Button
        key={6}
        disabled={
          !showGiftCardDetails?.customer_id || !showGiftCardDetails?.isActive
        }
        loading={editCardLoading}
        onClick={deActivateGiftCard}
      >
        Deactivate card
      </Button>
      <Button
        key={1}
        icon={<EditOutlined />}
        onClick={() => unAssignGiftCard(showGiftCardDetails)}
        disabled={!showGiftCardDetails?.customer_id}
      >
        Unassign card
      </Button>
      <Button
        key={2}
        icon={<DeleteOutlined />}
        onClick={() => deleteGiftCard(showGiftCardDetails)}
      >
        Delete card
      </Button>
      <Button
        onClick={() =>
          exportComponentAsJPEG(componentRef, {
            fileName: showGiftCardDetails.card_number,
          })
        }
      >
        Export As JPG
      </Button>
      <Button key={3} disabled={!showGiftCardDetails?.customer_id}>
        Credit card
      </Button>
    </div>
  );

  const getCustomerNameFromId = (id) => {
    const customer = customers.find((cstmr) => cstmr.id === id);
    return `${customer?.first_name} ${customer?.last_name || ""}`;
  };

  const uploadProps = {
    name: "image",
    action: `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/upload`,
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} uploaded successfully`);
        setImageUrl(info.file.response.imageCloudData.url);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed`);
      }
    },
  };

  const saveGiftCardUrl = () => {
    if (!imageUrl)
      return message.error("Upload a gift card template image to continue!");
    return saveGiftCardTemplate({
      variables: {
        data: {
          gift_card_template_image_url: imageUrl,
          gift_card_template_text_color: cardTemplateColor,
        },
      },
    });
  };

  return (
    <Card
      title="Gift Cards"
      loading={listCardLoading}
      extra={[
        <Button onClick={() => setShowCreateCards(true)}>
          Create Gift Cards
        </Button>,
      ]}
    >
      <p>Total Cards Generated: {giftCards.length}</p>
      {giftCards.length > 0 && (
        <p>Unassigned Cards: {unassignedaCards.length}</p>
      )}
      {giftCards.length > 0 && <p>Assigned Cards: {assignedaCards.length}</p>}

      <Button onClick={() => setShowGiftCards(true)}>Show All Cards</Button>

      <div>
        <p style={{ marginTop: 20 }}>
          <b>Gift Card Template</b>
        </p>

        <div className="gift-card">
          <div style={{ position: "absolute" }}>
            <div
              className="card-number"
              style={{
                color:
                  cardTemplateColor ||
                  user.gift_card_template_text_color ||
                  "#fff",
                marginTop: "35%",
                marginLeft: "25%",
              }}
            >
              <span>0000</span>
              <span>0000</span>
              <span>0000</span>
              <span>0000</span>
            </div>
            <div
              className="card-expiry"
              style={{
                color:
                  cardTemplateColor ||
                  user.gift_card_template_text_color ||
                  "#fff",
                marginTop: "30px",
                marginLeft: "20px",
              }}
            >
              <span>
                <b>VALID THRU</b>
              </span>
              <p>
                <b style={{ marginLeft: 15 }}>00/00</b>
              </p>
            </div>
          </div>
          <img
            src={
              imageUrl ||
              user.gift_card_template_image_url ||
              "https://loystar.co/wp-content/uploads/2022/06/default_loystar_giftcard.png"
            }
            alt="card template"
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Upload
            listType="picture"
            multiple={false}
            {...uploadProps}
            onRemove={() =>
              setImageUrl(
                "https://loystar.co/wp-content/uploads/2022/06/default_loystar_giftcard.png"
              )
            }
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
        </div>

        <div style={{ marginTop: 20 }}>
          <p>
            <b>Change Text Color</b>
          </p>
          <SketchPicker
            color={cardTemplateColor || "#fff"}
            onChangeComplete={(val) => setCardTemplateColor(val.hex)}
          />
          {/* <Input
            value={cardTemplateColor || "#fff"}
            onChange={(val) => setCardTemplateColor(val)}
            size="large"
          /> */}
        </div>
        <Button
          style={{ marginTop: 20 }}
          type="primary"
          onClick={saveGiftCardUrl}
          loading={saveGiftCardTemplateLoading}
        >
          Save template
        </Button>
      </div>

      {showGiftCards && (
        <Modal
          title="Gift Cards"
          visible={showGiftCards}
          width={600}
          onCancel={() => setShowGiftCards(false)}
          footer={[
            <Button type="ghost" onClick={() => setShowGiftCards(false)}>
              Cancel
            </Button>,
          ]}
        >
          {!giftCards.length && (
            <div>
              <p>
                You have no gift cards available. Contact admin for support.
              </p>
              <p>
                {" "}
                <a href="mailto: hello@loystar.co">Send Email</a>
              </p>
            </div>
          )}
          <h4>Total Gift Cards: {giftCards.length} </h4>

          <Table
            dataSource={giftCards}
            columns={columns}
            onRow={(val) => ({ onClick: () => setShowGiftCardDetails(val) })}
          />
        </Modal>
      )}
      {showCreateCards && (
        <Modal
          title="Create Gift Cards"
          visible={showCreateCards}
          onCancel={() => setShowCreateCards(false)}
          footer={[
            <Button onClick={() => setShowCreateCards(false)}>Cancel</Button>,
            <Button
              type="primary"
              onClick={createGiftCards}
              loading={addCardsLoading}
            >
              Create
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              label="Number of Cards"
              name="number"
              rules={[
                {
                  required: true,
                  message: "Enter number of cards to create to continue",
                },
              ]}
            >
              <InputNumber size="large" style={{ width: "100%" }} />
            </Form.Item>
          </Form>
        </Modal>
      )}
      {showGiftCardDetails && (
        <Modal
          visible={!!showGiftCardDetails}
          title="Gift Card Details"
          width={700}
          onCancel={() => setShowGiftCardDetails(null)}
          footer={[
            <Button onClick={() => setShowGiftCardDetails(null)}>
              Cancel
            </Button>,
            <Popover key="actions" title="Actions" content={menu}>
              <Button type="primary" id="btn-component" key="action-btn">
                Actions
              </Button>
            </Popover>,
          ]}
        >
          <p>
            <b>Card Number: </b>
            {showGiftCardDetails.card_number}
          </p>
          <p>
            <b>Amount: </b>
            {showGiftCardDetails.amount}
          </p>
          <p>
            <b>Customer: </b>
            {showGiftCardDetails.customer_id
              ? getCustomerNameFromId(showGiftCardDetails.customer_id)
              : "Not assigned"}
          </p>
          <p>
            <b>Active: </b>
            {showGiftCardDetails.isActive.toString()}
          </p>
          <p>
            <b>Created at: </b>
            {new Date(showGiftCardDetails.created_at).toLocaleString()}
          </p>
          <p>
            <b>Expires on: </b>
            {new Date(Number(showGiftCardDetails.expiry_date)).toLocaleString()}
          </p>

          <div className="gift-card" ref={componentRef}>
            <div style={{ position: "absolute" }}>
              <div
                className="card-number"
                style={{
                  color:
                    cardTemplateColor ||
                    user.gift_card_template_text_color ||
                    "#fff",
                  marginTop: "35%",
                  marginLeft: "25%",
                  width: "100%",
                }}
              >
                <span>{showGiftCardDetails.card_number.slice(0, 4)}</span>
                <span>{showGiftCardDetails.card_number.slice(4, 8)}</span>
                <span>{showGiftCardDetails.card_number.slice(8, 12)}</span>
                <span>{showGiftCardDetails.card_number.slice(12, 16)}</span>
              </div>
              <div
                className="card-expiry"
                style={{
                  color:
                    cardTemplateColor ||
                    user.gift_card_template_text_color ||
                    "#fff",
                  marginTop: "30px",
                  marginLeft: "20px",
                }}
              >
                <span>
                  <b>VALID THRU</b>
                </span>
                <p>
                  <b style={{ marginLeft: 15 }}>
                    {" "}
                    {new Date(Number(showGiftCardDetails.expiry_date))
                      .toLocaleDateString()
                      .slice(3)
                      .slice(0, 3) +
                      new Date(Number(showGiftCardDetails.expiry_date))
                        .toLocaleDateString()
                        .slice(3)
                        .slice(5)}
                  </b>
                </p>
              </div>
            </div>
            <img
              src={
                imageUrl ||
                user.gift_card_template_image_url ||
                "https://loystar.co/wp-content/uploads/2022/06/default_loystar_giftcard.png"
              }
              alt="card template"
            />
          </div>
        </Modal>
      )}
    </Card>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customer.allCustomers,
  products: state.product.allProducts,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getAllCustomers,
  getAllProducts,
  setCurrentUser,
  addNewProduct,
})(GiftCards);
