import React from "react";
import { Card, message, Button, Modal, Input, Tabs } from "antd";
import { connect } from "react-redux";
import { Icon } from "@ant-design/compatible";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getAllOffers, getBirthdayMessage, createBirthdayOffer, editBirthdayMessage } from "../../../../redux/actions/birthdayOfferActions";
import "./index.scss";
import strings from "../../../../strings";

const { TabPane } = Tabs;

class BirthdayAndOffers extends React.Component {
  state = {
    loading: false,
    openOfferModal: false,
    openMessageModal: false,
    offer: null,
    insertPoint: 0,
    isEdit: false,
    isLoading:false,
    messageValue:
      "Dear [CUSTOMER_NAME]," + strings.thisIsToWishYouHappyBirthday,
    message_id: null
  };

  toggleCreateOfferModal = this.toggleCreateOfferModal.bind(this);
  toggleMessageModal = this.toggleMessageModal.bind(this);
  createOffer = this.createOffer.bind(this);
  insertValue = this.insertValue.bind(this);
  upDateOfferMessage = this.upDateOfferMessage.bind(this);

  componentDidMount() {
    this.handleGetBirthdayMessage();
    this.props
      .getAllOffers(this.props.user.id)
      .then(result => {
        this.setState({ offer:  result.data.offer_description });
      })
      .catch(error => {
        console.log(error, "=-=-=-=-=-err=-=-=-=-=-");
      });
  }

  toggleCreateOfferModal(action="create") {
    this.setState({
      openOfferModal: !this.state.openOfferModal,
      isEdit: action === "create" ? false : true
    });
  }

  handleGetBirthdayMessage = () => {
    const { getBirthdayMessage } = this.props
    this.setState({ loading: true })
    getBirthdayMessage()
    .then(res => {
      if(res.status === 200){
        
        this.setState({ 
          loading: false,
          messageValue: res.data.preset_sms_text,
          message_id: res.data.id 
        })
      }
    })
    .catch(err => {
      this.setState({ 
        loading: false 
      });
      console.log("err", err.message);
      
    })
  }

  handleCreateBirthdayOffer = () => {
    const { createBirthdayOffer } = this.props;
    this.setState({ isLoading: true });

    createBirthdayOffer({ offer_description: this.state.offer })
    .then(res => {
      if(res.status === 200){
        this.setState({ 
          isLoading: false,
          offer: res.data.offer_description 
        });

        this.toggleCreateOfferModal("edit");
      }
    })
    .catch(err => {
      this.setState({ 
        isLoading: false 
      });
      console.log("err", err.message);
      
    })
  }

  createOffer() {
    const offer = this.state.offer.trim();
    if (offer) {
      this.props.createOffer();
    } else {
      message.error(strings.inputAnOfferPlease);
    }
  }
  toggleMessageModal() {
    this.setState({
      openMessageModal: !this.state.openMessageModal,
    });
  }

  insertValue(type) {
    const { messageValue, insertPoint } = this.state;
    if (!insertPoint) {
      message.info(strings.pleaseSelectTheInsertPoint);
      return;
    }
    const firstHalf = messageValue.substring(0, insertPoint);
    const secondHalf = messageValue.substring(insertPoint);
    //${secondHalf} ${secondHalf}
    if (type === "name") {
      this.setState({
        messageValue: `${messageValue} [CUSTOMER_NAME]`,
      });
    } else {
      this.setState({
        messageValue: `${messageValue} [BIRTHDAY_OFFER]`,
      });
    }
  }

  upDateOfferMessage() {
    const { editBirthdayMessage, user } = this.props;
    const { message_id } = this.state;
    this.setState({ isLoading: true });
    editBirthdayMessage(message_id, this.state.messageValue)
    .then(res => {
      if(res.status === 200){
        this.setState({ 
          isLoading: false,
          messageValue: res.data.preset_sms_text 
        });

        this.handleGetBirthdayMessage();

        this.toggleMessageModal();
      }
    })
    .catch(err => {
      this.setState({ 
        isLoading: false 
      });
      console.log("err", err.message);
      
    })
    
  }

  render() {
    const {
      loading,
      openOfferModal,
      openMessageModal,
      messageValue,
      offer,
      isEdit
    } = this.state;

    return (
      <main>
        <Card
          title={strings.birthdayOfferAndMessage}
          loading={loading}
          extra={<div></div>}
        >
          <section className="invoices-table mt-40">
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab={strings.birthdayOffer} key="1">
                <div>
                  {offer && <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "60%"}}>
                        <p>{offer}</p>
                        <div style={{ display: "flex", alignItems: "center", gap:5}}>
                          <EditOutlined onClick={() => this.toggleCreateOfferModal("edit")} style={{cursor: "pointer"}} />
                          <DeleteOutlined />
                        </div>
                    </div>}
                  {!offer && <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1>{strings.noOfferFound}</h1>
                    <span>{strings.settingABirthdayOffer}</span>
                    <Button onClick={() => this.toggleCreateOfferModal("create")}>
                      {strings.createOffer}
                    </Button>
                  </div>}
                </div>
              </TabPane>
              <TabPane tab={strings.birthdayMessage} key="2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {messageValue}
                  <Button onClick={this.toggleMessageModal}>
                    <Icon type="edit" />
                    {strings.edit}
                  </Button>
                </div>
              </TabPane>
            </Tabs>
          </section>
        </Card>
        <Modal
          title={isEdit ? strings.editOffer : strings.createOffer}
          cancelText={strings.cancel}
          open={openOfferModal}
          destroyOnClose
          onCancel={() => this.toggleCreateOfferModal()}
          okText={isEdit ? strings.editOffer : strings.createOffer}
          onOk={this.handleCreateBirthdayOffer}
          okButtonProps={{loading: this.state.isLoading}}
        >
          <label htmlFor="">{strings.whatIsTheOffer}</label>
          <Input
            placeholder=""
            name="offer"
            onChange={event => {
              this.setState({
                offer: event.target.value,
              });
            }}
            value={this.state.offer}
          />
        </Modal>
        <Modal
          title={strings.editMessage}
          cancelText={strings.cancel}
          open={openMessageModal}
          destroyOnClose
          onCancel={this.toggleMessageModal}
          okText={strings.save}
          onOk={this.upDateOfferMessage}
          okButtonProps={{loading: this.state.isLoading}}
        >
          <Input.TextArea
            onMouseUp={event => {
              this.setState({
                insertPoint: event.target.selectionStart,
              });
            }}
            onChange={(e) => this.setState({ messageValue: e.target.value })}
            rows={4}
            placeholder=""
            name="message"
            value={messageValue}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Button
              onClick={() => {
                this.insertValue("name");
              }}
              style={{ margin: "10px 0" }}
            >
              {strings.insert} CUSTOMER_NAME
            </Button>
            <Button
              onClick={() => {
                this.insertValue("offer");
              }}
              style={{ margin: "10px 0" }}
            >
              {strings.insert} BIRTHDAY_OFFER
            </Button>
          </div>
          <div>
            [CUSTOMER_NAME]: {strings.theNameOfCustomerInserted}
            <br />
            [BIRTHDAY_OFFER]: {strings.theBirtdayOfferInserted}
          </div>
        </Modal>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  invoices: state.invoice.allInvoices,
  user: state.auth.user,
  error: state.error,
  products: state.product.allProducts,
  customers: state.customer.allCustomers,
});

export default connect(mapStateToProps, {
  getAllOffers,
  getBirthdayMessage,
  createBirthdayOffer,
  editBirthdayMessage
})(BirthdayAndOffers);
