
import { MERCHANT_DETAILS } from "../constants";

const initialState = {
    merchant_details: {},
};

const merchantDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MERCHANT_DETAILS:
      return {
        ...state,
        merchant_details: payload,
      };

    default:
      return state;
  }
};

export default merchantDetailsReducer;
