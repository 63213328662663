import React, { Fragment } from 'react'
import numberFormatter from "../../../../../utils/numberFormatter";

const PaymentMethodTotal = ({ user, totalPaymentWithCard, totalPaymentWithCash, totalPaymentWithMobile, totalPaymentWithMTransfer, filterValue }) => {

  const getPaymentMethodTotal = () => {
    // const { user } = this.props
    // const { totalPaymentWithCard, totalPaymentWithCash, totalPaymentWithMobile, totalPaymentWithMTransfer, filterValue } = this.state

    if (!filterValue) return;

    const currency = user.currency || user.employer.currency

    if (filterValue === "Cash") {
      return (
        <span style={{ margin: "0 10px" }}>
          Total Cash Sales: {currency}
          {numberFormatter(totalPaymentWithCash || 0)}
        </span>
      )
    } else if (filterValue === "Card") {
      return (
        <span style={{ margin: "0 10px" }}>
          Total Card Sales: {currency}
          {numberFormatter(totalPaymentWithCard || 0)}
        </span>
      )
    } else if (filterValue === "Bank Transfer (USSD)") {
      return (
        <span style={{ margin: "0 10px" }}>
          Total Transfer (USSD) Sales: {currency}
          {numberFormatter(totalPaymentWithMobile || 0)}
        </span>
      )
    } else {
      return (
        <span style={{ margin: "0 10px" }}>
          Total Transfer Sales: {currency}
          {numberFormatter(totalPaymentWithMTransfer || 0)}
        </span>
      )
    }
  }

  return (
    <Fragment>
      {getPaymentMethodTotal()}
    </Fragment>
  )
}

export default PaymentMethodTotal
