import React from "react";
import strings from "../../../../../../strings";
import numberFormatter from "../../../../../../utils/numberFormatter";

const BasePrice = ({ plan, base_price }) => {
  return (
    <p>
      <span>{strings.basePrice}: </span>
      <span>{`${plan && plan.currency}${numberFormatter(
        base_price ? base_price.toFixed(2) : base_price
      )}`}</span>
    </p>
  );
};

export default BasePrice;
