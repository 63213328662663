import { 
  TOGGLE_RENEWAL_MODAL, 
  TOGGLE_SUBSCRIBE_MODAL, 
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_ACCESS
} from "../constants";
import axios from "axios";
export const toggleRenewalModal = () => dispatch => {
  dispatch({
    type: TOGGLE_RENEWAL_MODAL,
  });
};

export const toggleSubscribeModal = () => dispatch => {
  dispatch({
    type: TOGGLE_SUBSCRIBE_MODAL,
  });
};

export const checkSubscriptionStatus = ( merchant_id ) => async dispatch => {
  await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/subscription-status`, { merchant_id: merchant_id.toString() }, {
      headers: {
          ["Access-Control-Allow-Origin"]: "*",
          ["multi-level-token"]: "",
          ["user-auth"]: "",
      }
  })
  .then((res)=>{
    if(res.data.subscription.length > 0){
      const subscription = res.data.subscription[0];
      if(subscription.status === "active"){
        dispatch(setSubscriptionStatus("active"));
        dispatch(allowToSub(true))
  
        localStorage.setItem("stripe_subscription_id", JSON.stringify(subscription.id));

      }else{
        dispatch(setSubscriptionStatus("incomplete"))
        dispatch(allowToSub(true))
      }
    }else{
      dispatch(setSubscriptionStatus("not_active"));
      // dispatch(allowToSub(false))
    }
  })
  .catch((err)=>{
    console.log(err);
  })
}

export const setSubscriptionStatus = ( payload ) => dispatch => {
  dispatch({
    type: SUBSCRIPTION_STATUS,
    payload: payload
  });
};

export const allowToSub = ( payload ) => dispatch => {
  dispatch({
    type: SUBSCRIPTION_ACCESS,
    payload
  })
}
