import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, message } from "antd";
import { useQuery } from "@apollo/client";
import CustomTable from "../../../../../../components/CustomDataTable";
import {
  LIST_TIERED_CUSTOMERS,
  LIST_LOYALTY_CUSTOMERS,
  LIST_LOYALTIES,
} from "../Loyalties/constants";
import MemberDetailModal from "./_partials/MemberDetailModal";
import strings from "../../../../../../strings";

const Members = ({ user }) => {
  const [customerData, setCustomerData] = useState([]);
  const [currentMember, setCurrentMember] = useState(null);
  const [activeLoyaltyId, setActiveLoyaltyId] = useState(null);

  const [isViewMemberModalOpen, setIsViewMemberModalOpen] = useState(false);

  const { loading: isCustomerLoading, data } = useQuery(LIST_TIERED_CUSTOMERS, {
    onError: (error) => {
      message.error(error.message, 5);
    },
    fetchPolicy: "network-only",
  });

  const { data: result } = useQuery(LIST_LOYALTIES, {
    onError: (error) => {
      message.error(error.message, 5);
    },
    fetchPolicy: "network-only",
  });
  const { data: loyaltyData } = useQuery(LIST_LOYALTY_CUSTOMERS, {
    variables: { loyalty_id: activeLoyaltyId },
    onError: (error) => {
      message.error(error.message, 5);
    },
    fetchPolicy: "network-only",
    skip: !activeLoyaltyId,
  });

  useEffect(() => {
    const multiLevelLoyalties = result
      ? result.listLoyalties
        ? result.listLoyalties
        : []
      : [];
    const activeLoyalty =
      multiLevelLoyalties.length > 0 &&
      multiLevelLoyalties.find(
        (loyalty) => loyalty.status.toLowerCase() === "active"
      );
    if(activeLoyalty && activeLoyalty.owner_id){
      if (user.id !== activeLoyalty.owner_id) {
        setActiveLoyaltyId(activeLoyalty.id);
      }
    }
  }, [result, user.id]);

  useEffect(() => {
    if (data) {
      const customerData = data.listTieredCustomers.map((customer, idx) => {
        return {
          ...customer,
          key: customer ? customer.id : idx,
        };
      });
      setCustomerData(customerData);

      if (loyaltyData) {
        const loyaltyCustomers = loyaltyData.listLoyaltyCustomers.map(
          (customer, idx) => {
            return { ...customer, key: customer ? customer.id : idx };
          }
        );
        setCustomerData(loyaltyCustomers);
      }
    }
  }, [data, loyaltyData]);

  const toggleViewMemberModal = () => setIsViewMemberModalOpen((open) => !open);

  const columns = [
    {
      title: strings.firstName,
      dataIndex: "first_name",
      key: "first_name",
      render: (data) => data || "N/A",
    },
    {
      title: strings.lastName,
      dataIndex: "last_name",
      key: "last_name",
      render: (data) => data || "N/A",
    },
    {
      title: strings.email,
      dataIndex: "email",
      key: "email",
      render: (data) => data || "N/A",
    },
  ];

  return (
    <section className="apps-multilevel-members">
      <Card title={strings.allEnrolledMembers}>
        <CustomTable
          loading={isCustomerLoading}
          columns={columns}
          dataSource={customerData}
          currentMember={currentMember}
          onRow={(record) => {
            return {
              onClick: () => {
                setCurrentMember(record);
                toggleViewMemberModal();
              },
            };
          }}
        />
      </Card>

      {isViewMemberModalOpen ? (
        <MemberDetailModal
          visible={isViewMemberModalOpen}
          closeModal={toggleViewMemberModal}
          currentMember={currentMember}
        />
      ) : null}
    </section>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(Members);
