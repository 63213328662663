import { Modal, Form, Select, Card, Button, message, InputNumber, Table } from 'antd'
import React, { useEffect, useMemo, useState } from 'react';
import "./index.scss";
import strings from '../../../../../../strings';
import { getAllPurchaseOrder, receivePurchaseInventory } from '../../../../../../redux/actions/purchaseOrder';
import { getSuppliers } from '../../../../../../redux/actions/productActions';
import { connect } from 'react-redux';

const AcceptPurchaseOrder = ({
  open,
  user,
  onCancel,
  getAllPurchaseOrder,
  getSuppliers,
  allProducts,
  receivePurchaseInventory
}) => {
  const [ step, setStep ] = useState(1);
  const [ selectedPurchaseOrder, setSelectedPurchaseOrder ] = useState(null);
  const [ selectedProducts, setSelectedProducts ] = useState(null);
  const [ allPurchaseOrders, setAllPurchaseOrders ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    handleGetAllPurchaseOrder();
  }, []);

  const productMemo = useMemo(()=> selectedProducts, [selectedProducts]);

  const updateReceivedQuantity = (id, quantity) => {
    setSelectedProducts(selectedProducts.map(product => 
      product.id === id ? { ...product, received_quantity: quantity } : product
    ))
  }

  const addColumn = [
    {
      key: "1",
      title: strings.productName,
      dataIndex: "name",
    },
    {
      key: "2",
      title: strings.orderedQuantity,
      dataIndex: "ordered_quantity",
    },
    {
      key: "3",
      title: strings.receivedQuntity,
      dataIndex: "received_quantity",
      render(value, record){
        return (<div>
          <InputNumber 
            onChange={(value) => {
              updateReceivedQuantity(record.id, value);
            }}
            value={value}
          />
        </div>)
      }
    },
    {
      key: "4",
      title: strings.currentStock,
      dataIndex: "name",
      render(value, record){
        const currenProds = allProducts && allProducts.find(prod => prod.id == record.product_id);
        return ( 
          <span>{currenProds && currenProds.quantity || 0}</span>
        )
      }
    }
  ]

  const receiveColumn = [
    {
      key: "1",
      title: strings.productName,
      dataIndex: "name",
    },
    {
      key: "2",
      title: strings.receivedQuntity,
      dataIndex: "received_quantity",
    },
    {
      key: "3",
      title: strings.currentStock,
      dataIndex: "current_stock",
      render(value, record){
        const currenProds = allProducts && allProducts.find(prod => prod.id == record.product_id);
        return ( 
          <span>{currenProds && currenProds.quantity || 0}</span>
        )
      }
    },
    {
      key: "4",
      title: strings.newStock,
      dataIndex: "name",
      render(value, record){
        const currenProds = allProducts && allProducts.find(prod => prod.id == record.product_id);
        return ( 
          <span>{Number(currenProds && currenProds.quantity || 0) + Number(record.received_quantity)}</span>
        )
      }
    }
  ]

  const handleGetAllPurchaseOrder = () => {
    setLoading(true)
    getAllPurchaseOrder()
    .then(res => {
      if(res.status === 200 || res.status === 201){
        setLoading(false);
        setAllPurchaseOrders(() => res.data.filter(data => data.status === "supply_delivered"));
      }
    })
    .catch(err=> {
        setLoading(false);
        message.error(err?.response?.data?.message || err.message);
        console.log("err", err);
        
    })
  }

  const handleReceiveInventory = () => {
    const newProds = [...selectedProducts]
    const received_items = newProds.map((prod) => {
      delete prod.name;
      delete prod.price;
      delete prod.product_id;
      delete prod.ordered_quantity;
      return prod;
    })

    const data = {
      received_items
    }
    setLoading(true);
    receivePurchaseInventory(selectedPurchaseOrder, data)
    .then(res => {
      if(res.status === 200 || res.status === 201){
        Modal.success({
          title: strings.itemsHaveBeenReceivedSuccessfully,
          onOk: () => {
            setLoading(false);
            onCancel();
          }
        });
      }
    })
    .catch(err=> {
      setLoading(false);
      message.error(err?.response?.data?.message || err.message);
      console.log("err", err);
        
    })
  }

  const renderStep = () => {
    switch (step) {
      case 1:
      return (
        <Card
          title={strings.step1AcceptPurchaseOrder}
          bordered
        >
          <div className='ordersbody'>
            <div className='fullwidth'>
              <Select 
                onChange={(value) => {
                  setSelectedPurchaseOrder(value);
                  const selectedItem = allPurchaseOrders.find(orders => orders.id == value);
                  selectedItem && setSelectedProducts(selectedItem.purchase_order_items);
                  console.log("sle", selectedItem.purchase_order_items);
                  
                }}
                placeholder={strings.purchaseOrder}
                size='large'
                style={{width: "100%"}}
                value={selectedPurchaseOrder}
              >
                {allPurchaseOrders && allPurchaseOrders.map((purchase, index) => {
                    return (
                    <Select.Option key={index} value={purchase.id}>
                      PO{purchase.id && purchase.id} - {purchase.user && purchase.user.first_name || purchase.supplier && purchase.supplier.business_name} {purchase.user && purchase.user.last_name && purchase.user.last_name ||  ""}
                    </Select.Option>)
                })}
              </Select>
            </div>
                
            <div className='fullwidth'>
              <Button 
                onClick={() => setStep(2)} 
                disabled={!selectedPurchaseOrder}
                type='primary'
              >
                {strings.next}
              </Button>
            </div>
          </div>
        </Card>
      )
      case 2:
      return (
      <Card
          title={strings.step2AcceptPurchaseOrder}
          bordered
      >
        <div className='ordersbody'>
          <div>
              <Table 
                columns={addColumn}
                dataSource={productMemo}
                pagination={false}
              />
          </div>
          <div className='fullwidth flexJustify'>
              <Button 
                  onClick={() => setStep(1)} 
                    type='primary'
              >
                      {strings.back}
              </Button>
              <Button 
                  onClick={() => setStep(3)} 
                    type='primary'
              >
                      {strings.next}
              </Button>
          </div>
        </div>
      </Card>
      )
      case 3:
      return (
          <Card
              title={strings.step3AcceptPurchaseOrder}
              bordered
          >
        <div className='ordersbody'>
              <div>
                  <Table 
                      columns={receiveColumn}
                      dataSource={productMemo}
                      // summary={(data) => {
                      //     return (
                      //         <div style={{width: "100%"}}>
                      //         <span>{strings.totalAmount}: </span>
                      //         <span>{user.employer ? user.employer.currency : user.currency} {calculateTotal()}</span>
                      //     </div>
                      //     )
                      // }}
                      pagination={false}
                  />
                
              </div>
              <div className='fullwidth flexJustify'>
                  <Button 
                      onClick={() => setStep(2)} 
                      type='primary'
                  >
                    {strings.back}
                  </Button>
                  <Button 
                      onClick={handleReceiveInventory} 
                      type='primary'
                      loading={loading}
                  >
                      {strings.confirmAndUpdate}
                  </Button>
              </div>
          </div>
          </Card>
      )
    } 
  }
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={strings.receiveSuppliesAndUpdateInventory}
      footer={null}
    >
      <div>
          {/* <h1>{strings.receiveSuppliesAndUpdateInventory}</h1> */}
          {renderStep()}
      </div>
    </Modal>

  )
}

const mapStateToProps = (state) => ({
  suppliers: state.product.suppliers,
});

export default connect(mapStateToProps, { getSuppliers, getAllPurchaseOrder,receivePurchaseInventory })(AcceptPurchaseOrder);