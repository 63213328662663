export default class Category {
  constructor(client) {
    this.client = client;
  }

  getAllCategories(time_stamp) {
    return this.client.post("/get_latest_merchant_product_categories", {
      data: { time_stamp },
    });
  }

  addNewCategory(categoryData) {
    return this.client.post("/add_product_category", { data: categoryData });
  }

  editCategory(id, data) {
    return this.client.put(`/merchant_product_categories/${id}`, {
      data: data,
    });
  }

  deleteCategory(id) {
    return this.client.post(
      `/merchant_product_categories/set_delete_flag_to_true/${id}`
    );
  }
}
