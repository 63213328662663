import { Button, Form, Input, InputNumber, Modal, Select } from 'antd'
import React, { useState } from 'react'
import strings from '../../../../../strings';
import "./index.scss";
import ManageReturnModal from '../../Products/_partials/ManageReturnModa';

const FormItem = Form.Item
const PurchaseDetails = ({
    open,
    onCancel,
    purchaseOrder,
    user,
    returnPurchaseOrder,
    refetch
}) => {
  const [form] = Form.useForm();
  const [ loading, setLoading ] = useState(false);
  const [ isOthers, setIsOthers ] = useState(false);
  const [ showReturnModal, setShowReturnModal ] = useState(false);
  const [ selectedItem, setSelectedItem ] = useState(null);
  const reasons = [strings.itemIsDamaged, strings.itemDoesNotFit, strings.others]
  const handleReturnOrder = (item) => {
    setSelectedItem(item);
    setShowReturnModal(true);
  }

  const productStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "5px"
  }

  return (
    <>
     <Modal
      open={open}
      onCancel={onCancel}
      title={`${strings.purchaseOrderHistory} - ${purchaseOrder && purchaseOrder.user && purchaseOrder.user.first_name}`}
      footer={null}
    >
      <div className="images">
        <div style={{display: "flex", alignItems: "center", gap:20}}>

       
        </div>
       
        <p>
          <strong>{strings.supplier}</strong>: {purchaseOrder && purchaseOrder.user && purchaseOrder.user.first_name } {purchaseOrder && purchaseOrder.user && purchaseOrder.user.last_name || ""}
        </p>
        <p>
          <strong>{strings.businessName}</strong>: {purchaseOrder && purchaseOrder.supplier && purchaseOrder.supplier.business_name}
        </p>
        
        <p>
          <strong>{strings.status}</strong>: {purchaseOrder &&purchaseOrder.status}
        </p>
        
        <p>
          <strong>{strings.receivedBy}</strong>: {purchaseOrder && purchaseOrder.purchase_order_histories.length > 0 ? purchaseOrder.purchase_order_histories[purchaseOrder.purchase_order_histories.length - 1].triggered_by || "N/A" : "N/A"}
        </p>

        <div>
            <span>
                <strong>{strings.orderedItems}</strong>
            </span>

            <ul style={{...productStyle, listStyleType: "circle"}} className='supplier_prod'>
                {purchaseOrder && purchaseOrder.purchase_order_items.map((purchase, index) => {
                    return (
                        <li key={index} style={{display: "flex", justifyContent: "space-between", listStyleType: "circle"}}>
                          <div style={{display: "flex", flexDirection: "column",listStyleType: "circle"}}>
                            <span style={{fontSize: 14}}>
                              {purchase.name} - {user.currency || user.employer.currency} {purchase.price}
                            </span>
                            <div style={{display: "flex", alignItems: "center", gap:8}}>
                              <span  style={{fontSize: 12}}>
                                  <strong>{strings.orderedQty}</strong> - {purchase.ordered_quantity}
                              </span>
                              <span style={{fontSize: 12}}>
                              <strong>{strings.receivedQty}</strong> - {purchase.received_quantity || 0}
                              </span>
                              {purchase.order_return_item && <span style={{fontSize: 12}}>
                                <strong>{strings.returnedQty}</strong> - {purchase.returned_quantity || 0}
                              </span>}
                            </div>
                          </div>

                          {!purchase.order_return_item && purchaseOrder.status === "merchant_received" &&  <div>
                            <Button type="link" onClick={() => handleReturnOrder(purchase)} loading={loading}>{strings.returnItem}</Button>
                          </div>}
                          
                        </li>
                    )
                })}
            </ul>
        </div>
        
      </div>
    </Modal>

    {showReturnModal && (
      <ManageReturnModal 
        open={showReturnModal}
        onCancel={() => setShowReturnModal(false)}
        item={selectedItem}
        returnPurchaseOrder={returnPurchaseOrder}
        refetch={() => {
          refetch()
          onCancel();
        }}
        isPurhaseOrder
        purchaseOrder={purchaseOrder}
      />
    )}
    </>
   
  )
}

export default PurchaseDetails