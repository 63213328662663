import React from "react";
import { Modal, Input } from "antd";
import { Form } from "@ant-design/compatible";
import {
  sendSmsToCustomer,
  sendSmsToAllCustomer,
} from "../../../../../../redux/actions/customerActions";

import { connect } from "react-redux";
import strings from "../../../../../../strings";

const { TextArea } = Input;

class ShortSMSModal extends React.Component {
  state = {
    loading: false,
    textCount: 0,
    characterLimit: 160,
    totalSmsCount: 0,
  };

  componentDidMount() {
    const { thankYouMessage, form } = this.props;
    const { characterLimit } = this.state;

    const chars = 0;

    const totalSmsCount = Math.ceil(chars / characterLimit);
    const textRemaining =
      chars > characterLimit
        ? totalSmsCount * characterLimit - chars
        : characterLimit - chars;

    this.setState({
      totalSmsCount: totalSmsCount,
      textCount: textRemaining,
    });

    if (thankYouMessage) {
      form.setFieldsValue({
        message_text: thankYouMessage,
      });

      const chars = thankYouMessage.length;

      // Calculate text remaining in a message
      // const textRemaining =
      //   totalSmsCount * characterLimit -
      //   (chars % (totalSmsCount * characterLimit) ||
      //     totalSmsCount * characterLimit);

      const totalSmsCount = Math.ceil(chars / characterLimit);
      const textRemaining =
        chars > characterLimit
          ? totalSmsCount * characterLimit - chars
          : characterLimit - chars;

      this.setState({
        totalSmsCount: totalSmsCount,
        textCount: textRemaining,
      });
    }
  }

  onChange = e => {
    const { characterLimit } = this.state;
    const chars = e.target.value.length;

    // Calculate text remaining in a message
    // const textRemaining =
    //   totalSmsCount * characterLimit -
    //   (chars % (totalSmsCount * characterLimit) ||
    //     totalSmsCount * characterLimit);

    const totalSmsCount = Math.ceil(chars / characterLimit);
    const textRemaining =
      chars > characterLimit
        ? totalSmsCount * characterLimit - chars
        : characterLimit - chars;

    this.setState({
      totalSmsCount: totalSmsCount,
      textCount: textRemaining,
    });

    // this.setState({
    //   totalSmsCount: Math.ceil(chars / characterLimit),
    //   textCount: textRemaining,
    // });
  };

  handleSendSmsMessageToCustomer = () => {
    this.setState({ loading: true });

    const { totalSmsCount } = this.state;

    const {
      form,
      customer,
      sendSmsToCustomer,
      sendSmsToAllCustomer,
      closeModal,
      isBroadcast,
      smsBalance,
      totalCustomers,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        this.setState({ loading: false });
        return;
      }

      const isExceeded =
        Number(totalSmsCount) * Number(totalCustomers) > smsBalance;

      if (isBroadcast) {
        if (isExceeded) {
          this.setState({ loading: false });

          Modal.warn({
            title: strings.smsUnitsLow,
            content: strings.pleaseTopUpSmsUnits,
          });

          return;
        } else {
          sendSmsToAllCustomer({
            message_text: values.message_text,
            client_initiated_time: new Date(),
            short_message_service_campaign: {},
          })
            .then(res => {
              if (res.status && res.status === 200) {
                Modal.success({
                  title: strings.smsSuccessfullySentForDelivery,
                });

                this.setState({ loading: false });
                closeModal();
              }
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        }
      } else {
        const payload = {
          phone_number: customer.phone_number,
          message_text: values.message_text,
        };

        if (smsBalance < 1) {
          this.setState({ loading: false });

          Modal.warn({
            title: strings.smsUnitsLow,
            content: strings.pleaseTopUpSmsUnits,
          });

          return;
        } else {
          sendSmsToCustomer(payload)
            .then(res => {
              if (res.status && res.status === 200) {
                Modal.success({
                  title: strings.smsSuccessfullySentForDelivery,
                });

                this.setState({ loading: false });
                closeModal();
              }
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        }
      }
    });
  };

  render() {
    const {
      customer: { first_name },
      form: { getFieldDecorator },
      showModal,
      closeModal,
      smsBalance,
    } = this.props;

    const { textCount, totalSmsCount, loading } = this.state;

    return (
      <Modal
        title={`${strings.sendSmsTo} ${first_name ||
          strings.allCustomers} - ${smsBalance} ${strings.unitsAvailable}`}
        okText={strings.sendSms}
        cancelText={strings.cancel}
        visible={showModal}
        destroyOnClose
        onCancel={closeModal}
        onOk={this.handleSendSmsMessageToCustomer}
        okButtonProps={{ loading }}
      >
        <Form layout="vertical" >
          <Form.Item label={strings.message}>
            {getFieldDecorator("message_text", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseTypeInTheMessage,
                },
              ],
            })(<TextArea rows={4} onChange={this.onChange} />)}
          </Form.Item>
        </Form>
        <p>
          <span>
            {totalSmsCount} {strings.message}(s) / {textCount} characters remaining
          </span>
        </p>

        <p className="mt-20">
          <strong>{strings.note}: </strong>{strings.avoidUseOfSpecialCharacters}
        </p>
      </Modal>
    );
  }
}

const ShortSMSModalForm = Form.create()(ShortSMSModal);

export default connect(null, { sendSmsToCustomer, sendSmsToAllCustomer })(
  ShortSMSModalForm
);
