import React from "react";
import { Icon } from "@ant-design/compatible";
import PropTypes from "prop-types"

const MobileCartButton = ({
  isCartOpenOnMobile,
  toggleCartOnMobile,
  cartData
}) => (
  <button className="cart-button" onClick={toggleCartOnMobile}>
    <Icon type={isCartOpenOnMobile ? "close" : "shopping-cart"} />
    {!isCartOpenOnMobile ? (
      <span className="number">{cartData.length}</span>
    ) : null}
  </button>
);

MobileCartButton.propTypes = {
  isCartOpenOnMobile: PropTypes.bool.isRequired,
  toggleCartOnMobile: PropTypes.func.isRequired,
  cartData: PropTypes.array.isRequired
}

export default MobileCartButton;
