import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Card,
  Button,
  Select,
  Modal,
  Checkbox,
  Input,
  message,
  Radio,
  Switch,
} from "antd";
import { Form } from "@ant-design/compatible";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import {
  LIST_FORMS,
  SUBMIT_FORM_DATA,
} from "../Apps/MultiLevelLoyalty/Loyalties/constants";
import RadioGroup from "antd/lib/radio/group";
import { useHistory } from "react-router";

const { Option } = Select;

const FormEntry = ({ form, user }) => {
  const [currentForm, setCurrentForm] = useState(null);
  const { loading, data } = useQuery(LIST_FORMS);

  const history = useHistory();

  useEffect(() => {
    if (data && data.listForms) {
      const id = history.location.search.split("?")[1];
      const activeForm = data.listForms.find(form => form.id === id);
      activeForm && setCurrentForm(activeForm);
    }
  }, [data, history.location.search]);

  const { getFieldDecorator } = form;
  const FormItem = Form.Item;

  const [submitFormData, { loading: submitFormLoading }] = useMutation(
    SUBMIT_FORM_DATA,
    {
      onCompleted: data => {
        data.submitFormData &&
          Modal.success({
            title: "Data submitted successfully",
            okButtonProps: {
              id: "btn-component",
            },
            onOk: () => form.resetFields(),
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
    }
  );

  const handleSubmitCustomFormData = async values => {
    const payload = {
      id: currentForm.id,
      formData: values,
      savedBy:
        user.role_id === 1
          ? `${user.first_name}: Admin`
          : `${user.username}: ${user.role.name}`,
    };
    return submitFormData({ variables: payload });
  };

  const handleSubmitData = () => {
    form.validateFields((err, values) => {
      if (err) return;
      const payload = [];
      for (let key in values) {
        payload.push(`${key} - ${values[key] ? values[key] : "Nil"}`);
      }
      handleSubmitCustomFormData(payload.join("\n"));
    });
  };

  const renderInputType = field => {
    const options = field.field_options ? field.field_options.split(",") : [];
    switch (field.field_input_type) {
      case "select":
        return (
          <Select size="large" placeholder="Select value">
            {options.map(option => (
              <Option value={option.trim()}>{option.trim()}</Option>
            ))}
          </Select>
        );

      case "radio":
        return (
          <RadioGroup size="large">
            {options.map(option => (
              <Radio value={option.trim()}>{option.trim()}</Radio>
            ))}
          </RadioGroup>
        );

      case "checklist":
        return (
          <Checkbox.Group>
            {options.map(option => (
              <Checkbox value={option.trim()}>{option.trim()}</Checkbox>
            ))}
          </Checkbox.Group>
        );

      case "switch":
        return <Switch defaultChecked={false} size="large" />;

      default:
        return (
          <Input
            type={field.field_input_type}
            size="large"
            placeholder={`Enter ${field.field_name}`}
          />
        );
    }
  };

  const confirmReset = () => {
    Modal.confirm({
      title: "Reset Form",
      content: "Do you want to clear all form entries?",
      onOk: () => {
        form.resetFields();
      },
    });
  };

  return (
    <>
      <Card
        title="Enter Form Data"
        bordered={false}
        loading={loading}
        style={{ width: "40vw", marginLeft: "3vw" }}
      >
        <h4>{currentForm?.form_name}</h4>
        <Form layout="vertical">
          {currentForm?.form_fields.map(field => (
            <FormItem label={field.field_name}>
              {getFieldDecorator(field.field_name.split(".").join(", "), {
                rules: [
                  {
                    required: field.required_field,
                  },
                ],
              })(renderInputType(field))}
              <p>{field.notes}</p>
            </FormItem>
          ))}
        </Form>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="ghost" onClick={confirmReset}>
            Reset
          </Button>
          <Button
            style={{ marginLeft: "30px" }}
            type="primary"
            onClick={handleSubmitData}
            loading={submitFormLoading}
          >
            Submit
          </Button>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Form.create()(FormEntry));
