import React from "react";
import { connect } from "react-redux";
import { Button, Card, Input } from "antd";
import { Icon } from "@ant-design/compatible";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { getAllLoyaltyPrograms } from "../../../../redux/actions/loyaltyActions";
import Highlighter from "react-highlight-words";
import CustomDataTable from "../../../../components/CustomDataTable";
import { getTableColumns } from "./functions";
import LoyaltyDetailModal from "./_partials/LoyaltyDetailModal";
import EditLoyaltyModal from "./_partials/EditLoyaltyProgram";
import DeleteLoyaltyModal from "./_partials/DeleteLoyaltyProgram";
import AddLoyaltyProgram from "./AddLoyaltyProgram";
import strings from "../../../../strings";

class LoyaltyPrograms extends React.Component {
  state = {
    isLoadingLoyalties: false,
    showLoyaltyDetailModal: false,
    isEditLoyaltyModalOpen: false,
    isDeleteLoyaltyModalOpen: false,
    isAddLoyaltyProgramModalVisible: false,
  };

  componentDidMount() {
    this.setState({ isLoadingLoyalties: true });
    this.props
      .getAllLoyaltyPrograms()
      .then(res => this.setState({ isLoadingLoyalties: false }))
      .catch(err => this.setState({ isLoadingLoyalties: false }));
  }

  toggleAddLoyaltyProgramModal = () => {
    this.setState({
      isAddLoyaltyProgramModalVisible: !this.state
        .isAddLoyaltyProgramModalVisible,
    });
  };

  toggleLoyaltyDetailModal = record => {
    this.setState({
      showLoyaltyDetailModal: !this.state.showLoyaltyDetailModal,
      currLoyalty: record,
    });
  };

  toggleEditLoyaltyModal = () => {
    this.setState({
      isEditLoyaltyModalOpen: !this.state.isEditLoyaltyModalOpen,
    });
  };

  toggleDeleteLoyaltyModal = () => {
    this.setState({
      isDeleteLoyaltyModalOpen: !this.state.isDeleteLoyaltyModalOpen,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ [dataIndex]: selectedKeys[0] });
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    this.setState({ [dataIndex]: "" });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`${strings.search} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {strings.search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          {strings.reset}
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!record[dataIndex]) return;
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state[dataIndex]]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  render() {
    const {
      isLoadingLoyalties,
      showLoyaltyDetailModal,
      currLoyalty,
      isEditLoyaltyModalOpen,
      isDeleteLoyaltyModalOpen,
      isAddLoyaltyProgramModalVisible,
    } = this.state;

    const { allLoyaltyPrograms } = this.props;

    localStorage.removeItem("activeInstantRewardLoyalty");

    const sortedLoyaltyPrograms = allLoyaltyPrograms.sort((a, b) => {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    const dataSource = sortedLoyaltyPrograms.map((program, key) => {
      const created_at = new Date(program.created_at).toLocaleDateString();

      const [mm, dd, yy] = created_at.split("/");

      const orderedDate = `${dd} - ${mm} - ${yy}`;

      return {
        ...program,
        key,
        created_at: orderedDate,
      };
    });

    const totalLoyaltyPrograms = allLoyaltyPrograms.length || 0;

    const columns = getTableColumns.call(this);

    return (
      <div className="page-container loyalty-programs">
        {/* Main Content */}
        <Card
          title={strings.loyaltyPrograms}
          extra={
            <Button
              type="primary"
              icon={<PlusOutlined />}
              size="default"
              onClick={this.toggleAddLoyaltyProgramModal}
            >
              {strings.addNewLoyaltyProgram}
            </Button>
          }
        >
          <section>
            <h6>Total Loyalty Programs: {totalLoyaltyPrograms}</h6>

            <CustomDataTable
              columns={columns}
              dataSource={dataSource}
              loading={isLoadingLoyalties}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    this.toggleLoyaltyDetailModal(record);
                  },
                };
              }}
            />
          </section>
        </Card>
        {showLoyaltyDetailModal ? (
          <LoyaltyDetailModal
            user={this.props.user}
            currLoyalty={currLoyalty}
            showLoyaltyDetailModal={showLoyaltyDetailModal}
            closeModal={this.toggleLoyaltyDetailModal}
            toggleDeleteLoyaltyModal={this.toggleDeleteLoyaltyModal}
            toggleEditLoyaltyModal={this.toggleEditLoyaltyModal}
          />
        ) : null}
        {isEditLoyaltyModalOpen ? (
          <EditLoyaltyModal
            currLoyalty={currLoyalty}
            closeModal={this.toggleEditLoyaltyModal}
            isEditLoyaltyModalOpen={isEditLoyaltyModalOpen}
            getAllLoyaltyPrograms={this.props.getAllLoyaltyPrograms}
            toggleLoyaltyDetailModal={this.toggleLoyaltyDetailModal}
          />
        ) : null}
        {isDeleteLoyaltyModalOpen ? (
          <DeleteLoyaltyModal
            currLoyalty={currLoyalty}
            isDeleteLoyaltyModalOpen={isDeleteLoyaltyModalOpen}
            closeModal={this.toggleDeleteLoyaltyModal}
            toggleLoyaltyDetailModal={this.toggleLoyaltyDetailModal}
          />
        ) : null}

        {isAddLoyaltyProgramModalVisible ? (
          <AddLoyaltyProgram
            visible={isAddLoyaltyProgramModalVisible}
            toggleModal={this.toggleAddLoyaltyProgramModal}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
  error: state.error,
});

export default connect(mapStateToProps, { getAllLoyaltyPrograms })(
  LoyaltyPrograms
);
