import { GET_ALL_CATEGORIES } from "../constants";

const initialState = {
  allCategories: []
}


export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CATEGORIES:
      const allCategories = payload.filter(categories => (categories.deleted !== true));
      return {
        ...state,
        allCategories
      };
    default:
      return state;
  }
};
