import React from "react";
import { Button, Modal } from "antd";
import CustomTable from "../../../../../../../../../../components/CustomDataTable";
import strings from "../../../../../../../../../../strings";

const ViewMembersModal = ({ visible, closeModal, currentTier, loyalty_id }) => {
  const columns = [
    {
      title: strings.firstName,
      dataIndex: "first_name",
      key: "first_name",
      render: data => data || "N/A",
    },
    {
      title: strings.lastName,
      dataIndex: "last_name",
      key: "last_name",
      render: data => data || "N/A",
    },
    {
      title: strings.email,
      dataIndex: "email",
      key: "email",
      render: data => data || "N/A",
    },
    {
      title: strings.points,
      dataIndex: "points",
      key: "points",
    },
  ];

  const dataSource = [];
  currentTier.customers.forEach((customer, key) => {
    const points = customer.points.find(
      point => point.loyalty_id === loyalty_id
    );
    dataSource.push({
      ...customer,
      points: points ? points.points_value.toFixed(2) : "",
      key,
    });
  });

  return (
    <Modal
      title={`${strings.membersIn} ${currentTier.name}`}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button type="primary" key="close" onClick={closeModal}>
          Ok
        </Button>,
      ]}
    >
      <p>
        {strings.customers}: {currentTier.customers.length}
      </p>

      <CustomTable columns={columns} dataSource={dataSource} />
    </Modal>
  );
};

export default ViewMembersModal;
