export function transformInToFormObject(data) {
  let formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key]) && key !== "extra_pictures") {
      if(key === "variant_pictures"){
        data[key].forEach((obj, index) => {
          let keyList = Object.keys(obj);
  
          keyList.forEach((keyItem) => {
            let keyName = `data[variants][][picture]`;
  
            formData.append(keyName, obj);
          });
        });
      }else{
        data[key].forEach((obj, index) => {
          let keyList = Object.keys(obj);
  
          keyList.forEach((keyItem) => {
            // let keyName = [key, "[", index, "]", ".", keyItem].join("");
  
            // let keyName = [key, "[", index, "]", ".", keyItem].join("");
            let keyName = `data[${key}][][${keyItem}]`;
  
            formData.append(keyName, obj[keyItem]);
          });
        });
      } 
    }else if(key === "extra_pictures"){
      // if(data[key].length > 0){
        data[key].map(item => {
          formData.append(`data[${key}][]`, item);
        })
      // }else{
      //   formData.append(`data[${key}]`, []);
      // }
    }
    // else if (typeof data[key] === "object") {
    //   for (let innerKey in data[key]) {
    //     formData.append(`data[${key}.${innerKey}]`, data[key][innerKey]);
    //   }
    // }
    else {
      formData.append(`data[${key}]`, data[key]);
    }
  }
  return formData;
}

// data[variants][][price]
