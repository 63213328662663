import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import productReducer from "./productReducer";
import customerReducers from "./customerReducer";
import saleReducer from "./saleReducer";
import branchReducers from "./branchReducers";
import categoryReducer from "./categoryReducer";
import loyaltyReducer from "./loyaltyReducer";
import networkStatusReducer from "./networkStatusReducer";
import invoiceReducer from "./invoiceReducer";
import birthdayOfferReducer from "./birthdayOfferReducer";
import renewalReducer from "./renewalReducer";
import multiLevelReducer from "./multiLevelReducer";
import merchantReducer from "./merchantReducer";
import appReducer from "./appReducer";
import orderReducer from "./orderReducer";
import allSalesReducer from "./allSalesReducer";
import languageReducer from "./languageReducer";
import merchantDetailsReducer from "./merchantdetailsreducer";
import orderItemsReducer from "./orderItemsReducer";
import ordersReducer from "./saveOrderReducer";

export default combineReducers({
  auth: authReducer,
  error: errorReducer,
  product: productReducer,
  customer: customerReducers,
  sale: saleReducer,
  branch: branchReducers,
  category: categoryReducer,
  loyaltyProgram: loyaltyReducer,
  networkStatus: networkStatusReducer,
  invoice: invoiceReducer,
  birthdayOffer: birthdayOfferReducer,
  renewal: renewalReducer,
  multiLevel: multiLevelReducer,
  merchant: merchantReducer,
  appStore: appReducer,
  allSales: allSalesReducer,
  allOrders: orderReducer,
  language: languageReducer,
  userDetails: merchantDetailsReducer,
  orderItems: orderItemsReducer,
  orders: ordersReducer
});
