import { InputNumber, Modal, Radio, Select } from "antd";
import React, { useState } from "react";
import { editProduct } from "../../../../../../redux/actions/productActions";
import { transformInToFormObject } from "../../../../../../utils/generateFormData";
import { connect } from "react-redux";

const RadioGroup = Radio.Group; 
const Option = Select.Option;


const BulkUpdateModal = ({ 
    open, 
    onCancel, 
    products, 
    updateProducts,
    editProduct,
    selectedRowKeys 
}) => {
    const [ updateValue, setUpdateValue ] = useState("");
    const [ percentage, setPercentage ] = useState(1);
    const [ inputPrice, setPrice ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ productToUpdate, setProductToUpdate ] = useState([]);
    const [ updateType, setUpdateType ] = useState("")

    const handleRadioChange = (e) => {
        setUpdateValue(e.target.value);
    }

    const handleUpdateTypeSelect = (e)=> {
        setUpdateType(e.target.value)
    }

    const calculatePercentageIncrease = (price, percentag) => {
        const percent = percentag / 100;
        const percentageValue = price * percent;
        const newPrice = price + percentageValue;
        return newPrice;
    }

    const calculatePercentageDecrease = (price, percentag) => {
        const percent = percentag / 100;
        const percentageValue = price * percent;
        const newPrice = price - percentageValue;
        return newPrice;
    }

    const handleProductSelect = (value) => {
        if(value === "") return Modal.warn({title: "please select the products to update"});

        setProductToUpdate(value)
    }

    const handleEditBulkProduct = async () => {
        if(updateType === "") return Modal.warn({title: "please select the update type"});
        if(selectedRowKeys.length === 0) return Modal.warn({title: "please select the products to update"});
        
        // return console.log()
        const newProd = selectedRowKeys.map((prod)=>{
            return products.find((pro)=>{
                return pro.id === prod
            })    
        })

        // return console.log(newProd)
        if(updateValue === "") return Modal.warn({title: "please select the method of update"});
        if(updateValue === "price"){
            if(
                inputPrice <= 0     
                || inputPrice === "" 
                    || inputPrice === null
                ) return Modal.warn({title: "please enter a valid price"});

            setLoading(true);
            await newProd.map(async (product)=>{
                const { id, price: oldPrice } = product;
                let price;
                updateType === "increase" 
                    ? price = Number(oldPrice) + inputPrice 
                        : price = Number(oldPrice) - inputPrice;

                const newData = { price }

                const formData = transformInToFormObject(newData)
                
                await editProduct(id, formData);
            });
            setLoading(false);
            Modal.success({
                title: "Bulk Price Update successful",
                onOk: ()=>{
                    updateProducts();
                    onCancel();
                }
            });
        }

        if(updateValue === "percentage"){
            if(
                percentage <= 0     
                || percentage === "" 
                    || percentage === null
                ) return Modal.warn({title: "please enter a valid percentage"});

            setLoading(true);
            await newProd.map(async (product)=>{
                const { id, price: oldPrice } = product;

                let price;

                updateType === "increase" 
                    ? price = calculatePercentageIncrease(Number(oldPrice), percentage) 
                        : price = calculatePercentageDecrease(Number(oldPrice), percentage);

                const newData = { price }

                const formData = transformInToFormObject(newData)
                
                await editProduct(id, formData);
            });
            setLoading(false);
            Modal.success({
                title: "Bulk Price Update successful",
                onOk: ()=>{
                    updateProducts();
                    onCancel();
                }
            });
        }
    }

    const options = [
        {key: 1, value: "percentage", name: "Percentage"},
        {key: 2, value: "price", name: "Price"}
    ]
    const updateT = [
        {key: 1, value: "increase", name: "Increase product price"},
        {key: 2, value: "decrease", name: "Decrease product price"}
    ]

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            onOk={handleEditBulkProduct}
            okText="Edit Bulk Product"
            title="Bulk Price Update"
            okButtonProps={{loading: loading}}
        >
            <div style={{marginBottom: "1rem"}} >
                <h3>Select update type</h3>
                <RadioGroup
                    defaultValue={""}
                    name="update Type"
                    onChange={(e)=>handleUpdateTypeSelect(e)}
                >
                    {updateT.map((upda)=>{
                        return <Radio key={upda.key} value={upda.value}>{upda.name}</Radio>
                    })}
                </RadioGroup>
            </div>

            {/* <div style={{marginBottom: "1rem"}} >
                <h3>Select product to update</h3>
                <Select
                    mode="multiple"
                    // value={productToUpdate}
                    style={{width: "100%"}}
                    // value={productToUpdate}
                    onChange={(value)=>handleProductSelect(value)}
                    placeholder="Select product to update"
                >
                    {products && products.map((product)=>{
                        return <Option key={product.id} value={product.id}>{product.name}</Option>
                    })}
                </Select>
            </div> */}
            <h3>Select Update Method</h3>
            <RadioGroup 
                defaultValue={""} 
                name="Select Increase Method" 
                onChange={(e)=>handleRadioChange(e)}
            >
                {options.map((val, index)=>{
                    return <Radio key={val.key} value={val.value}>{val.name}</Radio>
                })}
            </RadioGroup>
            
            {updateValue === "percentage" && 
                <div
                    style={{
                        display: "flex", 
                        alignItems: "center", 
                        gap: "15px",
                        marginTop: "15px"
                    }}
                >
                    <h4>Set percentage value</h4>
                    <InputNumber 
                        size="large"
                        placeholder="Set percentage value"
                        value={percentage}
                        onChange={(value)=>setPercentage(value)}
                    />
                </div>
            }
            {updateValue === "price" && 
                <div
                    style={{
                        display: "flex", 
                        alignItems: "center", 
                        gap: "15px",
                        marginTop: "15px"
                    }}
                >
                    <h4>Set price value</h4>
                    <InputNumber 
                        size="large"
                        placeholder="Set percentage value"
                        value={inputPrice}
                        onChange={(value)=>setPrice(value)}
                    />
                </div>
            }
            
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    state: state
});
export default connect(mapStateToProps, {editProduct})(BulkUpdateModal);