import React, { Component } from "react";
import { Modal, Button, Input } from "antd";
import { Form } from "@ant-design/compatible";
import ReactToPrint from "react-to-print";
import SalesBill from "../SalesBill";
import strings from "../../../../strings";

const FormItem = Form.Item;

class SaveDraftForm extends Component {
  componentDidMount() {
    const { form, selectedCustomer } = this.props;

    const { setFieldsValue } = form;

    const transactionDate = new Date();

    const timeOfTransaction = transactionDate.toLocaleTimeString();
    const date = transactionDate.toLocaleDateString();

    const dateSplits = date.split("/");

    const [mm, dd, yy] = dateSplits;

    const dateOfTransaction = `${dd}/${mm}/${yy}`;

    const customerName = selectedCustomer
      ? `${selectedCustomer}'s Bill @ ${timeOfTransaction} ${dateOfTransaction}`
      : "";

    const guestName = `Guest Bill @ ${timeOfTransaction} ${dateOfTransaction}`;

    setFieldsValue({
      customerName: customerName ? customerName : guestName,
    });

    this.setState({
      transactionDate: transactionDate,
      customerName,
      timeOfTransaction,
      dateOfTransaction,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      const { form, selectedCustomer } = this.props;

      const { setFieldsValue } = form;

      const transactionDate = new Date();

      const timeOfTransaction = transactionDate.toLocaleTimeString();
      const date = transactionDate.toLocaleDateString();

      const dateSplits = date.split("/");

      const [mm, dd, yy] = dateSplits;

      const dateOfTransaction = `${dd}/${mm}/${yy}`;

      const customerName = selectedCustomer
        ? `${selectedCustomer}'s Bill @ ${timeOfTransaction} ${dateOfTransaction}`
        : "";

      const guestName = `Guest Bill @ ${timeOfTransaction} ${dateOfTransaction}`;

      setFieldsValue({
        customerName: customerName ? customerName : guestName,
      });

      this.setState({ transactionDate: transactionDate });
    }
  }

  handleSaveBill = () => {
    const {
      cartList,
      customer,
      customers,
      selectedCustomer,
      total,
      user,
      allLoyaltyPrograms,
      form,
      clearCart,
      toggleSaveDraftModal,
      toggleTransactionModal,
      discountAmount,
      has_Discount,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      const payload = {
        has_Discount,
        discountAmount,
        selectedCustomer,
      };

      const { customerName } = values;

      const { transactionDate } = this.state;

      let twoDigitMonth =
        transactionDate.getMonth() + 1 >= 10
          ? transactionDate.getMonth() + 1
          : "0" + (transactionDate.getMonth() + 1);

      let twoDigitDate =
        transactionDate.getDate() >= 10
          ? transactionDate.getDate()
          : "0" + transactionDate.getDate();

      const draftSalesList = JSON.parse(localStorage.getItem("draftSales"));

      const newDraftSales =
        draftSalesList && draftSalesList.length
          ? [
              ...draftSalesList,
              {
                id: Date.now(),
                merchant_id: user.id,
                transaction: cartList,
                customer: customer ? customer : "",
                selectedCustomer: selectedCustomer ? selectedCustomer : "",
                formattedCustomerName: customerName,
                total: total,
                created_at: new Date(),
                staff: "",
                status: "Incomplete",
                discountAmount: this.props.discountAmount,
                hasDiscount: this.props.has_Discount,
              },
            ]
          : [
              {
                id: Date.now(),
                merchant_id: user.id,
                transaction: cartList,
                customer: customer ? customer : "",
                selectedCustomer: selectedCustomer ? selectedCustomer : "",
                formattedCustomerName: customerName,
                total: total,
                created_at: new Date(),
                staff: "",
                status: "Incomplete",
                discountAmount: this.props.discountAmount,
                hasDiscount: this.props.has_Discount,
              },
            ];

      localStorage.setItem("draftSales", JSON.stringify(newDraftSales));

      Modal.success({
        title: strings.thisBillHasBeenSaved,
        content: (
          <div style={{ display: "flex" }}>
            <ReactToPrint
              trigger={() => <Button type="primary">Print Bill</Button>}
              content={() => this.componentRef}
            />

            <div style={{ display: "none" }}>
              <SalesBill
                currency={user.currency || user.employer.currency}
                saleTotal={total}
                transaction={cartList}
                purchaseTime={`${twoDigitDate} - ${twoDigitMonth} - ${transactionDate.getFullYear()} ${transactionDate.getHours()}:${transactionDate.getMinutes()}:${transactionDate.getSeconds()}`}
                user={user}
                selectedCustomer={selectedCustomer}
                customer={customer}
                customerList={customers}
                allLoyaltyPrograms={allLoyaltyPrograms}
                ref={(el) => (this.componentRef = el)}
                discountAmount={discountAmount}
                hasDiscount={has_Discount}
                customerName={this.state.customerName}
                timeOfTransaction={this.state.timeOfTransaction}
                dateOfTransaction={this.state.dateOfTransaction}
              />
            </div>
          </div>
        ),
        onOk: () => {
          toggleSaveDraftModal();
          toggleTransactionModal();
          clearCart();
        },
      });
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      isSaveDraftModalOpen,
      toggleSaveDraftModal,
    } = this.props;

    return (
      <Modal
        title={strings.saveDraft}
        visible={isSaveDraftModalOpen}
        destroyOnClose
        width={600}
        footer={[
          <Button key="back" onClick={toggleSaveDraftModal}>
            {strings.cancel}
          </Button>,
          <Button type="primary" key="save" onClick={this.handleSaveBill}>
            {strings.save}
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <FormItem label={strings.name}>
            {getFieldDecorator("customerName", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseTypeANameToIdentifyCustomer,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const SaveDraftModal = Form.create()(SaveDraftForm);

export default SaveDraftModal;
