import React from "react";
import { Icon, Input, Button } from "antd";
import { Form } from "@ant-design/compatible";
import strings from "../../../../strings";

const FormItem = Form.Item;

const Password = ({ form, resetPassword, loading }) => {
  const { getFieldDecorator } = form;

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("password")) {
      callback(strings.makeSureItMatchesPassword);
    } else {
      callback();
    }
  };

  // TODO: Submit form
  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        resetPassword(values);
      }
    });
  };

  return (
    <div>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <FormItem>
          {getFieldDecorator("password", {
            rules: [
              {
                message: strings.pleaseChooseNewPassword,
                required: true
              }
            ]
          })(
            <Input
              size="large"
              type="password"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={strings.chooseNewPassword}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator("password_confirmation", {
            rules: [
              {
                message: strings.enterNewPassword,
                required: true
              },
              {
                validator: compareToFirstPassword
              }
            ]
          })(
            <Input
              size="large"
              type="password"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={strings.confirmPassword}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator("reset_code", {
            rules: [
              {
                message: strings.enterTokenSent,
                required: true
              }
            ]
          })(
            <Input
              size="large"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={strings.resetToken}
            />
          )}
        </FormItem>

        <Button
          type="primary"
          size="large"
          block
          htmlType="submit"
          loading={loading}
        >
          {!loading ? strings.resetPassword : strings.resettingPassword}
        </Button>
      </Form>
    </div>
  );
};
const EnterPasswordForm = Form.create()(Password);

export default EnterPasswordForm;
