import { CHANGE_LANGUAGE } from "../constants";

const defaultLanguage = localStorage.getItem("current-language") || "English";

const initialState = {
  language: defaultLanguage,
};

const languageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: payload,
      };
    default:
      return state;
  }
};

export default languageReducer;
