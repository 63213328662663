import React, { } from 'react';
import strings from '../../../../../../strings';
import "./index.scss";

const poundsToDollarRate = process.env.REACT_APP_POUND_DOLLAR_RATE || 1.27;

const AppsPrice = ({
    activePaidApps,
    user,
    totalAppsPrice,
    fromStripeSubscription
}) => {
    
    const nairaDollarRate = process.env.REACT_APP_NAIRA_DOLLAR_RATE || 700;

    const convertToDollar = (amount) => {
        return (Number(amount) / nairaDollarRate).toFixed(2);
    };

    const convertPoundsToDollars = (amount) => {
        return (Number(amount) * poundsToDollarRate).toFixed(2);
    }

  return (
    <div className="app_price_div">
        <p>{strings.apps} ({activePaidApps && activePaidApps.length}) @ {user.currency}{totalAppsPrice && totalAppsPrice}</p>
        <div className="app_pricing">
            {activePaidApps && activePaidApps.map((app, index) => {
                return (
                    <div 
                        key={index}
                        style={{
                            display: app.app.cost === 0 ? "none" : "initial"
                         }}
                    >
                        <p 
                        style={{
                                display: "flex",
                                alignItems: "center", 
                                justifyContent: "space-between"
                            }}>
                            {/* <span><Checkbox /></span> */}
                            <span>-
                                {app.app.app_name} @ {user.currency}
                                {fromStripeSubscription ? (app.app.cost) : user.currency === "NGN" ? Number(app.app.cost) : convertToDollar(app.app.cost)}{" "}
                                {/* {`|| ${branch_discount_percentage * 100}% ${strings.discountApplies}`} */}
                            </span>

                            <span>
                                {user.currency}
                                {fromStripeSubscription ? (app.app.cost) : user.currency === "NGN" ? Number(app.app.cost) : convertToDollar(app.app.cost)}{" "}
                            </span>
                        </p>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default AppsPrice;