import React, { useState } from "react";
import moment from "moment";
import { Calendar, Badge, Button, Card, Row, Col } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import BookingDetails from "./BookingDetails";

const DailyBookingDetails = ({
  goBack,
  currDate,
  workHours,
  bookingsObj,
  customers,
  showBookingDetails,
}) => {
  const [currentDate, setCurrentDate] = useState(currDate);

  //get current day of week and working hours for the day
  const dayOfWeek = moment(currentDate).format("dddd").toLocaleLowerCase();
  const [opening, closing] = !!workHours
    ? workHours[dayOfWeek].split(" - ")
    : [null, null];
  const formattedDate = moment(currentDate).format("dddd, DD MMMM, YYYY");

  //get the bookings for the day
  const openingHours = formattedDate + " " + opening;
  const closingHours = formattedDate + " " + closing;
  const bookingsForTheDay =
    bookingsObj[moment(currentDate).format("DD-MM-YYYY")];

  //push all available hours into an array
  let hourArr = [];
  let openingHourMoment = moment(openingHours, "dddd, DD MMMM, YYYY hh:mma");
  let closingHourMoment = moment(closingHours, "dddd, DD MMMM, YYYY hh:mma");
  while (openingHourMoment < closingHourMoment) {
    hourArr.push(openingHourMoment.format("dddd, DD MMMM, YYYY hh:mma"));
    openingHourMoment.add(1, "h");
  }

  //save bookings for each hour and into otherBookings if not during work hours
  const hourlyBookings = {};
  const otherBookings = [];
  bookingsForTheDay &&
    bookingsForTheDay.forEach((booking) => {
      const bookingTimeMoment = moment(
        booking.booking_date,
        "DD-MM-YYYY   hh:mma"
      );
      const hours = bookingTimeMoment.hours();
      hourlyBookings[hours]
        ? (hourlyBookings[hours] = [...hourlyBookings[hours], booking])
        : (hourlyBookings[hours] = [booking]);

      if (
        hours < moment(openingHours, "dddd, DD MMMM, YYYY hh:mma").hours() ||
        hours > moment(closingHours, "dddd, DD MMMM, YYYY hh:mma").hours()
      ) {
        otherBookings.push(booking);
      }
    });

  const getCustomerName = (id) => {
    const customer = customers.find((cstmr) => cstmr.id === id);
    return `${customer && customer.first_name ? customer.first_name : "N/A"} ${customer &&
      customer.last_name ? customer.last_name : ""
    } ${customer && customer.phone_number ? ":" + customer.phone_number : ""}`;
  };

  const title = (
    <Row>
      <Col span={8}>
        <Button onClick={goBack}>Back</Button>
      </Col>
      <Col>
        <Row>
          <Col span={4}>
            <ArrowLeftOutlined
              style={{ margin: "0 20px" }}
              onClick={() => {
                setCurrentDate(moment(currentDate).subtract(1, "d"));
              }}
            />
          </Col>
          <Col span={16}>{formattedDate}</Col>
          <Col span={4}>
            <ArrowRightOutlined
              style={{ margin: "0 20px" }}
              onClick={() => {
                setCurrentDate(moment(currentDate).add(1, "d"));
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
  return (
    <Card title={title} bordered={false} style={{ width: "100%" }}>
      {hourArr.length > 0 &&
        hourArr.map((hour) => (
          <Card hoverable style={{ padding: "20px" }} key={Math.random()}>
            <Row>
              <Col span={6}>
                {moment(hour, "dddd, DD MMMM, YYYY hh:mma").format("hh:mma")}
              </Col>
              <Col>
                {hourlyBookings[
                  moment(hour, "dddd, DD MMMM, YYYY hh:mma").hours()
                ]
                  ? hourlyBookings[
                      moment(hour, "dddd, DD MMMM, YYYY hh:mma").hours()
                    ].map((booking) => (
                      <Row onClick={() => showBookingDetails(booking)}>
                        <Badge
                          status="success"
                          text={
                            getCustomerName(booking.customer_id) +
                            " : " +
                            booking.service_name +
                            " - " +
                            booking.booking_date.split(" ")[3]
                          }
                        />
                      </Row>
                    ))
                  : null}
              </Col>
            </Row>
          </Card>
        ))}
      {otherBookings.length > 0 && (
        <h4 style={{ marginTop: "30px" }}>Other bookings in the day:</h4>
      )}
      {otherBookings.map((booking) => (
        <Card hoverable style={{ padding: "20px" }} key={Math.random()}>
          <Row onClick={() => showBookingDetails(booking)}>
            <Col span={6}>
              {moment(booking.booking_date, "DD-MM-YYYY   hh:mma").format(
                "hh:mma"
              )}
            </Col>
            <Col>
              <Row>
                <Badge
                  status="success"
                  text={
                    getCustomerName(booking.customer_id) +
                    " - " +
                    booking.service_name
                  }
                />
              </Row>
            </Col>
          </Row>
        </Card>
      ))}
    </Card>
  );
};

const CalendarView = ({
  allBookings,
  customers,
  addServiceToCart,
  setCurrentBooking: setStateBooking,
  loading,
  workHours,
  toggleTransactionModal,
  bookingPaymentSuccessful,
}) => {
  const [currDate, setCurrDate] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);

  const bookingsObj = {};
  const monthlyBookings = {};
  allBookings &&
    allBookings.forEach((booking) => {
      const date = booking.booking_date.split(" ")[0];
      const month = moment(date, "DD-MM-YYYY").month();
      monthlyBookings[month]
        ? monthlyBookings[month].push(booking)
        : (monthlyBookings[month] = [booking]);
      bookingsObj[date]
        ? bookingsObj[date].push(booking)
        : (bookingsObj[date] = [booking]);
    });

  function getListData(value) {
    const currentDate = moment(value).format("DD-MM-YYYY");
    if (bookingsObj[currentDate]) return bookingsObj[currentDate];
    else return [];
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <div style={{ overflow: "hidden" }}>
        {listData.length > 0 && (
          <p>
            <Badge
              style={{ opacity: "0.7" }}
              status="success"
              text={
                listData.length + " booking" + (listData.length > 1 ? "s" : "")
              }
            />
          </p>
        )}
      </div>
    );
  }

  function monthCellRender(value) {
    const monthBookings = monthlyBookings[value.month()];
    return monthBookings ? (
      <Badge
        style={{ opacity: "0.7" }}
        status="success"
        text={
          monthBookings.length +
          " booking" +
          (monthBookings.length > 1 ? "s" : "")
        }
      />
    ) : null;
  }

  const showDailyBooking = (val) => {
    setCurrDate(val);
  };

  const showBookingDetails = (booking) => {
    setShowDetailsModal(true);
    setStateBooking(booking);
    setCurrentBooking(booking);
  };

  return (
    <>
      {!currDate && !showDetailsModal && (
        <Calendar
          dateCellRender={dateCellRender}
          monthCellRender={monthCellRender}
          onSelect={(val) => showDailyBooking(val)}
          onPanelChange={() => setCurrDate(null)}
        />
      )}
      {currDate && !showDetailsModal && (
        <DailyBookingDetails
          goBack={() => setCurrDate(null)}
          workHours={workHours}
          currDate={currDate}
          bookingsObj={bookingsObj}
          customers={customers}
          showBookingDetails={showBookingDetails}
        />
      )}
      {showDetailsModal && currDate && (
        <BookingDetails
          onCancel={() => {
            setShowDetailsModal(false);
            setCurrentBooking(null);
          }}
          currentBooking={currentBooking}
          customers={customers}
          addServiceToCart={addServiceToCart}
          loading={loading}
          toggleTransactionModal={toggleTransactionModal}
          bookingPaymentSuccessful={bookingPaymentSuccessful}
        />
      )}
    </>
  );
};

export default CalendarView;
