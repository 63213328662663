import React, { useState, useEffect } from 'react';
import { Modal, Button, Image } from 'antd';

const BundleDetails = ({ bundleProduct, open, onCancel }) => {
    const [ productWAndQuantity, setProductAndQuantity ]  = useState([]);


    useEffect(()=>{
        let prodQuant = [];
        bundleProduct && bundleProduct.products.map((prods, index)=>{
            bundleProduct && bundleProduct.quantity.map((quant, ind)=>{
                if(index === ind){
                    let prod = {
                        name: bundleProduct.products[index].name,
                        quant: bundleProduct.quantity[index]
                    }
                    prodQuant.push(prod);
                }
            })
        })
        setProductAndQuantity(prodQuant)
    }, [bundleProduct]);

  return (
    <>
        <Modal
            open={open}
            onCancel={onCancel}
            title={`${bundleProduct.name} details`}
            destroyOnClose
            onOk={onCancel}
        >
            <p>
              <strong>Bundle Name:</strong> {bundleProduct.name}
            </p>
            <p>
              <strong>Bundle Price:</strong>{" "}
              {bundleProduct.price}
            </p>
            <p>
              <strong>Bundle Description:</strong>{" "}
              {bundleProduct.description}
            </p>
            <p>
              <strong>Bundle Products:</strong>{" "}
              <ul>
                {productWAndQuantity && productWAndQuantity.map((product, index)=>{
                    return <li key={index}>{product.name} - {product.quant}units</li>
                })}
              </ul>
            </p>
            <p>
              <strong>Product Sku:</strong>{" "}
              {bundleProduct.barcode}
            </p>
            <div>
              <strong>Bundle Image(s):</strong>{" "}<br />
              <div style={{display: "flex", gap: 5, marginTop: 10}}>
                {bundleProduct.images.length > 0 && bundleProduct.images.map((image, index) => {
                  return <Image src={image} alt='bundle image' key={index} height={60}
                  width={60} />
                })}
              </div>
              
            </div>
        </Modal>
    </>
  )
}

export default BundleDetails;