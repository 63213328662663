import React, { useEffect } from "react";
import { Modal, Button, InputNumber } from "antd";
import { Form } from "@ant-design/compatible";
import strings from "../../../../strings";

const CustomePriceModal = ({
  visible,
  updateProductPrice,
  closeModal,
  productWithoutPrice,
  form,
  handleBlurFactor
}) => {
  const { getFieldDecorator, validateFields } = form;

  useEffect(() => {
    handleBlurFactor(false);
  }, [])

  const handleSubmit = async () => {
    try {
      const values = await validateFields();

      const newProduct = { ...productWithoutPrice };

      newProduct.price = values.price;
      newProduct.quantity = 1;

      updateProductPrice(newProduct);

      handleBlurFactor(true);
      closeModal();

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={`${strings.enterPriceFor} ${productWithoutPrice.name}`}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {strings.cancel}
        </Button>,
        <Button key="enter" type="primary" onClick={() => handleSubmit()}>
          Ok
        </Button>,
      ]}
    >
      <Form layout="vertical" >
        <Form.Item label={strings.enterProductPrice}>
          {getFieldDecorator("price", {
            rules: [
              {
                required: true,
                message: strings.pleaseEnterProductPrice,
              },
            ],
          })(
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\bNGN\s?|(,*)/g, "")}
              size="large"
              style={{ width: "100%" }}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(CustomePriceModal);
