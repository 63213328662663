import React, { useState } from "react";
import { Steps } from "antd";
import Phone from "./Phone";
import SignupScreen from "./SignupScreen";
import PersonalData from "./PersonalData";
import ChangeLanguage from "../../../components/ChangeLanguage";
import { Link } from "react-router-dom";
import strings from "../../../strings";

const { Step } = Steps;

const Signup = () => {
  const [current, setCurrent] = useState(0);
  const [textLabel, setTextLabel] = useState("");
  const [phone, setPhone] = useState("");
  const [countryData, setCountryData] = useState(null);
  const [personalDataFields, setPersonalDataFields] = useState({});

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: strings.enterPhoneNumber,
      content: (
        <Phone
          next={next}
          setTextLabel={setTextLabel}
          setPhone={setPhone}
          setCountryData={setCountryData}
        />
      ),
    },

    {
      title: strings.personalData,
      content: (
        <PersonalData
          next={next}
          previous={prev}
          setPersonalDataFields={setPersonalDataFields}
        />
      ),
    },
    {
      title: strings.validateConfirmationCOde,
      content: (
        <SignupScreen
          phone={phone}
          personalDataFields={personalDataFields}
          countryData={countryData}
        />
      ),
    },
  ];

  return (
    <div className="step-container">
      <ChangeLanguage />
      <div className="step--wrapper">
        <h1 className="title">{strings.signUp}</h1>

        <div className="logo">
          <img src="/assets/img/loystar-logo.png" alt="App Logo" />
        </div>
        <p style={{ textAlign: "center" }}>
          <em>The purpose of a business is to sell and keep customers</em>
        </p>

        <div
          className="auth-form__container"
          style={{ margin: "0 auto", maxWidth: "400px" }}
        >
          <Steps className="step-list" size="small" current={current}>
            {steps.map((item) => (
              <Step key={item.title} />
            ))}
          </Steps>

          <div className="steps-content">{steps[current].content}</div>

          <div className="form-utils__container">
            <Link to="/signin">{strings.signIn}</Link>
            {/* <Link to="/reset-password">Forgot Password?</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
