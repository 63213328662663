import { Button, Checkbox, Form, Input, InputNumber, message, Modal, Select } from 'antd'
import React, { useState } from 'react'
import strings from '../../../../../../strings';


const FormItem = Form.Item;
const ManageReturnModal = ({
    open,
    onCancel,
    item,
    mangeReturnedItems,
    handleGetReturnedItems,
    returnPurchaseOrder,
    getSalesRange,
    salespage,
    isPurhaseOrder,
    refetch,
    purchaseOrder
}) => {
    const [form] = Form.useForm();
    const [ isOthers, setIsOthers ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ returnItem, setReturnItem ] = useState(false);

    const handleSubmit = () => {
        const { validateFields } = form;
        
        validateFields()
        .then(values => {
            const { quantity, note, other } =  values;
            
            if(quantity > Number(item.quantity)) return message.warn(strings.cannotReturnMore);
            if(note === strings.itemIsDamaged && returnItem) return message.warn(strings.cannotReturnDamaged)
            if(note !== strings.itemIsDamaged && item.product.track_inventory && !returnItem ) return message.warn(strings.checktheboxtoreturnItem);
            let payload = {
                quantity,
                note:isOthers ? other : note,
                return_to_inventory: returnItem,//mark_as_damaged
                action: note === strings.itemIsDamaged ? "mark_as_damaged" : !item.product.track_inventory ? "normal_return" : "return_to_inventory"
            }
            // if(!item.product.track_inventory) payload.normal_return = true;
             

            setLoading(true);
            mangeReturnedItems(item.id, payload)
            .then(res => {
                if(res.status === 200 ){
                    message.success(`Success, ${res.data.message}!`);
                    setLoading(false);
                    handleGetReturnedItems && handleGetReturnedItems();
                    !salespage && getSalesRange();
                    onCancel();
                }
            })
            .catch(err => {
                setLoading(false);
                message.error(err.message);
            })


        })

    }

    const handleSubmitPurchase = () => {
        const { validateFields } = form;
        
        validateFields()
        .then(values => {
            const { quantity, note, other } =  values;
            
            if(quantity > Number(item.received_quantity)) return message.warn(strings.cannotReturnMore);
         
            let payload = {
                order_items: [{
                    id: item.id,
                    returned_quantity: quantity,
                    notes:isOthers ? other : note,
                }]
            }

            setLoading(true);
            returnPurchaseOrder(purchaseOrder.id, payload)
            .then(res => {
                if(res.status === 200 ){
                    message.success(`Success, ${res.data.message}!`);
                    setLoading(false);
                    refetch && refetch()
                }
            })
            .catch(err => {
                setLoading(false);
                console.log("error", err.message);
                
                message.error(err.message);
            })
        })

    }

    const reasons = [strings.itemIsDamaged, strings.itemDoesNotFit, strings.others]
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={!isPurhaseOrder ? `Manage ${item && item.product.name}` : strings.selectQuantityAndReeasonForReturn}
        footer={null}
    >
        
        <Form form={form} onFinish={() => !isPurhaseOrder ? handleSubmit() : handleSubmitPurchase()} layout="vertical">
            <FormItem name="quantity" rules={[{required:true}]}>
                <InputNumber placeholder='Quantity to return' style={{width:"100%"}} size="large" />
            </FormItem>

            <FormItem name="note"  rules={[{required:true}]}>
                <Select
                    onChange={(value) => {
                        if(value === strings.others) setIsOthers(true)
                    }}
                    size="large"
                >
                    {reasons && reasons.map((reason, index) => {
                        return (
                            <Select.Option value={reason} key={index}>{reason}</Select.Option>
                        )
                    })}
                </Select>
            </FormItem>

            {isOthers && <FormItem name="other">
                <Input 
                    placeholder='Reason..'
                    size="large"
                />
            </FormItem>}

            {!isPurhaseOrder && <FormItem name="return_to_inventory">
                <Checkbox checked={returnItem} onChange={(e) => setReturnItem(e.target.checked)}>
                    {strings.returnToInventory}
                </Checkbox>
            </FormItem>}

            <FormItem>
                <Button type="primary" htmlType="submit" loading={loading}>
                    {strings.send}
                </Button>
            </FormItem>
        </Form>
    </Modal>
  )
}

export default ManageReturnModal