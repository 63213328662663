import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";

const CustomTable = (props) => {
  const { pagination, onChange, current } = props;

  const { total, defaultPageSize } = pagination || {};

  const paginationConfig = {
    defaultPageSize: defaultPageSize || 30,
    total: Number(total),
    defaultCurrent: 1,
    current: pagination?.current,
  };

  if (current) {
    paginationConfig.current = current;
  }

  let properties = { ...props, pagination: { ...paginationConfig } };

  return (
    <>
      <Table
        size="middle"
        {...properties}
        total={total}
        loading={props.loading}
        onChange={(page, ...rest) => {
          onChange && onChange(page, ...rest);
        }}
        current={current}
        // locale={{
        //   emptyText: (
        //   props.isTransaction ? <div
        //     style={{
        //       display: "flex",
        //       alignItems: "top",
        //       justifyContent: "center",
        //       // marginTop: "-7rem",
        //       // zIndex: "2",
        //       // position: "relative"
        //     }}
        //   ><iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/WxyP9U3G5xw?si=FZ6I_GzLFMJARLTU&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div> : <Empty />
        //   )
        // }}
      />
    </>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default CustomTable;
