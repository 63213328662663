import {
  GET_ALL_CUSTOMERS,
  UPDATE_CUSTOMERS,
  ADD_LOYALTY_CUSTOMERS,
  SET_NEW_CUSTOMER,
} from "../constants";

const initialState = {
  allCustomers: [],
  loyaltyCustomers: [],
  newCustomer: null,
};

const mergeCustomers = (all, newer) => {
  const result = [...all];
  const obj = {};
  for (let customer of all) {
    obj[customer.id] = 1;
  }
  for (let customer of newer) {
    if (!obj[customer.id]) {
      const myCustomer = { ...customer, isLoyaltyCustomer: true };
      result.push(myCustomer);
    }
  }
  return result;
};

const customerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CUSTOMERS:
      return {
        ...state,
        allCustomers: payload,
      };

    case UPDATE_CUSTOMERS:
      return {
        ...state,
        allCustomers: [...state.allCustomers, payload],
        loyaltyCustomers: [...state.loyaltyCustomers, payload],
        newCustomer: payload,
      };

    case SET_NEW_CUSTOMER:
      return {
        ...state,
        newCustomer: payload,
      };

    case ADD_LOYALTY_CUSTOMERS:
      return {
        ...state,
        loyaltyCustomers: mergeCustomers(state.allCustomers, payload),
      };

    default:
      return state;
  }
};
export default customerReducer;
