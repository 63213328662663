import React, { useEffect, useRef, useState } from "react";
import { Popover, Select } from "antd";
import PropTypes from "prop-types";
import strings from "../../../../strings";

const Option = Select.Option;

const SelectCustomer = ({
  customers,
  customerValue,
  onCustomerSearch,
  type,
  cleanupSelectCustomerDropdown,
  customerName,
  isDisabled,
  instoreOrders,
  handleBlurFactor,
  user
}) => {

  useEffect(() => {
    return () => {
      cleanupSelectCustomerDropdown();
    };
  }, [cleanupSelectCustomerDropdown]);

  const textInput = useRef(null);

  const focus = () => {
    textInput.current.focus();
  };

  const getCustomField = (customer, field = "cars") => {
    if (customer.customer_custom_fields) {
      const fieldObj = customer.customer_custom_fields.find(
        one => one.name.toLowerCase() === field
      );
      if (fieldObj) return fieldObj.value;
      else return "";
    } else return "";
  };

  return (
    <div
      style={{
        padding: "5px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Select
        allowClear
        showSearch
        value={instoreOrders ? customerValue : type === "edit" ? customerName : customerValue}
        placeholder={strings.searchCustomerNameOrNumber}
        optionFilterProp="children"
        onChange={onCustomerSearch}
        size="large"
        style={{ width: "80%" }}
        filterOption={(input, option) => {
          return (
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
              0 ||
            (option.props.id && option.props.id.indexOf(input) >= 0)
          );
        }}
        onDeselect={e => console.log(e)}
        disabled={isDisabled === undefined ? false : isDisabled}
        ref={textInput}
        // onClick={() =>handleBlurFactor(false)}
        onMouseEnter={() => handleBlurFactor && handleBlurFactor(false)}
      >
        <Option key="add">{strings.addNewCustomer}</Option>
        {customers &&
          customers.map(customer => (
            <Option
              key={customer.user_id}
              value={customer.user_id}
              id={customer.loyalty_id}
            >
              {`${customer.first_name ? customer.first_name : "N/A"} ${
                customer.last_name ? customer.last_name : ""
              } ${
                customer.phone_number ? ":" + customer.phone_number : ""
              } ${getCustomField(customer, "car type")}  ${getCustomField(
                customer,
                "plate number"
              )}
              `}
            </Option>
          ))}
      </Select>

      <div
        className="scanner_icon"
        style={{ display: "flex", justifyContent: "right" }}
      >
        <Popover
          content={
            <span style={{ width: "150px" }}>
              {strings.clickTheSearchCustomerBox}
            </span>
          }
          placement="topRight"
        >
          <img
            src="/assets/img/scan_card.png"
            alt="Scan Card"
            style={{ width: "50px" }}
            onClick={() => focus()}
          />
        </Popover>
      </div>
    </div>
  );
};

SelectCustomer.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerValue: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  onCustomerSearch: PropTypes.func.isRequired,
};

export default SelectCustomer;
