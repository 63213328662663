import React from "react";
import { Input, Modal } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from 'prop-types'

// Redux
import { connect } from "react-redux";
import {
  addNewCategory,
  getAllCategories
} from "../../../../../../redux/actions/categoryActions";
import strings from "../../../../../../strings";

const FormItem = Form.Item;

class AddCategoryModal extends React.Component {
  state = {
    loading: false
  };

  handleAddCategory = () => {
    const { form, addNewCategory, getAllCategories, toggleModal } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      this.setState({ loading: true });
      addNewCategory(values).then(res => {
        if (res && res.status === 200) {
          form.resetFields();
          this.setState({ loading: false });
          getAllCategories();

          Modal.success({
            title: strings.youveSuccessfullyAddedANewCategory,
            onOk: this.props.handleSetCategory(res.data)
          });

          toggleModal();
        }
      });
    });
  };

  render() {
    const { form, showAddCategoryModal, toggleModal } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        title={strings.addNewCategory}
        okText={strings.addCategory}
        cancelText={strings.cancel}
        onOk={this.handleAddCategory}
        onCancel={toggleModal}
        destoryOnClose
        visible={showAddCategoryModal}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical" >
          <FormItem label={strings.categoryName}>
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterCategoryName
                }
              ]
            })(<Input size="large" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const AddCategoryForm = Form.create()(AddCategoryModal);

AddCategoryForm.propTypes = {
  addNewCategory: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func.isRequired
}

export default connect(
  null,
  { addNewCategory, getAllCategories }
)(AddCategoryForm);
