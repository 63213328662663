import React, { useState } from "react";
import { Modal, Input } from "antd";
import { Form } from "@ant-design/compatible";
import PropTypes from "prop-types";
import { 
    deleteCustomer, 
} from "../../../../../../redux/actions/customerActions";

import { connect } from "react-redux";
import strings from "../../../../../../strings";

const BulkDeleteCustomerModal = ({
    form,
    deleteCustomer,
    getAllCustomers,
    showModal,
    customerIds,
    closeModal,
    setIsSelectedRow
}) => {
    console.log(customerIds);
    const [ loading, setLoading ] = useState(false);
    const { getFieldDecorator } = form;

  const handleDeleteProduct = async () => {
    try {
      const values = await form.validateFields();

      if (values.delete.toLowerCase() !== "delete") {
        Modal.error({
          title:
            strings.pleaseTypeIn + " delete " + strings.toDeleteThisProduct,
        });
      } else {
        setLoading(true);

        const customerList = customerIds.map(prodId => {
          return deleteCustomer(prodId);
        });

        Promise.all(customerList)
          .then(res => {
            setLoading(false);

            Modal.success({
              title: strings.customerHasBeenDeletedSuccessfully,
            });

            getAllCustomers();
            closeModal();
          })
          .catch(err => {
            setLoading(false);

            Modal.error({
              title: strings.anErrorOccuredCustomersDeleted,
            });

            setIsSelectedRow();
            getAllCustomers();
            closeModal();
          });
      }
    } catch (error) {
      if (error) return;
    }
  };

    return (
      <Modal
        title={strings.bulkDelete}
        okText={strings.delete}
        cancelText={strings.cancel}
        open={showModal}
        destroyOnClose
        onCancel={closeModal}
        onOk={handleDeleteProduct}
        okButtonProps={{ loading: loading }}
      >
        <Form layout="vertical" >
          <Form.Item
            label={`${strings.youAreAboutToDelete} ${customerIds.length} ${strings.customers}(s). ${strings.pleaseTypeIn} "delete" ${strings.toConfirm}`}
          >
            {getFieldDecorator("delete", {
              rules: [
                {
                  required: true,
                  message:
                    strings.pleaseTypeIn + " delete " + strings.toConfirm,
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }


const BulkDeleteCustomerForm = Form.create()(BulkDeleteCustomerModal);

BulkDeleteCustomerForm.propTypes = {
  customerIds: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(null, {
    deleteCustomer
})(BulkDeleteCustomerForm);
