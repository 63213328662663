import { Button, message, Modal, DatePicker, Card } from 'antd'
import React, { useEffect, useState } from 'react'
import strings from '../../../../../../strings'
import CustomTable from '../../../../../../components/CustomDataTable';
import moment from 'moment';

const { RangePicker } = DatePicker;
const ProductHistory = ({
    open,
    onCancel,
    getProductHistory,
    product,
    user
}) => {
    const [ loading, setLoading ] = useState(false);
    const [ allHistory, setAllHistory ] = useState([]);
    const [ pagination, setPagination ] = useState({ current: 1, defaultPageSize: 10, pageSize: 30, total: 0 });
    const [begin, setBegin] = useState(null);
    const [end, setEnd] = useState(null);
    

    useEffect(() => {
        product && handleGetProductHistory(product.id,pagination.current, pagination.pageSize, begin, end);
    }, [])

    const handleGetProductHistory = (id, pageNumber, pageSize, begin, end) => {
        setLoading(true);
        getProductHistory(id, pageNumber, pageSize, begin, end)
        .then(res => {
            if(res.status === 200){
                setLoading(false);
                setAllHistory(res.data);
                setPagination((prev) => {
                    return {
                      ...prev,
                      total: res.headers.total,
                      current: pageNumber
                    }
                });
                
            }
        })
        .catch(err => {
            setLoading(false);
            console.log("unable to get product history", err);
            message.error(`${err?.response?.data?.error || err?.response?.data?.message || err?.message}`)
        })
    }

    const onChangeTablePage = (newPagination, filters) => {
        handleGetProductHistory(product.id, newPagination.current, newPagination.pageSize, begin, end);
    };

    const handleDurationChange = function (duration, ...rest) {
        const [begin, end] = duration;
        if (begin && end) {
            setLoading(true);
            
            handleGetProductHistory(
                product.id,
                pagination.current,
                pagination.pageSize,
                begin.format().split("T")[0],
                end.format().split("T")[0]
            )
        } 
    };

    const column =  [
          // {
          //   title: strings.productName,
          //   dataIndex: "product",
          //   render: record => (record ? <span>{record.name}</span> : <span>N/A</span>),
          // },
          {
            title: strings.newValue,
            dataIndex: "to",
            render: (record, value ) => {
              if(value.change_type.toLowerCase().includes("quantity")) return (record ? <span>{Number(record).toFixed(0)}</span> : <span>0</span>)
                if(value.change_type.toLowerCase().includes("price")) return (record ? <span>{user.currency} {Number(record).toFixed(2)}</span> : <span>0</span>)

              return (record ? <span>{record}</span> : <span>0</span>)
            },
          },
          {
            title: strings.oldValue,
            dataIndex: "from",
            render: (record, value ) => {
              if(value.change_type.toLowerCase().includes("quantity")) return (record ? <span>{Number(record).toFixed(0)}</span> : <span>0</span>)
                if(value.change_type.toLowerCase().includes("price")) return (record ? <span>{user.currency} {Number(record).toFixed(2)}</span> : <span>0</span>)

              return (record ? <span>{record}</span> : <span>0</span>)
            },
          },
          {
            title: strings.changeType,
            dataIndex: "change_type",
            render: record => (record ? <span>{record}</span> : <span>N/A</span>),
          },
          {
            title: strings.event,
            dataIndex: "change_action",
            render: record => (record ? <span>{record.split("_").join(" ")}</span> : <span>N/A</span>),
          },
        //   {
        //     title: strings.phoneNumber,
        //     dataIndex: "phone_number",
        //     render: record => (record ? <span>{record}</span> : <span>N/A</span>),
        //   },
          {
            title: strings.changeBy,
            dataIndex: "triggered_by",
            render: record => (record ? <span>{record}</span> : <span>N/A</span>),
          },
          {
            title: strings.dateTime,
            dataIndex: "created_at",
            render: record => (record ? <span>{moment(record).format("YYYY-DD-MM h:mm:A")}</span> : <span>N/A</span>),
          },
        //   {
        //     title: strings.actions,
        //     // dataIndex: "actions",
        //     dataIndex: "action",
        //     key: "action",
        //     render: (text, record) => (
        //       <div style={{display: 'flex', alignItems: "center", gap:5}}>
        //         <EditOutlined onClick={() => toggleEditSupplyModal(record)} />
        //         <DeleteOutlined onClick={() => deleteSupplier(record.id)} />
        //       </div>
        //     ),
        //   },
        ];
  return (
    <Modal
        title={`${strings.productChangeHistory} - ${product.name}`}
        open={open}
        onCancel={onCancel}
        width={950}
        footer={[
            <Button key="back" onClick={onCancel}>
              {strings.close}
            </Button>,
        ]}
    >
        <Card
        // title={strings.purchaseHistory}
        loading={loading}
        extra={ 
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ margin: "0 10px" }}>{strings.reportFrom}:</span>
                <RangePicker
                    placeholder={[strings.startDate, strings.endDate]}
                    onChange={handleDurationChange}
                    allowClear="false"
                />
                </div>
            </div>
        }
    >
        <CustomTable
          columns={column}
          loading={loading}
          dataSource={allHistory}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                // this.toggleCustomerDetailModal(record);
              },
            };
          }}
          onChange={onChangeTablePage}
          pagination={pagination}
        />
    </Card>
        
    </Modal>
  )
}

export default ProductHistory