import React, { useEffect, useState } from 'react';
import { Modal, Button, message, Table, InputNumber, Switch } from "antd";
import { editProduct } from '../../../../../../redux/actions/productActions';
import strings from '../../../../../../strings';
import { connect } from 'react-redux';
import { transformInToFormObject } from '../../../../../../utils/generateFormData';

const BulkQuantityEdit = ({
    open,
    onCancel,
    selectedRowKeys,
    products,
    updateProducts,
    editProduct,
    cancelSelection
}) => {
    const [ loading, setLoading ] = useState(false);
    const [ productToEdit, setProductToEdit ] = useState([]);

    useEffect(() => {
        const productsObj = {};
        for (let i of products) {
            productsObj[i.id] = i;
        }
        
        const productsToSend = selectedRowKeys 
        && selectedRowKeys
        .map((id) => productsObj[id])
        .filter((prod) => prod);

        setProductToEdit(productsToSend);
        // console.log(productsToSend);
    }, [products, selectedRowKeys])

    const columns = [
        {
          title: "Product Name",
          dataIndex: "name",
        },
        {
          title: "Current Quantity",
          dataIndex: "quantity",
        },
        {
          title: "Amount to add",
          dataIndex: "quantity",
          render: (val, record) => (
            <InputNumber
              min={1}
              max={val}
            //   defaultValue={1}
              onChange={(val) => updateProductQuantity(record.id, val)}
            />
          ),
        },
        {
            title: "Track Product Inventory",
            dataIndex: "track_inventory",
            render: (val, record) => (
              <Switch
                checked={val}
                onChange={(val) => updateProductTrackINV(record.id, val)}
              />
            ),
          },
    ];

    const updateProductQuantity = (id, quantity) => {
        if (!quantity) return message.error("Please enter quantity");

        setProductToEdit((prev) => {
            return prev.map(item => {
                if(item.id === id){
                    item.quantityToAdd = Number(quantity)
                    return item;
                }else{
                    return item
                }
            })
        })
    }

    const updateProductTrackINV = (id, value) => {
        setProductToEdit((prev) => {
            return prev.map(item => {
                if(item.id === id){
                    item.track_inventory = value
                    return item;
                }else{
                    return item
                }
            })
        })
    }

    const handleBulkProductEdit = () => {
        
        productToEdit.map((product) => {
            if(product.quantityToAdd && product.quantity) product.quantity = Number(product.quantity) + Number(product.quantityToAdd);
            if(product.quantityToAdd && !product.quantity) product.quantity = Number(product.quantityToAdd);
           
            delete product.quantityToAdd; 
        });

        const isNullProd = productToEdit.filter(product => product.track_inventory && !product.quantity);
        if(isNullProd.length > 0) return Modal.info({
            title: "Product with inventory trancking must have quantity set"
        });

        setLoading(true);
        Promise.all(productToEdit.map((product, index) => {
            const payload = {
                cost_price: product.cost_price,
                price: product.price,
                product_id: product.product_id,
                track_inventory: product.track_inventory,
                product_sku: product.product_sku,
                publish_to_loystar_shop: product.publish_to_loystar_shop,
                tax: product.tax,
                name: product.name,
                merchant_product_category_id: product.merchant_product_category_id,
                merchant_loyalty_program_id: product.merchant_loyalty_program_id,
                has_custom_qty: product.has_custom_qty,
                unit: product.unit,
                quantity: product.quantity
            }
            const formData = transformInToFormObject(payload)
            return editProduct(product.id, formData)
        }))
        .then(res => {
            setLoading(false);
            // console.log("r", res);
            if(res.length === productToEdit.length){
                Modal.success({
                    title: strings.productsEditedSuccessfully,
                    onOk: () => {
                        setLoading(false);
                        updateProducts();
                        cancelSelection();
                        onCancel();
                    }
                });
            }
        })
        .catch(err => {
            setLoading(false);
            Modal.error({
                title: "An error occured while updating product",
                onOk: () => {
                    onCancel();
                    updateProducts();
                }
            });
            
        })
        
    }
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        okText="Edit Products"
        title="Bulk Quantity Update"
        okButtonProps={{loading: loading}}
        onOk={handleBulkProductEdit}
    >
        <Table columns={columns} dataSource={productToEdit} />
    </Modal>
  )
}

const mapStateToProps = (state) => ({
    state: state
});
export default connect(mapStateToProps, {editProduct})(BulkQuantityEdit);