import React, { useEffect, useState } from "react";
import { Card, Col, Row, Modal } from "antd";
import { Link, useLocation } from "react-router-dom";

import "../../index.scss";
import ViewDetailsModal from "../ViewDetailsModal";
import AccounteerModal from "../Accounteer";

const BookKeeping = ({ apps }) => {
  const location = useLocation();
  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
  const [isAccounteerModalOpen, setIsAccounteerModalOpen] = useState(false);

  const [currentIntegration, setCurrentIntegration] = useState(null);
  const [ user, setUser ] = useState({});

  useEffect(()=>{
    const use = JSON.parse(localStorage.getItem("userDetails"))
    setUser(use);
  }, []);

  useEffect(()=>{
    handleSelectApp();
  }, []);

  const handleSelectApp = () => {
    const split = location.search && location.search.split("?")[1].split("&")[1];
    if(split.length > 0){
      const selected = apps && apps.filter((app)=>app.app_name.toLowerCase().includes(split.toLowerCase()));
      selected.length > 0 && setCurrentIntegration({
        ...selected[0],
      });
      selected.length > 0 && toggleViewDetailsModal();
      location.search = "";
    }
  }

  const toggleViewDetailsModal = () =>
    setIsViewDetailsModalOpen((open) => !open);

  const toggleAccounteerModal = () => setIsAccounteerModalOpen((open) => !open);

  return (
    <main>
      <Card bordered={false}>
        <section className="mt-20">
          <Row gutter={[16, 16]}>
            {apps.map((app) => {
              const {
                app_name,
                company_name,

                id,
                icon,
              } = app;

              return (
                <Col key={id} xs={24} md={12} lg={8} xl={8}>
                  <Link
                    to="#"
                    onClick={(e) => {
                      
                      e.preventDefault();

                      app_name === "Accounteer"
                        ? toggleAccounteerModal()
                        : toggleViewDetailsModal();

                      setCurrentIntegration({
                        ...app,
                      });
                    }}
                  >
                    <Card className="integration-card-item">
                      <div className="img-wrapper">
                        <img src={icon} alt={app_name} />
                      </div>

                      <div className="name">
                        <h4>{app_name}</h4>
                        <span>{company_name}</span>
                      </div>
                    </Card>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </section>
      </Card>

      {isViewDetailsModalOpen ? (
        <ViewDetailsModal
          visible={isViewDetailsModalOpen}
          toggleViewDetailsModal={toggleViewDetailsModal}
          currentIntegration={currentIntegration}
        />
      ) : null}

      {isAccounteerModalOpen ? (
        <AccounteerModal
          visible={isAccounteerModalOpen}
          toggleAccounteerModal={toggleAccounteerModal}
          currentIntegration={currentIntegration}
        />
      ) : null}
    </main>
  );
};

export default BookKeeping;
