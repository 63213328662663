const validateVariant = (selling_price, name, value, quantity) => {
  let result = {
    status: true,
    message: "",
  };
  if (!selling_price || !name || !value || !quantity) {
    result.status = false;
    result.message =
      "Please fill out Type, Value, Price and quantity values for Variant";
  } else if (selling_price && name && value && quantity) {
    if (selling_price < 0) {
      result.status = false;
      result.message = "Invalid variant value supplied";
    }

    if (name.trim().length === 0 || name.trim().length < 0) {
      result.status = false;
      result.message = "Invalid variant value supplied";
    }
    if (value.trim().length === 0 || value.trim().length < 0) {
      result.status = false;
      result.message = "Invalid variant value supplied";
    }
    if (quantity < 0) {
      result.status = false;
      result.message = "Invalid variant value supplied";
    }
  }
  return result;
};

export default validateVariant;
