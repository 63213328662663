import React from "react";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CustomDataTable from "../../../../../../components/CustomDataTable";
import strings from "../../../../../../strings";

const StaffTable = ({
  staff,
  onDeleteStaff,
  isDeletingStaff,
  toggleEditStaff,
  setCurrentStaff,
}) => {
  const columns = [
    { title: strings.username, dataIndex: "username", key: "date" },
    {
      title: strings.role,
      key: "role",
      render: (text, record) => <span>{record.role.name}</span>,
    },
    {
      title: strings.actions,
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <Popconfirm
            title={`${strings.areYouSureToArchiveThis} ${record.username}?`}
            onConfirm={_ => onDeleteStaff(record)}
            okText={strings.yes}
            cancelText={strings.no}
          >
            <Button icon={<DeleteOutlined />} type="danger" shape="circle" />
          </Popconfirm>
          <Button
            icon={<EditOutlined />}
            style={{ marginLeft: "5px" }}
            title="Edit staff"
            onClick={() => {
              toggleEditStaff();
              setCurrentStaff(record);
            }}
            shape="circle"
          />
          {/*
          <Tooltip
            trigger={["hover"]}
            title={<span>Reset Manager/Staff Password</span>}
            placement="top"
          >
            <Button
              onClick={toggleEditStaff}
              icon="edit"
              shape="circle"
              style={{ marginLeft: "1rem" }}
            />
          </Tooltip> */}
        </div>
      ),
    },
  ];
  return (
    <CustomDataTable
      columns={columns}
      dataSource={staff}
      pagination={false}
      loading={isDeletingStaff}
    />
  );
};

export default StaffTable;
