import { Form, Input, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import strings from '../../../../../../strings';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { Icon } from "@ant-design/compatible";
import { editBranch } from '../../../../../../redux/actions/branchActions';
import { connect } from 'react-redux';

const FormItem = Form.Item;

const libraries = ['places'];

const EditBranchModal = ({
    open, 
    onCancel,
    branchDetails,
    getBusinessBranch,
    editBranch
}) => {
    const [form] = Form.useForm();
    const inputRef = useRef();
    const [ country, setCountry ] = useState("");
    const [ state, setState ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);
    const [ address_line1, setAddress_line1 ] = useState("");
    const [ address_line2, setAddress_line2 ] = useState("");
    const [ postal_code, setPostal_code ] = useState("");
    const [ branchCoord, setBranchCoord ] = useState({
      longitude:null,
      latitude: null
    })

    useEffect(() => {
        const { setFieldsValue } = form;

        setFieldsValue({
            address_line1: branchDetails.address_line1,
            address_line2: branchDetails.address_line2,
            state: branchDetails.state,
            country: branchDetails.country
        });

        setAddress_line1(branchDetails.address_line1);
        setAddress_line2(branchDetails.address_line2);
        setState(branchDetails.state);
        setCountry(branchDetails.country);

    }, [branchDetails]);

    const handleEditBranch = () => {
        const data = {
            address_line1,
            address_line2,
            state,
            country,
            postal_code,
            longitude: branchCoord.longitude,
            latitude: branchCoord.latitude
        }

        setLoading(true);
        editBranch(branchDetails.id, data)
        .then(res => {
            if(res.status === 200){
                setLoading(false);
                Modal.success({
                    title: "Branch address has been editted successfully",
                    onOk: () => {
                        getBusinessBranch();
                        setLoading(false);
                        onCancel();
                    }
                })
            }
        })
        .catch(err => {
           Modal.error({
                title: "Unable to edit branch address",
                content: `${err?.response ? err?.response?.data?.message : err.message}`,
                onOk: () => setLoading(false)
           }) 
        })
    }

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };
      // phone_number
    
    const onPlaceChanged = () => {
        const { setFieldsValue } = form;
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            const location = place.geometry.location;
        
            const splitAddress = place.formatted_address.split(",");
            const length = splitAddress.length;
            const postalLine2 = length > 3 ? splitAddress[length - 3].split(" ") : null;
            const address_line1 = length > 3 ? splitAddress.splice(0,length - 3) : null;
            const newSplitAddress = place.formatted_address.split(",");
            
            length >= 1 ? setCountry(newSplitAddress[length - 1].trim().toString()) : setCountry("");

            length >= 1 ? setFieldsValue({country: newSplitAddress[length - 1].trim().toString()}) : setCountry("");

            length >= 2 ? setState(newSplitAddress[length - 2].trim().toString()) : setState("");

            length >= 2 ? setFieldsValue({state: newSplitAddress[length - 2].trim().toString()}) : setState("");

            postalLine2 && postalLine2.length > 2 ? setFieldsValue({postcode: postalLine2[2].toString()}) : setPostal_code("");

            postalLine2 && postalLine2.length > 2 ? setPostal_code(postalLine2[2].toString()) : setPostal_code("");

            postalLine2 && postalLine2.length > 2 ? setFieldsValue({address_line2: postalLine2[1].toString()}) : setAddress_line2("");

            postalLine2 && postalLine2.length > 2 ? setAddress_line2(postalLine2[1].toString()) : setAddress_line2("");

            address_line1 ? setFieldsValue({address_line1: address_line1.toString()}) : setAddress_line1("");

            address_line1 ? setAddress_line1(address_line1.toString()) : setAddress_line1("");

            console.log("jere", newSplitAddress, splitAddress.length);
            setBranchCoord({
                longitude: location.lng(),
                latitude: location.lat()
            });

        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={`Edit ${branchDetails.name} branch address`}
        onOk={handleEditBranch}
        okText={strings.editBranch}
        okButtonProps={{loading: loading}}
    >
        <Form layout="vertical" form={form} onFinish={handleEditBranch}>
            <div style={{display: "flex", alignItems: "center", gap:5}}>
                <FormItem name="address_line1" style={{width: "50%"}}>
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY} libraries={libraries}>
                        <div>
                            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                            <Input 
                                // placeholder="address line1"
                                value={address_line1}
                                // name="address_line1"
                                size="large"
                                onChange={(e)=>setAddress_line1(e.target.value)}
                                type="text"
                                // placeholder="Search for a place"
                                placeholder={strings.addressLine1}
                                style={{ width: '100%'}}
                                ref={inputRef}
                                prefix={
                                    <Icon
                                    type="environment"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                    />
                                }
                            />
                            </Autocomplete>
                        </div>
                    </LoadScript>
                </FormItem>

                <FormItem name="address_line2" style={{width: "50%"}}>
                    <Input 
                        size='large' 
                        value={address_line2} 
                        onChange={(e) => setAddress_line2(e.target.value)}
                    />
                </FormItem>
            </div>

            <div style={{display: "flex", alignItems: "center", gap:5}}>
                <FormItem name="country" style={{width: "50%"}}>
                    <CountryDropdown
                        label={country}
                        defaultOptionLabel={strings.selectCountry}
                        value={country}
                        onChange={(val) => setCountry(val)}
                        className="ant-input-lg ant-input"
                    />
                </FormItem>

                <FormItem name="state" style={{width: "50%"}}>
                    <RegionDropdown
                        label={state}
                        country={country}
                        value={state}
                        onChange={(val) => setState(val)}
                        className="ant-input-lg ant-input"
                        defaultOptionLabel={strings.selectRegion}
                    />
                </FormItem>
            </div>
        </Form>
    </Modal>
  )
}


export default connect(null, {
    editBranch
})(EditBranchModal)