import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Button, Input } from "antd";
import { Form } from "@ant-design/compatible";
import PhoneInput from "react-phone-input-2";
import {
  addSuppliers,
  getSuppliers,
} from "../../../../../../redux/actions/productActions";
import strings from "../../../../../../strings";

const FormItem = Form.Item;

const AddSupplierForm = ({
  visible,
  closeModal,
  addSuppliers,
  getSuppliers,
  form,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { getFieldDecorator } = form;

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      setIsLoading(true);

      const res = await addSuppliers({ ...values, sex: "-" });

      if (res.status && res.status === 200) {
        setIsLoading(false);

        Modal.success({
          title: strings.youveSuccessfullyAddedANewSupplier,
          onOk: async () => {
            await getSuppliers();

            closeModal();
          },
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={strings.addSupplier}
      open={visible}
      closeModal={closeModal}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          {strings.close}
        </Button>,
        <Button
          key="submit"
          loading={isLoading}
          type="primary"
          onClick={handleSubmit}
        >
          {strings.submit}
        </Button>,
      ]}
      destroyOnClose
    >
      <Form layout="vertical" >
        <FormItem label={strings.firstName}>
          {getFieldDecorator("first_name", {
            rules: [
              {
                required: true,
                message: strings.pleaseEnterFirstName,
              },
            ],
          })(<Input size="large" />)}
        </FormItem>

        <FormItem label={strings.lastName}>
          {getFieldDecorator("last_name")(<Input size="large" />)}
        </FormItem>

        <FormItem label={strings.email} type="email">
          {getFieldDecorator("email", {
            rules: [
              {
                required: false,
                message: strings.pleaseEnterSupplierEmail,
              },
            ],
          })(<Input size="large" />)}
        </FormItem>

        <FormItem label={strings.businessName}>
          {getFieldDecorator("business_name", {
            rules: [
              {
                required: true,
                message: strings.pleaseEnterBusinessName,
              },
            ],
          })(<Input size="large" />)}
        </FormItem>

        <FormItem label={strings.phoneNumber}>
          {getFieldDecorator("phone_number", {
            rules: [
              {
                required: false,
                message: strings.pleaseEnterCustomerPhoneNumber,
              },
            ],
          })(
            <PhoneInput
              inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
              inputStyle={{
                border: "1px solid #d9d9d9",
                width: "100%",
                height: "40px",
              }}
              placeholder={strings.phoneNumber}
              country="ng"
            />
          )}
        </FormItem>

        <FormItem label={strings.address}>
          {getFieldDecorator("address")(<Input size="large" />)}
        </FormItem>

        <FormItem label={strings.description}>
          {getFieldDecorator("description", {
            rules: [
              {
                required: false,
                message: strings.pleaseAddADescription,
              },
            ],
          })(<Input.TextArea size="large" />)}
        </FormItem>
      </Form>
    </Modal>
  );
};

const AddSupplierModal = Form.create()(AddSupplierForm);

export default connect(null, { addSuppliers, getSuppliers })(AddSupplierModal);
