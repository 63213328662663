export function formatGoogleAddress(addressComponents) {
    const address = {
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        state: '',
        country: ''
    };

    addressComponents.forEach(component => {
        const types = component.types;

        if (types.includes('street_number')) {
            address.addressLine1 = component.long_name + ' ' + address.addressLine1;
        }

        if (types.includes('postal_town')) {
            address.addressLine1 = component.long_name + ' ' + address.addressLine1;
        }

        if (types.includes('route')) {
            address.addressLine1 += component.long_name;//
        }

        if (types.includes('administrative_area_level_1')) {
            address.addressLine1 = component.long_name + ' ' + address.addressLine1;
        }

        if (types.includes('sublocality') || types.includes('sublocality_level_1') || types.includes('neighborhood')) {
            address.addressLine2 = component.long_name;
        }

        if (types.includes('administrative_area_level_2')) {
            address.addressLine2 += component.long_name;
        }

        if (types.includes('postal_code')) {
            address.postalCode = component.long_name;
        }

        if (types.includes('administrative_area_level_1')) {
            address.state = component.long_name; // Use short name for state abbreviations
        }

        if (types.includes('country')) {
            address.country = component.long_name;
        }
    });

    return address;
}

