import React from "react";
import { Modal, Input, Button } from "antd";
import { Form } from "@ant-design/compatible";

import ReactToPrint from "react-to-print";
import ShippingWaybill from "../ShippingWaybill";
import strings from "../../../../strings";

const { TextArea } = Input;

class ShippingAddressModal extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    const { customerList, customerValue, form } = this.props;

    const customer =
      customerList.length &&
      customerList.find(user => user.user_id === customerValue);

    const customerDetails =
      Array.isArray(customerList) &&
      customerList.length &&
      this.props.cartList.length &&
      customerList.filter(
        customer => customer.id === this.props.cartList[0].customer_id
      );

    form.setFieldsValue({
      address: customer ? customer.address_line1 : "--",
      shippingNote: `
        ${strings.dear} ${
        customer
          ? `${customer.first_name} ${customer.last_name}`
          : customerDetails.length > 0
          ? ` ${customerDetails[0].first_name} ${customerDetails[0].last_name}`
          : "--"
      },

        ${strings.thankYouForYourOrderFindGoods}
      `,
    });

    // set state
    this.setState({
      shippingNote: `
        ${strings.dear} ${
        customer
          ? `${customer.first_name} ${customer.last_name}`
          : customerDetails.length &&
            ` ${customerDetails[0].first_name} ${customerDetails[0].last_name}`
      },

        ${strings.thankYouForYourOrderFindGoods}
      `,
    });
  }

  handleTextChange = e => {
    // console.log(e.target.value, "hee");
  };
  handleTextAreaChange = e => {
    this.setState({
      shippingNote: e.target.value,
    });
  };

  getCartTotalAmount = () => {
    const cartTotalAmount =
      this.props.cartList.length === 0
        ? 0
        : this.props.cartList.reduce(function(prev, curr) {
            return Number(prev) + Number(curr.amount ? curr.amount : 0);
          }, 0);

    return cartTotalAmount.toFixed(2);
  };

  render() {
    const {
      isShippingAddressVisible,
      closeModal,
      form: { getFieldDecorator },
      user,
      allLoyaltyPrograms,
      customerList,
    } = this.props;

    const customer =
      customerList.length &&
      customerList.find(user => user.user_id === this.props.customerValue);

    const cartTotalAmount = this.props.isTwoPaymentMethod
      ? this.getCartTotalAmount() - this.props.mtier_amount
      : this.getCartTotalAmount();

    const transactionDate = new Date();

    const twoDigitMonth =
      transactionDate.getMonth() + 1 >= 10
        ? transactionDate.getMonth() + 1
        : "0" + (transactionDate.getMonth() + 1);
    const twoDigitDate =
      transactionDate.getDate() >= 10
        ? transactionDate.getDate()
        : "0" + transactionDate.getDate();

    return (
      <Modal
        title={strings.shippingNote}
        style={{ top: 20 }}
        visible={isShippingAddressVisible}
        onCancel={() => closeModal()}
        okButtonProps={{ loading: this.state.loading }}
        destroyOnClose
        footer={null}
      >
        <Form layout="vertical" >
          <Form.Item label={strings.shippingAddress}>
            {getFieldDecorator(
              "address",
              {}
            )(
              // <Input
              //   size="large"
              //   name="address"
              //   onChange={(e) => this.handleTextChange(e, "address")}
              // />
              <TextArea
                placeholder={strings.enterShippingAddress}
                rows={5}
                onChange={e => this.handleTextAreaChange(e)}
              />
            )}
          </Form.Item>

          <Form.Item label={strings.shippingNote}>
            {getFieldDecorator(
              "shippingNote",
              {}
            )(
              <TextArea
                placeholder={strings.enterShippingNote}
                rows={5}
                onChange={e => this.handleTextAreaChange(e)}
              />
            )}
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => this.props.closeModal()}
                style={{ marginRight: "8px" }}
              >
                Cancel
              </Button>
              <ReactToPrint
                trigger={() => <Button type="primary">Print details</Button>}
                content={() => this.componentRef}
              />
            </div>
          </Form.Item>

          <div style={{ display: "none" }}>
            <ShippingWaybill
              currency={user.currency || user.employer.currency}
              saleTotal={cartTotalAmount}
              paidWith={this.props.paid_with}
              channel={this.props.channel_mtd}
              transaction={this.props.cartList}
              saleId={this.props.saleId}
              purchaseTime={`${twoDigitDate} - ${twoDigitMonth} - ${transactionDate.getFullYear()} ${transactionDate.getHours()}:${transactionDate.getMinutes()}:${transactionDate.getSeconds()}`}
              user={this.props.user}
              selectedCustomer={this.props.customerName}
              customer={customer}
              customersList={customerList}
              allLoyaltyPrograms={allLoyaltyPrograms}
              ref={el => (this.componentRef = el)}
              shippingNote={this.state.shippingNote}
              hasDiscount={this.props.hasDiscount}
              discountAmount={this.props.discount_amount}
            />
          </div>
        </Form>
      </Modal>
    );
  }
}

const ShippingAddressModalForm = Form.create()(ShippingAddressModal);

export default ShippingAddressModalForm;
