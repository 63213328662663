import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, InputNumber,    } from 'antd';
import api from '../../../../../../api';
import { Icon, Form  } from "@ant-design/compatible";



const FormItem = Form.Item;

const EditCustomModal = ({ open, onCancel, custom, updateProducts, branchId, user, form, closeModal, customQuantity }) => {

    const { getFieldDecorator, setFieldsValue } = form;
    const [loading, setLoading ] = useState(false)
    const { id, name, price, quantity, barcode, merchant_id, product_id } = custom;

    const handleEdit = () => {
        form.validateFields(async (err, value)=>{
            if(err === null){
                setLoading(true);
                const data = {
                    name: value.name,
                    price: Number(value.price),
                    quantity:  Number(value.quantity),
                    barcode: value.barcode,
                    product_id,
                    merchant_id
                }

                const res = await api.product.editCustomQuantity(id, data);

                if(res.status === 200 ){
                    if(customQuantity !== undefined){
                        customQuantity.map((custom)=>{
                            if(custom.id === res.data.id){
                                custom.name = res.data.name;
                                custom.price = res.data.price;
                                custom.quantity = res.data.quantity;
                                custom.barcode = res.data.barcode;
                                return custom;
                            }else{
                                custom = custom;
                            }
                        })
                    }

                    setLoading(false);
                    Modal.success({
                        title: `${name} edited successfully`,
                        onOk: ()=>{
                            onCancel();
                            // closeModal()
                          !isNaN(branchId)
                            ? updateProducts(user.id, branchId)
                            : updateProducts();
                        }
                    });
                }else{
                    setLoading(false);
                    Modal.error({
                        title: `edit failed, please try again`
                    });   
                }
            }
        })
        

    }

  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={`Edit ${name} (${quantity}) - ${price}`}
        onOk={()=>handleEdit()}
        okButtonProps={{loading: loading}}
        destroyOnClose
    >
        <Form onFinish={()=>handleEdit()}>
            <FormItem label="Name">
                {getFieldDecorator("name", {
                    initialValue: name,
                    // rules: [{ required: true, message: "Please enter name" }] 
                })(
                        <Input size="large" />
                    )}
            </FormItem>

            <FormItem label="Price">
                {getFieldDecorator("price", {
                    initialValue: `${price}`,
                    // rules: [{ required: true, message: "Please enter price" }] 
                })(
                        <InputNumber size="large" style={{width: "100%"}}/>
                    )}
            </FormItem>

            <FormItem label="Quantity">
                {getFieldDecorator("quantity", {
                    initialValue: quantity,
                    // rules: [{ required: true, message: "Please enter quantity" }]
                 })(
                        <InputNumber size="large" style={{width: "100%"}}/>
                    )}
            </FormItem>

            <FormItem label="barcode">
                {getFieldDecorator("Product Sku", {
                    initialValue: barcode,
                    // rules: [{ required: true, message: "Please enter product sku" }]
                 })(
                        <Input size="large" />
                    )}
            </FormItem>
            
        </Form>
    </Modal>
  )
}

const EditCustomModalForm = Form.create()(EditCustomModal)
export default EditCustomModalForm;