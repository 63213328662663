import { Modal, Input, InputNumber, Checkbox } from "antd";
import { useState } from "react";
import { PaymentLabelContent } from "./PaymentLabelContent";

export const PaymentLabelModal = ({
  isVisible,
  onClose,
  splitPaymentActive,
  allPaymentLabels,
  totalAmount,
  amountRemaining,
  setAmountRemaining,
  setSelectedPaymentLabel,
  selectedPaymentLabel,
  strings,
  setTotalAmount,
  totalAmountToBePaid,
  splitPaymentMethods,
  handlePayment,
  location,
  hasDiscount,
  hasInstantRewardDiscount,
  discountAmount,
  instantRewardDiscount,
  clearDiscount,
  transactionDate,
  message,
  setSplitPaymentMethods,
  setSelectCardTypeModalOpen
}) => {
  // const [transferReference, setTransferReference] = useState("");
  const [localAmountRemaining, setLocalAmountRemaining] = useState(
    amountRemaining
  );

  const handleOk = () => {
    let valueToRemain;
    const transferReference = document.getElementById("transfer-reference-input")?.value || "";
  
    if (splitPaymentActive) {
      // Handle split payment
      if (splitPaymentMethods?.method1?.method === null) {
        setSplitPaymentMethods((prev) => ({
          ...prev,
          method1: {
            method: "card",
            amount: valueToRemain || amountRemaining,
          },
        }));
        setSelectCardTypeModalOpen(false); // Close the modal after updating method1
        return;
      } else if (splitPaymentMethods?.method2?.method === null) {
        setSplitPaymentMethods((prev) => ({
          ...prev,
          method2: {
            method: "card",
            amount: Number(totalAmount).toFixed(2),
          },
        }));
  
        const combinedMethods = `${splitPaymentMethods.method1.method} + card`;
        const combinedReference = `${transferReference}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-method2:card:${totalAmount}`;
  
        handlePayment(
          combinedMethods,
          location,
          hasDiscount || hasInstantRewardDiscount(),
          discountAmount || instantRewardDiscount,
          combinedReference,
          clearDiscount,
          transactionDate || Date.now(),
          new Date(transactionDate || Date.now()).getTime(),
          selectedPaymentLabel
        );
  
        onClose(); // Close the modal after handling payment
        return;
      }
    } else {
      // Handle single payment
      const paymentReference = transferReference || `${Date.now()}_card`;
  
      handlePayment(
        "card",
        location,
        hasDiscount || hasInstantRewardDiscount(),
        discountAmount || instantRewardDiscount,
        paymentReference,
        clearDiscount,
        transactionDate || Date.now(),
        new Date(transactionDate || Date.now()).getTime(),
        selectedPaymentLabel
      );
  
      onClose(); // Close the modal after handling payment
    }
  };

  return (
    <Modal
      open={isVisible}
      title={`${strings.confirmPayment}. ${
        splitPaymentActive
          ? totalAmount
          : hasInstantRewardDiscount()
          ? Number(totalAmountToBePaid - instantRewardDiscount).toFixed(2)
          : Number(totalAmountToBePaid).toFixed(2)
      }`}
      onOk={handleOk}
      onCancel={onClose}
    >
      <PaymentLabelContent
        splitPaymentActive={splitPaymentActive}
        allPaymentLabels={allPaymentLabels}
        totalAmount={totalAmount}
        amountRemaining={localAmountRemaining}
        setAmountRemaining={setLocalAmountRemaining}
        setSelectedPaymentLabel={setSelectedPaymentLabel}
        selectedPaymentLabel={selectedPaymentLabel}
        strings={strings}
        setTotalAmount={setTotalAmount}
        totalAmountToBePaid={totalAmountToBePaid}
        splitPaymentMethods={splitPaymentMethods}
        message={message}
      />
    </Modal>
  );
};
