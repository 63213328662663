import React from "react";
import { connect } from "react-redux";
import { Input, Modal } from "antd";
import { Form } from "@ant-design/compatible";

import {
  deleteCategory,
  getAllCategories,
} from "../../../../../../redux/actions/categoryActions";
import strings from "../../../../../../strings";

class DeleteCategoryModal extends React.Component {
  state = {
    loading: false,
  };

  handleDeleteCategory = () => {
    const {
      form,
      deleteCategory,
      getAllCategories,
      currCategory: { id },
      closeModal,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      if (values.delete.toLowerCase() !== "delete") {
        Modal.error({
          title:
            strings.pleaseTypeIn + " delete " + strings.toDeleteThisProduct,
        });
      } else {
        this.setState({ loading: true });

        deleteCategory(id)
          .then(res => {
            this.setState({ loading: false });
            getAllCategories();
            Modal.success({
              title: strings.categoryDeletedSuccessfully,
              onOk: () => {
                closeModal();
              },
            });
          })
          .catch(err => this.setState({ loading: false }));
      }
    });
  };

  render() {
    const {
      currCategory: { name },
      form: { getFieldDecorator },
      isDeleteCategoryModalOpen,
      closeModal,
    } = this.props;

    return (
      <Modal
        title={`${strings.delete} ${name}`}
        okText={strings.delete}
        cancelText={strings.cancel}
        visible={isDeleteCategoryModalOpen}
        destroyOnClose
        onCancel={closeModal}
        onOk={this.handleDeleteCategory}
        okButtonProps={{ loading: this.state.loading }}
      >
        <Form layout="vertical" >
          <p>
            <strong>
              All Products associated with this category would also be deleted.
            </strong>
          </p>
          <Form.Item
            label={strings.pleaseTypeIn + " delete " + strings.toConfirm}
          >
            {getFieldDecorator("delete", {
              rules: [
                {
                  required: true,
                  message:
                    strings.pleaseTypeIn + " delete " + strings.toConfirm,
                },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const DeleteCategoryForm = Form.create()(DeleteCategoryModal);

export default connect(null, { getAllCategories, deleteCategory })(
  DeleteCategoryForm
);
