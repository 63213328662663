import React, { useState, useEffect } from 'react';
import BundleListItem from '../BundleListItem';
import strings from '../../../../strings';

const BundleList = ({ data, addBundleToCart, user, location, cartList }) => {
  return (
  <>
    {data && data.length ? (
        data.map((product) => {
          if (!product.deleted) {
            return (
              <BundleListItem
                key={product.id}
                item={product}
                onClick={addBundleToCart}
                currency={user.currency}
                // toggleCustomPriceModal={toggleCustomPriceModal}
                location={location}
                cartList={cartList}
              />
            );
          }
        })
      ) : (
        
        <p style={{ margin: 20 }}>{strings.noProductItem}</p>
      )}
  </>)
}

export default BundleList